import React from "react";

const FormSelect = ({ formValue, onChangeForm, formData }) => {
  return (
    <div className=" custom-dropdown">
      <select
        value={formValue}
        name="select-year-form"
        id="select-year-form"
        className="small-select"
        onChange={onChangeForm}
      >
        <option value={"Select Year"}>Select Year</option>
        {formData
          ?.filter((year, index) => {
            const firstIndex = formData.findIndex(
              (formData) => formData?.form_session === year?.form_session
            );
            return index === firstIndex;
          })
          .map((year) => {
            return (
              <option key={year?.form_session} value={year?.form_session}>
                {year?.form_session}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default FormSelect;
