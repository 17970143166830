import React from "react";
import { Link } from "react-router-dom";
import Image from "../../../utils/image/Image";

const HeaderSearchContent = ({ mobileIcon }) => {
  return (
    <Link className="search_resource_container" to="/search">
      <div className="search_icon_mobile">
        <Image src={mobileIcon} alt="orange-search-icon" />
      </div>
      <div className="search_icon_desktop">
        <div className="search_icon"></div>
        <label>Search Content</label>
      </div>
    </Link>
  );
};

export default HeaderSearchContent;
