export const updateSubmitDataResponse = (
  structureId,
  updateFunction,
  setSubmitDataResponse,
  questionObject,
) => {
  setSubmitDataResponse((prevData) => {
    if (structureId === 0 || structureId == null) {
      return prevData;
    }
    const newData = [...prevData];
    const structureIndex = newData.findIndex(
      (item) => Number(Object.keys(item)[0]) === Number(structureId)
    );

    // Check if the structure with the specified structureId exists
    if (structureIndex !== -1) {
      // If it exists, apply the updateFunction to the existing structure
      newData[structureIndex][structureId] = updateFunction(
        newData[structureIndex][structureId]
      );
    } else {
      if (questionObject !== undefined) {
        // If it doesn't exist, create a new entry with the specified structureId
        const newEntry = {
          [structureId]: updateFunction(questionObject),
        };
        newData.push(newEntry);
      }
    }

    // Sort the newData array based on structureId
    newData.sort(
      (a, b) => Number(Object.keys(a)[0]) - Number(Object.keys(b)[0])
    );

    return newData;
  });
};
