import { Outlet, useParams } from "react-router-dom";

import arrowImg from "../../../assets/images/white-arrow-down.svg";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../shared/AppContext";
// import Auth from "../../../auth/Auth";
import io from "socket.io-client";
import { userService } from "../../../_services/User.services";
import Auth from "../../../auth/Auth";
import { CONSTANT } from "../../utils/constants";
import Button from "../../utils/forms/Button";
import Image from "../../utils/image/Image";

export default function LIveClass() {
  const { sidebarShow, setSidebarShow, setMsgContent, setSettings } =
    useContext(AppContext);
  const { id } = useParams();
  const [PSISData, setPSISData] = useState();
  const { setSpinner } = useContext(AppContext);
  //******************** Get Folder s=Structure Data ******************** */

  useEffect(() => {
    if (PSISData?.__token) {
      const socket = io("https://chat.prathamedu.com/chat", {
        // const socket = io("192.168.88.58:3000", {
        transports: ["websocket"],
        upgrade: false,
        auth: (cb) => {
          cb({
            // token: userService?.emailValidation(String(Auth?.user()?.user_name)) ? btoa(String(Auth?.user()?.user_name)) : String(Auth?.user()?.user_name),
            // token: "19240006|0EZMylEroE7yA0xp2ObDIc45zFgbgfOmoHsbdiC5U6iuFMsOiXuEpSuCLhgi",
            token: `${Auth.user()?.user_id}|` + PSISData?.__token,
            // token: Auth?.user()?.user_id + PSISData?.__token,
            as: "student",
          });
        },
      });

      if (socket) {
        socket.on("connect_error", (err) => {
          if (err.message === "invalid credentials") {
            socket.auth.token = "efgh";
            socket.connect();
          }
        });

        socket.on("message", (data) => {
          setMsgContent((prevContent) => [
            ...prevContent,
            { ...data?.message },
          ]);
        });

        socket.on("disconnect", () => {});

        socket.on("connect", (data) => {});

        socket.on("thread:setting", (data) => {
          setSettings(data);
        });

        socket.on("message:deleted", (data) => {
          setMsgContent((prevContent) =>
            prevContent.filter((ele) => ele._id !== data.message._id)
          );
        });
      }
    }

    //eslint-disable-next-line
  }, [PSISData?.__token]);

  const getProfileData = async () => {
    setSpinner(true);
    try {
      let params = {
        username: Auth?.user()?.user_id,
        // username: Auth?.user()?.user_id,
        role: "student",
      };
      const response = await userService.getPSISToken(params);
      if (response?.status >= 200 && response?.status <= 299) {
        const data = await response.json();
        setPSISData(data?.data);
        localStorage.setItem("PSIS_token", data?.data?.__token);
        setSpinner(false);
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }
      if (response?.status >= 400 && response?.status <= 499) {
        // const data = await response.json();
        // setToaster({ show: true, type: "danger", content: data.message, });
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getProfileData();
    //eslint-disable-next-line
  }, []);

  //******************** Get Folder s=Structure Data ******************** */
  return (
    <div className="col-md-12 d-flex live-class">
      <div className="white-box body-cont">
        <div className="live-class-layout">
          {localStorage.getItem("zoomMeetUrl") && (
            <iframe
              src={localStorage.getItem("zoomMeetUrl")}
              title="Iframe Example"
              allow="camera; microphone; fullscreen"
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
              referrerpolicy="no-referrer"
              allowfullscreen
              style={{
                height: !window?.localStorage.getItem("zoomMeetUrl") && 0,
              }}
            ></iframe>
          )}
          {Number(id) ===
            JSON.parse(window.localStorage.getItem(`liveClassId-${id}`)) && (
            <div className="text-center mb-3 pt-2 pb-4">
              <p className="fs-2">If you are unable to zoin the live class.</p>
              <p className="fs-2">
                Please click the below button to join the live class.
              </p>
              <Button
                className={"btn btn-theme ms-3 green-btn small-btn mt-0"}
                title={"Join Live Class"}
                onClick={() => {
                  const zoomMeetUrl = localStorage.getItem(`joinUrl-${id}`);
                  if (zoomMeetUrl) {
                    window.open(zoomMeetUrl, "_blank");
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="sidebar">
        <div className="chat-sec-sidebar schedule-sidebar">
          <div className="right-sidebar">
            <div className=" white-box">
              {/* <div className="live-class-header  ">
                <h3>
                  <span className=" back-btn">
                    <Image src={backBtn} alt="back btn" />
                  </span>
                  <span className="pe-3 user-icon">
                    <Image src={userImage} alt="user" />
                  </span>
                  Live class chat
                </h3>
              </div>
              <div className="live-class-body">
                <ChatingBox setMsgText={setMsgText} msgText={msgText} />
              </div> */}

              <Outlet />
            </div>
          </div>
        </div>
        <div
          className="mobile-sidebar-icon position-fixed"
          onClick={() => setSidebarShow(!sidebarShow)}
        >
          <div className="heading">
            Chat
            <Image src={arrowImg} alt="arrow-icon" />
          </div>
        </div>
      </div>
    </div>
  );
}
