export const useQuestionStatus = () => {
  // Question Status Mapping
  const questionStatusMapping = {
    "save and next": 0,
    "save and mark": 1,
    "mark and next": 2,
    active: 3,
    default: 4,
    "submit": 5
  };
  // Question Status Function
  const questionStatus = (key) => {
    return questionStatusMapping[key];
  };

  return { questionStatus };
};
