import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { AppContext } from "../../shared/AppContext";
import Header from "../portalLayout/Header/Header";

import arrowImg from "../../../assets/images/white-arrow-down.svg";
import BreadCrumbContextProvider from "../../shared/breadcrumb-context";
import Image from "../../utils/image/Image";

const DesignTestLayout = () => {
  const { sidebarShow, setSidebarShow, dark_mode } = useContext(AppContext);

  // useEffect(() => {
  //   window.addEventListener("beforeunload", (ev) => {
  //     ev.preventDefault();

  //     return (ev.returnValue = "Do you really want to close?");
  //   });
  //   window.close = () => {
  //     return "Do you really want to close?";
  //   };

  //   document.addEventListener("contextmenu", (e) => {
  //     e.preventDefault();
  //   });
  //   document.onkeydown = function (e) {
  //     // disable F12 key
  //     // if (e.keyCode === 123) {
  //     //   return false;
  //     // }
  //     // disable I key
  //     if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
  //       return false;
  //     }
  //     // disable J key
  //     if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
  //       return false;
  //     }
  //     // disable U key
  //     if (e.ctrlKey && e.keyCode === 85) {
  //       return false;
  //     }
  //     // disable R key
  //     if (e.ctrlKey && e.keyCode === 82) {
  //       return false;
  //     }
  //     // disable f5 key
  //     if (e.ctrlKey && e.keyCode === -21) {
  //       return false;
  //     }
  //   };
  // }, []);
  return (
    <BreadCrumbContextProvider>
      <div
        className={`dashboard-body exam-portal dyt-exam-portal full-wid${
          dark_mode ? " dark_mode" : ""
        } ${sidebarShow ? " show-inner-cont" : ""}`}
      >
        <Header />
        <div className="blue-bg"></div>
        <div className={`height-100 dash_body dyt_body`} id="supAdmin">
          <div className="container-fluid dash-container">
            <div className="row">
              <Outlet />
            </div>
          </div>
        </div>
        <div
          className="mobile-sidebar-icon position-fixed"
          onClick={() => setSidebarShow(!sidebarShow)}
        >
          <div className="heading">
            Palette <Image src={arrowImg} alt="arrow" />
          </div>
        </div>
      </div>
    </BreadCrumbContextProvider>
  );
};

export default DesignTestLayout;
