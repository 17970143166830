import { useState } from "react";
import { useApiRequest } from "./useApiRequest";

export const useFetchData = (
  apiFunction,
  setSpinner,
  setToaster,
  isToasterShow = false,
  isSpinner = true
) => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);
  const { fetchData } = useApiRequest(
    apiFunction,
    setSpinner,
    setToaster,
    isToasterShow,
    isSpinner,
    setData,
    setTotal
  );
  return { getData: fetchData, data, total };
};
