// QuestionComponent.jsx
import React from "react";
import "react-simple-keyboard/build/css/index.css";
import ComprehensionContainer from "./ComprehensionContainer";
import SingleAnswerTypeQuestion from "./SingleAnswerTypeQuesion";
import NumericAnswerTypeQuestion from "./NumericAnswerTypeQuestion";
import OneWordAnswerTypeQuestion from "./OneWordAnswerTypeQuestion";
import SubjectiveAnswerTypeQuestion from "./SubjectiveAnswerTypeQuestion";

const Question = ({
  questionData,
  currentQuestionIndex,
  questionType,
  handleOptionChange,
  isOptionSelected,
  keyboardRef,
  handleOneWordAnswer,
  handleOneWordInputChange,
  handleNumericAnswer,
  numericAnswer,
  handleSubjectiveAnswer,
  subjectiveAnswer,
}) => {
  const isSubjectiveAnswered =
    subjectiveAnswer !== null ? subjectiveAnswer : "";
  const isNumericAnswered = numericAnswer !== null ? numericAnswer : "";
  const isOneWordAnswered =
    keyboardRef?.current?.getInput() !== null &&
    keyboardRef?.current?.getInput();

  return (
    <div className="sub-report">
      {questionData?.length > 0 && (
        <div className="question-dash">
          <ComprehensionContainer
            questionData={questionData}
            currentQuestionIndex={currentQuestionIndex}
            questionType={questionType}
          />
          <SingleAnswerTypeQuestion
            questionData={questionData}
            currentQuestionIndex={currentQuestionIndex}
            handleOptionChange={handleOptionChange}
            isOptionSelected={isOptionSelected}
          />

          {questionData[currentQuestionIndex]?.question_type_id === 4 && (
            <OneWordAnswerTypeQuestion
              keyboardRef={keyboardRef}
              questionData={questionData}
              isOneWordAnswered={isOneWordAnswered}
              handleOneWordAnswer={handleOneWordAnswer}
              handleOneWordInputChange={handleOneWordInputChange}
              currentQuestionIndex={currentQuestionIndex}
            />
          )}
          {questionData[currentQuestionIndex]?.question_type_id === 3 && (
            <NumericAnswerTypeQuestion
              isNumericAnswered={isNumericAnswered}
              handleNumericAnswer={handleNumericAnswer}
            />
          )}
          {questionData[currentQuestionIndex]?.question_type_id === 5 && (
            <SubjectiveAnswerTypeQuestion
              handleSubjectiveAnswer={handleSubjectiveAnswer}
              questionData={questionData}
              currentQuestionIndex={currentQuestionIndex}
              isSubjectiveAnswered={isSubjectiveAnswered}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Question;
