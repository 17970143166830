import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../shared/AppContext";
import { useLiveClassRecordings } from "../../../hooks/hookList";
import { NewBackButton } from "../../utils/backBtn/NewBackButton";
import Table from "../../utils/table/Table";
import Pagination from "react-js-pagination";
import DatePicker from "../../utils/datePicker/DatePicker";
import Search from "../../utils/search/Search";
import { useBreadcrumb } from "../../shared/breadcrumb-context";
import { updateBreadcrumb } from "../../utils/commonFunctions/updateBreadcrumb";
import { useDebounce } from "../../../hooks/useDebounce";
import { convertIsoToDDMMYYYY, handleDateChange } from "../../utils/dateTime";

const liveClassTableHeadings = [
  {
    value: "S. No.",
    id: 1,
    className: "w-5 text-center",
  },
  {
    value: "Live CLass",
    id: 2,
    className: "w-10 text-center",
  },
  {
    value: "Date",
    id: 3,
    className: "w-10 text-center",
  },
  {
    value: "Class Recording",
    id: 6,
    className: "w-10 text-center",
  },
];

const LiveClassRecordings = () => {
  const navigate = useNavigate();
  const {
    setSpinner,
    setToaster,
    setLiveClassDate,
    liveClassDate,
    activePage,
    setActivePage,
    searchLiveRecordings,
    setSearchLiveRecordings,
  } = useContext(AppContext);
  const { dispatch, state } = useBreadcrumb();

  const { getData: getLiveClassRecordings, data: liveClassRecording } =
    useLiveClassRecordings(setSpinner, setToaster);

  const [breadcrumbName, setBreadcrumbName] = useState("Live Class Recordings");
  const [resultData, setResultData] = useState({
    from: "",
    to: "",
    total: "",
    last_page: "",
  });
  const debounce = useDebounce(searchLiveRecordings, 2000);

  const liveClassRecordingCallback = (liveClassData) => {
    setActivePage(liveClassData.data.from);
    setResultData({
      from: liveClassData?.data?.from,
      to: liveClassData?.data?.to,
      total: liveClassData?.data?.total,
      last_page: liveClassData?.data?.last_page,
    });
  };

  const onChangeSearch = (e) => {
    setSearchLiveRecordings(e.target.value);
  };

  useEffect(() => {
    if (debounce !== "") {
      const params = {
        page: 1,
        size: 10,
        ...(liveClassDate.start && { start_date: liveClassDate.start }),
        ...(liveClassDate.end && { end_date: liveClassDate.end }),
        title: debounce,
      };
      getLiveClassRecordings(params, liveClassRecordingCallback);
    }
  }, [debounce]);

  const callBackBreadcrumb = () => {
    return "/schedule/liveClassRecordings";
  };

  const handlePageChange = async (e) => {
    const params = {
      size: 10,
      page: e,
      start_date: liveClassDate.start,
      end_date: liveClassDate.end,
      ...(debounce && { title: debounce }),
    };
    await getLiveClassRecordings(params, liveClassRecordingCallback);
    setActivePage(e);
  };

  useEffect(() => {
    updateBreadcrumb(
      state,
      dispatch,
      breadcrumbName,
      null,
      null,
      false,
      callBackBreadcrumb
    );
  }, [breadcrumbName, navigate]);

  return (
    <div className="white-box">
      <div className="mb-4" title="Back to the previous page">
        <h1 className="text-capitalize live_class_title">
          <NewBackButton onClick={() => navigate(-1)} />
          {breadcrumbName}
        </h1>
      </div>
      <div className="row">
        <div className="col-12 col-lg-5 mb-5">
          <div className="w-100 search_container" style={{ display: "unset" }}>
            <Search
              onChange={onChangeSearch}
              value={searchLiveRecordings}
              placeholder="Search for example QA, VA"
            />
          </div>
        </div>
        <div className="date_picker_container mb-5 col-12 col-lg-7">
          <div className="row">
            <div className="date_picker_from col-6 col-md-5">
              <DatePicker
                label="Date From"
                id="date-pick"
                date={liveClassDate.start}
                placeholder="Select Start Date"
                onChange={(e) => {
                  handleDateChange(
                    e,
                    true,
                    liveClassDate,
                    setLiveClassDate,
                    getLiveClassRecordings,
                    liveClassRecordingCallback,
                    {
                      page: activePage,
                      size: 10,
                      ...(debounce && { title: debounce }),
                    },
                    setToaster
                  );
                }}
              />
            </div>
            <div className="date_picker_to col-6 col-md-5">
              <DatePicker
                label="Date To"
                id="date-pick"
                date={liveClassDate.end}
                placeholder="Select End Date"
                onChange={(e) =>
                  handleDateChange(
                    e,
                    false,
                    liveClassDate,
                    setLiveClassDate,
                    getLiveClassRecordings,
                    liveClassRecordingCallback,
                    {
                      page: activePage,
                      size: 10,
                      ...(debounce && { title: debounce }),
                    },
                    setToaster
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-1">
        <Table
          heading={liveClassTableHeadings}
          rows={liveClassRecording?.data
            ?.filter((d) => Number(d.is_active) !== 0)
            .map((d, index) => {
              const formattedStartDate = convertIsoToDDMMYYYY(d.start_date);
              return [
                {
                  value: (activePage - 1) * 10 + index + 1,
                  id: 1,
                  className: "w-5 text-center",
                },
                {
                  value: d?.meeting_name,
                  id: 2,
                  className: "w-15 text-center",
                },
                {
                  value: formattedStartDate,
                  id: 3,
                  className: "w-10 text-center",
                },

                {
                  value: (
                    <div className="text-center">
                      {d?.link_live_class_videos.length > 0 ? (
                        <NavLink
                          style={{ color: "#11ce8c" }}
                          className="text-decoration-underline text-center cursor-pointer p-0"
                          to={`/schedule/liveClassRecordings/${d.live_class_id}/recordings`}
                          state={{
                            recordings: d.link_live_class_videos,
                            start_date: liveClassDate.start,
                            end_date: liveClassDate.end,
                            liveClassName: d.meeting_name,
                          }}
                        >
                          Play Recordings
                        </NavLink>
                      ) : (
                        <div className="cursor-pointer text-center">
                          Not Available
                        </div>
                      )}
                    </div>
                  ),
                  id: 5,
                  className: "w-10",
                },
              ];
            })}
        />
      </div>
      {resultData.total > 0 && ![0, 1].includes(resultData?.last_page) && (
        <div>
          <Pagination
            pageRangeDisplayed={5}
            itemsCountPerPage={10}
            activePage={activePage}
            totalItemsCount={resultData.total}
            onChange={(e) => {
              handlePageChange(e);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default LiveClassRecordings;
