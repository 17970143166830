import React from "react";

import { Doughnut, Bar, Pie } from "react-chartjs-2";
// import dataImg from "../../../assets/images/no-data.png";
// import faker from 'faker';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";

import SubjectCards from "../card/SubjectCards";

import ColorCard from "../card/ColorCard";
import Auth from "../../../auth/Auth";
import Image from "../image/Image";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

const ProgressBar = ({
  progressHeading,
  className,
  circleProgressBar,
  graphProgressBar,
  filledCircleProgressBar,
  progressData,
  colorData,
  subGraphHeading,
  questionlevelProgressBar,
  totalMarksScored,
  graphData,
  cardData,
  UtillprogressHeading,
}) => {
  // bar chart
  const chartOptions2 = {
    plugins: {
      legend: {
        display: false, // Set to false to hide dataset labels
      },
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };

  const chartDataLevelWise2 = {
    datasets: [
      {
        label: Auth?.user()?.name,
        data: [{ x: "", y: totalMarksScored?.user }],
        backgroundColor: ["rgb(37 47 64 / 74%)"],
        borderRadius: 5,
        borderColor: ["#252F40"],
        hoverOffset: 100,
        borderWidth: 2,
      },
      {
        label: "Topper",
        data: [{ x: "", y: totalMarksScored?.topper }],
        backgroundColor: ["rgb(255 129 87 / 55%) "],
        borderRadius: 5,
        borderColor: ["#fe8057"],
        borderWidth: 2,
      },
      {
        label: "Average",
        data: [{ x: "", y: totalMarksScored?.average }],
        backgroundColor: ["rgb(17 206 140 / 57%)"],
        borderRadius: 5,
        borderColor: ["#11ce8c"],
        borderWidth: 2,
      },
      {
        label: "",
        data: [{ x: "", y: totalMarksScored?.topper }],
        backgroundColor: ["transparent"],
      },
      {
        label: "",
        data: [{ x: "", y: totalMarksScored?.topper }],
        backgroundColor: ["transparent"],
      },
      {
        label: "",
        data: [{ x: "", y: totalMarksScored?.topper }],
        backgroundColor: ["transparent"],
      },
    ],
  };
  // pie chart
  const chartOptions3 = {
    options: {
      plugins: {
        datalabels: {
          display: true,
        },
      },
    },
  };

  const chartData3 = {
    datasets: [
      {
        label: "My First Dataset",
        // data: [59, 18, 10, 13],
        data: colorData?.length
          ? colorData?.map((itm) => itm?.time_spent)
          : [59, 45, 44, 22, 1],
        // backgroundColor: [
        //   "#92e498",
        //   "rgb(36, 46, 63)",
        //   "rgb(251, 201, 90)",
        //   "rgb(253, 90, 88)",
        // ],
        backgroundColor: colorData?.length
          ? colorData?.map((itm) => itm.className)
          : [],
        hoverOffset: 4,
      },
    ],
  };

  // level wise bar chart
  // const chartDataLevelWise = {
  //   datasets: [
  //     {
  //       label: "Easy",
  //       data: [
  //         { x: "Easy", y: 6 },
  //         { x: "Medium", y: 1 },
  //         { x: "Difficult", y: 9 },

  //       ],
  //       backgroundColor: ["rgb(109, 113, 248)"],
  //       borderRadius: 5,
  //     },
  //     {
  //       label: "Medium",
  //       data: [
  //         { x: "Easy", y: 3 },
  //         { x: "Medium", y: 3 },
  //         { x: "Difficult", y: 3 },
  //       ],
  //       borderRadius: 5,
  //       backgroundColor: ["rgb(17, 206, 140)"],
  //     },
  //     {
  //       label: "Difficult",
  //       data: [
  //         { x: "Easy", y: 5 },
  //         { x: "Medium", y: 5 },
  //         { x: "Difficult", y: 5 },
  //       ],
  //       borderRadius: 5,
  //       backgroundColor: ["rgb(253, 90, 88)"],
  //     },
  //     {
  //       label: "Not Attempted",
  //       data: [
  //         { x: "Easy", y: 1 },
  //         { x: "Medium", y: 1 },
  //         { x: "Difficult", y: 1 },
  //       ],
  //       borderRadius: 5,
  //       backgroundColor: ["rgb(251, 201, 90)"],
  //     },
  //   ],
  // };
  return (
    <>
      <div className={`progress-sec ${className}`}>
        <div className="att_name text-center mb-3">
          <h4>{progressHeading}</h4>
        </div>
        <div className=" align-items-center  row flex progress-graph">
          {/* ******* progress card */}
          {cardData &&
            progressData?.map((elem, index) => {
              return (
                <div className="progress-report col-12 col-md-4" key={index}>
                  <>
                    <div className="progress-detail">
                      <SubjectCards
                        subJectData={progressData}
                        title={elem.name}
                      />
                    </div>
                  </>
                </div>
              );
            })}
          {/* **********progress bar  */}
          <div className="result-progress-graph text-end px-4 col">
            {/* <Image src={circleProgress} className="img-fluid" /> */}
            {/* circuler progress bar  */}
            {circleProgressBar && progressData?.length && (
              <Doughnut
                data={{
                  datasets: [
                    {
                      label: "circle progress bar",
                      data: progressData[0]?.doughData,
                      backgroundColor: ["#11ce8c", "#fd5a58", "#fbc95a"],
                    },
                  ],
                }}
                className="circle-progressbar"
              />
            )}
            {/* graph progress bar */}
            {graphProgressBar && (
              <div className="pai-chart d-flex align-items-center">
                <>
                  <span className="verticaltext dark-gray me-4">
                    {/* Total Marks Scored */}
                    {subGraphHeading}
                  </span>
                  <div className="graph ">
                    <ul className="list-unstyled d-flex graph-Ulist">
                      <li>
                        <span className="stud_name">
                          {Auth?.user()?.name} :{" "}
                        </span>
                        <span className="stud_detail">
                          {totalMarksScored?.user}
                        </span>
                      </li>
                      <li>
                        <span className="stud_name">Topper: </span>
                        <span className="stud_detail">
                          {totalMarksScored?.topper}
                        </span>
                      </li>
                      <li>
                        <span className="stud_name">Average: </span>
                        <span className="stud_detail">
                          {totalMarksScored?.average}
                        </span>
                      </li>
                    </ul>
                    <Bar
                      // options={chartOptions2}
                      // data={chartData2}
                      data={chartDataLevelWise2}
                      className="graph-details"
                    />
                  </div>
                </>
              </div>
            )}
            {/* filled progress bar  */}
            {filledCircleProgressBar && (
              <>
                <Pie
                  options={chartOptions3}
                  data={chartData3}
                  className="circle-progressbar filled-circlebar"
                />
              </>
            )}

            {/* Question level graph  */}
            {questionlevelProgressBar && graphData && (
              <div className="pai-chart d-flex align-items-center">
                <>
                  <span className="verticaltext dark-gray me-4">
                    {/* Total Marks Scored */}
                    {subGraphHeading}
                  </span>
                  <div className="graph me-5">
                    {/* <Image src={QAgraph} className="img-flui" width="{1000}" /> */}
                    <Bar
                      options={chartOptions2}
                      // data={chartDataLevelWise}
                      data={graphData}
                      className="level-chart"
                    />
                  </div>
                </>
              </div>
            )}
          </div>

          {/* *****color-chart */}
          {colorData && (
            <div className="col">
              <div className="progress-report ">
                {/* ***circleProgressBar*** */}
                <h4>{UtillprogressHeading}</h4>
                <div className=" color-util  ">
                  {colorData?.map((circleProgressColor, index) => {
                    return (
                      <React.Fragment key={index}>
                        <ColorCard
                          color={circleProgressColor?.className}
                          title={circleProgressColor?.color_name}
                          dataCount={circleProgressColor?.color_data}
                          percent={circleProgressColor?.percent}
                          totalMarksScored={circleProgressColor?.totalMarks}
                          scored={circleProgressColor.score}
                        />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
