import { useEffect, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../shared/AppContext";
import { useBreadcrumb } from "../../shared/breadcrumb-context";
import { useHomeAssignmentsExamsList } from "../../../hooks/hookList";
import ReportCards from "../../utils/card/ReportCards";

const Assignment = () => {
  const location = useLocation();
  const { setSpinner, setToaster } = useContext(AppContext);
  const { dispatch } = useBreadcrumb();
  const [assignementData, setAssignementData] = useState([]);

  const { getData: getHomeAssignmentList } = useHomeAssignmentsExamsList(
    setSpinner,
    setToaster
  );

  // Home Assignment Exam List Callback
  const homeAssignmentListCallback = (homeAssignmentData) => {
    const homeAssignmentList = homeAssignmentData?.data?.reduce((acc, elem) => {
      if (elem.exams_count) {
        acc.push({
          id: elem?.id,
          icon: "images/icon11.svg",
          className: "card-box redBG align-items-center",
          title: elem?.name,
          isSubHeading: true,
        });
      }
      return acc;
    }, []);
    setAssignementData(homeAssignmentList);
  };

  useEffect(() => {
    getHomeAssignmentList({}, homeAssignmentListCallback);
    dispatch({
      type: "SET_BREADCRUMB",
      crumb: [{ title: "Practice Tests", url: "/home-assignments" }],
    });
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="assignment-dash  fixed-dash ">
        <div
          className={`white-box body-cont pt-5 ${assignementData ? "" : ""}`}
        >
          <div className="reportcard_row pb-0 ">
            <div className="row  ">
              {assignementData?.length > 0 &&
                assignementData?.map((assignmentCardData, index) => {
                  return (
                    <div className="col-xl-3 col-md-4" key={index}>
                      <Link
                        to={`/home-assignments/new-assignment-details/${assignmentCardData?.id}`}
                        state={{
                          back_url: location?.pathname + location?.search,
                          course_name: assignmentCardData?.title,
                        }}
                      >
                        <ReportCards
                          title={assignmentCardData.title}
                          result={assignmentCardData.result}
                          image={assignmentCardData.icon}
                          className={assignmentCardData.className}
                          sub_heading={assignmentCardData.sub_heading}
                        />
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Assignment;
