import React from "react";
import { NavLink } from "react-router-dom";
import DocumentIcon from "../../../assets/images/menu-board.png";
import VideoIcon from "../../../assets/images/search-video.png";
import ExamIcon from "../../../assets/images/online-exam.png";
import HomeAssignmentStatusButton from "../homeAssignmentLatest/HomeAssignmentStatusButton";
import MockTestButton from "../test/MockTestButton";
import { uploadOMRFile } from "../../utils/helpers/uploadOMRFile";
import { CONSTANT } from "../../utils/constants";
import OMRFilePreviewModal from "../test/OMRFilePreviewModal";
import Image from "../../utils/image/Image";
import { convertIsoToOrdinalDate } from "../../utils/dateTime";

const SearchCard = ({
  cardData,
  setSelectedFile,
  selectedFile,
  setPreviewFile,
  previewFile,
  setIsModalOpen,
  isModalOpen,
  setToaster,
  pageNumber,
  onDocumentLoadSuccess,
  handleUploadOMR,
  studentId,
}) => {
  const formatDate = convertIsoToOrdinalDate(cardData?.resource_date);
  const iconMapping = {
    1: DocumentIcon,
    2: VideoIcon,
    4: ExamIcon,
  };
  const selectedIcon = iconMapping[cardData?.resource_type_id];
  return (
    <div className="col-md-6 col-xl-3 mt-4">
      <div className="search_card d-flex flex-column justify-content-center align-items-center">
        {cardData?.resource_type_id && (
          <div className="w-100 d-flex justify-content-center">
            <div className="search_card_icon d-flex justify-content-center align-items-center">
              <Image
                width={33}
                height={33}
                src={selectedIcon}
                alt="document-icon"
              />
            </div>
          </div>
        )}
        {cardData.resource_name && (
          <div className="search_label_title">
            <label>{cardData.resource_name}</label>
          </div>
        )}
        {cardData?.name && (
          <div className="search_label_title">
            <label>{cardData?.name}</label>
          </div>
        )}
        {cardData?.resource_date && (
          <div className="label_date">
            <label dangerouslySetInnerHTML={{ __html: formatDate }} />
          </div>
        )}
        {cardData.resource_desciption && (
          <div className="label_description">
            <label>{cardData.resource_desciption}</label>
          </div>
        )}
        <div
          className={`card_btn_container ${
            [1, 2].includes(cardData.resource_type_id)
              ? "d-flex justify-content-center"
              : ""
          }`}
        >
          {[1, 2].includes(cardData.resource_type_id) && (
            <NavLink
              className="card_btn"
              to={
                cardData.resource_type_id === 1
                  ? `/courses/document/${cardData.resource_id}`
                  : `/courses/video/${cardData.resource_id}`
              }
              state={{
                item: cardData,
                resourse:
                  cardData.resource_type_id === 1 ? "Document" : "Video",
                resourceData: [
                  {
                    id: 1,
                    name:
                      cardData.resource_type_id === 1 ? "Document" : "Video",
                  },
                ],
              }}
            >
              Open
            </NavLink>
          )}
          {cardData.resource_type_id === 4 && (
            <div>
              {cardData?.category?.name?.replace(/\s+/g, "").toLowerCase() ===
                "homeassignment" && (
                <HomeAssignmentStatusButton
                  id={cardData?.id}
                  examTemplateId={cardData?.exam_template_id}
                  status={cardData?.status}
                  endDate={cardData?.end_date}
                  resultDate={cardData?.result_date}
                  subjectName={cardData?.name}
                  attemptCount={cardData?.exam_attempt_count}
                />
              )}
              {cardData?.category?.name?.replace(/\s+/g, "").toLowerCase() !==
                "homeassignment" && (
                <MockTestButton
                  status={cardData.status}
                  id={cardData?.id}
                  startDate={cardData.start_date}
                  endDate={cardData?.end_date}
                  resultDate={cardData?.result_date}
                  examRetake={cardData?.exam_retakes}
                  examStatus={cardData?.exam_status}
                  examTemplateId={cardData.exam_template_id}
                  categoryExamName={cardData?.category?.name?.toLowerCase()}
                  categoryExamRetake={cardData?.category?.exam_retake}
                  subjectName={cardData?.name}
                  onChangeOMR={(e) =>
                    uploadOMRFile(
                      e,
                      setSelectedFile,
                      setPreviewFile,
                      setIsModalOpen,
                      setToaster
                    )
                  }
                  onClickOMR={() => {
                    document.getElementById("file_upload").click();
                    localStorage.setItem(
                      CONSTANT.LOCAL_STORAGE.OMR_EXAM_ID,
                      cardData?.id
                    );
                  }}
                  totalQuestions={cardData?.total_questions}
                  examName={cardData?.name}
                  questionLimit={Number(
                    cardData?.exam_template_structure
                      ? Number(cardData?.exam_template_structure[0]?.limit)
                      : undefined
                  )}
                  examTemplateUIId={cardData?.category.template.id}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div>
        <OMRFilePreviewModal
          isModalOpenOMR={isModalOpen}
          setIsModalOpenOMR={setIsModalOpen}
          selectedOMRFile={selectedFile}
          pageNumber={pageNumber}
          onDocumentLoadSuccess={onDocumentLoadSuccess}
          previewOMRFile={previewFile}
          handleUploadOMR={handleUploadOMR}
          studentId={studentId}
        />
      </div>
    </div>
  );
};

export default SearchCard;
