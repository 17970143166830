import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import crossIcon from "../../../assets/images/cross.svg";
import { AppContext } from "../../shared/AppContext";
import {
  useExamInstructions,
  useSetTest,
  useConfigureSlots,
} from "../../../hooks/hookList";
import Button from "../../utils/forms/Button";
import SlotSelection from "./SlotSelection";
import Table from "../../utils/table/Table";
import BasicInstructions from "./BasicInstructions";
import "./numericKeypad.scss";
import Image from "../../utils/image/Image";

const ExamInstructions = () => {
  const { setToaster, setSpinner } = useContext(AppContext);
  const { exam_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [termAndCond, setTermAndCond] = useState("");
  const [examInstructionData, setExamInstructionData] = useState();
  const [examTemplateSlots, setExamTemplateSlots] = useState([]);
  const [examTemplateStructure, setTemplateStructure] = useState([]);
  const [configureSlots, setConfigureSlots] = useState(false);
  const [configureSlotButton, setConfigureSlotButton] = useState(true);
  const [slotDetails, setSlotDetails] = useState(1);
  const [slotTableList, setSlotTableList] = useState([]);

  const { getData: getExamInstruction } = useExamInstructions(
    setSpinner,
    setToaster
  );

  const { getData: setTest } = useSetTest(setSpinner, setToaster);

  const { getData: getConfigureSlots } = useConfigureSlots(
    setSpinner,
    setToaster
  );
  const examName = JSON.parse(localStorage.getItem("testName"))?.subject;

  const examInstructionCallback = (instruction) => {
    setExamInstructionData(instruction?.data?.template);
    if (instruction?.data?.template?.slots === "yes") {
      if (instruction?.data?.template?.exam_template_slot[0]?.slot_type === 0)
        setConfigureSlotButton(false);
      if (instruction?.data?.slots?.length > 0) {
        setSlotTableList(instruction?.data?.slots);
      }
      setExamTemplateSlots(instruction?.data?.slots);
      if (instruction?.data?.slots.length > 0) setConfigureSlotButton(false);
      setTemplateStructure(instruction?.data?.template?.template_structures);
      setSlotDetails(instruction?.data?.template?.exam_template_slot[0]);
    } else {
      setConfigureSlotButton(false);
    }
  };
  const tempId = localStorage.getItem("tempId");

  const isSectionWiseLimit = examInstructionData?.template_structures?.every(
    (d) => Number(d?.section_wise_limit) !== 0
  );
  const isQuestionLimit = examInstructionData?.template_structures?.every(
    (d) => Number(d?.limit) !== 0
  );
  const isNegativeMarking = examInstructionData?.template_structures?.some(
    (d) => Number(d?.negative_marks) !== 0
  );
  const instructionTableData = examInstructionData?.template_structures?.map(
    (d, i) => {
      const data = [
        {
          value: i + 1,
          id: 1,
        },
        {
          value: d?.subject[0]?.name,
          id: 2,
        },
        {
          value: d?.total_questions,
          id: 3,
        },
        {
          value: "+" + "" + d.correct_marks,
          id: 4,
        },
      ];

      if (String(d?.partial_marking)?.toLowerCase()?.trim() !== "No") {
        data.push({
          value:
            Number(d?.negative_marks) === 0
              ? d?.negative_marks
              : "-" + "" + d?.negative_marks,
          id: 5,
        });
      }

      if (Number(d?.section_wise_limit) !== 0) {
        data.push({
          value: d?.duration + "" + "mins",
          id: 6,
        });
      }

      if (Number(d?.limit) !== 0) {
        data.push({
          value: d?.limit,
          id: 8,
        });
      }

      return data;
    }
  );

  const setTestInstructionHandler = async (e) => {
    if (!termAndCond) {
      setToaster({
        show: true,
        type: "danger",
        content: "Please accept and check instructions.",
      });
    } else {
      if (location.state.examTemplateUIId === 6) {
        const slots = [];
        examInstructionData.template_structures
          .sort((a, b) => a.id - b.id)
          .forEach((d, i, arr) => {
            if (i + 1 !== arr.length) {
              slots.push({
                slot_no: 1,
                exam_template_structure_id: d.id,
                status: 1,
              });
            } else {
              slots.push({
                slot_no: 2,
                exam_template_structure_id: d.id,
                status: 1,
              });
            }
          });
        const params = {
          exam_id,
          pattern_data: slots,
        };
        await getConfigureSlots(params);
      }

      await setTest({ exam_id }, (data, status) => {
        if (status >= 400 && status <= 500) {
          setToaster({
            show: true,
            type: "danger",
            content: "Something went wrong, please try again",
          });
        } else {
          if (location.state.examTemplateUIId === 5) {
            navigate(`/examPortal/${exam_id}/exams`);
          } else if (location.state.examTemplateUIId === 6) {
            navigate(`/examPortal/xat/${exam_id}/exams`);
          } else {
            navigate(`/examPortal/template/${exam_id}/exams`);
          }
        }
      });
    }
  };

  const closeModal = () => {
    setConfigureSlots(false);
    setConfigureSlotButton(false);
  };

  useEffect(() => {
    getExamInstruction(
      {
        id: tempId,
        exam_id: exam_id,
      },
      (data) => examInstructionCallback(data)
    );
  }, []);

  return (
    <div className="exam-instructions h-100">
      <div className="white-box h-100">
        <div className="dash-heading">
          <h2 className="themeLink pb-2 exam_instruction_heading">
            XAT - {examName}
          </h2>
        </div>
        <BasicInstructions name={examName} />
        <div className="instruction">
          {slotTableList.length > 0 && (
            <div className="data-table">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">Slots</th>
                    <th className="text-center">Sections</th>
                  </tr>
                </thead>
                <tbody>
                  {slotTableList.map((d, i, arr) => {
                    // Check if it's the first row of the slot
                    const isSlotStart =
                      i === 0 || arr[i - 1].slot_no !== d.slot_no;

                    return (
                      <tr key={i}>
                        {isSlotStart && (
                          <td
                            rowSpan={
                              arr.filter((item) => item.slot_no === d.slot_no)
                                .length
                            }
                            className="text-center vertical_middle"
                          >
                            <strong>Slot {d.slot_no}</strong>
                          </td>
                        )}
                        <td className="text-center">{d.subject_name}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}

          <div
            className="question mcq-questions fs-2"
            dangerouslySetInnerHTML={{
              __html: examInstructionData?.instructions,
            }}
          ></div>
        </div>
        <div className="paper_detail w-100" style={{ paddingRight: "3rem" }}>
          <Table
            heading={[
              {
                value: "S.No",
                id: 1,
              },
              {
                value: "Section",
                id: 2,
              },
              {
                value: "Total Questions",
                id: 3,
              },
              {
                value: "Correct Marks",
                id: 4,
              },
              {
                value: "Negative Marks",
                id: 5,
              },
              {
                value: isSectionWiseLimit ? "Duration" : "",
                id: 6,
              },

              {
                value: isQuestionLimit ? "Question Limit" : "",
                id: 8,
              },
              ,
            ]}
            rows={instructionTableData}
          />
        </div>

        <div className="nav-qa">
          <div className="accept-condition pt-4">
            <div className="input-text pt-2">
              <span htmlFor="termBox" className="ps-3 gray-text d-flex">
                <div className="form-group radio-group position-relative">
                  <div className="input-box">
                    <label>
                      <input
                        type="checkBox"
                        name="termBox"
                        id="termBox"
                        className={`text-input me-3`}
                        value={termAndCond}
                        onChange={(e) => setTermAndCond(e.target.checked)}
                      />
                      <span className="fs-4">
                        I have read the instructions.
                      </span>
                    </label>
                  </div>
                </div>
              </span>
            </div>
          </div>
          {configureSlotButton ? (
            <div className="w-100">
              <Button
                title="Configure Slots"
                type="button"
                className="proceed small-btn theme-btn btn"
                onClick={() => {
                  setConfigureSlots(true);
                }}
              />
            </div>
          ) : (
            <div className="exam_instructions_container">
              <Button
                title="Proceed"
                type="button"
                className="proceed btn theme-btn btn exam-instruction-proceed-btn"
                onClick={setTestInstructionHandler}
              />
            </div>
          )}

          <Modal
            dialogClassName="slots_modal"
            className="configure_slot_modal"
            size="md"
            show={configureSlots}
            centered
          >
            <Modal.Header>
              <Modal.Title className="fs-2 lh-base">
                Configure Slots
              </Modal.Title>
              <span className="close-btn float-end " role="button">
                <Image
                  src={crossIcon}
                  alt="cancel"
                  onClick={() => setConfigureSlots(false)}
                />
              </span>
            </Modal.Header>

            <div>
              <h3 className="fs-4 lh-base">
                Drag the subjects into the slots. If an error occurs, close the
                popup and reselect the items.
              </h3>
              <h4>
                Minimum Section Allowed per slot -{" "}
                {examInstructionData &&
                  examInstructionData.exam_template_slot &&
                  examInstructionData.exam_template_slot.length > 0 &&
                  examInstructionData?.exam_template_slot[0]?.min_section}
              </h4>
              <h4>
                Maximum Section Allowed per slot -{" "}
                {examInstructionData &&
                  examInstructionData.exam_template_slot &&
                  examInstructionData?.exam_template_slot.length > 0 &&
                  examInstructionData.exam_template_slot[0]?.max_section}
              </h4>
              <SlotSelection
                examTemplateStructure={examTemplateStructure}
                examId={exam_id}
                slotDetails={slotDetails}
                closeModal={closeModal}
              />
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ExamInstructions;
