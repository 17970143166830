import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm } from "@kaydhiman/react-hook-useform";
import { AppContext } from "../../../shared/AppContext";
import Button from "../../../utils/forms/Button";
import InputField from "../../../utils/forms/InputField";
import SelectInput from "../../../utils/forms/SelectInput";
import BackButton from "../../../utils/backBtn/BackButton";

import "react-bootstrap-typeahead/css/Typeahead.css";
import Auth from "../../../../auth/Auth";
import {
  useCampusList,
  useCollegeCourseCategory,
  useUniversitiesList,
  useUpdateWallet,
  useWalletDetails,
} from "../../../../hooks/hookList";

const WalletUpdate = () => {
  const { values, setInitialValues, bindField, isValid } = useForm({
    validations: {},
  });
  const { wallet_id } = useParams();
  const { setSpinner, setToaster } = useContext(AppContext);

  // Get Universities List
  const { getData: getUniversities, data: universities } = useUniversitiesList(
    setSpinner,
    setToaster
  );

  // Get College Course Category
  const { getData: getCollegeCourseCategory, data: collegeCourseCategory } =
    useCollegeCourseCategory(setSpinner, setToaster);

  const { getData: getCampusList, data: campuses } = useCampusList(
    setSpinner,
    setToaster
  );

  // Get Wallet Details
  const { getData: getWalletDetail } = useWalletDetails(setSpinner, setToaster);

  // Update Wallet Details
  const { getData: updateWallet } = useUpdateWallet(setSpinner, setToaster);

  const [selectedCourses, setSelectedCourses] = useState([]);

  const campusParams = {
    university_id: values?.university_id,
  };

  const walletDetailsCallback = (walletDetails) => {
    if (!walletDetails?.data || !Array.isArray(walletDetails.data)) {
      return; // Handle the case where data is not available or not an array
    }

    for (const walletData of walletDetails.data) {
      const {
        university,
        campus,
        category,
        username,
        password,
        exam_status,
        wallet_courses,
      } = walletData ?? {};

      setInitialValues((prev) => ({
        ...prev,
        university_id: university?.id ?? prev.university_id,
        university: university?.name ?? prev.university,
        campus: campus?.name ?? prev.campus,
        campus_id: campus?.id ?? prev.campus_id,
        category: category ?? prev.category,
        username: username ?? prev.username,
        password: password ?? prev.password,
        examStatusId: exam_status ?? prev.exam_status,
      }));

      setSelectedCourses(
        (prevSelectedCourses) =>
          wallet_courses?.map((itm) => itm?.courses) ?? prevSelectedCourses
      );
    }
  };

  const updateWalletDetails = async (e) => {
    e.preventDefault();
    let course = selectedCourses?.map((item) => ({ course_id: item?.id }));
    let formData = new FormData();
    formData.append("student_id", Auth?.user().user_id);
    formData.append("university_id", Number(values?.university_id));
    formData.append("campus_id", Number(values?.campus_id));
    // formData.append("courses", course)
    formData.append("category", values?.category);
    formData.append("username", values?.username);
    formData.append("password", values?.password);
    formData.append("exam_status", Number(values?.examStatusId));
    formData.append("score", values?.score);
    formData.append("rank", values?.rank);
    formData.append("remarks", values?.remarks);
    if (course?.length) {
      course.forEach((batch, index) =>
        formData.append(`courses[${index}][course_id]`, batch?.course_id)
      );
    }
    const params = {
      id: wallet_id,
      body: formData,
    };
    await updateWallet(params);
  };

  useEffect(() => {
    getUniversities();
    getWalletDetail({ wallet_id }, walletDetailsCallback);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (values?.university_id) {
      getCampusList(campusParams);
    }
    if (values?.campus_id !== undefined) {
      const params = {
        campus_id: values.campus_id,
      };
      getCollegeCourseCategory(params);
    }
    //eslint-disable-next-line
  }, [values?.university_id, values.campus_id]);

  return (
    <>
      <div className="white-box mb-5 adminForm">
        <div className="dash-heading">
          <h1 className="">
            <BackButton />
            Update Result
          </h1>
        </div>
        <form className="action-details" onSubmit={updateWalletDetails}>
          <div className="row">
            <div className="col-lg-4 col-md-6 pe-xxl-5">
              <SelectInput
                label="University"
                id="university"
                name="university_id"
                data={universities}
                setInitialValues={setInitialValues}
                values={values}
                title="Select University"
              />
            </div>
            <div className="col-lg-4 col-md-6 pe-xxl-5">
              <SelectInput
                label="Campus"
                id="campus"
                name="campus_id"
                data={campuses}
                setInitialValues={setInitialValues}
                values={values}
                title="Select Campus"
                // required
              />
            </div>
            <div className="col-lg-4 col-md-6 pe-xxl-5">
              <InputField
                type="text"
                label="Username/Email/Reg No"
                bindField={bindField}
                name="username"
                value={values.username}
                placeholder="Enter username/email"
              />
            </div>
            <div className="col-lg-4 col-md-6 pe-xxl-5">
              <InputField
                type="password"
                label="Password"
                bindField={bindField}
                name="password"
                value={values?.password}
                placeholder="Enter Password"
                required
              />
            </div>
            <div className="col-lg-4 col-md-6 pe-xxl-5  mb-5">
              {/* <SelectInput
                label="Applied Courses"
                id="campus"
                name="campus_id"
                data={[]}
                setInitialValues={setInitialValues}
                values={values}
                title="Select Campus"
              // required
              /> */}

              <label>Applied Courses</label>
              <Typeahead
                className="text-input"
                id="public-methods-example"
                labelKey="name"
                multiple
                options={collegeCourseCategory}
                placeholder="Choose a state..."
                selected={selectedCourses}
                onChange={(e) => setSelectedCourses(e)}
              />
            </div>

            <div className="col-lg-3 col-md-6 pe-xxl-5">
              <SelectInput
                label="Exam Status"
                id="examStatus"
                name="examStatusId"
                data={[
                  { name: "Selected", id: "1" },
                  { name: "Not selected", id: "0" },
                ]}
                setInitialValues={setInitialValues}
                values={values}
                title="Select Campus"
                required
              />
            </div>
            {values?.examStatus === "Selected" && (
              <>
                <div className="col-lg-3 col-md-6 pe-xxl-5 mb-5">
                  <InputField
                    type="number"
                    label="Score"
                    bindField={bindField}
                    name="score"
                    value={values?.score}
                    placeholder="Enter Score"
                    required
                    smallLabel={"(Use N/A in case score not available)"}
                  />
                </div>
                <div className="col-lg-3 col-md-6 pe-xxl-5  mb-5 ">
                  <InputField
                    type="number"
                    label="Rank"
                    bindField={bindField}
                    name="rank"
                    value={values?.rank}
                    placeholder="Enter Ranke"
                    required
                    smallLabel={"(Use N/A in case score not available)"}
                  />
                </div>
                <div className="col-lg-3 col-md-6 pe-xxl-5  mb-5">
                  <label>
                    Upload Result <span className="pink-text"> *</span>
                  </label>
                  <label className="upload_fil">
                    <input
                      type="file"
                      id="actual-btn"
                      className="mb-2"
                      name="localIdentity"
                      accept=".jpg,.png,.pdf"
                      // onChange={(e) => {
                      //   fileChangehandle(e, "UNITY_ASSET");
                      // }}
                    />
                    <label className="upload-btn pointer" htmlFor="actual-btn">
                      <span className="btn">Browse</span>
                    </label>
                    <span className="small-label">
                      (Supported Formats: JPG, PNG, PDF)
                    </span>
                  </label>
                </div>
              </>
            )}

            <div className="col-md-12">
              <label>Remarks</label>
              <textarea
                rows="5"
                wrap="soft"
                placeholder="Enter Remarks"
                className="from-teaxtarea"
                {...bindField("remarks")}
                name="remarks"
                value={values?.remarks}
              ></textarea>
            </div>
          </div>
          <div className="col-md-12 button-group btn-data">
            <Button
              className="proceed btn small-btn theme-btn"
              title="Update"
              // disable={!isValid()}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default WalletUpdate;
