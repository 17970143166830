// QuestionComponent.jsx
import React from "react";
import "react-simple-keyboard/build/css/index.css";
import Keyboard from "react-simple-keyboard";
import NumericKeypad from "./NumericKeypad";

const Question = ({
  questionData,
  currentQuestionIndex,
  questionType,
  handleOptionChange,
  isOptionSelected,
  keyboardRef,
  handleOneWordAnswer,
  handleOneWordInputChange,
  handleNumericAnswer,
  numericAnswer,
  handleSubjectiveAnswer,
  subjectiveAnswer,
}) => {
  const isSubjectiveAnswered =
    subjectiveAnswer !== null ? subjectiveAnswer : "";
  const isNumericAnswered = numericAnswer !== null ? numericAnswer : "";
  const isOneWordAnswered =
    keyboardRef?.current?.getInput() !== null &&
    keyboardRef?.current?.getInput();

  return (
    <div className="sub-report">
      {questionData?.length > 0 && (
        <div className="question-dash">
          <div
            className={`dash-heading question-head mock_comp ${
              questionData[currentQuestionIndex]?.comprehension_id !== null
                ? "comp-test"
                : ""
            }`}
          >
            {questionData[currentQuestionIndex]?.comprehension_id !== null && (
              <div className="comp-questions ">
                <h4 className="comp-questions-heading">
                  Comprehension for Questions{" "}
                </h4>

                <div className="comp-questions-cont">
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        questionData[currentQuestionIndex]?.comprehension[0]
                          ?.name,
                    }}
                  ></p>
                </div>
              </div>
            )}

            <h3 className="question_count">
              Question {currentQuestionIndex + 1}
            </h3>
            <div className="btn question-type">
              {questionType(
                questionData[currentQuestionIndex]?.question_type_id
              )}
            </div>
          </div>
          <div
            className="question exam_questions"
            dangerouslySetInnerHTML={{
              __html: questionData[currentQuestionIndex]?.question,
            }}
          ></div>
          <div className="awns_text single_answers_type">
            <ul className="mcq-awns">
              {![3, 4, 5].includes(
                questionData[currentQuestionIndex]?.question_type_id
              ) &&
                questionData[currentQuestionIndex]?.options?.map((awnData) => {
                  let option = (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: awnData.option,
                      }}
                    ></div>
                  );
                  return (
                    <li key={awnData.id}>
                      <div className="form-group radio-group position-relative">
                        <div className="input-box">
                          <input
                            className="d-none text-input custon-radio"
                            type={
                              questionData[currentQuestionIndex]
                                ?.question_type_id === 2 &&
                              questionData[
                                currentQuestionIndex
                              ]?.options?.filter((a) => a?.is_correct === 1)
                                ?.length > 1
                                ? "checkbox"
                                : "radio"
                            }
                            name={`awn_${
                              questionData[currentQuestionIndex]
                                ?.question_type_id === 2
                                ? `checkbox_${awnData?.id}`
                                : `radio_${questionData[currentQuestionIndex]?.id}`
                            }`}
                            value={awnData.id}
                            id={awnData.id}
                            onChange={(e) =>
                              handleOptionChange(e, awnData.question_id)
                            }
                            checked={isOptionSelected(
                              awnData.question_id,
                              awnData.id
                            )}
                          />
                          <label htmlFor={awnData.id}>{option}</label>
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
          {questionData[currentQuestionIndex]?.question_type_id === 4 && (
            <div className="awn-keyboard">
              <input
                value={isOneWordAnswered || ""}
                onChange={handleOneWordInputChange}
                placeholder={"Enter your answer"}
                readOnly
              />
              <Keyboard
                keyboardRef={(r) => (keyboardRef.current = r)}
                onChange={(value) => {
                  handleOneWordAnswer(
                    value,
                    questionData[currentQuestionIndex]?.options[0]?.question_id
                  );
                }}
                value={keyboardRef?.current?.getInput()}
              />
            </div>
          )}
          {questionData[currentQuestionIndex]?.question_type_id === 3 && (
            <>
              {/* <div className="numeric-keyboard">
                <NumPad.Number
                  onChange={(value) =>
                    handleNumericAnswer(
                      value,
                      questionData[currentQuestionIndex]?.options[0]
                        ?.question_id
                    )
                  }
                  label="Input Answer"
                  placeholder="Enter Your Answer"
                  decimal={2}
                  value={
                    (numericAnswer !== null && numericAnswer) ||
                    (inputAnswer !== null && inputAnswer) ||
                    ""
                  }
                />
              </div> */}
              <div className="numeric_input_field_container">
                <input
                  className="w-100 numeric_input_field"
                  type="text"
                  value={isNumericAnswered}
                  readOnly
                  placeholder="Enter Your Answer"
                />
                <NumericKeypad onKeyPress={handleNumericAnswer} />
              </div>
            </>
          )}
          {questionData[currentQuestionIndex]?.question_type_id === 5 && (
            <div>
              <textarea
                onChange={(e) =>
                  handleSubjectiveAnswer(
                    e?.target?.value,
                    questionData[currentQuestionIndex]?.options[0]?.question_id
                  )
                }
                value={isSubjectiveAnswered}
                className="subjective_answer_input"
                placeholder="Type Your Answer here"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Question;
