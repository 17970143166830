import React from "react";
import { Link } from "react-router-dom";
import Image from "../../utils/image/Image";

const SidebarLogo = ({ to, src, className, alt, onClick }) => {
  return (
    <Link to={to}>
      <Image className={className} src={src} alt={alt} onClick={onClick} />
    </Link>
  );
};

export default SidebarLogo;
