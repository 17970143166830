import React, { useContext, useState } from "react";
import OtpInput from "react-otp-input";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigationType,
} from "react-router-dom";
import { useForm } from "@kaydhiman/react-hook-useform";

import Button from "../utils/forms/Button";
import OnboardingLayout from "./OnboardingLayout";

import arrow from "../../assets/images/top_aerrow.svg";
import verify from "../../assets/images/verfy_otp.png";
import { AppContext } from "../shared/AppContext";
import { userService } from "../../_services/User.services";
import Image from "../utils/image/Image";

export default function VerfyCode() {
  const { values, bindField, isValid, setInitialValues } = useForm({
    validations: {
      user_id: {
        required: {},
      },
    },
  });

  const [otp, setOtp] = useState("");
  const { setSpinner, setToaster } = useContext(AppContext);
  const route = useNavigate();

  const location = useLocation();

  // api function
  const verifyOTP = async (e) => {
    e.preventDefault();

    setSpinner(true);
    try {
      let body = {
        user_id: location?.state?.user_id,
        otp: otp,
      };
      const response = await userService.verifyOTP(body);

      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        // route("/newpassword");
        route("/newpassword", {
          state: { user_id: location?.state?.user_id, otp: otp },
        });
        setToaster({
          show: true,
          type: "success",
          content: data.message,
        });

        setSpinner(false);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();

        if (data.errors) {
          let error = Object.keys(data.errors)?.reverse();

          for (let i = 0; i <= error.length; i++) {
            setToaster({
              show: true,
              type: "danger",
              content: data.errors[error[i]][0],
            });
          }
        } else {
          setToaster({
            show: true,
            type: "danger",
            content: data.message,
          });
        }
        setSpinner(false);
      }
    } catch (error) {
      setSpinner(false);
    }
    setSpinner(false);
    // api funstion
  };
  // api end function
  return (
    <>
      <OnboardingLayout
        Form={
          <>
            <div className="dash-heading">
              <h1>
                <span className="back_icon">
                  <Link to="/forgotpassword">
                    <Image src={arrow} alt="backImage" />
                  </Link>
                </span>
                Verification Code
              </h1>
              <p className="sub_heading mb-4">
                Please enter your six digits code sent to your registered phone
                number.
              </p>
            </div>

            <form onSubmit={verifyOTP} className="otp-from">
              <OtpInput
                className="mb-4 w-100"
                value={otp}
                onChange={(codesms) => setOtp(codesms)}
                numInputs={6}
                separator={<span style={{ width: "8px" }}></span>}
                isInputNum={true}
                shouldAutoFocus={true}
                inputStyle={{
                  borderRadius: "8px",
                  width: "54px",
                  height: "54px",
                  fontSize: "12px",
                  color: "#000",
                  fontWeight: "400",
                  caretColor: "blue",
                  border: "1px solid #CFD3DB",
                  backgroundColor: "#F8FAFF",
                }}
                focusStyle={{
                  border: "1px solid #CFD3DB",
                  outline: "none",
                }}
              />
              <Button
                type="submit"
                className="loginBtn"
                id="logBtn"
                title="Submit"
                disable={otp ? false : true}
              />
            </form>
          </>
        }
        image={verify}
      />
    </>
  );
}
