import React from "react";

const ComprehensiveQuestionContainer = ({ comprehension }) => {
  return (
    <div className="mt-4 question-box">
      <div
        className="question"
        dangerouslySetInnerHTML={{
          __html: comprehension,
        }}
      ></div>
    </div>
  );
};

export default ComprehensiveQuestionContainer;
