import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TestRightSidebar from "../../projectDashboard/portalLayout/TestRightSidebar";
import { NewBackButton } from "../backBtn/NewBackButton";
import { useBreadcrumb } from "../../shared/breadcrumb-context";
import Auth from "../../../auth/Auth";
import { useContext } from "react";
import { AppContext } from "../../shared/AppContext";
import Vimeo from "@vimeo/player";
import { useResourceTimeSpent } from "../../../hooks/hookList";
import VideoPlayer from "../videoPlayer/VideoPlayer";

const ResourceContainer = ({
  download,
  title,
  resourceName,
  path,
  handleCustomBack,
}) => {
  return (
    <div
      className={`mb-4 ${download && "d-flex justify-content-between"}
    `}
      title="Back to the previous page"
    >
      <h1 className="mb-3 exam_instruction_heading">
        <NewBackButton onClick={() => handleCustomBack()} />
        {title || resourceName}
      </h1>
      {download && (
        <div>
          <a
            className="btn theme-btn small-btn m-0"
            target="_blank"
            href={path}
            download
          >
            Download Document
          </a>
        </div>
      )}
    </div>
  );
};

const VideoResourceContainer = ({ videoTypeId, path, iframeRef }) => {
  let videoSrc = "";
  if (videoTypeId === 1) {
    videoSrc = `https://player.vimeo.com/video/${path}`;
  }

  if (videoTypeId === 2 && !path.includes("embed")) {
    videoSrc = path.replace("/watch?v=", "/embed/");
  } else {
    videoSrc = path;
  }

  if (videoTypeId === 3 && path?.startsWith("https://player.vimeo.com/video")) {
    videoSrc = path;
  }

  return (
    <div className="common_modal text-center">
      <VideoPlayer src={videoSrc} width="800" iframeRef={iframeRef} />
    </div>
  );
};

const VideoView = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { item, resourse, resourceData, url } = location.state;

  const { setSpinner, setToaster } = useContext(AppContext);
  const { dispatch } = useBreadcrumb();

  const [time, setTime] = useState(0); // Time in seconds
  const [isRunning, setIsRunning] = useState(false);
  const [videoSpeed, setVideoSpeed] = useState(1);

  const { getData: resourceTimeSpent } = useResourceTimeSpent(
    setSpinner,
    setToaster
  );

  const iframeRef = useRef(null);
  const timeSpentRef = useRef(0);
  const apiCalledRef = useRef(false); // Track API call
  const videoThresholdRef = useRef(0);

  const startTimer = () => {
    setIsRunning(true);
  };

  const pauseTimer = () => {
    setIsRunning(false);
  };

  const resetTimer = () => {
    setIsRunning(false);
    setTime(0);
  };

  const getResourceTimeSpent = async (time_spent) => {
    let params = {
      student_id: Auth?.user()?.id,
      resource_id: parseInt(id),
      resource_type_id: resourceData[0]?.id,
      time_spent,
      threshold:  videoThresholdRef.current,
    };

    await resourceTimeSpent(params, () => {
      resetTimer();
      startTimer();
    });
  };

  const handleCustomBack = () => {
    dispatch({ type: "REMOVE_BREADCRUMB", source: "custom" });
    navigate(url ? url : -1);
    // setScheduleDatePicker(location.state.date);
  };

  useEffect(() => {
    let timer;
    if (isRunning) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
        timeSpentRef.current = timeSpentRef.current + 1;
      }, 1000);
    }
    return () => clearInterval(timer); // Cleanup interval on unmount or pause
  }, [isRunning]);

  const fetchDurationVideo = async (player, playerRef) => {
    if (playerRef.current) {
      try {
        const seconds = await player.getDuration();
        const videoThresholdSeconds = Math.floor(seconds * 0.75);
        videoThresholdRef.current = videoThresholdSeconds
      } catch (error) {
        console.log("Error fetching video duration:", error);
      }
    }
  };

  useEffect(() => {
    const iframeElement = iframeRef.current; // Ensure iframeRef.current is available
    if (
      iframeElement &&
      iframeElement.src &&
      iframeElement.src.startsWith("https://player.vimeo.com/video/")
    ) {
      const player = new Vimeo(iframeElement); // Initialize Vimeo player

      fetchDurationVideo(player, iframeRef);
      player.on("play", () => {
        startTimer();
        if (!apiCalledRef.current) {
          apiCalledRef.current = true;
          getResourceTimeSpent(0);
        }
      });
      player.on("pause", () => pauseTimer());
      player.on("playbackratechange", (event) => {
        setVideoSpeed(event.playbackRate);
        getResourceTimeSpent(timeSpentRef.current);
      });

      return () => player.destroy(); // Proper cleanup
    }
  }, [iframeRef, item?.path]);

  useEffect(() => {
    if (time > 0 && time % 10 === 0) {
      getResourceTimeSpent(time * videoSpeed);
      timeSpentRef.current = timeSpentRef.current - time;
    }
  }, [time]);

  return (
    <div className="col-md-12 d-flex gap-4">
      <div className="white-box body-cont">
        <div className="doc-viewer">
          <ResourceContainer
            path={item?.path}
            title={item?.title}
            download={item?.downloadable}
            handleCustomBack={handleCustomBack}
            resourceName={item.resource_name}
          />

          <VideoResourceContainer
            path={item.path}
            videoTypeId={item?.video_type}
            iframeRef={iframeRef}
          />
        </div>
      </div>
      <div className="sidebar">
        <TestRightSidebar testDetail={2} resourse={resourse} />
      </div>
    </div>
  );
};

export default VideoView;
