import React, { useEffect, useContext, useState } from "react";
import { useForm } from "@kaydhiman/react-hook-useform";
import { AppContext } from "../../../shared/AppContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CONSTANT } from "../../../utils/constants";
import { useBreadcrumb } from "../../../shared/breadcrumb-context";
import { userService } from "../../../../_services/User.services";
import Auth from "../../../../auth/Auth";
import Button from "../../../utils/forms/Button";
import InputField from "../../../utils/forms/InputField";
import SelectInput from "../../../utils/forms/SelectInput";
import BackButton from "../../../utils/backBtn/BackButton";

const category = [
  "Gen",
  "SC",
  "ST",
  "OBC",
  "PWS",
  "EWS",
  "Defence Ward (If mentioned)",
];

const AddNewWallet = () => {
  const { values, setInitialValues, bindField, isValid } = useForm({
    validations: {
      category: {
        required: {},
      },
      username: {
        required: {},
      },
      password: {
        required: {},
      },
    },
  });

  const { setSpinner, setToaster } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [campuses, setCampuses] = useState([]);
  const [course, setCourse] = useState([]);
  const { dispatch } = useBreadcrumb();

  const getUniversities = async () => {
    try {
      const response = await userService?.getUniversity();
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();

        setUniversities(data?.data);

        setSpinner(false);
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setSpinner(false);
        setToaster({ show: true, type: "danger", content: data.message });
      }
    } catch (error) {}
  };

  const getCampus = async (id) => {
    setSpinner(true);
    try {
      const response = await userService?.getCampus({ university_id: id });
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();

        setCampuses(data?.data);
        setSpinner(false);
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setSpinner(false);
        setToaster({ show: true, type: "danger", content: data.message });
      }
    } catch (error) {}
  };

  //------------------api to get courses--------------
  const getCollegeCourseCategories = async (campus_id) => {
    setSpinner(true);

    try {
      const response = await userService.getCollegeCourseCategory({
        campus_id: campus_id,
      });
      setSpinner(false);
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();

        setCourse(data.data);
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();

        setToaster({
          show: true,
          type: "danger",
          content: data.message,
        });
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  //------------------api to get courses--------------
  const createWallet = async (e) => {
    setSpinner(true);
    e.preventDefault();
    try {
      let params = {
        student_id: Auth?.user().user_id,
        university_id: values?.university_id,
        campus_id: values?.campus_id,
        courses: selectedCourse,
        category: values?.category,
        username: values?.username,
        password: values?.password,
      };
      const response = await userService.addWallet(params);
      setSpinner(false);
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setInitialValues({});
        setSelectedCourse([]);
        setToaster({
          show: true,
          type: "success ",
          content: "New application details are  added successfully",
        });
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();

        if (data.errors) {
          let error = Object.keys(data.errors)?.reverse();

          for (let i = 0; i <= error.length; i++) {
            setToaster({
              show: true,
              type: "danger",
              content: data.errors[error[i]][0],
            });
          }
        } else {
          setToaster({ show: true, type: "danger", content: data.message });
        }
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  const handleCourse = (e, data) => {
    if (e.target.checked === false) {
      setSelectedCourse((prev) =>
        prev?.filter((fil) => fil?.course_id !== data?.id)
      );
    } else {
      setSelectedCourse((prev) => [...prev, { course_id: data.id }]);
    }
  };

  useEffect(() => {
    getUniversities();
    dispatch({
      type: "SET_BREADCRUMB",
      crumb: [{ title: "Add Form Wallet", url: "/forms/add_form_wallet" }],
    });
    setInitialValues({});
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (values?.university_id) {
      setInitialValues((prev) => ({ ...prev, campus_id: "", campus: "" }));
      getCampus(values?.university_id);
    }
    //eslint-disable-next-line
  }, [values?.university_id]);

  useEffect(() => {
    if (values?.campus_id) {
      getCollegeCourseCategories(values?.campus_id);
    }
  }, [values?.campus_id]);

  return (
    <>
      <div className="white-box mb-5 adminForm">
        <div className="dash-heading">
          <h1 className="">
            <BackButton /> Add New Application Details
          </h1>
        </div>
        <form className="action-details" onSubmit={createWallet}>
          <div className="row">
            <div className="col-lg-8 row">
              <div className="col-md-6 pe-xxl-5">
                <SelectInput
                  label="University"
                  id="university"
                  name="university_id"
                  data={universities}
                  setInitialValues={setInitialValues}
                  values={values}
                  title="Select University"
                />
              </div>
              <div className="col-md-6 pe-xxl-5">
                <SelectInput
                  label="Campus"
                  id="campus"
                  name="campus_id"
                  data={campuses}
                  setInitialValues={setInitialValues}
                  values={values}
                  title="Select Campus"
                  // required
                />
              </div>
              <div className="col-md-6 pe-xxl-5 mb-5">
                <InputField
                  type="text"
                  label="Username/Email/Reg No"
                  bindField={bindField}
                  name="username"
                  value={values?.username}
                  placeholder="Enter username/email"
                  required
                />
              </div>
              <div className="col-md-6 pe-xxl-5 mb-5">
                <InputField
                  type="password"
                  label="Password"
                  bindField={bindField}
                  name="password"
                  value={values?.password}
                  placeholder="Enter Password"
                  required
                />
              </div>

              <div className="col-md-12 cat-rdaios">
                <label>
                  Category Applied<span className="pink-text"> *</span>
                </label>
                <ul className="list-unstyled input-group ">
                  {category?.map((item, index) => {
                    // html character are visible as text "Rs.1500/- (General / EWS)&Nbsp;And&Nbsp;Rs.1,000/- (SC/ST)"
                    let name = "";
                    if (item === "SC") {
                      name = "Rs.1,000/- (SC/ST)";
                    }
                    if (item === "Gen") {
                      name = "Rs.1500/- (General / EWS)";
                    }
                    return (
                      <li>
                        <input
                          type="radio"
                          id={`cat-${index + 1}`}
                          name="categoryRadio"
                          className="text-input custon-radio d-none"
                          onChange={(e) =>
                            setInitialValues((prev) => ({
                              ...prev,
                              category: item,
                            }))
                          }
                        />
                        <label htmlFor={`cat-${index + 1}`} className="pointer">
                          {item}
                        </label>
                      </li>
                    );
                  })}

                  {/* <li>
                  <input
                    type="radio"
                    id="sec"
                    name="categoryRadio"
                    className="text-input custon-radio d-none"
                  />
                  <label htmlFor="sec" className="pointer">
                    SC
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="st"
                    name="categoryRadio"
                    className="text-input custon-radio d-none"
                  />
                  <label htmlFor="st" className="pointer">
                    ST
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="obc"
                    name="categoryRadio"
                    className="text-input custon-radio d-none"
                  />
                  <label htmlFor="obc" className="pointer">
                    OBC
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="pws"
                    name="categoryRadio"
                    className="text-input custon-radio d-none"
                  />
                  <label htmlFor="pws" className="pointer">
                    PWS
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="ews"
                    name="categoryRadio"
                    className="text-input custon-radio d-none"
                  />
                  <label htmlFor="ews" className="pointer">
                    EWS
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="d-ward"
                    name="categoryRadio"
                    className="text-input custon-radio d-none"
                  />
                  <label htmlFor="d-ward" className="pointer">
                    Defence Ward (If mentioned)
                  </label>
                </li> */}
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 pe-xxl-5 courses-checks">
              {course?.length > 0 && values?.campus_id && (
                <label htmlFor="">Applied College Course categories</label>
              )}
              <ul className="list-unstyled input-group ">
                {course?.length > 0 &&
                  values?.campus_id &&
                  course?.map((item, index) => {
                    return (
                      <li>
                        <div className="input-group align-items-center radio-group">
                          <input
                            type="checkbox"
                            id={`course-${index + 1}`}
                            name="course"
                            className="text-input custon-radio d-none custom-check"
                            onChange={(e) => {
                              handleCourse(e, item);
                            }}
                          />
                          <label
                            htmlFor={`course-${index + 1}`}
                            className="mb-0"
                          >
                            {item?.name}
                          </label>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="col-lg-4 col-sm-12  col-md-6 pe-xxl-5 button-group btn-data">
              <div className="col-6">
                <Button
                  className="proceed btn small-btn theme-btn w-100"
                  title="Save"
                  disable={!isValid()}
                />
              </div>

              <div className="col-6">
                <button
                  type="button"
                  className="btn border-btn small-btn ms-3 w-100 weight-500"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="notice mt-5">
          <p>
            Note : All students are requested to provide this information as it
            will aid us to track your results as well as Mocks being taken by
            you.
          </p>
          {/* <p>
              To update applied courses result please{" "}
              <Link to="" className="themeLink">
                Click Here
              </Link>
            </p> */}
        </div>
      </div>
    </>
  );
};

export default AddNewWallet;
