import React from "react";

const BasicInstructions = ({ name }) => {
  return (
    <div className="basic_instructions mt-3">
      <div>
        <h1 className="general_instructions">General Instructions:</h1>
      </div>
      <div>
        <ul>
          <li className="my-0 p-0">
            <p className="my-4 p-0">
              The clock will be set at the server. The countdown timer in the
              top right corner of screen will display the remaining time
              available for you to complete the examination. When the timer
              reaches zero, the examination will end by itself. You will not be
              required to end or submit your examination.
            </p>
          </li>
          <li className="my-0 p-0">
            <p className="my-4 p-0">
              The Questions Palette displayed on the right side of screen will
              show the status of each question using one of the following
              symbols:
            </p>
            <div className="my-3">
              <div className="d-flex align-items-center">
                <span className="">1.</span>
                <div
                  className="grayBox"
                  style={{
                    width: "30px",
                    minWidth: "30px",
                    height: "30px",
                    borderRadius: "3px",
                    marginLeft: "1rem",
                    fontSize: "1.4rem",
                  }}
                ></div>
                <span className="ms-3">
                  You have not visited the question yet.
                </span>
              </div>
              <div className="d-flex align-items-center my-3">
                <span className="">2.</span>
                <div
                  className="pinkBox"
                  style={{
                    width: "30px",
                    minWidth: "30px",
                    height: "30px",
                    borderRadius: "3px",
                    marginLeft: "1rem",
                    fontSize: "1.4rem",
                  }}
                ></div>
                <span className="ms-3">
                  You have not answered the question.
                </span>
              </div>
              <div className="d-flex align-items-center my-3">
                <span className="">3.</span>
                <div
                  className="greenBox"
                  style={{
                    width: "30px",
                    minWidth: "30px",
                    height: "30px",
                    borderRadius: "3px",
                    marginLeft: "1rem",
                    fontSize: "1.4rem",
                  }}
                ></div>
                <span className="ms-3">You have answered the question.</span>
              </div>
              {!name?.toLowerCase()?.includes("nmat") && (
                <div className="d-flex align-items-center my-3">
                  <span className="">4.</span>
                  <div
                    className="darkBlueBox"
                    style={{
                      width: "30px",
                      minWidth: "30px",
                      height: "30px",
                      borderRadius: "3px",
                      marginLeft: "1rem",
                      fontSize: "1.4rem",
                    }}
                  ></div>
                  <span className="ms-3">
                    You have NOT answered the question, but have marked the
                    question for review
                  </span>
                </div>
              )}
              {!name?.toLowerCase()?.includes("nmat") && (
                <div className="d-flex align-items-center my-3">
                  <span className="">5.</span>
                  <div
                    className="yellowBox"
                    style={{
                      width: "30px",
                      minWidth: "30px",
                      height: "30px",
                      borderRadius: "3px",
                      marginLeft: "1rem",
                      fontSize: "1.4rem",
                    }}
                  ></div>
                  <span className="ms-3">
                    The question(s) "Answered and Marked for Review" will be
                    considered for evaluation
                  </span>
                </div>
              )}
            </div>
          </li>
          <li className="my-0 p-0">
            <p className="my-4 p-0">
              In mobile devices, you can click on the "Palette" button which
              appears to the right of the screen to view the question palette.
            </p>
          </li>
        </ul>
      </div>
      <div className="my-3">
        <h1 className="general_instructions">Navigating to a Question:</h1>
        <ul>
          <li className="my-0 p-0">
            <p>To answer a question, do the following:</p>
            <div>
              <p className="my-4 p-0">
                1. Click on the question number in the Question Palette at the
                right of your screen to go to that numbered question directly.
                Or click on 'Next' or 'Previous' button to move from one
                question to another.
                <span>
                  Note that using this option does NOT save your answer to the
                  current question.
                </span>
              </p>
            </div>
            <div>
              <p className="my-4 p-0">
                2. Click on Save & Next to save your answer for the current
                question and then go to the next question.
              </p>
            </div>
            {!name.toLowerCase()?.includes("nmat") && (
              <div>
                <p className="my-4 p-0">
                  3. Click on Mark for Review & Next to save your answer for the
                  current question, mark it for review, and then go to the next
                  question.
                </p>
              </div>
            )}
          </li>
        </ul>
      </div>
      {!name?.toLowerCase()?.includes("nmat") && (
        <div className="">
          <h1 className="general_instructions">Answering a Question:</h1>
          <ul>
            <li className="my-0 p-0">
              <p className="my-4 p-0">
                Procedure for answering a multiple choice type question:
              </p>
              <div>
                <p className="my-4 p-0">
                  1. To select you answer, click on the button of one of the
                  options.
                </p>
                <p className="my-4 p-0">
                  2. To deselect your chosen answer, click on the button of the
                  chosen option again or click on the Clear button
                </p>
                <p className="my-4 p-0">
                  3. To change your chosen answer, click on the button of
                  another option
                </p>
                <p className="my-4 p-0">
                  4. To save your answer, you MUST click on the Save & Next
                  button or Mark for Review & Next button.
                </p>
                <p className="my-4 p-0">
                  5. To mark the question for review, click on the Mark for
                  Review & Next button or Mark for Review button.
                </p>
                <p className="my-4 p-0">
                  6. To change your answer to a question that has already been
                  answered, first select that question for answering and then
                  follow the procedure for answering that type of question.
                </p>
              </div>
            </li>
          </ul>
        </div>
      )}
      {!name.toLowerCase()?.includes("nmat") && (
        <div className="my-3">
          <h1 className="general_instructions">Navigating through sections:</h1>
          <ul>
            <li className="my-0 p-0">
              <p className="my-4 p-0">
                Sections in this question paper are displayed on the top bar of
                the screen. Questions in a section can be viewed by click on the
                section name. The section you are currently viewing is
                highlighted.
              </p>
            </li>
            <li className="my-0 p-0">
              <p className="my-4 p-0">
                After click the Save & Next button on the last question for a
                section, you will automatically be taken to the first question
                of the next section.
              </p>
            </li>
            <li className="my-0 p-0">
              <p className="my-4 p-0">
                You can shuffle between sections and questions during the
                examination as per the sectional guidelines during the time
                stipulated.
              </p>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default BasicInstructions;
