import React from "react";
import OrangeDots from "../../../assets/images/orange-dots.png";
import WhiteChevronRight from "../../../assets/images/white-chevron-right.png";
import Image from "../../utils/image/Image";

const CourseDescription = ({
  isOpenDescription,
  courseId,
  description,
  toggleDescription,
}) => {
  return (
    <div className="position-relative" style={{ padding: "0 16px" }}>
      {isOpenDescription !== courseId && (
        <>
          <div className="content_description">
            <span className="p-0 m-0">
              {description?.split("").slice(0, 62).join("")}
            </span>
            {description.split("").length > 61 && (
              <span
                className="orange_ellipis_container"
                onClick={() => toggleDescription(courseId)}
              >
                {/* <Image
                // className="orange_ellipis"
                src={WhiteChevronRight}
                alt="orange-dots"
              /> */}
                <div
                  style={{
                    width: "3px",
                    height: "3px",
                    borderRadius: "50%",
                    background: "#fff",
                    marginRight: "2px",
                    marginLeft: "2px",
                  }}
                ></div>
                <div
                  style={{
                    width: "3px",
                    height: "3px",
                    borderRadius: "50%",
                    background: "#fff",
                    marginRight: "2px",
                  }}
                ></div>
                <div
                  style={{
                    width: "3px",
                    height: "3px",
                    borderRadius: "50%",
                    background: "#fff",
                    marginRight: "2px",
                  }}
                ></div>
              </span>
            )}
          </div>
        </>
      )}
      {isOpenDescription === courseId && (
        <div
          className={"text-center"}
          style={{ marginTop: "10px", height: "200px", overflow: "auto" }}
        >
          {description}
        </div>
      )}
    </div>
  );
};

export default CourseDescription;
