import React, { useContext } from "react";
import { AppContext } from "../../shared/AppContext";

export default function BatchCard({ onClick }) {
  const { userData } = useContext(AppContext);

  return (
    <ul className="marks_list w-100 ps-0 mb-0">
      {userData?.batches?.map((batchData, index) => {
        return (
          <div key={index}>
            <li className="w-100 list-unstyled border-bottom-dashed mb-4 pb-4">
              <span className="float-starting">Allotted Batch : </span>
              <span className="float-end" style={{ fontWeight: 400 }}>
                {batchData?.name}
              </span>
            </li>
            {/* <li className="w-100 list-unstyled border-bottom-dashed mb-4 pb-4"> */}
            {/* <span className="float-starting">Days & Time : </span> */}
            {/* <span className="float-end"> */}

            {/* {batchData.day},&nbsp;{batchData.time}
                hrs */}

            {/* {batchData?.days_and_timing} */}
            {/* {batchData?.days_and_timing.split(",")?.map((a) => {
                  return (
                    a
                  )
                })} */}
            {/* </span> */}
            {/* </li> */}
            <li className="w-100 list-unstyled mb-2 ">
              <span className="float-starting">Center Allotted :</span>
              <span className="float-end" style={{ fontWeight: 400 }}>
                {batchData?.parent_batch?.name}
              </span>
            </li>
            {/* <li className="w-100 list-unstyled mb-0 ">
              <a className="btn theme-btn small-btn" href={`http://psis.prathamedu.com/student/id-card/download/${Auth?.user()?.user_id}`}>Download ID Card</a>
            </li> */}
          </div>
        );
      })}
    </ul>
  );
}
