import React, { useContext } from "react";

import Button from "../utils/forms/Button";
import InputField from "../utils/forms/InputField";
import OnboardingLayout from "./OnboardingLayout";

import { useForm } from "@kaydhiman/react-hook-useform";

// import lock from "../../assets/images/lock.svg";
import hidePass from "../../assets/images/pass_view-svg.png";
import showPass from "../../assets/images/eye-svg.png";
import reset_pass from "../../assets/images/reset_password.png";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../shared/AppContext";
import { userService } from "../../_services/User.services";
import { useState } from "react";

export default function NewPassword() {
  const [showHidePassword, setShowHidePassword] = useState(false);
  const [showHideConfirmPassword, setShowHideConfirmPassword] = useState(false);
  const { values, bindField, isValid } = useForm({
    validations: {
      password_confirmation: {
        required: {},
        noWhiteSpace: {},
        minLength: {
          value: 6,
          message: "Password should be minimum 6 charactors long.",
        },
      },
      password: {
        required: {},
        noWhiteSpace: {},
        minLength: {
          value: 6,
          message: "Password should be minimum 6 charactors long.",
        },
      },
    },
  });
  const location = useLocation();
  const { setSpinner, setToaster } = useContext(AppContext);
  const route = useNavigate();
  // api function
  const resetPassword = async (e) => {
    e.preventDefault();

    setSpinner(true);
    try {
      let body = {
        user_id: location?.state?.user_id,
        otp: location?.state?.otp,
        password: values?.password,
        password_confirmation: values?.password_confirmation,
      };
      const response = await userService.resetPassword(body);

      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        route("/");
        // route("/resetpassword", { state: { user_id: values?.user_id } });
        setToaster({
          show: true,
          type: "success",
          content: data.message,
        });

        setSpinner(false);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();

        if (data.errors) {
          let error = Object.keys(data.errors)?.reverse();

          for (let i = 0; i <= error.length; i++) {
            setToaster({
              show: true,
              type: "danger",
              content: data.errors[error[i]][0],
            });
          }
        } else {
          setToaster({
            show: true,
            type: "danger",
            content: data.message,
          });
        }
        setSpinner(false);
      }
    } catch (error) {
      setSpinner(false);
    }
    setSpinner(false);
    // api funstion
  };
  // api end function

  return (
    <>
      <OnboardingLayout
        Form={
          <>
            <div className="dash-heading">
              <h1>Reset Password</h1>
            </div>
            <form onSubmit={resetPassword}>
              <InputField
                label="New Password"
                value={values?.password || ""}
                id="newPassword"
                name="password"
                placeholder="Enter New Password"
                type={showHidePassword ? "text" : "password"}
                maxLength={11}
                iconData={{
                  className: "lock_ico pointer",
                  isLock: true,
                  src: showHidePassword ? showPass : hidePass,
                  alt: "user Icon",
                }}
                isPassword
                showHidePassword={showHidePassword}
                setShowHidePassword={setShowHidePassword}
                bindField={bindField}
              />

              <InputField
                label="Confirm New Password"
                value={values?.password_confirmation || ""}
                name="password_confirmation"
                maxLength={11}
                id="confirmPassword"
                placeholder="Enter Confirm New Password"
                type={showHideConfirmPassword ? "text" : "password"}
                iconData={{
                  className: "lock_ico pointer",
                  isLock: true,
                  src: showHideConfirmPassword ? showPass : hidePass,
                  alt: "user Icon",
                }}
                isPassword
                showHidePassword={showHideConfirmPassword}
                setShowHidePassword={setShowHideConfirmPassword}
                bindField={bindField}
              />
              <Button
                className="loginBtn"
                type={"submit"}
                id="logBtn"
                title="Submit"
                disable={!isValid()}
              />
            </form>
          </>
        }
        image={reset_pass}
      />
    </>
  );
}
