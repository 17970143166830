import React, { useContext, useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import Table from "../../utils/table/Table";
import { userService } from "../../../_services/User.services";
import { AppContext } from "../../shared/AppContext";
import { useSearchParams } from "react-router-dom";
import { CONSTANT } from "../../utils/constants";
import { convertIsoToDDMMYYYY } from "../../utils/dateTime";

const CompleteReports = () => {
  const [search] = useSearchParams();
  const { setSpinner, setToaster } = useContext(AppContext);
  const [performanceData, setPerformanceData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(100);
  const [omrResultTable, setOmrResultTable] = useState([]);

  const onPageChange = (e) => {
    let pageNo = e;
    setActivePage(pageNo);
    getReportsData(pageNo);
  };

  const examStatus = (key) => {
    switch (key) {
      case 0:
        return "Schedule";
      case 1:
        return "Expired";
      case 2:
        return "Attempted";
      case 3:
        return "Not-Attempted";
      case 4:
        return "Attempted";
      case 5:
        return "Attempted";
      case 6:
        return "Resume";
      default:
        return "";
    }
  };
  const getReportsData = async (pageNo) => {
    setSpinner(true);
    try {
      const response =
        search?.get("type") === "record"
          ? await userService?.getReportDetailData({ size: "10", page: pageNo })
          : await userService?.getCompletePerformanceData({
              size: "10",
              page: pageNo,
            });
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        let scheduleData = data?.data?.data;
        setTotalItemsCount(data?.data?.total);
        setPerformanceData(
          scheduleData?.map((item, i) => {
            if (search?.get("type") === "record") {
              const currentIndex = i + 1 + (pageNo - 1) * 10; // Adjust index based on the activePage
              const formattedDate = convertIsoToDDMMYYYY(item?.date);

              return [
                { value: currentIndex, id: 1 },
                {
                  value: formattedDate,
                  id: 2,
                },
                { value: item?.class_name, id: 3 },
                { value: item?.subject, id: 4 },
                { value: item?.class_type, id: 5 },
                {
                  id: 6,
                  value:
                    item?.status !== null
                      ? item?.class_type === "Online"
                        ? "Present"
                        : item?.status
                      : "Absent",
                  className:
                    item?.status !== null && item?.class_type === "Online"
                      ? "green-text"
                      : item?.status === "Present"
                      ? "green-text"
                      : "pink-text",
                },
              ];
            } else {
              const formattedUpdatedDate = convertIsoToDDMMYYYY(
                item?.updated_at
              );
              return [
                { value: item?.category_name, id: 1 },
                { value: item?.exam_name, id: 2 },
                // { value: examStatus(item?.exam_status), id: 3 },
                {
                  value: formattedUpdatedDate,
                  id: 3,
                },
                { value: item?.score, id: 4 },
                { value: item?.stu_rank, id: 5 },
              ];
            }
          })
        );
        setSpinner(false);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setSpinner(false);
        setToaster({ show: true, type: "danger", content: data.message });
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
        setSpinner(false);
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  const getViewOMRResult = async () => {
    setSpinner(true);
    try {
      const response = await userService.viewOMRResult();
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        if (data?.success === false && data.statuscode === 404) {
          setToaster({
            type: "danger",
            show: true,
            content: "Do not have any previous OMR Results",
          });
        } else {
          setOmrResultTable(
            data.omr_Result.map((omrData, index) => {
              return [
                {
                  value: index + 1,
                  id: 1,
                },
                {
                  value: omrData.mocksname,
                  id: 2,
                },
                {
                  value: omrData.correct,
                  id: 3,
                },
                {
                  value: omrData.incorrect,
                  id: 4,
                },
                {
                  value: omrData.blank,
                  id: 5,
                },
                {
                  value: omrData.score,
                  id: 6,
                },
                {
                  id: 7,
                  value: omrData.total_marks,
                },
              ];
            })
          );
        }
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setToaster({ show: true, type: "danger", content: data.message });
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getReportsData(1);
    search.get("type") === "omr" && getViewOMRResult();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="complete-reports">
      <div className="white-box body-cont">
        <div className="result-data">
          {search?.get("type") !== "omr" && (
            <Table
              heading={
                search?.get("type") === "record"
                  ? [
                      { value: "S.No", id: 1 },
                      { value: "Date", id: 2 },
                      { value: "Class Name", id: 3 },
                      { value: "Subject", id: 4 },
                      { value: "Class Mode", id: 5 },
                      { value: "Status", id: 6 },
                    ]
                  : [
                      { value: "Exam Category", id: 1 },
                      { value: "Name", id: 2 },
                      // { value: "Status", id: 3 },
                      { value: "Attempted On", id: 3 },
                      { value: "Score", id: 4 },
                      { value: "Rank", id: 5 },
                    ]
              }
              rows={performanceData}
            />
          )}
          {search.get("type") === "omr" && (
            <Table
              heading={[
                { value: "S.No", id: 1 },
                { value: "Mock Test", id: 2 },
                { value: "Correct", id: 3 },
                { value: "Incorrect", id: 4 },
                { value: "Skipped", id: 5 },
                { value: "Score", id: 6 },
                { value: "Total Marks", id: 7 },
              ]}
              rows={omrResultTable}
            />
          )}
        </div>
        {totalItemsCount >= 10 && search.get("type") !== "omr" && (
          <Pagination
            hideFirstLastPages={true}
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={totalItemsCount}
            pageRangeDisplayed={10}
            onChange={(e) => onPageChange(e)}
          />
        )}
      </div>
    </div>
  );
};

export default CompleteReports;
