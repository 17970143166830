import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useBreadcrumb } from "../../shared/breadcrumb-context";

const SubjectCards = ({ subJectData, isButton, className }) => {
  const location = useLocation();
  const { dispatch } = useBreadcrumb();

  const handleBreadCrumb = (data) => {
    dispatch({
      type: "ADD_BREADCRUMB",
      crumb: { title: data?.title, url: data?.url },
    });
  };
  return (
    <>
      <div className={`subjectData row  ${className ? className : ""}`}>
        {subJectData?.length > 0 &&
          subJectData?.map((subData, index) => {
            return (
              <div
                className={`sub-card ${
                  location.pathname.includes("resultnew") ||
                  location.pathname.includes("test-design-report")
                    ? "pb-0"
                    : "col-xxl-3 col-xl-4 col-lg-4  col-md-6 col-sm-6"
                }`}
                key={index}
              >
                <div className={subData?.className}>
                  <div className={`subcard-heading  sub-head`}>
                    {subData?.name && (
                      <>
                        <h4 className="card-title" title={subData.name}>
                          {subData.name.replaceAll("_", " ")}
                        </h4>
                        <div className="absCenter">
                          <button className="infoButton">
                            <div className="infoButton-btn">
                              <span className="infoButton-btn-text">i</span>
                            </div>
                            <div className="infoButton-container">
                              <div className="infoButton-container-message">
                                {subData.name.replaceAll("_", " ")}
                              </div>
                            </div>
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                  <ul className="marks_list w-100 ps-0">
                    {/* {loading && <Skeleton count={10} />} */}
                    {subData?.data?.map((classData, i) => {
                      return (
                        <li className="w-100 list-unstyled" key={i}>
                          <span className={`float-starting `}>
                            {classData.class}
                          </span>
                          <span className={`float-end `}>
                            {classData.class_data}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                  {isButton && (
                    <Link
                      className={`btn theme-btn color-btn ${
                        !subData?.className && "dark-blue"
                      }`}
                      onClick={() => {
                        if (subData?.isBreadCrumb) {
                          handleBreadCrumb({
                            title: subData.name,
                            url: subData?.url,
                          });
                        }
                        // Set the Exam Id in local storage to get in exam category
                        subData?.name.toLowerCase().includes("omr") &&
                          window.localStorage.setItem(
                            "examId",
                            subData.exam_id
                          );
                        if (subData?.stateData) {
                          localStorage.setItem("cardClass", subData?.stateData);
                        }
                      }}
                      to={subData?.url}
                    >
                      Open
                    </Link>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default SubjectCards;
