import React, { useContext, useEffect, useState } from "react";
import { NewBackButton } from "../../utils/backBtn/NewBackButton";
import { useNavigate } from "react-router-dom";
import DatePicker from "../../utils/datePicker/DatePicker";
import { AppContext } from "../../shared/AppContext";
import { useStudentLinkedScheduleHomework } from "../../../hooks/hookList";
import Table from "../../utils/table/Table";
import { getResourceTypeLabel } from "../../../hooks/useResourceType";
import Button from "../../utils/forms/Button";
import MockTestButton from "../test/MockTestButton";
import HomeAssignmentStatusButton from "../homeAssignmentLatest/HomeAssignmentStatusButton";
import { useBreadcrumb } from "../../shared/breadcrumb-context";
import { updateBreadcrumb } from "../../utils/commonFunctions/updateBreadcrumb";
import { Modal, Tab, Tabs } from "react-bootstrap";
import {
  calculateDateRange,
  convertIsoToOrdinalDate,
  handleDateChange,
} from "../../utils/dateTime";
import Pagination from "react-js-pagination";
import RedFlagIcon from "../../../assets/images/red-flag.svg";
import YellowFlagIcon from "../../../assets/images/yellow-flag.svg";
import OrangeFlagIcon from "../../../assets/images/orange-flag.svg";
import GreenFlagIcon from "../../../assets/images/green-flag.svg";
import GreenCheckIcon from "../../../assets/images/checked.png";

const homeworkTableHeadings = [
  {
    value: "S.No",
    id: 1,
  },
  {
    value: "Name",
    id: 2,
  },
  {
    value: "Type",
    id: 3,
  },
  {
    value: "Assigned Date",
    id: 4,
  },
  {
    value: "Due Date",
    id: 5,
  },
  {
    value: "Status",
    id: 6,
  },
  {
    value: "Action",
    id: 7,
  },
];

const homeworkTabs = [
  {
    title: "All",
    id: 1,
  },
  {
    title: "Active",
    id: 2,
  },
  {
    title: "Overdue",
    id: 3,
  },
  {
    title: "Completed",
    id: 4,
  },
];

const tabMapping = {
  1: "All",
  2: "Active",
  3: "OverDue",
  4: "Completed",
};

const getTabsTitle = (value) => tabMapping[value];

const HomeworkSchedule = () => {
  const navigate = useNavigate(); // Hook to navigate between routes
  const { setToaster, setSpinner } = useContext(AppContext); // Context for UI feedback
  const [breadcrumbName, setBreadcrumbName] = useState("Homework"); // State for breadcrumb title
  const [activeTab, setActiveTab] = useState(1);
  const [apiParams, setApiParams] = useState({
    start: "",
    end: "",
    search_keyword: getTabsTitle(activeTab),
  }); // State for selected date range
  const [homeworkData, setHomeworkData] = useState([]); // State for homework data
  const [page, setPage] = useState({
    current: 1,
    total: 0,
  });
  const { dispatch, state } = useBreadcrumb(); // Breadcrumb context for navigation

  const [modal, setModal] = useState({
    flag: false,
  });

  const {
    getData: getStudentScheduleLinkedHomework,
  } = // Hook to fetch homework data
    useStudentLinkedScheduleHomework(setSpinner, setToaster);

  const currentDate = new Date();

  // Callback function for breadcrumb navigation
  const callBackBreadcrumb = () => {
    return "/schedule/homework";
  };

  // Callback to process homework data
  const studentScheduleLinkedHomeworkCallback = (homework) => {
    const { data: homeworkData } = homework;
    if (homeworkData.data && Array.isArray(homeworkData.data)) {
      const sortedHomeWorkData = homeworkData.data.sort(
        (a, b) => Number(a.resource_type_id) - Number(b.resource_type_id)
      );
      setHomeworkData(sortedHomeWorkData);
      setPage((prev) => ({
        ...prev,
        current: homeworkData.current_page,
        total: homeworkData.total,
        from: homeworkData.from,
        to: homeworkData.to,
      }));
    }
  };

  const handleSelectTab = async (value) => {
    setActiveTab(Number(value));
    await getStudentScheduleLinkedHomework(
      {
        start_date: apiParams.start,
        end_date: apiParams.end,
        search_keyword:
          getTabsTitle(Number(value)).toLowerCase() === "all"
            ? ""
            : getTabsTitle(Number(value)).toLowerCase(),
      },
      studentScheduleLinkedHomeworkCallback
    );
  };

  const handlePageChange = async (nextPage) => {
    await getStudentScheduleLinkedHomework(
      {
        start_date: apiParams.start,
        end_date: apiParams.end,
        search_keyword:
          apiParams?.search_keyword?.toLowerCase() === "all"
            ? ""
            : apiParams?.search_keyword?.toLowerCase(),
        page: nextPage,
        size: 10,
      },
      studentScheduleLinkedHomeworkCallback
    );
  };

  useEffect(() => {
    // Set initial dates and fetch linked homework data on component mount
    const initialDates = calculateDateRange();
    setApiParams((prev) => ({
      ...prev,
      ...initialDates,
    }));
    getStudentScheduleLinkedHomework(
      {
        start_date: initialDates.start,
        end_date: initialDates.end,
        search_keyword:
          apiParams.search_keyword.toLowerCase() === "all"
            ? ""
            : apiParams?.search_keyword?.toLowerCase(),
        page: page.current,
        size: 10,
      },
      studentScheduleLinkedHomeworkCallback
    );
  }, []);

  useEffect(() => {
    // Update breadcrumb state on breadcrumb name or navigation change
    updateBreadcrumb(
      state,
      dispatch,
      breadcrumbName,
      null,
      null,
      false,
      callBackBreadcrumb
    );
  }, [breadcrumbName, navigate]);

  return (
    <div className="white-box">
      <div className="mb-3" title="Back to the previous page">
        <h1 className="text-capitalize live_class_title d-flex align-items-center">
          <NewBackButton onClick={() => navigate(-1)} />
          {breadcrumbName}
        </h1>
      </div>
      <div className="date_picker_container mb-3 col-12 col-lg-7">
        <div className="row">
          <div className="date_picker_from col-6 col-md-5">
            <DatePicker
              label="Date From"
              id="date-pick"
              date={apiParams.start}
              placeholder="Select Start Date"
              onChange={(e) => {
                handleDateChange(
                  e,
                  true,
                  apiParams,
                  setApiParams,
                  getStudentScheduleLinkedHomework,
                  studentScheduleLinkedHomeworkCallback,
                  {
                    search_keyword:
                      apiParams.search_keyword.toLowerCase() === "all"
                        ? ""
                        : apiParams?.search_keyword?.toLowerCase(),
                  },
                  setToaster
                );
              }}
            />
          </div>
          <div className="date_picker_to col-6 col-md-5">
            <DatePicker
              label="Date To"
              id="date-pick"
              date={apiParams.end}
              placeholder="Select End Date"
              onChange={(e) => {
                handleDateChange(
                  e,
                  false,
                  apiParams,
                  setApiParams,
                  getStudentScheduleLinkedHomework,
                  studentScheduleLinkedHomeworkCallback,
                  {
                    search_keyword:
                      apiParams.search_keyword.toLowerCase() === "all"
                        ? ""
                        : apiParams?.search_keyword?.toLowerCase(),
                  },
                  setToaster
                );
              }}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="exam-tabs">
          <Tabs
            id="uncontrolled-tab-example"
            className="grid-3"
            onSelect={handleSelectTab}
            activeKey={activeTab}
          >
            {homeworkTabs.map((tab) => (
              <Tab eventKey={tab.id} title={tab.title} key={tab.id} />
            ))}
          </Tabs>
        </div>
        <div className="pe-5 mt-5 cursor-pointer">
          <span
            style={{ color: "#fe8057", textDecoration: "underline" }}
            onClick={() => setModal((prev) => ({ ...prev, flag: true }))}
          >
            Click Here
          </span>
        </div>
      </div>
      <div className="my-5">
        <Table
          heading={homeworkTableHeadings}
          rows={homeworkData?.map((resource, i) => {
            const startDate = new Date(resource.start_date);
            const endDate = new Date(resource.end_date);
            const redFlagDate = new Date(resource.red_flag);
            const yellowFlagDate = new Date(resource.yellow_flag);
            const orangeFlagDate = new Date(resource.orange_flag);

            const isGreenFlag =
              currentDate >= startDate &&
              currentDate <= endDate &&
              currentDate < yellowFlagDate;

            const isYellowFlag =
              currentDate <= yellowFlagDate && currentDate < orangeFlagDate;

            const isOrangeFlag =
              currentDate <= orangeFlagDate && currentDate < redFlagDate;

            const isRedFlag =
              currentDate > orangeFlagDate && currentDate >= redFlagDate;
            return [
              {
                value: page.from + i,
                id: 1,
              },
              {
                value: (
                  <p>
                    {" "}
                    {resource?.resource[0]?.title ||
                      resource?.resource[0]?.name}
                    -{resource?.resource[0]?.id}
                  </p>
                ),
                id: 2,
              },
              {
                value: getResourceTypeLabel(resource.resource_type_id),
                id: 3,
              },
              {
                value: (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: convertIsoToOrdinalDate(resource?.start_date),
                    }}
                  />
                ),
                id: 4,
              },
              {
                value: (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: convertIsoToOrdinalDate(resource?.end_date),
                    }}
                  />
                ),
                id: 5,
              },
              {
                value: (
                  <div className="d-flex justify-content-center align-items-center">
                    <img
                      src={
                        (resource.isCompleted && GreenCheckIcon)||(
                          isGreenFlag && GreenFlagIcon
                        ) ||
                        (isYellowFlag && YellowFlagIcon) ||
                        (isOrangeFlag && OrangeFlagIcon) ||
                        (isRedFlag && RedFlagIcon)
                      }
                      alt="flag"
                      width={25}
                      height={25}
                    />
                  </div>
                ),
                id: 6,
              },
              {
                value: (
                  <>
                    {[1, 2].includes(resource.resource_type_id) && (
                      <div className="d-flex justify-content-center">
                        <Button
                          title="Open"
                          className="btn small-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            if (resource.resource_type_id === 1) {
                              navigate(
                                `/courses/document/${resource?.resource[0]?.id}`,
                                {
                                  state: {
                                    item: resource?.resource[0],
                                    resourse: "Document",
                                    resourceData: [
                                      {
                                        id: 1,
                                        name: "Document",
                                      },
                                    ],
                                  },
                                }
                              );
                            }
                            if (resource.resource_type_id === 2) {
                              navigate(
                                `/courses/video/${resource?.resource[0]?.id}`,
                                {
                                  state: {
                                    item: resource?.resource[0],
                                    resourse: "Video",
                                    resourceData: [
                                      {
                                        id: 1,
                                        name: "Video",
                                      },
                                    ],
                                  },
                                }
                              );
                            }
                          }}
                        />
                      </div>
                    )}
                    {[4].includes(resource?.resource_type_id) && (
                      <div>
                        {resource?.resource[0]?.category?.name
                          .replace(/\s+/g, "")
                          .toLowerCase() === "homeassignment" && (
                          <HomeAssignmentStatusButton
                            id={resource?.resource[0]?.id}
                            examTemplateId={
                              resource?.resource[0]?.exam_template_id
                            }
                            status={resource?.resource[0]?.status}
                            endDate={resource?.resource[0]?.end_date}
                            resultDate={resource?.resource[0]?.result_date}
                            subjectName={resource?.resource[0]?.name}
                            attemptCount={
                              resource?.resource[0]?.exam_attempt_count
                            }
                          />
                        )}
                        {resource?.resource[0]?.category?.name
                          .replace(/\s+/g, "")
                          .toLowerCase() !== "homeassignment" && (
                          <MockTestButton
                            status={resource?.resource[0]?.status}
                            id={resource?.resource[0]?.id}
                            startDate={resource?.resource[0]?.start_date}
                            endDate={resource?.resource[0]?.end_date}
                            resultDate={resource?.resource[0]?.result_date}
                            examRetake={resource?.resource[0]?.exam_retakes}
                            examStatus={resource?.resource[0].exam_status}
                            examTemplateId={
                              resource?.resource[0]?.exam_template_id
                            }
                            categoryExamName={resource?.resource[0]?.category?.name?.toLowerCase()}
                            categoryExamRetake={
                              resource?.resource[0]?.category?.exam_retake
                            }
                            subjectName={resource?.resource[0]?.name}
                            totalQuestions={
                              resource?.resource[0]?.total_questions
                            }
                            examName={resource?.resource[0]?.name}
                            questionLimit={Number(
                              resource?.resource[0]?.exam_template_structure
                                ? Number(
                                    resource?.resource[0]
                                      ?.exam_template_structure[0]?.limit
                                  )
                                : undefined
                            )}
                          />
                        )}
                      </div>
                    )}
                  </>
                ),
                id: 7,
              },
            ];
          })}
        />
        {page.total > 0 && (
          <div>
            <Pagination
              activePage={page.current}
              totalItemsCount={page.total}
              onChange={(e) => {
                handlePageChange(e);
              }}
            />
          </div>
        )}
      </div>
      <Modal
        centered
        size="sm"
        show={modal.flag}
        backdrop="static"
        onHide={() => setModal((prev) => ({ ...prev, flag: false }))}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center p-3">Legend</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-3 ps-3">
          <p className="mb-5">
            <img src={GreenCheckIcon} width={30} height={30} /> - Completed
            Activity
          </p>
          <p className="my-5">
            <img src={GreenFlagIcon} width={30} height={30} /> - Homework
            Assigned – 100% reward points if completed before the due date
          </p>
          <p className="my-5">
            <img src={YellowFlagIcon} width={30} height={30} /> - Due Date
            Passed (1-3 days) – 25% penalty on reward points
          </p>
          <p className="my-5">
            <img src={OrangeFlagIcon} width={30} height={30} /> - Due Date
            Passed (4-6 days) – 50% penalty on reward points
          </p>
          <p className="my-5">
            <img src={RedFlagIcon} width={30} height={30} /> - Due Date Passed
            (6+ days) – 75% penalty on reward points
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HomeworkSchedule;
