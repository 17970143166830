import backIcon from "../../../assets/images/left-arrow.png";
import Image from "../image/Image";

export const NewBackButton = ({ onClick }) => {
  return (
    <span className="pointer p-2 ps-0" onClick={onClick}>
      <Image className="back-btn" src={backIcon} alt="back icon" />
    </span>
  );
};
