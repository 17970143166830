import React from "react";

const Tooltip = ({ content, children,show, handleOnClick }) => {
  return (
    <div className="tooltip-container" onClick={handleOnClick}>
      {children}
      {show && <div className="custom-tooltip">{content}</div>}
    </div>
  );
};

export default Tooltip;
