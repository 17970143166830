import { useEffect } from "react";

export const useDisableKeyboard = (disableKeyboard, setToaster) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (disableKeyboard && event.key) {
        event.preventDefault();
        setToaster({
          show: true,
          type: "danger",
          content: `Sorry, you can't use this key!`,
        });
      }
    };

    if (disableKeyboard) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [disableKeyboard]);
};
