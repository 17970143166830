import Auth from "../auth/Auth";
import {
  authHeaderConfig,
  fetchApi,
  getUrlAndQuery,
  METHOD,
} from "./headerConfig";

export const userService = {
  login,
  signUp,
  resetPassword,
  forgotPassword,
  getClassList,
  verifyOTP,
  takeExam,
  attemptDYTTest,
  designTest,
  createAccess,
  getPrathamBatches,
  updateResourseView,
  updateWallet,
  // GET api's
  getStreamList,
  getCourses,
  examCategories,
  latestExamData,
  examTemplate,
  questionsData,
  pdf,
  getPdf,
  examResult,
  getTodaySchedule,
  downloadAdmitCard,
  getReportOverview,
  getSectionWiseReport,
  getQuestionWiseReport,
  getTopicWiseReport,
  getLeaderBoardReport,
  getLevelWiseReport,
  getChapter,
  getSubjects,
  getDesignTestList,
  getDesignTestQuestions,
  getDYTOverviewReport,
  getDYTLevelWiseReport,
  getDYTQuestionWiseReport,
  getDYTTopicWiseReport,
  getHomeAssignements,
  getAssignementSubjects,
  getAssignementTests,
  getAssignementTestQuestions,
  attemptAssignment,
  getAssignementReport,
  getAssignementQuestionReport,
  getProfileData,
  updateProfilePic,
  updatePassword,
  sendCode,
  updateProfile,
  profileVerifyOTP,
  getFormUpdates,
  addWallet,
  getFormWallets,
  getUniversity,
  getCampus,
  getUniversityTypes,
  getQueries,
  getSections,
  addQuery,
  addTestimonial,
  getDashboardShortcuts,
  getDashboardShortcutDetails,
  getBanners,
  getDashboarddata,
  getNotifications,
  getJoinLiveClassUrl,
  getInstruction,
  getPSISToken,
  logout,
  getClassReport,
  getTestReport,
  getCompletePerformanceData,
  getTags,
  getTagTypeList,
  getCollegeCourseCategory,
  //===================Chat Apis=========================
  getThreads,
  getThreadMessage,
  createClassMessage,
  uploadAttachment,
  //============= OMR Exam API's======================
  uploadOMRExam,
  getReportDetailData,
  getContentData,
  viewOMRResult,
  getAllSchedule,
  resourceTimeSpent,
  getMockAnalysisVideo,
  getSubjectList,
  configureCuetPattern,
  updateSlotSection,
  examTemplateStructure,
  getQuestions,
  submitTest,
  reward_submit,
  getSubmittedQuestionCount,
  updateTestData,
  startTest,
  getNewDashboardShortcuts,
  getAllScheduleClasses,
  bookDetails,
  getResourceItems,
  getResourceSearch,
  getLiveClassRecordings,
  getCourseContentDetails,
  getLatestResource,
  updatePSISPassword,
  omrSelfCheck,
  studentPerformanceReport,
  manageLoginSession,
  studentMenus,
  referEarnPsis,
  studentScheduleLinkedHomework,
  referEarnPsisList,
};

// Signup
function signUp(params) {
  return fetchApi("sign-up", METHOD.POST, params);
}

// login section
function login(params) {
  return fetchApi("login", METHOD.POST, params);
}

// Forgot password
function forgotPassword(params) {
  return fetchApi("forgotPassword", METHOD.POST, params);
}

// Verify OTP
function verifyOTP(params) {
  return fetchApi("verifyOtp", METHOD.POST, params);
}

// Reset password OTP
function resetPassword(params) {
  return fetchApi("resetPassword", METHOD.POST, params);
}

// Take Exam
function takeExam(params) {
  return fetchApi("student/exam/take-test", METHOD.POST, params);
}

// Update Resource View
function updateResourseView(params) {
  return fetchApi("student/resource-view", METHOD.POST, params);
}

// courses list
function getCourses(params) {
  return fetchApi(getUrlAndQuery("courses", params));
}

// Template structure api
function examTemplate(params) {
  return fetchApi(`exams/${params?.exam_id}/template-structure`);
}

// Questions api
function questionsData(params) {
  return fetchApi(getUrlAndQuery("questions", params, false));
}

//--- Class Section ---//
function getClassList(params) {
  return fetchApi(getUrlAndQuery("student-classes", params));
}

//Exam Categories
function examCategories() {
  return fetchApi("exams/category");
}

// Latest Exam
function latestExamData(params) {
  return fetchApi(getUrlAndQuery("exams", params));
}

// Get Stream List
function getStreamList(params) {
  return fetchApi(getUrlAndQuery("student-streams", params));
}

function pdf(params) {
  const requestOptions = {
    method: METHOD.GET,
    redirect: "follow",
    mode: "no-cors",
  };
  return fetch(params.pdf, requestOptions);
}

function getPdf(params) {
  return fetchApi(`documents/${params?.id}/document-view`);
}

// Exam Result Api
function examResult(params) {
  return fetchApi(`student/exam/${params?.id}/test-summary`);
}

// Get Today Schedule
function getTodaySchedule(params) {
  return fetchApi(getUrlAndQuery("student/schedule", params));
}

// Get Pratham Batches From PSIS
function getPrathamBatches(params) {
  let url = `http://psis.prathamedu.com/api/newLMS/student/batch_detail`;
  return fetch(url, {
    headers: authHeaderConfig(),
    body: JSON.stringify(params),
    method: METHOD.POST,
  });
}

// Download Admit Card Of Pratham
function downloadAdmitCard(params) {
  let url = `http://psis.prathamedu.com/student/id-card/download/${
    Auth?.user()?.user_id
  }`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${Auth.token()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*",
    },
    method: METHOD.GET,
  });
}

// Create Class Access Request
function createAccess(params) {
  return fetchApi(`student/classes-request/${params?.classId}`, METHOD.POST);
}

// Get Overview Report
function getReportOverview(params) {
  return fetchApi(getUrlAndQuery("student/reports/overview", params));
}

// Get Section Wise Report
function getSectionWiseReport(params) {
  return fetchApi(getUrlAndQuery("student/reports/section-wise", params));
}

// Get Questions Wise Report
function getQuestionWiseReport(params) {
  return fetchApi(getUrlAndQuery("student/reports/question-wise", params));
}

// Get Topic Wise Report
function getTopicWiseReport(params) {
  return fetchApi(getUrlAndQuery("student/reports/topic-wise", params));
}

// Get LeaderBoard Report
function getLeaderBoardReport(params) {
  return fetchApi(getUrlAndQuery("student/reports/leader-board", params));
}

// Get Level Wise Report
function getLevelWiseReport(params) {
  return fetchApi(getUrlAndQuery("student/reports/level-wise", params));
}

// Get Chapter List
function getChapter(params) {
  return fetchApi(getUrlAndQuery("topics", params));
}

// Get Subject list
function getSubjects() {
  return fetchApi("subjects?dyt=yes");
}

// Design your test
function designTest(params) {
  return fetchApi("student/design-test", METHOD.POST, params);
}

// GET DYT List
function getDesignTestList(params) {
  return fetchApi(getUrlAndQuery("student/student-dyts", params));
}

// Get Design your test questions
function getDesignTestQuestions(params) {
  return fetchApi(
    getUrlAndQuery("student/student-dyts-questions", params, false, true)
  );
}

// Attempt DYT Test
function attemptDYTTest(params) {
  return fetchApi("student/attempt-dyts", METHOD.POST, params);
}

// DYT Overview Report
function getDYTOverviewReport(params) {
  return fetchApi(getUrlAndQuery("student/dyt-overview", params));
}

// DYT Level Wise Report
function getDYTLevelWiseReport(params) {
  return fetchApi(getUrlAndQuery("student/dyt-level-wise", params));
}

// DYT Question Wise Report
function getDYTQuestionWiseReport(params) {
  return fetchApi(getUrlAndQuery("student/dyt-question-wise", params));
}

// DYT Question Wise Report
function getDYTTopicWiseReport(params) {
  return fetchApi(getUrlAndQuery("student/dyt-topic-wise", params));
}

// Get Home Assignments List Exams
function getHomeAssignements(params) {
  return fetchApi(getUrlAndQuery("student/home-assignment", params));
}

// Assignement Subjects List
function getAssignementSubjects(params) {
  return fetchApi(getUrlAndQuery("student/home-assignment/subjects/", params));
}

// Assignement Tests
function getAssignementTests(params) {
  return fetchApi(getUrlAndQuery("student/home-assignment/exams", params));
}

// Assignement Tests Questions
function getAssignementTestQuestions(params) {
  return fetchApi(getUrlAndQuery("student/home-assignment/questions", params));
}

// Attempt Assignment
function attemptAssignment(params) {
  return fetchApi("student/attempt-home-assignment", METHOD.POST, params);
}

// Assignement Report
function getAssignementReport(params) {
  return fetchApi(getUrlAndQuery("student/home-assignment/report", params));
}

// Assignement Question Report
function getAssignementQuestionReport(params) {
  return fetchApi(
    getUrlAndQuery("student/home-assignment/all-questions", params)
  );
}

// Get Profile Data
function getProfileData() {
  return fetchApi("student/profile");
}

// Update Profile Photo
function updateProfilePic(params) {
  let url = `${process.env.REACT_APP_BASE_URL}/api/student/profile/picture`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${Auth.token()}`,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    method: "POST",
    body: params,
  });
}

// Update Password
function updatePassword(params) {
  return fetchApi("student/profile/updatePassword", METHOD.POST, params);
}

// Update Password OTP
function sendCode(params) {
  return fetchApi("student/profile/sendCode", METHOD.POST, params);
}

// Update Profile Data
function updateProfile(params) {
  return fetchApi(`student/profile/${params?.id}`, METHOD.POST, params?.body);
}

// OTP Verify
function profileVerifyOTP(params) {
  return fetchApi("student/profile/verifyCode", METHOD.POST, params);
}

// Get Form Updates
function getFormUpdates(params) {
  return fetchApi(getUrlAndQuery("student/forms", params));
}

// Add Wallet
function addWallet(params) {
  return fetchApi("student/wallet", METHOD.POST, params);
}

// Get Form Wallets
function getFormWallets(params) {
  return fetchApi(getUrlAndQuery("student/wallet", params));
}

// Get University
function getUniversity() {
  return fetchApi("form-section/universities");
}

// Get Campus List
function getCampus(params) {
  return fetchApi(getUrlAndQuery("form-section/campuses", params));
}

// Get University Types
function getUniversityTypes(params) {
  return fetchApi(getUrlAndQuery("form-categories", params));
}

// Update Wallet
function updateWallet(params, id) {
  return fetchApi(`student/wallet/${params.id}`, METHOD.POST, params.body);
}

// Get Queries
function getQueries() {
  return fetchApi("query-management");
}

// Get Queries Sections List
function getSections(params) {
  return fetchApi(getUrlAndQuery("query-section", params));
}

// Add Query

function addQuery(params) {
  let url = `${process.env.REACT_APP_BASE_URL}/api/student/query`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${Auth.token()}`,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    method: "POST",
    body: params,
  });
}

// Add Testimonial
function addTestimonial(params) {
  return fetchApi("student/testimonial", METHOD.POST, params);
}

// Get Dashboard Shortcuts
function getDashboardShortcuts(params) {
  return fetchApi(getUrlAndQuery("dashboard-shortcut", params));
}

// Get New Dashboard Shortcuts
function getNewDashboardShortcuts(params) {
  return fetchApi(getUrlAndQuery("student-dashboard-shortcuts/new", params));
}

// Get Dashboard Shortcut Details
function getDashboardShortcutDetails(params) {
  return fetchApi(getUrlAndQuery("student-dashboard-shortcuts", params));
}

// Get Banners
function getBanners() {
  return fetchApi("banner");
}

// Get Dahboard Data
function getDashboarddata() {
  return fetchApi("student/dashboard");
}

// Get Notifications List
function getNotifications(params) {
  return fetchApi(getUrlAndQuery("notifications", params));
}

// Get Join Live Class URL
function getJoinLiveClassUrl(params) {
  return fetchApi("student/join-liveClass", METHOD.POST, params);
}

// Get Exam Instructions
function getInstruction(params) {
  return fetchApi(
    getUrlAndQuery("exams/edit-instructions", params, false, true)
  );
}
// Get PSIS Token
function getPSISToken(params) {
  const url = `https://liveapi.prathamedu.com/api/chat-app/token_by_userid`;
  return fetch(url, {
    headers: authHeaderConfig(),
    method: METHOD.POST,
    body: JSON.stringify(params),
  });
}

// Logout User
function logout() {
  return fetchApi("logout");
}

// Get Class Report
function getClassReport(params) {
  return fetchApi("student/student-report/class", METHOD.POST, params);
}

// Get Test Report
function getTestReport() {
  return fetchApi("student/student-report/test");
}

// Get Report Details Data
function getReportDetailData(params) {
  return fetchApi(getUrlAndQuery("student/student-report/detail", params));
}

// Get All Schedules
function getAllSchedule(params) {
  return fetchApi(getUrlAndQuery("student/schedule/all", params));
}

// Get All Schedules Classes
function getAllScheduleClasses(params) {
  return fetchApi(getUrlAndQuery("student/schedule/all", params));
}

// Get Content Data
function getContentData(params) {
  return fetchApi(getUrlAndQuery("student/content", params));
}

// Get Complete Performance Data
function getCompletePerformanceData(params) {
  return fetchApi(
    getUrlAndQuery("student/student-complete-performance", params)
  );
}

// Get Tags
function getTags(params) {
  return fetchApi(getUrlAndQuery("student/tags", params));
}

// Get Tag Type List
function getTagTypeList(params) {
  return fetchApi(getUrlAndQuery("student/tags/type", params));
}

// Get College Course Category
function getCollegeCourseCategory(params) {
  return fetchApi(getUrlAndQuery("student/college-course-categories", params));
}

// Get Threads
function getThreads(params) {
  const history = params?.history ? "/history" : "";
  const type = params?.type ? "?type=" + params?.type : "";
  const threadId = params?.threadId ? "/" + params?.threadId : "";
  const from = params?.from ? "&from=" + params?.from : "";
  const to = params?.to ? "&to=" + params?.to : "";
  const url = `https://chat.prathamedu.com/api/threads${history}${type}${threadId}${from}${to}`;
  return fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-token": params?.token,
    },
    method: METHOD.GET,
  });
}

// Get Thread Message
function getThreadMessage(thread, token) {
  return fetch(`https://chat.prathamedu.com/api/messages?threadId=${thread}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-token": token,
    },
    method: METHOD.GET,
  });
}
// "https://chat.prathamedu.com/api/messages"

// Create Class Message
function createClassMessage(params) {
  let url = "https://chat.prathamedu.com/api/messages";
  return fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-token": params?.token,
    },
    method: METHOD.POST,
    body: JSON.stringify(params?.body),
  });
}

// Upload Attachment
function uploadAttachment(params) {
  let url = `https://chat.prathamedu.com/api/storage`;
  return fetch(url, {
    headers: {
      "Content-Transfer-Encoding": "binary",
      "Content-Disposition": `attachment; filename="${params?.name}"`,
      "Content-Type": params?.type,
      "x-entity": "MESSAGE_ATTACHMENT",
      "x-token": params?.token,
      "x-thread-id": params?.threadId,
    },
    method: METHOD.PUT,
    body: params?.body,
  });
}

// Omr Upload Exam
function uploadOMRExam(data) {
  return fetchApi("omr/student/store", METHOD.POST, data);
}

// External Base URL of OMR Student
function viewOMRResult() {
  const url = `${
    process.env.REACT_APP_PREVIOUS_OMR_RESULT_BASE_URL
  }/omr_result?enrollmentid=${Auth?.user()?.user_id}`;
  return fetch(url, {
    headers: authHeaderConfig(),
    method: METHOD.GET,
  });
}

// Resource Time Spent
function resourceTimeSpent(params) {
  return fetchApi("student/resources-log", METHOD.POST, params);
}

// Get Mock Analysis Video
function getMockAnalysisVideo(params) {
  return fetchApi(`student/reports/video-analysis/${params.exam_id}`);
}

// Get Subjects List
function getSubjectList(params) {
  return fetchApi(getUrlAndQuery("subjects", params));
}

// Configure Cuet Pattern
function configureCuetPattern(params) {
  return fetchApi(`exams/exam-pattern/${params.exam_id}`, METHOD.POST, params);
}

// Update Slot Sections
function updateSlotSection(params) {
  return fetchApi(`exams/exam-pattern-status/${params.id}`, METHOD.PUT, params);
}

// Get Exam Template Structure
function examTemplateStructure(params) {
  return fetchApi(`exams/${params?.exam_id}/structure`);
}

// Get Questions
function getQuestions(params) {
  return fetchApi(getUrlAndQuery("questions/get", params, false, true));
}

// Submit Exam
function submitTest(params) {
  return fetchApi("student/exam/submit-exam", METHOD.POST, params);
}

// Reward Submit
function reward_submit(params) {
  return fetchApi("student/add-exam/reward-points", METHOD.POST, params);
}

// Get Submitted Questions Count
function getSubmittedQuestionCount(params) {
  return fetchApi(`exams/${params.exam_id}/submitted-question-status-count`);
}

// Update Test Data
function updateTestData(params) {
  return fetchApi(`exams/${params.exam_id}/update-test-data`, METHOD.PUT);
}

// Set Test Exam
function startTest(params) {
  return fetchApi(`exams/${params.exam_id}/set-test`, METHOD.PUT);
}

// Get Book Details
function bookDetails(params) {
  let url = `${process.env.REACT_APP_MY_ACCOUNT_BASE_URL}`;
  return fetch(url, {
    headers: authHeaderConfig(),
    method: METHOD.POST,
    body: JSON.stringify(params), // Convert the params to JSON and include in the body
  });
}

// Get Resource Items
function getResourceItems(params) {
  return fetchApi("student-dashboard-shortcuts/content", METHOD.POST, params);
}

// Get Resource Search
function getResourceSearch(params) {
  return fetchApi(
    getUrlAndQuery("student/serach-resources", params),
    METHOD.POST,
    params.body
  );
}

// Get Live Class Recordings
function getLiveClassRecordings(params) {
  return fetchApi(getUrlAndQuery("student/liveclass-recording", params));
}

// Course Content Details
function getCourseContentDetails() {
  return fetchApi("student/course-content-details");
}

// Get Latest Resource Content
function getLatestResource(params) {
  return fetchApi("student/course-content-details/latest", METHOD.POST, params);
}

// Update PSIS Password
function updatePSISPassword(params) {
  const url = `https://liveapi.prathamedu.com/api/stu/password/update?enrollment_id=${params.enrollmentId}&password=${params?.password}`;
  return fetch(url, {
    headers: authHeaderConfig(),
    method: METHOD.GET,
    // body: JSON.stringify(params),
  });
}

// Self Check OMR
function omrSelfCheck(formData) {
  return fetchApi("omr/admin/uploadfromcsv", METHOD.POST, formData);
}

// Get Student Performance Report
function studentPerformanceReport() {
  return fetchApi(`student-performance/${Auth?.user()?.user_id}`);
}

// Manage User Sessions
function manageLoginSession(params) {
  return fetchApi("manage-login-session", METHOD.POST, params);
}

function studentMenus() {
  return fetchApi("student/menus");
}

function referEarnPsis(params) {
  let url = `${process.env.REACT_APP_PSIS_BASE_URL}/student/refer/${params.userId}`;
  return fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    method: "GET",
  });
}

function studentScheduleLinkedHomework(params) {
  return fetchApi(getUrlAndQuery("student/home-work", params));
}

function referEarnPsisList(params) {
  let url = `${process.env.REACT_APP_PSIS_BASE_URL}/refferal-list/${params.userId}`;
  return fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    method: "GET",
  });
}
