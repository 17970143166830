import React from "react";
import Table from "../../utils/table/Table";

const FormReleasedTable = ({ formReleased }) => {
  return (
    <div className="white-box p-0">
      <Table
        className="border-0 no-stripe"
        heading={[
          {
            value: "S.No",
            id: 1,
            className: "title-data w-5",
          },
          { value: "University", id: 2, className: "w-15 " },
          {
            value: "Application Start Date",
            id: 3,
            className: "w-15",
          },
          {
            value: "Application End Date",
            id: 4,
            className: "w-15",
          },
          {
            value: "Application Exam Date",
            id: 5,
            className: "w-35",
          },
          {
            value: "Status",
            id: 6,
            className: "w-10",
          },
        ]}
        rows={formReleased}
      />
    </div>
  );
};

export default FormReleasedTable;
