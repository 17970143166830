import React from "react";
import Button from "../../utils/forms/Button";
import CommonModal from "../../utils/modal/CommonModal";

const MockCuetModal = ({ cuetModal, moveToNextSlot }) => {
  return (
    <CommonModal
      className="exam-modal cuet_modal"
      show={cuetModal}
      crossShow={false}
      body={
        <div className="modal-footer justify-content-center">
          <p className="text-center" style={{ fontSize: "2rem" }}>
            {`Current Slot has completed. Please click 'Continue' below to start the next slot of the exam.`}
          </p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              className="btn small-btn theme-btn w-100 text-nowrap py-3 mb-5 fs-2"
              title="Move to next Slot"
              onClick={moveToNextSlot}
            />
          </div>
        </div>
      }
    />
  );
};

export default MockCuetModal;
