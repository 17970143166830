import { userService } from "../_services/User.services";
import { useFetchData } from "./useFetchData";

export const useViewResourceType = (setSpinner, setToaster) => {
  return useFetchData(userService.getResourceItems, setSpinner, setToaster);
};

export const useNewShortcutDetails = (setSpinner, setToaster) => {
  return useFetchData(
    userService.getNewDashboardShortcuts,
    setSpinner,
    setToaster
  );
};

export const useResearchTypeSearch = (setSpinner, setToaster) => {
  return useFetchData(userService.getResourceSearch, setSpinner, setToaster);
};

export const useFormCategories = (setSpinner, setToaster) => {
  return useFetchData(userService.getUniversityTypes, setSpinner, setToaster);
};

export const useFormUpdates = (setSpinner, setToaster) => {
  return useFetchData(userService.getFormUpdates, setSpinner, setToaster);
};

export const useSetTest = (setSpinner, setToaster) => {
  return useFetchData(userService.startTest, setSpinner, setToaster);
};

export const useLiveClassRecordings = (setSpinner, setToaster) => {
  return useFetchData(
    userService.getLiveClassRecordings,
    setSpinner,
    setToaster
  );
};

export const useCollegeCourseCategory = (setSpinner, setToaster) => {
  return useFetchData(
    userService.getCollegeCourseCategory,
    setSpinner,
    setToaster
  );
};

export const useUniversitiesList = (setSpinner, setToaster) => {
  return useFetchData(userService.getUniversity, setSpinner, setToaster);
};

export const useCampusList = (setSpinner, setToaster) => {
  return useFetchData(userService.getCampus, setSpinner, setToaster);
};

export const useWalletDetails = (setSpinner, setToaster) => {
  return useFetchData(userService.getFormWallets, setSpinner, setToaster);
};

export const useUpdateWallet = (setSpinner, setToaster) => {
  return useFetchData(userService.updateWallet, setSpinner, setToaster);
};

export const useCourseContentDetails = (setSpinner, setToaster) => {
  return useFetchData(
    userService.getCourseContentDetails,
    setSpinner,
    setToaster
  );
};

export const useNotficationList = (setSpinner, setToaster) => {
  return useFetchData(userService.getNotifications, setSpinner, setToaster);
};

export const useFolderDetails = (setSpinner, setToaster) => {
  return useFetchData(userService.getCourses, setSpinner, setToaster);
};

export const useLatestResource = (setSpinner, setToaster) => {
  return useFetchData(userService.getLatestResource, setSpinner, setToaster);
};

export const useExamInstructions = (setSpinner, setToaster) => {
  return useFetchData(userService.getInstruction, setSpinner, setToaster);
};

export const useHomeAssignmentsList = (setSpinner, setToaster) => {
  return useFetchData(
    userService.getAssignementSubjects,
    setSpinner,
    setToaster
  );
};

export const useOMRFileUpload = (setSpinner, setToaster) => {
  return useFetchData(userService.uploadOMRExam, setSpinner, setToaster);
};

export const useZoomJoinUrl = (setSpinner, setToaster) => {
  return useFetchData(userService.getJoinLiveClassUrl, setSpinner, setToaster);
};

export const useOtherBatchesSchedule = (setSpinner, setToaster) => {
  return useFetchData(userService.getTodaySchedule, setSpinner, setToaster);
};

export const useUpdateTestData = (setSpinner, setToaster) => {
  return useFetchData(userService.updateTestData, setSpinner, setToaster);
};

export const useSubmittedQuestionCount = (setSpinner, setToaster) => {
  return useFetchData(
    userService.getSubmittedQuestionCount,
    setSpinner,
    setToaster
  );
};

export const useSubmitTest = (setSpinner, setToaster) => {
  return useFetchData(
    userService.submitTest,
    setSpinner,
    setToaster,
    false,
    false
  );
};

export const useProfileDetails = (setSpinner, setToaster) => {
  return useFetchData(userService.getProfileData, setSpinner, setToaster);
};

export const useLogin = (setSpinner, setToaster) => {
  return useFetchData(userService.login, setSpinner, setToaster);
};

export const useUpdatePSISPassword = (setSpinner, setToaster) => {
  return useFetchData(userService.updatePSISPassword, setSpinner, setToaster);
};

export const useUpdateLMSPassword = (setSpinner, setToaster) => {
  return useFetchData(userService.updatePassword, setSpinner, setToaster);
};

export const useSubmitOmrSelfCheck = (setSpinner, setToaster) => {
  return useFetchData(userService.omrSelfCheck, setSpinner, setToaster);
};

export const useStudentPerformanceReport = (setSpinner, setToaster) => {
  return useFetchData(
    userService.studentPerformanceReport,
    setSpinner,
    setToaster
  );
};

export const useUpdateProfilePhoto = (setSpinner, setToaster) => {
  return useFetchData(
    userService.updateProfilePic,
    setSpinner,
    setToaster,
    true
  );
};

export const useManageLoginSession = (setSpinner, setToaster) => {
  return useFetchData(userService.manageLoginSession, setSpinner, setToaster);
};

// Get Banners List
export const useBannersList = (setSpinner, setToaster) => {
  return useFetchData(userService.getBanners, setSpinner, setToaster);
};

// Get Home Assignments Exams List
export const useHomeAssignmentsExamsList = (setSpinner, setToaster) => {
  return useFetchData(userService.getHomeAssignements, setSpinner, setToaster);
};

// Get Subjects List
export const useSubjectsList = (setSpinner, setToaster) => {
  return useFetchData(userService.getSubjects, setSpinner, setToaster);
};

// Get Chapter or Topic List
export const useChaptersList = (setSpinner, setToaster) => {
  return useFetchData(userService.getChapter, setSpinner, setToaster);
};

// Get Tag Type List
export const useTagTypeList = (setSpinner, setToaster) => {
  return useFetchData(userService.getTagTypeList, setSpinner, setToaster);
};

// Get Tag List
export const useTagList = (setSpinner, setToaster) => {
  return useFetchData(userService.getTags, setSpinner, setToaster);
};

// Get DYT List
export const useDYTList = (setSpinner, setToaster) => {
  return useFetchData(userService.getDesignTestList, setSpinner, setToaster);
};

// DYT Create Test
export const useDYTCreateTest = (setSpinner, setToaster) => {
  return useFetchData(userService.designTest, setSpinner, setToaster, true);
};

// Get Home Assignment Question Report
export const useHomeAssignmentQuestionReport = (setSpinner, setToaster) => {
  return useFetchData(
    userService.getAssignementQuestionReport,
    setSpinner,
    setToaster
  );
};

// Create Query
export const useCreateQuery = (setSpinner, setToaster) => {
  return useFetchData(userService.addQuery, setSpinner, setToaster, true);
};

export const useStudentMenus = (setSpinner, setToaster) => {
  return useFetchData(userService.studentMenus, setSpinner, setToaster);
};

export const useSendOtpCode = (setSpinner, setToaster) => {
  return useFetchData(userService.sendCode, setSpinner, setToaster, true);
};

// OTP Verification
export const useOtpVerfication = (setSpinner, setToaster) => {
  return useFetchData(
    userService.profileVerifyOTP,
    setSpinner,
    setToaster,
    true
  );
};

export const useStudentLinkedScheduleHomework = (setSpinner, setToaster) => {
  return useFetchData(
    userService.studentScheduleLinkedHomework,
    setSpinner,
    setToaster
  );
};

export const useGetAllScheduleClass = (setSpinner, setToaster) => {
  return useFetchData(
    userService.getAllScheduleClasses,
    setSpinner,
    setToaster
  );
};

export const useLatestExamData = (setSpinner, setToaster) => {
  return useFetchData(userService.latestExamData, setSpinner, setToaster);
};

export const useUpdateSlotSection = (setSpinner, setToaster) => {
  return useFetchData(userService.updateSlotSection, setSpinner, setToaster);
};

export const useTemplateStructureDetails = (setSpinner, setToaster) => {
  return useFetchData(
    userService.examTemplateStructure,
    setSpinner,
    setToaster
  );
};

export const useExamTemplateQuestions = (setSpinner, setToaster) => {
  return useFetchData(userService.getQuestions, setSpinner, setToaster);
};

export const useReferEarnPsis = (setSpinner, setToaster) => {
  return useFetchData(userService.referEarnPsis, setSpinner, setToaster);
};

export const useUpdateProfile = (setSpinner, setToaster) => {
  return useFetchData(userService.updateProfile, setSpinner, setToaster);
};

export const useResourceTimeSpent = (setSpinner, setToaster) => {
  return useFetchData(
    userService.resourceTimeSpent,
    setSpinner,
    setToaster,
    false,
    false
  );
};

export const useReferEarnPsisList = (setSpinner, setToaster) => {
  return useFetchData(userService.referEarnPsisList, setSpinner, setToaster);
};

export const useConfigureSlots = (setSpinner, setToaster) => {
  return useFetchData(userService.configureCuetPattern, setSpinner, setToaster);
};
