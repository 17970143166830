import React from "react";
import LeftArrowWhiteIcon from "../../../assets/images/white-arrow-down.svg";
import Image from "../../utils/image/Image";

const CourseBackNavigation = ({ setIsOpenDescription, setIsFlipped }) => {
  return (
    <div
      className="d-flex align-items-center position-relative"
      style={{ width: "10%" }}
    >
      <div
        style={{
          padding: "3px",
          background: "#fe8057",
          borderRadius: "50%",
        }}
      >
        <div
          onClick={() => {
            setIsOpenDescription(null);
            setIsFlipped(false);
          }}
        >
          <Image
            src={LeftArrowWhiteIcon}
            style={{
              transform: "rotate(90deg)",
              cursor: "pointer",
            }}
            className="d-flex justify-content-center align-items-center"
            alt="left-arrow"
          />
        </div>
      </div>
    </div>
  );
};

export default CourseBackNavigation;
