import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Button from "../../utils/forms/Button";
import CommonModal from "../../utils/modal/CommonModal";
import { AppContext } from "../../shared/AppContext";
import { userService } from "../../../_services/User.services";
import Question from "./Question";
import QuestionPallete from "./QuestionPallete";
import ExamSummaryModal from "./ExamSummaryModal";

import { useQuestionStatus } from "../../../hooks/useQuestionStatus";
import { useQuestionType } from "../../../hooks/useQuestionType";
import { useHandleClassExam } from "../../../hooks/useHandleClassExam";
import NavigationDrawer from "../examLatestPratham/NavigationDrawer";
import "./newDytTest.scss";

const NewDytTest = () => {
  // State variables
  const { dyt_id } = useParams(); // Route parameter
  const { setToaster, setSpinner } = useContext(AppContext); // Context
  const [questions, setQuestions] = useState([]); // State for questions
  const [timeRemaining, setTimeRemaining] = useState(null); // State for time remaining
  const [totalTimeSpent, setTotalTimeSpent] = useState(0); // State for total time spent
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // State for current question index
  const [submitDataResponse, setSubmitDataResponse] = useState([]); // State for submitted data response
  const [confirmationModal, setConfirmationModal] = useState(false); // State for confirmation modal
  const [viewResultModal, setViewResultModal] = useState(false); // State for view result modal
  const [onChangeOneWord, setOnChangeOneWord] = useState(""); // State for one-word change
  const [numericAnswer, setNumericAnswer] = useState(null); // State for numeric answer
  const [subjectiveAnswer, setSubjectiveAnswer] = useState(""); // State for subjective answer
  const [mobileBtnModal,setMobileBtnModal] = useState(false)

  // Custom hooks
  const { questionStatus } = useQuestionStatus(); // Custom hook for question status
  const { questionType } = useQuestionType(); // Custom hook for question type
  const { handleClasses } = useHandleClassExam(); // Custom hook for handling class exams

  // Other hooks and refs
  const navigate = useNavigate(); // Navigation hook
  const checkQuestionStatus = (status) => ![0, 1, 2].includes(status); // Function to check question status
  const isTimerRunningRef = useRef(true); // Ref for timer running status
  const keyboardRef = useRef(); // Ref for keyboard
  const dataRef = useRef([]);

  const finalSubmitData = (examStatistics, dytStatus) => {
    const data = examStatistics.section_wise_data;
    const dytData = data[0][Number(dyt_id)];
    const combinedData = {
      student_dyt_id: Number(dyt_id),
      dyt_status: dytStatus,
      correct_answers: dytData?.correct_answers,
      incorrect_answers: dytData?.incorrect_answers,
      score: dytData?.score,
      time_spent: dytData?.time_spent ? dytData?.time_spent : 0,
      total_attempt:
        dytData?.total_attempt !== null ? dytData?.total_attempt : 0,
      total_marks: dytData?.total_marks,
      total_questions: dytData?.total_questions,
      questions: dytData?.question_data,
    };

    return combinedData;
  };

  // Function to calculate exam statistics based on exam structure and submission data
  const calculateExamStatistics = (responseData) => {
    const sectionData = responseData.map((sectionData) => {
      const sectionId = Object.keys(sectionData)[0];
      const sectionObject = sectionData[sectionId];

      // Filter questions with status key 0 or 1
      const filteredQuestions = sectionObject.question_data.filter(
        (question) => question.status === 0 || question.status === 1
      );

      for (const question of sectionObject.question_data) {
        if (
          [0, 1].includes(Number(question?.status)) &&
          [3, 4, 5].includes(
            question?.selected_options?.toString()?.toLowerCase().trim() ===
            question?.correct_option_value?.toString()?.toLowerCase()?.trim()
          )
        ) {
          question.is_correct = 0;
        } else {
          question.is_correct = 1;
        }
        if (
          [0, 1].includes(Number(Number(question?.status))) &&
          [1, 2, 6].includes(Number(question?.question_type_id))
        ) {
          if (
            Number(question.selected_options) ===
            Number(question?.correct_option)
          ) {
            question.is_correct = 0;
          } else {
            question.is_correct = 1;
          }
        }
        if ([2, 3, 4].includes(Number(question?.status))) {
          question.is_correct = null;
        }
      }

      const sectionCorrectAnswers = filteredQuestions.reduce(
        (acc, question) => acc + (question.is_correct === 0 ? 1 : 0),
        0
      );

      const sectionIncorrectAnswers = filteredQuestions.reduce(
        (acc, question) => acc + (question.is_correct === 1 ? 1 : 0),
        0
      );
      sectionObject.correct_answers = sectionCorrectAnswers;
      sectionObject.incorrect_answers = sectionIncorrectAnswers;
      sectionObject.score =
        sectionCorrectAnswers * Number(sectionObject.correct_marks) -
        sectionIncorrectAnswers * Number(sectionObject.negative_marks);

      sectionObject.total_marks =
        sectionObject.question_data.length *
        Number(sectionObject.correct_marks);
      sectionObject.time_spent = sectionObject?.question_data?.reduce(
        (acc, question) => acc + Number(question.time_spent),
        0
      );

      return { [sectionId]: sectionObject };
    });
    return { section_wise_data: sectionData };

    // Return the section-wise data and overall statistics
  };

  // Function to create a question object with initial values
  const createQuestionObject = (id, questionData, initialStatus) => {
    return {
      [id]: {
        total_attempt: questionData?.filter((question) =>
          [0, 1].includes(Number(question.status))
        ).length,
        total_marks:
          questionData?.length *
          Number(questionData[0]?.dyt_settings.dyt_correct_marks) -
          Number(questionData[0]?.dyt_settings.dyt_negative_marks),
        correct_answers: questionData.filter(
          (question) =>
            question.option_selected !== null &&
            question.option_selected.is_correct === 0
        ).length,
        incorrect_answers: questionData.filter(
          (question) =>
            question.option_selected !== null &&
            question.option_selected.is_correct === 1
        ).length,
        score: 0,
        correct_marks: Number(questionData[0]?.dyt_settings.dyt_correct_marks),
        negative_marks: Number(
          questionData[0]?.dyt_settings.dyt_negative_marks
        ),
        total_questions: questionData?.length || 0,
        question_data: questionData.map((question, index) => {
          // Check if there is saved user data for the question
          const savedUserExamData = question.option_selected;

          // Find the correct option for the question
          const correctOption = question?.questions[0]?.options.find(
            (option) => option.is_correct === 1
          );
          // If there is saved user data, use it; otherwise, use default values
          if (savedUserExamData !== null) {
            return {
              question_id: savedUserExamData?.question_id,
              status: question?.status,
              question_type_id: question.questions[0]?.question_type_id,
              correct_option: correctOption?.id,
              is_correct: savedUserExamData.is_correct,
              selected_options: savedUserExamData?.option,
              time_spent: Number(question?.time_spent),
            };
          } else {
            // If no saved user data, use default values for a new question
            return {
              question_id: question.question_id,
              question_type_id: question.questions[0]?.question_type_id,
              status: index === 0 ? initialStatus : 4,
              selected_options: null,
              correct_option:
                ![3, 4, 5].includes(question.questions[0]?.question_type_id) &&
                  correctOption
                  ? correctOption.id
                  : question.options[0]?.option,
              is_correct: null,
              time_spent: 0,
            };
          }
        }),
      },
    };
  };

  const updateSubmitDataResponse = (
    structureId,
    updateFunction,
    questionObject
  ) => {
    setSubmitDataResponse((prevData) => {
      if (structureId === 0 || structureId == null) {
        return prevData;
      }
      const newData = [...prevData];
      const structureIndex = newData.findIndex(
        (item) => Number(Object.keys(item)[0]) === Number(structureId)
      );

      // Check if the structure with the specified structureId exists
      if (structureIndex !== -1) {
        // If it exists, apply the updateFunction to the existing structure
        newData[structureIndex][structureId] = updateFunction(
          newData[structureIndex][structureId]
        );
      } else {
        if (questionObject !== undefined) {
          // If it doesn't exist, create a new entry with the specified structureId
          const newEntry = {
            [structureId]: updateFunction(questionObject),
          };
          newData.push(newEntry);
        }
      }

      // Sort the newData array based on structureId
      newData.sort(
        (a, b) => Number(Object.keys(a)[0]) - Number(Object.keys(b)[0])
      );

      return newData;
    });
  };

  const getExamStructure = async () => {
    setSpinner(true);
    try {
      const response = await userService.getDesignTestQuestions({
        student_dyt_id: Number(dyt_id),
      });

      if (response.ok) {
        const { data } = await response.json();

        if (data && data.length > 0) {
          setQuestions(data);
          const totalTimeSpent = data.reduce(
            (acc, question) =>
              question.time_spent !== null && acc + Number(question.time_spent),
            0
          );
          const questionObject = createQuestionObject(
            Number(dyt_id),
            data,
            questionStatus("active")
          );

          updateSubmitDataResponse(
            Number(data[0]?.student_dyt_id),
            (section) => {
              if (section) {
                const currentSection =
                  section !== undefined && section[Number(dyt_id)];
                return {
                  total_questions: data?.length,
                  correct_answers: currentSection?.correct_answers,
                  incorrect_answers: currentSection?.incorrect_answers,
                  score: currentSection?.score,
                  total_attempt: currentSection?.total_attempt,
                  total_marks: currentSection?.total_marks,
                  question_data: currentSection?.question_data,
                  correct_marks: currentSection?.correct_marks,
                  negative_marks: currentSection?.negative_marks,
                };
              }
            },
            questionObject
          );
          if (data.every((questionData) => questionData.time_spent === null)) {
            setTimeRemaining(Number(data[0]?.dyt_settings.dyt_time) * 60);
          } else {
            setTimeRemaining(
              Number(data[0]?.dyt_settings.dyt_time) * 60 - totalTimeSpent
            );
          }

          setCurrentQuestionIndex(0);
          setSpinner(false);
        } else {
          setToaster({
            show: true,
            type: "danger",
            content: "No data available.",
          });
          setSpinner(false);
        }
      } else {
        const errorData = await response.json();
        setToaster({ show: true, type: "danger", content: errorData.message });
        setSpinner(false);
      }
    } catch (error) {
      setToaster({ show: true, type: "danger", content: "An error occurred." });
      setSpinner(false);
    }
  };

  // Set the answer based on the question type id
  const setAnswerBasedOnType = (questionTypeId, selectedOption) => {
    const answerHandlers = {
      4: (selectedOption) => keyboardRef?.current?.setInput(selectedOption),
      3: setNumericAnswer,
      5: setSubjectiveAnswer,
    };
    const handler = answerHandlers[questionTypeId];
    if (handler) {
      handler(selectedOption);
    }
  };

  // Handle moving to the next question
  const handleNext = (currentIndex) => {
    if (currentIndex < questions.length - 1) {
      updateSubmitDataResponse(Number(dyt_id), (section) => {
        const currentQuestion = section?.question_data[currentIndex];
        const nextQuestion = section?.question_data[currentIndex + 1];
        if (
          [3, 4, 5].includes(nextQuestion.question_type_id) &&
          !["", "0", "1", "-1", null].includes(nextQuestion?.selected_options)
        ) {
          setAnswerBasedOnType(
            nextQuestion?.question_type_id,
            nextQuestion?.selected_options
          );
        }
        if (checkQuestionStatus(currentQuestion.status)) {
          currentQuestion.status = questionStatus("active");
        }
        if (checkQuestionStatus(nextQuestion.status)) {
          nextQuestion.status = questionStatus("active");
        }
        return section;
      });
    }
    setCurrentQuestionIndex(currentIndex + 1);
    keyboardRef?.current?.setInput("");
    setNumericAnswer("");
    setSubjectiveAnswer("");
  };

  // Handle moving to the previous question
  const handlePrevious = (currentIndex) => {
    if (currentIndex > 0) {
      updateSubmitDataResponse(Number(dyt_id), (section) => {
        const currentQuestionData = section.question_data[currentIndex];
        const previousQuestionData = section.question_data[currentIndex - 1];
        if (
          [3, 4, 5].includes(previousQuestionData?.question_type_id) &&
          !["0", "1", "-1", null].includes(
            previousQuestionData?.selected_options
          )
        ) {
          setAnswerBasedOnType(
            previousQuestionData?.question_type_id,
            previousQuestionData?.selected_options
          );
        }
        if (
          section.question_data.length === questions.length &&
          checkQuestionStatus(currentQuestionData.status)
        ) {
          currentQuestionData.status = questionStatus("active");
        }

        if (checkQuestionStatus(previousQuestionData.status)) {
          previousQuestionData.status = questionStatus("active");
        }

        return section;
      });
      setCurrentQuestionIndex(currentIndex - 1);
      keyboardRef?.current?.setInput("");
      setNumericAnswer("");
      setSubjectiveAnswer("");
    }
  };

  // Match the current question id with parameter id
  const isQuestionType = (question, questionTypeId) => {
    return Number(question?.question_type_id) === Number(questionTypeId);
  };

  // Check is the current question is answered
  const isQuestionAnswered = (
    currentQuestion,
    numericAnswer,
    keyboardRef,
    subjectiveAnswer
  ) => {
    const isNumericalQuestion = isQuestionType(currentQuestion, 3);
    const isOneWordQuestion = isQuestionType(currentQuestion, 4);
    const isSubjectiveQuestion = isQuestionType(currentQuestion, 5);
    const isNumericalQuestionAnswered =
      isNumericalQuestion && numericAnswer && numericAnswer;
    const isOneWordQuestionAnswered =
      isOneWordQuestion && keyboardRef?.current?.getInput() !== "";
    const isSubjectiveQuestionAnswered =
      isSubjectiveQuestion && subjectiveAnswer && subjectiveAnswer;

    return (
      isNumericalQuestionAnswered ||
      isOneWordQuestionAnswered ||
      isSubjectiveQuestionAnswered
    );
  };

  // Set the answered in state of the current question
  const getSelectedOption = (
    currentQuestion,
    numericAnswer,
    keyboardRef,
    subjectiveAnswer
  ) => {
    const answerMap = {
      4: keyboardRef?.current?.getInput(),
      3: numericAnswer,
      5: subjectiveAnswer,
    };
    return answerMap[currentQuestion?.question_type_id];
  };

  const handleQuestionStatus = async (
    key,
    submit = false,
    isPrevious = false
  ) => {
    const checkQuestionStatus = [0, 1, 2, 3].includes(
      Number(questionStatus(key))
    );
    if (!checkQuestionStatus) return;

    updateSubmitDataResponse(Number(dyt_id), (section) => {
      const currentQuestion = section?.question_data[currentQuestionIndex];
      const isLastQuestion =
        currentQuestionIndex === section?.question_data.length - 1;

      const isQuestionAnsweredResult = isQuestionAnswered(
        currentQuestion,
        numericAnswer,
        keyboardRef,
        subjectiveAnswer
      );
      const selectedOption = getSelectedOption(
        currentQuestion,
        numericAnswer,
        keyboardRef,
        subjectiveAnswer
      );

      if (
        ![3, 4, 5].includes(currentQuestion?.question_type_id) &&
        ["", "0", null, "-1"].includes(currentQuestion?.selected_options) &&
        ![3].includes(Number(questionStatus(key)))
      ) {
        if (!submit) {
          setToaster({
            show: true,
            type: "danger",
            content:
              "Please select an option before moving to the next question.",
          });
        }
        return section;
      }

      if (isQuestionAnsweredResult) {
        currentQuestion.selected_options = selectedOption;
      } else if (
        [3, 4, 5].includes(currentQuestion?.question_type_id) &&
        ![3].includes(Number(questionStatus(key)))
      ) {
        if (!submit) {
          setToaster({
            show: true,
            type: "danger",
            content: "Please type an answer before moving to the next question",
          });
        }
      }
      if (!isLastQuestion) {
        if ([0, 1].includes(currentQuestion?.status)) {
          section.total_attempt = section.total_attempt;
        } else if (
          !["", "0", "-1", null].includes(currentQuestion?.selected_options) &&
          ![2, 3, 4].includes(Number(questionStatus(key)))
        ) {
          section.total_attempt = Number(section.total_attempt) + 1;
        }
        if (
          !["", "0", "-1", null].includes(currentQuestion?.selected_options) ||
          [3].includes(Number(questionStatus(key)))
        ) {
          currentQuestion.status =
            Number(questionStatus(key)) !== 3
              ? Number(questionStatus(key))
              : currentQuestion?.status;
          if (!submit) {
            if (!isPrevious) {
              handleNext(currentQuestionIndex);
            } else {
              handlePrevious(currentQuestionIndex);
            }
          }
        }
      } else {
        if (!isPrevious) {
          {
            currentQuestion.status = questionStatus(key);
            if (![2, 3, 4].includes(Number(questionStatus(key)))) {
              section.total_attempt = Number(section.total_attempt) + 1;
            } else {
              section.total_attempt = section.total_attempt;
            }
          }
        } else {
          if (![0, 1].includes(currentQuestion?.status)) {
            currentQuestion.status = Number(questionStatus(key));
          }
          if (!submit) {
            handlePrevious(currentQuestionIndex);
          }
        }
      }

      return section;
    });
  };

  const handleSubmission = async (responseData, dytStatus) => {
    let calculationExam = calculateExamStatistics(responseData);
    try {
      const response = await userService.attemptDYTTest(
        finalSubmitData(calculationExam, dytStatus)
      );

      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
      }

      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setToaster({ show: true, type: "danger", content: data.message });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // Function to handle option changes for a specific question
  const handleOptionChange = (e, questionId) => {
    const selectedOption = Number(e.target.value);
    updateSubmitDataResponse(Number(dyt_id), (section) => {
      const questionIndex = section?.question_data?.findIndex(
        (question) => question.question_id === questionId
      );
      if (questionIndex !== -1) {
        const currentQuestion = section?.question_data[questionIndex];
        currentQuestion.selected_options = selectedOption;
      }

      return section;
    });
  };

  // Function to check if a specific option is selected for a question
  const isOptionSelected = (question_id, option_id) => {
    const currentSection = submitDataResponse.find(
      (section) => section[dyt_id]
    );
    const selectedQuestion = currentSection[
      Number(dyt_id)
    ]?.question_data?.find((question) => question.question_id == question_id);

    const isChecked =
      selectedQuestion?.selected_options == option_id ? true : false;

    return isChecked;
  };

  const handleOneWordInputChange = (input) => {
    keyboardRef?.current?.setInput(input);
  };

  const handleOneWordAnswer = (value, question_id) => {
    const currentSection = submitDataResponse.find(
      (section) => section[Number(dyt_id)]
    );
    const selectedQuestion = currentSection?.[
      Number(dyt_id)
    ]?.question_data?.find(
      (question) => Number(question.question_id) === Number(question_id)
    );
    if (selectedQuestion) {
      setOnChangeOneWord(value);
      keyboardRef?.current?.setInput(value);
    }
  };

  const handleNumericAnswer = (value, question_id) => {
    const currentSection = submitDataResponse.find(
      (section) => section[dyt_id]
    );
    const selectedQuestion = currentSection?.[dyt_id]?.question_data?.find(
      (question) => Number(question.question_id) === Number(question_id)
    );
    if (selectedQuestion) {
      setNumericAnswer(value);
    }
  };

  // Function to handle clicking on a question, updating its status, and setting the current question index
  const handleQuestionClick = (nextQuestionIndex) => {
    setCurrentQuestionIndex(nextQuestionIndex);
    updateSubmitDataResponse(Number(dyt_id), (section) => {
      const currentQuestion = section.question_data[currentQuestionIndex];
      const nextQuestion = section.question_data[nextQuestionIndex];
      if (
        [3, 4, 5].includes(nextQuestion?.question_type_id) &&
        !["", "0", null].includes(nextQuestion?.selected_options)
      ) {
        nextQuestion.question_type_id === 4 &&
          keyboardRef?.current?.setInput(nextQuestion.selected_options);
        nextQuestion.question_type_id === 3 &&
          setNumericAnswer(nextQuestion.selected_options);
      } else {
        keyboardRef?.current?.setInput(nextQuestion.selected_options);
        setNumericAnswer(nextQuestion.selected_options);
      }
      if (checkQuestionStatus(currentQuestion.status)) {
        currentQuestion.status = questionStatus("active");
      }
      if (checkQuestionStatus(nextQuestion.status)) {
        nextQuestion.status = questionStatus("active");
      }
      return section;
    });
  };

  // Function to handle clearing the answer for a specific question in a tab
  const handleClearAnswer = (currentIndex, tabId) => {
    updateSubmitDataResponse(dyt_id, (section) => {
      const currentQuestion = section?.question_data[currentIndex];
      if (currentQuestion) {
        currentQuestion.selected_options = null;
        if ([0, 1].includes(Number(currentQuestion.status))) {
          section.total_attempt = section.total_attempt - 1;
        }
        currentQuestion.selected_options = null;
        currentQuestion.status = questionStatus("active");
      }
      return section;
    });
  };

  const autoSubmitExam = async () => {
    await handleSubmission(submitDataResponse, 1);
    // await getSubmittedQuestionCount();
  };

  const viewResultHandler = () => {
    setViewResultModal(false);
    playPauseTimer(false);
    navigate(`/design_your_test/test-design-report/${dyt_id}`, {
      replace: true,
    });
  };

  const playPauseTimer = (value) => {
    isTimerRunningRef.current = value;
  };

  const finalExamSubmitHandler = async () => {
    await handleSubmission(submitDataResponse, 0);
    setConfirmationModal(false);
    setViewResultModal(true);
  };

  const startTimer = () => {
    const timer = setInterval(() => {
      if (isTimerRunningRef.current) {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 0) {
            clearInterval(timer);
            return 0;
          }
          setTotalTimeSpent((prevTotalTime) => prevTotalTime + 1);
          return prevTime - 1;
        });
      }
    }, 1000);
    return () => clearInterval(timer);
  };

  useEffect(() => {
    const fetchDataAndStartTimer = async () => {
      try {
        await getExamStructure();
        startTimer();
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataAndStartTimer();
  }, []);

  useEffect(() => {
    // when time expired
    if (timeRemaining <= 0 && timeRemaining !== null) {
      autoSubmitExam();
      setViewResultModal(true);
      playPauseTimer(true);
    }
    updateSubmitDataResponse(Number(dyt_id), (section) => {
      if (section !== undefined) {
        const currentQuestion = section?.question_data[currentQuestionIndex];
        if (currentQuestion) {
          currentQuestion.time_spent = Number(currentQuestion.time_spent) + 1;
        }
        return section;
      }
    });

    if (totalTimeSpent % 300 === 0) {
      // auto submit
      handleSubmission(submitDataResponse, 1);
    }
  }, [totalTimeSpent]);

  useEffect(() => {
    dataRef.current = submitDataResponse;
  }, [submitDataResponse]);

  useEffect(() => {
    window.addEventListener("beforeunload", async (event) => {
      event.preventDefault();
      await handleSubmission(dataRef.current);
      return;
    });

    return () =>
      window.removeEventListener("beforeunload", async (event) => {
        event.preventDefault();
        await handleSubmission(dataRef.current);
        return;
      });
  }, []);

  return (
    <div className="exam-screen d-flex">
      <div className="dyt_white_box">
        <div className="dyt_container">
        <div className="exam-sec row">
            <Question
              questionData={questions}
              currentQuestionIndex={currentQuestionIndex}
              questionType={questionType}
              handleOptionChange={handleOptionChange}
              isOptionSelected={isOptionSelected}
              handleOneWordAnswer={handleOneWordAnswer}
              keyboardRef={keyboardRef}
              handleOneWordInputChange={handleOneWordInputChange}
              handleNumericAnswer={handleNumericAnswer}
              numericAnswer={numericAnswer}
            />
            <div className="header-btns position-absolute">
              <ul className="ms-auto">
                <li>
                  <Button
                    className="prev-btn"
                    link="exams"
                    title="Previous "
                    disable={currentQuestionIndex === 0}
                    onClick={() => handleQuestionStatus("active", false, true)}
                  />
                </li>
                <li>
                  <Button
                    className="proceed next-btn"
                    link="exams"
                    title="Next"
                    disable={currentQuestionIndex === questions.length - 1}
                    onClick={() => handleQuestionStatus("active", false, false)}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="button-footer exam_buttons m-0">
          <div className="ms-4">
            <ul>
              <li>
                <Button
                  className="proceed border-btn green-btn text-white border-0"
                  link=""
                  title="Save And Next"
                  onClick={() => handleQuestionStatus("save and next", false)}
                />
              </li>
              <li>
                <Button
                  className="proceed border-btn dark-blue text-white border-0"
                  link=""
                  title="Save And Mark For Review"
                  onClick={() => handleQuestionStatus("save and mark", false)}
                />
              </li>
              <li>
                <Button
                  className="proceed border-btn yellow-btn text-white border-0"
                  link=""
                  title="Mark For Review And Next"
                  onClick={() => handleQuestionStatus("mark and next", false)}
                />
              </li>
            </ul>
          </div>

          <div className="m-auto">
            <ul className="ms-auto">
              <li>
                <Button
                  className="proceed gray-btn"
                  onClick={() =>
                    handleClearAnswer(currentQuestionIndex, dyt_id)
                  }
                  title="Clear"
                />
              </li>
              <li>
                <Button
                  className="proceed small-btn"
                  onClick={async () => {
                    playPauseTimer(false);
                    setConfirmationModal(true);
                    // await handleSubmission(submitDataResponse, 1);
                  }}
                  link=""
                  title="Submit"
                />
              </li>
            </ul>
          </div>
        
        </div>
        <div className="exam_mobile_btn">
          <Button
            title="Save/Navigate"
            className="proceed small-btn mt-3"
            onClick={() => setMobileBtnModal(true)}
          />
        </div>
      </div>
      {dyt_id && submitDataResponse?.length > 0 && (
        <QuestionPallete
          remainingTime={timeRemaining}
          questionsData={submitDataResponse}
          handleClasses={handleClasses}
          handleQuestionClick={handleQuestionClick}
          selectedTabId={dyt_id}
        />
      )}
      <div className="confirmation_modal">
        <CommonModal
          className="exam-modal dyt-summary-modal"
          show={confirmationModal}
          close={() => setConfirmationModal(false)}
          crossShow={false}
          body={
            <ExamSummaryModal
              submitDataResponse={submitDataResponse}
              dyt_id={dyt_id}
              finalExamSubmitHandler={finalExamSubmitHandler}
              playPauseTimer={playPauseTimer}
              setConfirmationModal={setConfirmationModal}
            />
          }
        />
      </div>
      <div>
        <CommonModal
          className="exam-modal"
          show={viewResultModal}
          crossShow={false}
          body={
            <div className="common_modal">
              <div className="modal-footer d-block pb-5 pt-0">
                <p>Thank you, </p> <p>Submitted Successfully.</p>
                <div className="button-footer w-100">
                  <Button
                    className="procees small-btn me-4"
                    title="View Result"
                    onClick={viewResultHandler}
                  />
                </div>
              </div>
            </div>
          }
        />
      </div>
      <NavigationDrawer  
        mobileBtnModal={mobileBtnModal}
        setMobileBtnModal={setMobileBtnModal}
        handleQuestionStatus={handleQuestionStatus}
        currentQuestionIndex={currentQuestionIndex}
        questions={questions}
        selectedTabId={dyt_id}
        handleClearAnswer={handleClearAnswer}
        playPauseTimer={playPauseTimer}
        handleSubmission={handleSubmission}
        submitDataResponse={submitDataResponse}
        // getSubmittedQuestionCount={getSubmittedQuestionCount}
        setConfirmationModal={setConfirmationModal}
      />
    </div>
  );
};

export default NewDytTest;
