import React from "react";

const SingleAnswerTypeQuestion = ({
  questionData,
  currentQuestionIndex,
  handleOptionChange,
  isOptionSelected,
}) => {
  return (
    <div className="awns_text single_answers_type">
      <ul className="mcq-awns">
        {![3, 4, 5].includes(
          questionData[currentQuestionIndex]?.question_type_id
        ) &&
          questionData[currentQuestionIndex]?.options?.map((awnData, i) => {
            let option = (
              <div
                dangerouslySetInnerHTML={{
                  __html: `${awnData.option}`,
                }}
              ></div>
            );
            return (
              <li key={awnData.id}>
                <div className="form-group radio-group position-relative">
                  <div className="input-box d-flex align-items-center">
                    <input
                      className="d-none text-input custon-radio"
                      type={
                        questionData[currentQuestionIndex]?.question_type_id ===
                          2 &&
                        questionData[currentQuestionIndex]?.options?.filter(
                          (a) => a?.is_correct === 1
                        )?.length > 1
                          ? "checkbox"
                          : "radio"
                      }
                      name={`awn_${
                        questionData[currentQuestionIndex]?.question_type_id ===
                        2
                          ? `checkbox_${awnData?.id}`
                          : `radio_${questionData[currentQuestionIndex]?.id}`
                      }`}
                      value={awnData.id}
                      id={awnData.id}
                      onChange={(e) =>
                        handleOptionChange(e, awnData.question_id)
                      }
                      checked={isOptionSelected(
                        awnData.question_id,
                        awnData.id
                      )}
                    />
                    <label htmlFor={awnData.id}>{option}</label>
                  </div>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default SingleAnswerTypeQuestion;
