import React from "react";
import { Tab, Tabs } from "react-bootstrap";

const CommonTab = ({ keys, setKeys, data, mockVisibility }) => {
  return (
    <div className="common-tab">
      <Tabs
        defaultActiveKey={keys}
        id="uncontrolled-tab-example"
        activeKey={keys}
        onSelect={(k) => {
          setKeys(k);
        }}
        className="mb-3 grid-3 "
      >
        {data?.length > 0 &&
          data?.map((key, i) => {
            if (
              key.title === "Mock Analysis Video" &&
              mockVisibility.length === 0
            ) {
              return null; // Skip rendering this tab if mockVisibility is empty
            }
            return (
              <Tab eventKey={`${key?.title}`} title={key?.title} key={i}></Tab>
            );
          })}
      </Tabs>
    </div>
  );
};

export default CommonTab;
