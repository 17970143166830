import React from "react";
import { useNavigate, Link } from "react-router-dom";
import ResourceIcon from "../../../assets/images/resources-icon.png";
import NewResource from "../../../assets/images/content-message-icon.png";
import LeftArrowWhiteIcon from "../../../assets/images/white-arrow-down.svg";
import OrangeCircle from "../../../assets/images/orange_circle_new.png";
import Image from "../../utils/image/Image";

const DIMENSIONS = {
  iconSize: 21,
  arrowSize: 20,
};

const ResourceItems = ({
  icon,
  label,
  count,
  isNew,
  onClick,
  courseId,
  folderId,
}) => {
  const handleClick = () => {
    if (count > 0) {
      onClick();
    }
  };
  return (
    <div
      className={`content_resource w-50 ${
        label === "New" && "justify-content-end"
      }`}
    >
      <div className="content_resource_icon position-relative">
        <Image width={21} height={21} src={icon} alt={label} />
        {count === 0 && <div className="orange_circle_description"></div>}
        {isNew && <div className="new_indicator" />}
        {label === "New" && count > 0 && (
          <div style={{ position: "absolute", right: "-5px", top: "-5px" }}>
            <Image
              style={{ width: "15px", height: "15px" }}
              src={OrangeCircle}
              alt="orange-circle"
            />
          </div>
        )}
      </div>
      <div
        className="content_resource_count cursor-pointer"
        onClick={handleClick}
      >
        <p>{label}</p>
        <div className="d-flex align-items-center">
          <span>{count}</span>
          {count > 0 && label === "Resources" && (
            <div>
              <div className="arrow_container d-flex justify-content-end position-relative">
                <div className="orange_circle arrow d-flex justify-content-center align-items-center">
                  <Image
                    width={14}
                    height={14}
                    src={LeftArrowWhiteIcon}
                    alt="left-arrow"
                  />
                </div>
              </div>
            </div>
          )}
          {count > 0 && label !== "Resources" && (
            <div>
              <div
                className="arrow_container d-flex justify-content-end position-relative"
                // onClick={onClick}
              >
                <div className="orange_circle arrow d-flex justify-content-center align-items-center">
                  <Image
                    width={14}
                    height={14}
                    src={LeftArrowWhiteIcon}
                    alt="left-arrow"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ContentDetailResource = ({
  resourceCount,
  newResourceCount,
  setIsNewResource,
  courseId,
  folderId,
  onClickResource,
  setCourseName,
  title,
}) => {
  const navigate = useNavigate();
  const resourceItems = [
    {
      label: "Resources",
      icon: ResourceIcon,
      count: resourceCount,
      isNew: false,
      courseId: courseId,
      folderId: folderId,
      onClick: () => navigate(`/course/${courseId}/folder/${folderId}`),
    },
    {
      label: "New",
      icon: NewResource,
      count: newResourceCount,
      isNew: newResourceCount === 0,
      onClick: () => {
        onClickResource(courseId);
        setIsNewResource(true);
        setCourseName(title);
      },
    },
  ];

  return (
    <div className="content_details_resource">
      {resourceItems.map((item, index) => (
        <ResourceItems key={index} {...item} />
      ))}
    </div>
  );
};

export default ContentDetailResource;
