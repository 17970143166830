import React from "react";
import CommonModal from "../../utils/modal/CommonModal";
import Button from "../../utils/forms/Button";

const ExamSummaryItems = ({ label, value, note }) => {
  return (
    <li className="w-100 list-unstyled">
      <span className="float-starting">{label}</span>
      {note && <small>{note}</small>}
      <span className="float-end">{value}</span>
    </li>
  );
};

const getQuestionCount = (questionData, statusArray) => {
  const questionCount = questionData.find((question) =>
    statusArray.includes(question?.status)
  );
  return questionCount?.total_count ?? 0;
};

const ExamSummary = ({
  totalQuestions,
  submittedQuestionData,
  playPauseTimer,
  confirmationModal,
  setConfirmationModal,
  isEnableSlot,
  lastStructureObject,
  selectedTabId,
  finalExamSubmitHandler,
}) => {
  const examSummaryData = [
    {
      label: "Total Number of Questions",
      value: totalQuestions,
    },
    {
      label: "Answered",
      value: getQuestionCount(submittedQuestionData, [0]),
    },
    {
      label: "Not Answered",
      value: getQuestionCount(submittedQuestionData, [3, 4, 5]),
    },
    {
      label: "Marked For Review",
      value: getQuestionCount(submittedQuestionData, [2]),
    },
    {
      label: "Answered & Marked For Review",
      value: getQuestionCount(submittedQuestionData, [1]),
      note: "(will be considered for evaluation)",
    },
  ];

  return (
    <div className="common_modal">
      <div className="gray-bg round-body">
        <div className="modalHeading">
          <h1>Exam Summary</h1>
        </div>
        <div className="modal-body">
          <ul className="ps-0 marks_list">
            {examSummaryData.map(({ label, value, note }, index) => (
              <ExamSummaryItems
                label={label}
                value={value}
                note={note}
                key={index}
              />
            ))}
          </ul>
        </div>
      </div>
      <div className="modal-footer d-block">
        <p>
          {isEnableSlot && lastStructureObject[0]?.id != selectedTabId
            ? `Are you sure you want to submit the current Slot? No
    changes will be allowed after submission.After submit the current slot you will move to the next slot`
            : `Are you sure you want to submit for final marking? No
    changes will be allowed after submission.`}
        </p>
        <div className="button-footer w-100">
          <ul className="d-flex list-unstyled">
            <li>
              <Button
                className="proceed small-btn me-4"
                title="Yes"
                onClick={finalExamSubmitHandler}
              />
            </li>
            <li>
              <Button
                className="proceed  small-btn border-btn"
                onClick={() => {
                  playPauseTimer(true);
                  setConfirmationModal(false);
                }}
                title="No"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const MockExamSummaryModal = ({
  confirmationModal,
  setConfirmationModal,
  submittedQuestionData,
  totalQuestions,
  isEnableSlot,
  lastStructureObject,
  selectedTabId,
  playPauseTimer,
  finalExamSubmitHandler,
}) => {
  const examSummaryProps = {
    confirmationModal,
    setConfirmationModal,
    submittedQuestionData,
    totalQuestions,
    isEnableSlot,
    lastStructureObject,
    selectedTabId,
    playPauseTimer,
    finalExamSubmitHandler,
  };

  return (
    <CommonModal
      className="exam-modal exam_summary_mock_modal"
      show={confirmationModal}
      close={() =>
        setConfirmationModal((prev) => ({ ...prev, confirmation: false }))
      }
      crossShow={false}
      body={<ExamSummary {...examSummaryProps} />}
    />
  );
};

export default MockExamSummaryModal;
