import React, { useContext, useEffect, useRef, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";

import Button from "../../utils/forms/Button";
import CommonModal from "../../utils/modal/CommonModal";
import Question from "./Question"; // Import the QuestionComponent
import QuestionPallete from "./QuestionPallete"; // Import the Question Pallete

import { AppContext } from "../../shared/AppContext";
import { CONSTANT } from "../../utils/constants";
import { userService } from "../../../_services/User.services";

import { useDisableKeyboard } from "../../../hooks/useDisableKeyboard";
import { useHandleClassExam } from "../../../hooks/useHandleClassExam";
import { useQuestionStatus } from "../../../hooks/useQuestionStatus";
import { useQuestionType } from "../../../hooks/useQuestionType";
import "./numericKeypad.scss";
import "./exam.scss";
import MockExamSummaryModal from "./MockExamSummaryModal";
import MockSectionModal from "./MockSectionModal";
import ViewResultModal from "./ViewResultModal";
import MockCuetModal from "./MockCuetModal";
import MockWebcam from "./MockWebcam";
import CountModal from "./CountModal";
import NavigationDrawer from "./NavigationDrawer";
import { removeHTMLTags } from "../../utils/removeTags";
import moment from "moment";
import { useResourceTimeSpent } from "../../../hooks/hookList";
import Auth from "../../../auth/Auth";

const Exams = () => {
  const { exam_id } = useParams();
  const navigate = useNavigate();
  const { setToaster, setSpinner } = useContext(AppContext);

  // States related to exam structure and questions
  const [examStructure, setExamStructureData] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [submittedQuestionData, setSubmittedQuestionData] = useState([]);
  const [submitDataResponse, setSubmitDataResponse] = useState([]);
  const [allStructure, setAllStructure] = useState({});
  const [currentSlot, setCurrentSlot] = useState({});
  const [resultDate, setResultDate] = useState(null);
  const [count, setCount] = useState(1);
  const [countModal, setCountModal] = useState(false);
  const [mobileBtnModal, setMobileBtnModal] = useState(false);
  const [questionWiseMarking, setQuestionWiseMarking] = useState(false);
  const [isSubjectiveMarking, setIsSubjectiveMarking] = useState(false);
  const [isShowViewResultButton, setIsShowViewResultButton] = useState(false);
  const [viewResultBtnTitle, setViewResultBtnTitle] = useState("View Result");

  // States related to timer and time tracking
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [totalTimeSpent, setTotalTimeSpent] = useState(0);
  const [sectionTimeRemaining, setSectionTimeRemaining] = useState(null);
  const [slotTimeRemaining, setSlotTimeRemaining] = useState(null);
  const [timeSpentSlot, setTimeSpentSlot] = useState(null);

  // States related to question limits,tabs & modals
  const [isEnabledQuestionLimit, setIsEnabledQuestionLimit] = useState(false);
  const [questionLimit, setQuestionLimit] = useState(null);
  const [isEnabledSectionLimit, setIsEnabledSectionLimit] = useState(false);
  const [sectionModal, setSectionModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [viewResultModal, setViewResultModal] = useState(false);
  const [cuetModal, setCuetModal] = useState(false);
  const [selectedTabId, setSelectedTabId] = useState(null);
  const [isEnableSlot, setIsEnableSlot] = useState(false);
  const [submitSection, setSubmitSection] = useState(false);

  // States related to answers and input handling
  const [onChangeOneWord, setOnChangeOneWord] = useState("");
  const [numericAnswer, setNumericAnswer] = useState("");
  const [subjectiveAnswer, setSubjectiveAnswer] = useState("");

  // States related to camera and keyboard access
  const [hasCameraAccess, setCameraAccess] = useState(false);
  const [isWebCamEnabled, setIsWebcamEnabled] = useState(false);
  const [disableKeyboard, setDisableKeyboard] = useState(false);

  // Refs
  const isTimerRunningRef = useRef(true);
  const keyboardRef = useRef();

  const studentId = JSON.parse(localStorage.getItem("studentInfo"))?.id;

  //hooks
  const { questionStatus } = useQuestionStatus();
  const { questionType } = useQuestionType();
  const { handleClasses } = useHandleClassExam();
  const { getData: getResourceTimeSpent } = useResourceTimeSpent(
    setSpinner,
    setToaster
  );
  useDisableKeyboard(disableKeyboard, setToaster);

  const checkQuestionStatus = (status) => {
    const isValid = ![0, 1, 2].includes(status);
    return isValid;
  };

  const allSlotStructureKey =
    Object.keys(allStructure).length >= 1 && Object.keys(allStructure);
  const lastStructurekey = allSlotStructureKey[allSlotStructureKey.length - 1];
  const lastStructureObject = allStructure[lastStructurekey];

  const lastSlot = Object.keys(allStructure).pop();
  const lastSlotObject = allStructure[lastSlot];

  const finalSubmitData = (examStatistics) => {
    const combinedData = {
      exam_id: Number(exam_id),
      exam_status: isSubjectiveMarking ? 2 : 1,
      data: examStatistics.section_wise_data,
    };

    return combinedData;
  };

  console.log("anurag", totalTimeSpent);

  // onChange Handler Of Numeric Question
  const handleNumericAnswer = (key) => {
    if (key === "⌫") {
      // If numericAnswer is null or empty, do nothing
      if (numericAnswer === null || numericAnswer === "") {
        return;
      }
      if (numericAnswer.length > 1) {
        setNumericAnswer(
          (prevNumericAnswer) =>
            prevNumericAnswer && prevNumericAnswer.slice(0, -1)
        );
      } else {
        setNumericAnswer("");
      }
    } else {
      setNumericAnswer((prevNumericAnswer) =>
        prevNumericAnswer !== null ? prevNumericAnswer + "" + key : key
      );
    }
  };
  // Function handle to enable/disable keyboard based on the question type
  const isEnableKeyboard = (questionType) => {
    if ([1, 2, 5].includes(questionType)) {
      setDisableKeyboard(false);
    } else {
      setDisableKeyboard(true);
    }
  };

  // Function to calculate exam statistics based on exam structure and submission data

  const calculateExamStatistics = (responseData) => {
    const sectionWiseData = responseData.map((sectionData) => {
      const sectionId = Object.keys(sectionData)[0];
      const sectionObject = sectionData[sectionId];
      sectionObject.score = 0;
      sectionObject.total_marks = 0;
      sectionObject.totalCorrectMarks = 0;
      sectionObject.totalNegativeMarks = 0;
      // Filter questions with status key 0 or 1
      const filteredQuestions = sectionObject.question_data.filter(
        (question) => question.status === 0 || question.status === 1
      );
      // Update is_correct value to null for questions with statuses 2, 3, or 4
      for (const question of sectionObject.question_data) {
        // For Numerical,One Word, Subjective Answer Type Questions update the is_correct value
        if ([0, 1].includes(question?.status)) {
          // For Single, Multiple Answer Type Questions updating the is_correct value
          if ([1, 2, 6].includes(question.question_type_id)) {
            if (
              Number(question.selected_option) ===
              Number(question.correct_option)
            ) {
              question.is_correct = 0;
              // Calculate Score Question Wise
              if (questionWiseMarking) {
                sectionObject.totalCorrectMarks += Number(
                  question.correct_marks
                );
              }
            } else {
              question.is_correct = 1;
              // Calculate Score Question Wise
              if (questionWiseMarking) {
                sectionObject.totalNegativeMarks += Number(
                  question.negative_marks
                );
              }
            }
          }

          if ([3, 4].includes(question.question_type_id)) {
            if (
              removeHTMLTags(
                question?.selected_option?.toString()?.toLowerCase()?.trim()
              ) ===
              removeHTMLTags(
                question?.correct_option_value
                  ?.toString()
                  ?.toLowerCase()
                  ?.trim()
              )
            ) {
              question.is_correct = 0;
              // Calculating Question Wise Score
              if (questionWiseMarking) {
                sectionObject.totalCorrectMarks += Number(
                  question.correct_marks
                );
              }
            } else {
              question.is_correct = 1;
              // Calculating Question Wise Score
              if (questionWiseMarking) {
                sectionObject.totalNegativeMarks += Number(
                  question.negative_marks
                );
              }
            }
          }
        }

        // For Question Statuses 2,3,4 update the is_correct value set to null & Subjective Answer is_correct value should be null
        if ([2, 3, 4].includes(question.status)) {
          question.is_correct = null;
        }

        if (
          [0, 1].includes(question.status) &&
          Number(question.question_type_id) === 5
        ) {
          question.correct_options = null;
          question.is_correct = null;
        }
        if (questionWiseMarking) {
          // Calculating Total Marks Question Wise
          sectionObject.total_marks += Number(question.correct_marks);
        }
      }

      const sectionCorrectAnswers = filteredQuestions.reduce(
        (acc, question) => acc + (question.is_correct === 0 ? 1 : 0),
        0
      );

      const sectionIncorrectAnswers = filteredQuestions.reduce(
        (acc, question) => acc + (question.is_correct === 1 ? 1 : 0),
        0
      );
      sectionObject.correct_answers = sectionCorrectAnswers;
      sectionObject.incorrect_answers = sectionIncorrectAnswers;

      // Template Wise Marking Score Calculating
      if (questionWiseMarking) {
        sectionObject.score =
          sectionObject.totalCorrectMarks - sectionObject.totalNegativeMarks;
      } else {
        sectionObject.score =
          sectionCorrectAnswers * Number(sectionObject.correct_marks) -
          sectionIncorrectAnswers * Number(sectionObject.negative_marks);
      }
      // Template Wise Marking Total Marks Calculating
      if (!questionWiseMarking) {
        sectionObject.total_marks = isEnabledQuestionLimit
          ? questionLimit * Number(sectionObject.correct_marks)
          : sectionObject.question_data.length *
            Number(sectionObject.correct_marks);
      }
      return {
        [sectionId]: sectionObject,
      };
    });

    // Return the section-wise data and overall statistics
    return {
      section_wise_data: sectionWiseData,
    };
  };

  // Function to create a question object with initial values
  const createQuestionObject = (
    id,
    questionData,
    initialStatus,
    isQuestionWiseMarking = false
  ) => {
    return {
      [id]: {
        section_time_spent: 0,
        total_attempt: null,
        total_marks: 0,
        correct_answers: 0,
        incorrect_answers: 0,
        score: 0,
        status: 0,
        duration: 0,
        // Template Wise Correct  Marking
        ...(!isQuestionWiseMarking && {
          correct_marks: 0,
        }),
        // Template Wise Incorrect Marking
        ...(!isQuestionWiseMarking && {
          negative_marks: 0,
        }),
        total_questions: questionData?.length || 0,
        question_data: (questionData || []).map((question, index) => {
          // Check if there is saved user data for the question
          const savedUserExamData = question.student_test_questions;

          // Find the correct option for the question
          const correctOption = question.options.find(
            (option) => option.is_correct === 1
          );
          // If there is saved user data, use it; otherwise, use default values
          if (savedUserExamData) {
            return {
              question_id: savedUserExamData.question_id,
              question_type_id: question.question_type_id,
              subject_id: savedUserExamData.subject_id,
              topic_id: savedUserExamData.topic_id,
              status:
                savedUserExamData.status === 4 && index === 0
                  ? 3
                  : savedUserExamData.status,
              selected_option:
                [3, 4, 5].includes(question?.question_type_id) &&
                savedUserExamData.input_answer !== null
                  ? savedUserExamData.input_answer
                  : !["-1", null].includes(savedUserExamData.selected_option)
                  ? savedUserExamData.selected_option
                  : null,
              correct_option:
                savedUserExamData.correct_options !== null
                  ? savedUserExamData.correct_options
                  : ![3, 4, 5].includes(question.question_type_id) &&
                    correctOption
                  ? correctOption.id
                  : question.options[0]?.id,
              // One Word Correct Option
              ...(question.question_type_id === 4 && {
                correct_option_value: correctOption.option,
              }),
              // Numerical Correct Option
              ...(question.question_type_id === 3 && {
                correct_option_value: correctOption.option,
              }),
              // Subjective Correct Option
              ...(question.question_type_id === 5 && {
                correct_option_value: correctOption.option,
              }),
              is_correct:
                savedUserExamData.is_correct !== null
                  ? savedUserExamData.is_correct
                  : null,
              time_spent: Number(savedUserExamData.time_spent),
              // Question Wise Correct Marks
              ...(isQuestionWiseMarking && {
                correct_marks: Number(question.correct_marks),
              }),
              // Question Wise Negative Marks
              ...(isQuestionWiseMarking && {
                negative_marks: Number(question.negative_marks),
              }),
            };
          } else {
            // If no saved user data, use default values for a new question
            return {
              question_id: question.id,
              question_type_id: question.question_type_id,
              topic_id: question.topic_id,
              subject_id: question.subject_id,
              status: index === 0 ? initialStatus : 4,
              selected_option: null,
              correct_option:
                question.question_type_id !== 4 && correctOption
                  ? correctOption.id
                  : question.options[0]?.option ||
                    (![3, 4, 5].includes(question.question_type_id) &&
                      correctOption)
                  ? correctOption.id
                  : question.options[0]?.option,
              is_correct: null,
              time_spent: 0,
            };
          }
        }),
      },
    };
  };

  const updateSubmitDataResponse = (
    structureId,
    updateFunction,
    questionObject
  ) => {
    setSubmitDataResponse((prevData) => {
      if (structureId === 0 || structureId == null) {
        return prevData;
      }
      const newData = [...prevData];
      const structureIndex = newData.findIndex(
        (item) => Number(Object.keys(item)[0]) === Number(structureId)
      );

      // Check if the structure with the specified structureId exists
      if (structureIndex !== -1) {
        // If it exists, apply the updateFunction to the existing structure
        newData[structureIndex][structureId] = updateFunction(
          newData[structureIndex][structureId]
        );
      } else {
        // If it doesn't exist, create a new entry with the specified structureId
        const newEntry = {
          [structureId]: updateFunction(questionObject),
        };
        newData.push(newEntry);
      }

      // Sort the newData array based on structureId
      newData.sort(
        (a, b) => Number(Object.keys(a)[0]) - Number(Object.keys(b)[0])
      );

      return newData;
    });
  };

  // Check if the result date is in the past
  const checkIfResultDateIsInPast = (resultDate, currentDate) => {
    return resultDate.isBefore(currentDate, "day");
  };

  // Check if the result date is today and the time has passed
  const checkIfResultDateIsTodayAndTimePassed = (resultDate, currentDate) => {
    return (
      resultDate.isSame(currentDate, "day") && resultDate.isBefore(currentDate)
    );
  };

  // Update the button state based on subjective marking
  const updateButtonState = (subjectiveMarking) => {
    if (subjectiveMarking) {
      setIsShowViewResultButton(false);
      setViewResultBtnTitle("Back To Mock Page");
    } else {
      setIsShowViewResultButton(true);
    }
  };

  // Main function to handle the result date checks
  const handleResultDateCheck = (data) => {
    const subjectiveMarking = data.is_subjective;
    const resultDate = moment(data.exam_data.result_date);
    const currentDate = moment();

    if (checkIfResultDateIsInPast(resultDate, currentDate)) {
      updateButtonState(subjectiveMarking);
    } else if (checkIfResultDateIsTodayAndTimePassed(resultDate, currentDate)) {
      updateButtonState(subjectiveMarking);
    } else {
      setViewResultBtnTitle("Back To Home Page");
      setIsShowViewResultButton(false);
    }
  };

  // Structure API
  const getExamStructure = async () => {
    setSpinner(true);
    try {
      const response = await userService.examTemplateStructure({ exam_id });
      if (response.ok) {
        const { data } = await response.json();
        if (data.exam_data) {
          if (data.exam_data.slots.toLowerCase() == "yes") {
            // Set Question Wise Marking when 1 other wise template marking scheme 0
            setQuestionWiseMarking(
              Number(data.exam_data.marking_scheme) === 1 ? true : false
            );
            setIsEnableSlot(true);
            setTimeSpentSlot(Number(data?.total_time_spent));
            setAllStructure(data.all_structure);
            setCurrentSlot(data.current_slot);
            setTotalQuestions(
              data.structure.reduce((totalQuestions, section) => {
                const totalQ = Number(section.total_questions) + totalQuestions;
                return totalQ;
              }, 0)
            );
          } else {
            setIsEnableSlot(false);
            // Set Question Wise Marking when 1 other wise template marking scheme 0
            setQuestionWiseMarking(
              Number(data.exam_data.marking_scheme) === 1 ? true : false
            );
            setIsWebcamEnabled(
              data?.exam_data?.moderation === 0 ? false : true
            );
            handleResultDateCheck(data);
          }
        }
        if (data.structure) {
          setIsSubjectiveMarking(data.is_subjective);
          setResultDate(data?.exam_data?.result_date);
          const totalSlotTime = data.structure.reduce((totalTime, section) => {
            const duration = Number(section.duration) * 60;
            return Number(totalTime) + Number(duration);
          }, 0);

          // Check all the section spent status is zero for first time
          const isEverySectionNull = data.structure.every(
            (structureIndex) => structureIndex.spent_status === null
          );

          // Find the current section in resume test whose status is 1
          const currentSectionStructure = data.structure.find(
            (structureIndex) => structureIndex.spent_status === 1
          );
          const currentSectionTimeLimit =
            Number(data.structure[0]?.section_wise_limit) !== 0;

          // Calculate total time spent across all sections
          const totalTimeSpent = data.structure.reduce((totalTime, section) => {
            const timeSpent = Number(section.time_spent);
            if (!Number.isNaN(timeSpent)) {
              return Number(totalTime) + Number(timeSpent);
            } else {
              return Number(totalTime);
            }
          }, 0);

          if (Number(data.total_time_spent) !== 0) {
            setSlotTimeRemaining(totalSlotTime - Number(totalTimeSpent));
          } else {
            setSlotTimeRemaining(totalSlotTime);
          }
          setTimeRemaining(Number(data.exam_data.duration) * 60);
          if (data.exam_data.slots.toLowerCase() != "yes") {
            setTotalQuestions(Number(data.exam_data.total_questions));
          }

          if (isEverySectionNull) {
            // Set the selected tab, question limit, and exam structure data
            setSelectedTabId(data.structure[0]?.id);
            if (data.structure[0]?.question_limit != 0) {
              setIsEnabledQuestionLimit(true);
              setQuestionLimit(Number(data.structure[0]?.limit));
            }

            setExamStructureData(data.structure);

            if (currentSectionTimeLimit) {
              setIsEnabledSectionLimit(true);
              setSectionTimeRemaining(Number(data.structure[0]?.duration) * 60);
            }
          } else {
            setSelectedTabId(currentSectionStructure.id);
            if (
              currentSectionStructure &&
              Number(currentSectionStructure.question_limit) !== 0
            ) {
              setIsEnabledQuestionLimit(true);
              setQuestionLimit(Number(currentSectionStructure.limit));
            }
            if (currentSectionTimeLimit) {
              setIsEnabledSectionLimit(true);
              setSectionTimeRemaining(
                Number(currentSectionStructure.duration) * 60 -
                  Number(currentSectionStructure.time_spent)
              );
            }
            setExamStructureData(data.structure);
          }

          // Fetch questions for the first section
          const questionResponse = await userService.getQuestions({
            exam_id,
            exam_template_structure_id: isEverySectionNull
              ? data.structure[0]?.id
              : currentSectionStructure.id,
          });

          if (questionResponse.ok) {
            const { data: questionData } = await questionResponse.json();
            setQuestions(questionData);
            const isQuestionType =
              questionData[currentQuestionIndex]?.question_type_id;
            const currentQuestionInputAnswer =
              questionData[currentQuestionIndex]?.student_test_questions
                ?.input_answer;
            isEnableKeyboard(isQuestionType);
            if (questionData?.length > 0 && Number(isQuestionType) === 3) {
              setNumericAnswer(currentQuestionInputAnswer);
            } else if (
              questionData?.length > 0 &&
              Number(isQuestionType) === 4
            ) {
              keyboardRef?.current?.setInput(currentQuestionInputAnswer);
            } else if (
              questionData?.length > 0 &&
              Number(isQuestionType) === 5
            ) {
              setSubjectiveAnswer(currentQuestionInputAnswer);
            }
            const sectionId = isEverySectionNull
              ? data.structure[0]?.id
              : currentSectionStructure.id;
            const isQuestionWiseMarking =
              Number(data.exam_data.marking_scheme) === 1 ? true : false;
            const questionObject = createQuestionObject(
              sectionId,
              questionData,
              questionStatus("active"),
              isQuestionWiseMarking
            );
            setCurrentQuestionIndex(0);

            // Update submit data response with relevant information
            updateSubmitDataResponse(
              isEverySectionNull
                ? data.structure[0]?.id
                : currentSectionStructure.id,
              (section) => {
                if (section) {
                  const sectionId = isEverySectionNull
                    ? data.structure[0]?.id
                    : currentSectionStructure.id;
                  const currentSection = section[sectionId];
                  const sectionDuration = isEverySectionNull
                    ? Number(data.structure[0]?.duration)
                    : Number(currentSectionStructure.duration);
                  const questionWiseCorrectMarks = isEverySectionNull
                    ? data.structure[0]?.correct_marks
                    : currentSectionStructure.correct_marks;
                  const questionWiseNegativeMarks = isEverySectionNull
                    ? data.structure[0]?.negative_marks
                    : currentSectionStructure.negative_marks;

                  return {
                    total_questions: questionData.length,
                    section_time_spent: Number(
                      currentSectionStructure?.time_spent
                    ),

                    correct_answers: currentSection?.correct_answers,
                    incorrect_answers: currentSection?.incorrect_answers,
                    score: currentSection?.score,
                    total_marks: currentSection?.total_marks,
                    total_attempt:
                      currentSectionStructure?.total_attempt !== undefined
                        ? Number(currentSectionStructure?.total_attempt)
                        : 0,
                    question_data: [...currentSection?.question_data],
                    status: 1,
                    duration: sectionDuration,
                    ...(Number(data.exam_data.marking_scheme) === 0 && {
                      correct_marks: questionWiseCorrectMarks,
                    }),
                    ...(Number(data.exam_data.marking_scheme) === 0 && {
                      negative_marks: questionWiseNegativeMarks,
                    }),
                  };
                }
              },
              questionObject
            );
            setTotalTimeSpent(totalTimeSpent);
            if (data.exam_data.slots.toLowerCase() == "yes") {
              const calculateTotalTimeSpent = (data) => {
                let totalTimeSpent = 0;
                Object.values(data).forEach((category) => {
                  category.forEach((item) => {
                    // Assuming time_spent is a string representing minutes
                    const timeSpent = item.time_spent
                      ? parseInt(
                          item.time_spent !== null ? item.time_spent : 0,
                          10
                        )
                      : 0;
                    totalTimeSpent += timeSpent;
                  });
                });

                return totalTimeSpent;
              };
              const totalTimeSpent = calculateTotalTimeSpent(
                data.all_structure
              );
              setTimeRemaining((prevTime) => prevTime - totalTimeSpent);
            } else {
              setTimeRemaining((prevTime) => prevTime - totalTimeSpent);
            }
          } else {
            const errorData = await questionResponse.json();
            setToaster({
              show: true,
              type: "danger",
              content: errorData.message,
            });
          }
        }
        setSpinner(false);
      } else {
        const errorData = await response.json();
        setToaster({ show: true, type: "danger", content: errorData.message });
        setSpinner(false);
      }
    } catch (error) {
      setToaster({ show: true, type: "danger", content: "An error occurred." });
      setSpinner(false);
    }
  };

  const moveToExamReport = () => {
    const resultDateTime = new Date(resultDate);

    const currentDateTime = new Date();
    const isResultDatePass =
      resultDateTime.toLocaleDateString() >
      currentDateTime.toLocaleDateString();

    const isResultTimePass =
      resultDateTime.toLocaleTimeString() >
      currentDateTime.toLocaleTimeString();

    if (isResultDatePass && !isSubjectiveMarking) {
      const isPassResultDate = isResultDatePass;
      return isPassResultDate;
    } else if (isResultTimePass && !isSubjectiveMarking) {
      const isPassResultDate = isResultDatePass;
      return isPassResultDate;
    } else {
      return true;
    }
  };

  const handleTabSelect = async (nextSubjectId, currentSubjectId) => {
    playPauseTimer(false);
    setSpinner(true);
    try {
      const response = await userService.getQuestions({
        exam_id: exam_id,
        exam_template_structure_id: Number(nextSubjectId),
      });

      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setQuestions(data?.data);

        setCurrentQuestionIndex(0);

        const currentSectionIndex = submitDataResponse.findIndex(
          (item) => Number(Object.keys(item)[0]) === Number(currentSubjectId)
        );

        if (currentSectionIndex !== -1) {
          updateSubmitDataResponse(
            Number(currentSubjectId),
            (currentSection) => {
              return {
                ...currentSection,
                status: 0,
              };
            }
          );
          setSelectedTabId(Number(nextSubjectId));
        }

        const nextSectionIndex = submitDataResponse.findIndex(
          (item) => Number(Object.keys(item)[0]) === Number(nextSubjectId)
        );

        const nextSectionStructure = examStructure.find(
          (d) => Number(d.id) === Number(nextSubjectId)
        );

        if (nextSectionIndex === -1) {
          // Include the score key in the data.data object

          const nextQuestionObject = createQuestionObject(
            Number(nextSubjectId),
            data.data,
            questionStatus("active"),
            questionWiseMarking
          );

          // Update the submit data response for the current section
          updateSubmitDataResponse(
            Number(nextSubjectId),
            (section) => {
              if (section) {
                const nextSection = section[Number(nextSubjectId)];
                return {
                  total_questions: data.data.length,
                  section_time_spent: Number(nextSection?.section_time_spent),
                  correct_answers: nextSection?.correct_answers,
                  incorrect_answers: nextSection?.incorrect_answers,
                  score: Number(nextSection?.score),
                  total_marks: Number(nextSection?.total_marks),
                  ...(!questionWiseMarking && {
                    correct_marks: Number(nextSectionStructure?.correct_marks),
                  }),
                  ...(!questionWiseMarking && {
                    negative_marks: Number(
                      nextSectionStructure?.negative_marks
                    ),
                  }),
                  total_attempt: Number(
                    examStructure.find(
                      (d) => Number(d.id) === Number(nextSubjectId)
                    )?.total_attempt
                  ),
                  question_data: [...nextSection?.question_data] || [],
                  status: 1,
                  duration: Number(nextSectionStructure?.duration),
                };
              }
            },
            nextQuestionObject
          );

          const nextSection = examStructure?.find(
            (nextSection) => Number(nextSection.id) === Number(nextSubjectId)
          );
          if (nextSection && Number(nextSection.question_limit) !== 0) {
            setIsEnabledQuestionLimit(true);
            setQuestionLimit(Number(nextSection.limit));
          } else {
            setIsEnabledQuestionLimit(false);
          }
          if (nextSection && Number(nextSection.section_wise_limit) !== 0) {
            setIsEnabledSectionLimit(true);
            setSectionTimeRemaining(Number(nextSection.duration) * 60);
          } else {
            setIsEnabledSectionLimit(false);
          }
          // Set the remaining time for the new section
          // setSectionTimeRemaining(Number(nextSection?.duration) * 60);

          // Set the selected tab to the next tab (section)
          setSelectedTabId(Number(nextSubjectId));
        } else {
          const nextSection = examStructure?.find(
            (nextSection) => Number(nextSection.id) === Number(nextSubjectId)
          );
          if (nextSection && Number(nextSection.question_limit) !== 0) {
            setIsEnabledQuestionLimit(true);
            setQuestionLimit(Number(nextSection.limit));
          } else {
            setIsEnabledQuestionLimit(false);
          }
          if (nextSection && Number(nextSection.section_wise_limit) !== 0) {
            setIsEnabledSectionLimit(true);
            setSectionTimeRemaining(Number(nextSection.duration) * 60);
          } else {
            setIsEnabledSectionLimit(false);
          }
          // If the next section is found, update its status to 1
          updateSubmitDataResponse(Number(nextSubjectId), (nextSection) => {
            return {
              ...nextSection,
              total_attempt: Number(
                examStructure.find(
                  (d) => Number(d.id) === Number(nextSubjectId)
                )?.total_attempt
              ),
              status: 1,
            };
          });
          setSelectedTabId(Number(nextSubjectId));
        }
        setSpinner(false);
        playPauseTimer(true);
      }

      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setSpinner(false);
        setToaster({ show: true, type: "danger", content: data.message });
      }

      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
        setSpinner(false);
      }
    } catch (error) {
      playPauseTimer(false);
      setSpinner(false);
    }
  };

  // Set the answer based on the question type id
  const setAnswerBasedOnType = (questionTypeId, selectedOption) => {
    const answerHandlers = {
      4: (selectedOption) => keyboardRef?.current?.setInput(selectedOption),
      3: setNumericAnswer,
      5: setSubjectiveAnswer,
    };
    const handler = answerHandlers[questionTypeId];
    if (handler) {
      handler(selectedOption);
    }
  };

  // Handle moving to the next question
  const handleNext = (currentIndex) => {
    if (currentIndex < questions.length - 1) {
      updateSubmitDataResponse(selectedTabId, (section) => {
        const currentQuestion = section.question_data[currentIndex];
        const nextQuestion = section.question_data[currentIndex + 1];

        isEnableKeyboard(nextQuestion?.question_type_id);
        if (
          [3, 4, 5].includes(nextQuestion?.question_type_id) &&
          !["0", "-1", "1"].includes(nextQuestion?.selected_option)
        ) {
          setAnswerBasedOnType(
            nextQuestion?.question_type_id,
            nextQuestion?.selected_option
          );
        }
        if (checkQuestionStatus(currentQuestion.status)) {
          currentQuestion.status = questionStatus("active");
        }
        if (checkQuestionStatus(nextQuestion.status)) {
          nextQuestion.status = questionStatus("active");
        }
        if (count === 1 && currentQuestion.status === 3) {
          setCountModal(true);
        }
        return section;
      });

      keyboardRef?.current?.setInput("");
    }
    setCount((prev) => prev + 1);
    setCurrentQuestionIndex(currentIndex + 1);
    keyboardRef?.current?.setInput("");
    setNumericAnswer("");
    setSubjectiveAnswer("");
  };

  // Handle moving to the previous question
  const handlePrevious = (currentIndex) => {
    if (currentIndex > 0) {
      updateSubmitDataResponse(selectedTabId, (section) => {
        const currentQuestionData = section.question_data[currentIndex];
        const previousQuestionData = section.question_data[currentIndex - 1];
        isEnableKeyboard(previousQuestionData?.question_type_id);

        if (
          [3, 4, 5].includes(previousQuestionData?.question_type_id) &&
          !["0", "1", "-1"].includes(previousQuestionData?.selected_option)
        ) {
          setAnswerBasedOnType(
            previousQuestionData?.question_type_id,
            previousQuestionData?.selected_option
          );
        }
        if (
          section.question_data.length === questions.length &&
          checkQuestionStatus(currentQuestionData.status)
        ) {
          currentQuestionData.status = questionStatus("active");
        }

        if (checkQuestionStatus(previousQuestionData.status)) {
          previousQuestionData.status = questionStatus("active");
        }
        if (count === 1 && currentQuestionData.status === 3) {
          setCountModal(true);
        }
        return section;
      });
      setCount((prev) => prev + 1);
      setCurrentQuestionIndex(currentIndex - 1);
      keyboardRef?.current?.setInput("");
      setNumericAnswer("");
      setSubjectiveAnswer("");
    }
  };

  // Match the current question id with parameter id
  const isQuestionType = (question, questionTypeId) => {
    return Number(question?.question_type_id) === Number(questionTypeId);
  };

  // Check is the current question is answered
  const isQuestionAnswered = (
    currentQuestion,
    numericAnswer,
    keyboardRef,
    subjectiveAnswer
  ) => {
    const isNumericalQuestion = isQuestionType(currentQuestion, 3);
    const isOneWordQuestion = isQuestionType(currentQuestion, 4);
    const isSubjectiveQuestion = isQuestionType(currentQuestion, 5);
    const isNumericalQuestionAnswered =
      isNumericalQuestion && String(numericAnswer) && String(numericAnswer);
    const isOneWordQuestionAnswered =
      isOneWordQuestion && keyboardRef?.current?.getInput() !== "";
    const isSubjectiveQuestionAnswered =
      isSubjectiveQuestion && subjectiveAnswer && subjectiveAnswer;

    return (
      isNumericalQuestionAnswered ||
      isOneWordQuestionAnswered ||
      isSubjectiveQuestionAnswered
    );
  };

  // Set the answered in state of the current question
  const getSelectedOption = (
    currentQuestion,
    numericAnswer,
    keyboardRef,
    subjectiveAnswer
  ) => {
    const answerMap = {
      4: keyboardRef?.current?.getInput(),
      3: numericAnswer,
      5: subjectiveAnswer,
    };
    return answerMap[currentQuestion?.question_type_id];
  };

  // Handle for Update Queston Status
  const handleQuestionStatus = async (
    key,
    submit = false,
    isPrevious = false
  ) => {
    const checkQuestionStatus = [0, 1, 2, 3].includes(
      Number(questionStatus(key))
    );
    if (!checkQuestionStatus) return;

    updateSubmitDataResponse(selectedTabId, (section) => {
      const currentQuestion = section.question_data[currentQuestionIndex];
      const isLastQuestion =
        currentQuestionIndex === section.question_data.length - 1;

      const isQuestionAnsweredResult = isQuestionAnswered(
        currentQuestion,
        numericAnswer,
        keyboardRef,
        subjectiveAnswer
      );
      const selectedOption = getSelectedOption(
        currentQuestion,
        numericAnswer,
        keyboardRef,
        subjectiveAnswer
      );
      if (
        ![3, 4, 5].includes(currentQuestion?.question_type_id) &&
        ["-1", null].includes(currentQuestion?.selected_option) &&
        ![3].includes(Number(questionStatus(key)))
      ) {
        if (!submit) {
          setToaster({
            show: true,
            type: "danger",
            content:
              "Please select an option before moving to the next question.",
          });
        }
        return section;
      }
      if (isQuestionAnsweredResult) {
        currentQuestion.selected_option = selectedOption;
      } else if (
        [3, 4, 5].includes(currentQuestion?.question_type_id) &&
        ![3].includes(Number(questionStatus(key)))
      ) {
        if (!submit) {
          setToaster({
            show: true,
            type: "danger",
            content: "Please type an answer before moving to the next question",
          });
        }
      }

      if (!isLastQuestion) {
        if (
          [0, 1].includes(Number(currentQuestion?.status)) &&
          [0, 1].includes(Number(questionStatus(key)))
        ) {
          section.total_attempt = section.total_attempt;
        }
        if (
          [2, 3, 4].includes(Number(currentQuestion?.status)) &&
          [0, 1].includes(Number(questionStatus(key))) &&
          !["-1", null].includes(currentQuestion?.selected_option)
        ) {
          section.total_attempt = Number(section.total_attempt) + 1;
        }

        if (
          [0, 1].includes(currentQuestion?.status) &&
          Number(questionStatus(key) === 2)
        ) {
          section.total_attempt = section.total_attempt - 1;
        }

        if (
          !["-1", null].includes(currentQuestion?.selected_option) ||
          [3].includes(Number(questionStatus(key)))
        ) {
          currentQuestion.status =
            Number(questionStatus(key)) !== 3
              ? Number(questionStatus(key))
              : currentQuestion?.status;
          if (!submit) {
            if (!isPrevious) {
              handleNext(currentQuestionIndex);
            } else {
              handlePrevious(currentQuestionIndex);
            }
          }
        }
      } else {
        if (!isEnabledSectionLimit) {
          const currentTabIndex = examStructure.findIndex(
            (d) => Number(d.id) === Number(selectedTabId)
          );
          const nextTabIndex = currentTabIndex + 1;
          if (!["-1", null].includes(currentQuestion?.selected_option)) {
            currentQuestion.status = questionStatus(key);
          }

          if (nextTabIndex < examStructure.length) {
            handleTabSelect(examStructure[nextTabIndex].id);
          }
        } else {
          if (!isPrevious) {
            currentQuestion.status = questionStatus(key);
            if (
              [0, 1].includes(Number(currentQuestion?.status)) &&
              [0, 1].includes(Number(questionStatus(key)))
            ) {
              section.total_attempt = section.total_attempt;
            }

            if (
              [2, 3, 4].includes(Number(currentQuestion?.status)) &&
              [0, 1].includes(Number(questionStatus(key))) &&
              !["-1", null].includes(currentQuestion?.selected_option)
            ) {
              section.total_attempt = Number(section.total_attempt) + 1;
            }

            if (
              [0, 1].includes(currentQuestion?.status) &&
              Number(questionStatus(key) === 2)
            ) {
              section.total_attempt = section.total_attempt - 1;
            }
          } else {
            if (![0, 1].includes(currentQuestion?.status)) {
              currentQuestion.status = questionStatus(key);
            }
            if (!submit) {
              handlePrevious(currentQuestionIndex);
            }
          }
        }
      }

      return section;
    });
  };

  const handleSubmission = async (responseData) => {
    let calculationExam = calculateExamStatistics(responseData);
    try {
      const response = await userService.submitTest(
        finalSubmitData(calculationExam)
      );

      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
      }

      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setToaster({ show: true, type: "danger", content: data.message });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Total Question Count API - User Submits Exam
  const getSubmittedQuestionCount = async () => {
    setSpinner(true);
    const params = {
      exam_id,
    };
    try {
      const response = await userService.getSubmittedQuestionCount(params);
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setSubmittedQuestionData(data.data);
        setSpinner(false);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setSpinner(false);
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  const updateTestData = async () => {
    const params = {
      exam_id,
    };
    try {
      setSpinner(true);
      const response = await userService.updateTestData(params);
      if (response.status >= 200 && response.status <= 299) {
        setSpinner(false);
      }
      if (response.status >= 400 && response.status <= 499) {
        setSpinner(false);
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  // Update Cuet Slot API
  const updateSlotSection = async (id, status, reload = false) => {
    const params = {
      ...status,
      id,
    };
    setSpinner(true);
    try {
      const response = await userService.updateSlotSection(params);
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setSpinner(false);
        setToaster({
          show: true,
          type: "success",
          content: data.message,
        });
        if (reload) {
          window.location.reload();
        }
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setSpinner(false);
        setToaster({
          show: true,
          type: "danger",
          content: data.message,
        });
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  // Function to handle option changes for a specific question
  const handleOptionChange = (e, questionId) => {
    const selectedOption = Number(e.target.value);
    updateSubmitDataResponse(selectedTabId, (section) => {
      const questionIndex = section.question_data.findIndex(
        (question) => question.question_id === questionId
      );
      if (isEnabledQuestionLimit && section.total_attempt >= questionLimit) {
        setToaster({
          show: true,
          type: "danger",
          content:
            "You have reached the maximum attempt limit for this current section.",
        });
      } else if (questionIndex !== -1) {
        const currentQuestion = section.question_data[questionIndex];
        currentQuestion.selected_option = selectedOption;
        // currentQuestion.is_correct =
        // Number(currentQuestion.correct_option) === selectedOption ? 0 : 1;
      }

      return section;
    });
  };

  // Function to check if a specific option is selected for a question
  const isOptionSelected = (question_id, option_id) => {
    const currentSection = submitDataResponse.find(
      (section) => section[selectedTabId]
    );

    const selectedQuestion = currentSection?.[
      selectedTabId
    ]?.question_data?.find((question) => question.question_id == question_id);

    const isChecked =
      selectedQuestion?.selected_option == option_id ? true : false;

    return isChecked;
  };

  const handleOneWordInputChange = (input) => {
    keyboardRef?.current?.setInput(input);
  };

  const handleOneWordAnswer = (value, question_id) => {
    const currentSection = submitDataResponse.find(
      (section) => section[selectedTabId]
    );
    const selectedQuestion = currentSection?.[
      selectedTabId
    ]?.question_data?.find(
      (question) => Number(question.question_id) === Number(question_id)
    );
    if (selectedQuestion) {
      setOnChangeOneWord(value);
      keyboardRef?.current?.setInput(value);
    }
  };

  // Handle For Subject Answer for Question Type - 5
  const handleSubjectiveAnswer = (value, question_id) => {
    const currentSection = submitDataResponse.find(
      (section) => section[selectedTabId]
    );
    const selectedQuestion = currentSection?.[
      selectedTabId
    ]?.question_data?.find(
      (question) => Number(question.question_id) === Number(question_id)
    );
    if (selectedQuestion) {
      setSubjectiveAnswer(value);
    }
  };

  // Function to handle clicking on a question, updating its status, and setting the current question index
  const handleQuestionClick = (nextQuestionIndex) => {
    setCurrentQuestionIndex(nextQuestionIndex);
    updateSubmitDataResponse(selectedTabId, (section) => {
      const currentQuestion = section.question_data[currentQuestionIndex];
      const nextQuestion = section.question_data[nextQuestionIndex];
      const isQuestionAnsweredResult = isQuestionAnswered(
        currentQuestion,
        numericAnswer,
        keyboardRef,
        subjectiveAnswer
      );
      const selectedOption = getSelectedOption(
        currentQuestion,
        numericAnswer,
        keyboardRef,
        subjectiveAnswer
      );
      isEnableKeyboard(nextQuestion?.question_type_id);

      if (
        [3, 4, 5].includes(nextQuestion?.question_type_id) &&
        !["-1", null].includes(nextQuestion?.selected_option)
      ) {
        setAnswerBasedOnType(
          nextQuestion?.question_type_id,
          nextQuestion?.selected_option
        );
      } else {
        keyboardRef?.current?.setInput(nextQuestion.selected_option);
        setNumericAnswer(nextQuestion.selected_option);
        setSubjectiveAnswer(nextQuestion?.selected_option);
      }
      if (checkQuestionStatus(currentQuestion.status)) {
        if (isQuestionAnsweredResult) {
          currentQuestion.selected_option = selectedOption;
        }
        currentQuestion.status = questionStatus("active");
      }
      if (checkQuestionStatus(nextQuestion.status)) {
        nextQuestion.status = questionStatus("active");
      }
      return section;
    });
  };

  // Function to handle clearing the answer for a specific question in a tab
  const handleClearAnswer = (currentIndex, tabId) => {
    updateSubmitDataResponse(tabId, (section) => {
      const currentQuestion = section?.question_data[currentIndex];
      if (currentQuestion) {
        setSubjectiveAnswer("");
        setNumericAnswer("");
        setOnChangeOneWord("");
        currentQuestion.selected_option = null;
        if ([0, 1].includes(Number(currentQuestion.status))) {
          section.total_attempt = section.total_attempt - 1;
        }
        currentQuestion.status = questionStatus("active");
      }
      return section;
    });
  };

  // Handler Move To Next Section When Section Time is Enabled
  const moveToNextSection = () => {
    const currentIndex = examStructure?.findIndex(
      (d) => Number(d.id) === Number(selectedTabId)
    );
    if (currentIndex !== -1 && currentIndex <= examStructure.length) {
      const nextIndex = currentIndex + 1;
      const nextSectionId = examStructure[nextIndex].id;
      handleTabSelect(nextSectionId, selectedTabId);
    }
    setSectionModal(false);
  };

  const handleSubmitSection = async () => {
    const currentSection = submitDataResponse?.find(
      (section) => Number(Object.keys(section)) === Number(selectedTabId)
    )[selectedTabId];
    currentSection.status = 0;
    await handleSubmission(submitDataResponse);
    moveToNextSection();
    setSubmitSection(false);
    if (mobileBtnModal) {
      setMobileBtnModal(false);
    }
  };

  const moveToNextSlot = () => {
    window.location.reload();
  };

  const autoSubmitExam = async () => {
    await handleSubmission(submitDataResponse);
    // await getSubmittedQuestionCount();
    await updateTestData();
  };

  const viewResultHandler = () => {
    setViewResultModal(false);
    playPauseTimer(false);
    if (isShowViewResultButton) {
      navigate(`/test/${exam_id}/exams/resultnew`, {
        replace: true,
      });
    } else {
      navigate("/test");
    }
  };

  // Play/Pause Timer Handler
  const playPauseTimer = (value) => {
    isTimerRunningRef.current = value;
  };

  // Update Cuet Slot Handler
  const cuetHandler = async (reload = false) => {
    const params = {
      status: 0,
    };

    updateSubmitDataResponse(Number(selectedTabId), (currentSection) => {
      return {
        ...currentSection,
        status: 0,
      };
    });
    await updateSlotSection(currentSlot.id, params, reload);
    await autoSubmitExam();
    return true;
  };

  // Handler for final submit
  const finalExamSubmitHandler = async () => {
    const resourceTimeSpentParams = {
      student_id: Auth?.user()?.id,
      resource_id: exam_id,
      resource_type_id: 4,
      time_spent: totalTimeSpent,
    };
    
    if (isEnableSlot) {
      const isLastSlotSubmit = lastSlotObject?.find(
        (d) => Number(d.id) === Number(selectedTabId)
      );
      if (isLastSlotSubmit) {
        await cuetHandler();
        await getResourceTimeSpent(resourceTimeSpentParams);
        setConfirmationModal(false);
        setViewResultModal(true);
      } else {
        await cuetHandler(true);
      }
    } else {
      await updateTestData();
      await getResourceTimeSpent(resourceTimeSpentParams);
      setConfirmationModal(false);
      setViewResultModal(true);
    }
  };

  // Start Timer
  const startTimer = () => {
    const timer = setInterval(() => {
      if (isTimerRunningRef.current) {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 0) {
            clearInterval(timer);
            return 0;
          }
          setTotalTimeSpent((prevTotalTime) => prevTotalTime + 1);
          setSlotTimeRemaining((prevSlotTime) => {
            if (prevSlotTime <= 1) {
              return 0;
            } else {
              return prevSlotTime - 1;
            }
          });
          setSectionTimeRemaining((prevSectionTime) => {
            if (prevSectionTime <= 1) {
              return 0;
            } else {
              return prevSectionTime - 1;
            }
          });

          return prevTime - 1;
        });
      }
    }, 1000);
    return () => clearInterval(timer);
  };

  const fetchDataAndStartTimer = async () => {
    try {
      await getExamStructure();
      startTimer();
    } catch (error) {
      console.log("Error fetching exam structure:", error);
    }
  };

  const initializeWebcam = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      fetchDataAndStartTimer();
    } catch (error) {
      console.log("Error accessing camera:", error);
      setCameraAccess(true);
    }
  };

  useEffect(() => {
    if (isWebCamEnabled) {
      initializeWebcam();
    } else {
      fetchDataAndStartTimer();
    }
  }, []);

  useEffect(() => {
    const lastSection = Number(examStructure[examStructure.length - 1]?.id);

    // when time expired
    if (timeRemaining <= 0 && timeRemaining !== null) {
      // when time expired
      if (isEnableSlot) {
        cuetHandler();
        setViewResultModal(true);
      } else {
        autoSubmitExam();
        setViewResultModal(true);
        playPauseTimer(true);
      }
    }

    updateSubmitDataResponse(Number(selectedTabId), (section) => {
      if (section !== undefined) {
        const currentQuestion = section?.question_data[currentQuestionIndex];
        let currentSectionTime = Number(section?.section_time_spent); // Change const to let
        if (isEnableSlot && slotTimeRemaining === 0) {
          playPauseTimer(false);
          const currentslot = currentSlot.slot_no;
          const nextSlot = currentslot + 1;
          const getNextSlot = Number(
            Object.keys(allStructure).find((d) => d == nextSlot)
          );
          const cuetResponse = cuetHandler();
          if (getNextSlot) {
            if (cuetResponse) {
              setCuetModal(true);
            }
          } else {
            const cuetResponse = cuetHandler();
            if (cuetResponse) {
              setViewResultModal(true);
            }
          }
        } else if (
          isEnabledSectionLimit &&
          currentSectionTime === Number(section.duration) * 60 &&
          lastSection !== Number(selectedTabId)
        ) {
          playPauseTimer(false);
          handleSubmission(submitDataResponse);
          setSectionModal(true);
        } else if (currentSectionTime) {
          setSectionModal(false);
          currentSectionTime = currentSectionTime + 1;
          playPauseTimer(true);
        } else {
          setSectionModal(false);
          currentSectionTime = 1;
          playPauseTimer(true);
        }

        if (
          isEnabledSectionLimit &&
          currentSectionTime === Number(section.duration) * 60 &&
          lastSection === Number(selectedTabId)
        ) {
          section.status = 0;
          autoSubmitExam();
        }
        if (currentQuestion) {
          currentQuestion.time_spent = Number(currentQuestion.time_spent) + 1;
        }
        section.section_time_spent = currentSectionTime;
        return section;
      }
    });
    if (totalTimeSpent % 300 === 0) {
      // auto submit
      handleSubmission(submitDataResponse);
    }
  }, [totalTimeSpent]);

  return (
    <>
      <div className="exam-screen d-flex gap-4 mt-3">
        <div className="exam_white_box">
          <div className="exam_container">
            <div className="exam-sec row">
              <div className="col-md-9 exam-tabs">
                <Tabs
                  id="uncontrolled-tab-example"
                  onSelect={(nextTabId) => {
                    if (isEnabledSectionLimit) {
                      setToaster({
                        show: true,
                        type: "danger",
                        content:
                          "Please wait until the designated current section time is over before proceeding to the next section.",
                      });
                    } else {
                      handleTabSelect(nextTabId, selectedTabId);
                    }
                  }}
                  activeKey={selectedTabId}
                  className="mb-3 grid-3 flex-nowrap text-nowrap overflow-auto w-100"
                >
                  {examStructure?.length > 0 &&
                    examStructure.map((key) => {
                      return (
                        <Tab
                          eventKey={key?.id}
                          title={key?.subject_name}
                          key={key?.id}
                          disabled={selectedTabId == key?.id}
                        ></Tab>
                      );
                    })}
                </Tabs>
              </div>
              <Question
                questionData={questions}
                currentQuestionIndex={currentQuestionIndex}
                questionType={questionType}
                handleOptionChange={handleOptionChange}
                isOptionSelected={isOptionSelected}
                handleOneWordAnswer={handleOneWordAnswer}
                keyboardRef={keyboardRef}
                handleOneWordInputChange={handleOneWordInputChange}
                handleNumericAnswer={handleNumericAnswer}
                numericAnswer={numericAnswer}
                subjectiveAnswer={subjectiveAnswer}
                handleSubjectiveAnswer={handleSubjectiveAnswer}
                setNumericAnswer={setNumericAnswer}
              />
              <div className="header-btns position-absolute">
                <ul className="ms-auto">
                  <li>
                    <Button
                      className="prev-btn"
                      link="exams"
                      title="Previous "
                      disable={currentQuestionIndex === 0}
                      onClick={() =>
                        handleQuestionStatus("active", false, true)
                      }
                    />
                  </li>
                  <li>
                    <Button
                      className="proceed next-btn"
                      link="exams"
                      title="Next"
                      disable={currentQuestionIndex === questions.length - 1}
                      onClick={() =>
                        handleQuestionStatus("active", false, false)
                      }
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="button-footer exam_buttons m-0">
            <div className="ms-4">
              <ul>
                <li>
                  <Button
                    className="proceed border-btn green-btn text-white border-0"
                    link=""
                    title="Save And Next"
                    onClick={() => handleQuestionStatus("save and next", false)}
                  />
                </li>
                <li>
                  <Button
                    className="proceed border-btn dark-blue text-white border-0"
                    link=""
                    title="Save And Mark For Review"
                    onClick={() => handleQuestionStatus("save and mark", false)}
                  />
                </li>
                <li>
                  <Button
                    className="proceed border-btn yellow-btn text-white border-0"
                    link=""
                    title="Mark For Review And Next"
                    onClick={() => handleQuestionStatus("mark and next", false)}
                  />
                </li>
              </ul>
            </div>

            <div className="m-auto">
              <ul className="ms-auto">
                <li>
                  <Button
                    className="proceed gray-btn"
                    onClick={() =>
                      handleClearAnswer(currentQuestionIndex, selectedTabId)
                    }
                    title="Clear"
                  />
                </li>
                {examStructure.at(-1)?.id !== selectedTabId &&
                  isEnabledSectionLimit && (
                    <li>
                      <Button
                        className="process small-btn"
                        title="Submit Section"
                        onClick={async () => {
                          setSubmitSection(true);
                          playPauseTimer(false);
                        }}
                      />
                    </li>
                  )}
                <li>
                  <Button
                    className="proceed small-btn"
                    onClick={async () => {
                      playPauseTimer(false);
                      await handleQuestionStatus("save and next", true);
                      await handleSubmission(submitDataResponse);
                      await getSubmittedQuestionCount();
                      setConfirmationModal(true);
                    }}
                    link=""
                    title="Submit"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="exam_mobile_btn">
            <Button
              title="Save/Navigate"
              className="proceed small-btn mt-3"
              onClick={() => setMobileBtnModal(true)}
            />
          </div>
        </div>
        {selectedTabId && submitDataResponse?.length > 0 ? (
          <QuestionPallete
            remainingTime={timeRemaining}
            questionsData={submitDataResponse}
            handleClasses={handleClasses}
            handleQuestionClick={handleQuestionClick}
            selectedTabId={selectedTabId}
            sectionTimeRemaining={sectionTimeRemaining}
            slotTimeRemaining={
              slotTimeRemaining !== undefined ? slotTimeRemaining : 0
            }
            isEnabledSectionTimeLimit={isEnabledSectionLimit}
            isEnabledQuestionLimit={isEnabledQuestionLimit}
            isEnabledSlot={isEnableSlot}
            totalAttempts={questionLimit}
          />
        ) : (
          ""
        )}
      </div>

      <div>
        <CountModal countModal={countModal} setCountModal={setCountModal} />
      </div>
      <div className="confirmation_modal">
        <CommonModal
          className="section_modal"
          show={submitSection}
          close={() => setSubmitSection(true)}
          crossShow={false}
          body={
            <div style={{ fontSize: "16px", fontWeight: "400" }}>
              <p className="form-label text-center">
                Are you sure you want to submit the current Section?{" "}
              </p>
              <p className="form-label text-center">
                No changes will be allowed after submission.
              </p>
              <p className="form-label text-center">
                After submit the current section you will move to the next
                section
              </p>
              <div className="d-flex justify-content-center mb-3">
                <Button
                  className="btn theme-btn small-btn py-3 me-4"
                  title="Yes"
                  onClick={handleSubmitSection}
                />
                <Button
                  className="btn theme-btn small-btn py-3"
                  title="No"
                  onClick={() => {
                    playPauseTimer(true);
                    setSubmitSection(false);
                  }}
                />
              </div>
            </div>
          }
        />
        <MockExamSummaryModal
          confirmationModal={confirmationModal}
          setConfirmationModal={setConfirmationModal}
          submittedQuestionData={submittedQuestionData}
          totalQuestions={totalQuestions}
          isEnableSlot={isEnableSlot}
          lastStructureObject={lastStructureObject}
          selectedTabId={selectedTabId}
          playPauseTimer={playPauseTimer}
          finalExamSubmitHandler={finalExamSubmitHandler}
        />
      </div>
      <div className="">
        <MockSectionModal
          sectionModal={sectionModal}
          moveToNextSection={moveToNextSection}
        />
      </div>
      <div>
        <ViewResultModal
          viewResultHandler={viewResultHandler}
          viewResultModal={viewResultModal}
          resultDate={resultDate}
          buttonTitle={viewResultBtnTitle}
          isSubjectiveMarking={isSubjectiveMarking}
        />
      </div>
      <div className="cuet_modal">
        <MockCuetModal cuetModal={cuetModal} moveToNextSlot={moveToNextSlot} />
      </div>
      {isWebCamEnabled && <MockWebcam hasCameraAccess={hasCameraAccess} />}
      <NavigationDrawer
        mobileBtnModal={mobileBtnModal}
        setMobileBtnModal={setMobileBtnModal}
        handleQuestionStatus={handleQuestionStatus}
        currentQuestionIndex={currentQuestionIndex}
        questions={questions}
        selectedTabId={selectedTabId}
        handleClearAnswer={handleClearAnswer}
        playPauseTimer={playPauseTimer}
        handleSubmission={handleSubmission}
        submitDataResponse={submitDataResponse}
        getSubmittedQuestionCount={getSubmittedQuestionCount}
        setConfirmationModal={setConfirmationModal}
        setSubmitSection={setSubmitSection}
        isEnabledSectionLimit={isEnabledSectionLimit}
        examStructure={examStructure}
      />
    </>
  );
};

export default Exams;
