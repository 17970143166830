import React, { useContext, useEffect, useState } from "react";
import { userService } from "../../../_services/User.services";
import { AppContext } from "../../shared/AppContext";
import { useBreadcrumb } from "../../shared/breadcrumb-context";
import { Modal } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";

import Table from "../../utils/table/Table";
import SubjectCards from "../../utils/card/SubjectCards";
import RightSidebar from "../portalLayout/RightSidebar";
import Button from "../../utils/forms/Button";
import Pagination from "react-js-pagination";
import MockTestButton from "./MockTestButton";
import Image from "../../utils/image/Image";
import {
  convertIsoToOrdinalDate,
  convertMinutesToHours,
  formatTime,
} from "../../utils/dateTime";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const classes = [
  "lightPink",
  "greenBG",
  "yellowBG",
  "darkBlue",
  "pinkBG",
  "lightBlue",
];

const Tests = () => {
  const { setSpinner, setToaster } = useContext(AppContext);
  const { dispatch } = useBreadcrumb();
  const [ExamData, setExamData] = useState([]);
  const [latestExamTable, setLatestExamTable] = useState([]);
  const [total, setTotal] = useState();
  const [activePage, setActivePage] = useState(1);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewFile, setPreviewFile] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [studentId, setStudentId] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [performanceData, setPerformanceData] = useState([]);

  // *****************Exam examCategories*****************
  const examCategories = async () => {
    setSpinner(true);

    try {
      const response = await userService.examCategories({});

      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setSpinner(false);
        // data api
        let tempary = [];
        let temphsh = {};
        let subAry = [];
        data?.data
          ?.filter(
            (a) =>
              a?.exam_count !== 0 &&
              !a?.name?.toLowerCase()?.includes("home assignment")
          )
          ?.forEach((item, i) => {
            subAry = [
              {
                class: "Total Tests:",
                class_data: item?.exam_count ? (
                  item?.exam_count
                ) : (
                  // <span className="alert-text">N/A</span>
                  <span className="gray-text">-</span>
                ),
              },
              {
                class: "Attempted:",
                class_data: !item?.attempted ? (
                  // <span className="alert-text">N/A</span>
                  <span className="gray-text">-</span>
                ) : (
                  item?.attempted
                ),
              },
              {
                class: "Best Rank:",
                class_data: !item?.best_rank ? (
                  // <span className="alert-text">N/A</span>
                  <span className="gray-text">-</span>
                ) : (
                  item?.best_rank
                ),
              },
            ];

            temphsh = {
              id: i + 1,
              name: item?.name,
              className: i >= 6 ? classes[i - 6] : classes[i],
              url: `/test/exam_category/${item?.id}`,
              data: subAry,
              isBreadCrumb: true,
              // Get the exam id in exam category
              exam_id: item?.exam[0]?.id,
            };
            tempary.push(temphsh);
          });

        setExamData(tempary);
      }
      if (response?.status === 401) {
        localStorage.removeItem("_prathamStudentToken");
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();

        setSpinner(false);
        setToaster({
          show: true,
          type: "danger",
          content: data.message,
        });
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  // **************** Performance Data API******************
  const getReportData = async () => {
    setSpinner(true);
    try {
      const response = await userService.getTestReport();
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setPerformanceData([
          {
            id: 1,
            icon: "images/icon08.svg",
            title: "Total Tests",
            result: data?.data?.total_test,
            className: "card-box redBG",
            isSpeed: false,
          },
          {
            id: 2,
            icon: "images/icon1.svg",
            title: "Attempted",
            result: data?.data?.attempted_tests,
            className: "card-box greenBG",
            isSpeed: false,
          },
          {
            id: 3,
            icon: "images/icon09.svg",
            title: "Speed",
            result: data?.data?.speed,
            className: "card-box yellowBG",
            isSpeed: true,
          },
          {
            id: 4,
            icon: "images/icon13.svg",
            className: "card-box pinkBG",
            title: "Accuracy",
            result: data?.data?.accuracy,
            isSpeed: false,
          },
        ]);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setSpinner(false);
        setToaster({
          show: true,
          type: "danger",
          content: data?.message,
        });
      }
      if (response?.status === 401) {
        localStorage.removeItem("_prathamStudentToken");
        setSpinner(false);
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  // **************Latest Exam**************
  const latestExamData = async (pageNo) => {
    let currentPage = pageNo === undefined ? 1 : pageNo;
    setSpinner(true);

    try {
      const response = await userService.latestExamData({
        page: currentPage,
        size: 10,
      });
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setLatestExamTable(
          Object.values(data?.data?.exam?.data)
            ?.filter((a) => a?.name)
            ?.map((item) => {
              return [
                { value: item.name, id: 1 },
                { value: item.category?.name, id: 2 },
                {
                  value: convertMinutesToHours(item.duration),
                },
                {
                  id: 4,
                  value: (
                    <>
                      {item?.attempted_on ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: convertIsoToOrdinalDate(item.attempted_on),
                          }}
                        />
                      ) : (
                        <span className="text-center d-block w-00">-</span>
                      )}
                    </>
                  ),
                },
                {
                  id: 5,
                  value:
                    (item?.score === 0 || item?.score !== null) &&
                    item.status !== 6 &&
                    new Date(item?.result_date) <= new Date()
                      ? `${item?.score}/${item?.exam_template_structure?.reduce(
                          (acc, curr) =>
                            acc +
                            Number(curr?.correct_marks) *
                              Number(curr?.total_questions),
                          0
                        )}`
                      : "-",
                  className: "text-center",
                },
                {
                  id: 6,
                  value:
                    (item?.rank || item?.rank === 0 || item?.rank !== null) &&
                    item?.total_test_students &&
                    item.status !== 6 &&
                    new Date(item?.result_date) <= new Date()
                      ? `${item?.rank}/${item?.total_test_students}`
                      : "-",
                  className: "text-center",
                },
                {
                  id: 7,
                  value: (
                    <MockTestButton
                      status={item.status}
                      id={item?.id}
                      startDate={item.start_date}
                      endDate={item?.end_date}
                      resultDate={item?.result_date}
                      examRetake={item?.exam_retakes}
                      examStatus={item?.exam_status}
                      examTemplateId={item.exam_template_id}
                      categoryExamName={item?.category?.name?.toLowerCase()}
                      categoryExamRetake={item?.category?.exam_retake}
                      subjectName={item?.name}
                      onChangeOMR={(e) => handleFileChange(e)}
                      onClickOMR={() => {
                        document.getElementById("file_upload").click();
                        localStorage.setItem("exam_id", item?.id);
                      }}
                      totalQuestions={item?.total_questions}
                      examName={item?.name}
                      questionLimit={Number(
                        item?.exam_template_structure[0]?.limit
                      )}
                      examTemplateUIId={item?.category?.template?.id}
                    />
                  ),
                },
              ];
            })
        );
        setSpinner(false);
        setTotal(data?.data?.exam.total);
      }
      if (response?.status === 401) {
        localStorage.removeItem("_prathamStudentToken");
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();

        setSpinner(false);
        setToaster({
          show: true,
          type: "danger",
          content: data.message,
        });
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  const onPageChange = (e) => {
    let pageNo = e;
    setActivePage(pageNo);
    latestExamData(pageNo);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  // Handling OMR Upload Image Or PDF
  const handleFileChange = (event) => {
    const acceptedFileTypesRegex = /\.(jpg|jpeg|png|pdf)$/i;

    // Check the file length should be greater than 0
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      // Check the file type should image or pdf
      if (file && acceptedFileTypesRegex.test(file?.name)) {
        // Create a File reader
        const reader = new FileReader();
        reader.onload = () => {
          setPreviewFile(reader.result);
        };
        reader.readAsDataURL(file);
        setIsModalOpen(true);
      } else {
        setToaster({
          show: true,
          type: "danger",
          content: "File Type should be only image or pdf",
        });
      }
    }
  };

  // Handling OMR Image or PDF Submit API
  const handleUpload = async (event) => {
    event.preventDefault();
    setSpinner(true);
    try {
      const formData = new FormData();
      formData.append(
        "exam_id",
        typeof window !== undefined
          ? parseInt(window?.localStorage.getItem("exam_id"))
          : ""
      );
      formData.append("student_id", studentId);
      formData.append("omr_attachment", selectedFile);

      const response = await userService.uploadOMRExam(formData);

      // Handle API Response
      if (response.status >= 200 && response.status <= 299) {
        // Call an API again when OMR upload succesfully & changed the button
        latestExamData();

        // Show Message when OMR upload successfully
        setToaster({
          show: true,
          type: "success",
          content: "File is uploaded successfully",
        });
        setSpinner(false);

        //File state reset when omr upload successfully

        // Preview state reset when omr upload successfully
        setPreviewFile("");

        // Modal Open for the preview of uploaded OMR
        setIsModalOpen(false);

        setSelectedFile(null);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        const errorMessages = Object.values(data.messages).flat().join(", ");

        // Show error toast
        setToaster({
          show: true,
          type: "danger",
          content: errorMessages,
        });

        // File state reset due to an error
        setSelectedFile(null);

        // File Preview state reset due to an error
        setPreviewFile("");

        setSpinner(false);

        // File Preview modal close due to an error
        setIsModalOpen(false);
      }
    } catch (error) {
      setSpinner(false);
      setToaster({
        show: true,
        type: "danger",
        content: error.message,
      });
    }
  };

  useEffect(() => {
    Promise.all([examCategories(), latestExamData(), getReportData()]);
    if (typeof window !== undefined) {
      const studentInfo = window?.localStorage.getItem("studentInfo");
      const parsedStudentInfo = studentInfo ? JSON?.parse(studentInfo) : null;
      setStudentId(parsedStudentInfo?.id);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch({
      type: "SET_BREADCRUMB",
      crumb: [{ title: "Tests", url: "/test" }],
    });
  }, []);

  return (
    <div className="col-md-12 d-flex gap-3">
      <div className="white-box body-cont test-body">
        {/* <div className="row reportcard_row  border-bottom-dashed mb-5">
          {performanceData?.map((test, index) => {
            return (
              <div
                className="col-xxl-3  col-xl-4 col-lg-6 col-md-6 col-sm-6"
                key={index + 1}
              >
                <ReportCards
                  title={test?.title}
                  result={test?.result}
                  image={test?.icon}
                  className={test?.className}
                  isSpeed={test?.isSpeed}
                />
              </div>
            );
          })}
        </div> */}
        <div className="sub-report pb-4 mb-5 border-bottom-dashed ">
          <div className="dash-heading mb-4 d-flex justify-content-between">
            <h1>Exam Categories</h1>
          </div>
          <SubjectCards subJectData={ExamData} className="mt-0" isButton />
        </div>
        <div className="sub-report">
          <div className="dash-heading">
            <h1>Latest Exams</h1>
          </div>
          {latestExamTable?.length > 0 && (
            <Table
              heading={[
                {
                  value: "Name",
                  id: 1,
                  className: "title-data",
                },
                {
                  value: "Exam Category",
                  id: 2,
                  className: "",
                },
                {
                  value: "Duration",
                  id: 3,
                  className: "text-capitalize",
                },
                {
                  value: "Attempted",
                  id: 4,
                  className: "",
                },
                { value: "Score", id: 5, className: "" },
                { value: "Rank", id: 6, className: "" },
                {
                  value: "Status",
                  id: 7,
                  className: "text-capitalize",
                  // className: "actionTh"
                },
              ]}
              rows={latestExamTable}
            />
          )}
        </div>
        {total >= 10 && (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={total}
            pageRangeDisplayed={5}
            onChange={(e) => onPageChange(e)}
            hideFirstLastPages={true}
          />
        )}
      </div>
      <div>
        <Modal
          size="lg"
          show={isModalOpen}
          centered
          onHide={() => setIsModalOpen(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "20px" }}>File Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleUpload}>
              {previewFile.includes("data:image") && (
                <div className="d-flex justify-content-center mt-5">
                  <Image
                    width="95%"
                    height="95%"
                    src={previewFile}
                    alt="preview-file-image"
                  />
                </div>
              )}
              {previewFile.includes("data:application/pdf") && (
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 d-flex">
                      <div
                        className="doc-viewer"
                        style={{ overflow: "hidden" }}
                      >
                        <Document
                          file={"data:application/pdf;" + previewFile}
                          renderMode="canvas"
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          <Page
                            height={100}
                            width={1000}
                            pageNumber={pageNumber}
                          />
                        </Document>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="d-flex justify-content-center pb-5">
                <Button
                  className="btn theme-btn dark-blue mt-5 w-25"
                  disabled={!selectedFile}
                  title="Submit"
                />
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
      <div className="sidebar">
        <RightSidebar />
      </div>
    </div>
  );
};

export default Tests;
