import React, { useState, useContext, useEffect } from "react";

import axios from "axios";
import Auth from "../../auth/Auth";
import * as tus from "tus-js-client";
import { AppContext } from "../shared/AppContext";
import { useForm } from "@kaydhiman/react-hook-useform";
import { userService } from "../../_services/User.services";

import Button from "../utils/forms/Button";
// import InputField from '../utils/forms/InputField';
import CommonModal from "../utils/modal/CommonModal";

import cameraImg from "../../assets/images/camera.png";
// import varfyIcon from "../../assets/images/varification.svg";
import fileattached from "../../assets/images/attach-file.png";
import { useNavigate } from "react-router-dom";
import Image from "../utils/image/Image";

function Testimonial() {
  const { values, bindField, setInitialValues } = useForm({ validations: {} });
  const { setSpinner, setToaster, userData } = useContext(AppContext);
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [videoUri, setVideoUri] = useState();
  const [attachment, setAttachment] = useState();
  const [isUploadVideo, setIsVideoUpload] = useState(false);

  const vimeoUploader = async () => {
    setSpinner(true);
    let url = `https://api.vimeo.com/me/videos`;

    const res = await axios({
      method: "post",
      url: url,
      headers: {
        Authorization: `Bearer 0a9ff75bdf53f8c4bfab3b7c43c11434`,
        Accept: "	application/vnd.vimeo.*+json;version=3.4",
        "Content-Type": "application/json",
      },

      data: {
        upload: {
          approach: "tus",
          size: file?.size?.toString(),
        },
        name: values?.testimonial,
        description: values.description,
      },
    }).catch(function (error) {
      setToaster({
        show: true,
        type: "danger",
        content: error?.response?.data?.error,
      });

      setSpinner(false);
    });

    if (res?.status === 200) {
      setVideoUri(res?.data?.uri?.split("/")[2]);

      const upload = new tus.Upload(file, {
        // endPoint: url,
        endPoint: `https://api.vimeo.com/me/videos`,
        uploadUrl: res?.data?.upload?.upload_link,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: file.name,
          filetype: file.type,
        },
        headers: {},
        onError: function (error) {
          console.log("Failed because: " + error);
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          console.log(bytesUploaded, bytesTotal, percentage + "%");
        },
        onSuccess: function () {
          console.log("Download %s from %s", upload.file.name, upload.url);
          setToaster({
            show: true,
            type: "success",
            content: "Video uploaded successfully.",
          });
          // setVideoUrl(upload.url);
          setSpinner(false);
          setIsVideoUpload(false);
        },
      });

      // Start the upload
      upload.start();
    }
  };

  //------------------api to get courses--------------
  const addTestimonial = async (e) => {
    setSpinner(true);
    e.preventDefault();
    try {
      let formData = new FormData();

      if (videoUri) formData.append("video", videoUri);
      if (attachment) formData.append("image", attachment);
      formData.append("testimonial", values?.testimonial);

      const response = await userService.addTestimonial(formData);
      setSpinner(false);
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        navigate("/dashboard");
        setInitialValues({});
        setVideoUri();
        setFile();
        setAttachment();
        setToaster({ show: true, type: "success", content: data.message });
      }
      if (response?.status === 401) {
        localStorage.removeItem("_prathamStudentToken");
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();

        setToaster({ show: true, type: "danger", content: data.message });
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  useEffect(() => {
    if (file?.name) {
      vimeoUploader();
    }
    //eslint-disable-next-line
  }, [file]);

  return (
    <div className="testimonial_dash">
      <div className="white-box">
        <div className="adminForm  profile-form ">
          <div className="profile-pic-row ">
            <div className=" col-sm-block d-sm-flex mb-5 mb-lg-0 ">
              <div
                className="profile-box border-end me-sm-5"
                // onClick={profilePicModalShow}
              >
                <label className="pointer">
                  <img
                    src={cameraImg}
                    alt="Camera"
                    className="position-absolute camera-img"
                  />
                </label>
                <img
                  src={userData?.image}
                  alt="User"
                  className="img-fluid current-img user-img"
                />
              </div>
              <div className="input-area w-100 ">
                {/* <InputField
                                    label="Add Testimonial"
                                    name="name"
                                    bindField={bindField}
                                    value={values?.name || ""}
                                    id="name"
                                    placeholder="Enter Your Testimonial"
                                    type="text"
                                    required
                                    disable={true}
                                    className="txt-textimonial"
                                /> */}
                <div className="testimonial-field">
                  <label htmlFor="">Add Testimonial</label>
                  <textarea
                    placeholder="Enter Your Testimonial"
                    className="txt-textimonial"
                    {...bindField("testimonial")}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="button-footer row">
              <div className="upload_fil">
                <Button
                  className="proceed  small-btn mt-0 me-3"
                  title="Upload Video"
                  onClick={() => setIsVideoUpload(true)}
                  disable={!values?.testimonial}
                />
                {/* <label className="icon_btn btn mb-0 me-col-0 me-3">
                                    Upload Video
                                    <input type="file"
                                        label="" title="Choose a video please"
                                        name="name"
                                        accept="video/mp4,video/x-m4v,video/*"
                                    />
                                </label> */}

                <label className="icon_btn gray-border-btn ">
                  <Image src={fileattached} alt="icon" />
                  Attach Files
                  <input
                    type="file"
                    label=""
                    name="name"
                    onChange={(e) => {
                      setAttachment(e.target?.files[0]);
                    }}
                    // accept="video/mp4,video/x-m4v,video/*"
                  />
                </label>
              </div>
              <div className="submit-btn">
                <Button
                  className="proceed  theme-btn "
                  title="Submit Feedback"
                  onClick={addTestimonial}
                  disable={!values?.testimonial && !attachment && !videoUri}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <CommonModal
        show={isUploadVideo}
        close={() => setIsVideoUpload(false)}
        className="video-upload-modal"
        body={
          <div className="common_modal">
            <div className="modal-footer d-block pb-5  pt-0">
              <p>Do you want to upload video?</p>

              <div className="button-footer w-100">
                <ul className="d-flex list-unstyled">
                  <li>
                    {/* <Button
                                            className="proceed  small-btn me-4"
                                            title="Yes"
                                        // onClick={viewResult}
                                        /> */}

                    <label
                      className="icon_btn  theme-btn small-btn me-4 btn text-white"
                      style={{ backgroundColor: "#fe8057" }}
                    >
                      Yes
                      <input
                        type="file"
                        label=""
                        title="Choose a video please"
                        name="name"
                        accept="video/mp4,video/x-m4v,video/*"
                        onChange={(e) => {
                          setFile(e.target?.files[0]);
                        }}
                      />
                    </label>
                    <Button
                      className="proceed  small-btn me-4"
                      title="No"
                      onClick={() => setIsVideoUpload(false)}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default Testimonial;
