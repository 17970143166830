import React from "react";
import { Link } from "react-router-dom";
import Card from "../../utils/card/Card";

const HomeShortcutCard = ({ card, batchType }) => {
  // Destructuring the properties from the `card` object for easier access
  const {
    parent_shortcut_id,
    batch_type,
    url,
    id,
    shortcut_content_type,
    name,
    icon,
    url_target,
    content_type,
    is_active,
  } = card;

  // Check if the card should be visible based on the provided conditions
  const isCardVisible =
    (is_active === 1 &&
      parent_shortcut_id === null &&
      Number(batch_type) === 0) || // Condition when batch_type is 0
    (![null, 0].includes(Number(batch_type)) &&
      Number(batchType) === Number(batch_type)); // Condition when batchType matches batch_type and is not null or 0

  // Return early if the card does not meet the visibility conditions
  if (!isCardVisible) return null;

  // Determine if the Link should redirect to the URL or fallback to the default shortcut route
  const isRedirect =
    parent_shortcut_id === null &&
    url !== null &&
    [2, 3].includes(shortcut_content_type); // Redirect if content type is 2 or 3 and there is a valid URL

  // Map the content_type to extract resources for the Link state
  const resources = content_type?.map((resource) => ({
    id: resource?.id,
    name: resource?.name,
  }));

  return (
    <div className="col-lg-4 col-sm-6">
      {/* Link component with dynamic URL and target based on `isRedirect` */}
      <Link
        to={isRedirect ? url : `/dashboard/shortcut/${id}`} // Use `url` if `isRedirect` is true, otherwise use default shortcut URL
        state={{
          folder_name: name || "Folder", // Default folder name if `name` is not available
          resource: resources, // Pass mapped resources as state to the Link
        }}
        target={isRedirect ? `_${url_target}` : "_self"} // Use `url_target` if `isRedirect` is true, otherwise open in the same tab
      >
        {/* Card component to display the shortcut's title and icon */}
        <Card title={name} image={icon} className="assignment-card" />
      </Link>
    </div>
  );
};

export default HomeShortcutCard;
