import React, { useContext, useEffect, useRef, useState } from "react";
import Auth from "../../../auth/Auth";
import attachment from "../../../assets/images/attach_file.svg";
import pdfIcon from "../../../assets/images/pdf.png";
import send_message from "../../../assets/images/sned_mgs_icon.svg";
import docIcon from "../../../assets/images/docs.svg";
import crossIcon from "../../../assets/images/red_cross.svg";
import placeholder from "../../../assets/images/image-placeholder.jpg";
import playIcon from "../../../assets/images/play.svg";

import { AppContext } from "../../shared/AppContext";
import { userService } from "../../../_services/User.services";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import moment from "moment";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import Image from "../../utils/image/Image";

function ChatingBox({ setMsgText, msgText, isHistory }) {
  const { threadId } = useParams();
  const {
    msgContent,
    setMsgContent,
    setSpinner,
    setToaster,
    threadInfo,
    seconds,
    setSeconds,
  } = useContext(AppContext);
  const [showImage, setShowImage] = useState();
  const [showImg, setShowImg] = useState("");
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [file, setFile] = useState();
  const fileRef = useRef();

  const chatTimeIntervals = localStorage.getItem("chatTimeIntervals")?.length
    ? JSON.parse(localStorage.getItem("chatTimeIntervals"))
    : [];
  const token = localStorage.getItem("PSIS_token");

  const loadPrivateImage = async (ref, url) => {
    if (ref.alt !== "img-loaded") {
      try {
        const imgResponse = await fetch(
          `https://chat.prathamedu.com/api/storage/public/` + url,
          {
            headers: {
              "x-token": `${Auth?.user()?.user_id}|${token}`,
            },
          }
        );
        if (imgResponse.status === 200) {
          let body = await imgResponse.blob();
          ref.alt = "img-loaded";
          ref.src = URL.createObjectURL(body);
        } else {
          ref.alt = "img-loaded";
          ref.src = placeholder;
        }
      } catch (error) {}
    }
  };

  const imageBigSize = (event, type) => {
    Fancybox.show([
      {
        src: event.target.src,
        type: type,
        Toolbar: {
          display: {
            left: ["infobar"],
            middle: [
              "zoomIn",
              "zoomOut",
              "toggle1to1",
              "rotateCCW",
              "rotateCW",
              "flipX",
              "flipY",
            ],
            right: ["slideshow", "thumbs", "close"],
          },
        },
      },
    ]);
  };

  const clearFile = () => {
    fileRef.current.value = "";
    setShowImg();
    setFile();
  };

  const convertToLocalmessageDate = (date) => {
    let local = moment.utc(date).local().format("DD/MM/YYYY HH:mm A");
    return local;
  };

  //===============================* OnChange function for attachment *=====================================================
  const selectAttachment = (file) => {
    if (!file[0].type) {
      setToaster({
        show: true,
        type: "danger",
        content: "Please select valid file!",
      });
      clearFile();
      return;
    } else if (
      file[0]?.name?.match(
        /\.(exe|apk|html|htm|ini|cdr|php|jsp|asp|sql|msi|rar|zip|7zip)$/
      )
    ) {
      // alert("Please select valid file!");
      setToaster({
        show: true,
        type: "danger",
        content: "Please select valid file!",
      });
    } else if (file[0]?.size > 25e6) {
      setToaster({
        show: true,
        type: "danger",
        content: `Please upload file lesser then 25 MB`,
      });
    } else {
      setFile(file[0]);
      let a = URL.createObjectURL(file[0]);
      setShowImg(a);
      // setSelectedFileType(file[0]?.type);

      // if (file[0].type === "application/pdf") {
      //   setShowImg();
      // }
    }
  };

  // =======================================Upload Attachment ===============================================

  const uploadAttackment = async (videoThumb = "") => {
    setSpinner(true);
    try {
      let params = {
        name: file.name,
        type: videoThumb ? videoThumb.type : file.type,
        threadId: threadId,
        body: videoThumb ? videoThumb : file,
        token: `${Auth?.user()?.user_id}|${token}`,
      };
      const response = await userService?.uploadAttachment(params);

      if (response?.status >= 200 && response?.status <= 299) {
        const data = await response.json();
        setSpinner(false);
        return data?.id;
      } else {
        setSpinner(false);
      }
      if (response?.status === 401) {
        localStorage.clear();
      }
    } catch (error) {
      setSpinner(false);
      return error;
    }
  };

  //===================================== GET THUMBNAIL FROM VIDEO ==============================================
  const getThumbnailFromVideo = (file) => {
    if (!file.type.startsWith("video/")) {
      return;
    }

    return new Promise((resolve, reject) => {
      const video = document.getElementById("videoView");
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, 150, 150);
      ctx.canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/png");
    });
  };

  //===================================== SEND MESSAGE API ==============================================

  const sendMessage = async () => {
    setSpinner(true);
    try {
      if (
        Auth?.user()?.role === "student" &&
        (!threadInfo?.enableStudentChat ||
          !threadInfo?.enableFileSharing ||
          threadInfo.studentChatPause > 0)
      ) {
        // getThreadInfo("msgSend");
        setSpinner(false);
      }

      if (Auth?.user()?.role === "student" && !threadInfo?.enableStudentChat) {
        setSpinner(false);
        setToaster({
          show: true,
          type: "danger",
          content:
            "Ssh! teacher only mode. Message sending is off by the teacher",
        });
        return;
      }
      let attachId = "";
      let thumbnailId = "";
      let msgType = "TEXT";
      let thumbnailImg;

      if (file) {
        if (file?.type?.startsWith("image/")) {
          msgType = "MEDIA_IMAGE";
        } else if (file.type.startsWith("video/")) {
          msgType = "MEDIA_VIDEO";
          thumbnailImg = await getThumbnailFromVideo(file);
          thumbnailId = await uploadAttackment(thumbnailImg);
        } else if (file.type.startsWith("audio/")) {
          msgType = "MEDIA_AUDIO";
        } else {
          msgType = "ATTACHMENT";
        }
        attachId = await uploadAttackment();
        if (!attachId) {
          setSpinner(false);
          // alert("No sharing please! File sharing off by the teacher");
          setToaster({
            show: true,
            type: "danger",
            content: "No sharing please! File sharing off by the teacher",
          });

          // setShowImg("");
          setFile();
          // setTimeout(() => {
          //   setBtnPressed(false);
          //   setNewMsgSend(false);
          // }, 0);
          return;
        }
        setSpinner(false);
      }

      let params = {
        token: `${Auth?.user()?.user_id}|${token}`,
        threadId: threadId,
        body: {
          threadId: threadId,
          content: msgText.trim().length === 0 ? "" : msgText,
          type: msgType,
          ...(attachId
            ? {
                meta: {
                  attachment: attachId,
                  ...(thumbnailId ? { thumbnail: thumbnailId } : ""),
                },
              }
            : ""),
        },
      };
      const response = await userService.createClassMessage(params);
      if (response?.status >= 200 && response?.status <= 299) {
        setSpinner(false);
        setMsgText("");

        if (Number(threadInfo?.studentChatPause) > 0) {
          const eleIndex = chatTimeIntervals.findIndex(
            (ele) => ele.id === threadId
          );
          eleIndex === -1
            ? chatTimeIntervals.push({
                id: threadId,
                time: Number(threadInfo?.studentChatPause),
              })
            : (chatTimeIntervals[eleIndex].time = Number(
                threadInfo?.studentChatPause
              ));
          localStorage.setItem(
            "chatTimeIntervals",
            JSON?.stringify(chatTimeIntervals)
          );
          setSeconds(threadInfo?.studentChatPause);
        }
        clearFile();
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setSpinner(false);
        setToaster({
          show: true,
          type: "danger",
          content: data?.errors?.content?.message,
        });
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  // file load for audio,video file etc
  const fileLoad = async (event, msg, fileName = "") => {
    let apiUrl =
      `https://chat.prathamedu.com/api/storage/public/` +
      msg?.meta?.attachment?.key;

    if (msg?.type === "MEDIA_VIDEO" || msg?.type === "MEDIA_AUDIO") {
      apiUrl = `https://chat.prathamedu.com/api/messages/${msg?._id}/media/sign?thread=${threadId}`;
    }
    try {
      const fileResponse = await fetch(apiUrl, {
        headers: {
          "x-token": `${Auth?.user()?.user_id}|${token}`,
        },
        method: msg?.type === "ATTACHMENT" ? "GET" : "POST",
      });

      if (fileResponse.status === 200) {
        let url = "";
        if (msg?.type === "ATTACHMENT") {
          if (fileResponse?.url?.includes("pdf")) {
            url = window.URL.createObjectURL(
              msg?.type !== "ATTACHMENT"
                ? new Blob([await fileResponse.blob()])
                : new Blob([await fileResponse.blob()], {
                    type: "application/pdf",
                  })
            );
          }

          if (fileResponse?.url?.includes("zip")) {
            url = window.URL.createObjectURL(
              msg?.type !== "ATTACHMENT"
                ? new Blob([await fileResponse.blob()])
                : new Blob([await fileResponse.blob()], {
                    type: "application/zip",
                  })
            );
          }

          if (fileResponse?.url?.includes("docx")) {
            url = window.URL.createObjectURL(
              msg?.type !== "ATTACHMENT"
                ? new Blob([await fileResponse.blob()])
                : new Blob([await fileResponse.blob()], {
                    type: "application/docx",
                  })
            );
          }

          if (msg?.type === "ATTACHMENT") {
            const a = document.createElement("a");
            a.setAttribute("download", fileName);
            a.setAttribute("target", "_blank");
            a.href = url;
            a.click();
          }
        } else if (msg?.type === "MEDIA_VIDEO") {
          fileResponse.json().then((urlId) => {
            setShowVideoModal(true);
            setVideoUrl(
              `https://chat.prathamedu.com/api/links/${urlId.token}?signableId=${msg?._id}`
            );
            // Fancybox.show([
            //   {
            //     src: `https://chat.prathamedu.com/links/${urlId.token}?signableId=${msg?._id}`,
            //     type: 'video',
            //     // controlsList: "nodownload"
            //   },
            // ]);
          });
        } else if (msg?.type === "MEDIA_AUDIO") {
          return new Promise((resolve, reject) => {
            fileResponse.json().then((urlId) => {
              if (urlId) {
                resolve(
                  `https://chat.prathamedu.com/api/links/${urlId.token}?signableId=${msg?._id}`
                );
              }
            });
          });
        }
      }
    } catch (error) {}
  };

  const checkTextUrl = (text, ev = "") => {
    const pattern =
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

    if (ev && ev === "click") {
      if (pattern.test(text)) {
        window.open(text, "_blank");
        return;
      }
      return;
    }

    if (pattern.test(text)) {
      return true;
    }
    return false;
  };

  //==========================GET LIST OF MESSAGES===================================
  const getThreadMessages = async () => {
    setSpinner(true);
    try {
      const res = await userService?.getThreadMessage(
        threadId,
        `${Auth?.user()?.user_id}|${token}`
      );
      res.json().then((res) => {
        let newList = res?.messages?.map((ele) => {
          let newObj = Object.assign({}, ele);
          if (newObj.type === "TEXT") {
            newObj.isTextUrl = checkTextUrl(newObj.content);
          }
          if (newObj.type === "MEDIA_AUDIO") {
            const demo = fileLoad("", newObj);
            demo.then((url) => {
              newObj.audioUrl = url;
            });
          }
          return newObj;
        });
        setSpinner(false);
        // setMsgContent(res.messages)
        setMsgContent(newList);
        if (res?.status >= 400 && res?.status <= 500) {
          setSpinner(false);
          // localStorage.clear();
        }
      });
    } catch (error) {
      setSpinner(false);
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      sendMessage(e);
    }
  };

  const nameFormating = (f, l) => {
    let name = f + " " + l;
    if (name.length > 15) {
      name = f + " ...";
    }
    return name;
  };

  // ************************for scrolling to the latest message**************************
  // useEffect(() => {
  //   const element = document.getElementById(`msg-${msgContent?.length - 1}`);
  //   element?.scrollIntoView();
  // }, [msgContent])

  useEffect(() => {
    if (seconds > 0 && threadInfo.studentChatPause > 0) {
      setTimeout(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    }
    //eslint-disable-next-line
  }, [seconds]);

  //=========== When slow mode is on and then refresh, store timer value to localstorage========================
  useEffect(() => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();

      let thredIndx = chatTimeIntervals.findIndex((ele) => ele.id === threadId);

      if (threadInfo?.studentChatPause && thredIndx !== -1) {
        chatTimeIntervals[thredIndx].time = seconds;
        localStorage.setItem(
          "chatTimeIntervals",
          JSON?.stringify(chatTimeIntervals)
        );
      }
      return (ev.returnValue = "Do you really want to close?");
    });
    //eslint-disable-next-line
  }, [seconds]);

  useEffect(() => {
    if (token && threadId) getThreadMessages();
    //eslint-disable-next-line
  }, [token]);

  return (
    <>
      <div className="chat_box_body">
        {/* message area  */}
        <div className="chat-logs">
          {/* received message  */}

          {msgContent?.length > 0 &&
            msgContent?.map((msg, i) => {
              return (
                <>
                  {msg?.content?.trim()?.length !== 0 && (
                    <div
                      id={`msg-${i + 1}`}
                      className={`col-lg-8 ${
                        msg?.senderUserId === Auth?.user()?.user_id
                          ? " offset-lg-4 self_people "
                          : "user_people "
                      }`}
                      key={i + 1}
                    >
                      <div className="chat-msg ">
                        {msg?.senderUserId !== Auth?.user()?.user_id && (
                          <div className="group_uname">
                            <div
                              className="chat-header-content"
                              style={{ minWidth: "70%", textAlign: "left" }}
                            >
                              {nameFormating(
                                msg?.sender?.fname,
                                msg?.sender?.lname
                              )}{" "}
                              {msg?.senderUserRole === "TEACHER"
                                ? "(Faculty)"
                                : `(${msg?.senderUserId})`}
                            </div>
                          </div>
                        )}
                        <div
                          className="cm-msg-text"
                          style={
                            msg?.senderUserId !== Auth?.user()?.user_id
                              ? { color: "black" }
                              : {}
                          }
                        >
                          {msg.isTextUrl ? (
                            <div>
                              {msg?.content?.split(" ")?.map((ele, index) => {
                                return (
                                  <>
                                    <div
                                      key={index + 1}
                                      onClick={() => {
                                        if (
                                          ele?.includes("youtube") ||
                                          ele?.includes("vimeo")
                                        ) {
                                          setVideoUrl(ele);
                                          setShowVideoModal(true);
                                        } else {
                                          checkTextUrl(ele, "click");
                                        }
                                      }}
                                    >
                                      <span
                                        key={`key-${index}`}
                                        className={
                                          checkTextUrl(ele) ? "urlSpan" : ""
                                        }
                                      >
                                        {ele + "hahahah "}
                                      </span>
                                    </div>
                                  </>
                                );
                              })}{" "}
                            </div>
                          ) : (
                            msg?.content
                          )}
                        </div>
                      </div>
                      <div className="time_con">
                        {moment.utc(msg.createdAt).local().format("DD/MM")} at{" "}
                        {moment.utc(msg.createdAt).local().format("HH:mm A")}
                      </div>
                    </div>
                  )}
                  {msg?.meta?.attachment && (
                    <div className={`row`}>
                      <div
                        className={`${
                          msg?.type === "MEDIA_AUDIO" ||
                          msg?.type === "ATTACHMENT"
                            ? " col-md-9"
                            : " col-md-8"
                        }
                      // ${
                        msg.type === "MEDIA_AUDIO"
                          ? "col-md-10 offset-lg-2"
                          : ""
                      }
                       ${
                         msg.senderUserRole === "TEACHER"
                           ? msg?.senderUserId ===
                             btoa(String(Auth?.user()?.user_id))
                             ? (msg.type === "MEDIA_AUDIO" ||
                               msg?.type === "ATTACHMENT"
                                 ? "offset-lg-4"
                                 : "offset-lg-4") + " self_people"
                             : "user_people"
                           : msg?.senderUserId == Auth?.user()?.user_id
                           ? (msg.type === "MEDIA_AUDIO" ||
                             msg?.type === "ATTACHMENT"
                               ? " offset-lg-4 "
                               : "offset-lg-4 ") + " self_people"
                           : "user_people"
                       }`}
                      >
                        <div
                          className={`chat-msg group_uname_box ovHidUnset media-box ${
                            msg?.senderUserRole === "TEACHER" &&
                            Auth?.user().role === "student"
                              ? "highlightDiv"
                              : ""
                          }`}
                        >
                          {msg?.senderUserId !== Auth?.user()?.user_id && (
                            <div className="group_uname">
                              <div
                                className="chat-header-content"
                                style={{ minWidth: "70%", textAlign: "left" }}
                              >
                                {nameFormating(
                                  msg?.sender?.fname,
                                  msg?.sender?.lname
                                )}{" "}
                                {msg?.senderUserRole === "TEACHER"
                                  ? "(Faculty)"
                                  : `(${msg?.senderUserId})`}
                              </div>
                            </div>
                          )}
                          <div className="cm-msg-text">
                            {msg?.type === "ATTACHMENT" && (
                              <>
                                <Image
                                  className="pdf-icon"
                                  src={pdfIcon}
                                  width="70px"
                                  height="70px"
                                  title={msg?.meta?.attachment}
                                  alt=""
                                  onClick={() =>
                                    fileLoad(
                                      "",
                                      msg,
                                      typeof msg?.meta?.attachment === "string"
                                        ? msg?.meta?.attachment
                                        : msg?.meta?.attachment.name
                                    )
                                  }
                                />
                                <span
                                  className="attachment-title"
                                  style={{ wordBreak: "break-word" }}
                                  onClick={() =>
                                    fileLoad(
                                      "",
                                      msg,
                                      typeof msg?.meta?.attachment === "string"
                                        ? msg?.meta?.attachment
                                        : msg?.meta?.attachment.name
                                    )
                                  }
                                >
                                  {typeof msg?.meta?.attachment === "string"
                                    ? msg?.meta?.attachment
                                    : msg?.meta?.attachment.name}
                                </span>
                              </>
                            )}
                            {msg?.type === "MEDIA_IMAGE" && (
                              <Image
                                onLoad={(event) =>
                                  loadPrivateImage(
                                    event.target,
                                    typeof msg?.meta?.attachment === "string"
                                      ? msg?.meta?.attachment
                                      : msg?.meta?.attachment.key
                                  )
                                }
                                src={placeholder}
                                // width="100px"
                                // height="100px"
                                alt=""
                                className="chat-img"
                                onClick={(event) =>
                                  imageBigSize(event, "image")
                                }
                              />

                              // <Lightbox image={msg?.meta?.attachment?.name} title="Image Title" />
                            )}
                            {msg?.type === "MEDIA_VIDEO" && (
                              <div className="videoThumb">
                                <Image
                                  className="chat-img videoImg"
                                  onLoad={(event) =>
                                    loadPrivateImage(
                                      event.target,
                                      msg?.meta?.thumbnail?.key
                                    )
                                  }
                                  // src={`${process.env.PUBLIC_URL}/video-placeholder.webp`}
                                  src={placeholder}
                                  // height="120px"
                                  alt=""
                                />
                                <div className="vid-player">
                                  <Image
                                    className="playIc"
                                    src={playIcon}
                                    onClick={(event) => fileLoad(event, msg)}
                                    alt=""
                                  />
                                </div>
                              </div>
                            )}
                            {msg?.type === "MEDIA_AUDIO" && (
                              <audio
                                onLoad={(event) => fileLoad(event, msg)}
                                controlsList="nodownload"
                                src={msg.audioUrl}
                                controls
                                style={{
                                  width: "270px",
                                }}
                              ></audio>
                            )}
                          </div>
                        </div>
                        <div className="time_con">
                          {convertToLocalmessageDate(msg?.createdAt)}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            })}

          {/* message type text area  */}
          {!isHistory && (
            <div className="input_send  ">
              <div className="chat_input position-relative">
                {showImg && (
                  <div className="media-box mx-4  mb-4  border-dark position-relative">
                    <span className="crossimg">
                      <Image
                        src={crossIcon}
                        alt="cross icon"
                        onClick={clearFile}
                      />
                    </span>
                    {file?.type !== "application/pdf" &&
                      !file?.type.includes("video") && (
                        <Image
                          className="img-fluid media-content"
                          // file?.type?.startsWith('video/') ? `${process.env.PUBLIC_URL}/video-placeholder.webp` :
                          src={showImg}
                          alt=""
                        />
                      )}

                    {file?.type === "application/pdf" && (
                      <Image
                        className="img-fluid media-content"
                        src={pdfIcon}
                        alt=""
                      />
                    )}

                    {file?.type?.split("/")[0] !== "image" &&
                      file?.type !== "application/pdf" &&
                      file?.type?.split("/")[0] !== "video" && (
                        <Image
                          className="img-fluid media-content"
                          src={docIcon}
                          alt=""
                        />
                      )}

                    {file?.type.includes("video") && (
                      <video
                        id="videoView"
                        className="text-center"
                        width="300"
                        height="220"
                        controls
                      >
                        <source
                          src={URL.createObjectURL(file)}
                          type="video/mp4"
                        />
                        <source
                          src={URL.createObjectURL(file)}
                          type="video/ogg"
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}

                    {/* <video className="img-fluid media-content" src={showImg}></video> */}
                    <p className="text-center"> {file?.name}</p>
                  </div>
                )}

                <div className="input_prof_icon d-flex justify-content-center">
                  <Image
                    src={
                      Auth.user()?.image
                        ? Auth.user().image
                        : "images/profile-circle.svg"
                    }
                    alt="user"
                  />
                </div>
                {threadInfo?.enableFileSharing && (
                  <div className="input_file_icon">
                    <label for="fileUploadMgs">
                      <Image src={attachment} alt="attach file" />
                    </label>
                    <input
                      type="file"
                      ref={fileRef}
                      id="fileUploadMgs"
                      onChange={(e) => selectAttachment(e.target.files)}
                      style={{ display: "none" }}
                    />
                  </div>
                )}
                <form>
                  <textarea
                    className="w-100 textarea_send"
                    placeholder={
                      seconds > 0 && threadInfo?.studentChatPause > 0
                        ? "Hey! take a breath! go slow! slow mode on"
                        : Auth?.user()?.role === "student" &&
                          !threadInfo?.enableStudentChat
                        ? "Ssh! teacher only mode"
                        : "Add a comment…"
                    }
                    rows="1"
                    value={msgText}
                    onChange={(e) => setMsgText(e.target.value)}
                    onKeyDown={onEnterPress}
                    disabled={
                      (!threadInfo?.enableStudentChat || seconds > 0) &&
                      threadInfo?.studentChatPause > 0
                    }
                  ></textarea>
                </form>
              </div>
              <div className="send_content  pointer">
                {seconds > 0 && threadInfo?.studentChatPause > 0 ? (
                  seconds
                ) : (
                  <>
                    <Image
                      src={send_message}
                      alt="send file my"
                      onClick={sendMessage}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <Modal
                show={show}
                onHide={() => setShow(false)}
                centered
                className="modalclass"
            >
                <Modal.Body>
                    <div className="row sure-delete">
                        <h2>
                            Are you sure,
                            <br />
                            you want to delete this University?
                        </h2>
                        <div className="row delete-btn">
                            <button className={`ml-3 btn mx-0`}>Yes</button>

                            <button className={`btn-light ml-3`}>Yes</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal> */}

      <Modal
        Modal
        show={showVideoModal}
        onHide={() => setShowVideoModal(false)}
        centered
        className=" video-modal"
        // close={() => setShowVideoModal(false)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ReactPlayer
            url={videoUrl}
            playing={true}
            controls={true}
            playIcon={true}
            width="100%"
            height="100%"
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ChatingBox;
