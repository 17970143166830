import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";

import { userService } from "../../../_services/User.services";
import TestRightSidebar from "../../projectDashboard/portalLayout/TestRightSidebar";
import { useForm } from "@kaydhiman/react-hook-useform";
import { NewBackButton } from "../backBtn/NewBackButton";
import { useBreadcrumb } from "../../shared/breadcrumb-context";
import Auth from "../../../auth/Auth";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useContext } from "react";
import { AppContext } from "../../shared/AppContext";
import Image from "../image/Image";
import Vimeo from "@vimeo/player";
import { useResourceTimeSpent } from "../../../hooks/hookList";

// import FileViewer from 'react-file-viewer';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ResourceContainer = ({
  download,
  title,
  resourceName,
  path,
  handleCustomBack,
}) => {
  return (
    <div
      className={`mb-4 ${download && "d-flex justify-content-between"}
    `}
      title="Back to the previous page"
    >
      <h1 className="mb-3 exam_instruction_heading">
        <NewBackButton onClick={() => handleCustomBack()} />
        {title || resourceName}
      </h1>
      {download && (
        <div>
          <a
            className="btn theme-btn small-btn m-0"
            target="_blank"
            href={path}
            download
          >
            Download Document
          </a>
        </div>
      )}
    </div>
  );
};

const DocumentResourceContainer = ({
  pdfUrl,
  numPages,
  pageNumber,
  changePageBack,
  changePageNext,
  isLoadSuccess,
  documentPath,
  isImagePath,
  path,
}) => {
  return (
    <div className="pdf-view" onContextMenu={(e) => e.preventDefault()}>
      {pdfUrl && (
        <div className="pdf-header header-btns ">
          <button
            className="btn theme-btn small-btn prev-btn"
            disabled={pageNumber === 1}
            {...(pageNumber < 2 && {
              onClick: () => {
                changePageBack();
              },
            })}
            onClick={changePageBack}
          >
            Previous
          </button>
          <p>
            <span className="doc-heading">Page</span>
            <span className="doc-numb">{pageNumber} </span>
            <span className="doc-heading">of</span>
            <span className="doc-numb"> {numPages} </span>
          </p>
          <button
            className="btn theme-btn small-btn next-btn "
            disabled={pageNumber === numPages}
            {...(numPages !== pageNumber && {
              onClick: () => {
                changePageNext();
              },
            })}
          >
            Next
          </button>
        </div>
      )}
      {!pdfUrl !== undefined && (
        <Document
          file={"data:application/pdf;base64," + pdfUrl}
          onLoadSuccess={isLoadSuccess}
          renderMode="canvas"
        >
          <Page height={100} width={1000} pageNumber={pageNumber} />
        </Document>
      )}
      {path.endsWith("pdf") && (
        <Image
          src={isImagePath(path) ? path : documentPath}
          width="100%"
          height="100%"
          alt="document-user"
        />
      )}
    </div>
  );
};

const DocumentView = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { item, resourse, resourceData, url } = location.state;
  const { setSpinner, setToaster } = useContext(AppContext);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [resourceType, setResourceType] = useState(item);
  const { dispatch } = useBreadcrumb();
  const [pageVisible, setPageVisible] = useState(true);
  const [apiCallTimerActive, setApiCallTimerActive] = useState(true);
  const [timeRemaining, setTimeRemaining] = useState(false);
  const [pdfUrl, setpdfUrl] = useState();
  const timeSpentRef = useRef(0);
  // Define a state variable to track the timer
  const [timer, setTimer] = useState(null);

  const { getData: resourceTimeSpent } = useResourceTimeSpent(
    setSpinner,
    setToaster
  );

  const isImagePath = (filePath) => {
    const validExtensions = ["jpeg", "jpg", "png"];
    const isImage = validExtensions?.some((ext) =>
      filePath?.toLowerCase().endsWith(ext)
    );
    return isImage;
  };

  // Function to start the timer
  const startTimer = () => {
    setTimer(
      setInterval(() => {
        if (pageVisible) {
          timeSpentRef.current += 1; // Update the ref
        }
      }, 1000)
    );
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offSet) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offSet);
  };

  const changePageBack = () => {
    changePage(-1);
  };

  const changePageNext = () => {
    changePage(+1);
  };

  // Reset the timer after each API call.f
  const resetTimer = () => {
    timeSpentRef.current = 0;
  };

  const getResourceTimeSpent = async (time_spent) => {
    let params = {
      student_id: Auth?.user()?.id,
      resource_id: parseInt(id),
      resource_type_id: resourceData[0]?.id,
      time_spent,
    };

    await resourceTimeSpent(params, (data) => {
      resetTimer();
    });
  };

  const getBase64PdfUrl = async () => {
    setSpinner(true);
    const response = await userService.getPdf({ id: id });
    try {
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setpdfUrl(data?.data);
        getResourceTimeSpent(timeSpentRef.current);
        startTimer();
        setSpinner(false);
      }
      if (response.status >= 400 && response.status <= 500) {
        const data = await response.json();
        setSpinner(false);
      }
      if (response?.status === 401) {
        localStorage.removeItem("_prathamStudentToken");
        setSpinner(false);
      }
    } catch {
      setSpinner(false);
    }
  };

  const handleCustomBack = () => {
    dispatch({ type: "REMOVE_BREADCRUMB", source: "custom" });
    navigate(url ? url : -1);
    // setScheduleDatePicker(location.state.date);
  };

  useEffect(() => {
    if (resourse === "Document" && item.path.endsWith("pdf")) {
      getBase64PdfUrl();
    }
    dispatch({
      type: "SET_BREADCRUMB",
      crumb: [
        {
          title: item?.title || item?.resource_name,
        },
      ],
    });
  }, []);

  useEffect(() => {
    if (resourse === "Document") {
      // Check the Page is active or inactive state
      const handleVisibilityChange = () => {
        if (window?.document?.visibilityState === "visible") {
          // Page is now visible, resume the timer
          setPageVisible(true);
          //Check if the API call timer should be active
          setApiCallTimerActive(true);
          setTimeRemaining(true);
        } else {
          // Page is not visible, pause the timer
          setPageVisible(false);
          // Pause the API call timer
          setApiCallTimerActive(false);
        }
      };
      window?.document?.addEventListener(
        "visibility change",
        handleVisibilityChange
      );
      return () => {
        window?.document.removeEventListener(
          "visibility change",
          handleVisibilityChange
        );
        getResourceTimeSpent(timeSpentRef.current);
      };
    }
  }, []);

  useEffect(() => {
    if (resourse === "Document") {
      return () => clearInterval(timer);
    }
  }, [pageVisible]);

  useEffect(() => {
    if (resourse === "Document") {
      // Call an API Automate every 2 Minutes
      if (apiCallTimerActive && timeRemaining) {
        const remainingTime = 120 - (timeSpentRef.current % 120);
        const interval = setInterval(() => {
          getResourceTimeSpent(timeSpentRef.current);
          setTimeRemaining(false);
        }, remainingTime * 1000);
        return () => clearInterval(interval);
      } else {
        if (apiCallTimerActive) {
          const interval = setInterval(() => {
            getResourceTimeSpent(timeSpentRef.current);
          }, 120 * 1000);
          return () => clearInterval(interval);
        }
      }
    }
  }, [apiCallTimerActive, timeRemaining]);

  return (
    <div className="col-md-12 d-flex gap-4">
      <div className="white-box body-cont">
        <div className="doc-viewer">
          <ResourceContainer
            path={item?.path}
            title={item?.title}
            download={item?.downloadable}
            handleCustomBack={handleCustomBack}
            resourceName={item.resource_name}
          />
          {resourse === "Document" && (
            <DocumentResourceContainer
              pdfUrl={pdfUrl}
              numPages={numPages}
              pageNumber={pageNumber}
              changePageBack={changePageBack}
              changePageNext={changePageNext}
              isLoadSuccess={onDocumentLoadSuccess}
              documentPath={item.document}
              isImagePath={isImagePath}
              path={item?.path}
            />
          )}
        </div>
      </div>
      <div className="sidebar">
        <TestRightSidebar
          testDetail={resourceType ? resourceType : ""}
          resourse={resourse}
        />
      </div>
    </div>
  );
};

export default DocumentView;
