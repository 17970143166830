import React from "react";

const CourseTitle = ({ isOpenDescription, courseId, title }) => {
  return (
    <div
      className={
        isOpenDescription === courseId ? "content_title_2" : "content_title"
      }
    >
      <p className="p-0 m-0 text-uppercase">{title}</p>
    </div>
  );
};

export default CourseTitle;
