import React from "react";
import { NavLink } from "react-router-dom";
import Image from "../../utils/image/Image";

const MenuSidebar = ({
  item,
  location,
  handleBreadCrumb,
  isMobile,
  setIsSidebarHide,
  setActiveMenu,
  activeMenu,
  index,
  handelSubSidebarNavigation,
  isActiveChild,
  isSidebarHide,
}) => {
  return (
    <>
      <NavLink
        to={item.url}
        state={{
          back_url: location?.pathname + location?.search,
        }}
        className={`${item?.className} text-capitalize ${
          location.pathname.includes("/forms/add_form_wallet") &&
          item.name === "forms"
            ? "active"
            : ""
        } ${
          location.pathname.includes("courses") &&
          item.name === "course_content"
            ? "active"
            : ""
        } `}
        onClick={() => {
          handleBreadCrumb({
            title: item.title,
            url: item.url,
          });
          isMobile && setIsSidebarHide(!isSidebarHide);
          setActiveMenu(index);
        }}
        key={index + 1}
        title={item.title}
        style={{ fontWeight: "500" }}
      >
        <div className="nav-links w-100 d-block">
          <Image src={item.icon} className={`nav-icons`} alt="nav-icon" />
          {!isSidebarHide && (
            <span
              className={`nav_name ${
                location.pathname.includes(item.url) && activeMenu === index
                  ? "white-text"
                  : "light-black-text"
              }`}
            >
              {item.title}
            </span>
          )}
        </div>
        {item?.isSubMenusShow && (
          <div className="drop-down">
            <ul className="list-group sub-menu">
              {item?.subMenus?.map((dropItem, index) => {
                return (
                  <li className="list-unstyled" key={index}>
                    <NavLink
                      to={dropItem.url}
                      className={dropItem?.className}
                      onClick={() => handelSubSidebarNavigation(item, index)}
                      style={() => {
                        return {
                          color: isActiveChild === index ? "#fff" : "#4c4c4c",
                        };
                      }}
                      state={{
                        back_url: location?.pathname + location?.search,
                      }}
                      key={index}
                      title={dropItem.title}
                    >
                      {dropItem.title}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </NavLink>
    </>
  );
};

export default MenuSidebar;
