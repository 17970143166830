import React from "react";
import NoData from "../../../assets/images/no-data.png";
import ArrowUp from "../../../assets/images/triangle.png";
import Image from "../image/Image";

const Table = ({ heading, rows = [], loading, className }) => {
  // const { setSpinner, setToaster, spinner } = useContext(AppContext);
  return (
    <div
      className={`data-table ${rows?.length ? "" : "emptyTable"} ${
        className ? className : ""
      }`}
    >
      <table className="table">
        <thead>
          <tr>
            {heading &&
              heading?.map((headingData, index) => {
                return (
                  headingData?.value?.length > 0 && (
                    <th
                      className={
                        headingData?.className ? headingData?.className : ""
                      }
                      key={`head-${index}`}
                    >
                      <div className="d-flex align-items-center gap-4 cursor-pointer text-center">
                        {headingData?.value}
                        {headingData?.isFilter && (
                          <div>
                            <Image
                              style={{ marginLeft: 0 }}
                              className="d-block"
                              onClick={headingData?.onClickAscending}
                              src={ArrowUp}
                              alt="arrow"
                            />
                            <Image
                              className="d-block"
                              onClick={headingData?.onClickDescending}
                              src={ArrowUp}
                              style={{
                                transform: "rotate(180deg)",
                                marginLeft: 0,
                                position: "relative",
                                top: "-5px",
                              }}
                              alt="arrow"
                            />
                          </div>
                        )}
                      </div>
                    </th>
                  )
                );
              })}
          </tr>
        </thead>
        <tbody>
          {rows?.length > 0 &&
            rows?.map((rowsData, index) => {
              return (
                <tr key={`row-${index}`}>
                  {rowsData?.length &&
                    rowsData?.map((row, i) => {
                      return (
                        String(row.value).length >= 1 && (
                          <td
                            key={`td-${i}`}
                            className={row?.className}
                            style={{
                              background: index % 2 === 0 ? "#F2F4FC" : "",
                            }}
                          >
                            {row?.value}
                          </td>
                        )
                      );
                    })}
                </tr>
              );
            })}
        </tbody>
      </table>
      {rows?.length === 0 && (
        <div className="w-100">
          <div className="d-flex justify-content-center align-items-center">
            <Image src={NoData} alt="no-data" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
