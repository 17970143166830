import { CONSTANT } from "../component/utils/constants";

export const useDifficultyLevel = () => {
  const difficultyLevelMapping = {
    1: CONSTANT.TYPE.EASY,
    2: CONSTANT.TYPE.MEDIUM,
    3: CONSTANT.TYPE.HARD,
  };
  const getDifficultyLevel = (key) => difficultyLevelMapping[key] || "";
  return { getDifficultyLevel };
};
