import React from "react";

const HeaderExamTitle = ({ location, isSidebarHide, examName, topicName }) => {
  const isBreadcrumbShow =
    !location?.pathname?.includes("examPortal") ||
    !location?.pathname?.includes("test_design_layout") ||
    !location?.pathname?.includes("newAssignmentQuiz");

  return (
    <div
      className={`${isBreadcrumbShow ? "header_mock" : ""} 
  `}
    >
      {examName && (
        <h3 className="text-center" style={{ color: "white", display: "block" }}>{examName}</h3>
      )}
      {topicName && (
        <h3 style={{ color: "white", display: "block" }}>{topicName}</h3>
      )}
    </div>
  );
};

export default HeaderExamTitle;
