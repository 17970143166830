import React from "react";

const Image = ({
  src,
  alt,
  width,
  height,
  className,
  onClick,
  style,
  title,
  onLoad,
  mouseEnter,
  mouseLeave,
}) => {
  return (
    <img
      className={className}
      src={src}
      alt={alt}
      width={width}
      height={height}
      style={style}
      title={title}
      onClick={onClick}
      onLoad={onLoad}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    />
  );
};

export default Image;
