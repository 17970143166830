export const uploadOMRFile = (
  event,
  setSelectedFile,
  setPreviewFile,
  setIsModalOpen,
  setToaster
) => {
  const acceptedFileTypesRegex = /\.(jpg|jpeg|png|pdf)$/i;

  // Check the file length should be greater than 0
  if (event.target.files && event.target.files.length > 0) {
    const file = event.target.files[0];
    setSelectedFile(file);
    // Check the file type should image or pdf
    if (file && acceptedFileTypesRegex.test(file?.name)) {
      // Create a File reader
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewFile(reader.result);
      };
      reader.readAsDataURL(file);
      setIsModalOpen(true);
    } else {
      setToaster({
        show: true,
        type: "danger",
        content: "File type should be only image or pdf",
      });
    }
  }
};
