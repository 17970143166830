// Event Listeners Constant
export const MOUSEDOWN = "mousedown";
export const TOUCHSTART = "touchstart";
export const RESIZE = "resize";

// PRATHAM CONSTANTS
export const PRATHAM_TOKEN = "prathamtoken";
export const PRATHAM_STUDENT_TOKEN = "_prathamStudentToken";

// Local Storage KEY CONSTANTS
export const EXAM_DATA = "examData";
export const STUDENT_INFO = "studentInfo";
export const FINAL_EXAM_DATA = "finalExamData";
export const BREADCRUMB_DATA = "breadCrumbData";
export const HEADER_NAME = "headerName";

export const CONSTANT = {
  YES: "Yes",
  DAY: "day",
  SECONDS: "seconds",
  ZERO_SECONDS: "00:00:00",
  INCORRECT: "Incorrect",
  HOURS_WITH_MINUTES: "hh:mm A",
  HOURS_WITH_SECONDS: "HH:mm:ss",
  HOURS_MINUTES: "HH:mm",
  OFFLINE: "Offline",
  DATE_YEAR_FORMAT: "DD/MM/YYYY",
  DAY_WISE_FORMAT: "DD-MM-yyyy",
  DAY_WISE_SPACING_FORMAT: "DD MM YYYY",
  YEARS_WISE_FORMAT: "YYYY-MM-DD",
  YEARS_WITH_HOUR_FORMAT: "YYYY-MM-DD HH:mm:ss",
  MINUTES: "minutes",
  UNDEFINED: "undefined",
  NULL: "null",
  HOME: "home",
  DYT: "dyt",
  SELECT_YEAR: "Select Year",
  COLLEGE_FORM_NAME: "St. Xavier",
  APPLICATION_RELEASED: "Application Released",
  ERROR: "error",
  OMR: "omr",
  OPEN: "Open",
  QUERYS: "querys",
  PDF: "pdf",
  HOME_ASSIGNMENT: "home assignment",
  EXAM: "exam",
  LIVE_CLASS: "live_class",
  IFRAME: "IFrame",
  PAGE: "Page",
  OF: "Of",
  AMAZON_AWS: "amazonaws",
  DATE: "Date",
  CLASSES: "Classes",
  PRATHAM_TOKEN: "prathamtoken",
  CLASSNAMES: {
    OFFLINE: "Offline",
    ONLINE: "Online",
    YOUTUBE: "Youtube",
    NO_CLASS_NAME: "No Class Name",
  },
  CLASS_TYPE: {
    OFFLINE: 1,
    ONLINE: 2,
    YOUTUBE: 3,
  },
  // BUTTON CONSTANTS
  BUTTON: {
    SUBMIT_QUIZ: "Submit Quiz",
    PREVIOUS: "Previous",
    NEXT: "Next",
    OLD_PSIS_BUTTON: "old_psis_button",
    JOIN_NOW: "Join Now",
  },

  //PATH CONSTANTS
  PATH: {
    ASSIGNMENT_QUIZ: "/home-assignments/assignment-details/assignmentQuiz",
    TEST: "/test",
    SCHEDULE: "/schedule",
    REPORTS: "/reports",
    FORM: "/form",
    DOCUMENT: "/document",
    DESIGN_YOUR_TEST: "design_your_test",
    ASSIGNMENT_DETAILS: "assignment-details",
    ASSIGNMENT_REPORT: "assignment-report",
    UPDATE_PROFILE: "/update-profile",
    TEST_DESIGN_LAYOUT: "test_design_layout",
    TEST_DESIGN_REPORT: "test-design-report",
    COURSE_LIVE_CLASS: "/courses/live_class",
    DASHBOARD: "/dashboard",
    COURSES: "/courses",
    FORM_UPDATES: "/forms/form_updates",
    FORM_WALLET: "/forms/form_updates/form_wallet",
    HOME_ASSIGNMENTS: "/home-assignments",
    ALL_CLASSES: "allclasses",
    HOME: "/",
    LOGIN: "/login",
    EXAM_PORTAL: "examPortal",
    EXAMS: "/exams",
    EXAM_INSTRUCTIONS: "/test/examinstructions/",
    NOTIFICATION: "/notification",
    RESULT_NEW: "resultnew",
    VIMEO_URL: "https://player.vimeo",
  },

  //PLACEHOLDER CONSTANTS
  PLACEHOLDER: {
    ENTER_YOUR_ANSWER: "Enter Your Answer",
    TYPE_YOUR_QUERY: "Type Your Query",
    SEARCH_QUESTION_PAPER: "Search Question Paper",
    EMAIL_ID: "Email ID",
    PHONE_NUMBER: "Phone Number",
    PARENT_EMAIL_ID: `Parent's Email Id`,
    PARENT_PHONE_NUMBER: `Parent's Phone Number`,
  },

  //LABEL CONSTANTS
  LABEL: {
    HOME: "Home",
    MY_COURSES: "My Courses",
    TOTAL: "Total",
    CORRECT_ANSWER: "Correct Answer",
    MY_ANSWER: "My Answer",
    TEST_REPORT: "Test Report",
    DASHBOARD: "Dashboard",
    TESTS: "Tests",
    SCHEDULE: "Schedule",
    REPORTS: "Reports",
    FORMS: "Forms",
    FORM_UPDATES: "Form Updates",
    FORM_WALLET: "Form Wallet",
    DESIGN_YOUR_TEST: "Design Your Test",
    HOME_ASSIGNMENTS: "Home Assignments",
    GO_TO_OLD_PSIS: "Go To Old PSIS",
    PSIS: "psis",
    CLASSES: "Classes",
    CLICK_HERE: "click here",
    VIDEOS: "Videos",
    VIDEO: "Video",
    DOCUMENTS: "Documents",
    DOCUMENT: "Document",
    NOTES: "Notes",
    LIVE_CLASS: "Live Class",
    LIVE_CLASSES: "Live Classes",
    ONLINE_TESTS: "Online Tests",
    QUESTION: "Question",
    OPTIONS: "Options",
    TOTAL_QUESTIONS: "Total Questions",
    CORRECT: "Correct",
    INCORRECT: "Incorrect",
    NOT_ATTEMPTED: "Not Attempted",
    VIEW_ALL_QUESTIONS: "View All Questions",
    SUBJECT: "Subject",
    CHAPTER: "chapter",
    CREATE: "Create",
    PREVIOUS_TEST: "Previous Tests",
    S_NO: "S.No",
    NAME: "Name",
    SCORE: "Score",
    STATUS: "Status",
    ASSIGNMENT_QUIZ: "assignmentQuiz",
    QUESTION_PALETTE: "Question Palette",
    CALENDAR: "Calendar",
    PROFILE: "Profile",
    LOGOUT: "logout",
    TEST: "Test",
    NOTIFICATION: "Notifications",
    VIEW_ALL_NOTIFICATION: "View All Notifications",
    MENTORSHIP_FORM: "Mentorship Form",
    WELCOME: "Welcome!",
    CHANGE_THEME: "Change Theme",
    SHARE_TESTIMONIAL: "Share A Testimonial",
    START_DATE_REMARK: "start_date_remark",
    END_DATE_REMARK: "end_date_remark",
    EXAM_DATE_REMARK: "exam_date_remark",
    COURSE_OFFERED: "Course (s) Offered",
    ELIGIBILITY_CRITERIA: "Eligibility Criteria",
    ADMISSION_PROCESS: "Selection/Admission Process",
    ONLINE_APPLICATION: "Online Application starts from",
    LAST_DATE_ONLINE_APPLCIATION: "Last date for submitting online application",
    EXAM_DATE: "Exam Date",
    FORM_LINK: "Form Link",
    APPLICATION_FEES: "Application Fees",
    HOW_TO_FILL_FORM: "How to fill the form",
    FORM_STATUS_UNIVERSITIES: "Forms Status of the Universities",
    UNIVERSITY: "University",
    APPLICATION_START_DATE: "Application Start Date",
    APPLICATION_END_DATE: "Application End Date",
    APPLICATION_EXAM_DATE: "Application Exam Date",
    LAW_UNIVERSITIES: "Law Universities",
    FORM_STATUS: "Form Status",
    HELPLINE_DESK: `PRATHAM's Forms Helpline Desk`,
    HELPLINE_DESK_SUMMARY: `We will also be assisting Parents/Students to fill up the forms in
    case they are facing any problems w.r.t. uploading/resizing of
    photos and signatures etc.`,
    SERVICE_ZONE: "Service Zone",
    HELPLINE_CONTACT_NO: "Helpline Contact No.",
    IMPORTATN_NOTICE: "Important Notice",
    IMPORTATN_NOTICE_SUMMARY_FIRST: `PRATHAM will assist students & parents in form filling by
    providing Form Filling PPT & recordings however the onus &
    responsibility of filling the form correctly and before the
    last date of the application lies solely with the Student
    and Parent.`,
    IMPORTATN_NOTICE_SUMMARY_SECOND: ` Parents are requested not to share any personal details with
    any PRATHAM employee to fill the student application forms.
    If shared PRATHAM will not be held responsible in case of
    incorrect form filling/ incorrect course/program selected or
    delay in submission of forms.`,
    IMPORTATN_NOTICE_SUMMARY_THIRD: `All Parents and Students are requested to save a copy of the
    Application Form filled by them and ensure to save the
    application User ID and Password in PRATHAM FORM WALLET
    after each form filled.`,
    IMPORTATN_NOTICE_SUMMARY_FOURTH: `Students and Parents are requested to track the last date of
    University applications as they are subject to change.`,
    IMPORTATN_NOTICE_SUMMARY_FIFTH: `You are requested to go through the University Bulletin
    thoroughly before filling any University Application Form.`,
    TEAM_PRATHAM: "Team PRATHAM",
    REGARDS: "Regards",
    CLIENT_SERVICE_SUMMARY: `For any query or assistance in form filling, please call your
    respective Client Service Managers or Centre Managers.`,
    DEAR_STUDENTS: "Dear Students",
    RESUME: "Resume",
    SUB_FOLDERS: "Sub-Folders",
    FOLDER: "folder",
    ALL_QUERIES: "All Queries",
    FILE: "file",
    SECTION_ID: "section_id",
    SUB_SECTION_ID: "sub_section_id",
    QUERY: "query",
    HOW_CAN_WE_HELP: "How Can We Help?",
    LAST_UPDATE_ON: "Last Update On",
    RESPONSE: "Response",
    NO_QUERY_FOUND: "No Query Found",
    SELECT_CATEGORY: "Select Category",
    SECTION: "Section",
    SUB_SECTION: "subSection",
    SELECT_SUB_CATEGORY: "Select sub-category",
    QUERIXES: "querixes",
    BACK: "Back",
    SUBMIT: "Submit",
    QUERY_RESPONSES: "Query Responses",
    NEW_QUERY: "New Query",
    TOTAL_TESTS: "Total Tests",
    BEST_RANK: "Best Rank",
    UPLOAD_OMR: "Upload OMR",
    FILE_UPLOAD: "file_upload",
    SPEED: "Speed",
    ACCURACY: "Accuracy",
    EXAM_CATEGORIES: "Exam Categories",
    LATEST_EXAM: "Latest Exams",
    EXAM_CATEGORY: "Exam Category",
    ATTEMPTED_ON: "Attempted",
    RANK: "Rank",
    DATA_IMAGE: "data:image",
    DATA_APPLICATION_PDF: "data:application/pdf",
    VIEW_LESS: "View Less",
    VIEW_MORE: "View More",
    COURSE_DETAIL_TABLE: "courseDetailtable",
    NO_DATA: "No Data",
    START_TIME: "Start Time",
    END_TIME: "End Time",
    ATTEND: "Attend",
    MOBILE_BTN: "Mobile Btn",
    NOT_VIEWED: "Not Viewed",
    VIEWED: "Viewed",
    ATTEMPT: "Attempt",
    RADIO_GROUP: "radio-group",
    FOLDER_VIEW: "Folder View",
    RESOURCE_VIEW: "Resource View",
    PATH: "Path",
    TIME: "Time",
    ATTENDANCE: "Attendance",
    FROM: "From",
    TO: "To",
    TITLE: "Title",
    OVERVIEW: "Overview",
    SECTION_WISE: "Section Wise",
    TOPIC_WISE: "Topic Wise",
    LEVEL_WISE: "Level Wise",
    LEADER_BOARD: "Leader Board",
    QUESTION_WISE_ANALYSIS: "Question Wise Analysis",
    OMR_TOPIC_TESTS: "OMR TOPIC TESTS",
    OMR_BASED_MOCK_TESTS: "OMR BASED MOCK TESTS",
    QUESTION_PAPER_ANSWERS: "Question Paper With Answers",
    IMAGE: "Image",
    MY_PROFILE: "My Profile",
    FILENAME: "FileName",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    USER_ID: "User Id",
    ID: "ID",
    VERIFIED: "Verified",
    NOT_VERIFIED: "Not Verified",
    VERIFY_NOW: "Verify Now",
    REGISTERED_EMAIL_ID: "Registered Email Id",
    REGISTERED_PHONE_NUMBER: "Registered Phone Number",
    PARENT_EMAIL: "Parent Email",
    PARENT_PHONE: "Parent Phone",
    UPDATE: "Update",
    SKIP_FOR_NOW: "Skip For Now",
    VIEW_RESULT: "View Result",
    MOVE_TO_LIST: "Move to list",
    THANK_YOU: "Thank You",
    SUBMITTED_SUCCESSFULLY: "Submitted Successfully",
    NO: "No",
    MARKED_FOR_REVIEW: "Marked for Review",
    NOT_ANSWERED: "Not Answered",
    ANSWERED: "Answered",
    NO_OF_QUESTIONS: "No of questions",
    EXAM_SUMMARY: "Exam Summary",
    CLEAR: "Clear",
    MARKED_FOR_REVIEW_NEXT: "Mark For Review And Next",
    SAVE_MARK_FOR_REVIEW: "Save And Mark For Review",
    EXAM: "Exam",
    ANONYMOUS_FEEDBACK: "Anonymous Feedback",
    SKIPPED: "Skipped",
    MOCK_ANALYSIS_VIDEO: "Mock Analysis Video",
    TOPPER: "Topper",
    AVERAGE: "Average",
    TIME_LEFT: "Time Left",
    TEST_NAME: "Test Name",
    TEST_DURATION: "Test Duration",
    CENTER_NAME: "Center Name",
    CANDIDATES_APPEARED: "Candidates Appeared",
    HIGHEST_SCORE: "Highest Score",
    PERCENTAGE: "Percentage",
    OVERALL_RANK: "Overall Rank",
    CENTER_RANK: "Center Rank",
    CENTER: "Center",
    TOTAL_SCORE: "Total Score",
    PERCENTILE: "Percentile",
  },

  //TYPE CONSTANTS
  TYPE: {
    BUTTON: "button",
    CHECKBOX: "checkbox",
    RADIO: "radio",
    SET_BREADCRUMB: "SET_BREADCRUMB",
    CLEAR_BREADCRUMBS: "CLEAR_BREADCRUMBS",
    REMOVE_SELECT_BREADCRUMB: "REMOVE_SELECT_BREADCRUMB",
    REMOVE_BREADCRUMB: "REMOVE_BREADCRUMB",
    DANGER: "danger",
    SUCCESS: "success",
    DATE: "date",
    CLASS: "class",
    TEST: "test",
    SELECT: "Select",
    GREEN_BOX: "greenBox",
    PINK_BOX: "pinkBox",
    YELLOW_BOX: "yellowBox",
    GRAY_BOX: "grayBox",
    DARK_BLUE_BOX: "darkBlueBox",
    EASY: "Easy",
    MEDIUM: "Medium",
    HARD: "Hard",
    ACTIVE: "active",
    SAVE_AND_NEXT: "save and next",
    SAVE_AND_MARK: "save and mark",
    MARK_AND_NEXT: "mark and next",
    ADD_BREADCRUMB: "ADD_BREADCRUMB",
    LIVE_CLASS: "liveClass",
    RESOURCE: "Resource",
    COURSE_ID: "courseId",
    IS_FOLDER: "isFolder",
    IS_RESOURCE: "isResourse",
    KEY: "Key",
    SINGLE_ANSWER_TYPE: "Single Answer Type",
    MULTIPLE_ANSWER_TYPE: "Multiple Answer Type",
    NUMERICAL_ANSWER_TYPE: "Numerical Answer Type",
    WORD_ANSWER_TYPE: "Word Answer Type",
    SUBJECTIVE_ANSWER_TYPE: "Subjective Answer Type",
    COMPREHENSION_TYPE: "Comprehension Type",
    QUESTION_TYPE: "Question Type",
    TEXT: "Text",
    EMAIL: "email",
    PHONE: "phone",
    NUMERIC: "Numeric",
    WORD: "Word",
    OPTIONS: "Options",
    GREEN_BORDER: "greenBorder",
    DARK_BLUE_BORDER: "dark-blueBorder",
    RED_BORDER: "redBorder",
    BLUE_BORDER: "blueBorder",
    YELLOW_BORDER: "yellowBorder",
    DIFFICULT: "Difficult",
  },

  // LOCAL STORAGE CONSTANTS
  LOCAL_STORAGE: {
    COUNT: "count",
    PRATHAM_STUDENT_TOKEN: "_prathamStudentToken",
    EXAM_CATEGORY_ID: "exam_category_id",
    DURATION: "duration",
    REAL_DURATION: "realDuration",
    RESULT_DATE: "resultDate",
    EXAM_ID: "examId",
    TEMP_ID: "tempId",
    STATUS: "status",
    TEST_NAME: "testName",
    HOME_ASSIGNMENT_EXAM_NAME: "HAExamName",
    RESUME_TIME: "resumeTime",
    TIME_TAKEN_BY_QUESTION: "timeTakenByQuestion",
    EXAM_TIMER: "examTimer",
    BREADCRUMBS: "breadcrumbs",
    ZOOM_MEET_URL: "zoomMeetUrl",
    OMR_EXAM_ID: "exam_id",
    CARD_CLASS: "cardClass",
    TEMPLATE_STRUCTURE_DATA: "templateStructureData",
    UI_TEMPLATE: "UITemplate",
    STUDENT_ID: "student_id",
    OMR_ATTACHMENT: "omr_attachment",
    STUDENT_INFO: "studentInfo",
    IN_TEST: "inTest",
  },

  // IMAGE PATH CONSTANTS
  IMAGE_PATH: {
    DASHBOARD: "images/ic_home_white.svg",
    MY_COURSES: "images/ic_my_courses_white.svg",
    TESTS: "images/clipboard-text-white.svg",
    SCHEDULE: "images/menu-board-white.svg",
    REPORTS: "images/note-text-white.svg",
    DESIGN_YOUR_TEST: "images/design-test.png",
    HOME_ASSIGNMENTS: "images/assignment.png",
    FORMS: "images/ic_form_white.svg",
    ONLINE_STATUS: "images/green-circle.png",
    CHECK: "images/check-svgrepo-com.svg",
    NOT_ATTEMPTED: "images/icon21.svg",
    INCORRECT: "images/icon22.svg",
    CORRECT: "images/icon1.svg",
    TOTAL_QUESTIONS: "images/icon11.svg",
    LOGOUT: "images/logout.svg",
    CHANGE_THEME: "images/brush.svg",
    PROFILE: "images/profile-circle.svg",
    ATTEMPTED: "images/icon14.svg",
    SPEED: "images/icon12.svg",
    ACCURACY: "images/icon13.svg",
    TOTAL_TEST: "images/icon08.svg",
    SPEED_TEST: "images/icon09.svg",
  },

  // ALT CONSTANTS
  ALT: {
    DASHBOARD: "dashboard-logo",
    NAV_ICON: "nav-icon",
    CALENDOR_ICON: "calendor-icon",
    NEXT_ICON: "next-icon",
    NO_DATA_FOUND_ICON: "no-data-found-icon",
    LIVE_CLASS_ICON: "live-class-icon",
    DOCUMENT_ICON: "document-icon",
    NOTE_ICON: "note-icon",
    HOME_ICON: "home-icon",
    SETTING_ICON: "setting-icon",
    NOTIFICATION_ICON: "notification-icon",
    MORE_SETTING: "more-setting-icon",
    USER: "user-icon",
    CHANGE_THEME: "change-theme-icon",
    VIEW_ICON: "view-icon",
    SCHEDULE_ICON: "schedule-icon",
    NO_DATA_ICON: "no-data-icon",
    CHAT_BOX: "chat-box-icon",
    MINIMIZE: "minimize-icon",
    MAXIMIZE: "maxmize-icon",
    ATTACHMENT: "attachment",
    ATTACHMENT_USER: "user-attachment",
    PREVIEW_FILE_IMAGE: "preview-file-image",
    DOCUMET_USER: "user-document",
    YOUTUBE_ICON: "youtube-icon",
    MOBILE_BTN_ICON: "mobile-btn-icon",
    SCHEDULE_CALENDER_ICON: "schedule-calendar-icon",
    PENCIL_ICON: "pencil-icon",
    SHOW_IMAGE: "show-image-icon",
    VERIFICATION_ICON: "verification-icon",
  },

  //ATTRIBUTES CONSTANT
  ATTRIBUTE: {
    BLANK: "_blank",
    NO_REFERRER: "noreferrer",
  },

  //EXAM STATUS
  EXAM_STATUS: {
    ONLINE: "Online",
    SCHEDULED: "Scheduled",
    EXPIRED: "Expired",
    COMPLETED: "Completed",
    TAKE_TEST: "Take Test",
    SCHEDULE: "schedule",
    VIEW_REPORT: "View Report",
    RESULT_AWAITED: "Result Awaited",
    RETAKE: "Retake",
    RESUME_TEST: "Resume Test",
    TAKE_EXAM: "Take Exam",
    CLASS: "class",
    ATTEMPTED: "Attempted",
    LIVE: "live",
    NOT_ATTEMPTED: "Not_Attempted",
  },

  FOLDER_COLOR: {
    LIGHT_PINK: "lightPink",
    YELLOW_BG: "yellowBG",
    GREEN_BG: "greenBG",
    DARK_BLUE: "darkBlue",
    PINK_BG: "pinkBG",
    LIGHT_BLUE: "lightBlue",
  },

  //ERROR CONTENT
  CONTENT: {
    SOMETHING_WENT_WRONG: "Something went wrong",
    EXAM_SCHEDULED: "Exam is scheduled",
    WRITE_YOUR_QUERY: "Please write your query before submit",
    QUERY_RECEIVED_SUCCESSFULLY:
      "Query Received successfully, We aim to resolve your query within 48 hours",
    SELECT_CATEGORY: "Please Select Category",
    FILE_TYPE: "File Type should be only image or pdf",
    UPLOAD_SUCCESSFULLY: "File is uploaded successfully",
    NO_CLASS_SCHEDULED: "No class scheduled for the day",
    RESULT_PUBLISHED: "The result will be published on",
    FINAL_MARKING: "Are you sure you want to submit for final marking?",
    AFTER_SUBMISSION: "No changes will be allowed after submission.",
    ANSWERED_MARKED: "Answered & Marked for Review",
    CONSIDERED_EVALUATION: "will be considered for evaluation",
    ENTER_YOUR_ANSWER: "Please enter your answer",
    CHOOSE_OPTION: "Please choose an option",
  },

  EVENTS: {
    VISIBLE: "visible",
    VISIBILITY_CHANGE: "visibility change",
  },

  // ROLES CONSTANT
  ROLE: {
    ADMIN: "admin",
    TEACHER: "teacher",
    COUNSELOR: "counselor",
    SUB_ADMIN: "subAdmin",
    STUDENT: "student",
  },
};
