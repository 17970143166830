import ResourceViewItem from "./ResourceViewItem";
import DashboardArrowDown from "../../../assets/images/dashboard-arrow-down.png";
import Image from "../../utils/image/Image";

const ResourceViewComponent = ({
  data,
  toggleItem,
  openItems,
  resourceType,
  setResourceType,
}) => {
  // Check if the resourceType is present in the openItems state
  const isOpen = openItems.some((item) => item.title === resourceType);

  return (
    <div>
      <div
        className={`document-item d-flex justify-content-between cursor-pointer ${
          isOpen && data.data.length == 0 && "active-document"
        }`}
        onClick={() => {
          toggleItem(resourceType, 0, resourceType);
          setResourceType(resourceType);
        }}
      >
        <div className="text-capitalize">{resourceType}</div>
        <div className="d-flex justify-content-center align-items-center">
          {/* <div>{data.total_count}</div> */}
          {data.data.length > 0 && (
            <div className="" style={{ marginLeft: "10px" }}>
              <Image src={DashboardArrowDown} alt="dashboard-arrow-down" />
            </div>
          )}
        </div>
      </div>
      {isOpen
        ? data?.data?.map((e, index) => {
            return (
              <ResourceViewItem
                key={index}
                item={e}
                toggleItem={toggleItem}
                openItems={openItems}
                isOpen={openItems?.some((d) => d?.title?.includes(e?.title))}
                level={1}
                resourceType={resourceType}
                setResourceType={setResourceType}
              />
            );
          })
        : null}
    </div>
  );
};

export default ResourceViewComponent;
