import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";

// import { PDFExport } from "@progress/kendo-react-pdf";
import { data } from "../../utils/data/Data";
import { AppContext } from "../../shared/AppContext";
import { useForm } from "@kaydhiman/react-hook-useform";
import { userService } from "../../../_services/User.services";
import Table from "../../utils/table/Table";
import Button from "../../utils/forms/Button";
import CommonTab from "../../utils/tab/CommonTab";
import ReportCards from "../../utils/card/ReportCards";
import ProgressBar from "../../utils/progressReport/ProgressBar";
import arrowImg from "../../../assets/images/white-arrow-down.svg";
import cross from "../../../assets/images/cancel.svg";
import tick from "../../../assets/images/blue-tick01.svg";
import dataImg from "../../../assets/images/no-data.png";
import TestQuestionHtml from "../../utils/questionAwn/TestQuestionHtml";
import { CONSTANT } from "../../utils/constants";
import { useBreadcrumb } from "../../shared/breadcrumb-context";
import ColorCard from "../../utils/card/ColorCard";
import Image from "../../utils/image/Image";

const examRef = React?.createRef(null);
const tabData = [
  { id: 1, title: CONSTANT.LABEL.OVERVIEW },
  { id: 2, title: CONSTANT.LABEL.SECTION_WISE },
  { id: 3, title: CONSTANT.LABEL.TOPIC_WISE },
  { id: 4, title: CONSTANT.LABEL.LEVEL_WISE },
  { id: 5, title: CONSTANT.LABEL.LEADER_BOARD },
  { id: 6, title: CONSTANT.LABEL.QUESTION_WISE_ANALYSIS },
  { id: 7, title: CONSTANT.LABEL.MOCK_ANALYSIS_VIDEO },
];

const ResultNew = () => {
  const { exam_id } = useParams();
  const { setToaster, setSpinner, sidebarShow, setSidebarShow } =
    useContext(AppContext);
  const { values, setInitialValues } = useForm({ validations: {} });

  const [keys, setKeys] = useState(CONSTANT.LABEL.OVERVIEW);
  const [reviewdData, setReviewData] = useState({});
  const [topicWiseData, setTopicWiseData] = useState([]);
  const [userTableData, setUserTableData] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [examTemplateData, setExamTemplateData] = useState([]);
  const [topTenTableData, setTopTenTableData] = useState({});
  const [questionAnalysisData, setQuestionAnalysisData] = useState([]);
  const [examName, setExamName] = useState("");
  const isOMRTopicTest =
    examName !== CONSTANT.LABEL.OMR_TOPIC_TESTS ||
    examName !== CONSTANT.LABEL.OMR_BASED_MOCK_TESTS;

  const [mockAnalysisVideo, setMockAnalysisVideo] = useState([]);
  const [mode, setMode] = useState(null);
  const { dispatch } = useBreadcrumb();

  const graphProgressColorData = data.graphProgressColor.map((d) => {
    let totalMarks;
    if (d.color_name === CONSTANT.LABEL.TOPPER) {
      totalMarks = `${reviewdData.totalMarksScored?.topper}/${
        reviewdData.totalMarksScored?.totalQuestions &&
        reviewdData?.totalMarksScored?.totalQuestions !== undefined
          ? reviewdData.totalMarksScored?.totalQuestions &&
            reviewdData.totalMarksScored?.totalQuestions
          : reviewdData.total_score && reviewdData.total_score
      }`;
    } else if (d.color_name === CONSTANT.LABEL.AVERAGE) {
      totalMarks = `${reviewdData.totalMarksScored?.average}/${
        reviewdData.totalMarksScored?.totalQuestions &&
        reviewdData.totalMarksScored.totalQuestions !== undefined
          ? reviewdData.totalMarksScored?.totalQuestions &&
            reviewdData.totalMarksScored?.totalQuestions
          : reviewdData.total_score && reviewdData.total_score
      }`;
    } else {
      totalMarks = `${reviewdData.totalMarksScored?.user}/${
        reviewdData.totalMarksScored?.totalQuestions &&
        reviewdData.totalMarksScored?.totalQuestions !== undefined
          ? reviewdData.totalMarksScored?.totalQuestions &&
            reviewdData.totalMarksScored?.totalQuestions
          : reviewdData.total_score && reviewdData.total_score
      }`;
    }
    return {
      ...d,
      totalMarks: totalMarks,
    };
  });

  const circleProgressColorData = data.circleProgressColor.map((d) => {
    let values;
    if (d.color_name === CONSTANT.LABEL.CORRECT) {
      values = Number(
        reviewdData.doughnutData &&
          reviewdData?.doughnutData[0]?.data?.find(
            (d) => d?.class === `${CONSTANT.LABEL.CORRECT}:`
          )?.class_data
      );
    } else if (d.color_name === CONSTANT.LABEL.INCORRECT) {
      values = Number(
        reviewdData.doughnutData && reviewdData.doughnutData[0]?.doughData[1]
      );
    } else {
      values =
        reviewdData &&
        reviewdData.doughnutData &&
        reviewdData.doughnutData[0]?.doughData[
          reviewdData?.doughnutData[0]?.doughData.length - 1
        ];
    }

    return {
      ...d,
      score: values,
    };
  });

  //****************** Function for jump to the particular question after click to question number ***************** */
  const gotoServices = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView();
    setInitialValues((prev) => ({ ...prev, divId: id }));
  };

  //**************** Function to get Overview report api data ****************//
  const getReportOverViewData = async () => {
    setSpinner(true);
    const response = await userService.getReportOverview({ id: exam_id });
    try {
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setMode(data.mode);
        setInitialValues((prev) => ({
          ...prev,
          student_test_id: data?.student_test_id,
        }));

        setExamName(data?.exam_details?.name);
        let myData = Object.values(data?.subjects)?.map((elem, index) => {
          return {
            id: index + 1,
            className: elem?.subject_color,
            color_name: elem?.subject_name,
            color_data: timeSpentInPercent(
              elem?.total_time_spent,
              data?.exam_details?.duration
            ).toFixed(2),
            percent: true,
            time_spent: elem?.total_time_spent,
          };
        });

        if (myData?.length) {
          myData.push({
            className: "#fd5a58",
            color_name: CONSTANT.LABEL.TIME_LEFT,
            color_data: `${(
              ((
                Number(data?.exam_details?.duration) * 60 -
                Object?.values(data?.subjects)
                  ?.map((a) => Number(a?.total_time_spent))
                  ?.reduce((a, b) => a + b, 0)
              )?.toFixed(2) /
                (Number(data?.exam_details?.duration) * 60)) *
              100
            ).toFixed(1)}%`,
            time_spent: (
              Number(data?.exam_details?.duration) * 60 -
              Object?.values(data?.subjects)
                ?.map((a) => Number(a?.total_time_spent))
                ?.reduce((a, b) => a + b, 0)
            )?.toFixed(2),
          });
        }

        let tempData = data?.subjects?.map((item) => {
          return {
            id: item.subject_id,
            name: item.subject_name,
          };
        });
        setSelectedTemplate(tempData[0]?.name);
        setExamTemplateData(tempData);
        setReviewData((prev) => ({
          ...prev,
          boardData: [
            {
              id: "1",
              icon: "images/icon16.svg",
              className: "card-box redBG",
              title: CONSTANT.LABEL.SCORE,
              result: data?.score + "/" + data?.total_score,
            },
            {
              id: "2",
              icon: "images/icon17.svg",
              className: "card-box darkBlue",
              title: CONSTANT.LABEL.HIGHEST_SCORE,
              result: data?.highest_score ? data?.highest_score : 0,
            },
            {
              id: "3",
              icon: "images/icon18.svg",
              className: "card-box pinkBG",
              title: CONSTANT.LABEL.PERCENTAGE,
              result: data?.percentage ? data?.percentage : 0,
            },
            {
              id: "4",
              icon: "images/icon19.svg",
              className: "card-box   greenBG",
              title: CONSTANT.LABEL.OVERALL_RANK,
              result: data?.overall_rank ? data?.overall_rank : 0,
            },
            {
              id: "5",
              icon: "images/icon20.svg",
              className: "card-box yellowBG",
              title: CONSTANT.LABEL.CENTER_RANK,
              result: data?.center_Rank ? data?.center_Rank : 0,
            },
          ],

          centerData: [
            {
              id: "1",
              className: "card-box ",
              title: CONSTANT.LABEL.TEST_NAME,
              result: data?.exam_details?.name,
            },
            {
              id: "2",
              className: "cards-box ",
              title: CONSTANT.LABEL.TEST_DURATION,
              result: data?.exam_details?.duration,
            },
            {
              id: "3",
              className: "cards-box ",
              title: CONSTANT.LABEL.CENTER_NAME,
              result: data?.branch,
            },
            {
              id: "4",
              className: "cards-box ",
              title: CONSTANT.LABEL.CANDIDATES_APPEARED,
              result: data?.candidates_appeared,
            },
          ],
          doughnutData: [
            {
              id: "1",
              className: "cards-box gray-card p-0",
              doughData: [
                data?.correct_answers,
                data?.incorrect_answers,
                data?.skipped_question,
              ],
              data: [
                {
                  class: `${CONSTANT.LABEL.TOTAL_QUESTIONS}:`,
                  class_data: data?.exam_details?.total_questions,
                  id: 1,
                },
                {
                  class: `${CONSTANT.LABEL.ACCURACY} (%):`,
                  class_data: data?.accuracy,
                  id: 2,
                },
                {
                  class: `${CONSTANT.EXAM_STATUS.ATTEMPTED}:`,
                  class_data: data?.attempted_questions,
                  id: 3,
                },
                {
                  class: `${CONSTANT.LABEL.CORRECT}:`,
                  class_data: data?.correct_answers,
                  id: 4,
                },
              ],
            },
          ],

          sectionTimeSpentData: myData?.map((item) => {
            return [
              { value: item?.color_name, id: 1 },
              {
                value: secondsToHms(item?.time_spent),
                id: 2,
                className: "light-black-text",
              },
            ];
          }),
          sectionTimeCardData: myData,
          totalMarksScored: {
            user: data?.score,
            topper: data?.highest_score,
            average: data?.average_score,
            totalQuestions: data?.total_score,
          },
        }));
        setSpinner(false);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setSpinner(false);

        setToaster({ show: true, type: "danger", content: data.message });
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }

      if (response.status === 500) {
        const data = await response.json();
        setSpinner(false);

        setToaster({ show: true, type: "danger", content: data.message });
      }
    } catch {
      setSpinner(false);
    }
  };

  //**************** Function to get Section Wise report api data ****************//
  const getSectionWiseReportData = async (section) => {
    setSpinner(true);
    setSelectedTemplate(section?.name);
    const response = await userService?.getSectionWiseReport({
      exam_id: exam_id,
      section_id: section?.id,
    });
    try {
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setSpinner(false);
        setReviewData((prev) => ({
          ...prev,
          total_score: data.total_score,
          boardData: [
            {
              id: "1",
              icon: "images/icon16.svg",
              className: "card-box redBG",
              title: CONSTANT.LABEL.SCORE,
              result: data?.score ? data?.score + "/" + data?.total_score : 0,
            },
            {
              id: "2",
              icon: "images/icon17.svg",
              className: "card-box darkBlue",
              title: CONSTANT.LABEL.HIGHEST_SCORE,
              result: data?.highest_score,
            },
            {
              id: "3",
              icon: "images/icon18.svg",
              className: "card-box pinkBG",
              title: CONSTANT.LABEL.PERCENTAGE,
              result: data?.percentage,
            },
            {
              id: "4",
              icon: "images/icon19.svg",
              className: "card-box   greenBG",
              title: CONSTANT.LABEL.OVERALL_RANK,
              result: data?.overall_rank,
            },
            {
              id: "5",
              icon: "images/icon20.svg",
              className: "card-box yellowBG",
              title: CONSTANT.LABEL.CENTER_RANK,
              result: data?.center_Rank,
            },
          ],

          doughnutData: [
            {
              id: "1",
              className: "cards-box gray-card p-0",
              doughData: [
                data?.correct_answers,
                data?.incorrect_answers,
                data?.skipped_question,
              ],
              data: [
                {
                  class: `${CONSTANT.LABEL.TOTAL_QUESTIONS}:`,
                  class_data: data.total_questions,
                  id: 1,
                },
                {
                  class: `${CONSTANT.LABEL.ACCURACY} (%):`,
                  class_data: data?.accuracy?.toFixed(1),
                  id: 2,
                },
                {
                  class: `${CONSTANT.LABEL.ACCURACY}:`,
                  class_data: data?.total_attempt,
                  id: 3,
                },
                {
                  class: `${CONSTANT.LABEL.CORRECT}:`,
                  class_data: data?.correct_answers,
                  id: 4,
                },
              ],
            },
          ],

          totalMarksScored: {
            user: data?.score,
            topper: data?.highest_score,
            average: data?.average_score,
          },
          sectionQuestionList: data?.question?.map((quest, index) => {
            return [
              { value: index + 1, id: 1, className: "text-center" },
              {
                value:
                  (!["0", null].includes(quest.selected_option) &&
                    quest?.is_correct === 0 &&
                    CONSTANT.LABEL.CORRECT) ||
                  (!["0", null, ""].includes(quest.selected_option) &&
                    quest?.is_correct === 1 &&
                    CONSTANT.LABEL.INCORRECT) ||
                  (["0", null, ""].includes(quest.selected_option) &&
                    CONSTANT.LABEL.SKIPPED) ||
                  (!["0", null].includes(quest.selected_option) &&
                    quest?.is_correct === null &&
                    CONSTANT.LABEL.SKIPPED) ||
                  (["0", null].includes(quest.selected_option) &&
                    quest?.is_correct === null &&
                    CONSTANT.LABEL.SKIPPED),
                id: 2,
                className: `text-center ${
                  (!["0", null].includes(quest.selected_option) &&
                    quest?.is_correct === 0 &&
                    "green-text") ||
                  (!["0", null].includes(quest.selected_option) &&
                    quest?.is_correct === 1 &&
                    "themeLink") ||
                  (["0", null].includes(quest.selected_option) &&
                    "yellow-text") ||
                  (!["0", null].includes(quest.selected_option) &&
                    quest?.is_correct === null &&
                    "yellow-text") ||
                  (["0", null].includes(quest.selected_option) &&
                    quest?.is_correct === null &&
                    "yellow-text")
                }`,
              },
              {
                value: mode === 0 ? quest?.time_spent + "s" : "",
                id: 3,
                className: "text-center",
              },
              {
                value: difficuiltyLevel(quest?.question_difficulty_id),
                id: 4,
                className: "text-center",
              },
              {
                value: `${quest?.correctly_attempted}/${quest?.total_attempted}`,
                id: 4,
                className: "text-center",
              },
            ];
          }),
        }));
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  //**************** Function to get Question Wise report api data ****************//
  const getQuestionWiseReportData = async (filter) => {
    setSpinner(true);

    try {
      const response = await userService.getQuestionWiseReport({
        student_test_id: values?.student_test_id,
        ...(filter ? { filter: filter } : ""),
      });

      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        let abc = data?.data?.map((itm) => {
          itm?.answers?.map((a) => {
            itm?.selected_answers?.map((elem) => {
              if (elem?.options?.id === a.id) {
                a.selectedOption = elem?.options?.id;
                return a;
              }
              return a;
            });
            return a;
          });
          return itm;
        });
        setQuestionAnalysisData(
          abc.sort(
            (a, b) =>
              a.exam_questions[0].exam_sort_id -
              b.exam_questions[0].exam_sort_id
          )
        );
        setSpinner(false);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();

        setToaster({ show: true, type: "danger", content: data.message });
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }
    } catch {
      setSpinner(false);
    }
  };

  //**************** Function to get Topic Wise report api data ****************//

  const getTopicWiseReportData = async (section) => {
    setSpinner(true);
    const response = await userService.getTopicWiseReport({
      exam_id: exam_id,
      section_id: section?.id,
    });
    try {
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setSpinner(false);

        setTopicWiseData(
          data?.data?.map((elem) => {
            return [
              { value: elem?.name, id: 1, className: "text-center" },
              { value: elem?.total_questions, id: 2, className: "text-center" },
              { value: elem?.total_attempted, id: 3, className: "text-center" },
              {
                value: elem?.correctly_attempted,
                id: 4,
                className: "text-center",
              },
              {
                value:
                  Number(elem?.correctly_attempted) === 0 &&
                  Number(elem?.total_attempted) === 0
                    ? 0
                    : (
                        (Number(elem?.correctly_attempted) /
                          Number(elem?.total_attempted)) *
                        100
                      )?.toFixed(2),
                id: 4,
                className: "text-center",
              },

              {
                value: mode === 0 ? secondsToHms(elem?.time_spent) : "",
                id: 4,
                className: "text-center",
              },
            ];
          })
        );
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setSpinner(false);
        setToaster({ show: true, type: "danger", content: data.message });
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }
    } catch {
      setSpinner(false);
    }
  };
  //**************** Function to get Leader Board report api data ****************//

  const getLeaderBoardReportData = async () => {
    setSpinner(true);
    const response = await userService.getLeaderBoardReport({
      exam_id: exam_id,
    });
    try {
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setSpinner(false);

        let tableHead = [
          { value: CONSTANT.LABEL.RANK, id: 1, className: "w-10 text-center" },
          { value: CONSTANT.LABEL.NAME, id: 2, className: "w-15 text-center" },
          {
            value: CONSTANT.LABEL.CENTER,
            id: 3,
            className: "w-15 text-center",
          },
        ];

        let userTableBody = [
          {
            value: data?.student_performance?.rank,
            id: 1,
            className: "text-center",
          },
          {
            value: data?.student_performance?.name,
            id: 2,
            className: "text-center",
          },
          {
            value: data?.student_performance?.center,
            id: 3,
            className: "text-center",
          },
        ];

        data?.student_performance?.subject?.forEach((elem) => {
          tableHead.push({
            value: elem?.subject_name,
            className: "w-10 text-center",
          });
          userTableBody.push({
            value: elem?.correctly_attempted,
            className: "text-center",
          });
        });
        // leaderboard table head setup
        tableHead.push(
          {
            value: CONSTANT.LABEL.TOTAL_SCORE,
            id: 5,
            className: "w-10 text-center",
          },
          {
            value: CONSTANT.LABEL.PERCENTILE,
            id: 6,
            className: "w-10 text-center",
          }
        );

        userTableBody.push(
          { value: data?.student_performance?.score, className: "text-center" },
          {
            value: data?.student_performance?.percent,
            className: "text-center",
          }
        );
        // leaderboard top ten body setup
        let topTenBodyData = data?.top_ten_student_performance?.map((elem) => {
          let body = [
            { value: elem?.rank, id: 1, className: "text-center" },
            { value: elem?.name, id: 2, className: "text-center" },
            { value: elem?.center, id: 3, className: "text-center" },
          ];
          elem?.subject?.forEach((elem) => {
            body.push({
              value: elem?.correctly_attempted,
              className: "text-center",
            });
          });
          body.push(
            { value: elem?.score, className: "text-center" },
            { value: elem?.percent, className: "text-center" }
          );

          return body;
        });
        setUserTableData({
          head: tableHead,
          body: [userTableBody],
        });

        setTopTenTableData({
          head: tableHead,
          body: topTenBodyData,
        });

        setReviewData((prev) => ({
          ...prev,
          boardData: [
            {
              id: "1",
              icon: "images/icon16.svg",
              className: "card-box redBG",
              title: CONSTANT.LABEL.SCORE,
              result: data?.score + "/" + data?.total_score,
            },
            {
              id: "2",
              icon: "images/icon17.svg",
              className: "card-box darkBlue",
              title: CONSTANT.LABEL.HIGHEST_SCORE,
              result: data?.highest_score,
            },
            {
              id: "3",
              icon: "images/icon18.svg",
              className: "card-box pinkBG",
              title: CONSTANT.LABEL.PERCENTAGE,
              result: data?.percentage,
            },
            {
              id: "4",
              icon: "images/icon19.svg",
              className: "card-box   greenBG",
              title: CONSTANT.LABEL.OVERALL_RANK,
              result: data?.overall_rank,
            },
            {
              id: "5",
              icon: "images/icon20.svg",
              className: "card-box yellowBG",
              title: CONSTANT.LABEL.CENTER_RANK,
              result: data.center_rank,
            },
          ],
        }));
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setSpinner(false);
        setToaster({ show: true, type: "danger", content: data.message });
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  //**************** Function to get Level Wise report api data ****************//
  const getLevelWiseReportData = async (section) => {
    const response = await userService.getLevelWiseReport({
      exam_id: exam_id,
      section_id: section?.id,
    });
    try {
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();

        setReviewData((prev) => ({
          ...prev,
          levelWiseChart: [
            {
              easy: data?.data?.total_easy_questions,
              medium: data?.data?.total_medium_questions,
              hard: data?.data?.total_hard_questions,
            },
            {
              correct_answers_4_easy: data?.data?.total_easy_correct_questions,
              correct_answers_4_medium:
                data?.data?.total_medium_correct_questions,
              correct_answers_4_hard: data?.data?.total_hard_correct_questions,
            },
            {
              incorrect_answers_4_easy:
                data?.data?.total_easy_incorrect_questions,
              incorrect_answers_4_medium:
                data?.data?.total_medium_incorrect_questions,
              incorrect_answers_4_hard:
                data?.data?.total_hard_incorrect_questions,
            },
            {
              not_attempted_4_easy:
                data?.data?.total_easy_questions -
                (data?.data?.total_easy_correct_questions +
                  data?.data?.total_easy_incorrect_questions),
              not_attempted_4_medium:
                data?.data?.total_medium_questions -
                (data?.data?.total_medium_correct_questions +
                  data?.data?.total_medium_incorrect_questions),
              not_attempted_4_hard:
                data?.data?.total_hard_questions -
                (data?.data?.total_hard_correct_questions +
                  data?.data?.total_hard_incorrect_questions),
            },
          ],
          levelWiseChartData: {
            datasets: [
              {
                label: CONSTANT.TYPE.EASY,
                data: [
                  {
                    x: CONSTANT.TYPE.EASY,
                    y: data?.data?.total_easy_questions,
                  },
                  {
                    x: CONSTANT.TYPE.MEDIUM,
                    y: data?.data?.total_medium_questions,
                  },
                  {
                    x: CONSTANT.TYPE.DIFFICULT,
                    y: data?.data?.total_hard_questions,
                  },
                ],
                backgroundColor: ["rgb(109, 113, 248)"],
                borderRadius: 5,
              },
              {
                label: CONSTANT.TYPE.MEDIUM,
                data: [
                  {
                    x: CONSTANT.TYPE.EASY,
                    y: data?.data?.total_easy_correct_questions,
                  },
                  {
                    x: CONSTANT.TYPE.MEDIUM,
                    y: data?.data?.total_medium_correct_questions,
                  },
                  {
                    x: CONSTANT.TYPE.DIFFICULT,
                    y: data?.data?.total_hard_correct_questions,
                  },
                ],
                borderRadius: 5,
                backgroundColor: ["rgb(17, 206, 140)"],
              },
              {
                label: CONSTANT.TYPE.DIFFICULT,
                data: [
                  {
                    x: CONSTANT.TYPE.EASY,
                    y: data?.data?.total_easy_incorrect_questions,
                  },
                  {
                    x: CONSTANT.TYPE.MEDIUM,
                    y: data?.data?.total_medium_incorrect_questions,
                  },
                  {
                    x: CONSTANT.TYPE.DIFFICULT,
                    y: data?.data?.total_hard_incorrect_questions,
                  },
                ],
                borderRadius: 5,
                backgroundColor: ["rgb(253, 90, 88)"],
              },
              {
                label: CONSTANT.LABEL.NOT_ATTEMPTED,
                data: [
                  {
                    x: CONSTANT.TYPE.EASY,
                    y:
                      data?.data?.total_easy_questions -
                      (Number(data?.data?.total_easy_correct_questions) +
                        Number(data?.data?.total_easy_incorrect_questions)),
                  },
                  {
                    x: CONSTANT.TYPE.MEDIUM,
                    y:
                      data?.data?.total_medium_questions -
                      (Number(data?.data?.total_medium_correct_questions) +
                        Number(data?.data?.total_medium_incorrect_questions)),
                  },
                  {
                    x: CONSTANT.TYPE.DIFFICULT,
                    y:
                      data?.data?.total_hard_questions -
                      (Number(data?.data?.total_hard_correct_questions) +
                        Number(data?.data?.total_hard_incorrect_questions)),
                  },
                ],
                borderRadius: 5,
                backgroundColor: ["rgb(251, 201, 90)"],
              },
            ],
          },
          levelWiseTableData: [
            [
              { value: CONSTANT.TYPE.EASY, id: 1, className: "text-center" },
              {
                value: data?.data?.total_easy_questions,
                id: 2,
                className: "text-center",
              },
              {
                value: data?.data?.total_easy_correct_questions,
                id: 3,
                className: "text-center",
              },
              {
                value: data?.data?.total_easy_incorrect_questions,
                id: 4,
                className: "text-center",
              },
              {
                value:
                  data?.data?.total_easy_questions -
                  (Number(data?.data?.total_easy_correct_questions) +
                    Number(data?.data?.total_easy_incorrect_questions)),
                id: 5,
                className: "text-center",
              },
              {
                value:
                  Number(data?.data?.total_easy_correct_questions) <= 0 ||
                  Number(data?.data?.total_easy_questions) <= 0
                    ? 0
                    : (
                        (data?.data?.total_easy_correct_questions /
                          data?.data?.total_easy_questions) *
                        100
                      )?.toFixed(2),
                id: 6,
                className: "text-center",
              },
            ],
            [
              { value: CONSTANT.TYPE.MEDIUM, id: 1, className: "text-center" },
              {
                value: data?.data?.total_medium_questions,
                id: 2,
                className: "text-center",
              },
              {
                value: data?.data?.total_medium_correct_questions,
                id: 3,
                className: "text-center",
              },
              {
                value: data?.data?.total_medium_incorrect_questions,
                id: 4,
                className: "text-center",
              },
              {
                value:
                  data?.data?.total_medium_questions -
                  (Number(data?.data?.total_medium_correct_questions) +
                    Number(data?.data?.total_medium_incorrect_questions)),
                id: 5,
                className: "text-center",
              },
              {
                value:
                  Number(data?.data?.total_medium_correct_questions) <= 0 ||
                  Number(data?.data?.total_medium_questions) <= 0
                    ? 0
                    : (
                        (data?.data?.total_medium_correct_questions /
                          data?.data?.total_medium_questions) *
                        100
                      )?.toFixed(2),
                id: 6,
                className: "text-center",
              },
            ],
            [
              { value: CONSTANT.TYPE.HARD, id: 1, className: "text-center" },
              {
                value: data?.data?.total_hard_questions,
                id: 2,
                className: "text-center",
              },
              {
                value: data?.data?.total_hard_correct_questions,
                id: 3,
                className: "text-center",
              },
              {
                value: data?.data?.total_hard_incorrect_questions,
                id: 4,
                className: "text-center",
              },
              {
                value:
                  data?.data?.total_hard_questions -
                  (Number(data?.data?.total_hard_correct_questions) +
                    Number(data?.data?.total_hard_incorrect_questions)),
                id: 5,
                className: "text-center",
              },
              {
                value:
                  Number(data?.data?.total_hard_correct_questions) <= 0 ||
                  Number(data?.data?.total_hard_questions) <= 0
                    ? 0
                    : (
                        (data?.data?.total_hard_correct_questions /
                          data?.data?.total_hard_questions) *
                        100
                      ).toFixed(2),
                id: 6,
                className: "text-center",
              },
            ],
          ],
        }));
      }

      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();

        setToaster({ show: true, type: "danger", content: data?.message });
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }
    } catch {
      setSpinner(false);
    }
  };

  //*************** Function to get Mock Analysis Video *************************//
  const getMockAnalysisVideo = async () => {
    setSpinner(true);
    const response = await userService.getMockAnalysisVideo({
      exam_id: exam_id,
    });
    try {
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setMockAnalysisVideo(data.data);
        setMockAnalysisVideo(data.data.map((data) => data?.video));
        setSpinner(false);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setToaster({
          show: true,
          type: CONSTANT.TYPE.DANGER,
          content: data?.message,
        });
        setSpinner(false);
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
        setSpinner(false);
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  const difficuiltyLevel = (key) => {
    switch (key) {
      case 1:
        return CONSTANT.TYPE.EASY;
      case 2:
        return CONSTANT.TYPE.MEDIUM;
      case 3:
        return CONSTANT.TYPE.HARD;
      default:
        return "";
    }
  };

  const secondsToHms = (d) => {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    const hDisplay = h > 0 ? h + (h === 1 ? " hrs, " : " hrs, ") : "";
    const mDisplay = m > 0 ? m + (m === 1 ? " mins, " : " mins, ") : "";
    const sDisplay = s > 0 ? s + (s === 1 ? " secs" : " secs") : "";
    return (
      (hDisplay ? hDisplay : "0 hrs ") +
      (mDisplay ? mDisplay : "0 mins ") +
      (sDisplay ? sDisplay : "0 secs ")
    );
  };

  const timeSpentInPercent = (time, exDuration) => {
    time = Number(time);
    exDuration = Number(exDuration);
    return (time / 60 / exDuration) * 100;
  };

  useEffect(() => {
    // getReportOverViewData();
    setInitialValues((prev) => ({ ...prev, divId: "question-1" }));
    //eslint-disable-next-line
    dispatch({
      type: "SET_BREADCRUMB",
      crumb: [{ title: "Tests", url: "/test" }],
    });
  }, []);

  const keyToFunctionMap = {
    "Section Wise": (data) => getSectionWiseReportData(data),
    "Topic Wise": (data) => getTopicWiseReportData(data),
    "Level Wise": (data) => getLevelWiseReportData(data),
    Overview: () => {
      getReportOverViewData();
      getMockAnalysisVideo();
    },
    "Leader Board": () => getLeaderBoardReportData(),
    "Question Wise Analysis": () => getQuestionWiseReportData(),
  };

  useEffect(() => {
    if (keys) {
      setSelectedTemplate(examTemplateData[0]?.name);
      const selectedFunction = keyToFunctionMap[keys];
      if (selectedFunction) {
        selectedFunction(examTemplateData?.length ? examTemplateData[0] : []);
      }
    }
  }, [keys]);

  const keyCallForDataToMap = {
    "Section Wise": (data) => getSectionWiseReportData(data),
    "Topic Wise": (data) => getTopicWiseReportData(data),
    "Level Wise": (data) => getLevelWiseReportData(data),
  };

  const callApiForData = (key, data) => {
    setSelectedTemplate(data?.name);
    const selectedFunction = keyCallForDataToMap[key];
    if (selectedFunction) {
      return selectedFunction(data);
    }
    return null; // Handle default case if needed
  };

  const pdfExportComponent = React.useRef(null);

  const exportPDFWithComponent = (e) => {
    e.preventDefault();
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  const getStatusBoxColor = (data) => {
    const { is_correct, status, selected_option, answers, marks_obtained } =
      data;
    const correctAnswer = answers.find((d) => d.is_correct === 1);
    if (status === 0 || status === 1) {
      if (
        (is_correct === 0 &&
          !["0", null].includes(selected_option) &&
          Number(correctAnswer?.id) === Number(selected_option)) ||
        marks_obtained > 0
      ) {
        return "greenBox";
      }
      if (
        (is_correct === 1 &&
          !["0", null].includes(selected_option) &&
          Number(correctAnswer?.id) !== Number(selected_option)) ||
        marks_obtained <= 0
      ) {
        return "pinkBox";
      }
    }

    if (status === 2 && !["0", null].includes(selected_option)) {
      return "yellowBox";
    }

    if (["0", null].includes(selected_option) || [3, 4].includes(status)) {
      return "yellowBox";
    }

    return null; // Default case, return null if no condition matches
  };

  return (
    <div className="result-dash fixed-dash">
      <div className="white-box body-cont">
        <>
          <div className="sticky-dash-header">
            {keys === "Overview" && (
              <div className=" row reportcard_row border-bottom-dashed mb-3">
                {reviewdData?.centerData?.length
                  ? reviewdData?.centerData?.map((resultDetails, index) => {
                      return (
                        <div
                          className="col-md-3 col-sm-6 col-12 cards"
                          key={index}
                        >
                          <ReportCards
                            title={resultDetails.title || "N"}
                            subTitle={resultDetails.subTitle}
                            result={resultDetails.result}
                            className={`border-end p-0 rounded-0 ${resultDetails.className}`}
                          />
                        </div>
                      );
                    })
                  : [
                      {
                        id: "1",
                        className: "card-box ",
                        title: "Test Name",
                        result: <span className="alert-text h6">N/A</span>,
                        subTitle: "",
                      },
                      {
                        id: "2",
                        className: "cards-box ",
                        title: "Test Duration",
                        result: <span className="alert-text h6">N/A</span>,
                      },
                      {
                        id: "3",
                        className: "cards-box ",
                        title: "Center Name",
                        result: <span className="alert-text h6">N/A</span>,
                      },
                      {
                        id: "4",
                        className: "cards-box ",
                        title: "Candidates Appeared",
                        result: <span className="alert-text h6">N/A</span>,
                      },
                    ].map((y, index) => {
                      return (
                        <div
                          className="col-md-3 col-sm-6 col-12 cards"
                          key={index}
                        >
                          <ReportCards
                            title={y.title || "N"}
                            subTitle={y.subTitle}
                            result={y.result}
                            className={`border-end p-0 rounded-0 ${y.className}`}
                          />
                        </div>
                      );
                    })}
              </div>
            )}
            {reviewdData?.doughnutData ? (
              <>
                <div
                  className={`${
                    keys !== "Overview" ? "exam-tabs mt-5" : "exam-tabs mt-0"
                  }`}
                >
                  <CommonTab
                    setKeys={setKeys}
                    keys={keys}
                    data={tabData}
                    mockVisibility={mockAnalysisVideo}
                  />
                </div>
                <div className="sub-tabs">
                  <ul className="grid-3 nav nav-tabs">
                    {(keys === "Section Wise" ||
                      keys === "Topic Wise" ||
                      keys === "Level Wise") &&
                      examTemplateData
                        // keys === "Topic Wise" ? topics :
                        ?.map((elem) => {
                          return (
                            <li className="nav-item">
                              <Button
                                className={`${
                                  // keys === "Topic Wise" ? selectedTopic :
                                  selectedTemplate === elem?.name
                                    ? "active"
                                    : "white-text"
                                }`}
                                title={elem?.name}
                                // onClick={() => setSelectedTemplate(elem.title)}
                                onClick={() => callApiForData(keys, elem)}
                              />
                            </li>
                          );
                        })}
                  </ul>
                </div>
              </>
            ) : (
              ""
            )}
          </div>

          {/* overview tab  */}
          {reviewdData?.doughnutData ? (
            <div className="tab-sec">
              {keys === "Overview" && (
                <>
                  <div className="row reportcard_row  pb-0 ">
                    {reviewdData?.boardData?.map((item, index) => {
                      //
                      return (
                        <div className="col tab-cards" key={index}>
                          <ReportCards
                            title={item.title}
                            result={
                              item.result &&
                              item.result !== "undefined/undefined" ? (
                                item.result
                              ) : (
                                <span className="alert-text font-16">
                                  No data
                                </span>
                              )
                            }
                            image={item.icon}
                            className={item.className}
                            isSpeed={item.isSpeed}
                            cardData
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="graph-sec">
                    <div className="row border-bottom-dashed pb-5">
                      {/* Analytics for the Whole Question Paper */}
                      <div className="col-md-12 col-xxl-6 progress-col circle-progress pe-4 ">
                        <ProgressBar
                          className="lightPink"
                          progressHeading="Analytics for the Whole Question Paper"
                          circleProgressBar={true}
                          colorData={
                            reviewdData?.doughnutData
                              ? circleProgressColorData
                              : []
                          }
                          progressData={reviewdData?.doughnutData}
                          cardData
                        />
                      </div>
                      {/* Individual vs Topper vs Average Comparison */}
                      <div className="col-md-12 col-xxl-6 progress-col graph-progress ps-4">
                        <ProgressBar
                          progressHeading="Individual vs Topper vs Average Comparison"
                          subGraphHeading="Total Marks Scored"
                          graphProgressBar={true}
                          progressData={[]}
                          totalMarksScored={reviewdData?.totalMarksScored}
                          colorData={graphProgressColorData}
                        />
                      </div>
                    </div>
                    {isOMRTopicTest ? (
                      <div className="row">
                        {/* Time Spend */}
                        {mode !== 1 && (
                          <div className="col-md-7 col-xxl-6 progress-col filled-graph pe-4">
                            <ProgressBar
                              progressHeading="Time Spent"
                              className="gray-bg "
                              filledCircleProgressBar={
                                reviewdData?.sectionTimeCardData?.length
                                  ? true
                                  : false
                              }
                              colorData={reviewdData?.sectionTimeCardData}
                              progressData={[]}
                            />
                          </div>
                        )}
                        {mode !== 1 && (
                          <div className="col-md-5 col-xxl-6 progress-col ps-4">
                            <Table
                              heading={[
                                {
                                  value: "Sections",
                                  id: 1,
                                  className: "w-60",
                                },
                                { value: "Time", id: 2, className: "w-40" },
                              ]}
                              rows={reviewdData?.sectionTimeSpentData}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              )}
              {/* sub tabs  */}
              {keys === "Section Wise" && (
                <>
                  <div className="row reportcard_row pb-0 ">
                    {reviewdData?.boardData?.map(
                      (overviewReportData, index) => {
                        return (
                          <div className="col tab-cards" key={index}>
                            <ReportCards
                              title={overviewReportData.title}
                              result={overviewReportData.result}
                              image={overviewReportData.icon}
                              className={overviewReportData.className}
                              isSpeed={overviewReportData.isSpeed}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div className="row border-bottom-dashed pb-5 mb-5">
                    {/* Analytics for the Whole Question Paper */}
                    <div className="col-md-12 col-xxl-6 progress-col circle-progress pe-4 ">
                      <ProgressBar
                        className=" lightPink"
                        progressHeading="Analytics for the Whole Question Paper"
                        circleProgressBar={true}
                        colorData={circleProgressColorData}
                        progressData={reviewdData?.doughnutData}
                      />
                    </div>
                    {/* Individual vs Topper vs Average Comparison */}
                    <div className="col-md-12 col-xxl-6 progress-col graph-progress ps-4">
                      <ProgressBar
                        progressHeading="Individual vs Topper vs Average Comparison"
                        subGraphHeading="Total Marks Scored"
                        graphProgressBar={true}
                        progressData={[]}
                        totalMarksScored={reviewdData?.totalMarksScored}
                        colorData={graphProgressColorData}
                      />
                    </div>
                  </div>
                  {isOMRTopicTest && (
                    <Table
                      // heading={data?.testExamTableData?.heading}
                      heading={[
                        {
                          value: "Q. No.",
                          id: 1,
                          className: "w-15 text-center",
                        },
                        {
                          value: "Status",
                          id: 2,
                          className: "w-15 text-center",
                        },
                        {
                          value: mode === 0 ? "Time Spent(Seconds)" : "",
                          id: 3,
                          className: "w-20 text-center",
                        },
                        {
                          value: "Level",
                          id: 4,
                          className: "w-30 text-center",
                        },
                        {
                          value: "Correctly Attempted By(Students)",
                          id: 5,
                          className: "w-30 text-center",
                        },
                      ]}
                      rows={reviewdData?.sectionQuestionList}
                    />
                  )}
                </>
              )}
              {keys === "Topic Wise" && (
                <>
                  <Table
                    heading={[
                      { value: "Topics", id: 1, className: "w-15 text-center" },
                      {
                        value: "Total Questions",
                        id: 2,
                        className: "w-15 text-center",
                      },
                      {
                        value: "Attempted",
                        id: 3,
                        className: "w-15 text-center",
                      },
                      {
                        value: "Correct",
                        id: 3,
                        className: "w-15 text-center",
                      },
                      {
                        value: "Accuracy",
                        id: 3,
                        className: "w-15 text-center",
                      },
                      {
                        value: mode === 0 ? "Time Spent(Hr:Min:Sec)" : "",
                        id: 3,
                        className: `w-15 text-center ${
                          isOMRTopicTest ? "" : "d-none"
                        }`,
                      },
                    ]}
                    rows={topicWiseData}
                  />
                </>
              )}
              {keys === "Level Wise" && (
                <>
                  {/* <div className="row reportcard_row  pb-0 ">
                    {reviewdData?.boardData?.map(
                      (overviewReportData, index) => {
                        return (
                          <div className="col tab-cards" key={index}>
                            <ReportCards
                              title={overviewReportData.title}
                              result={overviewReportData.result}
                              image={overviewReportData.icon}
                              className={overviewReportData.className}
                              isSpeed={overviewReportData.isSpeed}
                            />
                          </div>
                        );
                      }
                    )}
                  </div> */}
                  <div className="graph-sec qa-level">
                    <div className="row border-bottom-dashed pb-5 mb-5">
                      {/* Analytics for the Whole Question Paper */}
                      {/* Individual vs Topper vs Average Comparison */}
                      <div className="col-md-12 progress-col graph-progress">
                        <ProgressBar
                          progressHeading="Question Level Wise Analysis"
                          subGraphHeading="Time Comparison (Seconds)"
                          questionlevelProgressBar={true}
                          progressData={[]}
                          totalMarksScored={reviewdData?.totalMarksScored}
                          colorData={data.qaGraphProgressColor}
                          // graphData={reviewdData?.levelWiseChart}
                          graphData={reviewdData?.levelWiseChartData}
                        />
                      </div>
                    </div>
                  </div>
                  <Table
                    heading={[
                      { value: "Level", id: 1, className: "w-15 text-center" },
                      {
                        value: "Total Questions",
                        id: 2,
                        className: "w-15 text-center",
                      },
                      {
                        value: "Correct",
                        id: 3,
                        className: "w-15 text-center",
                      },
                      {
                        value: "Incorrect",
                        id: 4,
                        className: "w-15 text-center",
                      },
                      {
                        value: "Skipped",
                        id: 5,
                        className: "w-15 text-center",
                      },
                      {
                        value: "Accuracy",
                        id: 6,
                        className: "w-15 text-center",
                      },
                    ]}
                    rows={reviewdData?.levelWiseTableData}
                  />
                </>
              )}
              {keys === "Leader Board" && (
                <>
                  <div className="row reportcard_row  pb-0 ">
                    {reviewdData?.boardData?.map(
                      (overviewReportData, index) => {
                        return (
                          <div className="col tab-cards" key={index}>
                            <ReportCards
                              title={overviewReportData.title}
                              result={overviewReportData.result}
                              image={overviewReportData.icon}
                              className={overviewReportData.className}
                              isSpeed={overviewReportData.isSpeed}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div className="result-tables">
                    <div className="sub-report mt-5 border-bottom-dashed pb-5">
                      <div className="dash-heading">
                        <h2>Your Performance</h2>
                      </div>
                      <Table
                        heading={userTableData.head}
                        rows={userTableData?.body}
                      />
                    </div>
                    <div className="sub-report mt-5">
                      <div className="dash-heading">
                        <h2>Top 10 Students</h2>
                      </div>
                      <Table
                        heading={topTenTableData?.head}
                        rows={topTenTableData?.body}
                      />
                    </div>
                  </div>
                </>
              )}
              {keys === "Question Wise Analysis" && (
                <>
                  <div className="question-result d-flex">
                    <div
                      className="question-report-sec"
                      style={{
                        height: keys === "Question Wise Analysis" && "75vh",
                        overflowY: keys === "Question Wise Analysis" && "auto",
                      }}
                    >
                      <div className="question-result-header border-bottom-dashed row ">
                        {/* <div className="col-xxl-5 dash-heading">
                          <h2>Question Paper With Answers</h2>
                        </div> */}
                        {/* <div className="col-xxl-7 question-search-box">
                          <div className="search-form">
                            <form className="adminForm align-items-center d-sm-inline-flex w-100">
                              <div className="search-box ">
                                <div className="form-group radio-group position-relative">
                                  <div className="input-box">
                                    <input
                                      type="text"
                                      placeholder="Search question paper"
                                      className={`text-input searchBox`}
                                      // value={value}
                                      onChange={(e) =>
                                        getQuestionWiseReportData(
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                               <Button
                                  className="small-btn mt-0 px-2"
                                  title={"Download Paper"}
                                  onClick={(e) => {
                                    exportPDFWithComponent(e);
                                  }}
                                /> 
                            </form>
                          </div>
                        </div> */}
                      </div>

                      <div className="result-questions">
                        {/* <PDFExport
                          ref={pdfExportComponent}
                          // paperSize="auto"
                          // margin={40}
                          paperSize="A6"
                          // margin={{ left: "1cm", top: "1cm", right: "1cm", bottom: "1cm" }}
                        > */}
                        <p className="sub-report">
                          <TestQuestionHtml
                            mathsExam={questionAnalysisData}
                            difficuiltyLevel={difficuiltyLevel}
                            examRef={examRef}
                            cross={cross}
                            tick={tick}
                            examName={examName}
                            mode={mode}
                          />
                        </p>
                        {/* </PDFExport> */}
                      </div>
                    </div>
                    <div className="schedule-sidebar">
                      <div className="right-sidebar">
                        <div
                          className="question-count gray-bg"
                          style={{ position: "sticky", top: "100px" }}
                        >
                          <div className="dash-heading border-bottom-dashed pb-4">
                            <h2>Question Palette</h2>
                          </div>
                          <div className="qa-nums qa-nums-scroll">
                            {questionAnalysisData?.map((e, i) => {
                              return (
                                <span
                                  className={`
                                    ${
                                      getStatusBoxColor(e, i)
                                      // values?.divId === `question-${i + 1}`
                                      // ? "pinkBox"
                                      // : e?.answers?.map(a => a?.id)?.includes(+e?.selected_option) ? "greenBox" :
                                      //   "grayBox"
                                    }
                                       inst-box text-center`}
                                  onClick={() =>
                                    gotoServices(`question-${i + 1}`)
                                  }
                                >
                                  {i + 1}
                                </span>
                              );
                            })}
                            <div className="col">
                              <div className="progress-report">
                                <div className="color-util">
                                  <ColorCard
                                    color="greenBox inst-box text-center"
                                    title={CONSTANT.LABEL.CORRECT}
                                  />
                                  <ColorCard
                                    color="pinkBox inst-box text-center"
                                    title={CONSTANT.LABEL.INCORRECT}
                                  />
                                  <ColorCard
                                    color="yellowBox inst-box text-center"
                                    title={CONSTANT.LABEL.NOT_ATTEMPTED}
                                  />
                                  {/* <ColorCard
                                    color="darkBlueBox inst-box text-center"
                                    title={"Answered & Marked for Review "}
                                  /> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="mobile-sidebar-icon position-fixed"
                    onClick={() => setSidebarShow(!sidebarShow)}
                  >
                    <div className="heading">
                      Calendar <Image src={arrowImg} alt="arrow" />
                    </div>
                  </div>
                </>
              )}
              {keys === "Mock Analysis Video" && (
                <>
                  <div className="row">
                    {mockAnalysisVideo?.map((video) => {
                      return (
                        <div className="col-md-4">
                          <div className="d-flex justify-content-center">
                            <iframe
                              src={`${video[0]?.content}?loop=1&autopause=0`}
                              width={"100%"}
                              height="300"
                              frameBorder="0"
                              allow="autoplay; fullscreen"
                              allowFullScreen
                              title="video by id"
                            ></iframe>
                          </div>
                          <h2 className="text-center mt-3">
                            {video[0]?.title}
                          </h2>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          ) : (
            <h2 className="text-center w-100 border-0 position- alert-text h-50 error-msg mb-5">
              {/* <span> No data found</span> */}
              <Image src={dataImg} alt="" />
            </h2>
          )}
        </>
      </div>
    </div>
  );
};

export default ResultNew;
