import moment from "moment";
import React, { useContext } from "react";

import userImage from "../../../assets/images/user.svg";
import Auth from "../../../auth/Auth";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../shared/AppContext";
import Image from "../../utils/image/Image";

const Threads = ({ item, index, isHistory }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setToaster, setSpinner } = useContext(AppContext);

  const handleLiveClass = (elem) => {
    if (moment(new Date()).isBetween(elem?.startAt, elem?.endAt)) {
      if (elem.blockedStudents.indexOf(String(Auth?.user().user_id)) === -1) {
        navigate(`/courses/live_class/${id}/chat/${elem?._id}`, {
          state: isHistory ? true : false,
        }); //to redirect to history page from chat if true
        return;
      }
      setToaster({
        show: true,
        type: "danger",
        content:
          "Oops! you did a mistake. You are blocked, talk to your service manager for immediate help.",
      });
    } else {
      setToaster({
        show: true,
        type: "danger",
        content: `Please wait... Class will be live on ${moment(
          new Date(elem?.startAt)
        ).format("DD-MM-YYYY hh:mm A")}`,
      });
    }

    if (moment(new Date()).isAfter(elem?.endAt)) {
      setToaster({
        show: true,
        type: "danger",
        content: `Class has been expired at ${moment(elem?.endAt).format(
          "HH:MM A"
        )}`,
      });
    }
  };

  return (
    <div className="chat_card_container " title={item?.name} key={index}>
      <div
        className={`chat_card chat_boxS chat_Pbt  ${
          moment(new Date()).isBetween(item?.startAt, item?.endAt)
            ? "brd_unread"
            : ""
        }`}
      >
        <div
          className="card_cont"
          onClick={() => {
            if (isHistory) {
              navigate(`/courses/live_class/${id}/chat/${item?._id}`, {
                state: isHistory ? true : false,
              });
            } else {
              handleLiveClass(item);
            }
          }}
        >
          <div className="row">
            <div className="col-8 d-flex align-items-center">
              <div className="card_img">
                <Image
                  src={userImage}
                  alt="user"
                  className={`small_user-icon ${
                    moment(new Date()).isBetween(item?.startAt, item?.endAt)
                      ? "active-dot-icon-img"
                      : ""
                  }`}
                />
                {moment(new Date()).isBetween(item?.startAt, item?.endAt) && (
                  <div className="active-dot-icon"></div>
                )}
              </div>
              <div className="card_txt">
                <h4 className="ulive">{item?.name}</h4>
                <h5 className="uname mb-0">
                  {/* {elem?.lastMessage?.senderUserId} */}
                </h5>
              </div>
            </div>
            <div className="col-4 d-flex justify-content-end align-items-center flex-wrap">
              <div className="time_con text-end">
                {moment(new Date()).format("DD MMM, YYYY")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Threads;
