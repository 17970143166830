import React from "react";
import Button from "../../utils/forms/Button";
import { Modal } from "react-bootstrap";
const NMATModal = ({ showNmatModal, setShowNmatModal, playPauseTimer }) => {
  return (
    <Modal className="count_modal" size="sm" show={showNmatModal} centered>
      <Modal.Body>
        <div className="">
          <p className="p-0 text-center d-block mt-4" style={{ fontWeight: "500", fontSize: "24px" }}>
            Answered Required
          </p>
          <p className="p-0 d-block text-center" style={{ fontWeight: "400", fontSize: "20px" }}>
            You cannot continue with this question unanswered
          </p>
        </div>
        <div className="d-flex justify-content-center mb-5">
          <Button
            className="proceed small-btn mt-0"
            title="Close"
            onClick={() => {
              setShowNmatModal((prev) => ({ ...prev, nmat: false }));
              playPauseTimer(true);
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default NMATModal;
