import React from "react";
import Table from "../../utils/table/Table";

const FormHelplineDesk = () => {
  return (
    <div className="white-box">
      <div className="gray-bg rounded p-5">
        <div className="dash-heading text-center">
          <h1 className=" text-center mb-5">PRATHAM's Forms Helpline Desk</h1>
          <p className="global-para">
            We will also be assisting Parents/Students to fill up the forms in
            case they are facing any problems w.r.t. uploading/resizing of
            photos and signatures etc.
          </p>
        </div>
        <div className="uni-data mb-5">
          <div className=" white-box p-0">
            <Table
              className="border-0 no-stripe"
              heading={[
                {
                  value: "S.No",
                  id: 1,
                  className: "title-data w-10",
                },
                {
                  value: "Service Zone",
                  id: 2,
                  className: "w-35",
                },
                {
                  value: "Helpline Contact No.",
                  id: 7,
                  className: "w-15",
                },
                {
                  value: "Helpline Contact No.",
                  id: 7,
                  className: "w-25",
                },
              ]}
              rows={[
                [
                  { value: 1, id: 1, className: "color_black" },
                  {
                    value: "Kailash Colony",
                    id: 2,
                    className: "white-space-none color_black",
                  },
                  { value: "011-42666000", id: 1, className: "color_black" },
                  {
                    value: "9599211135 / 7428983029 / 9311414438",
                    id: 1,
                    className: "color_black",
                  },
                ],
                [
                  { value: 2, id: 1, className: "color_black" },
                  {
                    value:
                      "PRATHAM Online, Lucknow, Chandigarh, Non Classroom Program, Jaipur ,Panipat",
                    id: 2,
                    className: "color_black",
                  },
                  { value: "011-42666000", id: 1, className: "color_black" },
                  {
                    value: "9821383324 / 9319987440",
                    id: 1,
                    className: "color_black",
                  },
                ],
                [
                  { value: 3, id: 1, className: "color_black" },
                  {
                    value: "Noida",
                    id: 2,
                    className: "color_black",
                  },
                  { value: "011-42666000", id: 1, className: "color_black" },
                  {
                    value: "9821383324/7428691087",
                    id: 1,
                    className: "color_black",
                  },
                ],

                [
                  { value: 4, id: 1, className: "color_black" },
                  {
                    value: "Faridabad",
                    id: 2,
                    className: "color_black",
                  },
                  { value: "011-42666000", id: 1, className: "color_black" },
                  {
                    value: "9821383324 / 9319987440",
                    id: 1,
                    className: "color_black",
                  },
                ],
                [
                  { value: 5, id: 1, className: "color_black" },
                  {
                    value: "Saket, Malviya Nagar",
                    id: 2,
                    className: "color_black",
                  },
                  { value: "011-42666000", id: 1, className: "color_black" },
                  {
                    value: "9599211135 / 9311414438",
                    id: 1,
                    className: "color_black",
                  },
                ],
                [
                  { value: 6, id: 1, className: "color_black" },
                  {
                    value: "Gurgaon, Vasant Kunj, Safdarjung ",
                    id: 2,
                    className: "color_black",
                  },
                  { value: "011-42666000", id: 1, className: "color_black" },
                  {
                    value: "9599961654 / 9821383324",
                    id: 1,
                    className: "color_black",
                  },
                ],
                [
                  { value: 7, id: 1, className: "color_black" },
                  {
                    value: "Preet Vihar, Karkardooma, Aligarh, Hissar, Karnal",
                    id: 2,
                    className: "color_black",
                  },
                  { value: "011-42666000", id: 1, className: "color_black" },
                  {
                    value: "9599211135 / 9311414435",
                    id: 1,
                    className: "color_black",
                  },
                ],
                [
                  { value: 8, id: 1, className: "color_black" },
                  {
                    value: "Pitampura / Paschim Vihar",
                    id: 2,
                    className: "color_black",
                  },
                  { value: "011-42666000", id: 1, className: "color_black" },
                  {
                    value: "9599211135 / 9311313051",
                    id: 1,
                    className: "color_black",
                  },
                ],
                [
                  { value: 8, id: 1, className: "color_black" },
                  {
                    value: "Rohini AC, Rohini DC, Ashok Vihar, North Campus, ",
                    id: 2,
                    className: "color_black",
                  },
                  { value: "011-42666000", id: 1, className: "color_black" },
                  {
                    value: "9599211135 / 9625311729",
                    id: 1,
                    className: "color_black",
                  },
                ],
                [
                  { value: 8, id: 1, className: "color_black" },
                  {
                    value: "Connaught Place, Karol Bagh, West Patel Nagar, ",
                    id: 2,
                    className: "color_black",
                  },
                  { value: "011-42666000", id: 1, className: "color_black" },
                  {
                    value: "8287956267 / 9821383324",
                    id: 1,
                    className: "color_black",
                  },
                ],
                [
                  { value: 8, id: 1, className: "color_black" },
                  {
                    value: "Janakpuri, Dwarka",
                    id: 2,
                    className: "color_black",
                  },
                  { value: "011-42666000", id: 1, className: "color_black" },
                  {
                    value: "9354395136 / 9821383324",
                    id: 1,
                    className: "color_black",
                  },
                ],
                [
                  { value: 9, id: 1, className: "color_black" },
                  {
                    value: "Rajouri Garden ,Vikaspuri",
                    id: 2,
                    className: "color_black",
                  },
                  { value: "011-42666000", id: 1, className: "color_black" },
                  {
                    value: "7065132233 / 9821383324",
                    id: 1,
                    className: "color_black",
                  },
                ],
                [
                  {
                    value: 10,
                    id: 1,
                    className: "color_black",
                  },
                  {
                    value:
                      "Lets CAT Pitampura/ Connaught Place / Kailash Colony / North Campus/ Pratham Online",
                    id: 1,
                    className: "color_black",
                  },
                  { value: "011-42666000", id: 1, className: "color_black" },
                  {
                    value: "7428983024",
                    id: 1,
                    className: "color_black",
                  },
                ],
              ]}
            />
          </div>
        </div>

        <p className="global-para text-center">
          For any query or assistance in form filling, please call your
          respective Client Service Managers or Centre Managers.
        </p>
        <p className="global-para text-center fw-bold">Regards</p>
        <p className="global-para text-center fw-bold">Team Pratham</p>
      </div>
    </div>
  );
};

export default FormHelplineDesk;
