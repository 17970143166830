import React, { useState, useEffect, useContext } from "react";

import { Link } from "react-router-dom";
import { useForm } from "@kaydhiman/react-hook-useform";

import OtpInput from "react-otp-input";

import InputField from "../../utils/forms/InputField";
import CommonModal from "../../utils/modal/CommonModal";
import Button from "../../utils/forms/Button";
import BackButton from "../../utils/backBtn/BackButton";

import { AppContext } from "../../shared/AppContext";
import { userService } from "../../../_services/User.services";

import { CONSTANT } from "../../utils/constants";

import { useBreadcrumb } from "../../shared/breadcrumb-context";
import {
  useLogin,
  useProfileDetails,
  useUpdateLMSPassword,
  useUpdatePSISPassword,
  useUpdateProfilePhoto,
} from "../../../hooks/hookList";

import cameraImg from "../../../assets/images/camera.png";
import varfyIcon from "../../../assets/images/varification.svg";
import CrossVarfyIcon from "../../../assets/images/cross-varification.svg";
import userImg from "../../../assets/images/user-image.jpg";
import hidePass from "../../../assets/images/pass_view-svg.png";
import showPass from "../../../assets/images/eye-svg.png";
import UpdateProfileImageModal from "./UpdateProfileImageModal";
import Auth from "../../../auth/Auth";
import Image from "../../utils/image/Image";

const UpdateProfile = () => {
  const { values, bindField, setInitialValues } = useForm({ validations: {} });
  const { setSpinner, setToaster, setUserData } = useContext(AppContext);
  const [type, setType] = useState("");
  const [otp, setOtp] = useState("");
  const [show, setShow] = useState(false);
  const [passChanged, setPassChanged] = useState(false);
  const [varfyModalShow, setVarfyModalShow] = useState(false);
  const [passwordModalShow, setPasswordModalShow] = useState(false);
  const [showImage, setShowImage] = useState();
  const [counterValue, setCounterValue] = useState(5);
  const [showHidePassword, setShowHidePassword] = useState([
    false,
    false,
    false,
  ]);
  const { dispatch } = useBreadcrumb();
  let countDown;
  const { getData: getProfileData } = useProfileDetails(setSpinner, setToaster);
  const { getData: getLogin } = useLogin(setSpinner, setToaster);
  const { getData: updatePSISPassword } = useUpdatePSISPassword(
    setSpinner,
    setToaster
  );

  const { getData: updatePassword } = useUpdateLMSPassword(
    setSpinner,
    setToaster
  );

  const { getData: updateProfilePhoto } = useUpdateProfilePhoto(
    setSpinner,
    setToaster
  );

  // Array of password fields
  const passwordFields = [
    {
      label: "Old Password",
      id: "password",
      name: "old_password",
      placeholder: "Enter Old Password",
    },
    {
      label: "New Password",
      id: "new_password",
      name: "new_password",
      placeholder: "Enter New Password",
    },
    {
      label: "Confirm Password",
      id: "confirm_password",
      name: "confirm_password",
      placeholder: "Enter Confirm Password",
    },
  ];

  // Function to handle input field changes
  const bindFieldPassword = (name) => ({
    value: values[name],
    onChange: (event) => {
      setInitialValues({
        ...values,
        [name]: event.target.value,
      });
    },
  });

  // Function to toggle show/hide password
  const setShowHidePasswordToggle = (index, value) => {
    const updatedShowHidePassword = [...showHidePassword];
    updatedShowHidePassword[index] = value;
    setShowHidePassword(updatedShowHidePassword);
  };

  const startTimer = () => {
    countDown = setInterval(() => {
      setCounterValue((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(countDown);
          localStorage.clear();
          window.location.href = process.env.REACT_APP_PRATHAM_BASE_URL;
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);
  };

  const profilePicModalShow = () => {
    setShow(true);
  };
  const profilePicModalClose = () => {
    setShow(false);
    // setShowImage(studentInfo?.image ? studentInfo?.image : userImg);
  };

  // update passowrd
  const passwordModalOpen = () => {
    setPasswordModalShow(true);
  };
  const passwordModalClose = () => {
    setPasswordModalShow(false);
  };
  // password changed
  const passwordChangedOpen = () => {
    setPassChanged(true);
    setPasswordModalShow(false);
    startTimer();
  };
  // varfication Modal
  const varfyModalOpen = () => {
    setVarfyModalShow(true);
  };
  const varfyModalClose = () => {
    setVarfyModalShow(false);
  };

  const handleImage = (e) => {
    setInitialValues((prev) => ({
      ...prev,
      image: URL.createObjectURL(e.target.files[0]),
    }));
    setShowImage(e.target.files[0]);
  };

  // Profile Details Callback
  const profileDetailsCallback = (profileDetails) => {
    setInitialValues({
      ...profileDetails?.data,
      student_class: profileDetails?.data.class?.name,
      student_stream: profileDetails?.data?.stream?.name,
      image:
        profileDetails?.data?.image !== "NULL" &&
        profileDetails?.data?.image !== null
          ? profileDetails?.data?.image
          : userImg,
      board: profileDetails?.data?.board?.name,
      primary_email: profileDetails?.data?.primary_email
        ? profileDetails?.data?.primary_email
        : profileDetails?.data?.email,
      primary_phone: profileDetails?.data?.primary_phone
        ? profileDetails?.data?.primary_phone
        : profileDetails?.data?.phone,
    });
    profileDetails?.data?.additional_details?.forEach((det) => {
      setInitialValues((prev) => ({
        ...prev,
        [det?.key]: [det?.value][0],
      }));
      setUserData({
        ...profileDetails?.data,
        student_class: profileDetails?.data?.class?.name,
        student_stream: profileDetails?.data?.stream?.name,
      });
    });
  };

  // Update PSIS Password Handler
  const updatePSISPasswordHandler = async (e) => {
    e.preventDefault();
    if (
      !values.old_password ||
      !values.new_password ||
      !values.confirm_password
    ) {
      setToaster({
        show: true,
        type: "danger",
        content: "All fields are mandatory",
      });
      return;
    }
    const arePasswordFieldsDefined =
      (values.confirm_password !== undefined &&
        values.confirm_password !== "") ||
      (values.new_password !== undefined && values.new_password !== "") ||
      (values.old_password !== undefined && values.old_password !== "");
    if (arePasswordFieldsDefined) {
      if (values.new_password !== values.confirm_password) {
        setToaster({
          show: true,
          type: "danger",
          content: "The confirm password does not match with the new password",
        });
      } else if (
        values.new_password.length < 8 ||
        values.confirm_password.length < 8
      ) {
        setToaster({
          show: true,
          type: "danger",
          content: "Password must be at least 8 characters long.",
        });
      } else {
        const params = {
          user_id: values.user_id,
          role: "student",
          password: values.old_password,
        };
        await getLogin(params, async (data) => {
          if (data.success === false) {
            setToaster({
              show: true,
              type: "danger",
              content: "The old password you entered is incorrect  ",
            });
          } else {
            if (values.new_password !== values.confirm_password) {
              setToaster({
                show: true,
                type: "danger",
                content:
                  "The confirm password does not match with the new password",
              });
            } else {
              const params = {
                enrollmentId: values.user_id,
                password: values.new_password,
              };
              await updatePSISPassword(params, (data) => {
                if (data.success === true) {
                  const params = {
                    old_password: values?.old_password,
                    new_password: values?.new_password,
                    confirm_password: values?.confirm_password,
                  };
                  updatePassword(params, async (data) => {
                    if (data.success === false) {
                      const params = {
                        enrollmentId: values.user_id,
                        password: values.old_password,
                      };
                      await updatePSISPassword(params, () => {
                        setToaster({
                          show: true,
                          type: "danger",
                          content:
                            "Passwords must consist of numeric characters only",
                        });
                      });
                    } else {
                      passwordChangedOpen();
                    }
                  });
                }
              });
            }
          }
        });
      }
    } else {
      setToaster({
        show: true,
        type: "danger",
        content: "All fields are mandatory",
      });
    }
  };

  // ************************Get Profile Data******************************
  const updateProfilePhotoHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if(showImage){
      formData?.append("image", showImage);
    }
    formData?.append("enrollment_id", Auth.user()?.user_id);
    formData?.append("student_id", Auth.user()?.id);
    await updateProfilePhoto(formData, updateProfileCallback);
  };
  const updateProfileCallback = (profilePhoto) => {
    setToaster({ show: true, type: "success", content: profilePhoto?.message });
    getProfileData({}, profileDetailsCallback);
    setSpinner(false);
    setShowImage({});
    profilePicModalClose();
  };
  // ************************Verify Email and Phone******************************
  const sendCode = async (e, type) => {
    e.preventDefault();
    setType(type === 0 ? "email" : "phone");
    setSpinner(true);
    try {
      let params = {
        ...(type === 0
          ? { email: values?.email }
          : { user_id: values?.user_id }),
      };
      const response = await userService.sendCode(params);
      if (response?.status >= 200 && response?.status <= 299) {
        const data = await response.json();

        setSpinner(false);
        varfyModalOpen();
        setToaster({ show: true, type: "success", content: data.message });
        setOtp("");
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }
      if (response?.status >= 400 && response?.status <= 499) {
        const data = await response.json();
        setToaster({ show: true, type: "danger", content: data.message });
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  // ************************Verify Email and Phone******************************
  const VerifyOTP = async (e) => {
    e.preventDefault();
    setSpinner(true);
    try {
      let params = {
        // ...(type === 0
        //   ? { email: values?.email }
        //   : { user_id: values?.user_id }),
        ...(type === "email"
          ? { email: values?.email }
          : { user_id: values?.user_id }),
        otp: otp,
      };

      const response = await userService.profileVerifyOTP(params);
      if (response?.status >= 200 && response?.status <= 299) {
        const data = await response.json();
        varfyModalClose();
        getProfileData({}, profileDetailsCallback);
        setSpinner(false);
        setToaster({ show: true, type: "success", content: data.message });
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }
      if (response?.status >= 400 && response?.status <= 499) {
        const data = await response.json();

        setToaster({ show: true, type: "danger", content: data.message });
        setSpinner(false);
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getProfileData({}, profileDetailsCallback);
    dispatch({
      type: "SET_BREADCRUMB",
      crumb: [{ title: "Update Profile", url: "/update-profile" }],
    });
    //eslint-disable-next-line
  }, []);
  return (
    <>
      <div className=" update-profile-dash">
        <div className="white-box">
          <div className="modalHeading mb-5">
            <h1>
              {" "}
              <BackButton />
              <span className="h2">
                <i>{values?.name}</i>
              </span>{" "}
              's Profile
            </h1>
          </div>
          {/* <button
            className="btn theme-btn small-btn prev-btn mb-2 mt-0"
            onClick={() => { navigate("/dashboard") }}
          >
            Back
          </button> */}
          <div className="adminForm  profile-form ">
            <div className="row align-items-center profile-pic-row ">
              <div className="col-5 col-sm-block d-sm-flex align-items-center mb-5 mb-lg-0 ">
                <div
                  className="profile-box border-end me-sm-5"
                  onClick={profilePicModalShow}
                >
                  <label className="pointer">
                    <Image
                      src={cameraImg}
                      alt="Camera"
                      className="position-absolute camera-img"
                    />
                  </label>
                  <Image
                    src={values?.image !== "NULL" ? values?.image : userImg}
                    alt="User"
                    className="img-fluid current-img user-img"
                  />
                </div>
                <div className="input-area w-100 mt-5 mt-md-0">
                  <InputField
                    label="Name"
                    name="name"
                    bindField={bindField}
                    value={values?.name || ""}
                    id="name"
                    placeholder="Enter name"
                    type="text"
                    required
                    disable={true}
                  />
                </div>
              </div>
              <div className="col">
                <InputField
                  label="User ID"
                  name="user_id"
                  bindField={bindField}
                  value={values?.user_id || ""}
                  id="user_id"
                  placeholder="Enter user id"
                  type="text"
                  disable={true}
                  required
                />
              </div>
              <div className="col">
                <InputField
                  label="Password"
                  id="password"
                  type="password"
                  name="password"
                  value={values?.password || ""}
                  bindField={bindField}
                  placeholder="********"
                  disable={true}
                  inputButtonData={{
                    className: "lock_ico pointer",
                    isButton: true,
                    title: "Update Password",
                    onClick: passwordModalOpen,
                  }}
                  iconData={{
                    className: "lock_ico pointer",
                    isLock: true,
                    src: showHidePassword ? showPass : hidePass,
                    alt: "user Icon",
                  }}
                  required
                />
              </div>
            </div>
            <div className="row  ">
              {/* <div className="col-md-6 col-lg-4  d-flex align-items-end">
                <div className="w-100">
                  <InputField
                    label="Board"
                    name="board"
                    className="w-100"
                    bindField={bindField}
                    value={values?.board || ""}
                    id="board"
                    type="text"
                    placeholder="Board"
                    disable={true}
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <InputField
                  label="Class"
                  name="student_class"
                  bindField={bindField}
                  value={values?.student_class || ""}
                  id="student_class"
                  placeholder="Class"
                  type="text"
                  disable={true}
                />
              </div>
              <div className="col-md-6 col-lg-4">
                <InputField
                  label="Stream"
                  id="student_stream"
                  name="student_stream"
                  value={values?.student_stream || ""}
                  bindField={bindField}
                  placeholder="Stream"
                  required
                  disable={true}
                />
              </div> */}
              <div className="col-md-6 col-lg-4  d-flex align-items-end">
                <div className="w-100">
                  <InputField
                    label={"Address"}
                    name="address"
                    className="w-100"
                    bindField={bindField}
                    value={values?.address || ""}
                    id="address"
                    type="text"
                    placeholder="Full address"
                    disable={true}
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <InputField
                  label="Pincode"
                  name="pincode"
                  bindField={bindField}
                  value={values?.pincode || ""}
                  id="pincode"
                  placeholder="Pincode"
                  type="number"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    } else if (event.target.value.length === 6) {
                      event.preventDefault();
                    }
                  }}
                  disable={true}
                />
              </div>
              <div className="col-md-6 col-lg-4">
                <InputField
                  label="City"
                  id="city"
                  name="city"
                  value={values?.city || ""}
                  bindField={bindField}
                  placeholder="City"
                  disable={true}
                />
              </div>
              <div className="col-md-6 col-lg-4">
                <InputField
                  label="State"
                  name="state"
                  bindField={bindField}
                  value={values?.state || ""}
                  id="state"
                  placeholder="State"
                  type="text"
                  disable={true}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-end">
                <div className="w-100">
                  <InputField
                    label="Country"
                    name="country"
                    className="w-100"
                    bindField={bindField}
                    value={values?.country || ""}
                    id="country"
                    type="text"
                    placeholder="Country"
                    disable={true}
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="varify-input">
                  <InputField
                    type="email"
                    name="primary_email"
                    label="Primary Email Address"
                    value={values?.primary_email || ""}
                    placeholder="Email ID"
                    id="primary_email"
                    bindField={bindField}
                    disable={true}
                    required
                  />
                  {values?.is_email_verified === 0 && values?.primary_email && (
                    <div className="vaified varify-input-content ">
                      <span className="pink-text d-xs-none">Not Verified</span>
                      <Link
                        className="proceed btn theme-btn small-btn ms-3 mt-0"
                        // onClick={thankYouModalOpen}
                        to="#"
                        // to="/update-profile"
                        onClick={(e) => sendCode(e, 0)}
                      >
                        <span className="">
                          <Image
                            src={CrossVarfyIcon}
                            alt="varification cross icon "
                            width="16px"
                            className="me-2 d-xs-block d-none"
                            onClick={() => setType(0)}
                          />
                          <span>Verify Now</span>
                        </span>
                      </Link>
                    </div>
                  )}{" "}
                  {values?.is_email_verified === 1 && values?.primary_email && (
                    <div className="vaified varify-input-content green-text">
                      <span className="d-flex p-0">
                        <Image
                          src={varfyIcon}
                          alt="varification check icon "
                          width="16px"
                          className="me-2"
                        />
                        <span>Verified</span>
                      </span>
                    </div>
                  )}
                </div>
              </div>

              {/* <div className="col-md-6 col-lg-4">
                <InputField
                  label="Gender"
                  id="gender"
                  name="gender"
                  value={values?.gender || ""}
                  bindField={bindField}
                  placeholder="Gender"
                  disable={true}
                />
              </div> */}

              <div className="col-md-6 col-lg-4">
                <div className="varify-input">
                  <InputField
                    type="text"
                    name="primary_phone"
                    label="Primary Phone Number"
                    placeholder="Primary phone number"
                    value={values?.primary_phone || ""}
                    id="phone-num"
                    bindField={bindField}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      } else if (event.target.value.length === 10) {
                        event.preventDefault();
                      }
                    }}
                    disable={true}
                    required
                  />
                  {values?.is_phone_verified === 0 && values?.primary_phone && (
                    <div className="vaified varify-input-content ">
                      <span className="pink-text d-xs-none">Not Verified</span>
                      <Link
                        className="proceed btn theme-btn small-btn ms-3 mt-0"
                        // onClick={thankYouModalOpen}
                        to="#"
                        // to="/update-profile"
                        // onClick={varfyModalOpen}
                        onClick={(e) => sendCode(e, 1)}
                      >
                        <span className="">
                          <Image
                            src={CrossVarfyIcon}
                            alt="varification cross icon "
                            width="16px"
                            className="me-2 d-xs-block d-none"
                            onClick={() => setType(1)}
                          />
                          <span>Verify Now</span>
                        </span>
                      </Link>
                    </div>
                  )}
                  {values?.is_phone_verified === 1 && values?.primary_phone && (
                    <div className="vaified varify-input-content green-text">
                      <span className="d-flex p-0">
                        <Image
                          src={varfyIcon}
                          alt="varification check icon "
                          width="16px"
                          className="me-2"
                        />
                        <span>Verified</span>
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="varify-input">
                  <InputField
                    type="email"
                    name="alt_email"
                    label="Alternate Email Address"
                    placeholder="Alternate email address"
                    id="alt_email"
                    bindField={bindField}
                    disable={true}
                    required
                  />
                  {/* <div className="vaified varify-input-content green-text">
                    <span className="d-flex p-0">
                      <Image
                        src={varfyIcon}
                        alt="varification check icon "
                        width="16px"
                        className="me-2"
                      />
                      <span>Verified</span>
                    </span>
                  </div> */}
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="varify-input">
                  <InputField
                    type="text"
                    name="alt_phone"
                    label="Alternate Phone Number"
                    placeholder="Alternate phone number"
                    id="alt_phone"
                    bindField={bindField}
                    disable={true}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      } else if (event.target.value.length === 10) {
                        event.preventDefault();
                      }
                    }}
                    required
                  />
                  {/* <div className="vaified varify-input-content green-text">
                    <span className="d-flex p-0">
                      <Image
                        src={varfyIcon}
                        alt="varification check icon "
                        width="16px"
                        className="me-2"
                      />
                      <span>Verified</span>
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* PROFILE PIC MODAL  */}
        <UpdateProfileImageModal
          handleImage={handleImage}
          profilePicModalClose={profilePicModalClose}
          show={show}
          showImage={showImage}
          updateProfilePhoto={updateProfilePhotoHandler}
          values={values}
          image={values?.image}
        />
        {/* password change modal  */}
        <CommonModal
          className="profile-modal profile-pic-modal "
          show={passwordModalShow}
          close={passwordModalClose}
          body={
            <div className="common_modal">
              <div className="modalHeading">
                <h1 className="modal-title text-center">Update Password</h1>
              </div>
              <div className="profile-form adminForm">
                <form>
                  {passwordFields?.map((field, index) => (
                    <InputField
                      key={index}
                      label={field.label}
                      id={field.id}
                      name={field.name}
                      value={values[field.name] || ""}
                      bindField={bindFieldPassword}
                      placeholder={field.placeholder}
                      type={showHidePassword[index] ? "text" : "password"}
                      iconData={{
                        className: "lock_ico pointer",
                        isLock: true,
                        src: showHidePassword[index] ? showPass : hidePass,
                        alt: "user Icon",
                      }}
                      isPassword
                      setShowHidePasswordToggle={() => {
                        setShowHidePasswordToggle(
                          index,
                          !showHidePassword[index]
                        );
                      }}
                    />
                  ))}
                </form>
                <div className="modal-footer d-block">
                  <div className="button-footer w-100">
                    <Button
                      className="proceed  small-btn px-4 py-3 w-100"
                      onClick={updatePSISPasswordHandler}
                      title="Update"
                      disable={
                        !values?.new_password ||
                        !values?.confirm_password ||
                        !values?.old_password
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        />

        {/* password changed  */}
        <CommonModal
          className="profile-modal "
          show={passChanged}
          body={
            <div className="common_modal">
              <div className="body-content d-flex justify-content-center text-center mb-4">
                {/* <Image src={doneGif} alt="Done " /> */}
                <div className="blink_timer text-center d-flex justify-content-center align-items-center">
                  <div className="text-white" style={{ fontSize: "50px" }}>
                    <span>{counterValue || 0}</span>
                  </div>
                </div>
              </div>
              <div className="modalHeading">
                <h1 className="modal-title text-center px-5">
                  Congratulations! <br />
                  Your password has been successfully changed. Redirecting you
                  to the PSIS Page shortly.
                </h1>
              </div>
              <div className="w-100 d-flex justify-content-center"></div>
            </div>
          }
        />

        {/* varified  */}
        <CommonModal
          className="varification-modal "
          show={varfyModalShow}
          close={varfyModalClose}
          // heading={"My Profile"}
          body={
            <div className="common_modal">
              <div className="modalHeading">
                <h1 className="modal-title text-center mb-4">
                  {type === "email" && "Verify Email Address"}
                  {type === "phone" && "Verify Phone Number"}
                </h1>
                <p className="dark-gray m-auto text-center sub-heading">
                  Please enter your six digits verification code sent to &nbsp;
                  {type === "phone" ? values?.phone : values?.email}
                  {/* <span>
                    <b>
                      {studentInfo?.email &&
                        (studentInfo?.email ? (
                          studentInfo?.email
                        ) : (
                          <span className="pink-text">Enter email address</span>
                        ))}
                      {studentInfo?.phone &&
                        (studentInfo?.phone ? (
                          studentInfo?.phone
                        ) : (
                          <span className="pink-text">Enter phone number</span>
                        ))}
                    </b>
                  </span> */}
                </p>
              </div>
              <div className="profile-form">
                <div className="profile-form adminForm">
                  <p className="dark-text form-heading">OTP</p>
                  <form className="d-md-flex ">
                    <OtpInput
                      className="mb-4 w-100"
                      value={otp}
                      onChange={(codesms) => setOtp(codesms)}
                      numInputs={6}
                      separator={<span style={{ width: "8px" }}></span>}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      inputStyle={{
                        borderRadius: "8px",
                        width: "54px",
                        height: "54px",
                        fontSize: "12px",
                        color: "#000",
                        fontWeight: "400",
                        caretColor: "blue",
                        border: "1px solid #CFD3DB",
                        backgroundColor: "#F8FAFF",
                      }}
                      focusStyle={{
                        border: "1px solid #CFD3DB",
                        outline: "none",
                      }}
                    />
                  </form>
                </div>
                <div className="modal-footer px-0 d-block">
                  <div className="button-footer w-100">
                    <Button
                      className="proceed  small-btn px-4 py-3 w-100"
                      // onClick={varfyModalClose}
                      onClick={(e) => VerifyOTP(e)}
                      title="Submit"
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </div>
    </>
  );
};

export default UpdateProfile;
