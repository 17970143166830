import React from "react";
import CourseImage from "./CourseImage";
import CourseTitle from "./CourseTitle";
import CourseBackNavigation from "./CourseBackNavigation";
import CourseDescription from "./CourseDescription";
import ContentDetailResource from "./ContentDetailResource";

const ContentCard = ({
  title,
  description,
  icon,
  defaultImage,
  resourceCount,
  newResourceCount,
  className,
  isOpenDescription,
  setIsOpenDescription,
  courseId,
  toggleDescription,
  setIsFlipped,
  isFlipped,
  setIsNewResource,
  folderId,
  onClickResource,
  setCourseName,
}) => {
  return (
    <div
      key={courseId}
      className={`content_card ${className} ${
        isFlipped && isOpenDescription === courseId && "flipped"
      } `}
    >
      {isOpenDescription !== courseId && (
        <CourseImage
          icon={icon}
          defaultImage={defaultImage}
          courseId={courseId}
          folderId={folderId}
        />
      )}
      <div
        className={`${
          isOpenDescription === courseId
            ? "content_details_2 back"
            : "content_details"
        }`}
      >
        {title !== null && (
          <div
            className={
              isOpenDescription === courseId
                ? "d-flex justify-content-between"
                : ""
            }
          >
            <CourseTitle
              isOpenDescription={isOpenDescription}
              title={title}
              courseId={courseId}
            />
            {isOpenDescription === courseId && (
              <CourseBackNavigation
                setIsOpenDescription={setIsOpenDescription}
                setIsFlipped={setIsFlipped}
              />
            )}
          </div>
        )}
        {description !== null && (
          <CourseDescription
            courseId={courseId}
            description={description}
            isOpenDescription={isOpenDescription}
            toggleDescription={toggleDescription}
          />
        )}
        <ContentDetailResource
          title={title}
          newResourceCount={newResourceCount}
          resourceCount={resourceCount}
          setIsNewResource={setIsNewResource}
          courseId={courseId}
          folderId={folderId}
          onClickResource={onClickResource}
          setCourseName={setCourseName}
        />
      </div>
    </div>
  );
};

export default ContentCard;
