// QuestionComponent.jsx
import React from "react";
import "react-simple-keyboard/build/css/index.css";
import Keyboard from "react-simple-keyboard";
import NumPad from "react-numpad";
import tick from "../../../assets/images/blue-tick01.svg";
import NumericKeypad from "../examLatestPratham/NumericKeypad";
import Image from "../../utils/image/Image";

const Question = ({
  questionData,
  currentQuestionIndex,
  questionType,
  isOptionSelected,
  keyboardRef,
  subjectiveAnswer,
  numericAnswer,
  oneWordAnswer,
  selectedOption,
  handleOptionChange,
  handleOneWordAnswer,
  handleOneWordInputChange,
  handleNumericAnswer,
  handleSaveSubjectiveAnswer,
  handleOnChangeOneWord,
  handleSubjectiveAnswer,
  handleUpdateNumericalQuestion,
  isHideNumericalKeyboard,
  isHideOneWordKeyboard,
  isHideSubjectiveKeyboard,
}) => {
  const correctAnswer = questionData[currentQuestionIndex]?.options?.find(
    (correctAnswer) => correctAnswer.is_correct === 1
  );
  // const isSubjectiveAnswered = subjectiveAnswer !== null ? subjectiveAnswer : "";
  const isNumericAnswered = numericAnswer !== null ? numericAnswer : "";
  const isOneWordAnswered = oneWordAnswer !== undefined ? oneWordAnswer : "";
  const isSubjectiveAnswered =
    subjectiveAnswer !== null ? subjectiveAnswer : "";

  return (
    <div className="sub-report">
      {questionData?.length > 0 && (
        <div className="question-dash">
          <div
            className={`dash-heading question-head ${
              questionData[currentQuestionIndex]?.comprehension_id !== null
                ? "comp-test"
                : ""
            }`}
          >
            {questionData[currentQuestionIndex]?.comprehension_id !== null && (
              <div className="comp-questions comprehension_questions">
                <h4 className="comp-questions-heading">
                  Comprehension for Questions{" "}
                </h4>

                <div className="comp-questions-cont">
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        questionData[currentQuestionIndex]?.comprehension[0]
                          ?.name,
                    }}
                  ></p>
                </div>
              </div>
            )}
            <div className="question_container">
              <h3>Question {currentQuestionIndex + 1}</h3>
            </div>
            <div className="btn question-type">
              {questionType(
                questionData[currentQuestionIndex]?.question_type_id
              )}
            </div>
          </div>
          <div
            className="question mcq-questions home_questions"
            dangerouslySetInnerHTML={{
              __html: questionData[currentQuestionIndex]?.question,
            }}
          ></div>
          <div className="awns_text home_options">
            <ul className="mcq-awns">
              {![3, 4, 5].includes(
                questionData[currentQuestionIndex]?.question_type_id
              ) &&
                questionData[currentQuestionIndex]?.options?.map((awnData) => {
                  let option = (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: awnData.option,
                      }}
                    ></div>
                  );
                  return (
                    <li key={awnData.id}>
                      <div className="form-group radio-group position-relative">
                        <div className="input-box">
                          <input
                            className="d-none text-input custon-radio"
                            type={
                              questionData[currentQuestionIndex]
                                ?.question_type_id === 2 &&
                              questionData[
                                currentQuestionIndex
                              ]?.options?.filter((a) => a?.is_correct === 1)
                                ?.length > 1
                                ? "checkbox"
                                : "radio"
                            }
                            name={`awn_${
                              questionData[currentQuestionIndex]
                                ?.question_type_id === 2
                                ? `checkbox_${awnData?.id}`
                                : `radio_${questionData[currentQuestionIndex]?.id}`
                            }`}
                            value={awnData.id}
                            id={awnData.id}
                            onChange={(e) =>
                              handleOptionChange(e, awnData.question_id)
                            }
                            checked={isOptionSelected(
                              awnData.question_id,
                              awnData.id
                            )}
                            disabled={!["0", null].includes(selectedOption)}
                          />
                          <label htmlFor={awnData.id}>{option}</label>
                          {!["0", null].includes(selectedOption) &&
                            correctAnswer?.id === awnData?.id && (
                              <button className="small-btn report-green-btn mt-0 no-wrap ms-3">
                                <Image
                                  src={tick}
                                  width={15}
                                  height={15}
                                  alt="tick"
                                />{" "}
                                <span>Correct Answer</span>
                              </button>
                            )}
                          {!["0", null].includes(selectedOption) &&
                            Number(selectedOption) === awnData?.id && (
                              <button
                                className={`small-btn report-theme-btn mt-0 no-wrap ms-4`}
                              >
                                <Image src={tick} width={15} height={15} alt="tick" />
                                <span>My Answer</span>
                              </button>
                            )}
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
          {questionData[currentQuestionIndex]?.question_type_id === 3 && (
            <>
              {!isHideNumericalKeyboard && (
                <div className="numeric_input_field_container">
                  <input
                    className="w-100 numeric_input_field"
                    type="text"
                    value={isNumericAnswered}
                    readOnly
                    placeholder="Enter Your Answer"
                  />
                  <NumericKeypad
                    onKeyPress={handleNumericAnswer}
                    handleUpdateNumericalQuestion={
                      handleUpdateNumericalQuestion
                    }
                  />
                </div>
              )}
              {isHideNumericalKeyboard && (
                <div style={{ padding: "0 14px" }}>
                  <div>
                    <span>{selectedOption || isNumericAnswered}</span>{" "}
                    <span>
                      <button
                        className={`small-btn ${
                          isNumericAnswered ===
                          questionData[currentQuestionIndex]?.options[0]?.option
                            ? "report-green-btn"
                            : "report-theme-btn"
                        } mt-0 no-wrap ms-4`}
                      >
                        <Image src={tick} width={15} height={15} alt="tick" />
                        <span>Your Answer</span>
                      </button>
                    </span>{" "}
                  </div>
                  <div className="my-4">
                    <span>
                      {questionData[currentQuestionIndex]?.options[0]?.option}
                    </span>
                    <span>
                      <button
                        className={`small-btn report-green-btn mt-0 no-wrap ms-4`}
                      >
                        <Image src={tick} width={15} height={15} alt="tick" />
                        <span>Correct Answer</span>
                      </button>
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
          {questionData[currentQuestionIndex]?.question_type_id === 4 &&
            !isHideOneWordKeyboard && (
              <div className="awn-keyboard">
                <input
                  value={isOneWordAnswered || ""}
                  onChange={handleOneWordInputChange}
                  placeholder={"Enter your answer"}
                  readOnly
                />
                <Keyboard
                  keyboardRef={(r) => (keyboardRef.current = r)}
                  onChange={(value) => {
                    handleOnChangeOneWord(
                      value,
                      questionData[currentQuestionIndex]?.options[0]
                        ?.question_id
                    );
                  }}
                  value={keyboardRef?.current?.getInput()}
                />
                <button
                  className="btn theme-btn w-30 p-2 "
                  onClick={handleOneWordAnswer}
                >
                  Save Answer
                </button>
              </div>
            )}
          {isHideOneWordKeyboard && (
            <div style={{ padding: "0 14px" }}>
              <div>
                <span>{selectedOption || isOneWordAnswered}</span>{" "}
                <span>
                  <button
                    className={`small-btn ${
                      String(isOneWordAnswered)
                        .toLocaleLowerCase()
                        .replaceAll(".", "")
                        .replaceAll(",", "") ===
                      String(
                        questionData[currentQuestionIndex]?.options[0]?.option
                      )
                        .toLocaleLowerCase()
                        .replaceAll(".", "")
                        .replaceAll(",", "")
                        ? "report-green-btn"
                        : "report-theme-btn"
                    } mt-0 no-wrap ms-4`}
                  >
                    <Image src={tick} width={15} height={15} alt="tick" />
                    <span>Your Answer</span>
                  </button>
                </span>{" "}
              </div>
              <div className="my-4">
                <span>
                  {questionData[currentQuestionIndex]?.options[0]?.option}
                </span>
                <span>
                  <button
                    className={`small-btn report-green-btn mt-0 no-wrap ms-4`}
                  >
                    <Image src={tick} width={15} height={15} alt="tick" />
                    <span>Correct Answer</span>
                  </button>
                </span>
              </div>
            </div>
          )}
          {questionData[currentQuestionIndex]?.question_type_id === 5 &&
            !isHideSubjectiveKeyboard && (
              <div>
                <textarea
                  onChange={(e) =>
                    handleSubjectiveAnswer(
                      e?.target?.value,
                      questionData[currentQuestionIndex]?.options[0]
                        ?.question_id
                    )
                  }
                  value={isSubjectiveAnswered}
                  className="subjective_answer_input"
                  placeholder="Type Your Answer here"
                />
                <div>
                  <button
                    className="btn theme-btn p-3"
                    onClick={handleSaveSubjectiveAnswer}
                  >
                    Save Answer
                  </button>
                </div>
              </div>
            )}
          {isHideSubjectiveKeyboard && (
            <div style={{ padding: "0 14px" }}>
              <div>
                <span>{selectedOption || isSubjectiveAnswered}</span>{" "}
                <span>
                  <button
                    className={`small-btn ${
                      String(isSubjectiveAnswered)
                        .toLocaleLowerCase()
                        .replaceAll(".", "")
                        .replaceAll(",", "") ===
                      String(
                        questionData[currentQuestionIndex]?.options[0]?.option
                      )
                        .toLocaleLowerCase()
                        .replaceAll(".", "")
                        .replaceAll(",", "")
                        ? "report-green-btn"
                        : "report-theme-btn"
                    } mt-0 no-wrap ms-4`}
                  >
                    <Image src={tick} width={15} height={15} alt="tick" />
                    <span>Your Answer</span>
                  </button>
                </span>{" "}
              </div>
              <div className="my-4">
                <span>
                  {questionData[currentQuestionIndex]?.options[0]?.option}
                </span>
                <span>
                  <button
                    className={`small-btn report-green-btn mt-0 no-wrap ms-4`}
                  >
                    <Image src={tick} width={15} height={15} alt="tick" />
                    <span>Correct Answer</span>
                  </button>
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Question;
