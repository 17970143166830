import React from "react";
import Image from "../../../utils/image/Image";

const UserList = ({
  userName,
  userId,
  userDetails,
  handleOnClick,
  profileRef,
}) => {
  return (
    <div className="mobile_user_list" ref={profileRef}>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <p className="user_name">{userName}</p>
        <p className="user_id">{userId}</p>
      </div>
      {userDetails.length > 0 &&
        userDetails?.map((d, i) => {
          return (
            <div
              className={`cursor-pointer ${
                d.name === "School Round Slot Selection" && "d-lg-none"
              }`}
              onClick={() => handleOnClick(d)}
            >
              <div className="d-flex align-items-center">
                {d.icon && (
                  <div>
                    <Image className="user_list_icon" src={d.icon} />
                  </div>
                )}
                {d?.name && <div className="user_list_name">{d.name}</div>}
              </div>
              {i + 1 !== userDetails.length && (
                <div className="user_list_dashed_line"></div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default UserList;
