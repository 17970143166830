import React from "react";
import { useLocation } from "react-router-dom";
// import dummyIcon from "../../../assets/images/dummy-shortcut.png";
import dummyIcon from "../../../assets/images/dummy-image.png";
import Image from "../image/Image";

const Card = ({ image, title, className }) => {
  //
  // const location = useLocation();
  // if (location?.pathname === "/dashboard" && image) {
  //   // image = `http://103.149.154.53/Pratham-backend/storage/app/public/dev/icons/${image}`
  //   image = `${image}`;
  // } else {
  //   image = "";
  // }
  return (
    <>
      <div className={`class_box ${className ? className : ""}`}>
        <Image
          src={image ? image : dummyIcon}
          alt="liveClass"
          className={`${image ? "" : "theme-bg"}`}
          width={100}
          height={100}
        />
        <h4>{title}</h4>
      </div>
    </>
  );
};

export default Card;
