import DashboardArrowDown from "../../../assets/images/dashboard-arrow-down.png";
import Image from "../../utils/image/Image";

const ResourceViewItem = ({
  item,
  toggleItem,
  openItems,
  isOpen,
  level,
  resourceType,
}) => {
  return (
    <>
      {item.title !== null && (
        <div
          className={`document-item-child d-flex justify-content-between cursor-pointer ${
            isOpen && "active-document"
          }`}
          onClick={() => toggleItem(item, level, resourceType)}
        >
          <div>{item?.title}</div>
          <div className="d-flex justify-content-between align-items-center">
            {/* <div>{item?.count}</div> */}
            <div className="" style={{ marginLeft: "10px" }}>
              <Image
                src={DashboardArrowDown}
                alt="dashboard-arrow-down"
                style={{
                  visibility: level !== 2 && item.child ? "unset" : "hidden",
                }}
              />
            </div>
          </div>
        </div>
      )}
      {isOpen &&
        item?.child &&
        item?.child?.length > 0 &&
        item?.child?.map((f, index) => {
          return (
            <ResourceViewItem
              key={index}
              item={f}
              toggleItem={toggleItem}
              openItems={openItems}
              isOpen={openItems?.some((d) => d?.title?.includes(f?.title))}
              level={level + 1}
              resourceType={resourceType}
            />
          );
        })}
    </>
  );
};

export default ResourceViewItem;
