import {
  BREADCRUMB_DATA,
  EXAM_DATA,
  FINAL_EXAM_DATA,
  HEADER_NAME,
  PRATHAM_STUDENT_TOKEN,
  PRATHAM_TOKEN,
  STUDENT_INFO,
} from "../component/utils/constants";

class Auth {
  authenticated = false;

  login(data) {
    const token = data.token;
    localStorage.setItem(PRATHAM_STUDENT_TOKEN, token);
    const user = JSON.stringify(data?.userInfo);
    localStorage.setItem(STUDENT_INFO, user);

    if (token) {
      this.authenticated = true;
    } else {
      this.authenticated = false;
    }
  }

  token() {
    let token = localStorage.getItem(PRATHAM_STUDENT_TOKEN);
    return token;
  }

  user() {
    let user = localStorage.getItem(STUDENT_INFO);
    return JSON.parse(user);
  }

  logout() {
    // window.localStorage.clear();
    localStorage.removeItem("_prathamStudentToken");
    this.authenticated = false;
  }

  isUserAuthenticated() {
    const urlParams = new URLSearchParams(window?.location?.search);
    const studentInfo = localStorage?.getItem(STUDENT_INFO);
    const loginCounterStatus =
      JSON.parse(studentInfo)?.login_counter > 0 ? true : false;
    if (urlParams.has(PRATHAM_TOKEN)) {
      this.logout();
      // return false;
    } else {
      /*
       * When the user already logged in initialy login_Counter is zero. We need to force
       * logut of the user and to check only login_counter value is not equal to
       * zero. Because after this condition run so automates force logout of ther user.
       * When the login api hits login_counter value is incremented by 1.
       * */
      if (localStorage.getItem(PRATHAM_STUDENT_TOKEN) && loginCounterStatus) {
        return true;
      } else {
        return false;
      }
    }
  }

  //*****************************// Exam SECTION //******************************//

  setExamData(data) {
    const examData = JSON.stringify(data);
    localStorage.setItem(EXAM_DATA, examData);
  }

  getExamData() {
    let examdata = localStorage.getItem(EXAM_DATA);
    // return examdata?.length ? JSON?.parse(examdata) : [];
    return JSON?.parse(examdata);
  }

  setFinalExamData(data) {
    const examData = JSON.stringify(data);
    localStorage.setItem(FINAL_EXAM_DATA, examData);
  }

  //*****************************// BREAD CRUMB SECTION //******************************//
  setBreadCrumbData(data) {
    sessionStorage.setItem(BREADCRUMB_DATA, JSON.stringify(data));
  }

  setHeaderTitle(title) {
    sessionStorage.setItem(HEADER_NAME, JSON.stringify(title));
  }

  emptySession() {
    sessionStorage.clear();
  }

  getBreadSearch(data) {
    return data ? JSON.parse(atob(data)) : [];
  }

  getBreadHeadSearch(data) {
    return data ? data : "";
  }

  setBreadSearch(data, params) {
    if (data && params) {
      params?.set(btoa(JSON?.stringify(data)));
    }
  }

  breadData() {
    return {
      breadData: JSON.parse(sessionStorage.getItem("breadCrumbData")),
      headerName: JSON.parse(sessionStorage.getItem("headerName")),
    };
  }
}

export default new Auth();
