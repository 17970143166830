import React from "react";
import Image from "../image/Image";

const QuestionOptions = ({ optionsData, tick, cross }) => {
  return (
    <ul className="mcq-questions ps-0">
      {optionsData?.answers?.map((option, index) => {
        const alphabets = {
          1: "A",
          2: "B",
          3: "C",
          4: "D",
          5: "E",
          6: "F",
          7: "G",
        };
        return (
          <li className="list-unstyled d-flex position-relative">
            <div>
              <span className="qa_data d-inline-flex align-items-center">
                {![3, 4].includes(optionsData.question.question_type_id) && (
                  <span>({alphabets[index + 1]})</span>
                )}
                {[3, 4].includes(optionsData.question.question_type_id) && (
                  <span className="rounded_dot"></span>
                )}
                <span
                  dangerouslySetInnerHTML={{
                    __html: option?.option,
                  }}
                ></span>
                {
                  <span>
                    {option?.is_correct === 1 && (
                      <button className="small-btn report-green-btn mt-0 no-wrap ms-3">
                        <Image src={tick} width={15} height={15} alt="tick" />{" "}
                        <span>Correct Answer</span>
                      </button>
                    )}
                  </span>
                }
              </span>
              {[3, 4, 5].includes(optionsData?.question?.question_type_id) && (
                <br />
              )}
              {/** For Question Type 3,4,5 (Numerical,One Word,Subjective) => Wrong Answer */}
              {[3, 4, 5].includes(optionsData?.question?.question_type_id) &&
                [0, 1].includes(optionsData.status) &&
                optionsData.is_correct === 1 && (
                  <div
                    className="qa_data d-inline-flex align-items-center mt-5"
                    style={{ marginLeft: "5px" }}
                  >
                    <span className="rounded_dot"></span>{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: optionsData.input_answer,
                      }}
                    ></span>
                    <div>
                      <button
                        className={`small-btn report-theme-btn mt-0 no-wrap ms-4`}
                      >
                        <Image src={cross} width={15} height={15} alt="cross" />
                        <span>My Answer</span>
                      </button>
                    </div>
                  </div>
                )}
              {/** For Question Type 3,4,5 (Numerical,One Word,Subjective) => Right Answer */}
              {[3, 4, 5].includes(optionsData?.question?.question_type_id) &&
                [0, 1].includes(optionsData.status) &&
                optionsData.is_correct === 0 && (
                  <div
                    className="qa_data d-inline-flex align-items-center mt-5"
                    style={{ marginLeft: "5px" }}
                  >
                    <span className="rounded_dot"></span>{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: optionsData.input_answer,
                      }}
                    ></span>
                    <div>
                      <button
                        className={`small-btn green-btn mt-0 no-wrap ms-4`}
                      >
                        <Image src={tick} width={15} height={15} alt="tick" />
                        <span>My Answer</span>
                      </button>
                    </div>
                  </div>
                )}
            </div>
            {/** For Question Type 1 => correct answer */}
            {[1].includes(optionsData?.question?.question_type_id) &&
              [0, 1].includes(optionsData.status) &&
              Number(optionsData?.selected_option) === option?.id &&
              option?.is_correct === 1 && (
                <button className={`small-btn report-green-btn mt-0 ms-4`}>
                  <Image src={tick} width={15} height={15} alt="tick" />
                  <span>My Answer</span>
                </button>
              )}
            {/** For Question Type 1 => wrong answer */}
            {[1].includes(optionsData?.question?.question_type_id) &&
              [0, 1].includes(optionsData.status) &&
              Number(optionsData?.selected_option) === option?.id &&
              option?.is_correct === 0 && (
                <button
                  className={`small-btn report-theme-btn mt-0 no-wrap ms-4`}
                >
                  <Image src={cross} width={15} height={15} alt="cross" />
                  <span>My Answer</span>
                </button>
              )}
            {/* {optionsData?.selected_answers?.map((sel) => {
              return (
                <>
                  {sel?.options?.id === option?.id &&
                    option?.is_correct === 1 && (
                      <button className="small-btn report-green-btn mt-0 no-wrap ms-3">
                        <Image src={tick} width={15} height={15} alt="tick" />{" "}
                        <span>Correct Answer</span>
                      </button>
                    )}
                  {sel?.options?.id === option?.id &&
                    option?.is_correct === 0 && (
                      <button className="small-btn report-theme-btn mt-0 no-wrap ms-3">
                        <Image src={cross} width={15} height={15} alt="cross" />{" "}
                        <span>My Answer</span>
                      </button>
                    )}
                </>
              );
            })} */}
            {/* {option?.is_correct === 1 && (
              <button
                className="small-btn report-green-btn mt-0 no-wrap ms-3"
                style={{
                  position:
                    [3, 4, 5].includes(
                      Number(optionsData?.question?.question_type_id)
                    ) && "absolute",
                  top:
                    [3, 4, 5].includes(
                      Number(optionsData?.question?.question_type_id)
                    ) && "18px",
                  left:
                    [3, 4, 5].includes(
                      Number(optionsData?.question?.question_type_id)
                    ) && "60px",
                }}
              >
                <Image src={tick} width={15} height={15} alt="tick" />{" "}
                <span>Correct Answer</span>
              </button>
            )} */}
          </li>
        );
      })}
    </ul>
  );
};

export default QuestionOptions;
