export const useRedirection = () => {
  const redirectionMapping = {
    276: process.env.REACT_APP_OLYMPIAD_BASE_URL,
    278: process.env.REACT_APP_PRIMARY_OLYMPIAD_BASE_URL,
  };
  const getRedirection =(orgId) => {
    return redirectionMapping[Number(orgId)] || process.env.REACT_APP_PRATHAM_BASE_URL
  }
  return {getRedirection}
};
