import React from "react";
import Table from "../../../utils/table/Table";

const FormCategoryTable = ({ categoryTableForm }) => {
  return (
    <div className="uni-data mb-5">
      <div className=" white-box p-0">
        <Table
          className="border-0 no-stripe"
          heading={[
            {
              value: "S.No",
              id: 1,
              className: "title-data w-5",
            },
            {
              value: "University",
              id: 2,
              className: "w-35 whiteSpaceNormal",
            },
            {
              value: "Form Status",
              id: 3,
              className: "w-15 whiteSpaceNormal",
            },
          ]}
          rows={categoryTableForm}
        />
      </div>
    </div>
  );
};

export default FormCategoryTable;
