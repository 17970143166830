// React imports
import React, { useContext, useState, useEffect } from "react";
import { useForm } from "@kaydhiman/react-hook-useform";
import { useNavigate } from "react-router-dom";

// Context and hooks
import { AppContext } from "../../shared/AppContext";
import {
  useOtherBatchesSchedule,
  useZoomJoinUrl,
} from "../../../hooks/hookList";

// Date handling
import moment from "moment";
import DatePicker from "../../utils/datePicker/DatePicker";

// UI components
import Button from "../../utils/forms/Button";
import Table from "../../utils/table/Table";

// Asset import
import nextIcon from "../../../assets/images/nextArrow.svg";

// Authentication
import Auth from "../../../auth/Auth";

// Constants
import { CONSTANT } from "../../utils/constants";
import SelectInput from "../../utils/forms/SelectInput";
import Image from "../../utils/image/Image";

const OtherClasses = () => {
  const { values, setInitialValues } = useForm({
    validations: {},
  });
  const navigate = useNavigate();
  const { setSpinner, setToaster, userData } = useContext(AppContext);
  const [classSchedule, setClassSchedule] = useState([]);
  const [calanderDate, setCalanderDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [accessModal, setaccessModal] = useState(false);
  const [uniqueBatches, setUniqueBatches] = useState([]);
  const [batches, setBatches] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectBatch, setSelectBatch] = useState({
    name: "Select",
    id: "Select",
  });
  const [selectSubject, setSelectSubject] = useState({
    name: "Select",
    id: "Select",
  });
  const { getData: getOtherBatchesSchedule } = useOtherBatchesSchedule(
    setSpinner,
    setToaster
  );
  const { getData: getZoomJoinURL } = useZoomJoinUrl(setSpinner, setToaster);
  const studentBatchType =
    userData && userData?.batches !== undefined
      ? userData?.batches[userData?.batches?.length - 1]?.batch_type
      : null;

  const accessModalOpen = (data) => {
    setaccessModal(true);
    setInitialValues({ ...values, name: data.meeting_name, id: data?.id });
  };

  const accessModalClose = () => {
    setaccessModal(false);
    setInitialValues({});
  };

  const getPreviousDate = () => {
    const previousDate = new Date(selectedDate);
    previousDate.setDate(previousDate.getDate() - 1);
    const previousDateString = previousDate.toISOString().split("T")[0];
    setSelectedDate(previousDateString);
    setCalanderDate(previousDateString);
    setSelectBatch({ name: "", id: "" });
    setSelectSubject({ name: "", id: "" });
  };

  const getNextDate = () => {
    const nextDate = new Date(selectedDate);
    nextDate.setDate(nextDate.getDate() + 1);
    const nextDateString = nextDate.toISOString().split("T")[0];
    setSelectedDate(nextDateString);
    setCalanderDate(nextDateString);
    setSelectBatch({ name: "", id: "" });
    setSelectSubject({ name: "", id: "" });
  };

  const formatBatchElement = (elem, index) => {
    const subject = elem?.subject;
    const topic = elem?.topic;
    return [
      { id: 1, value: index + 1, className: "w-5" },
      { id: 2, value: elem?.batch_name, className: "w-20" },
      {
        id: 3,
        value: (
          <>
            {formatTime(moment(elem?.start_time, "HH:mm")).slice(0, 5)}-
            {formatTime(moment(elem?.end_time, "HH:mm")).slice(0, 5)}
          </>
        ),
        className: "w-5",
      },
      {
        id: 4,
        value: <>{`${subject}-${topic}`}</>,
      },
      {
        id: 5,
        value: (
          <>{Number(elem.class_type) === 1 ? elem.room : renderButton(elem)}</>
        ),
      },
    ];
  };

  const otherBatchesScheduleCallback = (otherBatchSchedule) => {
    const uniqueBatchSchedule = otherBatchSchedule.data.filter(
      (elem, index, self) => index === self.findIndex((t) => t.id === elem.id)
    );
    const otherBatchData = uniqueBatchSchedule?.map(formatBatchElement);
    const otherBatch = uniqueBatchSchedule
      ?.filter(
        (elem, index, self) =>
          index === self.findIndex((t) => t.batch_name === elem.batch_name)
      )
      .map((elem) => ({
        name: elem?.batch_name,
        id: elem?.id,
      }));
    const subjectsName = uniqueBatchSchedule
      ?.filter(
        (elem, index, self) =>
          index === self.findIndex((t) => t.subject === elem.subject)
      )
      .map((otherBatch, index) => ({
        name: otherBatch?.subject,
        id: index + 1,
      }));

    setSubjects(subjectsName);
    setUniqueBatches(uniqueBatchSchedule);
    setBatches(otherBatch);
    setClassSchedule(otherBatchData);
  };

  const handleZoomJoinLiveClass = async (liveClass) => {
    const params = {
      first_name: Auth?.user()?.name,
      last_name: ".",
      email: Auth?.user()?.email,
      meeting_id: liveClass?.meeting_id,
      live_class_id: liveClass?.id,
      meeting_type_id: liveClass?.meeting_type_icd,
    };
    const threadIdObject =
      liveClass?.thread_id !== undefined
        ? JSON.parse(liveClass?.thread_id)
        : liveClass?.elem.thread_id
        ? JSON.parse(liveClass?.elem.thread_id)
        : 0;
    const threadId = threadIdObject.threadId;
    await getZoomJoinURL(params, (data) => {
      navigate(`/courses/live_class/${liveClass?.id}/chat/${threadId}`, {
        state: false,
      });
      if (data?.join_url) {
        localStorage.setItem(
          CONSTANT.LOCAL_STORAGE.ZOOM_MEET_URL,
          data?.join_url
        );
        setToaster({
          show: true,
          type: CONSTANT.TYPE.SUCCESS,
          content: data.message,
        });
        if (
          liveClass?.thread_id &&
          Object.keys(JSON.parse(liveClass?.thread_id)).includes(CONSTANT.ERROR)
        ) {
          setToaster({
            show: true,
            type: CONSTANT.TYPE.DANGER,
            content: JSON.parse(liveClass?.thread_id)?.error,
          });
        } else {
          navigate(`/courses/live_class/${liveClass?.id}/chat/${threadId}`, {
            state: false,
          });
        }
        setSpinner(false);
      }
    });
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    return formattedTime;
  };

  const onChangeDateHandler = (e) => {
    setSelectedDate(e);
    setCalanderDate(e);
  };

  const renderButton = (liveClassData) => {
    const currentDate = moment();
    const startLiveClassDate = moment(liveClassData.date);
    const startLiveClassTime = moment(liveClassData.start_time, "HH:mm:ss");
    const endLiveClassTime = moment(liveClassData.end_time, "HH:mm:ss");
    if (startLiveClassDate.isSame(currentDate, "day")) {
      if (currentDate.isBetween(startLiveClassTime, endLiveClassTime)) {
        return (
          <div className="btn-group">
            <Button
              className="small-btn green-btn"
              title="Join Now"
              onClick={() => {
                handleZoomJoinLiveClass(liveClassData);
              }}
            />
          </div>
        );
      } else if (currentDate.isAfter(endLiveClassTime)) {
        return <div>Completed</div>;
      } else if (
        currentDate.isBefore(startLiveClassTime) &&
        currentDate.isBefore(endLiveClassTime)
      ) {
        return <div>Scheduled</div>;
      }
    } else if (startLiveClassDate.isBefore(currentDate)) {
      return <div>Completed</div>;
    } else if (startLiveClassDate.isAfter(currentDate, "day")) {
      return <div>Scheduled</div>;
    }
  };

  useEffect(() => {
    if (calanderDate) {
      const params = {
        date: moment(calanderDate).format("YYYY-MM-DD"),
        page: 1,
        size: 10,
        type: "all-classes",
      };
      getOtherBatchesSchedule(params, otherBatchesScheduleCallback);
    }
    //eslint-disable-next-line
  }, [calanderDate]);

  useEffect(() => {
    if (selectBatch.name !== "" || selectSubject.name !== "") {
      let selectedBatch = uniqueBatches;
      if (selectBatch?.name === "Select") {
        setSelectBatch({ name: "", id: "" });
      }
      if (selectSubject?.name === "Select") {
        setSelectSubject({ name: "", id: "" });
      }

      if (selectBatch.name !== "" && calanderDate) {
        selectedBatch = selectedBatch.filter(
          (d) => d.batch_name === selectBatch.name
        );
      }

      if (selectSubject.name !== "") {
        selectedBatch = selectedBatch.filter(
          (d) => d.subject === selectSubject.name
        );
      }

      const filterBatches = selectedBatch.map(formatBatchElement);
      setClassSchedule(filterBatches);
    } else {
      setClassSchedule(uniqueBatches.map(formatBatchElement));
    }
  }, [selectSubject, selectBatch, calanderDate]);

  return (
    <div className="white-box schedule-dash">
      <div className="">
        <div className="row">
          <div className="schedule-header_2 col-lg-6">
            <div className="row align-items-center">
              <div className="date_picker_container col-8 col-lg-7">
                <div className="row">
                  <div className="date_picker_from col-12">
                    <DatePicker
                      label="Select Date"
                      id="date-pick"
                      date={selectedDate}
                      placeholder="Select Date"
                      onChange={(e) => {
                        onChangeDateHandler(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="next-prev col-4">
                <span className="prev-icon prev-date">
                  <Image
                    src={nextIcon}
                    className="pointer"
                    onClick={() => {
                      getPreviousDate();
                    }}
                    alt={CONSTANT.ALT.NEXT_ICON}
                  />
                </span>
                <span className="prev-icon ps-3 next-date">
                  <Image
                    src={nextIcon}
                     className="pointer"
                    onClick={() => {
                      getNextDate();
                    }}
                    alt={CONSTANT.ALT.NEXT_ICON}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 adminForm mb-5">
            <div className="row align-items-center">
              <div className="col-md-5">
                <SelectInput
                  label="Batch"
                  id="batch_id"
                  name="batch"
                  data={batches}
                  values={selectBatch}
                  title="Select"
                  onClickHandler={(name, id) => setSelectBatch({ name, id })}
                />
              </div>
              <div className="col-md-5">
                <SelectInput
                  label="Subject"
                  id="subject_id"
                  name="subject"
                  data={subjects}
                  values={selectSubject}
                  title="Select"
                  onClickHandler={(name, id) => {
                    setSelectSubject({ id: name, name });
                  }}
                />
              </div>
              <div className="col-md-2">
                <Button
                  title="Clear"
                  className="btn small-btn"
                  onClick={() => {
                    setSelectBatch({ name: "", id: "" });
                    setSelectSubject({ name: "", id: "" });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Table
        heading={[
          { id: 1, value: "S.No", className: "w-5" },
          {
            id: 2,
            value: "Batch",
            className: "w-20",
          },
          {
            id: 3,
            value: "Start & End Time",
            className: "w-5",
          },
          { id: 4, value: "Subject & Topic", className: "w-5" },
          {
            id: 5,
            value: `${
              (Number(studentBatchType) === 1 && "Room") ||
              (Number(studentBatchType) === 2 && "Status") ||
              (Number(studentBatchType) === 0 && "Status")
            }`,
            className: "w-10",
          },
        ]}
        rows={classSchedule}
      />
    </div>
  );
};

export default OtherClasses;
