import React from "react";
import { Modal } from "react-bootstrap";

import crossIcon from "../../../assets/images/cross.svg";
import Image from "../image/Image";

const CommonModal = ({
  show,
  close,
  heading,
  body,
  className,
  crossShow = true,
  modalBodyClassName,
}) => {
  return (
    <Modal show={show} centered className={`${className ? className : ""} `}>
      <Modal.Header>
        <Modal.Title>{heading}</Modal.Title>
        {crossShow && (
          <span className="close-btn float-end " role="button">
            <Image src={crossIcon} alt="cross" onClick={close} />
          </span>
        )}
      </Modal.Header>
      <Modal.Body className={modalBodyClassName}>{body}</Modal.Body>
    </Modal>
  );
};

export default CommonModal;
