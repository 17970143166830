import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import clock from "../../../assets/images/timer.png";
import backBtn from "../../../assets/images/left-arrow.png";
import Image from "../../utils/image/Image";

const ChatSettings = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <>
      <div className="live-class-header ">
        <div className="chat_box">
          <div className="row chat_header align-items-center">
            <div className="col-12 left_cont d-flex align-items-center chat_head_gap">
              <span className=" back-btn">
                <Image
                  src={backBtn}
                  alt="back btn"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </span>
              <h2 className="ht mb-0">Settings</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="live-class-body">
        <div className="chat_card_container mt-2t">
          <div
            className="chat_card chat_boxS chat_Pbt brd_white"
            onClick={() => {
              navigate(`/courses/live_class/${id}/chat_history`);
            }}
          >
            <div className="card_cont cursor">
              <div className="row">
                <div className="col-12 d-flex align-items-center ">
                  <div className="card_img">
                    <Image
                      src={clock}
                      alt="notification"
                      className="small_iconSett"
                    />
                  </div>
                  <div className="card_txt">
                    <h4 className="ulive mb-0">Chat History</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatSettings;