import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import Button from "../../utils/forms/Button";

// Function to set localStorage items
const setLocalStorageItems = (examTemplateId, subjectName) => {
  localStorage.setItem("tempId", examTemplateId);
  localStorage.setItem(
    "testName",
    JSON.stringify({
      subject: subjectName,
    })
  );
};

const UploadOMRButton = ({ onChange, onClick, location }) => {
  return (
    <div
      className={`${
        !location?.pathname?.includes("folder") &&
        `my-2 d-flex justify-content-center`
      }`}
    >
      <input
        id="file_upload"
        type="file"
        className="d-none"
        onChange={onChange}
      />
      <Button
        type="file"
        className="btn theme-btn dark-blue small-btn m-0"
        title="Upload OMR"
        onClick={onClick}
      />
    </div>
  );
};

// Function to create NavLink component
const createNavLink = (
  to,
  onClick,
  children,
  className,
  examTemplateUIId,
  location,
  totalQuestions,
  examName,
  id,
  questionLimit
) => {
  return (
    <div
      className={`${
        !location?.pathname?.includes("folder") &&
        `d-flex justify-content-center`
      }`}
    >
      <NavLink
        className={`btn small-btn theme-btn m-0 ${className}`}
        style={{ padding: "5px 10px" }}
        to={to}
        onClick={onClick}
        state={{
          totalQuestions,
          examName,
          id,
          questionLimit,
          examTemplateUIId,
        }}
      >
        {children}
      </NavLink>
    </div>
  );
};

const renderScheduledButton = (endDate) => {
  const endDateExam = new Date(endDate);
  return (
    endDateExam > new Date() && (
      <div className="btn-group">
        <div className="greyLink">Scheduled</div>
      </div>
    )
  );
};

const renderExpiredButton = () => {
  return (
    <div className="btn-group text-center d-flex justify-content-center">
      <div className="greyLink">Expired</div>
    </div>
  );
};

const renderViewReportButton = (id, location) => {
  return createNavLink(
    `/test/${id}/exams/resultnew`,
    null,
    "View Report",
    "border-btn",
    location
  );
};

const renderResultAwaitedButton = () => (
  <div className="btn-group w-100 d-flex justify-content-center">
    <div className="greyLink">Result Awaited</div>
  </div>
);

const renderRetakeButton = (id, examTemplateId, subjectName, location) => {
  return createNavLink(
    `/test/examinstructions/${id}`,
    () => setLocalStorageItems(examTemplateId, subjectName),
    "Retake",
    "border-btn",
    location
  );
};

const renderResumeTestButton = (
  id,
  examTemplateId,
  subjectName,
  examTemplateUIId,
  location
) => {
  return examTemplateUIId === 6
    ? createNavLink(
        `/test/xat/examinstructions/${id}`,
        () => setLocalStorageItems(examTemplateId, subjectName),
        "Resume Test",
        "border-btn",
        examTemplateUIId,
        location
      )
    : createNavLink(
        `/test/examinstructions/${id}`,
        () => setLocalStorageItems(examTemplateId, subjectName),
        "Resume Test",
        "border-btn",
        examTemplateUIId,
        location
      );
};

const renderTakeExamButton = (
  id,
  examTemplateId,
  subjectName,
  categoryExamName,
  onChange,
  onClick,
  examTemplateUIId,
  location,
  totalQuestions,
  examName,
  questionLimit
) => {
  console.log("template", examTemplateUIId);
  return (
    <div
      className={`${
        location?.pathname?.includes("folder")
          ? `d-flex align-items-center gap-4`
          : ""
      }`}
    >
      {categoryExamName?.toLowerCase()?.replace(/\s/g, "") !==
        "cuetomrbasedmock" &&
        examTemplateUIId === 6 &&
        createNavLink(
          `/test/xat/examinstructions/${id}`,
          () => setLocalStorageItems(examTemplateId, subjectName),
          "Take Exam",
          "green-btn",
          examTemplateUIId,
          location,
          totalQuestions
        )}
      {categoryExamName?.toLowerCase()?.replace(/\s/g, "") !==
        "cuetomrbasedmock" &&
        examTemplateUIId !== 6 &&
        createNavLink(
          `/test/examinstructions/${id}`,
          () => setLocalStorageItems(examTemplateId, subjectName),
          "Take Exam",
          "green-btn",
          examTemplateUIId,
          location,
          totalQuestions
        )}
      {/* {categoryExamName?.toLowerCase()?.replace(/\s/g, "") !==
        "cuetomrbasedmock" &&
        categoryExamName?.toLowerCase()?.includes("omr") && (
          <UploadOMRButton
            onChange={onChange}
            onClick={onClick}
            location={location}
          />
        )} */}
      {categoryExamName?.toLowerCase()?.includes("omr") && (
        <div className="my-3">
          {createNavLink(
            `/test/omr/self-check/${id}`,
            null,
            "Self Check OMR",
            "btn theme-btn dark-blue small-btn m-0",
            examTemplateUIId,
            location,
            totalQuestions,
            examName,
            id,
            questionLimit
          )}
        </div>
      )}
    </div>
  );
};

const renderViewReportRetakeButton = (
  id,
  examTemplateId,
  subjectName,
  examRetake,
  categoryExamRetake,
  location
) => {
  return (
    <div
      className={`d-flex ${
        examRetake < categoryExamRetake
          ? "justify-content-between"
          : "justify-content-center"
      } `}
    >
      {renderViewReportButton(id, location)}
      {examRetake < categoryExamRetake && (
        <div className="ms-4">
          {
            (createNavLink(`/test/examinstructions/${id}`),
            () => setLocalStorageItems(examTemplateId, subjectName),
            "ReTake Exam",
            "border-btn",
            location)
          }
        </div>
      )}
    </div>
  );
};

const MockTestButton = ({
  status,
  id,
  examTemplateId,
  startDate,
  endDate,
  resultDate,
  subjectName,
  examRetake,
  categoryExamRetake,
  categoryExamName,
  onClickOMR,
  onChangeOMR,
  totalQuestions,
  examName,
  questionLimit,
  examStatus,
  examTemplateUIId,
}) => {
  const location = useLocation();
  switch (status) {
    case 0:
      return renderScheduledButton(endDate);
    case 1:
      if (Number(examRetake) === 0) {
        return renderExpiredButton();
      } else if (
        Number(examRetake) > 0 &&
        new Date(endDate) < new Date() &&
        examStatus !== 2
      ) {
        return renderViewReportButton(id, location);
      } else {
        return renderResultAwaitedButton();
      }

    case 2:
      if (new Date(resultDate) <= new Date() && examStatus !== 2) {
        return renderViewReportButton(id, location);
      } else {
        return renderResultAwaitedButton();
      }
    case 3:
      if (examRetake === 0) {
        return renderTakeExamButton(
          id,
          examTemplateId,
          subjectName,
          categoryExamName,
          onChangeOMR,
          onClickOMR,
          examTemplateUIId,
          location,
          totalQuestions,
          examName,
          questionLimit
        );
      }

    case 4:
      if (new Date(resultDate) <= new Date() && examStatus !== 2) {
        return renderViewReportButton(id, location);
      } else if (
        examRetake > 1 &&
        new Date(resultDate) <= new Date() &&
        examRetake <= categoryExamRetake &&
        examStatus !== 2
      ) {
        return renderRetakeButton(id, examTemplateId, subjectName, location);
      } else {
        return renderResultAwaitedButton();
      }
    case 5:
      if (new Date(resultDate) <= new Date() && examStatus !== 2) {
        return renderViewReportRetakeButton(
          id,
          examTemplateId,
          subjectName,
          examRetake,
          categoryExamRetake,
          location
        );
      } else {
        return renderResultAwaitedButton();
      }

    case 6:
      if (new Date(endDate) > new Date()) {
        return renderResumeTestButton(
          id,
          examTemplateId,
          subjectName,
          examTemplateUIId,
          location
        );
      }

    case 7:
      if (
        categoryExamName.includes("omr") &&
        new Date(resultDate) < new Date() &&
        examStatus === 2
      ) {
        return renderResultAwaitedButton();
      } else {
        return renderViewReportButton(id, location);
      }
    default:
      return null;
  }
};

export default MockTestButton;
