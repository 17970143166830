import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useForm, patterns } from "@kaydhiman/react-hook-useform";

import Button from "../utils/forms/Button";
import OnboardingLayout from "./OnboardingLayout";
import InputField from "../utils/forms/InputField";
import SelectInput from "../utils/forms/SelectInput";

// import lock from "../../assets/images/lock.svg";
import hidePass from "../../assets/images/pass_view-svg.png";
import showPass from "../../assets/images/eye-svg.png";
import phone from "../../assets/images/phone.svg";
import email from "../../assets/images/email.svg";
import userIcon from "../../assets/images/user.svg";
import userImg from "../../assets/images/user-image.jpg";
import cameraImg from "../../assets/images/ic_camera.png";
import signupImg from "../../assets/images/signupImg.png";
import { userService } from "../../_services/User.services";
// import Auth from "../../auth/Auth";
import { AppContext } from "../shared/AppContext";
import Image from "../utils/image/Image";
// import crossIcon from "../../assets/images/red_cross.svg";

export default function SignUp() {
  const [showHidePassword, setShowHidePassword] = useState(false);
  const { values, bindField, setInitialValues } = useForm({
    validations: {
      name: {
        required: {},
      },
      password: {
        required: {},
        noWhiteSpace: {},
        minLength: {
          value: 6,
          message: "Password should be minimum 6 charactors long.",
        },
      },

      email: {
        required: {},
        noWhiteSpace: {},
        pattern: {
          value: patterns.email,
          message: "Invalid email address.",
        },
      },
      phone_number: {
        required: {},
      },

      class_id: {
        required: {},
      },

      stream_id: {
        required: {},
      },

      city: {
        required: {},
      },
    },
  });
  const route = useNavigate();
  const { setSpinner, setToaster } = useContext(AppContext);

  const [showImage, setShowImaage] = useState("");
  const [classData, setClassData] = useState([]);
  const [streamData, setStreamData] = useState([]);

  const handleIamge = (e) => {
    const getImage = URL.createObjectURL(e.target.files[0]);
    setShowImaage(getImage);
  };

  //--- Get Class List ---//

  const getClassList = async () => {
    setSpinner(true);

    try {
      const response = await userService.getClassList({});

      setSpinner(false);

      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setClassData(data?.data);
      }
      if (response.status === 401) {
        // Auth.logout();
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();

        setToaster({
          show: true,
          type: "danger",
          content: data.message,
        });
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  //--- Getting Stream List ---//

  const streamListing = async (id) => {
    const response = await userService.getStreamList({ class_id: id });
    try {
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();

        setStreamData(data?.data);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();

        setToaster({
          show: true,
          type: "danger",
          content: data.message,
        });
      }
    } catch {
      setSpinner(false);
    }
  };

  const signUpHandler = async (e) => {
    e.preventDefault();

    setSpinner(true);
    try {
      const response = await userService.signUp(values);

      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();

        route("/");

        setToaster({
          show: true,
          type: "success",
          content: data.message,
        });

        setSpinner(false);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();

        if (data.errors) {
          let error = Object.keys(data.errors)?.reverse();

          for (let i = 0; i <= error.length; i++) {
            setToaster({
              show: true,
              type: "danger",
              content: data.errors[error[i]][0],
            });
          }
        } else {
          setToaster({
            show: true,
            type: "danger",
            content: data.message,
          });
        }
        setSpinner(false);
      }
    } catch (error) {
      setSpinner(false);
    }
    setSpinner(false);
  };

  useEffect(() => {
    getClassList();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (values?.class_id) {
      streamListing(values?.class_id);
    }
    //eslint-disable-next-line
  }, [values?.class_id]);

  return (
    <>
      <OnboardingLayout
        Form={
          <>
            <div className="dash-heading">
              <h1>Sign Up</h1>
            </div>
            <form onSubmit={signUpHandler}>
              <div className="profile-box">
                <label htmlFor="filename" className="pointer">
                  <Image
                    src={cameraImg}
                    alt="Camera"
                    className="position-absolute camera-img"
                  />
                </label>
                <Image
                  src={!showImage ? userImg : showImage}
                  alt="User"
                  className="img-fluid current-img user-img"
                />

                <input
                  type="file"
                  id="filename"
                  name="filename"
                  className="d-none"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => handleIamge(e)}
                />
              </div>
              <div className="scrol-form">
                <InputField
                  label="Name"
                  name="name"
                  bindField={bindField}
                  value={values?.name || ""}
                  id="name"
                  placeholder="Enter Name"
                  type="text"
                  iconData={{
                    className: "lock_ico",
                    isLock: true,
                    src: userIcon,
                    alt: "user Icon",
                  }}
                />
                <InputField
                  label="Email Address"
                  id="email"
                  value={values?.email || ""}
                  name="email"
                  bindField={bindField}
                  placeholder="Enter Email Address"
                  type="email"
                  iconData={{
                    className: "lock_ico",
                    isLock: true,
                    src: email,
                    alt: "Lock Icon",
                  }}
                />
                <InputField
                  label="Password"
                  id="password"
                  name="password"
                  value={values?.password || ""}
                  bindField={bindField}
                  placeholder="Enter Password"
                  type={showHidePassword ? "text" : "password"}
                  iconData={{
                    className: "lock_ico pointer",
                    isLock: true,
                    src: showHidePassword ? showPass : hidePass,
                    alt: "user Icon",
                  }}
                  isPassword
                  showHidePassword={showHidePassword}
                  setShowHidePassword={setShowHidePassword}
                />

                <InputField
                  label="Phone Number"
                  id="phone_number"
                  name="phone_number"
                  value={values?.phone_number || ""}
                  bindField={bindField}
                  placeholder="Enter Phone Number"
                  type="tel"
                  iconData={{
                    className: "lock_ico",
                    isLock: true,
                    src: phone,
                    alt: "phone Icon",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    } else if (event.target.value.length === 10) {
                      event.preventDefault();
                    }
                  }}
                />

                <SelectInput
                  label="Class"
                  id="class"
                  name="class_id"
                  data={classData}
                  setInitialValues={setInitialValues}
                  values={values}
                />

                <SelectInput
                  label="Stream"
                  id="stream"
                  name="stream_id"
                  data={streamData?.map((sub) => sub.stream).flat()}
                  setInitialValues={setInitialValues}
                  values={values}
                  disable={values?.class_id ? false : true}
                />

                <InputField
                  label="City"
                  id="city"
                  name="city"
                  value={values?.city || ""}
                  bindField={bindField}
                  placeholder="Enter City"
                  type="text"
                  // iconData={{
                  //   className: "lock_ico",
                  //   isLock: true,
                  //   // src: phone,
                  //   alt: "phone Icon",
                  // }}
                />

                <Button
                  className="loginBtn"
                  // to="dashboard"
                  id="logBtn"
                  title="Sign Up"
                  // disable={!isValid()}
                />
                <p className="text-center pt-4 footer-context">
                  Already have an account?{" "}
                  <Link to="/" className="themeLink">
                    Sign In
                  </Link>
                </p>
              </div>
            </form>
          </>
        }
        image={signupImg}
      />
    </>
  );
}
