import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { CONSTANT } from "../component/utils/constants";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MIS = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };
  const changePage = (offSet) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offSet);
  };

  const changePageBack = () => {
    changePage(-1);
  };

  const changePageNext = () => {
    changePage(+1);
  };
  return (
    <div
      className="py-3"
      style={{ padding: "0px", background: "white", borderRadius: "12px" }}
    >
      <div className="doc-viewer">
        <div className="pdf-view" onContextMenu={(e) => e.preventDefault()}>
          <div className="pdf-header header-btns ">
            <button
              className="btn theme-btn small-btn prev-btn"
              disabled={pageNumber === 1}
              {...(pageNumber < 2 && {
                onClick: () => {
                  changePageBack();
                },
              })}
              onClick={changePageBack}
            >
              {CONSTANT.BUTTON.PREVIOUS}
            </button>
            <p>
              <span className="doc-heading">{CONSTANT.PAGE}</span>
              <span className="doc-numb">{pageNumber} </span>
              <span className="doc-heading">{CONSTANT.OF.toLowerCase()}</span>
              <span className="doc-numb"> {numPages} </span>
            </p>
            <button
              className="btn theme-btn small-btn next-btn "
              disabled={pageNumber === numPages}
              {...(numPages !== pageNumber && {
                onClick: () => {
                  changePageNext();
                },
              })}
            >
              {CONSTANT.BUTTON.NEXT}
            </button>
          </div>
          <div className="d-flex justify-content-center">
            <div className="w-75" style={{ overflow: "auto" }}>
              <Document
                file={"/mis.pdf"}
                // file={{ url: "https://prathamportal.s3.ap-south-1.amazonaws.com/dev/documents/20230609014531Session%20Flow.pdf" }}
                onLoadSuccess={onDocumentLoadSuccess}
                renderMode="canvas"
              >
                <Page height={100} width={1000} pageNumber={pageNumber} />
              </Document>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MIS;
