import React, { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import Pagination from "react-js-pagination";

import email from "../../assets/images/email.png";
import { userService } from "../../_services/User.services";
import { AppContext } from "../shared/AppContext";
import { CONSTANT } from "../utils/constants";
import Image from "../utils/image/Image";
import {
  convertIsoToDDMMYYYY,
  convertIsoToDDMMYYYYTime,
} from "../utils/dateTime";

const Notification = (props) => {
  const { setSpinner, setToaster } = useContext(AppContext);
  const [notificationData, setNotificationData] = useState([]);
  const location = useLocation();

  const [total, setTotal] = useState();
  const [activePage, setActivePage] = useState(1);

  // ************************Get Notification Data******************************
  const getNotificationList = async (pageNo) => {
    setSpinner(true);

    try {
      let currentPage = pageNo === undefined ? 1 : pageNo;
      const response = await userService.getNotifications({
        page: currentPage,
        size: 10,
        view_all: true,
      });
      if (response?.status >= 200 && response?.status <= 299) {
        const data = await response.json();

        setNotificationData(data?.data?.data);
        setSpinner(false);
        setTotal(data?.data?.total);
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }
      if (response?.status >= 400 && response?.status <= 499) {
        const data = await response.json();
        setToaster({ show: true, type: "danger", content: data.message });
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  const onPageChange = (e, type) => {
    let pageNo = e;
    setActivePage(pageNo);
    getNotificationList(pageNo);
  };

  useEffect(() => {
    getNotificationList();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="notification-dash">
      <div className="white-box">
        <div className="notification-sec">
          <div className="notification-drop">
            {/* notification dropdown header  */}
            <div className="notification-drop-header d-flex align-items-center justify-content-between dash-heading">
              <h1 className="mb-0 ">Announcements</h1>
            </div>
            <div className="notification-drop-body">
              <ul>
                {notificationData?.length > 0 &&
                  notificationData?.map((msg, index) => {
                    const formattedDate = convertIsoToDDMMYYYY(msg.created_at);
                    const currentDate = convertIsoToDDMMYYYY(new Date());
                    const formattedDateTime = convertIsoToDDMMYYYYTime(
                      msg.created_at
                    );
                    return (
                      // <li className="unread-notification  notification-cont">
                      <li
                        className={`${
                          `notification-${index + 1}` === location?.state?.id
                            ? "un"
                            : ""
                        }read-notification  notification-cont`}
                      >
                        <div className="notifications">
                          <ul>
                            {/* <li className="notifications-icon">
                              <Image src={email} alt="notification icon" />
                            </li> */}
                            <li className="notification-list">
                              <div className="notification-list-header">
                                <ul className="align-items-center">
                                  <li>
                                    <span className="post-time">
                                      Posted On: &nbsp;{" "}
                                      <b>{formattedDateTime}</b>
                                    </span>
                                  </li>
                                  <li>
                                    <span className="post-author">
                                      Posted By: &nbsp;{" "}
                                      <b> {msg?.user_data?.name}</b>
                                    </span>
                                  </li>
                                  {/* <li>
                                    <span className="post-role">
                                      Role: &nbsp;{" "}
                                      <b> {msg?.posted_by_user_type}</b>
                                    </span>
                                  </li> */}
                                  {formattedDate === currentDate && (
                                    <li className="theme-btn w-auto rounded-pill extra_padding">
                                      <h4 className="text-white">New</h4>
                                    </li>
                                  )}
                                </ul>
                              </div>
                              {/* <p className='dark-gray'>{msg?.message}</p> */}
                              <p
                                className="dark-gray"
                                dangerouslySetInnerHTML={{
                                  __html: msg?.message,
                                }}
                              ></p>
                            </li>
                          </ul>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {total >= 10 && (
        <Pagination
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={total}
          pageRangeDisplayed={5}
          onChange={(e) => onPageChange(e, 5)}
          hideFirstLastPages={true}
        />
      )}
    </div>
  );
};

export default Notification;
