import React, { useContext, useState } from "react";

import { Dropdown } from "react-bootstrap";

import axios from "axios";
// import * as tus from 'tus-js-client';

import CarouselComp from "../../utils/carousel/CarouselComp";
import { useNavigate } from "react-router-dom";
import RightSidebar from "../portalLayout/RightSidebar";
import { useEffect } from "react";
import CommonModal from "../../utils/modal/CommonModal";
import { useForm } from "@kaydhiman/react-hook-useform";
import { AppContext } from "../../shared/AppContext";
import { userService } from "../../../_services/User.services";
import Auth from "../../../auth/Auth";
import userImg from "../../../assets/images/user-image.jpg";
import moment from "moment";
import { CONSTANT } from "../../utils/constants";
import { useBreadcrumb } from "../../shared/breadcrumb-context";
import HomeShortcutCard from "./HomeShortcutCard";
import "./homeNotifications.scss";
import UpdateProfileImageModal from "../portalLayout/UpdateProfileImageModal";
import {
  useBannersList,
  useNewShortcutDetails,
  useNotficationList,
  useOtp,
  useOtpVerfication,
  useProfileDetails,
  useSendOtpCode,
  useUpdateProfilePhoto,
} from "../../../hooks/hookList";
import Image from "../../utils/image/Image";
import UpdateProfileModal from "./UpdateProfileModal";
import { useRedirection } from "../../../hooks/useRedirection";

const Home = () => {
  const navigate = useNavigate();
  const {
    showUpdateProfileModal,
    setShowUpdateProfileModal,
    showNotificationPopUp,
    setShowNotificationPopUp,
    userData,
    setUserData,
    setSpinner,
    setToaster,
    modalPopUpShow,
  } = useContext(AppContext);
  const { dispatch } = useBreadcrumb();
  const { setInitialValues, values, bindField } = useForm({
    validations: {},
  });
  const [showImage, setShowImaage] = useState();
  const [notification, setNotification] = useState([]);
  const [shortcutData, setShortcutData] = useState([]);
  const [banners, setBanners] = useState([]);
  const [notificationModalShow, setNotificationModalShow] = useState(false);

  const [otp, setOtp] = useState({
    modal: false,
    type: null,
    value: null,
    error: null,
  });

  // Fetch Dashboard Shortcut
  const { getData: getNewShortcutDetails } = useNewShortcutDetails(
    setSpinner,
    setToaster
  );

  // Get Profile Details
  const { getData: getProfileData } = useProfileDetails(setSpinner, setToaster);

  // Get Notifications List
  const { getData: getNotificationsList } = useNotficationList(
    setSpinner,
    setToaster
  );

  // Banners Data
  const { getData: getBanners } = useBannersList(setSpinner, setToaster);

  // Send OTP
  const { getData: sendOtpCode } = useSendOtpCode(setSpinner, setToaster);

  // Verification OTP
  const { getData: getOtpVerification } = useOtpVerfication(
    setSpinner,
    setToaster
  );

  const { getRedirection } = useRedirection();

  const batchType =
    userData && userData?.batches !== undefined
      ? userData?.batches[userData?.batches?.length - 1]?.batch_type
      : null;

  const handleImage = (e) => {
    setInitialValues((prev) => ({
      ...prev,
      image: URL.createObjectURL(e.target.files[0]),
    }));
    setShowImaage(e.target.files[0]);
  };

  // Profile Details Callback
  const profileDetailsCallback = (profileData) => {
    if (profileData?.data?.is_active === 0) {
      localStorage.clear();
      window.location.href = getRedirection(userData.org_id);
    } else {
      // setSpinner(false);
      setInitialValues({
        ...profileData?.data,
        parent_email: profileData?.data?.parent?.email,
        parent_phone: profileData?.data?.parent?.phone,
        first_name:
          profileData?.data?.name?.split(" ")[0]?.length === 1
            ? profileData?.data?.name
            : profileData?.data?.name?.split(" ")[0],
        ...(profileData?.data?.name?.split(" ")[1] !== undefined
          ? { last_name: profileData?.data?.name?.split(" ")[1] }
          : ""),
      });
    }
    setShowImaage(
      profileData?.data?.image ? profileData?.data?.image : userImg
    );
  };

  // ************************Get Profile Data******************************
  const updateProfileData = async (e) => {
    setSpinner(true);
    try {
      let name = userData?.first_name + " ";
      let params = {
        id: Auth?.user()?.id,
        body: {
          user_id: userData?.user_id,
          name: name + (userData?.last_name ? userData?.last_name : ""),
          // email: userData?.email,
          phone: userData?.phone,
        },
      };

      const response = await userService.updateProfile(params);
      if (response?.status >= 200 && response?.status <= 299) {
        const data = await response.json();
        setShowNotificationPopUp(true);
        setShowUpdateProfileModal(false);
        setToaster({ show: true, type: "success", content: data.message });
        setSpinner(false);
      }
      if (response?.status === 401) {
        localStorage.removeItem("_prathamStudentToken");
        setSpinner(false);
      }
      if (response?.status >= 400 && response?.status <= 499) {
        const data = await response.json();
        setToaster({ show: true, type: "danger", content: data.message });
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  // Dashboard Shortcut Callback
  const dashboardShortcutDetailsCallback = (dashboardShortcut) => {
    const dashboardShortcutData = dashboardShortcut?.data?.sort(
      (a, b) => Number(a.display_order) - Number(b.display_order)
    );
    setShortcutData(dashboardShortcutData);
  };

  // Banners Callback
  const bannersCallback = (bannerData) => {
    const ids = bannerData?.data?.map((banner) => banner.banner_id);
    const filtered = bannerData.data.filter(
      ({ banner_id }, index) => !ids.includes(banner_id, index + 1)
    );
    setBanners(filtered);
  };

  //=====================get thumbnale from vimeo========================
  const getVimeoVideo = (vimeoId) => {
    let img_url = "";
    let url = `https://api.vimeo.com/videos/${vimeoId}/pictures`;
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer 0a9ff75bdf53f8c4bfab3b7c43c11434`,
        Accept: "application/vnd.vimeo.*+json;version=3.4",
        "Content-Type": "application/json",
      },
    }).then((resp) => {
      setBanners((prev) =>
        prev?.map((itm) => {
          itm.img_url = resp?.data?.data[0]?.base_link;
          itm.video = resp?.data?.data[0]?.uri;
          return itm;
        })
      );
      img_url = resp?.data?.data[0]?.base_link;
    });
    return img_url;
  };

  // ************************Get Profile Data******************************

  /* Unneccessary use of API now & for optimization & in future this api will be used */

  /* const getDashBoarddata = async () => {
    setSpinner(true);
    try {
      const response = await userService.getDashboarddata();
      if (response?.status >= 200 && response?.status <= 299) {
        const data = await response.json();

        // setSpinner(false);
        setCardData([
          {
            id: "1",
            icon: i6,
            className: "card-box redBG",
            title: "Attendance",
            result: (data?.data?.attended_calsses && data?.data?.total_calsses) ? data?.data?.attended_calsses + "/" + data?.data?.total_calsses : 0,
          },
          {
            id: "2",
            icon: i7,
            className: "cards-box greenBG",
            title: "Exam Attempts",
            result: data?.data?.attempted_exams,
          },
          {
            id: "3",
            icon: i8,
            className: "cards-box yellowBG",
            title: "Average Rank",
            result: (data?.data?.attempted_exams && data?.data?.total_exams) ? data?.data?.attempted_exams + "/" + data?.data?.total_exams : 0,
          },
        ]);
      };
      if (response?.status === 401) {
        localStorage.removeItem("_prathamStudentToken");
      }
      if (response?.status >= 400 && response?.status <= 499) {
        const data = await response.json();
        setToaster({ show: true, type: "danger", content: data.message, });
        setSpinner(false);
      };

    } catch (error) {
      setSpinner(false);
    }
  }; */

  {
    /** 

  function isCurrentDateBetween(startDate, endDate) {
    const currentDate = new Date(); // Get the current date

    // Convert start date, end date, and current date to milliseconds
    const startDateMs = new Date(startDate)?.getTime();
    const endDateMs = new Date(endDate)?.getTime();
    const currentDateMs = currentDate.getTime();

    // Check if the current date is between start date and end date
    if (currentDateMs >= startDateMs && currentDateMs <= endDateMs) {
      return true;
    } else {
      return false;
    }
  }
*/
  }

  // Notification List Callback
  const notificationListCallback = (notificationData) => {
    const currentDate = new Date();
    notificationData.data.filter((itm) => {
      // Convert start date, end date, and current date to milliseconds
      const startDateMs = new Date(itm?.pop_up_valid_from)?.getTime();
      const endDateMs = new Date(itm?.pop_up_valid_to)?.getTime();
      const currentDateMs = currentDate.getTime();

      // Check if the current date is between start date and end date
      if (currentDateMs >= startDateMs && currentDateMs <= endDateMs) {
        return itm;
      }
      return itm;
    });
    setNotification(
      notificationData?.data?.filter(
        (item) =>
          moment(new Date()).isBetween(
            item?.pop_up_valid_from,
            item?.pop_up_valid_to
          ) && item?.pop_up
      )
    );
  };

  {
    /***
  const extractImageSource = (image) => {
    // Regular expression to match the URL within the src attribute of the img tag
    const urlRegex = /<Image src=\"([^\"]+)\"/;

    // Executing the regular expression to extract the URL
    const match = image.match(urlRegex);

    // Extracted URL will be in match[1] (first capturing group)
    const imageUrl = match ? match[1] : null;
    return imageUrl;
  };
   */
  }

  const handleSendOtp = async (otpType) => {
    const params = {
      ...(otpType === 0 && { email: values?.email }),
      ...(otpType === 1 && { user_id: values?.user_id }),
    };
    await sendOtpCode(params);
  };

  const handleVerificationOtp = async () => {
    const params = {
      ...(otp.type === 0 && { email: values.email }),
      ...(otp.type === 1 && { user_id: values.user_id }),
      otp: otp.value,
    };
    await getOtpVerification(params);
    await getProfileData({}, profileDetailsCallback);
    setOtp((prev) => ({ ...prev, modal: false, value: null, type: null }));
  };

  useEffect(() => {
    Promise.all([
      getProfileData({}, profileDetailsCallback),
      getBanners({}, bannersCallback),
      // getDashBoarddata(),
      getNotificationsList({}, notificationListCallback),
      getNewShortcutDetails({}, dashboardShortcutDetailsCallback),
    ]);
    setInitialValues({
      first_name: userData.first_name,
    });
    dispatch({
      type: "SET_BREADCRUMB",
      crumb: [{ title: "", url: "/dashboard" }],
    });

    //eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (
  //     [276, 278].includes(userData?.org_id) &&
  //     (userData.is_email_verified === 0 || userData.is_phone_verified === 0)
  //   ) {
  //     setShowUpdateProfileModal(true);
  //   }
  // }, [userData]);

  useEffect(() => {
    if (notification?.length > 0 && modalPopUpShow && showNotificationPopUp) {
      setNotificationModalShow(true);
    }
    //eslint-disable-next-line
  }, [notification?.length]);

  return (
    <>
      <div className="col-md-12 d-flex gap-4">
        <div className="body-cont">
          <CarouselComp banners={banners} getVimeoVideo={getVimeoVideo} />
          <div className="live_class">
            <div className="row mt-5">
              {shortcutData?.map((cards, index) => (
                <HomeShortcutCard
                  key={index}
                  card={cards}
                  batchType={batchType}
                />
              ))}
            </div>
          </div>
          {/* For now this should be hide in future we will be show for  */}
          {/* <div className="white-box mt-5 col-md-12 heading-subject">
            <div className="text-end text-decoration-underline">
              <Link to="/" className="themeLink">
                View Detailed Reports
              </Link>
            </div>
            <div className="row reportcard_row mt-5 border-bottom-dashed mb-5">
              {cardData?.map((report, index) => {
                return (
                  <div className="col-xxl-4 col-lg-6 col-md-6" key={index}>
                    <ReportCards
                      title={report.title}
                      result={report.result}
                      image={report.icon}
                      className={report.className}
                    />
                  </div>
                );
              })}
            </div>
            <div className="sub-report">
              <div className="dash-heading">
                <h1 className="after-underline">Subject Wise Performance</h1>
              </div>
              {!(<SubjectCards subJectData={data?.subJectData} isClass />) ? (
                "No data"
              ) : (
                <SubjectCards subJectData={data?.subJectData} isClass />
              )}
            </div>
          </div> */}
        </div>
        <div className="sidebar">
          <RightSidebar />
        </div>
      </div>
       
      <CommonModal
        show={notificationModalShow}
        close={() => setNotificationModalShow(false)}
        className="notification-modal notification-sec"
        heading={
          <div class="notification-drop-header d-flex align-items-center justify-content-between">
            <p class="gray-text mb-0">Announcements</p>
            <div class="modal-btn d-flex "></div>
          </div>
        }
        body={
          <>
            <div className="notification-drop-body ">
              {notification?.length > 0
                ? notification?.map((note, index) => {
                    return (
                      <Dropdown.Item
                        href="#/notification"
                        className={`${
                          note?.is_active === 1 ? "" : "un"
                        }read-notification notification-cont`}
                        key={index + 1}
                        onClick={() => {
                          navigate("/notification", {
                            state: {
                              id: `notification-${index + 1}`,
                            },
                          });
                        }}
                      >
                        <div className="notifications">
                          <ul className="mb-0">
                            <li className="notifications-cont w-100">
                              {/* <div>
                                <Image
                                  className="w-100"
                                  style={{ maxWidth: "unset" }}
                                  src={extractImageSource(note?.message)}
                                />
                              </div> */}
                              <p
                                style={{ width: "100%", height: "100%" }}
                                dangerouslySetInnerHTML={{
                                  __html: note?.message,
                                }}
                              ></p>
                            </li>
                            {/* <li className="notifications-more">
                              <Image src={moreSetting} alt="notification icon" />
                            </li> */}
                          </ul>
                        </div>
                      </Dropdown.Item>
                    );
                  })
                : "No Data"}
            </div>
          </>
        }
      />
    </>
  );
};

export default Home;
