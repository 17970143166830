import React, { useContext, useEffect, useState } from "react";
import html2pdf from "html2pdf.js";
import ReportCards from "../../utils/card/ReportCards";
import SubjectCards from "../../utils/card/SubjectCards";
import Table from "../../utils/table/Table";
import { AppContext } from "../../shared/AppContext";
import { userService } from "../../../_services/User.services";
import { useBreadcrumb } from "../../shared/breadcrumb-context";
import { Link } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import "./report.scss";
import {
  convertIsoToOrdinalDate,
} from "../../utils/dateTime";

const Reports = () => {
  const { setSpinner, setToaster, userData } = useContext(AppContext);
  const { dispatch } = useBreadcrumb();
  const [key, setKey] = useState("Subject Wise");
  const [reportData, setReportData] = useState([]);
  const [recordsData, setRecordsData] = useState([]);
  const [performanceData, setPerformanceData] = useState([]);
  const [categoryWiseData, setCategoryWiseData] = useState([]);
  const [subjectWiseData, setSubjectWiseData] = useState([]);
  const [topicTestData, setTopicTestData] = useState([]);
  const [classModeData, setClassModeData] = useState({});
  const [detailData, setDetailData] = useState([]);

  const downloadPDF = () => {
    const element = document.getElementById("content-to-pdf"); // Replace with the ID of the content to convert
    const screenWidth = window.screen.width + 50; // Get the screen width in pixels
    const screenHeight = window.screen.height + 50; // Get the screen height in pixels
    const pdfOptions = {
      margin: 10,
      filename: "report.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "px",
        format: [screenWidth, screenHeight],
        orientation: "landscape",
      }, // Use screen width and height
    };
    html2pdf().from(element).set(pdfOptions).save();
  };

  const classes = [
    "lightPink",
    "greenBG",
    "yellowBG",
    "pinkBG",
    "lightBlue",
    "darkBlue",
  ];

  const getTestReport = async () => {
    setSpinner(true);
    try {
      const response = await userService?.getTestReport();
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setPerformanceData([
          {
            id: 1,
            icon: "images/icon08.svg",
            className: "card-box redBG",
            title: "Total Tests",
            result: data?.data?.total_test,
            isSpeed: false,
          },
          {
            id: 2,
            icon: "images/icon1.svg",
            className: "cards-box greenBG",
            title: "Attempted",
            result: data?.data?.attempted_tests,
            isSpeed: false,
          },
          {
            id: 3,
            icon: "images/icon09.svg",
            className: "cards-box yellowBG",
            title: "Speed",
            result: data?.data?.speed,
            isSpeed: true,
          },
          {
            id: 4,
            icon: "images/icon09.svg",
            className: "cards-box pinkBG",
            title: "Accuracy",
            result: data?.data?.accuracy,
            isSpeed: false,
          },
        ]);

        setCategoryWiseData(
          data?.data?.category_wise_data?.map((item, i) => {
            return [
              { value: item?.name, id: 1 },
              { value: item?.count, id: 2 },
              { value: item?.attempted, id: 3 },
              { value: item?.accuracy !== null ? item.accuracy : "NA", id: 4 },
              { value: item?.speed !== null ? item.speed : "NA", id: 5 },
            ];
          })
        );
        setDetailData(data?.data?.category_wise_data);

        if (data?.data?.subject_wise_data) {
          setSubjectWiseData(
            data?.data?.subject_wise_data?.map((item) => {
              return [
                { value: item?.subject_name, id: 1 },
                { value: item?.total_questions, id: 2 },
                { value: item?.attempted, id: 3 },
                {
                  value: item?.accuracy !== null ? item?.accuracy : "NA",
                  id: 4,
                },
                { value: item?.speed !== null ? item?.speed : "NA", id: 5 },
              ];
            })
          );
        }
        if (data.data.topic_test_data.length > 0) {
          const topicTestsData = data.data.topic_test_data.map((topicData) => {
            return [
              {
                value: topicData?.mock_name,
                id: 1,
              },
              {
                value: topicData?.total_marks,
                id: 2,
              },
              {
                value: topicData?.score,
                id: 3,
              },
              {
                value: topicData?.correct,
                id: 4,
              },
              {
                value: topicData?.incorrect,
                id: 5,
              },
            ];
          });
          setTopicTestData(topicTestsData);
        }
        setSpinner(false);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setSpinner(false);
        setToaster({ show: true, type: "danger", content: data.message });
      }
      if (response?.status === 401) {
        localStorage.removeItem("_prathamStudentToken");
        setSpinner(false);
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  const getClassReport = async () => {
    const params = {
      course_ids: userData.course_ids,
      batch_ids: userData.batch_ids,
    };
    setSpinner(true);
    try {
      const response = await userService?.getClassReport(params);
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setReportData([
          {
            id: 1,
            icon: "images/icon08.svg",
            className: "card-box redBG",
            title: "Total Classes",
            result: data?.data?.total_classes,
            isSpeed: false,
          },
          {
            id: 2,
            icon: "images/icon1.svg",
            className: "cards-box greenBG",
            title: "Total Attended",
            result: data?.data?.total_classes_attended,
            isSpeed: false,
          },
          {
            id: 3,
            icon: "images/icon09.svg",
            className: "cards-box yellowBG",
            title: "Last 15 days",
            result: data?.data?.last_15_days,
            isSpeed: true,
          },
          {
            id: 4,
            icon: "images/icon09.svg",
            className: "cards-box pinkBG",
            title: "Last 30 days",
            result: data?.data?.last_30_days,
            isSpeed: false,
          },
        ]);
        const newData = {
          total_offline_classes: data?.data?.total_offline_classes,
          total_online_classes: data?.data?.total_online_classes,
          total_attented_offline_classes:
            data?.data?.total_attented_offline_classes,
          total_attented_online_classes:
            data?.data?.total_attented_online_classes,
          subject_wise: data?.data?.subject_wise,
        };
        setClassModeData(newData);

        let tempary = [];
        let temphsh = {};
        let subAry = [];

        data?.data?.subject_wise?.forEach((item, i) => {
          subAry = [
            {
              class: "Total Classes:",
              class_data: item?.total,
            },
            {
              class: "Attended:",
              class_data: item?.attended,
            },
          ];
          temphsh = {
            id: i + 1,
            name: item?.subject,
            className: classes[Math.floor(Math.random() * i)],
            data: subAry,
          };
          tempary.push(temphsh);
        });

        setRecordsData(tempary);
        setSpinner(false);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setSpinner(false);
        setToaster({ show: true, type: "danger", content: data.message });
      }
      if (response?.status === 401) {
        localStorage.removeItem("_prathamStudentToken");
        setSpinner(false);
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  useEffect(() => {
    let tempary = [];
    let temphsh = {};
    let subAry = [];
    if (key === "Subject Wise") {
      classModeData?.subject_wise?.forEach((item, i) => {
        subAry = [
          {
            class: "Total Classes:",
            class_data: item?.total,
          },
          {
            class: "Attended:",
            class_data: item?.attended,
          },
        ];
        temphsh = {
          id: i + 1,
          name: item?.subject,
          className: classes[Math.floor(Math.random() * i)],
          data: subAry,
        };
        tempary.push(temphsh);
      });
    }
    if (key === "Class Mode Wise") {
      tempary = [
        {
          id: 1,
          name: "Online",
          className: classes[1],
          isBreadCrumb: true,
          data: [
            {
              class: "Total Classes:",
              class_data: classModeData?.total_online_classes,
            },
            {
              class: "Attended:",
              class_data: classModeData?.total_attented_online_classes,
            },
          ],
        },
        {
          id: 1,
          name: "Offline",
          className: classes[0],
          isBreadCrumb: true,
          data: [
            {
              class: "Total Classes:",
              class_data: classModeData?.total_offline_classes,
            },
            {
              class: "Attended:",
              class_data: classModeData?.total_attented_offline_classes,
            },
          ],
        },
      ];
    }

    setRecordsData(tempary);
    //eslint-disable-next-line
  }, [key]);

  useEffect(() => {
    if (userData.course_ids && userData.batch_ids) {
      Promise.all([getTestReport(), getClassReport()]);
      dispatch({
        type: "SET_BREADCRUMB",
        crumb: [{ title: "Reports", url: "/reports" }],
      });
    }
  }, [userData]);

  return (
    <>
      {/* <div className="download-page col-xxl-2  col-xl-2 col-lg-3 col-md-3 col-sm-4"></div> */}
      <div className="report-dash" id="content-to-pdf">
        <div className="white-box col-md-12">
          <div className="attendance_container">
            <div className="dash-heading-attendance">
              <h1 className="report_title">Attendance</h1>
            </div>
            <div className="report_btn_container">
              <Link to="completereports?type=omr" className="themeLink">
                <button className="btn small-btn theme-btn mt-0 no-wrap">
                  Check OMR Score
                </button>
              </Link>
              <Link className="preparedness_btn" to="/test/student-performance">
                <button className="small-btn green-btn mt-0 no-wrap">
                  Preparedness Report
                </button>
              </Link>
              <div className="download_report_btn">
                <button
                  className="small-btn yellow-btn mt-0 no-wrap"
                  onClick={downloadPDF}
                >
                  Download Report
                </button>
              </div>
            </div>
          </div>

          <div className="row reportcard_row mt-5 border-bottom-dashed mb-5">
            {reportData?.map((report) => {
              return (
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                  <ReportCards
                    title={report.title}
                    result={report.result}
                    image={report.icon}
                    className={report.className}
                    isSpeed={report.isSpeed}
                  />
                </div>
              );
            })}
          </div>
          <div className="dash-heading-report">
            <h1 className="mb-4 report_title">Records</h1>
            <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-center gap-3">
              <div className="text-end text-decoration-underline">
                <Link to="completereports?type=record" className="themeLink">
                  View Complete Records
                </Link>
              </div>
              {/* <div className="text-end text-decoration-underline">
                <Link to="completereports?type=omr" className="themeLink">
                  Check Your Previous OMR Score Here
                </Link>
              </div> */}
            </div>
          </div>
          <div className="exam-tabs">
            <Tabs
              defaultActiveKey="liveclass"
              id="uncontrolled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3 grid-3"
            >
              <Tab eventKey="Subject Wise" title="Subject Wise">
                <div className="sub-report">
                  <SubjectCards subJectData={recordsData} />
                </div>
              </Tab>
              <Tab eventKey="Class Mode Wise" title="Class Mode Wise">
                <SubjectCards subJectData={recordsData} />
              </Tab>
            </Tabs>
          </div>
        </div>

        <div className="white-box mt-5 col-md-12">
          <div className="dash-heading-performance">
            <h1 className="report_title">Performance</h1>
            <div className="text-center text-lg-end text-decoration-underline">
              <Link to="completereports?type=performance" className="themeLink">
                View Complete Performance
              </Link>
            </div>
          </div>
          <div className="row reportcard_row my-2 border-bottom-dashed">
            {performanceData?.map((report) => {
              return (
                <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6">
                  <ReportCards
                    title={report.title}
                    result={report.result}
                    image={report.icon}
                    className={report.className}
                    isSpeed={report.isSpeed}
                  />
                </div>
              );
            })}
          </div>
          {categoryWiseData.length > 0 ? (
            <div className="border-bottom-dashed mb-5 pb-4">
              <Table
                heading={[
                  { value: "Exam Category", id: 1 },
                  { value: "Total Tests", id: 2 },
                  { value: "Tests Attempted", id: 3 },
                  { value: "Accuracy", id: 4 },
                  { value: "Speed", id: 5 },
                ]}
                rows={categoryWiseData}
              />
            </div>
          ) : (
            ""
          )}
          {subjectWiseData.length > 0 ? (
            <div className="">
              <Table
                heading={[
                  { value: "Subjects", id: 1 },
                  { value: "Total Questions", id: 2 },
                  { value: "Attempted", id: 3 },
                  { value: "Accuracy", id: 4 },
                  { value: "Speed", id: 5 },
                ]}
                rows={subjectWiseData}
              />
            </div>
          ) : (
            ""
          )}
          {detailData.length > 0 ? (
            <div className="mt-5">
              <h1 className="mb-3 report_title">Complete Details</h1>
              {detailData?.map((category, index) => (
                <div key={index}>
                  {category.details.length > 0 && (
                    <div>
                      <h3 className="my-4" key={index}>
                        {category.name}
                      </h3>
                      <Table
                        heading={[
                          { value: "S No", id: 1 },
                          { value: "Test Name", id: 2 },
                          { value: "score", id: 3 },
                          { value: "Question Answered", id: 4 },
                          { value: "Accuracy %", id: 5 },
                          { value: "Submitted On", id: 6 },
                        ]}
                        rows={category.details.map((detail, j) => {
                          return [
                            { value: j + 1, id: "6" },
                            { value: detail?.name, id: 1 },
                            {
                              value: `${detail?.score}/${detail?.total_marks}`,
                              id: 2,
                            },
                            {
                              value: `${detail?.total_attempt}/${detail?.total_questions}`,
                              id: 3,
                            },
                            {
                              value: `${(
                                (detail?.correct_answers /
                                  detail?.total_attempt) *
                                100
                              ).toFixed(2)}`,
                              id: 4,
                            },
                            {
                              value: (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: convertIsoToOrdinalDate(
                                      detail.updated_at
                                    ),
                                  }}
                                />
                              ),
                              id: 5,
                            },
                          ];
                        })}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
          {topicTestData.length > 0 && (
            <div className="mt-5">
              <h1 className="mb-3 report_title">OMR BASED TEST</h1>
              <Table
                heading={[
                  {
                    value: "Mock name",
                    id: 1,
                  },
                  {
                    value: "Total Marks",
                    id: 2,
                  },
                  {
                    value: "Score",
                    id: 3,
                  },
                  {
                    value: "Correct",
                    id: 4,
                  },
                  {
                    value: "Incorrect",
                    id: 5,
                  },
                ]}
                rows={topicTestData}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Reports;
