import React from "react";
import Image from "../../../utils/image/Image";

const HeaderHamburger = ({
  setIsSidebarHide,
  isSidebarHide,
  icon,
  location,
}) => {
  const isShow =
    location?.pathname.includes("examPortal") ||
    location.pathname.includes(
      "/home-assignments/assignment-details/newAssignmentQuiz/"
    );
  return (
    <div className="d-flex">
      <div
        className="header_mobile_breadcrumb"
        onClick={() => setIsSidebarHide(!isSidebarHide)}
      >
        <div
          className={`breadcrumb_container ${
            isSidebarHide ? "container_close" : "container_open"
          }`}
        >
          <span
            className={!isSidebarHide ? "line_initial_white" : "line_initial"}
          ></span>
          <span
            className={!isSidebarHide ? "line_second_white" : "line_second"}
          ></span>
          <span
            className={!isSidebarHide ? "line_third_white" : "line_third"}
          ></span>
        </div>
      </div>
      {!isShow && (
        <div className="logo_container">
          <Image src={icon} alt="logo" />
        </div>
      )}
    </div>
  );
};

export default HeaderHamburger;
