import { useEffect } from "react";
import { MOUSEDOWN, TOUCHSTART } from "../component/utils/constants";

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener(MOUSEDOWN, listener);
    document.addEventListener(TOUCHSTART, listener);
    return () => {
      document.removeEventListener(MOUSEDOWN, listener);
      document.removeEventListener(TOUCHSTART, listener);
    };
  }, [ref, handler]);
};
