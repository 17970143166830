import React, { useEffect } from "react";

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import arrowImg from "../../../assets/images/white-arrow-down.svg";

import ScheduleCard from "../../utils/card/ScheduleCard";
import BatchCard from "../../utils/card/BatchCard";
import { userService } from "../../../_services/User.services";
import moment from "moment";
import { useContext } from "react";
import { AppContext } from "../../shared/AppContext";
import { useState } from "react";
import Auth from "../../../auth/Auth";
import CommonModal from "../../utils/modal/CommonModal";
import { useBreadcrumb } from "../../shared/breadcrumb-context";
import { CONSTANT } from "../../utils/constants";
import Image from "../../utils/image/Image";
import HomeScheduleCard from "../../utils/card/HomeScheduleCard";

export default function RightSidebar() {
  const {
    setToaster,
    setSpinner,
    calendarDate,
    setCalendarDate,
    userData,
    setScheduleDatePicker,
    sidebarShow,
    setSidebarShow,
  } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { dispatch } = useBreadcrumb();
  const [scheduleData, setScheduleData] = useState({ test: [], classes: [] });
  const [batchDetails, setBatchDetails] = useState([]);
  const [batchModal, setBatchModal] = useState(false);
  const selDate = moment(calendarDate).format("DD-MM-YYYY");
  const today = moment(new Date()).format("DD-MM-YYYY");
  const navigation = (data) => {
    navigate(`/test/examinstructions/${data?.id}`, {
      state: { back_url: location?.pathname + location?.search },
    });
  };

  const whatStatus = (params) => {
    const { start_date, start_time, end_time, live_class_id } = params;
    const currentTime = moment();
    const givenDate = moment(start_date, CONSTANT.YEARS_WITH_HOUR_FORMAT);
    const dateOnly = givenDate.format(CONSTANT.YEARS_WISE_FORMAT);
    const startDateTimeString = `${dateOnly} ${start_time}`;
    const endDateTimeString = `${dateOnly} ${end_time}`;
    const givenDateTime = moment(
      startDateTimeString,
      CONSTANT.YEARS_WITH_HOUR_FORMAT
    );

    // Calculate 15 minutes before the scheduled start time
    const joinWindowStart = givenDateTime.clone().subtract(30, "minutes");

    const endTime = moment(endDateTimeString, CONSTANT.YEARS_WITH_HOUR_FORMAT);

    if (live_class_id !== null) {
      if (currentTime.isBetween(joinWindowStart, givenDateTime)) {
        return "JOIN NOW";
      } else if (currentTime.isBetween(givenDateTime, endTime)) {
        return "In Progress";
      } else if (currentTime.isBefore(givenDateTime)) {
        return CONSTANT.EXAM_STATUS.SCHEDULED;
      } else {
        return CONSTANT.EXAM_STATUS.COMPLETED;
      }
    } else {
      if (currentTime.isBetween(joinWindowStart, givenDateTime)) {
        return "JOIN NOW";
      } else if (currentTime.isBetween(givenDateTime, endTime)) {
        return "In Progress";
      } else if (currentTime.isBefore(givenDateTime)) {
        return CONSTANT.EXAM_STATUS.SCHEDULED;
      } else {
        return CONSTANT.EXAM_STATUS.COMPLETED;
      }
    }
  };

  const testTitle = (item) => {
    let status = "";
    if (item?.status === 0) {
      status = "schedule";
    }

    if (item?.status === 1 && item?.exam_retakes === 0) {
      status = "View Report";
    }

    if (item?.status === 1 && item?.exam_retakes > 0) {
      status = "View Report";
    }

    if (
      (item?.status === 2 || item?.status === 4) &&
      new Date(item?.end_date) < new Date() &&
      !item?.category?.name?.toLowerCase().includes("home")
    ) {
      status = " View Report";
    }

    if (
      (item?.status === 2 || item?.status === 4) &&
      !item?.category?.name?.toLowerCase().includes("home") &&
      new Date(item?.result_date) > new Date()
    ) {
      status = "Result Awaited";
    } else {
      status = "View Report";
    }

    if (
      new Date(item?.result_date) <= new Date() &&
      item?.exam_retakes < item?.category?.exam_retakes &&
      item?.status === 4
    ) {
      status = "Retake";
    }

    if (item?.status === 6) {
      status = "Resume Test";
    }

    // if (item?.status === 3 || item?.exam_category_id === 5) {
    if (
      item?.status === 3 ||
      item?.category?.name?.toLowerCase().includes("home")
    ) {
      status = "Take Exam";
    }

    if (item?.status === 5) {
      if (new Date(item?.end_date) > new Date()) {
        status = "Resume Test";
      } else {
        status = "Expired";
      }
    }

    return status;
  };
  //********************Get today schedule(classes and tests)********************//

  const getTodaySchedule = async (date) => {
    const classType = (classType) => {
      switch (classType) {
        case 1:
          return "Offline";
        case 2:
          return "Online";
        case 3:
          return "Youtube";
        default:
          return "No Class Name";
      }
    };
    const response = await userService?.getAllSchedule({
      date: moment(date).format("YYYY-MM-DD"),
    });
    try {
      /*
      classes: data?.data?.classes?.data?.map((elem) => {
      return {
        id: elem?.id,
        title: elem?.meeting_name,
        time: moment(elem?.start_date).format("hh:mm A"),
        icon: "images/green-circle.png",
        status: "Online",
        btn_title: "Join Now",
        isStatus: true,
        isButton: whatStatus(elem?.status) === "Online" ? true : false,
        isIcon: whatStatus(elem?.status) === "Online" ? true : false,
        host_id: elem?.host_id,
        host_name: elem?.host_name,
        thread_id: elem?.thread_id,
        join_url: elem?.join_url,
      };
    }),
    */
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setSpinner(false);
        setScheduleData({
          classes: data?.data?.data?.map((elem) => {
            elem?.live_class_id !== null &&
              elem.join_url !== null &&
              window?.localStorage.setItem(
                `liveClassId-${elem?.live_class_id}`,
                elem?.live_class_id
              );
            elem?.live_class_id !== null &&
              elem.join_url !== null &&
              window?.localStorage.setItem(
                `joinUrl-${elem?.live_class_id}`,
                elem.join_url
              );
            const classStatus = whatStatus({
              start_date: elem?.date,
              start_time: elem?.start_time,
              end_time: elem?.end_time,
              duration: elem?.duration,
              class_type: elem?.class_type,
              live_class_id: elem?.live_class_id,
              thread_id: elem?.thread_id,
              join_url: elem?.join_url,
            });
            return {
              id: elem.live_class_id,
              title: elem?.subject,
              time: `${moment(elem?.start_time, "HH:mm:ss").format(
                "hh:mm A"
              )} - ${moment(elem?.end_time, CONSTANT.HOURS_WITH_SECONDS).format(
                "hh:mm A"
              )}`,
              icon:
                (classStatus === "In Progress" && "images/green-circle.png") ||
                (classStatus === "JOIN NOW" && "images/green-circle.png"),
              status: classStatus,
              btn_title:
                (elem?.live_class_id !== null &&
                  classStatus === "In Progress") ||
                (elem.live_class_id !== null && classStatus === "JOIN NOW")
                  ? "Join Now"
                  : classStatus,
              status_title: classStatus,
              isButton:
                (elem?.live_class_id !== null &&
                  classStatus === "In Progress") ||
                (elem.live_class_id !== null && classStatus === "JOIN NOW")
                  ? true
                  : false,
              isStatus: true,
              isIcon: false,
              room:
                elem?.class_type === "Offline"
                  ? `${elem?.batch_name}, ${elem?.room}`
                  : classType(elem?.class_type),
              elem: elem,
            };
          }),
        });
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setSpinner(false);
        setToaster({ show: true, type: "danger", content: data.message });
      }
      if (response?.status === 401) {
        localStorage.removeItem("_prathamStudentToken");
      }
    } catch {
      setSpinner(false);
    }
  };

  //********************Get Pratham batches details ********************//

  // const getPrathamBatchesAndBatch = async () => {
  //   setSpinner(true);
  //   const response = await userService?.getPrathamBatches({
  //     username: Auth.user().user_id,
  //   });
  //   try {
  //     if (response.status >= 200 && response.status <= 299) {
  //       var data = await response.json();
  //       localStorage.setItem("center_name", data?.data[0]?.centre_name);
  //       setBatchDetails(
  //         data?.data?.map((elem) => ({
  //           id: elem?.enrolment_id,
  //           batch_num: elem?.batch_name,
  //           days_and_timing: elem?.batch_timing,
  //           day: 10,
  //           time: 20,
  //           center: elem?.centre_name,
  //         }))
  //       );
  //       setSpinner(false);
  //     }
  //     if (response.status >= 400 && response.status <= 499) {
  //       const data = await response.json();
  //       setSpinner(false);
  //       setToaster({ show: true, type: "danger", content: data.message });
  //     }
  //     if (response?.status === 401) {
  //       localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
  //     }
  //   } catch {
  //     setSpinner(false);
  //   }
  // };

  //********************Get Pratham batches details ********************//

  const downloadAdmitCard = async () => {
    setSpinner(true);
    const response = await userService?.downloadAdmitCard({
      username: Auth?.user()?.user_id,
    });
    try {
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();

        setBatchDetails(
          data?.data?.map((elem) => ({
            id: elem?.enrolment_id,
            batch_num: elem?.batch_name,
            days_and_timing: elem?.batch_timing,
            day: 10,
            time: 20,
            center: elem?.centre_name,
          }))
        );
        setSpinner(false);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setSpinner(false);
        setToaster({ show: true, type: "danger", content: data.message });
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }
    } catch {
      setSpinner(false);
    }
  };

  // useEffect(() => {
  //   // getPrathamBatchesAndBatch();
  //   //eslint-disable-next-line
  // }, []);

  const toHoursAndMinutes = (totalSeconds) => {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours}:${minutes}:${seconds}`;
    // return { h: hours, m: minutes, s: seconds };
  };

  const localStorageData = (item) => {
    let a = Number(item?.duration) * 60;
    if (item?.time_spent) {
      a = a - Number(item?.time_spent);
    }

    localStorage.setItem("exam_category_id", item?.exam_category_id);
    // localStorage.setItem("duration", Math.floor(item?.duration / 60) + ":" + Math.round(item?.duration % 60, 2) + " :00");
    localStorage.setItem("UITemplate", item?.category?.template?.id);
    localStorage.setItem("duration", toHoursAndMinutes(a));
    localStorage.setItem("realDuration", item?.duration);
    localStorage.setItem("resultDate", item?.result_date);
    localStorage.setItem("examId", item?.id);
    localStorage.setItem("tempId", item?.exam_template_id);
    localStorage.setItem("status", item?.status);
    // localStorage.setItem('resumeTimeDeducted', true)
    if (item?.time_spent) {
      localStorage.setItem("resumeTime", item?.time_spent);
    }
  };

  const handleExam = (data) => {
    // /home-assignments/assignment-details/assignmentQuiz/26
    if (data?.status === 1 && data?.exam_retakes === 0) {
      navigate(`/test/${data?.id}/exams/resultnew`);
    }
    if (data?.status === 1 && data?.exam_retakes > 0) {
      navigate(`/test/${data?.id}/exams/resultnew`);
    }
    if (data?.status === 2) {
      navigate(`/test/${data?.id}/exams/resultnew`);
    }
    if (data?.status === 5) {
      if (new Date(data?.end_date) > new Date()) {
        navigation(data);
        localStorageData(data);
        // return "Resume Test"
      } else {
        navigate(`/test/${data?.id}/exams/resultnew`);
      }
    }
    if (data?.status === 3) {
      // if (data?.exam_category_id === 5) {
      if (data?.category?.name?.toLowerCase().includes("home")) {
        navigate(
          `/home-assignments/assignment-details/assignmentQuiz/${data?.id}`
        );
      } else {
        navigation(data);
      }
      localStorageData(data);
    }
    if (data?.status === 4) {
      // if (data?.exam_category_id === 5) {
      if (data?.category?.name?.toLowerCase().includes("home")) {
        navigate(
          `/home-assignments/assignment-details/assignmentQuiz/${data?.id}`
        );
      }
      // if (data?.exam_category_id !== 5) {
      // if (!data?.category?.name?.toLowerCase().includes('home')) {
      //   navigate(`/home-assignments/assignment-report/${data?.id}`)
      // }
      else {
        navigate(`/test/${data?.id}/exams/resultnew`);
        // navigation(data);
      }
      localStorageData(data);
    }
    if (data?.status === 6) {
      // if (data?.exam_category_id === 5) {
      if (data?.category?.name?.toLowerCase().includes("home")) {
        navigate(
          `/home-assignments/assignment-details/assignmentQuiz/${data?.id}`
        );
      } else {
        navigation(data);
      }
      localStorageData(data);
    }
    if (data?.status === 0) {
      setToaster({
        show: true,
        type: "success",
        content: "Exam is scheduled.",
      });
    }
  };

  useEffect(() => {
    if (calendarDate) {
      getTodaySchedule(calendarDate);
    }
    //eslint-disable-next-line
  }, [calendarDate]);

  useEffect(() => {
    if (location.pathname !== "/schedule") {
      setScheduleDatePicker("");
    }
  }, [location]);

  return (
    <>
      <div className="schedule-sidebar">
        <div className="right-sidebar">
          <div className="white-box">
            <div className="calendar_sidebar">
              <div className="dash-heading">
                <h2>Calendar</h2>
              </div>

              <Calendar
                prev2Label=""
                next2Label=""
                onChange={(e) => {
                  setCalendarDate(e);
                }}
                value={calendarDate}
              />
            </div>
            <div className="schedule_sec mt-5">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <h3 className="schedule_timing mb-0">
                  {selDate === today ? "Today's" : selDate} Schedule
                </h3>
                <div
                  onClick={() =>
                    dispatch({
                      type: "SET_BREADCRUMB",
                      crumb: [{ title: "Schedule", url: "/schedule" }],
                    })
                  }
                >
                  <h3>
                    <NavLink
                      to="/schedule"
                      onClick={() => setSidebarShow(false)}
                      className="themeLink text-decoration-underline float-end"
                    >
                      <h4 className="mb-0 show_all">Show All</h4>
                    </NavLink>
                  </h3>
                </div>
              </div>
              {scheduleData?.classes?.length > 0 ||
              scheduleData?.test?.length > 0 ? (
                <>
                  <div className="right-schedule">
                    <HomeScheduleCard
                      scheduleData={scheduleData?.classes}
                      type="class"
                    />
                  </div>
                  <div className="right-schedule">
                    <HomeScheduleCard
                      scheduleData={scheduleData?.test?.slice(0, 1)}
                      type="test"
                      handleExam={handleExam}
                    />
                  </div>
                </>
              ) : (
                <span className="alert-text font-16">No Classes</span>
              )}
            </div>
          </div>
          <div className="white-box mt-2">
            <div className="d-flex flex-column-reverse flex-md-column">
              {userData?.batches?.length > 0 && (
                <div>
                  <div className="dash-heading">
                    <h2>Batch Details</h2>
                  </div>
                  <BatchCard
                    batchCard={userData?.batches}
                    onClick={downloadAdmitCard}
                  />
                </div>
              )}
              {![276, 278].includes(userData?.org_id) && (
                <div className="batch-modal mt-lg-5 mb-3 mb-lg-0 d-flex">
                  <a
                    className="btn theme-btn small-btn m-0"
                    href={`${process.env.REACT_APP_BATCH_ID_CARD_URL}/${
                      Auth?.user()?.user_id
                    }`}
                    download
                    target="_blank"
                    style={{ fontSize: "12px", fontWeight: "400" }}
                  >
                    Download Student ID Card
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="mobile-sidebar-icon position-fixed"
        onClick={() => {
          setSidebarShow(!sidebarShow);
          setBatchModal(false);
        }}
      >
        <div className="heading">
          Calendar
          <Image src={arrowImg} alt="arrow" />
        </div>
      </div>
      {/* batch detail  */}
      <CommonModal
        className="batch-modal"
        show={batchModal}
        close={() => setBatchModal(false)}
        // className={dark_mode}
        heading={<h1 className="modal-title text-center"> Batch Details</h1>}
        body={
          <div className="common_modal pb-5">
            <div className="modalHeading"></div>
            <BatchCard batchCard={batchDetails} onClick={downloadAdmitCard} />
          </div>
        }
      />
    </>
  );
}
