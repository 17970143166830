import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../shared/AppContext";
import { useFormCategories, useFormUpdates } from "../../../hooks/hookList";
import view_icon from "../../../assets/images/eys_view.png";
import FormHelplineDesk from "./FormHelplineDesk";
import FormModal from "./FormModal";
import FormCategoryTabs from "./formCategory/FormCategoryTabs";
import FormReleasedTable from "./FormReleasedTable";
import FormSelect from "./FormSelect";
import Image from "../../utils/image/Image";

const FormUpdates = () => {
  const { setSpinner, setToaster, userData } = useContext(AppContext);
  const { getData: getFormCategories, data: formCategories } =
    useFormCategories(setSpinner, setToaster);

  const { getData: getFormUpdates } = useFormUpdates(setSpinner, setToaster);

  const [keys, setKeys] = useState("");
  const [types, setTypes] = useState([]);
  const [formData, setFormData] = useState([]);
  const [instModal, setInstModal] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [formUpdateData, setFormUpdateData] = useState([]);
  const [isSelectedDate, setIsSelectedData] = useState(
    new Date().getFullYear() + 1
  );
  const [formCategoryFillForm, setFormCategoryFillForm] = useState([]);

  const getFormCategoryName = (formCategoryId) => {
    const filterCategoryFormData = formCategories.find(
      (form) => Number(form.category_id) === Number(formCategoryId)
    );
    return filterCategoryFormData;
  };

  const showTabData = (formCategory) => {
    const formCategoryTable = formCategory?.category_wise_form?.map(
      (categoryWise, index) => {
        return [
          {
            value: index + 1,
            id: 1,
            className: "w-5 text-center",
          },
          {
            value: categoryWise?.name,
            id: 2,
            className: "w-35",
          },
          {
            value: (
              <div className="w-100 d-flex justify-content-between">
                <div>{categoryWise?.notifiaction?.name}</div>
                <div>
                  <Image
                    src={view_icon}
                    onClick={() => gotoServices(categoryWise.id)}
                  />
                </div>
              </div>
            ),
            id: 3,
            className: "w-15 themeLink cursor-pointer",
          },
        ];
      }
    );
    const formCategoryData = formCategory?.category_wise_form?.map(
      (categoryWise) => ({
        universityName: categoryWise?.name,
        universityId: formCategory?.category_id,
        formId: categoryWise?.id,
        isRecommendation: categoryWise?.recommendation,
        formTable: [
          {
            heading: "Course (s) Offered",
            data: (
              <div
                dangerouslySetInnerHTML={{
                  __html: categoryWise?.courses_offered,
                }}
              ></div>
            ),
            tdClassName: "color_black",
          },
          {
            heading: "Eligibility Criteria",
            data: (
              <div
                dangerouslySetInnerHTML={{
                  __html: categoryWise?.eligibility_criteria,
                }}
              />
            ),
            tdClassName: "color_black",
          },
          {
            heading: "Selection/Admission Process",
            data: (
              <div
                dangerouslySetInnerHTML={{
                  __html: categoryWise.selection_process,
                }}
              />
            ),
          },
          ...(categoryWise.start_dates[0]?.start_date_remark !== null
            ? [
                {
                  heading: "Online Application starts from",
                  data:
                    categoryWise.start_dates[0]?.start_date_remark !== null
                      ? categoryWise.start_dates[0]?.start_date_remark
                      : "",
                },
              ]
            : []),
          ...(categoryWise.end_dates[0]?.end_date_remark !== null
            ? [
                {
                  heading: "Last date for submitting online application",
                  data:
                    categoryWise.end_dates[0]?.end_date_remark !== null
                      ? categoryWise.end_dates[0]?.end_date_remark
                      : "",
                },
              ]
            : []),
          ...(categoryWise.exam_dates[0]?.exam_date_remark !== null
            ? [
                {
                  heading: "Exam Date",
                  data:
                    categoryWise.exam_dates[0]?.exam_date_remark !== null
                      ? categoryWise.exam_dates[0]?.exam_date_remark
                      : "",
                },
              ]
            : []),
          {
            heading: "Form Link",
            data: (
              <div
                dangerouslySetInnerHTML={{ __html: categoryWise.form_link }}
              ></div>
            ),
          },
          {
            heading: "Application Fees",
            data: (
              <div
                dangerouslySetInnerHTML={{ __html: categoryWise.form_fees }}
              ></div>
            ),
          },
          {
            heading: "How to fill the form",
            data: (
              <div
                style={{ textTransform: "lowercase" }}
                dangerouslySetInnerHTML={{
                  __html: categoryWise.how_to_fill_form,
                }}
              ></div>
            ),
          },
          ...(categoryWise?.recommendation === 1
            ? [
                {
                  heading: "Remarks",
                  data: (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: categoryWise?.recommendation_remark,
                      }}
                    ></div>
                  ),
                },
              ]
            : []),
        ],
      })
    );
    setFilterData(formCategoryTable);
    setFormCategoryFillForm(formCategoryData);
  };

  const handleTabChange = (formCategoryId) => {
    const formCategory = getFormCategoryName(formCategoryId);
    setKeys(formCategory.category_name);
    showTabData(formCategory);
  };

  const gotoServices = (id) => {
    const element = document.getElementById(String(id));
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      console.error("Element with ID", id, "not found.");
    }
  };

  const formUpdateCallback = (formUpdate) => {
    const uniqueFormData = new Set();
    const formData = formUpdate?.data
      ?.filter((date) => {
        if (
          !uniqueFormData.has(date.university_id) &&
          ((Number(isSelectedDate) !== "Select Year" &&
            Number(date.form_session) === Number(isSelectedDate)) ||
            (Number(isSelectedDate) === "Select Year" &&
              Number(date.form_session) !== Number(isSelectedDate)))
        ) {
          uniqueFormData.add(date.university_id);
          return true;
        }
        return false;
      })
      ?.map((item, index) => {
        return [
          {
            value: index + 1,
            id: 1,
            className: "color_black text-center",
          },
          {
            value: item?.university?.forms[0]?.name,
            id: 2,
            className: "color_black whiteSpaceNormal",
          },
          {
            value: item?.start_dates?.map(
              (itm) =>
                `${itm?.start_date_remark !== null && itm?.start_date_remark} `
            ),
            id: 3,
            className: "color_black whiteSpaceNormal",
          },
          {
            value: item?.end_dates?.map(
              (itm) =>
                `${itm?.end_date_remark !== null && itm?.end_date_remark} `
            ),
            id: 4,
            className: "color_black whiteSpaceNormal",
          },
          {
            value: item?.exam_dates.map(
              (itm) =>
                `${itm?.exam_date_remark !== null && itm?.exam_date_remark} `
            ),
            id: 5,
            className: "color_black whiteSpaceNormal",
          },
          {
            value: (
              <div className="btn-group align-items-center">
                <span className="themeLink">{item?.notifiaction?.name}</span>
              </div>
            ),
            id: 6,
            className: "color_black whiteSpaceNormal",
          },
        ];
      });
    setFormData(formUpdate?.data);
    setFormUpdateData(formData);
  };

  const formCategoriesCallback = (formCategory) => {
    setTypes(
      formCategory.data
        .sort((a, b) => a.category_id - b.category_id)
        .map((d) => d)
    );
    setKeys(formCategory.data[0]?.category_name);
    showTabData(formCategory.data[0]);
  };

  useEffect(() => {
    const params = {
      year: isSelectedDate,
      org_id: userData.org_id,
      student:'yes'
    };
    getFormUpdates(params, formUpdateCallback);
    getFormCategories(params, formCategoriesCallback);
    //eslint-disable-next-line
  }, [isSelectedDate, userData]);

  return (
    <>
      <div className="white_box mb-4">
        <div className="row">
          <div className="col-md-9">
            <p className="global-para fw-500">Dear Students,</p>
          </div>
          <div className="col-md-3 text-end">
            <FormSelect
              formData={formData}
              formValue={isSelectedDate}
              onChangeForm={(e) => setIsSelectedData(e.target.value)}
            />
          </div>
        </div>
        <p className="global-para">
          Greetings {Number(userData.org_id) === 1 && "from"}{" "}
          {Number(userData.org_id) === 1 && (
            <span className="fw-500"> PRATHAM Test Prep!</span>
          )}{" "}
          In order to keep you updated with all the recent exam notifications
          for the major entrances, we are sending you the list of forms that
          have been released till date for admissions in {isSelectedDate}{" "}
          academic years:
        </p>
        <p className="global-para">
          <span className="fw-500"> Note:</span> This notification and the
          application form(s) are applicable for those students who will appear
          for entrance examination in {isSelectedDate}.
        </p>
        {/* <Button
          className="loginBtn small-btn"
          link="/home"
          id="logBtn"
          title={"Entrance Exam Pattern- 2022"}
          // disable={!isValid()}
        /> */}
      </div>
      <div className="blue-bg h-auto position-relative white-box mb-5">
        <div className="dash-heading">
          <h1 className="text-white text-center">
            Forms Status of the Universities
          </h1>
        </div>
        <FormReleasedTable formReleased={formUpdateData} />
      </div>
      <div className="white-box mb-5">
        <FormCategoryTabs
          activeKey={keys}
          onSelect={(k) => handleTabChange(k)}
          tabs={types}
          tabName={keys}
          categoryTableForm={filterData}
          categoryFormDetails={formCategoryFillForm}
        />
      </div>
      {Number(userData.org_id) === 1 && <FormHelplineDesk />}
      <FormModal isShow={instModal} setIsShow={setInstModal} />
    </>
  );
};

export default FormUpdates;
