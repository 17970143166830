import React from 'react';
import Webcam from 'react-webcam';
import Draggable from 'react-draggable';

const WebcamComponent = () => {
  const webcamRef = React.useRef(null);
  return (
    <>
    <Draggable>
    <div className="camera-component">
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={200}
        height={200}
      />
      </div>
      </Draggable>
    </>
  );
};

export default WebcamComponent;
