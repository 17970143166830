import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../shared/AppContext";
import {
  useReferEarnPsis,
  useReferEarnPsisList,
} from "../../../hooks/hookList";
import CopyIcon from "../../../assets/images/copy.svg";
import Image from "../../utils/image/Image";
import Table from "../../utils/table/Table";
import VerticalTable from "../../utils/table/VerticalTable";
import ViewIcon from "../../../assets/images/eys_view.png";
import { Modal } from "react-bootstrap";

const ReferEarn = () => {
  const { setSpinner, setToaster, userData } = useContext(AppContext);
  const [referEarnData, setReferEarnData] = useState([]);
  const [referLink, setReferLink] = useState(null);
  const [referralList, setReferralList] = useState([]);
  const [isOpenReferences, setIsOpenReferences] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);

  const { getData: getReferEarnPsis } = useReferEarnPsis(
    setSpinner,
    setToaster
  );

  const { getData: getReferEarnList } = useReferEarnPsisList(
    setSpinner,
    setToaster
  );

  const handleReferencesList = (data) => {
    const { success, data: referralData } = data;
    if (success) {
      if (!Array.isArray(referralData)) {
        setToaster({
          show: true,
          type: "warning",
          content: "Invalid Data",
        });
        return;
      }
      if (referralData.length === 0) {
        setToaster({
          show: true,
          type: "warning",
          content: "Referral List Empty",
        });
        return;
      }
      setReferralList(referralData);
    }
  };

  const maskNumber = (num) => {
    const numStr = num.toString();
    if (numStr.length < 4) {
      return numStr; // Handle numbers too short to mask
    }
    return numStr.slice(0, 2) + "XXXXX" + numStr.slice(7, 10);
  };

  const handleCopyClipboard = async () => {
    try {
      // Check if the referLink is valid
      if (!referLink) {
        setToaster({
          show: true,
          type: "error",
          content: "Failed to copy the link.",
        });
      }

      // Copy the URL to the clipboard
      await navigator.clipboard.writeText(referLink);
      setToaster({
        show: true,
        type: "success",
        content: "Link Copied Successfully",
      });
    } catch (error) {
      // Handle errors: either copying failed or URL is invalid
      setToaster({
        show: true,
        type: "error",
        content: "Failed to copy the link.",
      });
    }
  };
  useEffect(() => {
    if (userData.user_id) {
      getReferEarnPsis({ userId: userData?.user_id }, (referData) => {
        if (!Array.isArray(referData)) {
          setToaster({
            show: true,
            type: "warning",
            content:
              "Invalid input: Expected an array, but received a different data type.",
          });
          return;
        }
        if (referData.length === 0) {
          setToaster({
            show: true,
            type: "warning",
            content: "Referral data is not available ",
          });
          return;
        }
        setReferEarnData(referData);
        setReferLink(referData[0]?.refer_link);
      });
      getReferEarnList({ userId: userData?.user_id }, handleReferencesList);
    }
  }, [userData]);

  return (
    <div className="body-cont d-flex justify-content-center">
      <div
        className="d-flex flex-wrap flex-lg-nowrap justify-content-center"
        style={{
          background: "white",
          height: "80vh",
          overflow: "auto",
          width: "100%",
          borderRadius: "1.5rem",
          padding: "1rem",
        }}
      >
        {referEarnData.length > 0 && (
          <div className="refer_code_container d-flex flex-column align-items-center">
            {referEarnData.map((d) => {
              return (
                <>
                  <div>
                    <h4 className="mb-3 text-capitalize">
                      Scan QR Code or Share the Link with your Friends
                    </h4>
                  </div>
                  <div
                    className="refer_code"
                    dangerouslySetInnerHTML={{ __html: d.refer_code }}
                  />
                  <div className="my-3">
                    {/* <h5>Your Referral Link</h5> */}
                    <div className="w-100 d-flex flex-column flex-lg-row justify-content-center mt-4 mt-lg-0 gap-4 align-items-center">
                      <div className="refer_link">
                        <p className="p-0 m-0">{d.refer_link}</p>
                      </div>
                      <button
                        className="refer_link_button"
                        style={{
                          border: "none",
                          outline: "none",
                          padding: "0.6rem 1rem",
                          borderRadius: "0.6rem",
                          background: "#fe8057",
                          border: "0.1rem solid #dfe1e5",
                          color: "#fff",
                          minWidth: "10rem",
                        }}
                        onClick={handleCopyClipboard}
                      >
                        <Image
                          src={CopyIcon}
                          alt="copy-icon"
                          width={15}
                          height={15}
                          className="me-3"
                        />
                        Copy
                      </button>
                    </div>
                  </div>
                </>
              );
            })}
            <div className="refer_earn_description">
              <p className="mb-4">
                <b>Refer, Learn, and Earn with PRATHAM Test Prep</b>
              </p>
              <p className="mb-4">
                <b> Refer Your Friends</b> : Encourage your friends to join
                PRATHAM Test Prep
              </p>
              <p className="mb-4">
                <b> Learn Together </b>: Boost your preparation by studying
                alongside your friends.
              </p>
              <p className="mb-4">
                <b>Earn Rewards</b> : Enjoy benefits from PRATHAM when your
                friends enroll.
              </p>
              <p className="">
                Just like you, we want to see you and your friends succeed
                together on the path to acing entrance exams. Start referring
                and let the journey begin!
              </p>
            </div>
          </div>
        )}
        {referEarnData.length > 0 && (
          <div className="refer_terms_conditions">
            <div className={!isOpenReferences && "refer_terms"}>
              <div
                className={`d-flex flex-column-reverse flex-lg-row align-items-center ${
                  isOpenReferences
                    ? "justify-content-end"
                    : "justify-content-between"
                }`}
              >
                {!isOpenReferences && (
                  <h1 className="text-left mt-3 mt-lg-0 mb-3">
                    Terms & Conditions
                  </h1>
                )}
                <button
                  className="btn theme-btn small-btn m-0 green-btn"
                  onClick={() => setIsOpenReferences(!isOpenReferences)}
                >
                  {isOpenReferences ? "Go Back" : "My References"}
                </button>
              </div>
              {!isOpenReferences && (
                <div>
                  <p>
                    <b>Refer & Earn Program Policy</b>
                  </p>
                  <div>
                    <p>
                      <b>Eligibility:</b>
                    </p>
                    <ul>
                      <li>
                        <p>
                          The referral program is exclusively available for
                          PRATHAM's Enrolled Students.
                        </p>
                      </li>
                      <li>
                        <p>
                          Non-enrolled students are not eligible to participate
                          in this program.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p>
                      <b>How to Refer:</b>
                    </p>
                    <p>Referrals can be submitted in two ways:</p>
                    <ul>
                      <li>
                        <p>By filling out the referral form directly.</p>
                      </li>
                      <li>
                        <p>
                          By sharing the referral link or QR code with your
                          referrals to complete their details accurately.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p>
                      <b>Referral Guidelines:</b>
                    </p>
                    <ul>
                      <li>
                        <p>
                          A student can only be referred once by any enrolled
                          student.
                        </p>
                      </li>
                      <li>
                        <p>
                          The first individual to share the referral will be
                          credited with the referral.
                        </p>
                      </li>
                      <li>
                        <p>
                          Duplicate referrals cannot be added to the system. If
                          attempted, an error message will indicate that
                          duplicate referrals are not allowed.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p>
                      <b>Referral Nurturing and Enrollment Process:</b>
                    </p>
                    <ul>
                      <li>
                        <p>
                          Once a referral is shared, PRATHAM counselors will
                          connect with the referred student for counseling and
                          the enrollment process.
                        </p>
                      </li>
                      <li>
                        <p>
                          Upon successful registration of the referred student
                          and fulfillment of the minimum payment criteria, the
                          referring student’s reward will be processed.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p>
                      <b>Rewards:</b>
                    </p>
                    <ul>
                      <li>
                        <p>
                          Referring students will receive an Amazon voucher
                          worth ₹500 when the referred student completes the
                          registration and meets the payment criteria.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p>
                      <b>Reward Criteria:</b>
                    </p>
                    <ul>
                      <li>
                        <p>
                          For Crash Course & Extended Crash Course: Paid amount
                          must be ₹15,000 or more.{" "}
                        </p>
                      </li>
                      <li>
                        <p>
                          For Extended One Year, One Year, and Two-Year
                          programs: Paid amount must be ₹25,000 or more.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p><b>Reward Timeline:</b></p>
                    <ul>
                      <li>
                        Rewards will be provided within 60 days of your
                        referral's enrollment, subject to the completion of the
                        minimum payment criteria and adherence to PRATHAM's
                        terms of service.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p>
                      <b>Refund Policy:</b>
                    </p>
                    <ul>
                      <li>
                        <p>
                          In case of a refund, PRATHAM reserves the right to
                          deduct the reward amount from the referring student
                          along with applicable service charges as per PRATHAM's
                          refund policy.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p>
                      <b>Note:</b>
                    </p>
                    <ul>
                      <li>
                        <p>
                          Both the referring and referred students must complete
                          their respective programs.{" "}
                        </p>
                      </li>
                      <li>
                        <p>
                          All participants are required to adhere to PRATHAM’s
                          terms and conditions.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p>
                      For further details, please contact your service manager!
                    </p>
                  </div>
                </div>
              )}
              {isOpenReferences && (
                <div className="mt-5 refer_terms">
                  <Table
                    heading={[
                      {
                        id: 1,
                        value: "#",
                      },
                      {
                        id: 2,
                        value: "Name",
                      },
                      {
                        id: 3,
                        value: "Phone",
                      },
                      {
                        id: 4,
                        value: "Status",
                      },
                      {
                        id: 5,
                        value: "View",
                      },
                    ]}
                    rows={referralList.map((d, i) => {
                      return [
                        {
                          id: 1,
                          value: i + 1,
                        },
                        {
                          id: 2,
                          value: d.student_name,
                        },
                        {
                          id: 3,
                          value: maskNumber(d.mobile),
                        },
                        {
                          id: 4,
                          value: d.status,
                        },
                        {
                          id: 5,
                          value: (
                            <div className="d-flex justify-content-center cursor-pointer">
                              <Image
                                src={ViewIcon}
                                alt="view-icon"
                                width={25}
                                height={25}
                                onClick={() => {
                                  setIsModalOpen(true);
                                  setModalData([
                                    {
                                      heading: "Name",
                                      data: (
                                        <span className="">
                                          {d.student_name}
                                        </span>
                                      ),
                                    },
                                    {
                                      heading: "Email",
                                      data: <span>{d.email}</span>,
                                    },
                                    {
                                      heading: "Phone",
                                      data: <span>{maskNumber(d.mobile)}</span>,
                                    },
                                    {
                                      heading: "School",
                                      data: <span>{d.school_id}</span>,
                                    },
                                    {
                                      heading: "Class",
                                      data: <span>{d.class}</span>,
                                    },

                                    {
                                      heading: "Stream",
                                      data: <span>{d.stream}</span>,
                                    },
                                    {
                                      heading: "Status",
                                      data: (
                                        <span>
                                          {d.status}
                                          <br />
                                          {d.remark !== null && (
                                            <span>{d.remark}</span>
                                          )}
                                        </span>
                                      ),
                                    },
                                  ]);
                                }}
                              />
                            </div>
                          ),
                        },
                      ];
                    })}
                  />
                </div>

                // <ul className="list-group p-0 my-5">
                //   {referralList.map((d) => {
                //     return (
                //       <li className="list-group-item">{d.student_name}</li>
                //     );
                //   })}
                // </ul>
              )}
            </div>
          </div>
        )}
      </div>
      <Modal
        dialogClassName="refer-earn-dialog"
        size="sm"
        show={isModalOpen}
        backdrop="static"
        onHide={() => setIsModalOpen(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>References Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VerticalTable varticalData={modalData} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ReferEarn;
