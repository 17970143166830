import React, { useEffect, useContext, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { useForm } from "@kaydhiman/react-hook-useform";

import { AppContext } from "../../shared/AppContext";
import { useBreadcrumb } from "../../shared/breadcrumb-context";

import Table from "../../utils/table/Table";
import Button from "../../utils/forms/Button";
import SelectInput from "../../utils/forms/SelectInput";
import Auth from "../../../auth/Auth";
import Pagination from "react-js-pagination";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  useChaptersList,
  useDYTCreateTest,
  useDYTList,
  useSubjectsList,
  useTagList,
  useTagTypeList,
} from "../../../hooks/hookList";

const dytTableHeadings = [
  { value: "S.No", id: 1, className: "w-15" },
  { value: "Name", id: 2, className: "w-30" },
  {
    value: "Score",
    id: 3,
    className: "w-30",
  },
  { value: "Status", id: 4, className: "w-15" },
];

const DesignTest = () => {
  const location = useLocation();
  const { setSpinner, setToaster } = useContext(AppContext);
  const { values, setInitialValues } = useForm({ validations: {} });
  const { dispatch } = useBreadcrumb();
  const [tags, setTags] = useState([]);
  const [dytList, setDytList] = useState([]);
  const [total, setTotal] = useState();
  const [activePage, setActivePage] = useState(1);
  const userInfo = Auth?.user();

  // Get Subjects List
  const { getData: getSubjectsList } = useSubjectsList(setSpinner, setToaster);

  // Get Chapters List
  const { getData: getChaptersList } = useChaptersList(setSpinner, setToaster);

  // Get Tag Type List
  const { getData: getTagTypeList } = useTagTypeList(setSpinner, setToaster);

  // Get Tags List
  const { getData: getTagsList } = useTagList(setSpinner, setToaster);

  // Get DYT List
  const { getData: getDYTList } = useDYTList(setSpinner, setToaster);

  const { getData: createDYTTest } = useDYTCreateTest(setSpinner, setToaster);

  // Subjects List Callback
  const subjectsListCallback = (subjectListData) => {
    const subjectsList = subjectListData?.data?.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setInitialValues((prev) => ({ ...prev, subjects: subjectsList }));
  };

  // Chapters List Callback
  const chaptersListCallback = (chaptersData) => {
    const chaptersList = chaptersData?.data?.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setInitialValues((prev) => ({ ...prev, chapters: chaptersList }));
  };

  // DYT List Callback
  const dytListCallback = (dytListData) => {
    const dytList = dytListData?.data?.data?.map((dyt, index) => {
      return [
        { value: dytListData?.data?.from + index, id: 1 },
        { value: dyt?.name, id: 2 },
        { value: dyt?.score ? dyt?.score : "N/A", id: 3 },
        {
          value: (
            <div className="btn-data">
              {dyt?.dyt_status === 0 && (
                <Link
                  className="btn theme-btn  small-btn ms-3"
                  to={"/design_your_test/test-design-report/" + dyt.id}
                  state={{
                    back_url: "design_your_test",
                  }}
                  target="_blank"
                >
                  View Report
                </Link>
              )}
              {[2, 3].includes(Number(dyt?.dyt_status)) && (
                <React.Fragment>
                  <Link
                    className="btn yellow-btn small-btn ms-3 weight-500"
                    to={"/test_design_layout/" + dyt.id}
                    target="_blank"
                    onClick={() => {
                      localStorage.setItem(
                        "testName",
                        JSON.stringify({
                          subject: dyt?.subject?.name,
                          topic: dyt?.topic?.name,
                        })
                      );
                    }}
                    state={{
                      back_url: location?.pathname,
                    }}
                  >
                    Take Test
                  </Link>
                </React.Fragment>
              )}

              {dyt?.dyt_status === 1 && (
                <React.Fragment>
                  <Link
                    className="btn yellow-btn small-btn ms-3 weight-500"
                    to={"/test_design_layout/" + dyt.id}
                    target="_blank"
                    onClick={() => {
                      localStorage.setItem(
                        "testName",
                        JSON.stringify({
                          subject: dyt?.subject?.name,
                          topic: dyt?.topic?.name,
                        })
                      );
                    }}
                  >
                    Resume Test
                  </Link>
                </React.Fragment>
              )}
            </div>
          ),
          id: 4,
        },
      ];
    });
    setDytList(dytList);
    setTotal(dytListData?.data?.total);
    setInitialValues((prev) => ({
      ...prev,
      submitted_tests: dytListData?.data?.submitted_tests,
      total_tests: dytListData?.data?.total_tests,
    }));
  };

  // Tags List Callback
  const tagListCallback = (tagListData) => {
    setTags(tagListData.data);
  };

  // Tag Type List Callback
  const tagTypeListCallback = async (tagTypesData) => {
    const questionId = tagTypesData.data.filter(
      (itm) => itm?.name === "Question"
    )[0]?.id;
    await getTagsList({ tag_type_id: questionId }, tagListCallback);
  };

  // Create DYT Test
  const createDYTTestHandler = async (e) => {
    e.preventDefault();
    const params = {
      subject_id: values?.subject,
      subject_name: values?.name,
      topic_id: values?.chapter,
      tag_id: tags?.find((a) => a?.name.toLowerCase()?.includes("dyt"))?.id,
    };
    const dytListParams = {
      page: 1,
      size: 10,
    };
    await createDYTTest(params);
    await getDYTList(dytListParams, dytListCallback);
    setInitialValues((prev) => ({
      ...prev,
      chapter: "",
      subject: "",
      chapter_id: "",
      subject_id: "",
    }));
  };

  const onPageChange = (e) => {
    let pageNo = e;
    const params = {
      page: pageNo,
      size: 10,
    };
    getDYTList(params, dytListCallback);
    setActivePage(pageNo);
  };

  useEffect(() => {
    const dytListParams = {
      page: 1,
      size: 10,
    };
    Promise.all([
      getDYTList(dytListParams, dytListCallback),
      getTagTypeList({}, tagTypeListCallback),
      getSubjectsList({}, subjectsListCallback),
    ]);
    // getTagList();
    dispatch({
      type: "SET_BREADCRUMB",
      crumb: [{ title: "Design Your Test", url: "/design_your_test" }],
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (values?.subject) {
      setInitialValues((prev) => ({
        ...prev,
        chapter_id: "Select",
        chapter: "Select",
      }));
      const chapterParams = {
        subject_id: values?.subject,
        dyt_topics: "yes",
      };
      getChaptersList(chapterParams, chaptersListCallback);
    }
    //eslint-disable-next-line
  }, [values?.subject]);

  useEffect(() => {
    if (values?.subject && values?.chapter && userInfo) {
      setInitialValues((prev) => ({
        ...prev,
        name:
          values?.subject_id
            ?.split(" ")
            .map((word) => word.charAt(0))
            .join("") +
          "-" +
          values.chapter_id
            ?.split(" ")
            .map((word) => word.charAt(0))
            .join(""),
      }));
    }

    //eslint-disable-next-line
  }, [values?.subject_id && values?.chapter_id]);

  return (
    <div className="test-design-dash fixed-dash">
      <div className="white-box body-cont  ">
        <div className="test-design-header sticky-dash-header  py-5">
          <form onSubmit={createDYTTestHandler}>
            <div className="row border-bottom-dashed mb-5  align-items-center">
              <div className="col-lg-3 col-md-4 adminForm">
                <SelectInput
                  label="Subject"
                  id="subject_id"
                  name="subject"
                  data={values?.subjects || []}
                  setInitialValues={setInitialValues}
                  values={values}
                  title="Select"
                />
              </div>
              <div className="col-lg-3 col-md-4 adminForm">
                <SelectInput
                  label="Chapter"
                  id="chapter_id"
                  name="chapter"
                  data={values?.chapters || []}
                  setInitialValues={setInitialValues}
                  values={values}
                  title="Select"
                />
              </div>
              <div className="col-lg-2 col-md-3 align-items-center adminForm">
                <Button title="Create" className="small-btn mt-0 mb-4 w-100" />
              </div>
            </div>
          </form>

          <div className="dash-heading mb-0">
            <h1>
              Previous Tests
              <span className="text-count">
                ({values?.submitted_tests}/{values?.total_tests})
              </span>
            </h1>
          </div>
        </div>
        <div className="sub-report">
          <Table heading={dytTableHeadings} rows={dytList} />
          {total >= 10 && (
            <Pagination
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={total}
              pageRangeDisplayed={5}
              onChange={(e) => onPageChange(e)}
              hideFirstLastPages={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DesignTest;
