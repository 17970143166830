import React from "react";
import Image from "../image/Image";

const ReportCards = ({
  image,
  result,
  title,
  className,
  isSpeed,
  subTitle,
  isSubHeading,
  sub_heading,
  isMinute,
}) => {
  return (
    <>
      <div className={`attendence_box d-flex ${className}`}>
        {image ? (
          <div className="att_icon">
            <Image src={image} alt="liveClass" />
          </div>
        ) : (
          ""
        )}

        <div className="att_name">
          <h5>{title}</h5>
          <span>
            {result}
            {/* {isSpeed && <span className="small-cont">/question</span>}
            {isMinute && <span className="small-cont">Minutes</span>} */}

            {subTitle && <h5>{subTitle}</h5>}
            {sub_heading && (
              <div className="att_icon">
                <h5>{sub_heading}</h5>
              </div>
            )}
            {/* {subTitle ? <h5>{subTitle}</h5> : ""} */}
          </span>
        </div>
      </div>
    </>
  );
};

export default ReportCards;
