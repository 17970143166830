import React from "react";
import SearchIcon from "../../../assets/images/search_black.png";
import "../../projectDashboard/searchResource/search.scss";
import Image from "../image/Image";

const Search = ({ onChange, placeholder, value }) => {
  return (
    <div className="search_bar w-100 ">
      <div className="position-relative">
        <input
          style={{ fontSize: "14px" }}
          className="w-100"
          type="search"
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
        <Image
          src={SearchIcon}
          width={20}
          height={20}
          className="position-absolute search_icon"
          alt="search-icon"
        />
      </div>
    </div>
  );
};

export default Search;
