import React, { useEffect } from "react";
import { useForm } from "@kaydhiman/react-hook-useform";
import Pagination from "react-js-pagination";

import Button from "../../utils/forms/Button";
import Table from "../../utils/table/Table";

import view_icon from "../../../assets/images/eys_view.png";
import edit_icon from "../../../assets/images/edit.png";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../shared/AppContext";
import { userService } from "../../../_services/User.services";
import { useState } from "react";
import CommonModal from "../../utils/modal/CommonModal";
import { CONSTANT } from "../../utils/constants";
import { useBreadcrumb } from "../../shared/breadcrumb-context";
import Image from "../../utils/image/Image";

const FormWallet = () => {
  const { values, setInitialValues } = useForm({ validations: {} });

  const { setSpinner, setToaster } = useContext(AppContext);
  const navigate = useNavigate();
  const [walletdata, setWalletData] = useState([]);
  const [total, setTotal] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [loginDetail, setLoginDetail] = useState(false);
  const [resultDetail, setResultDetail] = useState(false);
  const { dispatch } = useBreadcrumb();

  const handleLoginModal = (data) => {
    setInitialValues((prev) => ({
      ...prev,
      username: data?.username,
      password: data?.password,
    }));
    setLoginDetail(true);
  };

  const getWallets = async (pageNo) => {
    let currentPage = pageNo === undefined ? 1 : pageNo;
    setSpinner(true);
    try {
      const response = await userService?.getFormWallets({
        page: currentPage,
        size: 10,
      });
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();

        setWalletData(
          data?.data?.data?.map((item, index) => {
            return [
              { value: index + 1, id: 1 },
              { value: item?.university?.name, id: 2 },
              { value: item?.campus?.name, id: 3 },
              {
                value: item?.wallet_courses?.map(
                  (elem, index) => (index ? ", " : " ") + elem?.courses?.name
                ),
                id: 4,
              },
              { value: "Not Selected", id: 4 },
              {
                value: (
                  <div className="btn-group align-items-center">
                    <Image
                      src={view_icon}
                      alt="view-icon"
                      className="pointer"
                      onClick={() => setResultDetail(true)}
                    />
                  </div>
                ),
                id: 4,
              },
              {
                value: (
                  <div className="btn-group align-items-center">
                    <Image
                      src={view_icon}
                      alt="view-icon"
                      className="pointer"
                      onClick={() => handleLoginModal(item)}
                    />
                  </div>
                ),
                id: 7,
              },
              {
                value: (
                  <div className="btn-group align-items-center">
                    <Link to={`/forms/update_wallet/${item?.id}`}>
                      <Image src={edit_icon} alt="edit-icon" />
                    </Link>
                  </div>
                ),
                id: 7,
              },
            ];
          })
        );

        setTotal(data.data.total);
        setSpinner(false);
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setSpinner(false);
        setToaster({ show: true, type: "danger", content: data.message });
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  const onPageChange = (e) => {
    let pageNo = e;
    setActivePage(pageNo);
    getWallets(pageNo);
  };

  useEffect(() => {
    getWallets();
    dispatch({
      type: "SET_BREADCRUMB",
      crumb: [{ title: "Form Wallet", url: "/forms/form_updates/form_wallet" }],
    });
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        className="white-box mb-5"
        style={{ height: walletdata.length === 0 && "60vh" }}
      >
        <div className="row">
          <div className="col-md-9">
            <div className="dash-heading">
              <h1 className="">Applied Universities/Courses</h1>
            </div>
          </div>
          <div className="col-md-3 text-end">
            <Link
              className="btn theme-btn small-btn mt-0"
              to="/forms/add_form_wallet"
              id="logBtn"
              title={"Add"}
              // disable={!isValid()}
            >
              Add
            </Link>
          </div>
        </div>
        {walletdata.length > 0 && (
          <Table
            className=""
            heading={[
              { value: "Sr. No", id: 1, className: "title-data w-10" },
              { value: "University", id: 2, className: "w-15" },
              { value: "Campus", id: 3, className: "w-15" },
              { value: "Applied Course", id: 4, className: "w-15" },
              { value: "Result Status", id: 5, className: "w-15" },
              { value: "Result", id: 7, className: "w-5" },
              { value: "Login Details", id: 7, className: "w-10" },
              { value: "Action", id: 7, className: "w-10" },
            ]}
            rows={walletdata}
          />
        )}
        {walletdata.length === 0 && (
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <p className="fs-2 d-block">
              You have not saved any form information.
            </p>
            <p className="fs-2 d-block">Click Here to add new form details.</p>
            <Button
              className="btn theme-btn small-btn mt-0"
              title="Add New Form"
              onClick={(e) => {
                e.preventDefault();
                navigate("/forms/add_form_wallet");
              }}
            />
          </div>
        )}
        {total >= 10 ? (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={total}
            pageRangeDisplayed={5}
            onChange={(e) => onPageChange(e)}
            hideFirstLastPages={true}
          />
        ) : (
          ""
        )}
      </div>
      {/* login detail modal */}
      <CommonModal
        show={loginDetail}
        className="detail-modal"
        close={() => setLoginDetail(false)}
        body={
          <>
            <div className="dash-heading text-center">
              <h2 className="text-center mb-3">Login Details</h2>
            </div>
            <div className="gray-bg">
              <div className="w-100 border-bottom-dashed">
                <span className="detail-heading text-start">Username:</span>
                <span className="detail-body float-end">
                  {values?.username}
                </span>
              </div>
              <div className="w-100">
                <span className="detail-heading text-start">Password:</span>
                <span className="detail-body float-end">
                  {values?.password}
                </span>
              </div>
            </div>
          </>
        }
      />
      {/* result detail modal */}
      <CommonModal
        show={resultDetail}
        className="detail-modal"
        close={() => setResultDetail(false)}
        body={
          <>
            <div className="dash-heading text-center">
              <h2 className="text-center mb-3">Results</h2>
            </div>
            <div className="gray-bg">
              <div className="w-100">
                <span className="detail-heading text-start">Score</span>
                <span className="detail-body float-end">19240006</span>
              </div>
              <div className="w-100">
                <span className="detail-heading text-start">Rank</span>
                <span className="detail-body float-end">
                  e1b71b13d7f031f17623a0973d31c4b9
                </span>
              </div>
              <div className="w-100">
                <span className="detail-heading text-start">
                  Interested Courses
                </span>
                <span className="detail-body float-end">
                  e1b71b13d7f031f17623a0973d31c4b9
                </span>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default FormWallet;
