import React from "react";
import { Link } from "react-router-dom";
import Image from "../../utils/image/Image";

const CourseImage = ({ icon, defaultImage, courseId, folderId }) => {
  return (
    <div className="content_logo coursor-pointer">
      <Link to={`/course/${courseId}/folder/${folderId}`}>
        <Image
          style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px" }}
          className="content_logo_image"
          src={
            icon !== null
              ? icon
              : "https://www.prathamonline.com/files/default_image.jpg"
          }
          alt="content-logo"
        />
      </Link>
    </div>
  );
};

export default CourseImage;
