import React from "react";
import email from "../../../../assets/images/email.png";
import { useNavigate, NavLink } from "react-router-dom";
import Image from "../../../utils/image/Image";

const HeaderNotifications = ({
  mobileIcon,
  desktopIcon,
  notificationList,
  setIsOpenNotification,
  isOpenNotification,
  notificationRef,
  handleClickNotification,
}) => {
  const navigate = useNavigate();
  return (
    <div className="notification" ref={notificationRef}>
      <div
        className="notification_container"
        onClick={async () => {
          await handleClickNotification();
        }}
      >
        <Image
          className="mobile_notification_icon"
          src={mobileIcon}
          alt="notification-icon"
        />
        <Image
          className="desktop_notification_icon"
          src={desktopIcon}
          alt="notification-icon"
        />
      </div>
      {isOpenNotification && (
        <div className="notification_list">
          <div className="notification_title">
            <h4 className="m-0 p-0">Announcements</h4>
          </div>
          <div className="notification_all_list">
            {notificationList?.map((note, index) => {
              return (
                <div
                  className="notification_note d-flex"
                  onClick={() => {
                    navigate("/notification", {
                      state: `notification-${index + 1}`,
                    });
                    setIsOpenNotification(false);
                  }}
                >
                  <div className="notification_message_container">
                    <div className="email_icon_container">
                      <Image src={email} alt="notification-icon" />
                    </div>
                  </div>

                  <div
                    className="notification_message"
                    dangerouslySetInnerHTML={{ __html: note.message }}
                  />
                </div>
              );
            })}
          </div>

          <div
            className="notification_bottom"
            onClick={() => {
              navigate("/notification");
              setIsOpenNotification(false);
            }}
          >
            <h4>View All Announcements</h4>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderNotifications;
