import React from "react";
import CommonModal from "../../utils/modal/CommonModal";

const FormModal = ({ isShow, setIsShow }) => {
  return (
    <CommonModal
      show={isShow}
      className="form-modal"
      close={() => setIsShow(false)}
      body={
        <div className="white_box pt-1 pb-4 ps-4">
          <div className="dash-heading text-center">
            <h2 className="text-center mb-2">Important Notice</h2>
          </div>
          <div className="modal-content-area">
            <ol>
              <li>
                <p className="global-para">
                  PRATHAM will assist students & parents in form filling by
                  providing Form Filling PPT & recordings however the onus &
                  responsibility of filling the form correctly and before the
                  last date of the application lies solely with the Student and
                  Parent.
                </p>
              </li>
              <li>
                <p className="global-para">
                  Parents are requested not to share any personal details with
                  any PRATHAM employee to fill the student application forms. If
                  shared PRATHAM will not be held responsible in case of
                  incorrect form filling/ incorrect course/program selected or
                  delay in submission of forms.
                </p>
              </li>
              <li>
                <p className="global-para">
                  All Parents and Students are requested to save a copy of the
                  Application Form filled by them and ensure to save the
                  application User ID and Password in PRATHAM FORM WALLET after
                  each form filled.
                </p>
              </li>
              <li>
                <p className="global-para">
                  Students and Parents are requested to track the last date of
                  University applications as they are subject to change.
                </p>
              </li>
              <li>
                <p className="global-para">
                  You are requested to go through the University Bulletin
                  thoroughly before filling any University Application Form.
                </p>
              </li>
            </ol>
          </div>
          {/* <Button
        className="loginBtn"
        link="/home"
        id="logBtn"
        title={"Continue"}
        onClick={() => setInstModal(false)}
        // disable={!isValid()}
      /> */}
        </div>
      }
    />
  );
};

export default FormModal;
