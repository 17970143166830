import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../shared/AppContext";
import { NewBackButton } from "../../utils/backBtn/NewBackButton";
import {
  useNewShortcutDetails,
  useOMRFileUpload,
  useViewResourceType,
} from "../../../hooks/hookList";
import { getResourceType } from "../../../hooks/useResourceType";
import RightSidebar from "../portalLayout/RightSidebar";
import ResourceGroup from "../dashboardShortcut/ResourceGroup";
import ShortcutCard from "../dashboardShortcut/ShortcutCard";
import Button from "../../utils/forms/Button";
import ResourceViewCard from "../dashboardShortcut/ResourceViewCard";
import { getUniqueItems } from "../../utils/helpers/getUniqueItems";
import { pdfjs } from "react-pdf/dist/esm/entry.webpack";
import Image from "../../utils/image/Image";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const mapItems = (items, type) => {
  return items ? items.map((item) => ({ type, ...item })) : [];
};

const ShortcutDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { shortcutId } = useParams();

  const { setSpinner, setToaster, userData, spinner } = useContext(AppContext);

  const [shortcutName, setShortcutName] = useState();
  const [shortcutDetailsData, setShortcutDetailsData] = useState([]);
  const [groupBy, setGroupBy] = useState();
  const [resourceType, setResourceType] = useState();
  const [visibleCards, setVisibleCards] = useState(9);
  const [showAll, setShowAll] = useState(false);

  const [examData, setExamData] = useState({});
  const [liveClassData, setLiveClassData] = useState({});
  const [documentData, setDocumentData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [openItems, setOpenItems] = useState([
    { title: "documents", level: 0 },
  ]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewFile, setPreviewFile] = useState("");
  const [isModalOpenOMR, setIsModalOpenOMR] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [studentId, setStudentId] = useState(null);
  // Get Shortcut Details
  const { getData: getNewShortcutDetails, data: newShortcutDetails } =
    useNewShortcutDetails(setSpinner, setToaster);

  // Get the Resource Data
  const { data: resourceItem, getData: getResourceTypeItems } =
    useViewResourceType(setSpinner, setToaster);

  const { getData: handleUploadOMR } = useOMRFileUpload(setSpinner, setToaster);

  const hasAnyResourceData = [examData, documentData, videoData].some(
    (dataArray) => dataArray.length > 0
  );

  const resourceGroups = [
    { data: documentData, resourceType: "documents" },
    { data: videoData, resourceType: "videos" },
    { data: examData, resourceType: "exams" },
  ];

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const shouldRenderViewCard =
    shortcutId &&
    hasAnyResourceData &&
    newShortcutDetails?.shortcut_details?.is_active === 1;

  const uniqueResourceItems = getUniqueItems(resourceItem);

  const handleViewMore = () => {
    setVisibleCards((prev) => prev + 9);
  };

  const handleViewLess = () => {
    setVisibleCards(9);
    setShowAll(false);
  };

  const batchType =
    userData && userData?.batches !== undefined
      ? userData?.batches[userData?.batches?.length - 1]?.batch_type
      : null;

  const resourceTypeParams = (value, resourceType) => {
    const params = {
      dashboard_shortcut_id: shortcutId,
      resource_type_id: getResourceType(resourceType),
      search_by_key: groupBy?.split(",").map((item) => item?.split("_")[0]),
      search_by_value:
        value !== null ? value?.slice(1)?.map((d) => d?.title) : "exams",
    };
    return params;
  };

  const openInitialItems = (resourceTypeData, resourceType) => {
    if (resourceTypeData[0]?.data?.length === 0) {
      setOpenItems((prevItem) => [
        ...prevItem,
        { title: resourceType, levle: 0 },
      ]);
      const params = {
        dashboard_shortcut_id: shortcutId,
        resource_type_id: getResourceType(resourceType),
        search_by_key: groupBy?.split(",").map((item) => item?.split("_")[0]),
        search_by_value: [resourceType],
      };

      getResourceTypeItems(params);
    }
    if (resourceTypeData && resourceTypeData?.length > 0) {
      const firstDocument = resourceTypeData[0]?.data[0];
      let parentTitle;
      let firstChildTitle;
      if (firstDocument) {
        parentTitle = firstDocument?.title;
        if (firstDocument?.child) {
          firstChildTitle = firstDocument?.child[0]?.title;
        }
        setOpenItems((prevItem) => [
          ...prevItem,
          { title: resourceType, levle: 0 },
          { title: parentTitle, level: 1 },
          { title: firstChildTitle, level: 2 },
        ]);
        const params = {
          dashboard_shortcut_id: shortcutId,
          resource_type_id: getResourceType(resourceType),
          search_by_key: groupBy?.split(",").map((item) => item?.split("_")[0]),
          search_by_value:
            firstChildTitle !== undefined
              ? [parentTitle, firstChildTitle]
              : [parentTitle],
        };

        getResourceTypeItems(params);
      }
    }
  };

  const toggleItem = (item, level, resourceType) => {
    if (typeof item === "object") {
      setOpenItems((prevOpenItems) => {
        const isOpen = prevOpenItems.some(
          (prevItem) =>
            prevItem.title === item.title && prevItem.level === level
        );

        if (!isOpen) {
          // Remove any items with a higher level
          const filteredItems = prevOpenItems.filter(
            (prevItem) => prevItem.level < level
          );

          // Add the clicked item to the state
          const updatedItems = [...filteredItems, { title: item.title, level }];

          // Check if the clicked item is a last child
          const isLastChild = !item.child || item.child.length === 0;

          // Trigger the onClickResource function only for last child

          if (isLastChild && updatedItems?.length > 1) {
            getResourceTypeItems(
              resourceTypeParams(updatedItems, resourceType)
            );
          }

          return updatedItems;
        } else {
          // If the clicked item is already open, remove it from the state
          return prevOpenItems.filter(
            (prevItem) =>
              !(prevItem.title === item.title && prevItem.level === level)
          );
        }
      });
    } else {
      if (["liveClass", "exams"].includes(item)) {
        getResourceTypeItems(resourceTypeParams(null, resourceType));
      }
      setOpenItems([{ title: item, level: 0 }]);
    }
  };

  const handleOnClickDashboardShortcut = () => {
    navigate(-1);
  };

  useEffect(() => {
    getNewShortcutDetails({ id: shortcutId });
    const studentInfo = localStorage?.getItem("studentInfo");
    const parsedStudentInfo = studentInfo ? JSON?.parse(studentInfo) : null;
    setStudentId(parsedStudentInfo.id);
    //eslint-disable-next-line
  }, [shortcutId]);

  useEffect(() => {
    setShortcutName(newShortcutDetails?.parent_shortcut_name);
    setGroupBy(newShortcutDetails?.shortcut_details?.group_by);
    const externalLinks = mapItems(
      newShortcutDetails?.external_link,
      "external_link"
    );
    const folders = mapItems(newShortcutDetails?.folder, "folder");
    const linkedCourses = mapItems(
      newShortcutDetails?.link_course_to_folder,
      "link_course_to_folder"
    );
    setShortcutDetailsData(
      [...externalLinks, ...folders, ...linkedCourses].sort(
        (a, b) => a.display_order - b.display_order
      )
    );
    const filterAndSetData = (data, setter) => {
      const filteredData = data?.filter((item) => item.total_count > 0) ?? [];
      setter(filteredData);
    };

    const dataMappings = [
      { data: newShortcutDetails?.document, setter: setDocumentData },
      { data: newShortcutDetails?.video, setter: setVideoData },
      { data: newShortcutDetails?.exams, setter: setExamData },
      { data: newShortcutDetails?.live_classes, setter: setLiveClassData },
    ];

    dataMappings.forEach(({ data, setter }) => filterAndSetData(data, setter));
  }, [newShortcutDetails]);

  useEffect(() => {
    const dataArrays = [
      { data: documentData, type: "documents" },
      { data: videoData, type: "videos" },
      { data: liveClassData, type: "liveClass" },
      { data: examData, type: "exams" },
    ];
    // Find the first non-empty data array and set the resource type
    for (const { data, type } of dataArrays) {
      if (data && data.length > 0) {
        openInitialItems(data, type);
        setResourceType(type);
        break; // Exit the loop once the first non-empty data array is found
      }
    }
  }, [documentData, videoData, liveClassData, examData]);

  return (
    <div className="col-md-12 d-flex gap-4 courses">
      <div className="white-box body-cont">
        <div
          className="w-100 d-flex align-items-center mb-4"
          title="Back to the previous page"
        >
          <NewBackButton onClick={handleOnClickDashboardShortcut} />
          <h1 className="exam_instruction_heading text-capitalize mb-0">
            {location?.state?.folder_name
              ? location?.state?.folder_name
              : shortcutName}
          </h1>
        </div>
        {shortcutDetailsData.length > 0 && (
          <div className="sub-report card-report recourse-table">
            <div className="live_class border-top-dashed border-bottom-dashed py-4">
              <div className="row">
                {batchType !== null &&
                  shortcutDetailsData
                    .filter((d) => d.is_active === 1)
                    .sort((a, b) => a.display_order - b.display_order)
                    .map((d) => {
                      if (
                        ![0, null].includes(Number(batchType)) &&
                        ![0].includes(Number(d?.batch_type)) &&
                        Number(batchType) === Number(d?.batch_type)
                      ) {
                        return (
                          <div className="col-md-3" key={d.id}>
                            <ShortcutCard item={d} batchType={batchType} />
                          </div>
                        );
                      } else if (
                        (batchType !== null && Number(batchType) === 0) ||
                        Number(d.batch_type) === 0
                      ) {
                        return (
                          <div className="col-md-3" key={d.id}>
                            <ShortcutCard item={d} batchType={batchType} />
                          </div>
                        );
                      }
                    })}
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-4">
            {resourceGroups.map(({ data, resourceType }) => {
              if (
                shortcutId &&
                data &&
                data?.length > 0 &&
                newShortcutDetails?.shortcut_details?.is_active === 1
              ) {
                if (
                  Number(batchType) !== 0 &&
                  Number(batchType) ===
                    Number(newShortcutDetails?.shortcut_details?.batch_type)
                ) {
                  return (
                    <ResourceGroup
                      key={resourceType}
                      data={data}
                      resourceType={resourceType}
                      toggleItem={toggleItem}
                      openItems={openItems}
                      setResourceType={setResourceType}
                    />
                  );
                } else if (
                  Number(batchType) === 0 ||
                  Number(newShortcutDetails?.shortcut_details?.batch_type) === 0
                ) {
                  return (
                    <ResourceGroup
                      key={resourceType}
                      data={data}
                      resourceType={resourceType}
                      toggleItem={toggleItem}
                      openItems={openItems}
                      setResourceType={setResourceType}
                    />
                  );
                }
              }
            })}
          </div>
          {shouldRenderViewCard && (
            <div className="col-md-8">
              {!spinner && (
                <div className="" style={{ padding: "10px 0" }}>
                  <div className="row">
                    {resourceItem &&
                      uniqueResourceItems
                        ?.slice(0, visibleCards)
                        ?.map((d) => (
                          <ResourceViewCard
                            card={d}
                            setSelectedFile={setSelectedFile}
                            selectedFile={selectedFile}
                            setPreviewFile={setPreviewFile}
                            previewFile={previewFile}
                            setIsModalOpen={setIsModalOpenOMR}
                            isModalOpen={isModalOpenOMR}
                            setToaster={setToaster}
                            pageNumber={pageNumber}
                            onDocumentLoadSuccess={onDocumentLoadSuccess}
                            handleUploadOMR={handleUploadOMR}
                            studentId={studentId}
                          />
                        ))}
                    {/* {hasAnyResourceData &&
                        resourceItem &&
                        resourceItem?.length === 0 && (
                          <div className="d-flex justify-content-center align-items-center">
                            <Image
                              className="no_data_img"
                              src={NoData}
                              alt="no-data-icon"
                            />
                          </div>
                        )}*/}
                  </div>
                  {resourceItem &&
                    resourceItem?.length > visibleCards &&
                    (resourceItem?.length === visibleCards ? (
                      <Button
                        className="btn small-btn theme-btn"
                        title="View Less"
                        onClick={handleViewLess}
                      />
                    ) : (
                      <Button
                        className="btn small-btn theme-btn"
                        title="View More"
                        onClick={handleViewMore}
                      />
                    ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="sidebar">
        <RightSidebar />
      </div>
    </div>
  );
};

export default ShortcutDetails;
