// React imports
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// Component imports
import Button from "../../utils/forms/Button";
import Table from "../../utils/table/Table";
import Pagination from "react-js-pagination";
import RightSidebar from "../portalLayout/RightSidebar";
import ContentCard from "./ContentCard";

// Context and hook imports
import { AppContext } from "../../shared/AppContext";
import { useBreadcrumb } from "../../shared/breadcrumb-context";
import {
  useCourseContentDetails,
  useLatestResource,
} from "../../../hooks/hookList";

// Asset imports
import Content1 from "../../../assets/images/pratham-image.png";

// Custom component imports
import HomeAssignmentStatusButton from "../homeAssignmentLatest/HomeAssignmentStatusButton";
import MockTestButton from "../test/MockTestButton";

// Style import
import "./content.scss";

const Content = () => {
  const { setSpinner, setToaster } = useContext(AppContext);
  const navigate = useNavigate();
  const { dispatch } = useBreadcrumb();
  const [packageNames, setPackageNames] = useState([]);
  const [courseName, setCourseName] = useState("");
  const [courseDetails, setCourseDetails] = useState([]);
  const [isOpenDescription, setIsOpenDescription] = useState(null);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isShowNewResource, setIsShowNewResource] = useState(false);
  const [latestResourceData, setLatestResourceData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [courseId, setCourseId] = useState(null);
  const [resultData, setResultData] = useState({
    from: "",
    to: "",
    total: "",
    last_page: "",
  });

  // Get Course Details
  const { getData: getCourseContentDetails, data: courseContentData } =
    useCourseContentDetails(setSpinner, setToaster);

  // Get Latest Resource
  const { getData: getLatestResource } = useLatestResource(
    setSpinner,
    setToaster
  );

  const latestResourceCallback = (resourceData) => {
    setActivePage(resourceData?.data?.from);
    setResultData({
      from: resourceData?.data?.from,
      to: resourceData?.data?.to,
      total: resourceData?.data?.total,
      last_page: resourceData?.data?.last_page,
    });
    setLatestResourceData(resourceData?.data.data);
  };

  const cssClasses = [
    "border_pale_blue",
    "border_melrose",
    "border_pink_sherbet",
    "border_light_turquoise",
    "border_basket_ball_orange",
    "border_sahara_sand",
    "border_jellyfish",
    "border_pixie_green",
    "border_cadet_grey",
  ];

  const getRandomIndex = (max) => {
    return Math.floor(Math.random() * max);
  };
  const toggleDescription = (courseId) => {
    const findCourseCard = courseDetails?.find((d) => d.course_id === courseId);
    setIsOpenDescription(findCourseCard.course_id);
    setIsFlipped(true);
  };

  const handleLatestResource = async (courseId) => {
    const params = {
      course_id: courseId,
    };
    setCourseId(courseId);
    getLatestResource(params, latestResourceCallback);
  };

  useEffect(() => {
    getCourseContentDetails();
    dispatch({
      type: "SET_BREADCRUMB",
      crumb: [{ title: "Content", url: "/content" }],
    });
  }, []);

  useEffect(() => {
    setPackageNames(courseContentData?.package_name);
    setCourseDetails(courseContentData?.course_details);
  }, [courseContentData]);

  return (
    <div className="col-md-12 d-flex gap-4 courses">
      <div className="white-box body-cont">
        <div>
          <h2 className="subscription_container">Course Content</h2>
        </div>
        <div className="package_container">
          {packageNames?.map((d, index) => (
            <React.Fragment key={index}>
              <span
                style={{
                  marginRight: "10px",
                  marginLeft: index !== 0 && "10px",
                }}
              >
                {d?.name}
              </span>
              {index !== packageNames?.length - 1 && "|"}
            </React.Fragment>
          ))}
        </div>
        <div className="content_container">
          <div className="row">
            {courseDetails?.map((d) => {
              const randomIndex = getRandomIndex(cssClasses.length);
              const randomClass = cssClasses[randomIndex];
              return (
                <div className={`col-md-4 mb-4`} key={d.course_id}>
                  <ContentCard
                    title={d.course_name}
                    courseId={d.course_id}
                    folderId={d.parent_folder_id}
                    description={d.course_description}
                    icon={d.course_icon}
                    defaultImage={Content1}
                    resourceCount={d.total_resource_count}
                    newResourceCount={d.new_resource_count}
                    className={randomClass}
                    isOpenDescription={isOpenDescription}
                    toggleDescription={toggleDescription}
                    setIsOpenDescription={setIsOpenDescription}
                    setIsFlipped={setIsFlipped}
                    isFlipped={isFlipped}
                    setIsNewResource={setIsShowNewResource}
                    onClickResource={handleLatestResource}
                    setCourseName={setCourseName}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="sidebar">
        <RightSidebar />
      </div>
      <Modal
        size="md"
        centered
        className="courses_modal"
        show={isShowNewResource}
        onHide={() => setIsShowNewResource(false)}
      >
        <Modal.Header className="w-100" closeButton>
          <Modal.Title className="w-90 text-center modal_title">
            {courseName?.replace("_", " ")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_body">
          <div className="modal_dashed_border" />
          <div>
            <Table
              heading={[
                {
                  id: 1,
                  value: "S. No.",
                  className: "w-5 black_color",
                },
                {
                  id: 2,
                  value: "Resource Name",
                  className: "w-30 black_color",
                },
                {
                  id: 3,
                  value: "Resource Type",
                  className: "w-5 black_color",
                },
                {
                  id: 4,
                  value: "",
                  className: "w-30",
                },
              ]}
              rows={latestResourceData
                ?.filter(
                  (d) => d.resource_name !== null && d.resource_id !== null
                )
                .map((d, i) => {
                  return [
                    {
                      id: i + 1,
                      value: (activePage - 1) * 10 + i + 1,
                      className: "w-5 black_color",
                    },
                    {
                      id: 2,
                      value: d.resource_name || d?.name,
                      className: "w-30 black_color",
                    },
                    {
                      id: 2,
                      value:
                        (d.resource_type_id === 1 && "Document") ||
                        (d.resource_type_id === 2 && "Video") ||
                        (d.resource_type_id === 4 && "Exam"),
                      className: "w-5 black_color",
                    },
                    {
                      id: 4,
                      value: (
                        <div>
                          {[1, 2].includes(d.resource_type_id) && (
                            <div className="w-100 d-flex justify-content-center">
                              <Button
                                className="btn small-btn"
                                title="View"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (d.resource_type_id === 1) {
                                    navigate(
                                      `/courses/document/${d.resource_id}`,
                                      {
                                        state: {
                                          item: d,
                                          resourse: "Document",
                                          resourceData: [
                                            {
                                              id: 1,
                                              name: "Document",
                                            },
                                          ],
                                        },
                                      }
                                    );
                                  }
                                  if (d.resource_type_id === 2) {
                                    navigate(
                                      `/courses/video/${d.resource_id}`,
                                      {
                                        state: {
                                          item: d,
                                          resourse: "Video",
                                          resourceData: [
                                            {
                                              id: 2,
                                              name: "Video",
                                            },
                                          ],
                                        },
                                      }
                                    );
                                  }
                                }}
                              />
                            </div>
                          )}
                          {d.resource_type_id === 4 && (
                            <div>
                              {d?.category?.name
                                .replace(/\s+/g, "")
                                .toLowerCase() === "homeassignment" && (
                                <HomeAssignmentStatusButton
                                  id={d?.id}
                                  examTemplateId={d?.exam_template_id}
                                  status={d?.status}
                                  endDate={d?.end_date}
                                  resultDate={d?.result_date}
                                  subjectName={d?.name}
                                  attemptCount={d?.exam_attempt_count}
                                />
                              )}
                              {d?.category?.name
                                .replace(/\s+/g, "")
                                .toLowerCase() !== "homeassignment" && (
                                <MockTestButton
                                  status={d?.status}
                                  id={d?.id}
                                  startDate={d?.start_date}
                                  endDate={d?.end_date}
                                  resultDate={d?.result_date}
                                  examRetake={d?.exam_retakes}
                                  examStatus={d.exam_status}
                                  examTemplateId={d?.exam_template_id}
                                  categoryExamName={d?.category?.name?.toLowerCase()}
                                  categoryExamRetake={d?.category?.exam_retake}
                                  subjectName={d?.name}
                                  totalQuestions={d?.total_questions}
                                  examName={d?.name}
                                  questionLimit={Number(
                                    d?.exam_template_structure
                                      ? Number(
                                          d?.exam_template_structure[0]?.limit
                                        )
                                      : undefined
                                  )}
                                  examTemplateUIId={d?.category?.template?.id}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      ),
                      className: "w-15",
                    },
                  ];
                })}
            />
          </div>
          {resultData.total > 0 && (
            <div>
              <Pagination
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={resultData.total}
                onChange={async (e) => {
                  const params = {
                    size: 10,
                    page: e,
                    course_id: courseId,
                  };
                  await getLatestResource(params);
                  setActivePage(e);
                }}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Content;
