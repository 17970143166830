import React from "react";

const VideoPlayer = ({
  src,
  title,
  subject,
  width = "100%",
  height = "360",
  iframeRef,
}) => {
  return (
    <div className="mb-5">
      <iframe
        id="youtube-player"
        src={src}
        width={width}
        height={height}
        allow="autoplay; fullscreen"
        allowFullScreen
        title="video by id"
        style={{ borderRadius: "10px" }}
        ref={iframeRef}
      ></iframe>
      {subject && (
        <div className="my-4">
          <h1 className="text-center">{subject}</h1>
        </div>
      )}
      {title && (
        <div className="my-4">
          <h1 className="text-center">{title}</h1>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
