import React from "react";
import Image from "../../../utils/image/Image";

const HeaderUserList = ({
  setProfileDrop,
  profileDrop,
  userIcon,
  arrowIcon,
  userName,
  userId,
  profileRef,
  location,
}) => {
  return (
    <div className="user_details_container" ref={profileRef}>
      <div
        className="d-flex align-items-center inner_container"
        onClick={() =>
          location.pathname.includes("examPortal")
            ? setProfileDrop(false)
            : setProfileDrop(!profileDrop)
        }
      >
        <div>
          <Image className="user_icon" src={userIcon} alt="user-image" />
        </div>
        <div className="arrow_container">
          <div className="large_screen_user_name">
            <p className="p-0 m-0">{userName}</p>
          </div>
          <div className="d-flex align-items-center">
            <p className="p-0 m-0 large_screen_user_id">{userId}</p>
            {!location.pathname.includes("examPortal") && (
              <Image className="arrow_icon" src={arrowIcon} alt="arrow-down" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderUserList;
