import React from "react";
import { NavLink, useLocation } from "react-router-dom";

// Function to set localStorage items
const setLocalStorageItems = (examTemplateId, subjectName) => {
  localStorage.setItem("tempId", examTemplateId);
  localStorage.setItem(
    "testName",
    JSON.stringify({
      subject: subjectName,
    })
  );
};

// Function to create NavLink component
const createNavLink = (to, onClick, children, className, location) => (
  <div
    className={`${
      !location?.pathname?.includes("folder") && `d-flex justify-content-center`
    }`}
  >
    <NavLink
      className={`btn small-btn theme-btn m-0 ${className}`}
      style={{ padding: "5px 10px" }}
      to={to}
      onClick={onClick}
    >
      {children}
    </NavLink>
  </div>
);

const renderScheduledButton = (endDate) =>
  endDate > new Date() && (
    <div className="btn-group">
      <div className="greyLink">Scheduled</div>
    </div>
  );

const renderExpiredButton = () => (
  <div className="btn-group">
    <div>Expired</div>
  </div>
);

const renderViewReportButton = (id, location) =>
  createNavLink(
    `/home-assignments/assignment-report/${id}`,
    null,
    "View Report",
    "report-btn border-btn",
    location
  );

const renderReTakeTestButton = (
  id,
  examTemplateId,
  subjectName,
  attemptCount,
  location
) => {
  return (
    <div className="d-flex justify-content-center">
      {renderViewReportButton(id, location)}
      <div className="ms-4">
        {attemptCount >= 1 &&
          createNavLink(
            `/home-assignments/assignment-details/home-assignment-instructions/${examTemplateId}/${id}`,
            () => setLocalStorageItems(examTemplateId, subjectName),
            "Re-Take Test",
            "report-btn border-btn"
          )}
      </div>
    </div>
  );
};

const renderResumeTestButton = (id, examTemplateId, subjectName, location) =>
  createNavLink(
    `/home-assignments/assignment-details/home-assignment-instructions/${examTemplateId}/${id}`,
    () => setLocalStorageItems(examTemplateId, subjectName),
    "Resume Test",
    "border-btn",
    location
  );

const renderTakeExamButton = (id, examTemplateId, subjectName, location) =>
  createNavLink(
    `/home-assignments/assignment-details/home-assignment-instructions/${examTemplateId}/${id}`,
    () => setLocalStorageItems(examTemplateId, subjectName),
    "Take Exam",
    "green-btn",
    location
  );

const HomeAssignmentStatusButton = ({
  status,
  id,
  examTemplateId,
  startDate,
  endDate,
  resultDate,
  subjectName,
  attemptCount,
}) => {
  const location = useLocation();
  switch (status) {
    case 0:
      return renderScheduledButton(endDate);
    case 1:
      return renderExpiredButton();
    case 2:
      return renderViewReportButton(id, location);
    case 4:
      return renderReTakeTestButton(
        id,
        examTemplateId,
        subjectName,
        attemptCount,
        location
      );
    case 5:
      return renderResumeTestButton(id, examTemplateId, subjectName, location);
    case 3:
      return renderTakeExamButton(id, examTemplateId, subjectName, location);
    case 6:
      return renderResumeTestButton(id, examTemplateId, subjectName, location);
    default:
      return null;
  }
};

export default HomeAssignmentStatusButton;
