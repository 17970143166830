import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "@kaydhiman/react-hook-useform";
import Button from "../utils/forms/Button";
import InputField from "../utils/forms/InputField";
import OnboardingLayout from "./OnboardingLayout";

import phone from "../../assets/images/user.svg";
import arrow from "../../assets/images/top_aerrow.svg";
import forgot_pass from "../../assets/images/forgot_pass.png";
import { AppContext } from "../shared/AppContext";
import { userService } from "../../_services/User.services";
import Auth from "../../auth/Auth";
import Image from "../utils/image/Image";

export default function ForgetPassword() {
  const { values, bindField, isValid, setInitialValues } = useForm({
    validations: {
      user_id: {
        required: {},
      },
    },
  });
  const { setSpinner, setToaster } = useContext(AppContext);
  const route = useNavigate();
  // api function
  const forgotPassword = async (e) => {
    e.preventDefault();

    setSpinner(true);
    try {
      const response = await userService.forgotPassword(values);

      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        // route("/verifycode");
        route("/verifycode", { state: { user_id: values?.user_id } });

        setToaster({
          show: true,
          type: "success",
          content: data.message,
        });

        setSpinner(false);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();

        if (data.errors) {
          let error = Object.keys(data.errors)?.reverse();

          for (let i = 0; i <= error.length; i++) {
            setToaster({
              show: true,
              type: "danger",
              content: data.errors[error[i]][0],
            });
          }
        } else {
          setToaster({
            show: true,
            type: "danger",
            content: data.message,
          });
        }
        setSpinner(false);
      }
    } catch (error) {
      setSpinner(false);
    }
    setSpinner(false);
    // api funstion
  };
  // api end function
  return (
    <OnboardingLayout
      Form={
        <>
          <div className="dash-heading">
            <h1>
              <span className="back_icon">
                <Link to="/">
                  <Image src={arrow} alt="backImage" />
                </Link>
              </span>
              Forgot Password
            </h1>
            <p className="sub_heading mb-4">
              Please enter your registered phone number to reset your password.
            </p>
          </div>
          <form onSubmit={forgotPassword}>
            <InputField
              label="User ID"
              id="user_id"
              name="user_id"
              value={values?.user_id || ""}
              placeholder="Enter your ID"
              type="text"
              bindField={bindField}
              iconData={{
                className: "lock_ico",
                isLock: true,
                src: phone,
                alt: "user Icon",
              }}
            />
            <Button
              type="submit"
              className="loginBtn"
              link="/verifycode"
              id="varifyBtn"
              title="Submit"
              disable={!isValid()}
            />
            <p className="text-center pt-4 footer-context">
              <Link to="/" className="themeLink">
                Sign In
              </Link>
            </p>
          </form>
        </>
      }
      image={forgot_pass}
    />
  );
}
