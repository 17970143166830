import moment from "moment";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { userService } from "../../../_services/User.services";
import Auth from "../../../auth/Auth";
import { AppContext } from "../../shared/AppContext";
import ArrowDown from "../../../assets/images/orange-arrow-down.svg";
import Image from "../image/Image";
import MockTestButton from "../../projectDashboard/test/MockTestButton";
import Button from "../forms/Button";
import { getResourceTypeLabel } from "../../../hooks/useResourceType";
import Table from "../table/Table";
import CommonModal from "../modal/CommonModal";
import { convertIsoToOrdinalDate } from "../dateTime";
import HomeAssignmentStatusButton from "../../projectDashboard/homeAssignmentLatest/HomeAssignmentStatusButton";
import GreenFlagIcon from "../../../assets/images/green-flag.svg";
import YellowFlagIcon from "../../../assets/images/yellow-flag.svg";
import OrangeFlagIcon from "../../../assets/images/orange-flag.svg";
import RedFlagIcon from "../../../assets/images/red-flag.svg";
import GreenCheckIcon from "../../../assets/images/checked.png";

const HomeScheduleCard = ({ scheduleData, className, type, handleExam }) => {
  const navigate = useNavigate();
  const { setToaster, setSpinner } = useContext(AppContext);
  const [modal, setModal] = useState({ homework: false });
  const [homeworkData, setHomeWorkData] = useState(null);
  const [openTopicDetails, setOpenTopicDetails] = useState(
    new Array(scheduleData?.length).fill(false)
  );

  const currentDate = new Date();

  const handleLiveClass = async (id) => {
    setSpinner(true);
    try {
      const response = await userService.getJoinLiveClassUrl({
        first_name: Auth?.user()?.name,
        last_name: ".",
        email: Auth?.user()?.email,
        meeting_id: id?.meeting_id,
        live_class_id: id?.id,
        meeting_type_id: id?.meeting_type_id,
      });
      const threadIdObject = id.thread_id
        ? JSON.parse(id?.thread_id)
        : id?.elem.thread_id
        ? JSON.parse(id?.elem.thread_id)
        : 0;
      const threadId = threadIdObject.threadId;

      if (response?.status >= 200 && response?.status <= 299) {
        const data = await response.json();
        navigate(`/courses/live_class/${id?.id}/chat/${threadId}`, {
          state: false,
        });
        setSpinner(false);
        if (data?.code === 429) {
          setToaster({
            show: true,
            type: "danger",
            content: data.message,
          });
          setSpinner(false);
        }
        if (data?.code === 404) {
          setToaster({
            show: true,
            type: "danger",
            content: data.message,
          });
          setSpinner(false);
        }
        if (data?.code === 3000) {
          setToaster({
            show: true,
            type: "danger",
            content: data.message,
          });
          setSpinner(false);
        }

        if (data?.join_url) {
          localStorage.setItem("zoomMeetUrl", data?.join_url);
          setToaster({
            show: true,
            type: "success",
            content: data.message,
          });
          if (
            id?.thread_id &&
            Object.keys(JSON.parse(id?.thread_id)).includes("error")
          ) {
            setToaster({
              show: true,
              type: "danger",
              content: JSON.parse(id?.thread_id)?.error,
            });
          } else {
            navigate(`/courses/live_class/${id?.id}/chat/${threadId}`, {
              state: false,
            });
          }
          setSpinner(false);
        }
        // setSpinner(false);
      }
      if (response?.status === 401) {
        // localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }
      if (response?.status >= 400 && response?.status <= 499) {
        const data = await response.json();
        setToaster({
          show: true,
          type: "danger",
          content: data.message,
        });
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  // Exam Status Mapping
  const examStatusMapping = {
    "Take Exam": (scheduleCardData) => handleExam(scheduleCardData.elem),
    Retake: (scheduleCardData) => handleExam(scheduleCardData.elem),
    "Resume Test": (scheduleCardData) => handleExam(scheduleCardData.elem),
    "View Report": (scheduleCardData) => handleExam(scheduleCardData.elem),
  };

  // Handling Exam Status Button onClick
  const handleExamStatus = (scheduleCardData) => {
    const actionExamStatus = examStatusMapping[scheduleCardData.btn_title];
    if (actionExamStatus) {
      actionExamStatus(scheduleCardData);
    }
  };

  const toggleTopicDetails = (index) => {
    const newOpenTopicDetails = [...openTopicDetails];
    newOpenTopicDetails[index] = !newOpenTopicDetails[index];
    setOpenTopicDetails(newOpenTopicDetails);
  };

  return (
    <>
      {scheduleData?.map((scheduleCardData, index) => {
        console.log("cardddddd", scheduleCardData);
        return (
          <div className={`cards ${className && className}`} key={index}>
            <div className="gray-bg">
              <div className="gray-bg schedule-card-home w-100">
                <ul className="d-flex justify-content-between">
                  <li className="title-txt">
                    {scheduleCardData.title}{" "}
                    {scheduleCardData?.elem?.topic &&
                      `- ${scheduleCardData?.elem?.topic}`}
                  </li>
                  {/* <li className="title-txt"></li> */}
                  {!scheduleCardData?.elem?.category && (
                    <li className="shch_time-status title-txt">
                      {scheduleCardData.time}
                    </li>
                  )}
                </ul>
                <div className="sch_status my-2">
                  <ul className="text-center d-flex flex-row justify-content-between">
                    {scheduleCardData.status !== "Completed" && (
                      <li
                        className={`title-txt  ${
                          scheduleCardData.status === "Completed" &&
                          "green-text"
                        }`}
                      >
                        {scheduleCardData?.isIcon &&
                          scheduleCardData?.status_title &&
                          scheduleCardData.icon && (
                            <Image
                              src={scheduleCardData.icon}
                              className="pe-2"
                              alt="schedule-icon"
                            />
                          )}

                        {scheduleCardData.isStatus &&
                          scheduleCardData.status && (
                            <span>
                              {scheduleCardData.status === "Online" ? (
                                <>
                                  {scheduleCardData.icon && (
                                    <Image
                                      src={scheduleCardData.icon}
                                      className="pe-2"
                                      alt="schedule-icon"
                                    />
                                  )}
                                  <span>{scheduleCardData.status}</span>
                                </>
                              ) : scheduleCardData.status === "Completed" ? (
                                <>
                                  {scheduleCardData.icon && (
                                    <Image
                                      src={scheduleCardData.icon}
                                      className="pe-2"
                                      alt="schedule-icon"
                                    />
                                  )}
                                  <span>{scheduleCardData.status}</span>
                                </>
                              ) : (
                                scheduleCardData?.room
                              )}
                            </span>
                          )}
                      </li>
                    )}
                    {scheduleCardData.status === "Completed" && (
                      <li className={`title-txt `}>
                        {scheduleCardData?.isIcon &&
                          scheduleCardData?.status_title &&
                          scheduleCardData.icon && (
                            <Image
                              src={scheduleCardData.icon}
                              className="pe-2"
                              alt="schedule-icon"
                            />
                          )}

                        {scheduleCardData.room && (
                          <span>{scheduleCardData?.room}</span>
                        )}
                      </li>
                    )}
                    {scheduleCardData.btn_title === "Completed" &&
                      type !== "class" && (
                        <p
                          className={`btn theme-btn small-btn ${
                            scheduleCardData?.btn_title === "Attempted" &&
                            "green-btn"
                          }`}
                          onClick={() => handleExam(scheduleCardData?.elem)}
                        >
                          {scheduleCardData.btn_title}
                        </p>
                      )}

                    {scheduleCardData.isButton ? (
                      <li className="sch_time gray-text">
                        {scheduleCardData?.status === "In Progress" &&
                        type === "class" &&
                        scheduleCardData?.btn_title ? (
                          <div
                            className={`${
                              scheduleCardData.elem.class_type === 1
                                ? "green-text"
                                : "btn theme-btn small-btn"
                            } `}
                            onClick={() => handleLiveClass(scheduleCardData)}
                          >
                            {scheduleCardData.elem.class_type === 1
                              ? "In Progress"
                              : scheduleCardData.btn_title}
                          </div>
                        ) : (
                          <React.Fragment>
                            {scheduleCardData?.status !== "JOIN NOW" && (
                              <button
                                className="btn theme-btn small-btn"
                                onClick={() =>
                                  handleExamStatus(scheduleCardData)
                                }
                              >
                                {scheduleCardData.btn_title}
                              </button>
                            )}
                          </React.Fragment>
                        )}
                        {scheduleCardData?.status === "JOIN NOW" &&
                        type === "class" &&
                        scheduleCardData?.btn_title ? (
                          <div
                            className={`${
                              scheduleCardData.elem.class_type === 1
                                ? "green-text"
                                : "btn theme-btn small-btn"
                            } `}
                            onClick={() => handleLiveClass(scheduleCardData)}
                          >
                            {scheduleCardData.elem.class_type === 1
                              ? "In Progress"
                              : scheduleCardData.btn_title}
                          </div>
                        ) : (
                          <React.Fragment>
                            {scheduleCardData?.status !== "In Progress" && (
                              <button
                                className="btn theme-btn small-btn"
                                onClick={() =>
                                  handleExamStatus(scheduleCardData)
                                }
                              >
                                {scheduleCardData.btn_title}
                              </button>
                            )}
                          </React.Fragment>
                        )}
                      </li>
                    ) : scheduleCardData?.status_title ? (
                      <>
                        <li className="title-txt ">
                          {scheduleCardData.status === "Scheduled" ? (
                            <div className="pe-none btn theme-btn ">
                              {scheduleCardData?.status_title}
                            </div>
                          ) : (
                            <>
                              {/* {scheduleCardData.time <
                          moment().format(`MM-DD-YYYY | h:mm a`) ? (
                          <span>{scheduleCardData?.status_title}</span>
                            
                            : ""} */}

                              {type === "class" ? (
                                // < moment(new Date()).format(`DD-MM-YYYY | h:mm a`)
                                <>
                                  <span className="green-text p-0">
                                    {scheduleCardData.status_title !==
                                      "Completed" &&
                                      scheduleCardData.icon && (
                                        <Image
                                          src={scheduleCardData.icon}
                                          className="pe-2"
                                          alt="schedule-icon"
                                        />
                                      )}
                                    {scheduleCardData?.status_title}
                                  </span>
                                </>
                              ) : (
                                <>
                                  {
                                    scheduleCardData?.btn_title !==
                                      "Result Awaited" &&
                                      type !== "class" && (
                                        <>
                                          <p
                                            className={`btn theme-btn small-btn ${
                                              scheduleCardData?.btn_title !==
                                                "Result Awaited" && "green-btn"
                                            }`}
                                            // {...(scheduleCardData?.btn_title !== "Result Awaited" ? { onClick: handleExam(scheduleCardData?.elem) } : "")}
                                            onClick={() =>
                                              handleExam(scheduleCardData?.elem)
                                            }
                                          >
                                            {scheduleCardData.btn_title}
                                          </p>
                                        </>
                                      )
                                    // <p
                                    //   className="btn theme-btn small-btn"
                                    // // onClick={() => handleExam(scheduleCardData?.elem)}
                                    // >
                                    //   {scheduleCardData.btn_title}
                                    // </p>
                                  }
                                </>
                              )}
                              {scheduleCardData.time ==
                                moment().format(`DD-MM-YYYY | h:mm a`) && (
                                <Link
                                  // to={`/schedule/live_class/${scheduleCardData.id}`}
                                  // state={scheduleCardData.join_url}
                                  to=""
                                  className="btn theme-btn small-btn"
                                >
                                  Resume
                                </Link>
                              )}
                            </>
                          )}
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center pb-4">
                {!scheduleCardData?.elem?.category && (
                  <div
                    className="toggle_topic_details d-flex align-items-center cursor-pointer"
                    onClick={() => toggleTopicDetails(index)}
                  >
                    <span className="title-txt see_topic_details">
                      See Topic Details
                    </span>
                    <Image
                      src={ArrowDown}
                      width={15}
                      height={15}
                      className="ml-5p"
                      alt="arrow-down"
                    />
                  </div>
                )}
                <div className="pe-3">
                  {Array.isArray(scheduleCardData?.elem?.homework) &&
                  scheduleCardData?.elem?.homework?.length > 0 &&
                  scheduleCardData?.elem?.homework ? (
                    <button
                      className="homework_btn"
                      onClick={() => {
                        setModal((prev) => ({ ...prev, homework: true }));
                        setHomeWorkData({
                          label: `${scheduleCardData?.title}-${scheduleCardData.elem.topic}-${scheduleCardData?.elem.topic_details}`,
                          resources: scheduleCardData?.elem?.homework,
                        });
                      }}
                    >
                      Homework
                    </button>
                  ) : null}
                </div>
              </div>

              {!scheduleCardData?.elem?.category && openTopicDetails[index] && (
                <div className="pb-2 topic_details">
                  {scheduleCardData?.elem?.topic_details}
                </div>
              )}
            </div>
          </div>
        );
      })}
      <div className="homework_modal">
        <CommonModal
          show={modal.homework}
          close={() => setModal((prev) => ({ ...prev, homework: false }))}
          heading={`Homework - ${homeworkData?.label}`}
          crossShow={true}
          body={
            <div className="mt-4 mb-5">
              <Table
                heading={[
                  {
                    value: "S.no",
                  },
                  {
                    value: "Title",
                  },
                  {
                    value: "Type",
                  },
                  { value: "Assigned Date" },
                  { value: "Due Date" },
                  { value: "Status" },
                  { value: "Action" },
                ]}
                rows={
                  homeworkData !== null &&
                  Array.isArray(homeworkData.resources) &&
                  homeworkData.resources.length > 0 &&
                  homeworkData?.resources.map((homework, i) => {
                    const startDate = new Date(homework?.start_date);
                    const endDate = new Date(homework?.end_date);
                    const redFlagDate = new Date(homework?.red_flag);
                    const yellowFlagDate = new Date(homework?.yellow_flag);
                    const orangeFlagDate = new Date(homework?.orange_flag);

                    const isGreenFlag =
                      currentDate >= startDate &&
                      currentDate <= endDate &&
                      currentDate < yellowFlagDate;

                    const isYellowFlag =
                      currentDate <= yellowFlagDate &&
                      currentDate < orangeFlagDate;

                    const isOrangeFlag =
                      currentDate <= orangeFlagDate &&
                      currentDate < redFlagDate;

                    const isRedFlag =
                      currentDate > orangeFlagDate &&
                      currentDate >= redFlagDate;
                    return [
                      {
                        id: 1,
                        value: i + 1,
                      },
                      {
                        id: 2,
                        value:
                          homework.resource[0]?.title ||
                          homework?.resource[0]?.name,
                      },
                      {
                        id: 3,
                        value: getResourceTypeLabel(homework?.resource_type_id),
                      },
                      {
                        value: (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: convertIsoToOrdinalDate(
                                homework?.start_date
                              ),
                            }}
                          />
                        ),
                        id: 4,
                      },
                      {
                        value: (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: convertIsoToOrdinalDate(
                                homework?.end_date
                              ),
                            }}
                          />
                        ),
                        id: 5,
                      },
                      {
                        value: (
                          <div className="d-flex justify-content-center align-items-center">
                            <img
                              src={
                                (homework?.isCompleted && GreenCheckIcon) ||
                                (isGreenFlag && GreenFlagIcon) ||
                                (isYellowFlag && YellowFlagIcon) ||
                                (isOrangeFlag && OrangeFlagIcon) ||
                                (isRedFlag && RedFlagIcon)
                              }
                              alt="flag"
                              width={25}
                              height={25}
                            />
                          </div>
                        ),
                        id: 6,
                      },
                      {
                        value: (
                          <>
                            {[1, 2].includes(homework.resource_type_id) && (
                              <Button
                                className="btn small-btn"
                                title="Open"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (homework?.resource_type_id === 1) {
                                    navigate(
                                      `/courses/document/${homework?.id}`,
                                      {
                                        state: {
                                          item: homework,
                                          resourse: "Document",
                                          resourceData: [
                                            {
                                              id: 1,
                                              name: "Document",
                                            },
                                          ],
                                        },
                                      }
                                    );
                                  }
                                  if (homework?.resource_type_id === 2) {
                                    navigate(`/courses/video/${homework.id}`, {
                                      state: {
                                        item: homework,
                                        resourse: "Video",
                                        resourceData: [
                                          {
                                            id: 2,
                                            name: "Video",
                                          },
                                        ],
                                      },
                                    });
                                  }
                                }}
                              />
                            )}
                            {[4].includes(homework.resource_type_id) && (
                              <div>
                                {homework?.resource[0]?.category?.name
                                  .replace(/\s+/g, "")
                                  .toLowerCase() === "homeassignment" && (
                                  <HomeAssignmentStatusButton
                                    id={homework?.resource[0]?.id}
                                    examTemplateId={
                                      homework?.resource[0]?.exam_template_id
                                    }
                                    status={homework?.resource[0]?.status}
                                    endDate={homework?.resource[0]?.end_date}
                                    resultDate={
                                      homework?.resource[0]?.result_date
                                    }
                                    subjectName={homework?.resource[0]?.name}
                                    attemptCount={
                                      homework?.resource[0]?.exam_attempt_count
                                    }
                                  />
                                )}
                                {homework?.resource[0]?.category?.name
                                  .replace(/\s+/g, "")
                                  .toLowerCase() !== "homeassignment" && (
                                  <MockTestButton
                                    status={homework?.resource[0]?.status}
                                    id={homework?.resource[0]?.id}
                                    startDate={
                                      homework?.resource[0]?.start_date
                                    }
                                    endDate={homework?.resource[0]?.end_date}
                                    resultDate={
                                      homework?.resource[0]?.result_date
                                    }
                                    examRetake={
                                      homework?.resource[0]?.exam_retakes
                                    }
                                    examStatus={
                                      homework?.resource[0]?.exam_status
                                    }
                                    examTemplateId={
                                      homework?.resource[0]?.exam_template_id
                                    }
                                    categoryExamName={homework?.resource[0]?.category?.name?.toLowerCase()}
                                    categoryExamRetake={
                                      homework?.resource[0]?.category
                                        ?.exam_retake
                                    }
                                    subjectName={homework?.resource[0]?.name}
                                    // onChangeOMR={(e) => handleFileChange(e)}
                                    // onClickOMR={() => {
                                    //   document
                                    //     .getElementById("file_upload")
                                    //     .click();
                                    //   localStorage.setItem(
                                    //     CONSTANT.LOCAL_STORAGE.OMR_EXAM_ID,
                                    //     homework?.id
                                    //   );
                                    // }}
                                    totalQuestions={
                                      homework?.resource[0]?.total_questions
                                    }
                                    examName={homework?.resource[0]?.name}
                                    questionLimit={Number(
                                      homework?.resource[0]
                                        ?.exam_template_structure[0]?.limit
                                    )}
                                  />
                                )}
                              </div>
                            )}
                          </>
                        ),
                      },
                    ];
                  })
                }
              />
            </div>
          }
        />
      </div>
    </>
  );
};

export default HomeScheduleCard;
