import { Link } from "react-router-dom";

import Button from "../forms/Button";
import Auth from "../../../auth/Auth";



const cards = [
  {
    id: "1",
    icon: "images/icon01.svg",
    className: "card-box",
    title: "Live Classes",
  },
  {
    id: "2",
    icon: "images/icon02.svg",
    className: "card-box",
    title: "Video Lectures",
  },
  {
    id: "3",
    icon: "images/icon03.svg",
    className: "card-box",
    title: "Online Tests",
  },
  {
    id: "4",
    icon: "images/icon04.svg",
    className: "card-box",
    title: "Current Affairs",
  },
  {
    id: "5",
    icon: "images/icon05.svg",
    className: "card-box",
    title: "Supplementary Material",
  },
  {
    id: "6",
    icon: "images/icon01.svg",
    className: "card-box",
    title: "Live Classes",
  },
];
const reportcards = [
  {
    id: "1",
    icon: "images/icon08.svg",
    className: "card-box redBG",
    title: "Attendance",
    result: "44/50",
  },
  {
    id: "2",
    icon: "images/icon1.svg",
    className: "cards-box greenBG",
    title: "Exam Attempts",
    result: "24/25",
  },
  {
    id: "3",
    icon: "images/icon09.svg",
    className: "cards-box yellowBG",
    title: "Average Rank",
    result: "143/2024",
  },
];
const classData = [
  {
    id: 1,
    subject_name: "English",
    className: "redBG",
    subject_data: [
      {
        class: "Class Attended",
        class_data: "10",
      },
      {
        class: "Tests Attempted",
        class_data: "6",
      },
      {
        class: "Question Attempted",
        class_data: "12",
      },
      {
        class: "Speed",
        class_data: "12",
      },
      {
        class: "Accuracy",
        class_data: "12",
      },
    ],
  },
  {
    id: 2,
    subject_name: "Math",
    className: "greenBG",
    subject_data: [
      {
        class: "Class Attended",
        class_data: "10",
      },
      {
        class: "Tests Attempted",
        class_data: "6",
      },
      {
        class: "Question Attempted",
        class_data: "12",
      },
      {
        class: "Speed",
        class_data: "12",
      },
      {
        class: "Accuracy",
        class_data: "12",
      },
    ],
  },
  {
    id: 3,
    subject_name: "Science",
    className: "yellowBG",
    subject_data: [
      {
        class: "Class Attended",
        class_data: "10",
      },
      {
        class: "Tests Attempted",
        class_data: "6",
      },
      {
        class: "Question Attempted",
        class_data: "12",
      },
      {
        class: "Speed",
        class_data: "12",
      },
      {
        class: "Accuracy",
        class_data: "12",
      },
    ],
  },
  {
    id: 4,
    subject_name: "Chemistry",
    className: "pinkBG",
    subject_data: [
      {
        class: "Class Attended",
        class_data: "10",
      },
      {
        class: "Tests Attempted",
        class_data: "6",
      },
      {
        class: "Question Attempted",
        class_data: "12",
      },
      {
        class: "Speed",
        class_data: "12",
      },
      {
        class: "Accuracy",
        class_data: "12",
      },
    ],
  },
];
const testData = [
  {
    id: "1",
    icon: "images/icon11.svg",
    className: "card-box redBG",
    title: "Total Test",
    result: "120",
    isSpeed: false,
  },
  {
    id: "2",
    icon: "images/icon14.svg",
    className: "card-box greenBG",
    title: "Attempted",
    result: "42",
    isSpeed: false,
  },
  {
    id: "3",
    icon: "images/icon12.svg",
    className: "card-box yellowBG",
    title: "Speed",
    result: "36s",
    isSpeed: true,
  },
  {
    id: "4",
    icon: "images/icon13.svg",
    className: "card-box pinkBG",
    title: "Accuracy",
    result: "62%",
    isSpeed: false,
  },
];
const exam_categories = [
  {
    id: 1,
    name: "Mock Exams",
    className: "redBG",
    url: "exam_category",
    data: [
      {
        class: "Total Tests:",
        class_data: "10",
      },
      {
        class: "Attempted:",
        class_data: "6",
      },
      {
        class: "Best Rank:",
        class_data: "12",
      },
    ],
  },
  {
    id: 2,
    name: "Quant Tests",
    className: "greenBG",
    url: "exam_category",
    data: [
      {
        class: "Total Tests:",
        class_data: "10",
      },
      {
        class: "Attempted:",
        class_data: "6",
      },
      {
        class: "Best Rank:",
        class_data: "12",
      },
    ],
  },
  {
    id: 3,
    name: "Home Assignments",
    className: "yellowBG",
    url: "exam_category",
    data: [
      {
        class: "Total Tests:",
        class_data: "10",
      },
      {
        class: "Attempted:",
        class_data: "6",
      },
      {
        class: "Best Rank:",
        class_data: "12",
      },
    ],
  },
  {
    id: 4,
    name: "Verbal Tests",
    className: "pinkBG",
    url: "exam_category",
    data: [
      {
        class: "Total Tests:",
        class_data: "10",
      },
      {
        class: "Attempted:",
        class_data: "6",
      },
      {
        class: "Best Rank:",
        class_data: "12",
      },
    ],
  },
];
const reportData = [
  {
    id: 1,
    icon: "images/icon08.svg",
    className: "card-box redBG",
    title: "Total Classes",
    result: "44/50",
  },
  {
    id: 2,
    icon: "images/icon1.svg",
    className: "cards-box greenBG",
    title: "Attended",
    result: "24/25",
  },
  {
    id: 3,
    icon: "images/icon09.svg",
    className: "cards-box yellowBG",
    title: "Last 15 days",
    result: "36 sec",
  },
  {
    id: 4,
    icon: "images/icon09.svg",
    className: "cards-box yellowBG",
    title: "Last 30 days",
    result: "62%",
  },
];

// course dashboard
// main  course

// const courseData = [
//   {
//     id: 1,
//     subject_name: "Crash Course 2022",
//     className: "lightPink",
//     url: "/courses/1",
//     subject_data: [
//       {
//         class: "Live Classes",
//         class_data: "10",
//       },
//       {
//         class: "Videos",
//         class_data: "6",
//       },
//       {
//         class: "Documents",
//         class_data: "12",
//       },
//       {
//         class: "Tests",
//         class_data: "12",
//       },
//     ],
//   },
//   {
//     id: 2,
//     subject_name: "General Awareness",
//     className: "greenBG",
//     url: "/courses/1",
//     subject_data: [
//       {
//         class: "Live Classes",
//         class_data: "10",
//       },
//       {
//         class: "Videos",
//         class_data: "6",
//       },
//       {
//         class: "Documents",
//         class_data: "12",
//       },
//       {
//         class: "Tests",
//         class_data: "12",
//       },
//     ],
//   },
//   {
//     id: 3,
//     subject_name: "Course 3",
//     className: "yellowBG",
//     url: "/courses/1",
//     subject_data: [
//       {
//         class: "Live Classes",
//         class_data: "10",
//       },
//       {
//         class: "Videos",
//         class_data: "6",
//       },
//       {
//         class: "Documents",
//         class_data: "12",
//       },
//       {
//         class: "Tests",
//         class_data: "12",
//       },
//     ],
//   },
//   {
//     id: 4,
//     subject_name: "Course 4",
//     className: "pinkBG",
//     url: "/courses/1",
//     subject_data: [
//       {
//         class: "Live Classes",
//         class_data: "10",
//       },
//       {
//         class: "Videos",
//         class_data: "6",
//       },
//       {
//         class: "Documents",
//         class_data: "12",
//       },
//       {
//         class: "Tests",
//         class_data: "12",
//       },
//     ],
//   },
//   {
//     id: 4,
//     subject_name: "Course 5",
//     className: "lightBlue",
//     url: "/courses/1",
//     subject_data: [
//       {
//         class: "Live Classes",
//         class_data: "10",
//       },
//       {
//         class: "Videos",
//         class_data: "6",
//       },
//       {
//         class: "Documents",
//         class_data: "12",
//       },
//       {
//         class: "Tests",
//         class_data: "12",
//       },
//     ],
//   },
//   {
//     id: 4,
//     subject_name: "Course 6",
//     className: "darkBlue",
//     url: "/courses/1",
//     subject_data: [
//       {
//         class: "Live Classes",
//         class_data: "10",
//       },
//       {
//         class: "Videos",
//         class_data: "6",
//       },
//       {
//         class: "Documents",
//         class_data: "12",
//       },
//       {
//         class: "Tests",
//         class_data: "12",
//       },
//     ],
//   },
// ];

// icon courseData
const courseDetailData = [
  {
    id: 1,
    subject_name: "Week 1",
    className: "redBG iconData",

    subject_data: [
      {
        isClass: false,
        // icon: "images/small01.svg",
        class_data: "10",
      },
      {
        isClass: false,
        // icon: "images/small02.svg",
        class_data: "6",
      },
      {
        isClass: false,
        // icon: "images/small03.svg",
        class_data: "12",
      },
      {
        isClass: false,
        // icon: "images/small04.svg",
        class_data: "12",
      },
    ],
  },
  {
    id: 2,
    subject_name: "Week 2",
    className: "redBG iconData",
    subject_data: [
      {
        icon: "images/small01.svg",
        class_data: "10",
      },
      {
        icon: "images/small02.svg",
        class_data: "6",
      },
      {
        icon: "images/small03.svg",
        class_data: "12",
      },
      {
        icon: "images/small04.svg",
        class_data: "12",
      },
    ],
  },
  {
    id: 3,
    subject_name: "Week 3",
    className: "redBG  iconData",
    subject_data: [
      {
        icon: "images/small01.svg",
        class_data: "10",
      },
      {
        icon: "images/small02.svg",
        class_data: "6",
      },
      {
        icon: "images/small03.svg",
        class_data: "12",
      },
      {
        icon: "images/small04.svg",
        class_data: "12",
      },
    ],
  },
  {
    id: 4,
    subject_name: "Week 4",
    className: "redBG iconData",
    subject_data: [
      {
        icon: "images/small01.svg",
        class_data: "10",
      },
      {
        icon: "images/small02.svg",
        class_data: "6",
      },
      {
        icon: "images/small03.svg",
        class_data: "12",
      },
      {
        icon: "images/small04.svg",
        class_data: "12",
      },
    ],
  },
];
// full courseData
const courseDetailFullData = [
  {
    id: 1,
    subject_name: "Week 1",
    className: "redBG",
    subject_data: [
      {
        class: "Live Classes:",
        class_data: "10",
      },
      {
        class: "Videos:",
        class_data: "6",
      },
      {
        class: "Question Attempted",
        class_data: "12",
      },
      {
        class: "Documents:",
        class_data: "12",
      },
      {
        class: "Tests:",
        class_data: "12",
      },
    ],
  },
  {
    id: 2,
    subject_name: "Week 2",
    className: "redBG",
    subject_data: [
      {
        class: "Live Classes:",
        class_data: "10",
      },
      {
        class: "Videos:",
        class_data: "6",
      },
      {
        class: "Question Attempted",
        class_data: "12",
      },
      {
        class: "Documents:",
        class_data: "12",
      },
      {
        class: "Tests:",
        class_data: "12",
      },
    ],
  },
  {
    id: 3,
    subject_name: "Week 3",
    className: "redBG",
    subject_data: [
      {
        class: "Live Classes:",
        class_data: "10",
      },
      {
        class: "Videos:",
        class_data: "6",
      },
      {
        class: "Question Attempted",
        class_data: "12",
      },
      {
        class: "Documents:",
        class_data: "12",
      },
      {
        class: "Tests:",
        class_data: "12",
      },
    ],
  },
  {
    id: 4,
    subject_name: "Week 4",
    className: "redBG",
    subject_data: [
      {
        class: "Live Classes:",
        class_data: "10",
      },
      {
        class: "Videos:",
        class_data: "6",
      },
      {
        class: "Question Attempted",
        class_data: "12",
      },
      {
        class: "Documents:",
        class_data: "12",
      },
      {
        class: "Tests:",
        class_data: "12",
      },
    ],
  },
];
// report dashboard
const reportPerformanceData = [
  {
    id: 1,
    icon: "images/icon08.svg",
    className: "card-box redBG",
    title: "Total Classes",
    result: "120",
    isSpeed: false,
  },
  {
    id: 2,
    icon: "images/icon1.svg",
    className: "cards-box greenBG",
    title: "Attended",
    result: "42",
    isSpeed: false,
  },
  {
    id: 3,
    icon: "images/icon09.svg",
    className: "cards-box yellowBG",
    title: "Last 15 days",
    result: "36 sec",
    isSpeed: true,
  },
  {
    id: 4,
    icon: "images/icon09.svg",
    className: "cards-box pinkBG",
    title: "Last 30 days",
    result: "62%",
    isSpeed: false,
  },
];
// table data
const testExamTableData = {
  heading: [
    { value: "Name", id: 1 },
    { value: "Exam Category", id: 2 },
    { value: "Duration", id: 3 },
    { value: "Attempted On", id: 4 },
    { value: "Score", id: 5 },
    { value: "Rank", id: 6 },
    { value: "Status", id: 7, className: "actionTh" },
  ],
  rows: [
    [
      { value: "Verbal Ability-AK Sir-Batch 1", id: 1 },
      { value: "Mock Exam", id: 2 },
      { value: "", id: 3 },
      { value: "", id: 4 },
      { value: "", id: 5 },
      { value: "00:30", id: 6 },
      {
        value: (
          <div className="btn-group">
            <div className="themeLink">
              <Link to="/" className="themeLink">
                Open
              </Link>
              <Button
                className="green-btn small-btn"
                link="examinstructions"
                title="Take Exam"
              />
            </div>
          </div>
        ),
        id: 7,
      },
    ],
    [
      { value: "Verbal Ability-AK Sir-Batch 1", id: 1 },
      { value: "Quant Exam", id: 2 },
      { value: "00:30", id: 3 },
      { value: "15/04/2022", id: 4 },
      { value: "250/300", id: 5 },
      { value: "125/2000", id: 6 },
      {
        value: (
          <div className="btn-group">
            <div className="greenLink">
              <div className=" green-text">Attempted</div>
              <Button className="theme-btn small-btn ms-0" title="Retake" />
              <Button
                className="theme-btn small-btn border-btn"
                title="View Report"
              />
            </div>
          </div>
        ),
        id: 7,
      },
    ],
    [
      { value: "Verbal Ability-AK Sir-Batch 1", id: 1 },
      { value: "Mock Exam", id: 2 },
      { value: "00:30", id: 3 },
      { value: "N/A", id: 4 },
      { value: "", id: 5 },
      { value: "", id: 6 },
      {
        value: (
          <div className="btn-group">
            <div className="greyLink">Expired</div>
          </div>
        ),
        id: 7,
      },
    ],
  ],
};
// report dashboard end
const mockTestExamTableData = {
  heading: [
    { value: "Name", id: 1 },
    { value: "Duration", id: 2 },
    { value: "Attempted On", id: 3 },
    { value: "Score", id: 4 },
    { value: "Rank", id: 5 },
    { value: "Status", id: 6, className: "actionTh" },
  ],
  rows: [
    [
      { value: "Verbal Ability-AK Sir-Batch 1", id: 1 },
      { value: "00:30", id: 2 },
      { value: "", id: 3 },
      { value: "", id: 4 },
      { value: "00:30", id: 5 },
      {
        value: (
          <div className="btn-group">
            <div className="themeLink">
              <Link to="/" className="themeLink">
                Open
              </Link>
              <Button
                link="examinstructions"
                className="green-btn small-btn"
                title="Take Exam"
              />
            </div>
          </div>
        ),
        id: 7,
      },
    ],
    [
      { value: "Verbal Ability-AK Sir-Batch 1", id: 1 },
      { value: "00:30", id: 2 },
      { value: "15/04/2022", id: 3 },
      { value: "250/300", id: 4 },
      { value: "125/2000", id: 5 },
      {
        value: (
          <div className="btn-group">
            <div className="greenLink">
              <div className=" green-text">Attempted</div>
              <Button className="theme-btn small-btn ms-0" title="Retake" />
              <Button
                className="theme-btn small-btn border-btn"
                title="View Report"
              />
            </div>
          </div>
        ),
        id: 6,
      },
    ],
    [
      { value: "Verbal Ability-AK Sir-Batch 1", id: 1 },
      { value: "00:30", id: 2 },
      { value: "N/A", id: 3 },
      { value: "", id: 4 },
      { value: "", id: 5 },
      {
        value: (
          <div className="btn-group">
            <div className="greyLink">Expired</div>
          </div>
        ),
        id: 6,
      },
    ],
    [
      { value: "Verbal Ability-AK Sir-Batch 1", id: 1 },
      { value: "00:30", id: 2 },
      { value: "15/04/2022", id: 3 },
      { value: "250/300", id: 4 },
      { value: "125/2000", id: 5 },
      {
        value: (
          <div className="btn-group">
            <div className="greenLink">
              <div className=" green-text">Attempted</div>
              <Button className="theme-btn small-btn ms-0" title="Retake" />
              <Button
                className="theme-btn small-btn border-btn"
                title="View Report"
              />
            </div>
          </div>
        ),
        id: 6,
      },
    ],
    [
      { value: "Verbal Ability-AK Sir-Batch 1", id: 1 },
      { value: "00:30", id: 2 },
      { value: "N/A", id: 3 },
      { value: "", id: 4 },
      { value: "", id: 5 },
      {
        value: (
          <div className="btn-group">
            <div className="greyLink">Expired</div>
          </div>
        ),
        id: 6,
      },
    ],

    [
      { value: "Verbal Ability-AK Sir-Batch 1", id: 1 },
      { value: "00:30", id: 2 },
      { value: "15/04/2022", id: 3 },
      { value: "250/300", id: 4 },
      { value: "125/2000", id: 5 },
      {
        value: (
          <div className="btn-group">
            <div className="greenLink green-text">Attempted</div>
          </div>
        ),
        id: 6,
      },
    ],
  ],
};
// questions data
export const mathsExamData = [
  {
    id: 1,
    qa_title:
      "For the four sets of three measured physical quantities as given below. which of the following option is correct?",
    qa_options: [
      {
        qa_options_number: "(i)",
        qa_options_title: " A1 =24.36, 131 = 0.0724, C1= 256.2",
        isButton: true,
        btn_title: "My Answer",
        icon: "images/cancel-svgrepo-com.svg",
        class_Name: "theme-btn ",
      },
      {
        qa_options_number: "(ii)",
        qa_options_title: " A2 = 24.44, B2 = 16.082, C2= 240.2",
      },
      {
        qa_options_number: "(iii)",
        qa_options_title: " A3 = 25.2, B3=19.2812, C3=236.183",
        isButton: true,
        btn_title: "Correct Answer",
        icon: "images/check-icon.png",
        class_Name: "green-btn ",
      },
      {
        qa_options_number: "(iv)",
        qa_options_title: " A4 = 25, B4 = 236.191, C4 = 19.5",
      },
    ],
    awn_options: [
      {
        id: "1",
        key: 1,
        awn_options_title: "A1+B1+C1 < A3+B3+C3 < A2+B2+C2 < A4+B4+C4",
      },
      {
        key: 2,
        id: "2",
        awn_options_title: "A1+B1+C1 < A2+B2+C2 = A2+B2+C2 = A3+B3+C3",
      },
      {
        id: "3",
        key: 3,
        awn_options_title: "A4+B4+C4 < A1+B1+C1 = A2+B2+C2 = A3+B3+C3",
      },
      {
        id: " 4",
        key: 4,
        awn_options_title: "qwA4+B4+C4 < A1+B1+C1 = A3+B3+C3 = A2+B2+C2erty",
      },
    ],
    exam_details: [
      {
        exam_details_title: "User Action",
        exam_details_action: "Answered",
        id: "1",
      },
      {
        exam_details_title: "Time Spent",
        exam_details_action: "5 Second(S)",
        id: "2",
      },
      {
        exam_details_title: "Level",
        exam_details_action: "Medium",
        id: "3",
      },
      {
        exam_details_title: "Subject",
        exam_details_action: "Logical Reasoning",
        id: "4",
      },
      {
        exam_details_title: "Chapter",
        exam_details_action: "Logical Reasoning",
        id: "5",
      },
    ],
  },
  {
    id: 2,
    qa_title:
      "For the four sets of three measured physical quantities as given below. which of the following option is correct?",
    qa_options: [
      {
        qa_options_number: "(i)",
        qa_options_title: " A1 =24.36, 131 = 0.0724, C1= 256.2",
        isButton: true,
        btn_title: "My Answer",
        icon: "images/cancel-svgrepo-com.svg",
        class_Name: "theme-btn ",
      },
      {
        qa_options_number: "(ii)",
        qa_options_title: " A2 = 24.44, B2 = 16.082, C2= 240.2",
      },
      {
        qa_options_number: "(iii)",
        qa_options_title: " A3 = 25.2, B3=19.2812, C3=236.183",
        isButton: true,
        btn_title: "Correct Answer",
        icon: "images/check-icon.png",
        class_Name: "green-btn ",
      },
      {
        qa_options_number: "(iV)",
        qa_options_title: " A4 = 25, B4 = 236.191, C4 = 19.5",
      },
    ],
    awn_options: [
      {
        id: "1",
        key: 1,
        awn_options_title: "A1+B1+C1 < A3+B3+C3 < A2+B2+C2 < A4+B4+C4",
      },
      {
        key: 2,
        id: "2",
        awn_options_title: "A1+B1+C1 < A2+B2+C2 = A2+B2+C2 = A3+B3+C3",
      },
      {
        id: "3",
        key: 3,
        awn_options_title: "A4+B4+C4 < A1+B1+C1 = A2+B2+C2 = A3+B3+C3",
      },
      {
        id: " 4",
        key: 4,
        awn_options_title: "qwA4+B4+C4 < A1+B1+C1 = A3+B3+C3 = A2+B2+C2erty",
      },
    ],
    exam_details: [
      {
        exam_details_title: "User Action",
        exam_details_action: "Answered",
        id: "1",
      },
      {
        exam_details_title: "Time Spent",
        exam_details_action: "5 Second(S)",

        id: "2",
      },
      {
        exam_details_title: "Level",
        exam_details_action: "Medium",
        id: "3",
      },
      {
        exam_details_title: "Subject",
        exam_details_action: "Logical Reasoning",
        id: "4",
      },
      {
        exam_details_title: "Chapter",
        exam_details_action: "Logical Reasoning",
        id: "5",
      },
    ],
  },
  {
    id: 3,
    qa_title:
      "For the four sets of three measured physical quantities as given below. which of the following option is correct?",
    qa_options: [
      {
        qa_options_number: "(i)",
        qa_options_title: " A1 =24.36, 131 = 0.0724, C1= 256.2",
      },
      {
        qa_options_number: "(ii)",
        qa_options_title: " A2 = 24.44, B2 = 16.082, C2= 240.2",
        isButton: true,
        btn_title: "My Answer",
        icon: "images/cancel-svgrepo-com.svg",
        class_Name: "theme-btn ",
      },
      {
        qa_options_number: "(iii)",
        qa_options_title: " A3 = 25.2, B3=19.2812, C3=236.183",
        isButton: true,
        btn_title: "Correct Answer",
        icon: "images/check-icon.png",
        class_Name: "green-btn ",
      },
      {
        qa_options_number: "(iV)",
        qa_options_title: " A4 = 25, B4 = 236.191, C4 = 19.5",
      },
    ],
    awn_options: [
      {
        id: "1",
        key: 1,
        awn_options_title: "A1+B1+C1 < A3+B3+C3 < A2+B2+C2 < A4+B4+C4",
      },
      {
        key: 2,
        id: "2",
        awn_options_title: "A1+B1+C1 < A2+B2+C2 = A2+B2+C2 = A3+B3+C3",
      },
      {
        id: "3",
        key: 3,
        awn_options_title: "A4+B4+C4 < A1+B1+C1 = A2+B2+C2 = A3+B3+C3",
      },
      {
        id: " 4",
        key: 4,
        awn_options_title: "qwA4+B4+C4 < A1+B1+C1 = A3+B3+C3 = A2+B2+C2erty",
      },
    ],
    exam_details: [
      {
        exam_details_title: "User Action",
        exam_details_action: "Answered",
        id: "1",
      },
      {
        exam_details_title: "Time Spent",
        exam_details_action: "5 Second(S)",
        id: "2",
      },
      {
        exam_details_title: "Level",
        exam_details_action: "Medium",
        id: "3",
      },
      {
        exam_details_title: "Subject",
        exam_details_action: "Logical Reasoning",
        id: "4",
      },
      {
        exam_details_title: "Chapter",
        exam_details_action: "Logical Reasoning",
        id: "5",
      },
    ],
  },
];
// questions data
export const quizQuestionData = [
  {
    id: 1,
    question:
      "For the four sets of three measured physical quantities as given below. which of the following option is correct?",

    awn_options: [
      {
        id: "1",
        key: 1,
        awn_options_title: "A1+B1+C1 < A3+B3+C3 < A2+B2+C2 < A4+B4+C4",
        isButton: false,
        // btn_title: "My Answer",
        // icon: "images/cancel-svgrepo-com.svg",
        // class_Name: "theme-btn ",
      },
      {
        key: 2,
        id: "2",
        awn_options_title: "A1+B1+C1 < A2+B2+C2 = A2+B2+C2 = A3+B3+C3",
        isButton: false,
        // btn_title: "Correct Answer",
        // icon: "images/check-icon.png",
        // class_Name: "green-btn ",
      },
      {
        id: "3",
        key: 3,
        awn_options_title: "A4+B4+C4 < A1+B1+C1 = A2+B2+C2 = A3+B3+C3",
      },
      {
        id: " 4",
        key: 4,
        awn_options_title: "qwA4+B4+C4 < A1+B1+C1 = A3+B3+C3 = A2+B2+C2erty",
      },
    ],
    exam_details: [
      {
        exam_details_title: "User Action",
        exam_details_action: "Answered",
        id: "1",
      },
      {
        exam_details_title: "Time Spent",
        exam_details_action: "5 Second(S)",
        id: "2",
      },
      {
        exam_details_title: "Level",
        exam_details_action: "Medium",
        id: "3",
      },
      {
        exam_details_title: "Subject",
        exam_details_action: "Logical Reasoning",
        id: "4",
      },
      {
        exam_details_title: "Chapter",
        exam_details_action: "Logical Reasoning",
        id: "5",
      },
    ],
  },
];
const reasoningExamData = [
  {
    id: 1,
    qa_title: "Reasoning Questions",
    qa_options: [
      {
        qa_options_number: "(i)",
        qa_options_title: " A1 =24.36, 131 = 0.0724, C1= 256.2",
      },
      {
        qa_options_number: "(ii)",
        qa_options_title: " A2 = 24.44, B2 = 16.082, C2= 240.2",
      },
      {
        qa_options_number: "(iii)",
        qa_options_title: " A3 = 25.2, B3=19.2812, C3=236.183",
      },
      {
        qa_options_number: "(iV)",
        qa_options_title: " A4 = 25, B4 = 236.191, C4 = 19.5",
      },
    ],
    awn_options: [
      {
        id: 5,
        awn_options_title: "A1+B1+C1 < A3+B3+C3 < A2+B2+C2 < A4+B4+C4",
      },
      {
        id: 6,
        awn_options_title: "A1+B1+C1 < A2+B2+C2 = A2+B2+C2 = A3+B3+C3",
      },
      {
        id: 7,
        awn_options_title: "A4+B4+C4 < A1+B1+C1 = A2+B2+C2 = A3+B3+C3",
      },
      {
        id: 8,
        awn_options_title: "qwA4+B4+C4 < A1+B1+C1 = A3+B3+C3 = A2+B2+C2erty",
      },
    ],
  },
];
const scienceExamData = [
  {
    id: 1,
    qa_title: "Science Questions",
    qa_options: [
      {
        qa_options_number: "(i)",
        qa_options_title: " A1 =24.36, 131 = 0.0724, C1= 256.2",
      },
      {
        qa_options_number: "(ii)",
        qa_options_title: " A2 = 24.44, B2 = 16.082, C2= 240.2",
      },
      {
        qa_options_number: "(iii)",
        qa_options_title: " A3 = 25.2, B3=19.2812, C3=236.183",
      },
      {
        qa_options_number: "(iV)",
        qa_options_title: " A4 = 25, B4 = 236.191, C4 = 19.5",
      },
    ],
    awn_options: [
      {
        id: 9,
        awn_options_title: "A1+B1+C1 < A3+B3+C3 < A2+B2+C2 < A4+B4+C4",
      },
      {
        id: 10,
        awn_options_title: "A1+B1+C1 < A2+B2+C2 = A2+B2+C2 = A3+B3+C3",
      },
      {
        id: 11,
        awn_options_title: "A4+B4+C4 < A1+B1+C1 = A2+B2+C2 = A3+B3+C3",
      },
      {
        id: 12,
        awn_options_title: "qwA4+B4+C4 < A1+B1+C1 = A3+B3+C3 = A2+B2+C2erty",
      },
    ],
  },
];
const gkExamData = [
  {
    id: 1,
    qa_title: "General-Knowledge Questions",
    qa_options: [
      {
        qa_options_number: "(i)",
        qa_options_title: " A1 =24.36, 131 = 0.0724, C1= 256.2",
      },
      {
        qa_options_number: "(ii)",
        qa_options_title: " A2 = 24.44, B2 = 16.082, C2= 240.2",
      },
      {
        qa_options_number: "(iii)",
        qa_options_title: " A3 = 25.2, B3=19.2812, C3=236.183",
      },
      {
        qa_options_number: "(iV)",
        qa_options_title: " A4 = 25, B4 = 236.191, C4 = 19.5",
      },
    ],
    awn_options: [
      {
        id: 13,
        awn_options_title: "A1+B1+C1 < A3+B3+C3 < A2+B2+C2 < A4+B4+C4",
      },
      {
        id: 14,
        awn_options_title: "A1+B1+C1 < A2+B2+C2 = A2+B2+C2 = A3+B3+C3",
      },
      {
        id: 15,
        awn_options_title: "A4+B4+C4 < A1+B1+C1 = A2+B2+C2 = A3+B3+C3",
      },
      {
        id: 16,
        awn_options_title: "qwA4+B4+C4 < A1+B1+C1 = A3+B3+C3 = A2+B2+C2erty",
      },
    ],
  },
];
// ScheduleCard
const schedule_cards = [
  {
    id: 1,
    title: "Live Class 1",
    time: "4:30 PM",
    icon: "images/vuesax-linear-task-square.svg",
    status: "Scheduled",
    btn_title: "Attend Now",
    isButton: true,
    isIcon: true,
    isStatus: true,
  },
  {
    id: 2,
    title: "Mock Test 2",
    time: "11:59 PM",
    icon: "images/check-svgrepo-com.svg",
    status: "Completed",
    className: "green-text",
    isStatus: true,
    isButton: false,
    isIcon: true,
  },
];
// ScheduleCard-page
const schedule_page = [
  {
    id: 1,
    title: "VA Class 10",
    time: "9:30 AM - 10:30 AM",
    icon: "images/green-circle.png",
    status: "Online",
    btn_title: "Join Now",
    isButton: true,
    isStatus: true,
    isIcon: true,
  },
  {
    id: 2,
    title: "QA Class 11",
    time: "11:00 AM - 12:00 PM",
    status: "Kailash Colony, Room 1",
    btn_title: "Scheduled",
    isButton: true,
    isStatus: true,
    isIcon: false,
  },
  {
    id: 3,
    title: "LR Class 4",
    time: "11:00 AM - 12:00 PM",
    status: "Kailash Colony, Room 1",
    btn_title: "Scheduled",
    isStatus: true,
    isButton: true,
  },
];
const test_schedule_data = [
  {
    id: 1,
    title: "Home Assignment",
    time: "End Date: 16-03-22 | 23:59 PM",
    icon: "images/check-svgrepo-com.svg",
    status: "Completed",
    className: "green-text",
    isIcon: true,
    isStatus: true,
    isButton: false,
  },
  {
    id: 2,
    title: "CLAT Mock Test 2",
    time: "End Date: 19-03-22 23:59 PM",
    btn_title: "Take Test",
    isStatus: false,
    isButton: true,
  },
  {
    id: 3,
    title: "QA Chapter Test 5 ",
    time: "End Date: 18-03-22 23:59 PM",
    btn_title: "Resume",
    isStatus: false,
    isButton: true,
  },
];
const class_table_data = {
  heading: [
    {
      id: 1,
      value: "Name",
    },
    { id: 2, value: "Address" },
    { id: 3, value: "Time" },
    { id: 4, value: "Batch" },
    { id: 5, value: "Action", className: "actionTh" },
  ],
  rows: [
    [
      { id: 1, value: "VA Class 10" },
      { id: 2, value: "Online" },
      { id: 3, value: "9:30 AM - 10:30 AM" },
      { id: 4, value: "Kailash Colony > Batch 1" },
      {
        id: 5,
        value: (
          <div className="btn-group">
            <Button className=" small-btn" link="" title="Request Access" />
          </div>
        ),
      },
    ],
    [
      { id: 1, value: "QA Class 11" },
      { id: 2, value: "Kailash Colony, Room 1" },
      { id: 3, value: "11:00 AM - 12:00 PM" },
      { id: 4, value: "Kailash Colony > Batch 1" },
      {
        id: 5,
        value: (
          <div className="btn-group">
            <Button className=" small-btn" link="" title="Request Access" />
          </div>
        ),
      },
    ],
    [
      { id: 1, value: "QA Class 11" },
      { id: 2, value: "Kailash Colony, Room 1" },
      { id: 3, value: "11:00 AM - 12:00 PM" },
      { id: 4, value: "Kailash Colony > Batch 1" },
      {
        id: 5,
        value: (
          <div className="btn-group">
            <Button className=" small-btn" link="" title="Request Access" />
          </div>
        ),
      },
    ],
    [
      { id: 1, value: "QA Class 11" },
      { id: 2, value: "Kailash Colony, Room 1" },
      { id: 3, value: "11:00 AM - 12:00 PM" },
      { id: 4, value: "Kailash Colony > Batch 1" },
      {
        id: 5,
        value: (
          <div className="btn-group">
            <Button className=" small-btn" link="" title="Request Access" />
          </div>
        ),
      },
    ],
    [
      { id: 1, value: "QA Class 11" },
      { id: 2, value: "Kailash Colony, Room 1" },
      { id: 3, value: "11:00 AM - 12:00 PM" },
      { id: 4, value: "Kailash Colony > Batch 1" },
      {
        id: 5,
        value: (
          <div className="btn-group">
            <Button className=" small-btn" link="" title="Request Access" />
          </div>
        ),
      },
    ],
    [
      { id: 1, value: "QA Class 11" },
      { id: 2, value: "Kailash Colony, Room 1" },
      { id: 3, value: "11:00 AM - 12:00 PM" },
      { id: 4, value: "Kailash Colony > Batch 1" },
      {
        id: 5,
        value: (
          <div className="btn-group">
            <Button className=" small-btn" link="" title="Request Access" />
          </div>
        ),
      },
    ],
    [
      { id: 1, value: "QA Class 11" },
      { id: 2, value: "Kailash Colony, Room 1" },
      { id: 3, value: "11:00 AM - 12:00 PM" },
      { id: 4, value: "Kailash Colony > Batch 1" },
      {
        id: 5,
        value: (
          <div className="btn-group">
            <Button
              className=" small-btn"
              // onClick={}
              title="Request Access"
            />
          </div>
        ),
      },
    ],
  ],
};

const resultCard = [
  {
    id: "1",
    icon: "images/icon11.svg",
    className: "card-box redBG",
    title: "Total Question",
    result: "100",
  },
  {
    id: "2",
    icon: "images/icon1.svg",
    className: "cards-box greenBG",
    title: "Total Attempted",
    result: "0",
  },
  {
    id: "3",
    icon: "images/icon13.svg",
    className: "cards-box yellowBG",
    title: "Correct Answers",
    result: "0",
  },
  {
    id: "4",
    icon: "images/icon14.svg",
    className: "cards-box pinkBG",
    title: "Incorrect Answers",
    result: "0",
  },
  {
    id: "5",
    icon: "images/icon15.svg",
    className: "cards-box darkBlue",
    title: "Score",
    result: "0",
  },
];

// batch data
const batch_cards = [{ id: 1, batch_num: 5, day: 10, time: 20, center: 5 }];
const test_detail = [
  {
    id: 1,
    test_name: "Verbal Ability-AK Sir-Batch 1",
    path: "Week 1 / Live Classes",
    time: " 10:00 AM",
    date: "5 Apr, 2022",
    type: "Document",
  },
];
// overview report data
const overviewReportData = [
  {
    id: "1",
    icon: "images/icon16.svg",
    className: "card-box redBG",
    title: "Score",
    result: "0/240",
  },
  {
    id: "2",
    icon: "images/icon17.svg",
    className: "card-box darkBlue",
    title: "Highest Score",
    result: "30.58",
  },
  {
    id: "3",
    icon: "images/icon18.svg",
    className: "card-box pinkBG",
    title: "Percentage",
    result: "30.58",
  },
  {
    id: "4",
    icon: "images/icon19.svg",
    className: "card-box   greenBG",
    title: "Overall Rank",
    result: "143",
  },
  {
    id: "5",
    icon: "images/icon20.svg",
    className: "card-box yellowBG",
    title: "Center Rank",
    result: "1",
  },
];
// result data
const resultDetails = [
  {
    id: "1",
    className: "card-box ",
    title: "Test Name",
    result: "IIM Indore 1 ",
    subTitle: "(2021-22)",
  },
  {
    id: "2",
    className: "cards-box ",
    title: "Test Duration",
    result: "90 Minutes",
  },
  {
    id: "3",
    className: "cards-box ",
    title: "Center Name",
    result: "ZZ Testing",
  },
  {
    id: "4",
    className: "cards-box ",
    title: "Candidates Appeared",
    result: "206",
  },
];
const progressData = [
  {
    id: "1",
    className: "cards-box gray-card p-0",
    data: [
      {
        class: "Total Questions:",
        class_data: "600",
        id: 1,
      },
      {
        class: "Accuracy (%):",
        class_data: "0",
        id: 2,
      },
      {
        class: "Attempted:",
        class_data: "0",
        id: 3,
      },
      {
        class: "Correct:",
        class_data: "0",
        id: 4,
      },
    ],
  },
];
const circleProgressColor = [
  {
    id: 1,
    className: "greenBox ",
    color_name: "Correct",
  },
  {
    id: 2,
    className: "pinkBox ",
    color_name: "Incorrect",
  },
  {
    id: 3,
    className: "yellowBox ",
    color_name: "Skipped",
  },
];
const graphProgressColor = [
  {
    id: 1,
    className: "darkBox ",
    color_name: Auth?.user()?.name,
  },
  {
    id: 2,
    className: "themeBox ",
    color_name: "Topper",
  },
  {
    id: 3,
    className: "greenBox ",
    color_name: "Average",
  },
];
const filledProgressColor = [
  {
    id: 1,
    className: "darkBox ",
    color_name: "Quantitative Aptitude:	",
    color_data: "  59%",
  },
  {
    id: 2,
    className: "themeBox ",
    color_data: " 18%",
    color_name: "Verbal Ability Aptitude:	",
  },
  {
    id: 3,
    className: "pinkBox ",
    color_data: "10%",
    color_name: "Data Interpretation:	",
  },
  {
    id: 4,
    className: "yellowBox ",
    color_data: "13%",
    color_name: "Time Left:	",
  },
];
const qaGraphProgressColor = [
  {
    id: 1,
    className: "greenBox ",
    color_name: "Correct 	",
  },
  {
    id: 2,
    className: "themeBox",
    color_name: "Incorrect",
  },
  {
    id: 3,
    className: "yellowBox ",
    color_name: "Not Attempted	",
  },
  {
    id: 4,
    className: "darkBlueBox",
    color_name: "Total Questions	",
  },
];
// assignment data
const assignmentCards = [
  {
    id: "1",
    icon: "images/icon11.svg",
    className: "card-box redBG align-items-center",
    title: "Full Year ",
    sub_heading: "Home Assignment",
    isSubHeading: true,
  },
  {
    id: "2",
    icon: "images/icon11.svg",
    className: "card-box redBG align-items-center",
    sub_heading: " (For Target Year 2022)",
    isSubHeading: true,
    title: "Home Assignments",
  },
];
// quiz test
// overview report data
const quizData = [
  {
    id: "1",
    icon: "images/icon11.svg",
    className: "card-box redBG",
    title: "Total",
    result: "20",
  },
  {
    id: "2",
    icon: "images/icon14.svg",
    className: "card-box greenBG",
    title: "Correct",
    result: "0",
  },
  {
    id: "3",
    icon: "images/icon21.svg",
    className: "card-box pinkBG",
    title: "Incorrect",
    result: "2",
  },
  {
    id: "4",
    icon: "images/icon22.svg",
    className: "card-box yellowBG ",
    title: "Not Attempted",
    result: "18",
  },
];

// quiz test
export const AssignmentReportData = [
  {
    id: 1,
    icon: "images/icon11.svg",
    className: "card-box redBG",
    title: "Total Questions",
    result: "20",
  },
  {
    id: 2,
    icon: "images/icon1.svg",
    className: "cards-box greenBG",
    title: "Correct",
    result: "0",
  },
  {
    id: 3,
    icon: "images/icon22.svg",
    className: "cards-box redBG",
    title: "Incorrect",
    result: "2",
  },
  {
    id: 4,
    icon: "images/icon21.svg",
    className: "cards-box yellowBG",
    title: "Not Attempted",
    result: "18",
  },
  {
    id: 5,
    icon: "images/icon24.svg",
    className: "cards-box blueBG",
    title: "Total Time",
    result: "20",
  },
  {
    id: 6,
    icon: "images/icon25.svg",
    className: "cards-box darkBlue",
    title: "Time Remaining",
    result: "2",
  },
];
// dummt Table
export const DummyData = [
  {
    heading: "A",
    data: "hello",
  },
  {
    heading: "B",
    data: "hello B",
  },
  {
    heading: "C",
    data: "hello C",
  },
  {
    heading: "D",
    data: "hello D",
  }
]
export const data = {
  cards,
  reportcards,
  classData,
  // courseData,
  testData,
  exam_categories,
  reportData,
  courseDetailData,
  courseDetailFullData,
  testExamTableData,
  reportPerformanceData,
  mockTestExamTableData,

  // mathsExamData,
  reasoningExamData,
  scienceExamData,
  gkExamData,

  schedule_cards,
  batch_cards,
  schedule_page,
  test_schedule_data,
  class_table_data,

  resultCard,
  test_detail,

  resultDetails,
  progressData,
  overviewReportData,
  circleProgressColor,
  graphProgressColor,
  filledProgressColor,
  qaGraphProgressColor,

  assignmentCards,
  quizData,
  // quizQuestionData,
};
