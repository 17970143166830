const isResourceType = {
  documents: 1,
  videos: 2,
  notes: 3,
  exams: 4,
  liveClass: 5,
};

const isResourceTypeValue = {
  1: "documents",
  2: "videos",
  3: "notes",
  4: "exams",
  5: "liveClass",
};

export const getResourceType = (type) => {
  return isResourceType[type];
};

export const getResourceTypeLabel = (typeId) => {
  return isResourceTypeValue[typeId];
};
