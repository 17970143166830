import React from "react";
import Button from "../../utils/forms/Button";
import CloseIcon from "../../../assets/images/cross.svg";
import Image from "../../utils/image/Image";

const NavigationDrawer = ({
  mobileBtnModal,
  setMobileBtnModal,
  handleQuestionStatus,
  currentQuestionIndex,
  selectedTabId,
  questions,
  handleClearAnswer,
  playPauseTimer,
  handleSubmission,
  submitDataResponse,
  getSubmittedQuestionCount,
  setConfirmationModal,
  setSubmitSection,
  isEnabledSectionLimit,
  examStructure,
}) => {
  return (
    <div className={`mobile_btn_drawer ${mobileBtnModal ? "open" : ""}`}>
      <div className="drawer_close">
        <Image
          src={CloseIcon}
          alt="close"
          onClick={() => setMobileBtnModal(false)}
        />
      </div>
      <div className="mobile_btn_container">
        <div className="my-3">
          <button
            className="save_next"
            onClick={() => {
              handleQuestionStatus("save and next", false);
              setMobileBtnModal(false);
            }}
          >
            Save and Next
          </button>
          {/* <Button
            className="save_mark"
            link=""
            title="Save And Mark For Review"
            onClick={() => {
              handleQuestionStatus("save and mark", false);
              setMobileBtnModal(false);
            }}
          /> */}
          <Button
            className="mark_next"
            link=""
            title="Mark For Review And Next"
            onClick={() => {
              handleQuestionStatus("mark and next", false);
              setMobileBtnModal(false);
            }}
          />
        </div>

        <div className="navigation_btn my-3">
          <button
            className={`clear_btn ${
              currentQuestionIndex === 0 ? "bg-white text-black border" : ""
            }`}
            onClick={() => {
              handleQuestionStatus("active", false, true);
              setMobileBtnModal(false);
            }}
            disable={currentQuestionIndex === 0}
          >
            Previous
          </button>
          <button
            className={`clear_btn ${
              currentQuestionIndex === questions.length - 1
                ? "bg-white text-black border"
                : ""
            }`}
            onClick={() => {
              handleQuestionStatus("active", false, false);
              setMobileBtnModal(false);
            }}
            disable={currentQuestionIndex === questions.length - 1}
          >
            Next
          </button>
          <Button
            className="clear_btn"
            onClick={() => {
              handleClearAnswer(currentQuestionIndex, selectedTabId);
              setMobileBtnModal(false);
            }}
            title="Clear"
          />
        </div>
        <div className="d-flex justify-content-center my-3">
          {examStructure.at(-1)?.id !== selectedTabId &&
            isEnabledSectionLimit && (
              <Button
                className="submit_btn"
                title="Submit Section"
                onClick={async () => {
                  setSubmitSection(true);
                  playPauseTimer(false);
                }}
              />
            )}
          <Button
            className="submit_btn"
            onClick={async () => {
              playPauseTimer(false);
              await handleQuestionStatus("save and next", true);
              await handleSubmission(submitDataResponse);
              getSubmittedQuestionCount && (await getSubmittedQuestionCount());
              setConfirmationModal(true);
              setMobileBtnModal(false);
            }}
            link=""
            title="Submit"
          />
        </div>
      </div>
    </div>
  );
};

export default NavigationDrawer;
