import React from "react";

const HeaderBreadcrumb = ({ isSidebarHide, state, removeBackItems }) => {
  return (
    <div
      className={`breadcrumb_header ${
        isSidebarHide ? "header_close" : "header_open"
      }`}
    >
      <h1 style={{ color: "white" }}>
        {state?.breadcrumbs[state?.breadcrumbs?.length - 1]?.title.replaceAll(
          "_",
          " "
        )}
      </h1>
      <div></div>
      {state?.breadcrumbs?.map((d, i) => {
        return (
          <h3
            key={i}
            style={{
              color:
                state?.breadcrumbs?.length > 1 &&
                state?.breadcrumbs?.length - 1 === i
                  ? "#96a0b1"
                  : "white",
              display: "inline",
              cursor: "pointer",
            }}
            onClick={() => removeBackItems(d?.title, i)}
          >
            {d?.title?.replaceAll("_", " ")}{" "}
            {state.breadcrumbs.length > 1 &&
              state.breadcrumbs.length - 1 !== i &&
              "/"}{" "}
          </h3>
        );
      })}
    </div>
  );
};

export default HeaderBreadcrumb;
