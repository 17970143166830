import React from "react";

const Button = ({
  type,
  className,
  id,
  onClick,
  link,
  title,
  data,
  disable,
}) => {
  return (
    <button
      type={type}
      id={id}
      onClick={onClick}
      className={`btn theme-btn ${className ? className : ""}`}
      disabled={disable}
    >
      {title}
    </button>
  );
};
export default Button;
