import React from "react";
import CommonModal from "../../utils/modal/CommonModal";
import Button from "../../utils/forms/Button";

const SectioSubmit = ({ setShow, moveToNextSection, playPauseTimer }) => {
  return (
    <div style={{ fontSize: "16px", fontWeight: "400" }}>
      <p className="form-label text-center">
        Are you sure you want to submit the current Section?{" "}
      </p>
      <p className="form-label text-center">
        No changes will be allowed after submission.
      </p>
      <p className="form-label text-center">
        After submit the current section you will move to the next section
      </p>
      <div className="d-flex justify-content-center mb-3">
        <Button
          className="btn theme-btn small-btn py-3 me-4"
          title="Yes"
          onClick={() => {
            moveToNextSection();
            setShow((prev) => ({ ...prev, submitSection: false }));
          }}
        />
        <Button
          className="btn theme-btn small-btn py-3"
          title="No"
          onClick={() => {
            playPauseTimer(true);
            setShow((prev) => ({ ...prev, submitSection: false }));
          }}
        />
      </div>
    </div>
  );
};

const SectionSubmitModal = ({
  show,
  setShow,
  moveToNextSection,
  playPauseTimer,
}) => {
  return (
    <CommonModal
      show={show}
      close={() => setShow((prev) => ({ ...prev, submitSection: true }))}
      crossShow={false}
      body={
        <SectioSubmit
          setShow={setShow}
          moveToNextSection={moveToNextSection}
          playPauseTimer={playPauseTimer}
        />
      }
    />
  );
};

export default SectionSubmitModal;
