import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import userImage from "../../../assets/images/user.svg";
// import userImage from "../../../assets/images/search_icon.svg";
import Setting from "../../../assets/images/settings.png";
import Auth from "../../../auth/Auth";
import { userService } from "../../../_services/User.services";
import { AppContext } from "../../shared/AppContext";
import Threads from "./Threads";
import Image from "../../utils/image/Image";

const AllThreads = ({ isHistory }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setToaster, setSpinner, historyToDate, historyFromDate } =
    useContext(AppContext);
  const [threads, setThreads] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [filterData, setFilterData] = useState([]);
  const token = localStorage.getItem("PSIS_token");

  const getAllThreads = async () => {
    setSpinner(true);
    try {
      const res = await userService?.getThreads({
        type: "CLASS",
        token: `${Auth?.user()?.user_id}|${token}`,
        ...(isHistory
          ? {
              history: true,
              to: moment(historyToDate).format("YYYY-MM-DD"),
              from: moment(historyFromDate).format("YYYY-MM-DD"),
            }
          : ""),
      });
      if (res?.status >= 200 && res?.status <= 299) {
        const data = await res.json();
        setThreads(data?.threads);
        setSpinner(false);
      }
      if (res?.status === 401) {
        localStorage.clear();
        setSpinner(false);
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  const searchClass = (value) => {
    if (threads?.length) {
      let filterData = threads?.filter(
        (itm) =>
          itm?.name?.toLowerCase()?.includes(value?.toLowerCase()) ||
          itm?.userId?.toLowerCase()?.includes(value?.toLowerCase())
      );

      setFilterData(filterData);
    }
  };

  const handleLiveClass = (elem) => {
    if (moment(new Date()).isBetween(elem?.startAt, elem?.endAt)) {
      if (elem.blockedStudents.indexOf(String(Auth?.user().user_id)) === -1) {
        navigate(`/courses/live_class/${id}/chat/${elem?._id}`);
        return;
      }
      setToaster({
        show: true,
        type: "danger",
        content:
          "Oops! you did a mistake. You are blocked, talk to your service manager for immediate help.",
      });
    } else {
      setToaster({
        show: true,
        type: "danger",
        content: `Please wait... Class will be live on ${moment(
          new Date(elem?.startAt)
        ).format("DD-MM-YYYY hh:mm A")}`,
      });
    }

    if (moment(new Date()).isAfter(elem?.endAt)) {
      setToaster({
        show: true,
        type: "danger",
        content: `Class has been expired at ${moment(elem?.endAt).format(
          "HH:MM A"
        )}`,
      });
    }
  };

  useEffect(() => {
    setFilterData(threads);
  }, [threads]);

  useEffect(() => {
    searchClass(searchStr);
    //eslint-disable-next-line
  }, [searchStr]);

  useEffect(() => {
    if (token) getAllThreads();
  }, [token]);
  return (
    <>
      <div className="all-chats">
        <div className="live-class-header all-chats">
          <div className="row chat_header align-items-center">
            <div className="col-11 left_cont">
              <h2 className="ht mb-0">{Auth?.user()?.name}</h2>
            </div>
            <div className="col-1 right_cont d-flex justify-content-end pr-4 align-items-start">
              {/* {Auth.user().role !== 'student' && <Link to="select-participants">
              <Image src={Plus} alt="plus icon" className="small_iconS" />
            </Link>
            } */}
              <Link to={`/courses/live_class/${id}/settings`}>
                <Image
                  src={Setting}
                  alt="setting icon"
                  className="small_iconS"
                />
              </Link>
            </div>
          </div>
          <div className="chat_tab mt-2t">
            <button
              type="button"
              id=" liveclass"
              role="tab"
              data-rr-ui-event-key="liveclass"
              className="nav-link active liveclass-btn"
            >
              Live Class
            </button>
            <div className="chat_search">
              <div className="input-box mb-5 mt-4">
                <input
                  type={"text"}
                  onChange={(e) => {
                    setSearchStr(e.target.value);
                    // searchClass(e.target.value)
                  }}
                  className={`roleSelector search_icon search_icon01`}
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <TabLiveClass /> */}
        <div className="live-class-body">
          <div className="live-class tab-listing">
            {filterData?.map((item, i) => {
              return <Threads item={item} isHistory={false} index={i + 1} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllThreads;
