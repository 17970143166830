import React from "react";
import Button from "../../utils/forms/Button";
import CommonModal from "../../utils/modal/CommonModal";
import varfyIcon from "../../../assets/images/varification.svg";
import Image from "../../utils/image/Image";
import OtpInput from "react-otp-input";

const UpdateProfileModal = ({
  otp,
  edit,
  setEdit,
  setOtp,
  values,
  userData,
  bindField,
  handleSendOtp,
  updateProfileData,
  setShowUpdateProfileModal,
  showUpdateProfileModal,
  setShowNotificationPopUp,
  handleVerificationOtp,
}) => {
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <CommonModal
      className="profile-modal update-profile"
      show={showUpdateProfileModal}
      close={() => setShowUpdateProfileModal(false)}
      crossShow={
        [276, 278].includes(userData.org_id) &&
        Number(values.is_email_verified) === 0 &&
        Number(values?.is_phone_verified) === 0
          ? false
          : true
      }
      modalBodyClassName="update-profile-body"
      body={
        <div className="common_modal">
          {showUpdateProfileModal && !otp.modal && (
            <>
              <div className="round-body">
                <div className="">
                  <h2 className="modal-title text-center mb-2">
                    {/* {values.first_name} {values.last_name} - {values?.user_id} */}
                    Verfiy Your Phone & Email
                  </h2>
                </div>
                <div className="profile-form adminForm">
                  <form>
                    <div className="w-100">
                      <div className="w-100 m-1">
                        <div className="varify-input">
                          {values?.is_phone_verified ? (
                            <div className="vaified varify-input-content green-text">
                              <Image
                                src={varfyIcon}
                                alt="varification check icon "
                                width="16px"
                              />
                              <span>Verified</span>
                            </div>
                          ) : (
                            <div className="vaified varify-input-content ">
                              {edit && (
                                <div
                                  className="proceed btn theme-btn small-btn ms-3 mt-0"
                                  onClick={async () => {
                                    if (values?.phone?.length === 10) {
                                      setOtp((prev) => ({
                                        ...prev,
                                        modal: true,
                                        type: 1,
                                        error: null,
                                      }));
                                      await handleSendOtp(1);
                                    } else {
                                      setOtp((prev) => ({ ...prev, error: 1 }));
                                    }
                                  }}
                                >
                                  Verify Now
                                </div>
                              )}
                            </div>
                          )}
                          <div className="mt-5">
                            <label className="form-label">Phone No.</label>
                            <div className="d-flex align-items-center mt-2">
                              <input
                                style={{
                                  border: !edit ? "1px solid #d3d8de" : "0",
                                }}
                                className="me-4"
                                type="number"
                                name="phone"
                                value={values?.phone}
                                placeholder="Phone Number"
                                {...bindField("phone")}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  if (event.target.value.length === 10) {
                                    event.preventDefault();
                                  }
                                }}
                                disabled={edit}
                              />
                            </div>
                          </div>
                          {otp.error === 1 && (
                            <p className="text-danger">
                              Please enter a valid phone number
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="w-100 m-1">
                        <div className="varify-input">
                          {values?.is_email_verified ? (
                            <div className="vaified varify-input-content green-text">
                              <Image
                                src={varfyIcon}
                                alt="varification check icon "
                                width="16px"
                              />
                              <span>Verified</span>
                            </div>
                          ) : (
                            <div className="vaified varify-input-content">
                              {edit && (
                                <div
                                  className="proceed btn theme-btn small-btn ms-3 mt-0"
                                  onClick={async () => {
                                    if (validateEmail(values?.email)) {
                                      setOtp((prev) => ({
                                        ...prev,
                                        modal: true,
                                        type: 0,
                                        error: null,
                                      }));
                                      await handleSendOtp(0);
                                    } else {
                                      setOtp((prev) => ({ ...prev, error: 0 }));
                                    }
                                  }}
                                >
                                  Verify Now
                                </div>
                              )}
                            </div>
                          )}
                          <div className="mt-5">
                            <label className="form-label">Email</label>
                            <div className="d-flex align-items-center mt-2">
                              <input
                                style={{
                                  border: !edit ? "1px solid #d3d8de" : "0",
                                }}
                                className="me-4"
                                type="email"
                                name="email"
                                value={values.email}
                                {...bindField("email")}
                                disabled={edit}
                              />
                            </div>
                          </div>

                          {otp.error === 0 && (
                            <p className="text-danger">
                              Please enter a valid email addres
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer d-block">
                <div className="button-footer w-100 my-4">
                  {!edit && (
                    <Button
                      className="proceed btn small-btn"
                      onClick={(e) => {
                        updateProfileData(e);
                      }}
                      disable={
                        values.email !== "" && values?.phone?.length >= 10
                          ? false
                          : true
                      }
                      title="Update"
                    />
                  )}
                  {edit && (
                    <Button
                      className="proceed btn small-btn w-25 me-4 py-2 text-center"
                      title="Edit"
                      onClick={() => setEdit(!edit)}
                    />
                  )}
                  {![276, 278].includes(userData.org_id) && (
                    <div className="footer-Link">
                      <span
                        className="themeLink text-decoration-underline pointer"
                        onClick={() => {
                          setShowUpdateProfileModal(false);
                          setShowNotificationPopUp(true);
                        }}
                      >
                        Skip For Now
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <p className="ps-5">
                  <b>Note:</b> Please verify your phone number to proceed
                  further.
                </p>
              </div>
            </>
          )}
          {otp.modal && (
            <div>
              <h1 className="modal-title text-center mb-4">
                {otp.type === 0
                  ? "Verify Email Address"
                  : "Verify Phone Number"}
              </h1>
              <p className="dark-gray m-auto text-center sub-heading">
                Please enter your six digits verification code sent to &nbsp;
                {otp.type === 0 ? values.email : values.phone}
              </p>
              <div className="profile-form">
                <div className="profile-form adminForm">
                  <p className="dark-text form-heading text-center mt-2">OTP</p>
                  <form className="d-md-flex justify-content-center">
                    <OtpInput
                      className="mb-4 w-100"
                      value={otp.value}
                      onChange={(codesms) =>
                        setOtp((prev) => ({ ...prev, value: codesms }))
                      }
                      numInputs={6}
                      separator={<span style={{ width: "8px" }}></span>}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      inputStyle={{
                        borderRadius: "8px",
                        width: "54px",
                        height: "54px",
                        fontSize: "12px",
                        color: "#000",
                        fontWeight: "400",
                        caretColor: "blue",
                        border: "1px solid #CFD3DB",
                        backgroundColor: "#F8FAFF",
                      }}
                      focusStyle={{
                        border: "1px solid #CFD3DB",
                        outline: "none",
                      }}
                    />
                  </form>
                </div>
                <div className="modal-footer px-0 d-block overflow-hidden">
                  <div className="button-footer w-100">
                    <Button
                      className="proceed small-btn px-4 py-3"
                      // onClick={varfyModalClose}
                      onClick={handleVerificationOtp}
                      title="Submit"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      }
    />
  );
};

export default UpdateProfileModal;
