/**
 * Convert an ISO date string to DD/MM/YYYY format.
 * @param {string} isoDateStr - The ISO date string (e.g., "2024-10-16T12:24:40.000000Z").
 * @returns {string} The date formatted as DD/MM/YYYY.
 */

// Utility function to get the ordinal suffix for a given day
const getOrdinalSuffix = (day) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const value = day % 100;

  // Check if the day falls within the special case range (11th to 13th)
  if (value >= 11 && value <= 13) {
    return "th";
  }

  // Return the appropriate suffix from the array or default to "th"
  return suffixes[value % 10] || "th";
};

// Utility function to format date components
const formatDateComponent = (component) => String(component).padStart(2, "0");

// Helper function to format date components (DD/MM/YYYY)
const formatDate = (date, isYearWise = false) => {
  const day = formatDateComponent(date.getDate());
  const month = formatDateComponent(date.getMonth() + 1);
  const year = date.getFullYear();
  return isYearWise ? `${year}-${month}-${day}` : `${day}-${month}-${year}`;
};

// Utility function to format time from a Date object
export const formatTime = (timeInput) => {
  let timeValue;

  // Corrected check for Date instance or specific time string format
  if (typeof timeInput === "string" && /^\d{2}:\d{2}:\d{2}$/.test(timeInput)) {
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeInput.split(":").map(Number);
    timeValue = new Date();
    timeValue.setHours(hours, minutes, seconds, 0); // Set the time on a new Date object
  } else if (timeInput instanceof Date) {
    timeValue = timeInput;
  } else {
    timeValue = new Date(timeInput); // Fallback for other time formats
  }

  let hours = timeValue.getHours();
  const minutes = formatDateComponent(timeValue.getMinutes());
  const amPm = hours >= 12 ? "PM" : "AM";

  // Convert 24-hour time to 12-hour format
  hours = hours % 12 || 12; // Convert '0' hour to '12' for 12 AM/PM
  const formattedHours = formatDateComponent(hours);

  return `${formattedHours}:${minutes} ${amPm}`;
};

export const convertIsoToDDMMYYYY = (isoDateStr) => {
  const date = new Date(isoDateStr);
  return formatDate(date); // Reuse the date formatting logic
};

export const convertIsoToYYYYMMDD = (isoDateStr) => {
  const date = isoDateStr ? new Date(isoDateStr) : new Date();
  return formatDate(date, true);
};

export const convertIsoToDDMMYYYYTime = (isoDateStr) => {
  const date = new Date(isoDateStr);
  const formattedDate = formatDate(date); // Reuse the date formatting logic

  // Use the new formatTime utility function
  const formattedTime = formatTime(date);

  // Combine date and time into the desired format
  return `${formattedDate} ${formattedTime}`;
};

// Function to convert ISO date to '21st March, 2024' format
export const convertIsoToOrdinalDate = (isoDateStr) => {
  const date = new Date(isoDateStr);

  // Get day, month, and year components
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" }); // Full month name
  const year = date.getFullYear();

  // Get the ordinal suffix for the day
  const ordinalSuffix = getOrdinalSuffix(day);

  // Combine the components into the desired format
  return `${day}<sup>${ordinalSuffix}</sup> ${month}, ${year}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  const hour = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  return `${hour}:${minutes}:${seconds}`;
};

// Function to calculate the number of days between two dates
export const getNumberOfDays = (startDate, endDate) => {
  // Convert both dates to milliseconds
  const startMilliseconds = startDate.getTime();
  const endMilliseconds = endDate.getTime();

  // Calculate the difference in milliseconds
  const millisecondsDiff = Math.abs(endMilliseconds - startMilliseconds);

  // Convert milliseconds difference to days
  const daysDiff = Math.ceil(millisecondsDiff / (1000 * 3600 * 24));

  return daysDiff;
};

// Utility function to get formatted date range
export const calculateDateRange = (event, daysToAdd = 6) => {
  // Set the current date based on the provided event date or default to today's date
  const currentDate = event ? new Date(event) : new Date();
  const endDate = new Date(currentDate);
  // Calculate the end date by adding the specified number of days
  endDate.setDate(currentDate.getDate() + daysToAdd);

  // Format the start and end dates to ISO string (YYYY-MM-DD)
  const formattedCurrentDate = currentDate.toISOString().slice(0, 10);
  const formattedEndDate = endDate.toISOString().slice(0, 10);

  return {
    start: formattedCurrentDate,
    end: formattedEndDate,
  };
};

export const handleDateChange = async (
  event,
  isStart,
  state,
  setState,
  apiCall,
  apiCallback,
  params,
  setToaster
) => {
  const date = new Date(event);
  if (isStart) {
    const initialDates = calculateDateRange(event);
    setState((prev) => ({ ...prev, ...initialDates }));
    let apiParams = {
      start_date: initialDates.start,
      end_date: initialDates.end,
      ...params,
    };
    if (typeof params === "object" && params.isDate) {
      apiParams.date = initialDates.start;
      delete apiParams.start_date;
    }

    await apiCall(apiParams, apiCallback);
  } else {
    const numberOfDays = getNumberOfDays(new Date(state.start), date);
    if (!state.start) {
      setToaster({
        show: true,
        type: "warning",
        content: "Please choose a start date first, then select the end date",
      });
      return;
    }
    if (numberOfDays >= 7) {
      setToaster({
        show: true,
        type: "warning",
        content: "The maximum allowable date range is 7 days",
      });
      return;
    }
    const startDate = new Date(state.start);
    startDate.setDate(date.getDate() - 6);
    const formattedStartDate = startDate.toISOString().slice(0, 10);
    setState((prev) => ({ ...prev, start: formattedStartDate, end: event }));
    const apiParams = {
      start_date: formattedStartDate,
      end_date: event,
      ...params,
    };
    await apiCall(apiParams, apiCallback);
  }
};

export const convertMinutesToHours = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours}h ${remainingMinutes}m`;
};
