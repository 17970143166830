import React, { useContext, useEffect, useRef, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";

import Button from "../../utils/forms/Button";
import Question from "./Question"; // Import the QuestionComponent
import QuestionPallete from "./QuestionPallete"; // Import the Question Pallete

import { AppContext } from "../../shared/AppContext";
import { useDisableKeyboard } from "../../../hooks/useDisableKeyboard";
import { useHandleClassExam } from "../../../hooks/useHandleClassExam";
import { useQuestionStatus } from "../../../hooks/useQuestionStatus";
import { useQuestionType } from "../../../hooks/useQuestionType";
import "./numericKeypad.scss";
import "./exam.scss";
import MockExamSummaryModal from "./MockExamSummaryModal";
import MockSectionModal from "./MockSectionModal";
import ViewResultModal from "./ViewResultModal";
import MockCuetModal from "./MockCuetModal";
import MockWebcam from "./MockWebcam";
import CountModal from "./CountModal";
import NavigationDrawer from "./NavigationDrawer";
import { removeHTMLTags } from "../../utils/removeTags";
import moment from "moment";
import NMATModal from "./NmatModal";
import { createQuestionObject } from "../../utils/helpers/createQuestionObject";
import { updateSubmitDataResponse } from "../../utils/helpers/updateExamSubmitDataResponse";
import {
  useExamTemplateQuestions,
  useSubmittedQuestionCount,
  useSubmitTest,
  useTemplateStructureDetails,
  useUpdateSlotSection,
  useUpdateTestData,
} from "../../../hooks/hookList";
import SectionSubmitModal from "./SectionSubmitModal";

const Exams = () => {
  const { exam_id } = useParams();
  const navigate = useNavigate();
  const { setToaster, setSpinner } = useContext(AppContext);

  // States related to exam structure and questions
  const [count, setCount] = useState(1);
  const [questions, setQuestions] = useState([]);
  const [currentSlot, setCurrentSlot] = useState({});
  const [resultDate, setResultDate] = useState(null);
  const [allStructure, setAllStructure] = useState({});
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [examStructure, setExamStructureData] = useState([]);
  const [submitDataResponse, setSubmitDataResponse] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [submittedQuestionData, setSubmittedQuestionData] = useState([]);
  const [viewResultBtnTitle, setViewResultBtnTitle] = useState("View Result");

  // States related to timer and time tracking
  const [totalTimeSpent, setTotalTimeSpent] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [timeSpentSlot, setTimeSpentSlot] = useState(null);
  const [questionLimit, setQuestionLimit] = useState(null);
  const [selectedTabId, setSelectedTabId] = useState(null);
  const [slotTimeRemaining, setSlotTimeRemaining] = useState(null);
  const [sectionTimeRemaining, setSectionTimeRemaining] = useState(null);
  const [modal, setModal] = useState({
    section: false,
    confirmation: false,
    viewResult: false,
    cuet: false,
    nmat: false,
    count: false,
    mobileBtn: false,
  });
  const [isEnabled, setIsEnabled] = useState({
    slot: false,
    questionWiseMarking: false,
    subjectiveMarking: false,
    viewResultBtn: false,
    questionLimit: false,
    sectionLimit: false,
    submitSection: false,
    webcam: false,
    cameraAccess: false,
    disableKeyboard: false,
  });
  // States related to answers and input handling
  const [answer, setAnswer] = useState({
    oneWord: "",
    numeric: "",
    subjective: "",
  });
  // Refs
  const isTimerRunningRef = useRef(true);
  const keyboardRef = useRef();
  const dataRef = useRef([]);

  //hooks
  const { questionStatus } = useQuestionStatus();
  const { questionType } = useQuestionType();
  const { handleClasses } = useHandleClassExam();

  const { getData: getTemplateExamQuestion } = useExamTemplateQuestions(
    setSpinner,
    setToaster
  );

  // Template Strucuture Details
  const { getData: templateStructureDetails } = useTemplateStructureDetails(
    setSpinner,
    setToaster
  );

  // Submit Examss
  const { getData: submitTest } = useSubmitTest(setSpinner, setToaster);

  // Submitted Question Count
  const { getData: submittedQuestionCount } = useSubmittedQuestionCount(
    setSpinner,
    setToaster
  );

  // Update Exam Data
  const { getData: updateTestData } = useUpdateTestData(setSpinner, setToaster);

  // Update Slot Section
  const { getData: updateSlotSection } = useUpdateSlotSection(
    setSpinner,
    setToaster
  );

  const allSlotStructureKey =
    Object.keys(allStructure).length >= 1 && Object.keys(allStructure);
  const lastStructurekey = allSlotStructureKey[allSlotStructureKey.length - 1];
  const lastStructureObject = allStructure[lastStructurekey];

  const lastSlot = Object.keys(allStructure).pop();
  const lastSlotObject = allStructure[lastSlot];

  useDisableKeyboard(isEnabled.disableKeyboard, setToaster);

  // Play/Pause Timer Handler
  const playPauseTimer = (value) => {
    isTimerRunningRef.current = value;
  };

  // Match the current question id with parameter id
  const isQuestionType = (question, questionTypeId) => {
    return Number(question?.question_type_id) === Number(questionTypeId);
  };

  // Move To Next Slot
  const moveToNextSlot = () => {
    window.location.reload();
  };

  // Check if the result date is in the past
  const checkIfResultDateIsInPast = (resultDate, currentDate) => {
    return resultDate.isBefore(currentDate, "day");
  };

  // Check if the result date is today and the time has passed
  const checkIfResultDateIsTodayAndTimePassed = (resultDate, currentDate) => {
    return (
      resultDate.isSame(currentDate, "day") && resultDate.isBefore(currentDate)
    );
  };

  // Check Question Valid Status
  const checkQuestionStatus = (status) => {
    const isValid = ![0, 1, 2].includes(status);
    return isValid;
  };

  // Function handle to enable/disable keyboard based on the question type
  const isEnableKeyboard = (questionType) => {
    if ([1, 2, 5].includes(questionType)) {
      setIsEnabled((prev) => ({ ...prev, disableKeyboard: false }));
    } else {
      setIsEnabled((prev) => ({ ...prev, disableKeyboard: true }));
    }
  };

  // Update the button state based on subjective marking
  const updateButtonState = (subjectiveMarking) => {
    if (subjectiveMarking) {
      setIsEnabled((prev) => ({ ...prev, viewResultBtn: false }));
      setViewResultBtnTitle("Back To Mock Page");
    } else {
      setIsEnabled((prev) => ({ ...prev, viewResultBtn: true }));
    }
  };

  // Handle Submit Particular Section
  const handleSubmitSection = () => {
    setIsEnabled((prev) => ({
      ...prev,
      submitSection: true,
    }));
    playPauseTimer(false);
  };

  // Handle Submit Confirm Modal
  const handleSubmit = async () => {
    playPauseTimer(false);
    await handleQuestionStatus("save and next", true);
    await handleSubmission(submitDataResponse);
    await getSubmittedQuestionCount();
    setModal((prev) => ({ ...prev, confirmation: true }));
  };

  // Handle Section Change
  const handleSectionChange = (nextSectionId) => {
    if (isEnabled.sectionLimit) {
      setToaster({
        show: true,
        type: "danger",
        content:
          "Please wait until the designated current section time is over before proceeding to the next section.",
      });
    } else {
      handleTabSelect(nextSectionId, selectedTabId);
    }
  };

  // Final Exam Submission
  const finalSubmitData = (examStatistics) => {
    const combinedData = {
      exam_id: Number(exam_id),
      exam_status: isEnabled.subjectiveMarking ? 2 : 1,
      data: examStatistics.section_wise_data,
    };
    return combinedData;
  };

  // Function to calculate exam statistics based on exam structure and submission data
  const calculateExamStatistics = (responseData) => {
    const sectionWiseData = responseData.map((sectionData) => {
      const sectionId = Object.keys(sectionData)[0];
      const sectionObject = sectionData[sectionId];
      sectionObject.score = 0;
      sectionObject.total_marks = 0;
      sectionObject.totalCorrectMarks = 0;
      sectionObject.totalNegativeMarks = 0;
      // Filter questions with status key 0 or 1
      const filteredQuestions = sectionObject.question_data.filter(
        (question) => question.status === 0 || question.status === 1
      );
      // Update is_correct value to null for questions with statuses 2, 3, or 4
      for (const question of sectionObject.question_data) {
        // For Numerical,One Word, Subjective Answer Type Questions update the is_correct value
        if ([0, 1].includes(question?.status)) {
          // For Single, Multiple Answer Type Questions updating the is_correct value
          if ([1, 2, 6].includes(question.question_type_id)) {
            if (
              Number(question.selected_option) ===
              Number(question.correct_option)
            ) {
              question.is_correct = 0;
              // Calculate Score Question Wise
              if (isEnabled.questionWiseMarking) {
                sectionObject.totalCorrectMarks += Number(
                  question.correct_marks
                );
              }
            } else {
              question.is_correct = 1;
              // Calculate Score Question Wise
              if (isEnabled.questionWiseMarking) {
                sectionObject.totalNegativeMarks += Number(
                  question.negative_marks
                );
              }
            }
          }

          if ([3, 4].includes(question.question_type_id)) {
            if (
              removeHTMLTags(
                question?.selected_option?.toString()?.toLowerCase()?.trim()
              ) ===
              removeHTMLTags(
                question?.correct_option_value
                  ?.toString()
                  ?.toLowerCase()
                  ?.trim()
              )
            ) {
              question.is_correct = 0;
              // Calculating Question Wise Score
              if (isEnabled.questionWiseMarking) {
                sectionObject.totalCorrectMarks += Number(
                  question.correct_marks
                );
              }
            } else {
              question.is_correct = 1;
              // Calculating Question Wise Score
              if (isEnabled.questionWiseMarking) {
                sectionObject.totalNegativeMarks += Number(
                  question.negative_marks
                );
              }
            }
          }
        }

        // For Question Statuses 2,3,4 update the is_correct value set to null & Subjective Answer is_correct value should be null
        if ([2, 3, 4].includes(question.status)) {
          question.is_correct = null;
        }

        if (
          [0, 1].includes(question.status) &&
          Number(question.question_type_id) === 5
        ) {
          question.correct_options = null;
          question.is_correct = null;
        }
        if (isEnabled.questionWiseMarking) {
          // Calculating Total Marks Question Wise
          sectionObject.total_marks += Number(question.correct_marks);
        }
      }

      const sectionCorrectAnswers = filteredQuestions.reduce(
        (acc, question) => acc + (question.is_correct === 0 ? 1 : 0),
        0
      );

      const sectionIncorrectAnswers = filteredQuestions.reduce(
        (acc, question) => acc + (question.is_correct === 1 ? 1 : 0),
        0
      );
      sectionObject.correct_answers = sectionCorrectAnswers;
      sectionObject.incorrect_answers = sectionIncorrectAnswers;

      // Template Wise Marking Score Calculating
      if (isEnabled.questionWiseMarking) {
        sectionObject.score =
          sectionObject.totalCorrectMarks - sectionObject.totalNegativeMarks;
      } else {
        sectionObject.score =
          sectionCorrectAnswers * Number(sectionObject.correct_marks) -
          sectionIncorrectAnswers * Number(sectionObject.negative_marks);
      }
      // Template Wise Marking Total Marks Calculating
      if (!isEnabled.questionWiseMarking) {
        sectionObject.total_marks = isEnabled.questionLimit
          ? questionLimit * Number(sectionObject.correct_marks)
          : sectionObject.question_data.length *
            Number(sectionObject.correct_marks);
      }
      return {
        [sectionId]: sectionObject,
      };
    });

    // Return the section-wise data and overall statistics
    return {
      section_wise_data: sectionWiseData,
    };
  };

  // Main function to handle the result date checks
  const handleResultDateCheck = (data) => {
    const subjectiveMarking = data.is_subjective;
    const resultDate = moment(data.exam_data.result_date);
    const currentDate = moment();

    if (checkIfResultDateIsInPast(resultDate, currentDate)) {
      updateButtonState(subjectiveMarking);
    } else if (checkIfResultDateIsTodayAndTimePassed(resultDate, currentDate)) {
      updateButtonState(subjectiveMarking);
    } else {
      setViewResultBtnTitle("Back To Home Page");
      setIsEnabled((prev) => ({ ...prev, viewResultBtn: false }));
    }
  };

  const examQuestionsCallback = (
    questions,
    templateDetails,
    isEverySectionNull,
    currentSectionStructure,
    totalTimeSpent
  ) => {
    if (questions.success) {
      const { data: questionData } = questions;
      setQuestions(questionData);
      const isQuestionType =
        questionData[currentQuestionIndex]?.question_type_id;
      const currentQuestionInputAnswer =
        questionData[currentQuestionIndex]?.student_test_questions
          ?.input_answer;

      const getCurrentQuestion = questionData?.findIndex(
        (ques) => Number(ques.student_test_questions?.status || 0) === 3
      );
      isEnableKeyboard(isQuestionType);
      if (questionData?.length > 0 && Number(isQuestionType) === 3) {
        setAnswer((prev) => ({
          ...prev,
          numeric: currentQuestionInputAnswer,
        }));
      } else if (questionData?.length > 0 && Number(isQuestionType) === 4) {
        keyboardRef?.current?.setInput(currentQuestionInputAnswer);
        setAnswer((prev) => ({
          ...prev,
          oneWord: currentQuestionInputAnswer,
        }));
      } else if (questionData?.length > 0 && Number(isQuestionType) === 5) {
        setAnswer((prev) => ({
          ...prev,
          subjective: currentQuestionInputAnswer,
        }));
      }
      const sectionId = isEverySectionNull
        ? templateDetails.structure[0]?.id
        : currentSectionStructure.id;
      const isQuestionWiseMarking =
        Number(templateDetails.exam_data.marking_scheme) === 1 ? true : false;
      const questionObject = createQuestionObject(
        sectionId,
        questionData,
        questionStatus("active"),
        isQuestionWiseMarking
      );
      if (getCurrentQuestion !== -1) {
        setCurrentQuestionIndex(getCurrentQuestion);
      } else {
        setCurrentQuestionIndex(0);
      }
      // Update submit data response with relevant information
      updateSubmitDataResponse(
        isEverySectionNull
          ? templateDetails.structure[0]?.id
          : currentSectionStructure.id,
        (section) => {
          if (section) {
            const sectionId = isEverySectionNull
              ? templateDetails.structure[0]?.id
              : currentSectionStructure.id;
            const currentSection = section[sectionId];
            const sectionDuration = isEverySectionNull
              ? Number(templateDetails.structure[0]?.duration)
              : Number(currentSectionStructure.duration);
            const questionWiseCorrectMarks = isEverySectionNull
              ? templateDetails.structure[0]?.correct_marks
              : currentSectionStructure.correct_marks;
            const questionWiseNegativeMarks = isEverySectionNull
              ? templateDetails.structure[0]?.negative_marks
              : currentSectionStructure.negative_marks;

            return {
              total_questions: questionData.length,
              section_time_spent: Number(currentSectionStructure?.time_spent),

              correct_answers: currentSection?.correct_answers,
              incorrect_answers: currentSection?.incorrect_answers,
              score: currentSection?.score,
              total_marks: currentSection?.total_marks,
              total_attempt:
                currentSectionStructure?.total_attempt !== undefined
                  ? Number(currentSectionStructure?.total_attempt)
                  : 0,
              question_data: [...currentSection?.question_data],
              status: 1,
              duration: sectionDuration,
              ...(Number(templateDetails.exam_data.marking_scheme) === 0 && {
                correct_marks: questionWiseCorrectMarks,
              }),
              ...(Number(templateDetails.exam_data.marking_scheme) === 0 && {
                negative_marks: questionWiseNegativeMarks,
              }),
            };
          }
        },
        setSubmitDataResponse,
        questionObject
      );
      setTotalTimeSpent(totalTimeSpent);
      if (templateDetails.exam_data.slots.toLowerCase() == "yes") {
        const calculateTotalTimeSpent = (data) => {
          let totalTimeSpent = 0;
          Object.values(data).forEach((category) => {
            category.forEach((item) => {
              // Assuming time_spent is a string representing minutes
              const timeSpent = item.time_spent
                ? parseInt(item.time_spent !== null ? item.time_spent : 0, 10)
                : 0;
              totalTimeSpent += timeSpent;
            });
          });

          return totalTimeSpent;
        };
        const totalTimeSpent = calculateTotalTimeSpent(
          templateDetails.all_structure
        );
        setTimeRemaining((prevTime) => prevTime - totalTimeSpent);
      } else {
        setTimeRemaining((prevTime) => prevTime - totalTimeSpent);
      }
    }
  };

  const templateDetailsCallback = async (templateDetails) => {
    if (templateDetails.success) {
      if (templateDetails.data) {
        const {
          all_structure = [],
          exam_data = {},
          current_slot,
          is_subjective,
          structure = [],
          total_time_spent,
        } = templateDetails.data;

        if (exam_data) {
          if (exam_data.slots.toLowerCase() === "yes") {
            // Set Question Wise Marking when 1 other wise template marking scheme 0
            setIsEnabled((prev) => ({
              ...prev,
              questionWiseMarking:
                Number(exam_data.marking_scheme) === 1 ? true : false,
              slot: true,
            }));
            setTimeSpentSlot(Number(total_time_spent));
            setAllStructure(all_structure);
            setCurrentSlot(current_slot);
            setTotalQuestions(
              structure.reduce((totalQuestions, section) => {
                const totalQ = Number(section.total_questions) + totalQuestions;
                return totalQ;
              }, 0)
            );
          } else {
            // Set Question Wise Marking when 1 other wise template marking scheme 0
            setIsEnabled((prev) => ({
              ...prev,
              slot: false,
              questionWiseMarking:
                Number(exam_data.marking_scheme) === 1 ? true : false,
              webcam: exam_data?.moderation === 0 ? false : true,
            }));
            setTotalQuestions(Number(exam_data.total_questions));
            handleResultDateCheck(templateDetails.data);
          }
        }
        if (structure) {
          // Total Slot Time
          const totalSlotTime = structure.reduce((totalTime, section) => {
            const duration = Number(section.duration) * 60;
            return Number(totalTime) + Number(duration);
          }, 0);

          // Check all the section spent status is zero for first time
          const isEverySectionNull = structure.every(
            (structureIndex) =>
              structureIndex.spent_status === null ||
              structureIndex.spent_status === 0
          );

          // Find the current section in resume test whose status is 1
          const currentSectionStructure = structure.find(
            (structureIndex) => structureIndex.spent_status === 1
          );

          const currentSectionTimeLimit =
            Number(structure[0]?.section_wise_limit) !== 0;

          // Calculate total time spent across all sections
          const totalTimeSpent = structure.reduce((totalTime, section) => {
            const timeSpent = Number(section.time_spent);
            if (!Number.isNaN(timeSpent)) {
              return Number(totalTime) + Number(timeSpent);
            } else {
              return Number(totalTime);
            }
          }, 0);

          setIsEnabled((prev) => ({
            ...prev,
            subjectiveMarking: is_subjective,
          }));
          setResultDate(exam_data?.result_date);
          setTimeRemaining(Number(exam_data.duration) * 60);
          if (Number(total_time_spent) !== 0) {
            setSlotTimeRemaining(totalSlotTime - Number(totalTimeSpent));
          } else {
            setSlotTimeRemaining(totalSlotTime);
          }
          if (isEverySectionNull) {
            // Set the selected tab, question limit, and exam structure data
            setSelectedTabId(structure[0]?.id);
            setExamStructureData(structure);
            if (structure[0]?.question_limit != 0) {
              setIsEnabled((prev) => ({ ...prev, questionLimit: true }));
              setQuestionLimit(Number(structure[0]?.limit));
            }
            if (currentSectionTimeLimit) {
              setIsEnabled((prev) => ({ ...prev, sectionLimit: true }));
              setSectionTimeRemaining(Number(structure[0]?.duration) * 60);
            }
          } else {
            setSelectedTabId(currentSectionStructure?.id);
            setExamStructureData(structure);
            if (
              currentSectionStructure &&
              Number(currentSectionStructure.question_limit) !== 0
            ) {
              setIsEnabled((prev) => ({ ...prev, questionLimit: true }));
              setQuestionLimit(Number(currentSectionStructure?.limit));
            }
            if (currentSectionTimeLimit) {
              setIsEnabled((prev) => ({ ...prev, sectionLimit: true }));
              setSectionTimeRemaining(
                Number(currentSectionStructure?.duration) * 60 -
                  Number(currentSectionStructure?.time_spent)
              );
            }
          }
          const templateQuestionParams = {
            exam_id,
            exam_template_structure_id: isEverySectionNull
              ? structure[0]?.id
              : currentSectionStructure?.id,
          };
          await getTemplateExamQuestion(templateQuestionParams, (data) =>
            examQuestionsCallback(
              data,
              templateDetails.data,
              isEverySectionNull,
              currentSectionStructure,
              totalTimeSpent
            )
          );
        }
      }
    }
  };

  const getExamStructure = async () => {
    await templateStructureDetails({ exam_id }, templateDetailsCallback);
  };

  const sectionChangeCallback = (
    nextSectionQuestions,
    nextSubjectId,
    currentSubjectId
  ) => {
    if (nextSectionQuestions.success) {
      const { data: questionData } = nextSectionQuestions;
      const currentSectionIndex = submitDataResponse.findIndex(
        (item) => Number(Object.keys(item)[0]) === Number(currentSubjectId)
      );
      const nextSectionIndex = submitDataResponse.findIndex(
        (item) => Number(Object.keys(item)[0]) === Number(nextSubjectId)
      );
      const nextSectionStructure = examStructure.find(
        (d) => Number(d.id) === Number(nextSubjectId)
      );
      setQuestions(questionData);
      setCurrentQuestionIndex(0);
      if (currentSectionIndex !== -1) {
        updateSubmitDataResponse(
          Number(currentSubjectId),
          (currentSection) => {
            return {
              ...currentSection,
              status: 0,
            };
          },
          setSubmitDataResponse
        );
        setSelectedTabId(Number(nextSubjectId));
      }

      if (nextSectionIndex === -1) {
        // Include the score key in the data.data object

        const nextQuestionObject = createQuestionObject(
          Number(nextSubjectId),
          questionData,
          questionStatus("active"),
          isEnabled.questionWiseMarking
        );

        // Update the submit data response for the current section
        updateSubmitDataResponse(
          Number(nextSubjectId),
          (section) => {
            if (section) {
              const nextSection = section[Number(nextSubjectId)];
              return {
                total_questions: questionData.length,
                section_time_spent: Number(nextSection?.section_time_spent),
                correct_answers: nextSection?.correct_answers,
                incorrect_answers: nextSection?.incorrect_answers,
                score: Number(nextSection?.score),
                total_marks: Number(nextSection?.total_marks),
                ...(!isEnabled.questionWiseMarking && {
                  correct_marks: Number(nextSectionStructure?.correct_marks),
                }),
                ...(!isEnabled.questionWiseMarking && {
                  negative_marks: Number(nextSectionStructure?.negative_marks),
                }),
                total_attempt: Number(
                  examStructure.find(
                    (d) => Number(d.id) === Number(nextSubjectId)
                  )?.total_attempt
                ),
                question_data: [...nextSection?.question_data] || [],
                status: 1,
                duration: Number(nextSectionStructure?.duration),
              };
            }
          },
          setSubmitDataResponse,
          nextQuestionObject
        );

        if (
          nextSectionStructure &&
          Number(nextSectionStructure.question_limit) !== 0
        ) {
          setIsEnabled((prev) => ({ ...prev, questionLimit: true }));
          setQuestionLimit(Number(nextSectionStructure.limit));
        } else {
          setIsEnabled((prev) => ({ ...prev, questionLimit: false }));
        }
        if (
          nextSectionStructure &&
          Number(nextSectionStructure.section_wise_limit) !== 0
        ) {
          setIsEnabled((prev) => ({ ...prev, sectionLimit: true }));
          setSectionTimeRemaining(Number(nextSectionStructure.duration) * 60);
        } else {
          setIsEnabled((prev) => ({ ...prev, sectionLimit: false }));
        }
        // Set the remaining time for the new section
        // setSectionTimeRemaining(Number(nextSectionStructure?.duration) * 60);

        // Set the selected tab to the next tab (section)
        setSelectedTabId(Number(nextSubjectId));
      } else {
        if (
          nextSectionStructure &&
          Number(nextSectionStructure.question_limit) !== 0
        ) {
          setIsEnabled((prev) => ({ ...prev, questionLimit: true }));
          setQuestionLimit(Number(nextSectionStructure.limit));
        } else {
          setIsEnabled((prev) => ({ ...prev, questionLimit: false }));
        }
        if (
          nextSectionStructure &&
          Number(nextSectionStructure.section_wise_limit) !== 0
        ) {
          setIsEnabled((prev) => ({ ...prev, sectionLimit: true }));
          setSectionTimeRemaining(Number(nextSectionStructure.duration) * 60);
        } else {
          setIsEnabled((prev) => ({ ...prev, sectionLimit: false }));
        }
        // If the next section is found, update its status to 1
        updateSubmitDataResponse(
          Number(nextSubjectId),
          (nextSection) => {
            return {
              ...nextSection,
              total_attempt: Number(
                examStructure.find(
                  (d) => Number(d.id) === Number(nextSubjectId)
                )?.total_attempt
              ),
              status: 1,
            };
          },
          setSubmitDataResponse
        );
        setSelectedTabId(Number(nextSubjectId));
      }
    }
  };

  const handleTabSelect = async (nextSubjectId, currentSubjectId) => {
    playPauseTimer(false);
    const tabSelectParams = {
      exam_id: exam_id,
      exam_template_structure_id: Number(nextSubjectId),
    };
    await getTemplateExamQuestion(tabSelectParams, (data) =>
      sectionChangeCallback(data, nextSubjectId, currentSubjectId)
    );
    playPauseTimer(true);
  };

  // Set the answer based on the question type id
  const setAnswerBasedOnType = (questionTypeId, selectedOption) => {
    const answerHandlers = {
      4: (selectedOption) => keyboardRef?.current?.setInput(selectedOption),
      3: (numericAnswer) =>
        setAnswer((prev) => ({ ...prev, numeric: numericAnswer })),
      5: (subjectiveAnswer) =>
        setAnswer((prev) => ({ ...prev, subjective: subjectiveAnswer })),
    };
    const handler = answerHandlers[questionTypeId];
    if (handler) {
      handler(selectedOption);
    }
  };

  // Handle moving to the next question
  const handleNext = (currentIndex) => {
    if (currentIndex < questions.length - 1) {
      updateSubmitDataResponse(
        selectedTabId,
        (section) => {
          const currentQuestion = section.question_data[currentIndex];
          const nextQuestion = section.question_data[currentIndex + 1];

          isEnableKeyboard(nextQuestion?.question_type_id);
          if (
            [3, 4, 5].includes(nextQuestion?.question_type_id) &&
            !["0", "-1", "1"].includes(nextQuestion?.selected_option)
          ) {
            setAnswerBasedOnType(
              nextQuestion?.question_type_id,
              nextQuestion?.selected_option
            );
          }
          if (checkQuestionStatus(currentQuestion.status)) {
            currentQuestion.status = questionStatus("active");
          }
          if (checkQuestionStatus(nextQuestion.status)) {
            nextQuestion.status = questionStatus("active");
          }
          if (count === 1 && currentQuestion.status === 3) {
            setModal((prev) => ({ ...prev, count: true }));
          }
          return section;
        },
        setSubmitDataResponse
      );

      keyboardRef?.current?.setInput("");
    }
    setCount((prev) => prev + 1);
    setCurrentQuestionIndex(currentIndex + 1);
    keyboardRef?.current?.setInput("");
    setAnswer({
      numeric: "",
      oneWord: "",
      subjective: "",
    });
  };

  // Handle moving to the previous question
  const handlePrevious = (currentIndex) => {
    if (currentIndex > 0) {
      updateSubmitDataResponse(
        selectedTabId,
        (section) => {
          const currentQuestionData = section.question_data[currentIndex];
          const previousQuestionData = section.question_data[currentIndex - 1];
          isEnableKeyboard(previousQuestionData?.question_type_id);

          if (
            [3, 4, 5].includes(previousQuestionData?.question_type_id) &&
            !["0", "1", "-1"].includes(previousQuestionData?.selected_option)
          ) {
            setAnswerBasedOnType(
              previousQuestionData?.question_type_id,
              previousQuestionData?.selected_option
            );
          }
          if (
            section.question_data.length === questions.length &&
            checkQuestionStatus(currentQuestionData.status)
          ) {
            currentQuestionData.status = questionStatus("active");
          }

          if (checkQuestionStatus(previousQuestionData.status)) {
            previousQuestionData.status = questionStatus("active");
          }
          if (count === 1 && currentQuestionData.status === 3) {
            setModal((prev) => ({ ...prev, count: true }));
          }
          return section;
        },
        setSubmitDataResponse
      );
      setCount((prev) => prev + 1);
      setCurrentQuestionIndex(currentIndex - 1);
      keyboardRef?.current?.setInput("");
      setAnswer({
        numeric: "",
        oneWord: "",
        subjective: "",
      });
    }
  };

  // Check is the current question is answered
  const isQuestionAnswered = (
    currentQuestion,
    numericAnswer,
    keyboardRef,
    subjectiveAnswer
  ) => {
    const isNumericalQuestion = isQuestionType(currentQuestion, 3);
    const isOneWordQuestion = isQuestionType(currentQuestion, 4);
    const isSubjectiveQuestion = isQuestionType(currentQuestion, 5);
    const isNumericalQuestionAnswered =
      isNumericalQuestion && String(numericAnswer) && String(numericAnswer);
    const isOneWordQuestionAnswered =
      isOneWordQuestion && keyboardRef?.current?.getInput() !== "";
    const isSubjectiveQuestionAnswered =
      isSubjectiveQuestion && subjectiveAnswer && subjectiveAnswer;

    return (
      isNumericalQuestionAnswered ||
      isOneWordQuestionAnswered ||
      isSubjectiveQuestionAnswered
    );
  };

  // Set the answered in state of the current question
  const getSelectedOption = (
    currentQuestion,
    numericAnswer,
    keyboardRef,
    subjectiveAnswer
  ) => {
    const answerMap = {
      4: keyboardRef?.current?.getInput(),
      3: numericAnswer,
      5: subjectiveAnswer,
    };
    return answerMap[currentQuestion?.question_type_id];
  };

  // Handle for Update Queston Status
  const handleQuestionStatus = async (
    key,
    submit = false,
    isPrevious = false
  ) => {
    const checkQuestionStatus = [0, 1, 2, 3].includes(
      Number(questionStatus(key))
    );
    if (!checkQuestionStatus) return;

    updateSubmitDataResponse(
      selectedTabId,
      (section) => {
        const currentQuestion = section.question_data[currentQuestionIndex];
        const isLastQuestion =
          currentQuestionIndex === section.question_data.length - 1;

        const isQuestionAnsweredResult = isQuestionAnswered(
          currentQuestion,
          answer.numeric,
          keyboardRef,
          answer.subjective
        );
        const selectedOption = getSelectedOption(
          currentQuestion,
          answer.numeric,
          keyboardRef,
          answer.subjective
        );
        if (
          ![3, 4, 5].includes(currentQuestion?.question_type_id) &&
          ["-1", null].includes(currentQuestion?.selected_option) &&
          ![3].includes(Number(questionStatus(key)))
        ) {
          if (!submit) {
            setToaster({
              show: true,
              type: "danger",
              content:
                "Please select an option before moving to the next question.",
            });
          }
          return section;
        }
        if (isQuestionAnsweredResult) {
          currentQuestion.selected_option = selectedOption;
        } else if (
          [3, 4, 5].includes(currentQuestion?.question_type_id) &&
          ![3].includes(Number(questionStatus(key)))
        ) {
          if (!submit) {
            setToaster({
              show: true,
              type: "danger",
              content:
                "Please type an answer before moving to the next question",
            });
          }
        }

        if (!isLastQuestion) {
          if (
            [0, 1].includes(Number(currentQuestion?.status)) &&
            [0, 1].includes(Number(questionStatus(key)))
          ) {
            section.total_attempt = section.total_attempt;
          }
          if (
            [2, 3, 4].includes(Number(currentQuestion?.status)) &&
            [0, 1].includes(Number(questionStatus(key))) &&
            !["-1", null].includes(currentQuestion?.selected_option)
          ) {
            section.total_attempt = Number(section.total_attempt) + 1;
          }

          if (
            [0, 1].includes(currentQuestion?.status) &&
            Number(questionStatus(key) === 2)
          ) {
            section.total_attempt = section.total_attempt - 1;
          }

          if (
            !["-1", null].includes(currentQuestion?.selected_option) ||
            [3].includes(Number(questionStatus(key)))
          ) {
            currentQuestion.status =
              Number(questionStatus(key)) !== 3
                ? Number(questionStatus(key))
                : currentQuestion?.status;
            if (!submit) {
              if (!isPrevious) {
                handleNext(currentQuestionIndex);
              } else {
                handlePrevious(currentQuestionIndex);
              }
            }
          }
        } else {
          if (!isEnabled.sectionLimit) {
            const currentTabIndex = examStructure.findIndex(
              (d) => Number(d.id) === Number(selectedTabId)
            );
            const nextTabIndex = currentTabIndex + 1;
            if (!["-1", null].includes(currentQuestion?.selected_option)) {
              currentQuestion.status = questionStatus(key);
            }

            if (nextTabIndex < examStructure.length) {
              handleTabSelect(examStructure[nextTabIndex].id);
            }
          } else {
            if (!isPrevious) {
              currentQuestion.status = questionStatus(key);
              if (
                [0, 1].includes(Number(currentQuestion?.status)) &&
                [0, 1].includes(Number(questionStatus(key)))
              ) {
                section.total_attempt = section.total_attempt;
              }

              if (
                [2, 3, 4].includes(Number(currentQuestion?.status)) &&
                [0, 1].includes(Number(questionStatus(key))) &&
                !["-1", null].includes(currentQuestion?.selected_option)
              ) {
                section.total_attempt = Number(section.total_attempt) + 1;
              }

              if (
                [0, 1].includes(currentQuestion?.status) &&
                Number(questionStatus(key) === 2)
              ) {
                section.total_attempt = section.total_attempt - 1;
              }
            } else {
              if (![0, 1].includes(currentQuestion?.status)) {
                currentQuestion.status = questionStatus(key);
              }
              if (!submit) {
                handlePrevious(currentQuestionIndex);
              }
            }
          }
        }

        return section;
      },
      setSubmitDataResponse
    );
  };

  const handleSubmission = async (responseData) => {
    let calculationExam = calculateExamStatistics(responseData);
    await submitTest(finalSubmitData(calculationExam));
  };

  // Total Question Count API - User Submits Exam
  const getSubmittedQuestionCount = async () => {
    setSpinner(true);
    const params = {
      exam_id,
    };
    await submittedQuestionCount(params, (data) =>
      setSubmittedQuestionData(data.data)
    );
  };

  const getUpdateTestData = async () => {
    const params = {
      exam_id,
    };
    await updateTestData(params);
  };

  // Update Cuet Slot API
  const getUpdateSlotSection = async (id, status, reload = false) => {
    setSpinner(true);
    const params = {
      id,
      ...status,
    };
    await updateSlotSection(params);
    if (reload) {
      window.location.reload();
    }
  };

  // Function to handle option changes for a specific question
  const handleOptionChange = (e, questionId) => {
    const selectedOption = Number(e.target.value);
    updateSubmitDataResponse(
      selectedTabId,
      (section) => {
        const questionIndex = section.question_data.findIndex(
          (question) => question.question_id === questionId
        );
        if (isEnabled.questionLimit && section.total_attempt >= questionLimit) {
          setToaster({
            show: true,
            type: "danger",
            content:
              "You have reached the maximum attempt limit for this current section.",
          });
        } else if (questionIndex !== -1) {
          const currentQuestion = section.question_data[questionIndex];
          currentQuestion.selected_option = selectedOption;
          // currentQuestion.is_correct =
          // Number(currentQuestion.correct_option) === selectedOption ? 0 : 1;
        }

        return section;
      },
      setSubmitDataResponse
    );
  };

  // Function to check if a specific option is selected for a question
  const isOptionSelected = (question_id, option_id) => {
    const currentSection = submitDataResponse.find(
      (section) => section[selectedTabId]
    );

    const selectedQuestion = currentSection?.[
      selectedTabId
    ]?.question_data?.find((question) => question.question_id == question_id);

    const isChecked =
      selectedQuestion?.selected_option == option_id ? true : false;

    return isChecked;
  };

  const handleOneWordInputChange = (input) => {
    keyboardRef?.current?.setInput(input);
  };

  const handleOneWordAnswer = (value, question_id) => {
    const currentSection = submitDataResponse.find(
      (section) => section[selectedTabId]
    );
    const selectedQuestion = currentSection?.[
      selectedTabId
    ]?.question_data?.find(
      (question) => Number(question.question_id) === Number(question_id)
    );
    if (selectedQuestion) {
      setAnswer((prev) => ({ ...prev, oneWord: value }));
      keyboardRef?.current?.setInput(value);
    }
  };

  // onChange Handler Of Numeric Question
  const handleNumericAnswer = (key) => {
    if (key === "⌫") {
      // If numericAnswer is null or empty, do nothing
      if (answer.numeric === null || answer.numeric === "") {
        return;
      }
      if (answer.numeric.length > 1) {
        setAnswer((prev) => ({
          ...prev,
          numeric: answer.numeric && answer.numeric.slice(0, -1),
        }));
      } else {
        setAnswer((prev) => ({ ...prev, numeric: "" }));
      }
    } else {
      setAnswer((prev) => ({
        ...prev,
        numeric: answer.numeric !== null ? answer.numeric + "" + key : key,
      }));
    }
  };

  // Handle For Subject Answer for Question Type - 5
  const handleSubjectiveAnswer = (value, question_id) => {
    const currentSection = submitDataResponse.find(
      (section) => section[selectedTabId]
    );
    const selectedQuestion = currentSection?.[
      selectedTabId
    ]?.question_data?.find(
      (question) => Number(question.question_id) === Number(question_id)
    );
    if (selectedQuestion) {
      setAnswer((prev) => ({ ...prev, subjective: value }));
    }
  };

  // Function to handle clicking on a question, updating its status, and setting the current question index
  const handleQuestionClick = (nextQuestionIndex) => {
    const currentSection = submitDataResponse.find(
      (section) => section[selectedTabId]
    );
    const currentQuestion = currentSection[selectedTabId]?.question_data?.find(
      (d, i) => i === currentQuestionIndex
    );
    if (
      currentQuestion.selected_option !== null &&
      [0, 1].includes(currentQuestion?.status)
    ) {
      setCurrentQuestionIndex(nextQuestionIndex);
      updateSubmitDataResponse(
        selectedTabId,
        (section) => {
          const currentQuestion = section.question_data[currentQuestionIndex];
          const nextQuestion = section.question_data[nextQuestionIndex];
          const isQuestionAnsweredResult = isQuestionAnswered(
            currentQuestion,
            answer.numeric,
            keyboardRef,
            answer.subjective
          );
          const selectedOption = getSelectedOption(
            currentQuestion,
            answer.numeric,
            keyboardRef,
            answer.subjective
          );
          isEnableKeyboard(nextQuestion?.question_type_id);

          if (
            [3, 4, 5].includes(nextQuestion?.question_type_id) &&
            !["-1", null].includes(nextQuestion?.selected_option)
          ) {
            setAnswerBasedOnType(
              nextQuestion?.question_type_id,
              nextQuestion?.selected_option
            );
          } else {
            keyboardRef?.current?.setInput(nextQuestion.selected_option);
            setAnswer((prev) => ({
              ...prev,
              subjective: nextQuestion?.selected_option,
              numeric: nextQuestion?.selected_option,
              oneWord: nextQuestion?.selected_option,
            }));
          }
          if (checkQuestionStatus(currentQuestion.status)) {
            if (isQuestionAnsweredResult) {
              currentQuestion.selected_option = selectedOption;
            }
            currentQuestion.status = questionStatus("active");
          }
          if (checkQuestionStatus(nextQuestion.status)) {
            nextQuestion.status = questionStatus("active");
          }
          return section;
        },
        setSubmitDataResponse
      );
    } else {
      setModal((prev) => ({ ...prev, nmat: true }));
      playPauseTimer(false);
    }
  };

  // Function to handle clearing the answer for a specific question in a tab
  const handleClearAnswer = (currentIndex, tabId) => {
    updateSubmitDataResponse(
      tabId,
      (section) => {
        const currentQuestion = section?.question_data[currentIndex];
        if (currentQuestion) {
          setAnswer({
            oneWord: "",
            numeric: "",
            subjective: "",
          });
          currentQuestion.selected_option = null;
          if ([0, 1].includes(Number(currentQuestion.status))) {
            section.total_attempt = section.total_attempt - 1;
          }
          currentQuestion.status = questionStatus("active");
        }
        return section;
      },
      setSubmitDataResponse
    );
  };

  // Handler Move To Next Section When Section Time is Enabled
  const moveToNextSection = () => {
    const currentIndex = examStructure?.findIndex(
      (d) => Number(d.id) === Number(selectedTabId)
    );
    if (currentIndex !== -1 && currentIndex <= examStructure.length) {
      const nextIndex = currentIndex + 1;
      const nextSectionId = examStructure[nextIndex].id;
      handleTabSelect(nextSectionId, selectedTabId);
    }
    setModal((prev) => ({ ...prev, section: false }));
  };

  const autoSubmitExam = async () => {
    await handleSubmission(submitDataResponse);
    await getUpdateTestData();
  };

  const viewResultHandler = () => {
    playPauseTimer(false);
    setModal((prev) => ({ ...prev, viewResult: false }));
    if (isEnabled.viewResultBtn) {
      navigate(`/test/${exam_id}/exams/resultnew`, {
        replace: true,
      });
    } else {
      navigate("/test");
    }
  };

  // Update Cuet Slot Handler
  const cuetHandler = async (reload = false) => {
    const params = {
      status: 0,
    };

    updateSubmitDataResponse(
      Number(selectedTabId),
      (currentSection) => {
        return {
          ...currentSection,
          status: 0,
        };
      },
      setSubmitDataResponse
    );
    await getUpdateSlotSection(currentSlot.id, params, reload);
    await autoSubmitExam();
    return true;
  };

  // Hanlder for final submit
  const finalExamSubmitHandler = async () => {
    if (isEnabled.slot) {
      const isLastSlotSubmit = lastSlotObject?.find(
        (d) => Number(d.id) === Number(selectedTabId)
      );
      if (isLastSlotSubmit) {
        console.log("last slot submit called");
        await cuetHandler();
        setModal((prev) => ({
          ...prev,
          confirmation: false,
          viewResult: true,
        }));
      } else {
        await cuetHandler(true);
      }
    } else {
      await getUpdateTestData();
      setModal((prev) => ({ ...prev, confirmation: false, viewResult: true }));
    }
  };

  // Start Timer
  const startTimer = () => {
    const timer = setInterval(() => {
      if (isTimerRunningRef.current) {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 0) {
            clearInterval(timer);
            return 0;
          }
          setTotalTimeSpent((prevTotalTime) => prevTotalTime + 1);
          setSlotTimeRemaining((prevSlotTime) => {
            if (prevSlotTime <= 1) {
              return 0;
            } else {
              return prevSlotTime - 1;
            }
          });
          setSectionTimeRemaining((prevSectionTime) => {
            if (prevSectionTime <= 1) {
              return 0;
            } else {
              return prevSectionTime - 1;
            }
          });

          return prevTime - 1;
        });
      }
    }, 1000);
    return () => clearInterval(timer);
  };

  const fetchDataAndStartTimer = async () => {
    try {
      await getExamStructure();
      startTimer();
    } catch (error) {
      console.log("Error fetching exam structure:", error);
    }
  };

  const initializeWebcam = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      fetchDataAndStartTimer();
    } catch (error) {
      console.log("Error accessing camera:", error);
      setIsEnabled((prev) => ({ ...prev, cameraAccess: true }));
    }
  };

  const questionProps = {
    questionData: questions,
    currentQuestionIndex,
    questionType,
    handleOptionChange,
    isOptionSelected,
    handleOneWordAnswer,
    keyboardRef,
    handleOneWordInputChange,
    handleNumericAnswer,
    numericAnswer: answer.numeric,
    subjectiveAnswer: answer.subjective,
    handleSubjectiveAnswer,
  };

  const palleteProps = {
    remainingTime: timeRemaining,
    questionsData: submitDataResponse,
    handleClasses,
    handleQuestionClick,
    selectedTabId,
    sectionTimeRemaining,
    slotTimeRemaining: slotTimeRemaining !== undefined ? slotTimeRemaining : 0,
    isEnabledSectionTimeLimit: isEnabled.sectionLimit,
    isEnabledQuestionLimit: isEnabled.questionLimit,
    isEnabledSlot: isEnabled.slot,
    totalAttempts: questionLimit,
  };

  const mockExamSummaryProps = {
    confirmationModal: modal.confirmation,
    setConfirmationModal: setModal,
    submittedQuestionData,
    totalQuestions,
    isEnableSlot: isEnabled.slot,
    lastStructureObject,
    selectedTabId,
    playPauseTimer,
    finalExamSubmitHandler,
  };

  const navigationDrawerProps = {
    mobileBtnModal: modal.mobileBtn,
    setMobileBtnModal: setModal,
    handleQuestionStatus,
    currentQuestionIndex,
    questions,
    selectedTabId,
    handleClearAnswer,
    playPauseTimer,
    handleSubmission,
    submitDataResponse,
    getSubmittedQuestionCount,
    setConfirmationModal: setModal,
  };

  useEffect(() => {
    if (isEnabled.webcam) {
      initializeWebcam();
    } else {
      fetchDataAndStartTimer();
    }
  }, []);

  useEffect(() => {
    const lastSection = Number(examStructure[examStructure.length - 1]?.id);

    // when time expired
    if (timeRemaining <= 0 && timeRemaining !== null) {
      // when time expired
      if (isEnabled.slot) {
        cuetHandler();
        setModal((prev) => ({ ...prev, viewResult: true }));
      } else {
        autoSubmitExam();
        playPauseTimer(true);
        setModal((prev) => ({ ...prev, viewResult: true }));
      }
    }

    updateSubmitDataResponse(
      Number(selectedTabId),
      (section) => {
        if (section !== undefined) {
          const currentQuestion = section?.question_data[currentQuestionIndex];
          let currentSectionTime = Number(section?.section_time_spent); // Change const to let
          if (isEnabled.slot && slotTimeRemaining === 0) {
            playPauseTimer(false);
            const currentslot = currentSlot.slot_no;
            const nextSlot = currentslot + 1;
            const getNextSlot = Number(
              Object.keys(allStructure).find((d) => d == nextSlot)
            );
            const cuetResponse = cuetHandler();
            if (getNextSlot) {
              if (cuetResponse) {
                setModal((prev) => ({ ...prev, cuet: true }));
              }
            } else {
              const cuetResponse = cuetHandler();
              if (cuetResponse) {
                setModal((prev) => ({ ...prev, viewResult: true }));
              }
            }
          } else if (
            isEnabled.sectionLimit &&
            currentSectionTime === Number(section.duration) * 60 &&
            lastSection !== Number(selectedTabId)
          ) {
            playPauseTimer(false);
            handleSubmission(submitDataResponse);
            setModal((prev) => ({ ...prev, section: true }));
          } else if (currentSectionTime) {
            currentSectionTime = currentSectionTime + 1;
            playPauseTimer(true);
            setModal((prev) => ({ ...prev, section: false }));
          } else {
            currentSectionTime = 1;
            playPauseTimer(true);
            setModal((prev) => ({ ...prev, section: false }));
          }

          if (
            isEnabled.sectionLimit &&
            currentSectionTime === Number(section.duration) * 60 &&
            lastSection === Number(selectedTabId)
          ) {
            section.status = 0;
            autoSubmitExam();
          }
          if (currentQuestion) {
            currentQuestion.time_spent = Number(currentQuestion.time_spent) + 1;
          }
          section.section_time_spent = currentSectionTime;
          return section;
        }
      },
      setSubmitDataResponse
    );
    if (totalTimeSpent % 300 === 0) {
      // auto submit
      handleSubmission(submitDataResponse);
    }
  }, [totalTimeSpent]);

  useEffect(() => {
    dataRef.current = submitDataResponse;
  }, [submitDataResponse]);

  useEffect(() => {
    window.addEventListener("beforeunload", async (event) => {
      event.preventDefault();
      await handleSubmission(dataRef.current);
      return;
    });
    return () =>
      window.removeEventListener("beforeunload", async (event) => {
        event.preventDefault();
        await handleSubmission(dataRef.current);
        return;
      });
  }, []);

  return (
    <>
      <div className="exam-screen d-flex gap-4 mt-3">
        <div className="exam_white_box">
          <div className="exam_container">
            <div className="exam-sec row">
              <div className="col-md-9 exam-tabs">
                <Tabs
                  id="uncontrolled-tab-example"
                  onSelect={handleSectionChange}
                  activeKey={selectedTabId}
                  className="mb-3 grid-3 flex-nowrap text-nowrap overflow-auto w-100"
                >
                  {examStructure?.length > 0 &&
                    examStructure.map((key) => {
                      return (
                        <Tab
                          eventKey={key?.id}
                          title={key?.subject_name}
                          key={key?.id}
                          disabled={selectedTabId == key?.id}
                        ></Tab>
                      );
                    })}
                </Tabs>
              </div>
              <Question {...questionProps} />
            </div>
          </div>
          <div className="button-footer exam_buttons m-0">
            <div className="ms-4">
              <ul>
                <li>
                  <Button
                    className="proceed border-btn green-btn text-white border-0"
                    link=""
                    title="Save And Next"
                    onClick={() => handleQuestionStatus("save and next", false)}
                  />
                </li>
              </ul>
            </div>
            {/* 
            <div className="m-auto">
              <ul className="ms-auto">
               <li>
                  <Button
                    className="proceed gray-btn"
                    onClick={() =>
                      handleClearAnswer(currentQuestionIndex, selectedTabId)
                    }
                    title="Clear"
                  />
                </li> 
                {examStructure[examStructure.length - 1]?.id !==
                  selectedTabId &&
                  isEnabled.sectionLimit && (
                    <li>
                      <Button
                        className="process small-btn"
                        title="Submit Section"
                        onClick={handleSubmitSection}
                      />
                    </li>
                  )}
                <li>
                  <Button
                    className="proceed small-btn"
                    onClick={handleSubmit}
                    title="Submit"
                  />
                </li>
              </ul>
            </div> */}
          </div>
          <div className="exam_mobile_btn">
            <Button
              title="Save/Navigate"
              className="proceed small-btn mt-3"
              onClick={() => setModal((prev) => ({ ...prev, mobileBtn: true }))}
            />
          </div>
        </div>
        <QuestionPallete {...palleteProps} />
      </div>
      <div>
        <CountModal countModal={modal.count} setCountModal={setModal} />
      </div>
      <div className="confirmation_modal">
        <SectionSubmitModal
          show={isEnabled.submitSection}
          setShow={setIsEnabled}
          moveToNextSection={moveToNextSection}
          playPauseTimer={playPauseTimer}
        />
        <MockExamSummaryModal {...mockExamSummaryProps} />
      </div>
      <div className="">
        <MockSectionModal
          sectionModal={modal.section}
          moveToNextSection={moveToNextSection}
        />
      </div>
      <div>
        <ViewResultModal
          viewResultHandler={viewResultHandler}
          viewResultModal={modal.viewResult}
          resultDate={resultDate}
          buttonTitle={viewResultBtnTitle}
          isSubjectiveMarking={isEnabled.subjectiveMarking}
        />
      </div>
      <div className="cuet_modal">
        <MockCuetModal cuetModal={modal.cuet} moveToNextSlot={moveToNextSlot} />
      </div>
      <div className="nmat-modal">
        <NMATModal
          setShowNmatModal={setModal}
          showNmatModal={modal.nmat}
          playPauseTimer={playPauseTimer}
        />
      </div>
      {isEnabled.webcam && (
        <MockWebcam hasCameraAccess={isEnabled.cameraAccess} />
      )}
      <NavigationDrawer {...navigationDrawerProps} />
    </>
  );
};

export default Exams;
