import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import Image from "../image/Image";

export const VerticalTable = ({
  // heading,
  // rows,
  // loading,
  className,
  // tdClassName,
  varticalData,
  id,
}) => {
  return (
    <div className={`data-table ${className ? className : ""}`}>
      <table className="table" id={id}>
        {/* <tr>
          {heading &&
            heading?.map((headingData, index) => {
              return (
                <th
                  className={
                    headingData?.className ? headingData?.className : ""
                  }
                  key={`head-${index}`}
                >
                  {headingData.value}
                </th>
              );
            })}
          {rows?.length ? (
            rows?.map((rowsData, index) => {
              return (
                rowsData.length &&
                rowsData.map((row, i) => {
                  return <td key={`td-${i}`} className={row?.className}>{row?.value}</td>;
                })
              );
            })
          ) : (
            <h2 className="text-center w-100 border-0 position-absolute alert-text h-50 error-msg mb-5">
         
              <Image src={dataImg} alt="" />
            </h2>
          )}

        </tr> */}
        <tbody>
          {varticalData?.map((data, index) => {
            return (
              <tr key={index}>
                <th
                  className={data?.className ? data?.className : ""}
                  key={`head-${index}`}
                >
                  {data.heading}
                </th>
                <td
                  key={`td-${index}`}
                  className={data?.tdClassName}
                  style={{ whiteSpace: "normal" }}
                >
                  {data?.data}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default VerticalTable;
