export const updateBreadcrumb = (
  state,
  dispatch,
  breadcrumbName,
  courseId,
  folderId,
  isFolder = false,
  callbackBreadcrumb
) => {
  const breadcrumbs = state?.breadcrumbs;
  if (
    breadcrumbs[breadcrumbs?.length - 1]?.title !== breadcrumbName &&
    breadcrumbs?.some((breadcrumb) =>
      breadcrumb?.title?.includes(breadcrumbName)
    )
  ) {
    const removeBreadcrumb = breadcrumbs?.findIndex(
      (breadcrumb) =>
        breadcrumb?.title?.trim().toLowerCase() ===
        breadcrumbName?.trim().toLowerCase()
    );
    const sliceBreadcrumb = breadcrumbs?.slice(0, removeBreadcrumb + 1);
    dispatch({ type: "SET_BREADCRUMB", crumb: sliceBreadcrumb });
  } else {
    if (breadcrumbs?.some((breadcrumb) => breadcrumb?.title === undefined)) {
      const filterBreadcrumb = breadcrumbs?.filter(
        (breadcrumb) => breadcrumb?.title !== undefined
      );
      dispatch({ type: "SET_BREADCRUMB", crumb: filterBreadcrumb });
    }
    dispatch({
      type: "ADD_BREADCRUMB",
      crumb: {
        title: breadcrumbName,
        url: callbackBreadcrumb
          ? callbackBreadcrumb()
          : isFolder
          ? `/courses/${courseId}/folder${folderId}`
          : `/courses/${courseId}/${folderId}`,
      },
    });
  }
};
