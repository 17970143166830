import React from "react";

const ColorCard = ({
  color,
  title,
  dataCount,
  percent,
  totalMarksScored,
  scored,
}) => {
  return (
    <div className="marks_list color-box d-flex ">
      <span
        className={`float-starting inst-box ${color ? color : ""}`}
        style={{ background: color }}
      ></span>
      <div className="w-100 d-flex justify-content-between">
        <span className="float-end d-flex align-items-center" style={{whiteSpace:'normal'}}>
          {title}
          <h4 className="dark-text color-count mb-0 ps-2">
            {dataCount} {percent ? " %" : ""}
          </h4>
        </span>
        <span>
          {totalMarksScored}
          {scored}
        </span>
      </div>
    </div>
  );
};

export default ColorCard;
