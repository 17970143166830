import React from "react";
import Button from "../../utils/forms/Button";
import { Modal } from "react-bootstrap";

const CountModal = ({ countModal, setCountModal }) => {
  return (
    <Modal className="count_modal" size="sm" show={countModal} centered>
      <Modal.Body>
        <div className="mt-4">
          <div className="modal-footer">
            <p className="p-0">
              Previous and Next buttons only help to move from one question to
              another and does not save your answer.
            </p>
            <p className="p-0">
              To save your answer, click on one of the Action buttons below the
              question.
            </p>
          </div>
          <div className="d-flex justify-content-center mb-5">
            <Button
              className="proceed small-btn mt-0"
              title="Close"
              onClick={() =>
                setCountModal((prev) => ({ ...prev, count: false }))
              }
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CountModal;
