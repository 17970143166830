import React, { useState, useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Button from "../../utils/forms/Button";
import { userService } from "../../../_services/User.services";
import { AppContext } from "../../shared/AppContext";
import "./numericKeypad.scss";

const SlotSelection = (props) => {
  const { setSpinner, setToaster } = useContext(AppContext);
  const [slots, setSlots] = useState(
    Array.from({ length: props.slotDetails.no_of_slots }, () => [])
  );
  const [subjects, setSubjects] = useState(
    props?.examTemplateStructure
      .map((item) => {
        return {
          name: item.subject[0].name,
          id: item.id.toString(),
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name))
  );

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (!destination) {
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      const newSlots = [...slots];
      const subject = subjects.find((s) => s.id === draggableId);

      if (destination.droppableId.startsWith("slot")) {
        const slotIndex =
          parseInt(destination.droppableId.replace("slot", ""), 10) - 1;
        newSlots[slotIndex].push(subject);

        // Remove the dragged subject from the subjects list
        const updatedSubjects = subjects.filter((s) => s.id !== draggableId);

        setSlots(newSlots);
        setSubjects(updatedSubjects); // Update the available subjects list after dragging
      }
    }
  };

  const handleSubmit = async () => {
    const minSection = props.slotDetails.min_section;
    const maxSection = props.slotDetails.max_section;
    let patternData = [];
    const isSlotCountWithinRange = slots.every(
      (slot) => slot.length >= 0 && slot.length <= 0
    );
    if (isSlotCountWithinRange) {
      slots.forEach((slotSubjects, index) => {
        patternData = patternData.concat(
          slotSubjects.map((subject) => ({
            slot_no: (index + 1).toString(),
            exam_template_structure_id: subject.id,
            status: "1",
          }))
        );
      });

      let params = {
        exam_id: props.examId,
        pattern_data: patternData,
      };

      try {
        setSpinner(true);
        const response = await userService.configureCuetPattern(params);
        if (response.status >= 200 && response.status <= 299) {
          setToaster({
            show: true,
            type: "success",
            content: "Slot selected successfully.",
          });
          props.closeModal();
          setSpinner(false);
        } else if (response.status >= 400 && response.status <= 499) {
          const data = await response.json();
          setSpinner(false);
          setToaster({ show: true, type: "danger", content: data.message });
        }
      } catch (error) {
        setSpinner(false);
        setToaster({
          show: true,
          type: "danger",
          content: "Something went wrong. Please try again.",
        });
      }
    } else {
      setToaster({
        show: true,
        type: "danger",
        content:
          "The number of subjects in each slot must be between the defined range.",
      });
    }
  };

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="slot_container">
          <Droppable droppableId="subjectList">
            {(provided) => (
              <div
                className="subject_container_instructions col-lg-3"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <h3 className="text-center">Subjects</h3>
                <div>
                  <div className="subject_inner_container">
                    {subjects.map((subject, index) => (
                      <Draggable
                        key={subject.id}
                        draggableId={subject.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="subject_box"
                            style={{
                              ...provided.draggableProps.style,
                            }}
                          >
                            <div>{subject.name}</div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                </div>

                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div className="selection_slot_container">
            {slots.map((slot, slotIndex) => (
              <Droppable
                key={`slot${slotIndex + 1}`}
                droppableId={`slot${slotIndex + 1}`}
              >
                {(provided) => (
                  <div
                    className="slot-config-container"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <h3 className="text-center">Slot {slotIndex + 1}</h3>
                    {slot.map((subject) => (
                      <div
                        className="slot-config-box text-center"
                        key={subject.id}
                      >
                        {subject.name}
                      </div>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </div>
        </div>
      </DragDropContext>

      <div className="d-flex justify-content-center my-5 px-5 col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4">
        <Button
          onClick={() => handleSubmit()}
          type="button"
          className="primary"
          title="Submit"
        />
      </div>
    </div>
  );
};

export default SlotSelection;
