// Function to create a question object with initial values
export const createQuestionObject = (
  id,
  questionData,
  initialStatus,
  isQuestionWiseMarking = false
) => {
  return {
    [id]: {
      section_time_spent: 0,
      total_attempt: null,
      total_marks: 0,
      correct_answers: 0,
      incorrect_answers: 0,
      score: 0,
      status: 0,
      duration: 0,
      // Template Wise Correct  Marking
      ...(!isQuestionWiseMarking && {
        correct_marks: 0,
      }),
      // Template Wise Incorrect Marking
      ...(!isQuestionWiseMarking && {
        negative_marks: 0,
      }),
      total_questions: questionData?.length || 0,
      question_data: (questionData || []).map((question, index) => {
        // Check if there is saved user data for the question
        const savedUserExamData = question.student_test_questions;

        // Find the correct option for the question
        const correctOption = question.options.find(
          (option) => option.is_correct === 1
        );
        // If there is saved user data, use it; otherwise, use default values
        if (savedUserExamData) {
          return {
            question_id: savedUserExamData.question_id,
            question_type_id: question.question_type_id,
            subject_id: savedUserExamData.subject_id,
            topic_id: savedUserExamData.topic_id,
            status:
              savedUserExamData.status === 4 && index === 0
                ? 3
                : savedUserExamData.status,
            selected_option:
              [3, 4, 5].includes(question?.question_type_id) &&
              savedUserExamData.input_answer !== null
                ? savedUserExamData.input_answer
                : !["-1", null].includes(savedUserExamData.selected_option)
                ? savedUserExamData.selected_option
                : null,
            correct_option:
              savedUserExamData.correct_options !== null
                ? savedUserExamData.correct_options
                : ![3, 4, 5].includes(question.question_type_id) &&
                  correctOption
                ? correctOption.id
                : question.options[0]?.id,
            // One Word Correct Option
            ...(question.question_type_id === 4 && {
              correct_option_value: correctOption.option,
            }),
            // Numerical Correct Option
            ...(question.question_type_id === 3 && {
              correct_option_value: correctOption.option,
            }),
            // Subjective Correct Option
            ...(question.question_type_id === 5 && {
              correct_option_value: correctOption.option,
            }),
            is_correct:
              savedUserExamData.is_correct !== null
                ? savedUserExamData.is_correct
                : null,
            time_spent: Number(savedUserExamData.time_spent),
            // Question Wise Correct Marks
            ...(isQuestionWiseMarking && {
              correct_marks: Number(question.correct_marks),
            }),
            // Question Wise Negative Marks
            ...(isQuestionWiseMarking && {
              negative_marks: Number(question.negative_marks),
            }),
          };
        } else {
          // If no saved user data, use default values for a new question
          return {
            question_id: question.id,
            question_type_id: question.question_type_id,
            topic_id: question.topic_id,
            subject_id: question.subject_id,
            status: index === 0 ? initialStatus : 4,
            selected_option: null,
            correct_option:
              question.question_type_id !== 4 && correctOption
                ? correctOption.id
                : question.options[0]?.option ||
                  (![3, 4, 5].includes(question.question_type_id) &&
                    correctOption)
                ? correctOption.id
                : question.options[0]?.option,
            is_correct: null,
            time_spent: 0,
          };
        }
      }),
    },
  };
};
