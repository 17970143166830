import React from "react";
import { Form } from "react-bootstrap";

const SearchFilter = ({ setResourceType, value }) => {
  return (
    <Form.Select
      className="form_select_search"
      value={value}
      onChange={(e) => setResourceType(Number(e.target.value))}
    >
      <option className="option_select" value="0">
        Select Content Type
      </option>
      <option className="option_select" value="1">
        Document
      </option>
      <option className="option_select" value="2">
        Video
      </option>
      <option className="option_select" value="4">
        Exam
      </option>
      {/*  <option value="5">
        Live Class
      </option> 
      <option value="3">
        Notes
      </option> */}
    </Form.Select>
  );
};

export default SearchFilter;
