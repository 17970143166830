import React from "react";
import ResourceViewComponent from "./ResourceViewComponent";

const ResourceGroup = ({
  data,
  resourceType,
  toggleItem,
  openItems,
  setResourceType,
}) => {
  return (
    <div className="" key={resourceType}>
      <div className="" style={{ padding: "11.5px" }}>
        <div
          style={{
            border: "1px solid #D7DDf5",
            borderRadius: "10px",
          }}
        >
          {data &&
            data.length > 0 &&
            data.map((d, index) => (
              <ResourceViewComponent
                key={index}
                data={d}
                openItems={openItems}
                toggleItem={toggleItem}
                resourceType={resourceType}
                setResourceType={setResourceType}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ResourceGroup;
