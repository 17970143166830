import { useEffect } from "react";
import { useState } from "react";
import { CONSTANT, RESIZE } from "../component/utils/constants";

export const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(
    typeof window !== CONSTANT.UNDEFINED && window?.innerWidth
  );
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(
        typeof window !== CONSTANT.UNDEFINED && window?.innerWidth
      );
    };

    if (typeof window !== CONSTANT.UNDEFINED) {
      window.addEventListener(RESIZE, handleResize);
    }
    return () => {
      if (typeof window !== CONSTANT.UNDEFINED) {
        window.removeEventListener(RESIZE, handleResize);
      }
    };
  }, []);
  return screenWidth;
};
