import React from "react";
import CommonModal from "../../utils/modal/CommonModal";
import Button from "../../utils/forms/Button";

const MockSectionModal = ({ sectionModal, moveToNextSection }) => {
  return (
    <CommonModal
      className="exam-modal section_modal"
      show={sectionModal}
      crossShow={false}
      body={
        <div>
          <p className="text-center" style={{ fontSize: "2rem" }}>
            {`Section has completed. Please click 'Continue' below to start the next section of the exam.`}
          </p>
          <div className="d-flex justify-content-center">
            <div className="col-md-5">
              <Button
                className="btn small-btn theme-btn w-100 text-nowrap py-3 mb-5 fs-2"
                title="Move to next section"
                onClick={moveToNextSection}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default MockSectionModal;
