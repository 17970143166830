import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import video_play from "../../../assets/images/play.png";
import Image from "../image/Image";

export default function CarouselComp({ banners, getVimeoVideo }) {
  const [index, setIndex] = useState(0);
  const [thumb, setThumb] = useState({ isShow: false, id: "" });
  const [playId, setPlayId] = useState("");

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const removeThumbnal = (id) => {
    banners.map((item) => {
      if (item.banner_id === id) {
        setThumb({
          isShow: true,
        });
      }
      return item;
    });
    return;
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      touch={true}
      interval={5000}
    >
      {banners?.length > 0 ? (
        banners?.map((ban, index) => {
          if (
            (!ban?.video || !ban?.img_url) &&
            ban.banners?.file_type === "video"
          ) {
            getVimeoVideo(ban?.banners?.content, ban.video);
          }

          return (
            <Carousel.Item key={index + 1}>
              {ban?.banners?.file_type === "video" ? (
                <>
                  {thumb.isShow && ban.banner_id === playId ? (
                    <>
                      <iframe
                        src={`https://player.vimeo.com/video/${Number(
                          ban?.banners?.content
                        )}?autoplay=1&loop=1&autopause=0`}
                        width="100%"
                        height="360"
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen
                        title="aa"
                      >
                        {" "}
                      </iframe>
                    </>
                  ) : (
                    <div id={ban.banner_id}>
                      {ban.banners.url !== null ? (
                        <a href={ban.banners.url} target="_blank">
                          <Image
                            src={`${process.env.REACT_APP_MEDIA_URL}/${ban?.banners?.thumbnail}`}
                            alt="anurag"
                          />
                          <div className="image-overlay">
                            <Image
                              src={video_play}
                              alt="play icon"
                              onClick={() => {
                                removeThumbnal(ban?.banner_id);
                                setPlayId(ban?.banner_id);
                              }}
                            />
                          </div>
                        </a>
                      ) : (
                        <div>
                          <Image
                            src={`${process.env.REACT_APP_MEDIA_URL}/${ban?.banners?.thumbnail}`}
                            alt="thumbnail"
                          />
                          <div className="image-overlay">
                            <Image
                              src={video_play}
                              alt="play icon"
                              onClick={() => {
                                removeThumbnal(ban?.banner_id);
                                setPlayId(ban?.banner_id);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {ban.banners.url !== null ? (
                    <a href={ban?.banners?.url} target="_blank">
                      <Image
                        className="d-block w-100"
                        src={`${ban?.banners?.name}`}
                        alt="First slide"
                      />
                    </a>
                  ) : (
                    <Image
                      className="d-block w-100"
                      src={`${ban?.banners?.name}`}
                      alt="First slide"
                    />
                  )}
                </>
              )}
            </Carousel.Item>
          );
        })
      ) : (
        <Carousel.Item></Carousel.Item>
      )}
    </Carousel>
  );
}
