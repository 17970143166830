import React from "react";
import Keyboard from "react-simple-keyboard";

const OneWordAnswerTypeQuestion = ({
  isOneWordAnswered,
  handleOneWordInputChange,
  keyboardRef,
  questionData,
  handleOneWordAnswer,
  currentQuestionIndex
}) => {
  return (
    <div className="awn-keyboard">
      <input
        value={isOneWordAnswered || ""}
        onChange={handleOneWordInputChange}
        placeholder="Enter your answer"
        readOnly
      />
      <Keyboard
        keyboardRef={(r) => (keyboardRef.current = r)}
        onChange={(value) => {
          handleOneWordAnswer(
            value,
            questionData[currentQuestionIndex]?.options[0]?.question_id
          );
        }}
        value={keyboardRef?.current?.getInput()}
      />
    </div>
  );
};

export default OneWordAnswerTypeQuestion;
