import React, { useEffect, useState } from "react";

// import calenderIcon from "../../../assets/images/calendar.svg";
// import prevIcon from "../../../assets/images/prevArrow.svg";
// import nextIcon from "../../../assets/images/nextArrow.svg";
import Table from "../../utils/table/Table";

import Pagination from "react-js-pagination";
// import Button from "../../utils/forms/Button";
// import CommonModal from "../../utils/modal/CommonModal";
import { useContext } from "react";
import { AppContext } from "../../shared/AppContext";
// import { useForm } from "@kaydhiman/react-hook-useform";
// import { useWhatStatus } from "../../../hooks/useWhatStauts";
import DatePicker from "../../utils/datePicker/DatePicker";
import { updateBreadcrumb } from "../../utils/commonFunctions/updateBreadcrumb";
import { useBreadcrumb } from "../../shared/breadcrumb-context";
import {
  convertIsoToOrdinalDate,
  formatTime,
  handleDateChange,
} from "../../utils/dateTime";
import { useGetAllScheduleClass } from "../../../hooks/hookList";

const allClassTableHeadings = [
  { id: 1, value: "S.No", className: "w-5 text-center" },
  { id: 2, value: "Date", className: "w-5 text-center" },
  {
    id: 3,
    value: "Start & End Time",
    className: "w-5 text-center",
  },
  { id: 4, value: "Subject", className: "w-5 text-center" },
  { id: 5, value: "Topic & Details", className: "w-5" },
  { id: 6, value: "Room", className: "w-10" },
];

const Classes = () => {
  // const { values, setInitialValues } = useForm({
  //   validations: {},
  // });
  // const [accessModal, setaccessModal] = useState(false);
  const { setSpinner, setToaster } = useContext(AppContext);
  // const [calanderDate, setCalanderDate] = useState(new Date());
  // const [classes, setClasses] = useState([]);
  const [total, setTotal] = useState();
  const [activePage, setActivePage] = useState(1);
  const [classDate, setClassDate] = useState({
    start: new Date().toISOString().slice(0, 10),
    end: new Date().toISOString().slice(0, 10),
  });
  const [allScheduleData, setAllScheduleData] = useState([]);
  const { state, dispatch } = useBreadcrumb();

  const { getData: getAllScheduleClass } = useGetAllScheduleClass(
    setSpinner,
    setToaster
  );

  const allScheduleClassCallback = (classData) => {
    const { data: classList, success } = classData;
    if (success && classList) {
      const { data: list, current_page, total, from } = classList;
      if (Array.isArray(list)) {
        const allClassList = list.map((cl, i) => {
          return [
            {
              id: 1,
              value: from + i,
              className: "w-5 text-center",
            },
            {
              id: 2,
              value: (
                <span
                  dangerouslySetInnerHTML={{
                    __html: convertIsoToOrdinalDate(cl.date),
                  }}
                />
              ),
              className: "w-5 text-center",
            },
            {
              id: 3,
              value: (
                <div>
                  {formatTime(cl.start_time)} - {formatTime(cl.end_time)}
                </div>
              ),
              className: "w-5 text-center",
            },
            {
              id: 4,
              value: cl.subject,
              className: "w-5 text-center",
            },
            {
              id: 5,
              value: (
                <div className="" style={{ whiteSpace: "normal" }}>
                  <span>
                    {cl.topic}-{cl.topic_details}
                  </span>
                </div>
              ),
              className: "w-10",
            },
            {
              id: 6,
              value: cl?.room,
              className: "w-5",
            },
          ];
        });
        setActivePage(current_page);
        setTotal(total);
        setAllScheduleData(allClassList);
      }
    }
  };

  // const { whatStatus } = useWhatStatus();

  // const setDate = (newDate) => {
  //   const date = newDate || new Date();
  //   setSelectedStartDate(
  //     date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  //   );
  // };

  // const accessModalOpen = (data) => {
  //   setaccessModal(true);
  //   setInitialValues({ ...values, name: data.meeting_name, id: data?.id });
  // };
  // const accessModalClose = () => {
  //   setaccessModal(false);
  //   setInitialValues({});
  // };

  // const getPreviousDate = () => {
  //   const currentDayInMilli = new Date(selectedStartDate).getTime();
  //   const oneDay = 1000 * 60 * 60 * 24;
  //   const previousDayInMilli = currentDayInMilli - oneDay;
  //   const previousDate = new Date(previousDayInMilli);
  //   if (selectedStartDate) {
  //     if (selectedStartDate === previousDate) {
  //       setDate(selectedStartDate);
  //     } else {
  //       setDate(previousDate);
  //     }
  //   }
  // };

  // const getNextDate = () => {
  //   const currentDayInMilli = new Date(selectedStartDate).getTime();
  //   const oneDay = 1000 * 60 * 60 * 24;
  //   const nextDayInMilli = currentDayInMilli + oneDay;
  //   const nextDate = new Date(nextDayInMilli);
  //   setDate(nextDate);
  //   setCalanderDate(nextDate);
  // };

  //********************Get Classes of other Batches********************//

  // const getClassesOfOtherBatch = async (queryParams) => {
  //   setSpinner(true);
  //   let currentPage = queryParams?.page === undefined ? 1 : queryParams?.page;
  //   const response = await userService?.getTodaySchedule({
  //     date: moment(queryParams?.date).format("YYYY-MM-DD"),
  //     type: "all-classes",
  //     page: currentPage,
  //     size: 10,
  //   });
  //   try {
  //     if (response.status >= 200 && response.status <= 299) {
  //       const data = await response.json();
  //       setClasses(
  //         data?.data?.data?.map((elem) => {
  //           return [
  //             { id: 1, value: elem?.meeting_name },
  //             {
  //               id: 2,
  //               value: whatStatus(elem?.status),
  //             },
  //             {
  //               id: 3,
  //               value: (
  //                 <>
  //                   {" "}
  //                   {moment(elem?.schdeule?.start_time, "HH:mm:ss").format(
  //                     "hh:mm A"
  //                   )}
  //                   -
  //                   {moment(elem?.schdeule?.end_time, "HH:mm:ss").format(
  //                     "hh:mm A"
  //                   )}
  //                 </>
  //               ),
  //             },
  //             {
  //               id: 4,
  //               value: (
  //                 <>
  //                   {elem?.branch?.length ? elem?.branch[0]?.name + " > " : "-"}{" "}
  //                   {elem?.batches?.length ? elem?.batches[0]?.name : "-"}
  //                 </>
  //               ),
  //             },
  //             {
  //               id: 5,
  //               value: (
  //                 <div className="btn-group">
  //                   <Button
  //                     className=" small-btn"
  //                     onClick={() => accessModalOpen(elem)}
  //                     title="Request Access"
  //                   />
  //                 </div>
  //               ),
  //             },
  //           ];
  //         })
  //       );

  //       setSpinner(false);
  //       setTotal(data?.data?.total);
  //     }
  //     if (response.status >= 400 && response.status <= 499) {
  //       const data = await response.json();
  //       setSpinner(false);
  //       setToaster({ show: true, type: "danger", content: data.message });
  //     }
  //     if (response?.status === 401) {
  //       localStorage.removeItem("_prathamStudentToken");
  //     }
  //   } catch {
  //     setSpinner(false);
  //   }
  // };

  //*******************Request classes to admin *******************//
  // const requestAccess = async () => {
  //   setSpinner(true);
  //   const response = await userService?.createAccess({ classId: values?.id });
  //   try {
  //     if (response.status >= 200 && response.status <= 299) {
  //       // const data = await response.json();
  //       setSpinner(false);
  //       accessModalClose();
  //     }
  //     if (response.status >= 400 && response.status <= 499) {
  //       const data = await response.json();
  //       setSpinner(false);
  //       setToaster({ show: true, type: "danger", content: data.message });
  //     }
  //     if (response?.status === 401) {
  //       localStorage.removeItem("_prathamStudentToken");
  //     }
  //   } catch {
  //     setSpinner(false);
  //   }
  // };

  const onPageChange = async (e) => {
    let pageNo = e;
    const params = {
      date: classDate.start,
      end_date: classDate.end,
      size: 10,
      page: e,
    };
    await getAllScheduleClass(params, allScheduleClassCallback);
    setActivePage(pageNo);
    // getClassesOfOtherBatch({ page: pageNo, date: calanderDate });
  };

  // useEffect(() => {
  //   if (calanderDate) {
  //     getClassesOfOtherBatch({ date: calanderDate, page: activePage });
  //   }
  //   //eslint-disable-next-line
  // }, [calanderDate]);

  useEffect(() => {
    const params = {
      date: classDate.start,
      end_date: classDate.end,
      page: activePage,
      size: 10,
    };
    getAllScheduleClass(params, allScheduleClassCallback);
    updateBreadcrumb(state, dispatch, "Weekly Schedule");
  }, []);

  return (
    <div className="white-box schedule-dash weekly schedule">
      <div className="col-md-12">
        <div className="row">
          <div className="col-6 col-xl-3 my-4">
            <DatePicker
              label="Date from"
              id="date-pick"
              placeholder="Select Start Date"
              date={classDate.start}
              onChange={(e) => {
                handleDateChange(
                  e,
                  true,
                  classDate,
                  setClassDate,
                  getAllScheduleClass,
                  allScheduleClassCallback,
                  { page: activePage, size: 10, isDate: true },
                  setToaster
                );
              }}
            />
          </div>
          <div className="col-6 col-xl-3 my-4">
            <DatePicker
              label="Date to"
              id="date-pick"
              placeholder="Select End Date"
              date={classDate.end}
              onChange={(e) => {
                handleDateChange(
                  e,
                  false,
                  classDate,
                  setClassDate,
                  getAllScheduleClass,
                  allScheduleClassCallback,
                  { page: activePage, size: 10, isDate: true },
                  setToaster
                );
              }}
            />
          </div>
        </div>
      </div>
      <div className="class-schedule my-4">
        <div className="sub_dash-heading mb-4">
          <h1>All Classes</h1>
        </div>
        <div className="">
          <Table heading={allClassTableHeadings} rows={allScheduleData} />
          {/* thank you modal  */}
          {/* <CommonModal
              show={accessModal}
              close={accessModalClose}
              body={
                <div className="common_modal">
                  <div className="modal-footer d-block pb-5  pt-0">
                    <p>{values?.name} is asking for request access. </p>{" "}
                    <p>Are you sure you want to give access?</p>
                    <div className="button-footer w-100">
                      <ul className="d-flex list-unstyled">
                        <li>
                          <Button
                            className="proceed  small-btn  border-btn me-4"
                            onClick={requestAccess}
                            title="Yes"
                          />
                        </li>
                        <li>
                          <Button
                            className="proceed  small-btn"
                            onClick={accessModalClose}
                            title="No"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              }
            /> */}
          {/* thank you modal end  */}
        </div>
        {total > 10 && (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={total}
            pageRangeDisplayed={5}
            onChange={(e) => onPageChange(e)}
            hideFirstLastPages={true}
          />
        )}
      </div>
    </div>
  );
};

export default Classes;
