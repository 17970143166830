import React from "react";
import { Accordion } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const QuestionDetailContainer = ({
  questionData,
  mode,
  examName,
  difficuiltyLevel,
}) => {
  const location = useLocation();
  const isExamName =
    examName !== "OMR BASED MOCK TESTS" || "OMR BASED TOPIC TESTS";
  const statusMessages = {
    0: "Save & Next",
    1: "Save & Mark For Review",
    2: "Mark For Review & Next",
    3: "Not Answered",
    4: "Not Answered",
  };

  const statusOption = (value) => {
    return statusMessages[Number(value)];
  };
  return (
    <div className="exam-details-footer  ">
      <div className="exam-details gray-bg">
        <ul className="mcq-questions ps-0">
          <li className="d-sm-inline-flex  list-unstyled">
            <span className="dark-gray weight-500">User Action:</span>
            <span className="dark-gray weight-normal">
              {location.pathname.includes("resultnew") &&
                statusOption(questionData?.status)}
              {location.pathname.includes("assignment-result") &&
              questionData.selected_option === "0"
                ? "Not Answered"
                : "Answered"}
            </span>
          </li>
          {isExamName && mode !== 1 && (
            <li className="d-sm-inline-flex  list-unstyled">
              <span className="dark-gray weight-500">Time Spent:</span>
              <span className="dark-gray weight-normal">
                {questionData?.time_spent} Seconds(s)
              </span>
            </li>
          )}
          <li className="d-sm-inline-flex  list-unstyled">
            <span className="dark-gray weight-500">Level:</span>
            <span className="dark-gray weight-normal">
              {difficuiltyLevel(questionData?.question?.question_difficulty_id)}
            </span>
          </li>

          <li className="d-sm-inline-flex  list-unstyled">
            <span className="dark-gray weight-500">Subject:</span>
            <span className="dark-gray weight-normal">
              {questionData?.question?.subject?.name}
            </span>
          </li>

          <li className="d-sm-inline-flex  list-unstyled">
            <span className="dark-gray weight-500">Chapter:</span>
            <span className="dark-gray weight-normal">
              {questionData?.topic?.name || questionData.question.topic.name}
            </span>
          </li>
        </ul>
      </div>
      {questionData?.question?.solution && (
        <div className="exam-detail-accordian mt-4 gray-bg">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>View Explanation</Accordion.Header>
              <Accordion.Body className="question_solution">
                <span
                  dangerouslySetInnerHTML={{
                    __html: questionData?.question?.solution,
                  }}
                ></span>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )}
    </div>
  );
};

export default QuestionDetailContainer;
