import React, { useContext, useEffect, useState } from "react";
// import ChatingBox from '../../utils/conversationsBox/ChatingBox'

import userImage from "../../../assets/images/user.svg";
import backBtn from "../../../assets/images/left-arrow.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ChatingBox from "./ChatingBox";
import { userService } from "../../../_services/User.services";
import { AppContext } from "../../shared/AppContext";
import Auth from "../../../auth/Auth";
import Image from "../../utils/image/Image";

const ChatScreen = () => {
  const location = useLocation();
  const { id, threadId } = useParams();
  const navigate = useNavigate();
  const { setthreadInfo, threadInfo, setSeconds, settings, setSpinner } =
    useContext(AppContext);
  const [msgText, setMsgText] = useState("");
  const token = localStorage.getItem("PSIS_token");
  const chatTimeIntervals = localStorage.getItem("chatTimeIntervals")?.length
    ? JSON.parse(localStorage.getItem("chatTimeIntervals"))
    : [];

  //=================Get thread info===================
  const getThreadInfo = async (type = "") => {
    setSeconds(0);
    setSpinner(true);
    const response = await userService?.getThreads({
      threadId: threadId,
      token: `${Auth?.user()?.user_id}|${token}`,
    });

    if (response?.status >= 200 && response?.status <= 299) {
      const data = await response.json();
      setSpinner(false);
      // check if threadId in chatTimeIntervals array and slow mode on then setTime.
      if (!type) {
        let thredIndx = chatTimeIntervals.findIndex(
          (ele) => ele.id === threadId
        );

        if (Number(data?.thread?.studentChatPause) > 0 && thredIndx !== -1) {
          setSeconds(chatTimeIntervals[thredIndx].time);
        } else {
          setSeconds(0);
          // check if slow mode off and thread exist in array then set time 0.
          if (thredIndx !== -1) {
            chatTimeIntervals[thredIndx].time = 0;
            localStorage.setItem(
              "chatTimeIntervals",
              JSON?.stringify(chatTimeIntervals)
            );
          }
        }
      }
      setthreadInfo(data?.thread);
    }
    if (response?.status >= 400 && response?.status <= 500) {
      // localStorage.clear();
      setSpinner(false);
    }
  };

  useEffect(() => {
    if (token) getThreadInfo();
    //eslint-disable-next-line
  }, [settings, token]);

  useEffect(() => {
    if (token) getThreadInfo();
    //eslint-disable-next-line
  }, [threadId, token]);

  return (
    <>
      <div className="live-class-header  ">
        <h3>
          <span className=" back-btn">
            <Image
              src={backBtn}
              alt="back btn"
              onClick={() => {
                location?.state
                  ? navigate(-1)
                  : navigate(`/courses/live_class/${id}/all_threads`);
              }}
            />
          </span>
          <span className="pe-3 user-icon">
            <Image src={userImage} alt="user" />
          </span>
          {threadInfo?.name}
        </h3>
      </div>
      <div className="live-class-body">
        <ChatingBox
          setMsgText={setMsgText}
          msgText={msgText}
          isHistory={location?.state}
        />
      </div>
    </>
  );
};

export default ChatScreen;
