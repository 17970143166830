import React, { useContext } from "react";
import BreadCrumbContextProvider from // BreadCrumbContext,
"../../shared/breadcrumb-context";
import { Outlet } from "react-router-dom";
import Header from "../portalLayout/Header/Header";
import arrowImg from "../../../assets/images/white-arrow-down.svg";

import { useEffect } from "react";
import { AppContext } from "../../shared/AppContext";
import Image from "../../utils/image/Image";
// import Auth from "../../../auth/Auth";

export default function ExamLayout() {
  const { sidebarShow, setSidebarShow, dark_mode } = useContext(AppContext);
  // const { setTempVar } = useContext(BreadCrumbContext);
  // const breadData = Auth?.breadData()?.breadData;
  // useEffect(() => {
  //   window.addEventListener("beforeunload", (ev) => {
  //     ev.preventDefault();

  //     return (ev.returnValue = "Do you really want to close?");
  //   });
  //   window.close = () => {
  //     return "Do you really want to close?";
  //   };

  //   document.addEventListener("contextmenu", (e) => {
  //     e.preventDefault();
  //   });
  //   document.onkeydown = function (e) {
  //     // disable F12 key
  //     if (e.keyCode === 123) {
  //       return false;
  //     }
  //     // disable I key
  //     if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
  //       return false;
  //     }
  //     // disable J key
  //     if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
  //       return false;
  //     }
  //     // disable U key
  //     if (e.ctrlKey && e.keyCode === 85) {
  //       return false;
  //     }
  //     // disable R key
  //     if (e.ctrlKey && e.keyCode === 82) {
  //       return false;
  //     }
  //     // disable f5 key
  //     if (e.ctrlKey && e.keyCode === -21) {
  //       return false;
  //     }
  //   };
  //   let elem = document.querySelector("#exam");
  //   if (elem.requestFullscreen) {
  //     elem.requestFullscreen();
  //   } else if (elem.mozRequestFullScreen) {
  //     elem.mozRequestFullScreen();     // Firefox
  //   } else if (elem.webkitRequestFullscreen) {
  //     elem.webkitRequestFullscreen();  // Safari
  //   } else if (elem.msRequestFullscreen) {
  //     elem.msRequestFullscreen();      // IE/Edge
  //   }

  // }, []);

  // window.location.onUnload("/");

  return (
    <BreadCrumbContextProvider>
      <div
        className={`dashboard-body exam-portal full-wid${
          dark_mode ? " dark_mode" : ""
        } ${sidebarShow ? " show-inner-cont" : ""}`}
        id="exam"
      >
        <Header />
        <div className="blue-bg"></div>
        <div className="height-100 exam_body" id="supAdmin">
          <div className="container-fluid dash-container">
            <div className="row">
              <Outlet />
            </div>
          </div>
        </div>
        <div
          className="mobile-sidebar-icon position-fixed"
          onClick={() => setSidebarShow(!sidebarShow)}
        >
          <div className="heading">
            {" "}
            <span>Palette</span>
            <Image src={arrowImg} alt="arrow" />
          </div>
        </div>
      </div>
    </BreadCrumbContextProvider>
  );
}
