import React, { useEffect, useState, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useForm } from "@kaydhiman/react-hook-useform";
import { AppContext } from "../../shared/AppContext";
import { CONSTANT } from "../../utils/constants";
import { useDifficultyLevel } from "../../../hooks/useDifficultyLevel";

import html2pdf from "html2pdf.js";
import ReportCards from "../../utils/card/ReportCards";
import arrowImg from "../../../assets/images/white-arrow-down.svg";
import cross from "../../../assets/images/cancel.svg";
import tick from "../../../assets/images/blue-tick01.svg";
import QuestionHTML from "../../utils/questionAwn/QuestionHtml";
import ColorCard from "../../utils/card/ColorCard";
import Image from "../../utils/image/Image";
import { useHomeAssignmentQuestionReport } from "../../../hooks/hookList";

const AssignmentResult = () => {
  const examRef = React?.createRef(null);
  const location = useLocation();
  const { getDifficultyLevel } = useDifficultyLevel();

  const { exam_id } = useParams();
  const { values, setInitialValues } = useForm({ validations: {} });
  const { setToaster, setSpinner, sidebarShow, setSidebarShow } =
    useContext(AppContext);
  const [cardData, setCardData] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionnumber, setQuestionNumber] = useState(0);

  const { getData: getHomeAssignmentQuestionReport } =
    useHomeAssignmentQuestionReport(setSpinner, setToaster);

  //****************** Function for jump to the particular question after click to question number ***************** */
  const gotoServices = (id) => {
    const rightSidebar = document.getElementById("schedule-right");
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView();
    }
    setInitialValues((prev) => ({ ...prev, divId: id }));
  };

  const getHomeAssignmentQuestionReportCallback = (questionData) => {
    setInitialValues((prev) => ({ ...prev, divId: `question-1` }));
    setQuestions(questionData?.data);
  };

  //0 => saved, 1=> save and mark for review, 2=> mark for review, 3 => skipped, 4=> not visited

  const classesMapping = {
    [CONSTANT.TYPE.ACTIVE]: "pinkBox",
    0: "greenBox",
    1: "darkBlueBox",
    2: "darkBlueBox",
    3: "yellowBox",
    4: "yellowBox",
  };

  const handleClasses = (key) => {
    return classesMapping[key] || CONSTANT.TYPE.GRAY_BOX;
  };

  const downloadPdf = (e) => {
    e.preventDefault();
    const element = document.getElementById(CONSTANT.PDF);

    const opt = {
      margin: 0.3,
      filename: "filename.pdf",
      image: {
        type: "jpeg",
        quality: 0.98,
      },
      html2canvas: {
        scale: 2,
        useCORS: true,
      },
      jsPDF: {
        unit: "in",
        format: "a4",
        orientation: "portrait",
      },
    };

    var objstr = document.getElementById(CONSTANT.PDF).innerHTML;
    // var objstr1 = document.getElementById('block2').innerHTML;

    var strr = `<html><head><title>Testing</title><style>.result-dash .result-questions {
    padding-top: 10px;
    margin-top: 1.5rem;
    height: 87%;
    overflow: auto;
}
.result-dash .white-box .question-result .mcq-questions .awn-btn {
    margin-left: 10px;
    margin-top: 0;
    padding: 4px 7px;
}
</style >
<link rel="stylesheet" type="text/css"  href="../../../assets/scss/dashboard.scss">

`;
    strr += "</head><body>";
    strr += objstr;
    // strr += '<div style="border:0.1rem solid #ccc!important;padding:0.5rem 1.5rem 0.5rem 1.5rem;margin-top:1.5rem">' + objstr1 + '</div>';
    strr += "</body></html>";

    // html2pdf()
    //   .set(opt)
    //   .from(element)
    //   .save();

    html2pdf().from(strr).set(opt).save();
  };

  useEffect(() => {
    const params = {
      student_test_id: exam_id,
    };
    getHomeAssignmentQuestionReport(
      params,
      getHomeAssignmentQuestionReportCallback
    );
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location?.state) {
      setCardData([
        {
          id: "1",
          icon: "images/icon11.svg",
          className: "card-box redBG",
          title: "Total Questions",
          result: location?.state?.total_questions,
        },
        {
          id: "2",
          icon: "images/icon14.svg",
          className: "card-box greenBG",
          title: "Correct",
          result: location?.state?.correct_answers,
        },
        {
          id: "3",

          icon: "images/icon22.svg",

          className: "card-box pinkBG",
          title: "Incorrect",
          result: location?.state?.incorrect_answers,
        },
        {
          id: "4",
          icon: "images/icon21.svg",
          className: "card-box yellowBG ",
          title: "Not Attempted",
          result:
            location?.state?.total_questions -
            (location?.state?.correct_answers +
              location?.state?.incorrect_answers),
        },
      ]);
    }
  }, [location?.state]);

  useEffect(() => {
    gotoServices(`question-${Number(questionnumber)}`);
    //eslint-disable-next-line
  }, [questionnumber]);

  return (
    <div className="result-dash fixed-dash assignment-result">
      <div className="white-box body-cont">
        <>
          {/* overview tab  */}
          <div className="sub-report">
            <div className="dash-heading mb-5 ">
              <h1 className="themeLink mb-3 text-center w-100">Test Report</h1>
              <h1 className="text-center">
                {window?.localStorage.getItem("HAExamName")}
              </h1>
            </div>
            <>
              <div className="row reportcard_row  pb-5 ">
                {cardData?.map((quizData, index) => {
                  return (
                    <div className="col" key={index}>
                      <ReportCards
                        title={quizData.title}
                        result={quizData.result}
                        image={quizData.icon}
                        className={quizData.className}
                        isSpeed={quizData.isSpeed}
                      />
                    </div>
                  );
                })}
              </div>
            </>
            <div className="question-result d-flex">
              <div className="question-report-sec">
                <div className="question-result-header border-bottom-dashed">
                  <div className="qa-down row ">
                    {/* <div className="col-xxl-5 dash-heading">
                      <h2>{CONSTANT.LABEL.QUESTION_PAPER_ANSWERS}</h2>
                    </div> */}
                    <div className="col-xxl-7 question-search-box">
                      {/* <div className="search-form">
                        <form className="adminForm align-items-center d-sm-inline-flex w-100">
                          <div className="search-box ">
                            <div className="form-group radio-group position-relative">
                              <div className="input-box">
                                <input
                                  type={CONSTANT.TYPE.TEXT.toLowerCase()}
                                  placeholder={
                                    CONSTANT.PLACEHOLDER.SEARCH_QUESTION_PAPER
                                  }
                                  className={`text-input searchBox`}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    setQuestionNumber(Number(e.target.value));
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <Button
                              className="small-btn mt-0 px-2"
                              title={"Download Paper"}
                              onClick={(e) => {
                                downloadPdf(e);
                                // exportPDFWithComponent(e);
                              }}
                            />
                        </form>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div
                  className="result-questions home_assignmnet_result_questions mt-0 pt-0"
                  style={{ maxHeight: "70vh", overflowY: "auto" }}
                >
                  {/* <PDFExport
                          ref={pdfExportComponent}
                          // paperSize="auto"
                          // margin={40}
                          paperSize="A6"
                          // margin={{ left: "1cm", top: "1cm", right: "1cm", bottom: "1cm" }}
                        > */}
                  <p className="sub-report">
                    <QuestionHTML
                      mathsExam={questions}
                      difficuiltyLevel={getDifficultyLevel}
                      examRef={examRef}
                      cross={cross}
                      tick={tick}
                    />
                  </p>
                </div>
              </div>
              <div
                className="schedule-sidebar"
                id="schedule-right"
                style={{ position: "sticky", top: "100px" }}
              >
                <div className="right-sidebar">
                  <div className="question-count gray-bg">
                    <div className="dash-heading border-bottom-dashed pb-4">
                      <h2>Question Palette</h2>
                    </div>
                    <div className="qa-nums">
                      {questions?.map((e, i) => {
                        return (
                          <span
                            className={`${handleClasses(
                              e?.is_correct === 1 && e.status === 0
                                ? "active"
                                : e.status
                            )} inst-box text-center`}
                            onClick={() => gotoServices(`question-${i + 1}`)}
                          >
                            {i + 1}
                          </span>
                        );
                      })}
                      <div className="col">
                        <div className="progress-report ">
                          <div className="color-util">
                            <ColorCard
                              color="greenBox inst-box text-center"
                              title="Correct"
                            />
                            <ColorCard
                              color="pinkBox inst-box text-center"
                              title="Incorrect"
                            />
                            <ColorCard
                              color="yellowBox inst-box text-center"
                              title="Not Attempted"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="mobile-sidebar-icon position-fixed"
              onClick={() => setSidebarShow(!sidebarShow)}
            >
              <div className="heading">
                Calendar
                <Image src={arrowImg} alt="calendar-icon" />
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default AssignmentResult;
