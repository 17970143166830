import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useForm } from "@kaydhiman/react-hook-useform";

import Button from "../utils/forms/Button";
import OnboardingLayout from "./OnboardingLayout";
import InputField from "../utils/forms/InputField";

// import lckIcon from "../../assets/images/lock.svg";
import hidePass from "../../assets/images/pass_view-svg.png";
import showPass from "../../assets/images/eye-svg.png";
// import showPass from "../../assets/images/show-eye.png";
import userIcon from "../../assets/images/user.svg";
import logImg from "../../assets/images/loginImg.png";
import { AppContext } from "../shared/AppContext";
import { userService } from "../../_services/User.services";
import Auth from "../../auth/Auth";
import { useState } from "react";
import Spinner from "../utils/shared/spinner/spinner";
import { Modal } from "react-bootstrap";
import { CONSTANT } from "../utils/constants";
import moment from "moment";
import Image from "../utils/image/Image";
import { useRedirection } from "../../hooks/useRedirection";
import { useManageLoginSession } from "../../hooks/hookList";
import UAParser from "ua-parser-js";

const getCurrentDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hour = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
};

const generateSessionParams = (
  studentLmsId,
  sessionId,
  deviceType,
  deviceModel,
  operatingSystem,
  operatingSystemVersion,
  browserName,
  getCurrentDateTime,
  remark = "Logged In Successfully",
) => {
  return {
    student_id: studentLmsId,
    session_id: sessionId,
    device_type: deviceType !== undefined ? deviceType : "PC",
    device_model:
      deviceModel !== undefined
        ? deviceModel
        : `${operatingSystem}-${operatingSystemVersion}`,
    browser: browserName,
    login_time: moment().format("YYYY-MM-DD HH:mm:ss"),
    ...(remark === "Logged Out Successfully" && {
      logout_time: getCurrentDateTime(),
    }),
    remark,
    is_active: remark === "Logged In Successfully" ? 1 : 0,
  };
};

const ErrorLoginModal = ({ isShow }) => {
  return (
    <Modal size="md" show={isShow} centered>
      <Modal.Header className="w-100 flex-column">
        <Modal.Title className="text-center" style={{ fontSize: "28px" }}>
          Authentication Failed
        </Modal.Title>
      </Modal.Header>
      <div>
        <h3 className="text-center fs-2 lh-base">
          Please login again or contact your Service Manager to reset your
          password.
        </h3>
        <div className="d-flex justify-content-center my-5 px-5">
          <Button
            type="button"
            className="primary"
            title="Login here"
            onClick={() =>
              window?.location?.replace(process.env.REACT_APP_PRATHAM_BASE_URL)
            }
          />
        </div>
      </div>
    </Modal>
  );
};

const getDeviceInfo = () => {
  const parser = new UAParser();
  const result = parser.getResult();
  const browserName = result.browser.name;
  const browserVersion = result.browser.version;
  const operatingSystem = result.os.name;
  const operatingSystemVersion = result.os.version;
  const deviceModel = result.device.model;
  const deviceType = result.device.type;
  return {
    browserName,
    browserVersion,
    operatingSystem,
    operatingSystemVersion,
    deviceModel,
    deviceType,
  };
};

const Login = () => {
  const { values, bindField, isValid } = useForm({
    validations: {
      user_id: {
        required: {},
        noWhiteSpace: {},
      },
      password: {
        required: {},
        noWhiteSpace: {},
        minLength: {
          value: 6,
          message: "Password should be minimum 6 charactors long.",
        },
      },
    },
  });
  const route = useNavigate();
  const {
    spinner,
    setSpinner,
    setToaster,
    setShowUpdateProfileModal,
    setModalPopUpShow,
    setShowNotificationPopUp,
    setIsSidebarHide,
    isSidebarHide,
    setStudentInfo,
    setLoginTime,
    userData,
  } = useContext(AppContext);
  const [showHidePassword, setShowHidePassword] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);

  const { getRedirection } = useRedirection();

  const { getData: manageLoginSession } = useManageLoginSession(
    setSpinner,
    setToaster
  );

  const generateUniqueSessionId = (userId) => {
    return `${Math.round(new Date() / 100)}${userId}`;
  };

  const manageLoginSessionCallback = (sessionData, studentSessionParams) => {
    if (sessionData.error && sessionData.data.length >= 3) {
      return;
    } else {
      window.localStorage.setItem(
        "studentSessionDevices",
        JSON.stringify(studentSessionParams)
      );
    }
  };

  // api function
  const loginHandler = async () => {
    setSpinner(true);
    try {
      // Read the token from the URL
      const urlParams = new URLSearchParams(window.location.search);
      // Get the token from the URL
      const token = urlParams.get("prathamtoken");
      // Decode the token (base64 decoding)
      const decodedToken = token ? atob(token) : null;
      // Check if token is present or is valid
      if (!token || !isValidBase64(token)) {
        setSpinner(false);
        const routeDirection = getRedirection(userData.org_id);
        // setIsModalShow(true);
        window?.location?.replace(routeDirection);
        return;
      }
      // Parsed the decoded token into parsed
      const parsedToken = decodedToken !== null && JSON.parse(decodedToken);
      if (parsedToken.role !== "student") {
        // No need to show pop-up modal.
        // setIsModalShow(true);
        // <ErrorLoginModal isShow={isModalShow} />;
        window?.location?.replace(process.env.REACT_APP_PRATHAM_BASE_URL);
      } else {
        const response = await userService.login(parsedToken);
        if (response.status >= 200 && response.status <= 299) {
          const data = await response.json();
          if (!localStorage.getItem("studentSessionDevices")) {
            const studentLmsId = data?.data?.userInfo?.id;
            const enrollmentStudentId = data?.data?.userInfo?.user_id;
            const sessionId = generateUniqueSessionId(enrollmentStudentId);
            const {
              browserName,
              deviceModel,
              deviceType,
              operatingSystem,
              operatingSystemVersion,
            } = getDeviceInfo();
            const studentSessionParams = generateSessionParams(
              studentLmsId,
              sessionId,
              deviceType,
              deviceModel,
              operatingSystem,
              operatingSystemVersion,
              browserName,
            );
            manageLoginSession(studentSessionParams, (sessionData) =>
              manageLoginSessionCallback(sessionData, studentSessionParams)
            );
            setLoginTime(moment().format("YYYY-MM-DD hh:mm:ss A"));
          }
          // Set student data for old psis button redirection
          setStudentInfo(data?.data);
          Auth.login(data?.data);
          setModalPopUpShow(true);
          route("/dashboard");
          setIsSidebarHide(false);

          // Clean the Pratham token from the URL & redirect to dashboard page
          if (typeof window !== undefined) {
            window?.history?.replaceState(null, null, "/dashboard");
          }
          if (
            Number(data?.data.userInfo?.is_email_verified) === 0 ||
            Number(data?.data.userInfo?.is_phone_verified) === 0
            // !data?.data.userInfo?.image ||
            // data?.data?.userInfo?.image == "NULL" ||
            // data?.data?.userInfo?.image !== null
          ) {
            // setShowUpdateProfileModal(true);
          } else {
            setShowNotificationPopUp(true);
          }
          setToaster({
            show: true,
            type: "success",
            content: data?.message,
          });
          setSpinner(false);
        }
        if (response.status >= 400 && response.status <= 499) {
          const data = await response.json();
          if (data.errors) {
            // User logout due to an error
            window?.localStorage.clear();
            if (typeof window !== undefined) {
              // If there is any error redirect to fallback page
              window?.location?.replace(process.env.REACT_APP_PRATHAM_BASE_URL);
              // Clean the Pratham token from the URL
              window?.history?.replaceState(
                null,
                null,
                process.env.REACT_APP_PRATHAM_BASE_URL
              );
            }
            let error = Object.keys(data.errors)?.reverse();

            for (let i = 0; i <= error.length; i++) {
              setToaster({
                show: true,
                type: "danger",
                content: data.errors[error[i]][0],
              });
            }
          } else {
            // User Logout due to an error
            window?.localStorage?.clear();
            if (typeof window !== undefined) {
              // If there is any error redirect to fallback page
              window?.location?.replace(process.env.REACT_APP_PRATHAM_BASE_URL);
              // Clean the Pratham token from the URL
              window?.history?.replaceState(
                null,
                null,
                process.env.REACT_APP_PRATHAM_BASE_URL
              );
            }
            // Show Toast Error Message
            setToaster({
              show: true,
              type: "danger",
              content: data.message,
            });
          }
          setSpinner(false);
        }
      }
    } catch (error) {
      // User Logout due to an error
      setSpinner(false);
      window?.localStorage?.clear();
      if (error instanceof SyntaxError && error.message.includes("JSON")) {
        setSpinner(false);
        // setIsModalShow(true);
        // No need to show pop-up modal now
        window?.location?.replace(process.env.REACT_APP_PRATHAM_BASE_URL);
        return;
      }

      // setIsModalShow(true);
      // No need to show pop-up modal now
      window?.location?.replace(process.env.REACT_APP_PRATHAM_BASE_URL);

      // Show Toast Error Message
      // setToaster({ show: true, type: "danger", content: error.message });
      // Set Loading Indicator False
    }

    setSpinner(false);
    // api funstion
  };

  // Function to check if a string is base64 encoded
  const isValidBase64 = (str) => {
    try {
      return btoa(atob(str)) === str;
    } catch (e) {
      return false;
    }
  };
  useEffect(() => {
    loginHandler();
  }, []);

  return (
    <>
      {/* <OnboardingLayout
        Form={
          <>
            <div className="dash-heading">
              <h1>Login</h1>
            </div>
            <form onSubmit={loginHandler}>
              <InputField
                label="Use ID"
                id="user_id"
                name="user_id"
                value={values?.user_id || ""}
                bindField={bindField}
                placeholder="Enter User ID"
                type="text"
                iconData={{
                  className: "lock_ico",
                  isLock: true,
                  src: userIcon,
                  alt: "Lock Icon",
                }}
              />
              <InputField
                label="Password"
                id="password"
                name="password"
                value={values?.password || ""}
                bindField={bindField}
                placeholder="Enter Password"
                type={showHidePassword ? "text" : "password"}
                iconData={{
                  className: "lock_ico pointer",
                  isLock: true,
                  src: showHidePassword ? showPass : hidePass,
                  alt: "eye Icon",
                }}
                isPassword
                showHidePassword={showHidePassword}
                setShowHidePassword={setShowHidePassword}
              />

              {/* <Image src={lckIcon} alt="lock image" /> */}
      {/* <Image src={hidePass} alt="eye image" />
              <Image src={showPass} alt="eye image" /> */}
      {/* <div className="w-100 text-end pt-2">
                <Link className="themeLink" to="/forgotpassword">
                  Forgot Password?
                </Link>
              </div>
              <Button
                className="loginBtn"
                link="/home"
                id="logBtn"
                title={"Login"}
                disable={!isValid()}
              />
              <p className="text-center pt-4 footer-context">
                Don't have an account yet?
                <Link to="/signup" className="themeLink">
                  &nbsp;Sign Up
                </Link>
              </p>
            </form>
          </>
        }
        image={logImg}
      />  */}
      {/***********Loading Indicator shown while user is not Logged In***************/}
      {spinner === true && (
        <div className="login-loading-indicator-background">
          <Spinner whiteBackground="true" />
        </div>
      )}
      {/* {spinner === false && <ErrorLoginModal isShow={isModalShow} />}  */}
    </>
  );
};

export default Login;
