// React imports
import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Context and service imports
import { AppContext } from "../../shared/AppContext";
import { userService } from "../../../_services/User.services";
import { useQuestionType } from "../../../hooks/useQuestionType";
import { useBreadcrumb } from "../../shared/breadcrumb-context";

// Component imports
import CommonModal from "../../utils/modal/CommonModal";
import Button from "../../utils/forms/Button";
import Question from "./Question";
import QuestionPallete from "./QuestionPallete";

// Style and image imports
import "./homeAssignmentExamList.scss";
import arrowImg from "../../../assets/images/white-arrow-down.svg";
import { Modal } from "react-bootstrap";
import "./homeAssignmentExamList.scss";
import {
  useSubmitTest,
  useSubmittedQuestionCount,
  useUpdateTestData,
} from "../../../hooks/hookList";

import "./homeAssignmentExam.scss";
import { removeHTMLTags } from "../../utils/removeTags";
import { updateSubmitDataResponse } from "../../utils/helpers/updateExamSubmitDataResponse";
import Image from "../../utils/image/Image";

const Exams = () => {
  // Destructure parameters from URL
  const { exam_id } = useParams();

  const navigate = useNavigate();

  // Destructure context values and state variables
  const { setToaster, setSpinner, spinner, sidebarShow, setSidebarShow } =
    useContext(AppContext);

  // States related to exam structure and questions
  const [examStructure, setExamStructureData] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [submittedQuestionData, setSubmittedQuestionData] = useState([]);
  const [submitDataResponse, setSubmitDataResponse] = useState([]);
  // States related to timer and time tracking
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [totalTimeSpent, setTotalTimeSpent] = useState(0);

  // States related to question limits,tabs & modals
  const [selectedTabId, setSelectedTabId] = useState(null);
  const [selectedOption, setSelectedOption] = useState("0");
  const [viewResultModal, setViewResultModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [isError, setIsError] = useState(false);

  // States related to answers and input handling
  const [answer, setAnswer] = useState({
    oneWord: "",
    numerical: "",
    subjective: "",
  });
  const [questionWiseMarking, setQuestionWiseMarking] = useState(false);
  const [hide, setHide] = useState({
    numerical: false,
    oneWord: false,
    subjective: false,
  });

  const [disableKeyboard, setDisableKeyboard] = useState(false);

  // Custom hooks
  const { questionType } = useQuestionType();
  const { dispatch } = useBreadcrumb();
  const { getData: updateTestData } = useUpdateTestData(setSpinner, setToaster);
  const { getData: getSubmittedQuestionCount } = useSubmittedQuestionCount(
    setSpinner,
    setToaster
  );
  const { getData: submitTest } = useSubmitTest(setSpinner, setToaster);

  // Ref for timer status
  const isTimerRunningRef = useRef(true);

  // Ref for keyboard
  const keyboardRef = useRef();
  const dataRef = useRef([]);

  const palleteClassMapping = {
    green: (status, isCorrect) => status === 0 && isCorrect === 0 && "greenBox",
    pink: (status, isCorrect) => status === 0 && isCorrect === 1 && "pinkBox",
    yellow: (status, isCorrect) =>
      status === 3 && ["", null].includes(isCorrect) && "yellowBox",
    gray: (status, isCorrect) =>
      (status === 4 && ["", null].includes(isCorrect) && "grayBox") ||
      (status === 3 && ["", null].includes(isCorrect) && "grayBox"),
  };

  const handleClasses = (status, isCorrect) => {
    let classKey = "";
    for (const key in palleteClassMapping) {
      if (palleteClassMapping[key](status, isCorrect)) {
        classKey = key;
        break;
      }
    }
    return palleteClassMapping[classKey]
      ? palleteClassMapping[classKey](status, isCorrect)
      : palleteClassMapping["gray"];
  };

  const finalSubmitData = (examStatistics) => {
    const combinedData = {
      exam_id: Number(exam_id),
      exam_status: 1,
      data: examStatistics.section_wise_data,
    };

    return combinedData;
  };

  // onChange Handler Of Numeric Question
  const handleNumericAnswer = (key) => {
    if (key === "⌫") {
      // If numericAnswer is null or empty, do nothing
      if (answer.numerical === null || answer.numerical === "") {
        return;
      }
      if (answer.numerical.length > 1) {
        setAnswer((prev) => ({
          ...prev,
          numerical: answer.numerical.slice(0, -1),
        }));
      } else {
        setAnswer((prev) => ({ ...prev, numerical: "" }));
      }
    } else {
      setAnswer((prev) => ({
        ...prev,
        numerical:
          answer.numerical !== null ? answer.numerical + "" + key : key,
      }));
    }
  };

  // Function handle to enable/disable keyboard based on the question type
  const isEnableKeyboard = (questionType) => {
    if ([1, 2, 5].includes(questionType)) {
      setDisableKeyboard(false);
    } else {
      setDisableKeyboard(true);
    }
  };

  // Function to calculate exam statistics based on exam structure and submission data
  const calculateExamStatistics = (responseData) => {
    const sectionWiseData = responseData.map((sectionData) => {
      const sectionId = Object.keys(sectionData)[0];
      const sectionObject = sectionData[sectionId];

      sectionObject.score = 0;
      sectionObject.total_marks = 0;
      sectionObject.totalCorrectMarks = 0;
      sectionObject.totalNegativeMarks = 0;

      // Filter questions with status key 0 or 1
      const filteredQuestions = sectionObject.question_data.filter(
        (question) => question.status === 0 || question.status === 1
      );

      for (const question of sectionObject.question_data) {
        // For Single, Multiple Answer Type Questions updating the is_correct value
        if ([1, 2, 6].includes(question.question_type_id)) {
          if (
            Number(question.selected_option) === Number(question.correct_option)
          ) {
            question.is_correct = 0;
            // Calculate Score Question Wise
            if (questionWiseMarking) {
              sectionObject.totalCorrectMarks += Number(question.correct_marks);
            }
          } else {
            question.is_correct = 1;
            // Calculate Score Question Wise
            if (questionWiseMarking) {
              sectionObject.totalNegativeMarks += Number(
                question.negative_marks
              );
            }
          }
        }
        if ([3, 4].includes(question.question_type_id)) {
          if (
            removeHTMLTags(
              question?.selected_option
                ?.toString()
                ?.toLowerCase()
                ?.trim()
                ?.replaceAll(".", "")
                ?.replaceAll(",", "")
            ) ===
            removeHTMLTags(
              question?.correct_option_value
                ?.toString()
                ?.toLowerCase()
                ?.trim()
                ?.replaceAll(".", "")
                ?.replaceAll(",", "")
            )
          ) {
            question.is_correct = 0;
            // Calculating Question Wise Score
            if (questionWiseMarking) {
              sectionObject.totalCorrectMarks += Number(question.correct_marks);
            }
          } else {
            question.is_correct = 1;
            // Calculating Question Wise Score
            if (questionWiseMarking) {
              sectionObject.totalNegativeMarks += Number(
                question.negative_marks
              );
            }
          }
        }
        // For Question Statuses 2,3,4 update the is_correct value set to null & Subjective Answer is_correct value should be null
        if ([2, 3, 4].includes(question.status)) {
          question.is_correct = null;
        }
        if (Number(question.question_type_id) === 5) {
          question.correct_options = null;
          question.is_correct = null;
        }
        if (questionWiseMarking) {
          // Calculating Total Marks Question Wise
          sectionObject.total_marks += Number(question.correct_marks);
        }
      }

      const sectionCorrectAnswers = filteredQuestions.reduce(
        (acc, question) => acc + (question.is_correct === 0 ? 1 : 0),
        0
      );

      const sectionIncorrectAnswers = filteredQuestions.reduce(
        (acc, question) => acc + (question.is_correct === 1 ? 1 : 0),
        0
      );

      const totalTimeSpent = sectionObject.question_data.reduce(
        (acc, question) => acc + question.time_spent,
        0
      );

      sectionObject.correct_answers = sectionCorrectAnswers;
      sectionObject.incorrect_answers = sectionIncorrectAnswers;
      // Template Wise Marking Score Calculating
      if (questionWiseMarking) {
        sectionObject.score =
          sectionObject.totalCorrectMarks - sectionObject.totalNegativeMarks;
      } else {
        sectionObject.score =
          sectionCorrectAnswers * Number(sectionObject.correct_marks) -
          sectionIncorrectAnswers * Number(sectionObject.negative_marks);
      }
      sectionObject.section_time_spent = totalTimeSpent;
      sectionObject.total_attempt =
        sectionCorrectAnswers + sectionIncorrectAnswers;
      // Template Wise Marking Total Marks Calculating
      if (!questionWiseMarking) {
        sectionObject.total_marks =
          sectionObject.question_data.length *
          Number(sectionObject.correct_marks);
      }

      return {
        [sectionId]: sectionObject,
      };
    });

    // Return the section-wise data and overall statistics
    return {
      section_wise_data: sectionWiseData,
    };
  };

  // Function to create a question object with initial values
  const createQuestionObject = (
    id,
    questionData,
    initialStatus,
    isQuestionWiseMarking
  ) => {
    return {
      [id]: {
        section_time_spent: 0,
        total_attempt: null,
        total_marks: 0,
        correct_answers: 0,
        incorrect_answers: 0,
        score: 0,
        status: 0,
        duration: 0,
        // Template Wise Correct  Marking
        ...(!isQuestionWiseMarking && {
          correct_marks: 0,
        }),
        // Template Wise Incorrect Marking
        ...(!isQuestionWiseMarking && {
          negative_marks: 0,
        }),
        total_questions: questionData?.length || 0,
        question_data: (questionData || []).map((question, index) => {
          // Check if there is saved user data for the question
          const savedUserExamData = question.student_test_questions;

          // Find the correct option for the question
          const correctOption = question.options.find(
            (option) => option.is_correct === 1
          );
          // If there is saved user data, use it; otherwise, use default values
          if (savedUserExamData) {
            return {
              question_id: savedUserExamData.question_id,
              question_type_id: question.question_type_id,
              subject_id: savedUserExamData.subject_id,
              topic_id: savedUserExamData.topic_id,
              status:
                savedUserExamData.status === 4 && index === 0
                  ? 3
                  : savedUserExamData.status,
              selected_option:
                [3, 4, 5].includes(Number(question?.question_type_id)) &&
                savedUserExamData.input_answer !== null
                  ? savedUserExamData.input_answer
                  : !["-1", null].includes(savedUserExamData?.selected_option)
                  ? savedUserExamData?.selected_option
                  : null,
              correct_option:
                savedUserExamData.correct_options !== null
                  ? savedUserExamData.correct_options
                  : ![3, 4, 5].includes(question.question_type_id) &&
                    correctOption
                  ? correctOption.id
                  : question.options[0]?.id,
              // One Word Correct Option
              ...(question.question_type_id === 4 && {
                correct_option_value: correctOption.option,
              }),
              // Numerical Correct Option
              ...(question.question_type_id === 3 && {
                correct_option_value: correctOption.option,
              }),
              // Subjective Correct Option
              ...(question.question_type_id === 5 && {
                correct_option_value: correctOption.option,
              }),
              is_correct:
                savedUserExamData.is_correct !== null
                  ? savedUserExamData.is_correct
                  : null,
              time_spent: Number(savedUserExamData.time_spent),
              // Question Wise Correct Marks
              ...(isQuestionWiseMarking && {
                correct_marks: Number(question.correct_marks),
              }),
              // Question Wise Negative Marks
              ...(isQuestionWiseMarking && {
                negative_marks: Number(question.negative_marks),
              }),
            };
          }
        }),
      },
    };
  };

  const getExamStructure = async () => {
    setSpinner(true);
    try {
      const response = await userService.examTemplateStructure({ exam_id });
      if (response.ok) {
        const { data } = await response.json();
        if (data.structure) {
          // Set Question Wise Marking When 1 other wise tempalte wise marking
          setQuestionWiseMarking(
            Number(data?.exam_data?.marking_scheme) === 1 ? true : false
          );
          // Find the current section in resume test whose status is 1
          const currentSectionStructure = data.structure.find(
            (structureIndex) => structureIndex.spent_status === 1
          );

          // Set the selected tab, question limit, and exam structure data
          setSelectedTabId(data.structure[0]?.id);
          setExamStructureData(data.structure);
          // Fetch questions for the first section
          const questionResponse = await userService.getQuestions({
            exam_id,
            exam_template_structure_id: data.structure[0]?.id,
          });
          if (questionResponse.ok) {
            const { data: questionData } = await questionResponse.json();
            setQuestions(questionData);
            const questionObject = createQuestionObject(
              data.structure[0]?.id,
              questionData,
              3,
              Number(data?.exam_data?.marking_scheme) === 1 ? true : false
            );
            setCurrentQuestionIndex(0);

            // Update submit data response with relevant information
            updateSubmitDataResponse(
              data.structure[0]?.id,
              (section) => {
                if (section) {
                  const currentSection = section[data.structure[0]?.id];
                  return {
                    total_questions: questionData.length,
                    correct_answers: currentSection?.correct_answers,
                    incorrect_answers: currentSection?.incorrect_answers,
                    score: currentSection?.score,
                    total_marks: currentSection?.total_marks,
                    total_attempt:
                      currentSectionStructure?.total_attempt !== undefined
                        ? Number(currentSectionStructure?.total_attempt)
                        : 0,
                    question_data: [...currentSection?.question_data],
                    status: 1,
                    duration: Number(data.structure[0]?.duration),
                    ...(Number(data.exam_data.marking_scheme) === 0 && {
                      correct_marks: data.structure[0]?.correct_marks,
                    }),
                    ...(Number(data.exam_data.marking_scheme) === 0 && {
                      negative_marks: data.structure[0]?.negative_marks,
                    }),
                  };
                }
              },
              setSubmitDataResponse,
              questionObject
            );
            if (data.structure[0]?.time_spent !== null) {
              setTimeRemaining(
                Number(data.exam_data.duration) * 60 -
                  Number(data.structure[0]?.time_spent)
              );
              setTotalTimeSpent(Number(data.structure[0]?.time_spent));
            } else {
              setTimeRemaining(Number(data.exam_data.duration) * 60);
            }
            if (
              ![null, "0"].includes(
                questionData[0]?.student_test_questions.selected_option
              )
            ) {
              if ([3, 4].includes(Number(questionData[0]?.question_type_id))) {
                setSelectedOption(
                  questionData[0]?.student_test_questions.input_answer
                );
                if (Number(questionData[0]?.question_type_id) === 3) {
                  setHide((prev) => ({ ...prev, numerical: true }));
                  setAnswer((prev) => ({
                    ...prev,
                    numerical:
                      questionData[0]?.student_test_questions.input_answer,
                  }));
                }
                if (Number(questionData[0]?.question_type_id) === 4) {
                  setHide((prev) => ({ ...prev, oneWord: true }));
                  setAnswer((prev) => ({
                    ...prev,
                    oneWord:
                      questionData[0]?.student_test_questions.input_answer,
                  }));
                }
              } else {
                setSelectedOption(
                  questionData[0]?.student_test_questions?.selected_option
                );
              }
            }
          } else {
            const errorData = await questionResponse.json();
            setToaster({
              show: true,
              type: "danger",
              content: errorData.message,
            });
          }
        }
        setSpinner(false);
      } else {
        setErrorModal(true);
        // const errorData = await response.json();
        // setToaster({ show: true, type: "danger", content: errorData.message });
        setSpinner(false);
      }
    } catch (error) {
      setToaster({ show: true, type: "danger", content: "An error occurred." });
      setSpinner(false);
    }
  };

  // Handle moving to the next question
  const handleNext = (currentIndex) => {
    if (currentIndex < questions.length - 1) {
      updateSubmitDataResponse(
        selectedTabId,
        (section) => {
          const nextQuestion = section.question_data[currentIndex + 1];
          if (!["0", null].includes(nextQuestion.selected_option)) {
            if (nextQuestion.question_type_id === 3) {
              setHide((prev) => ({ ...prev, numerical: true }));
              setAnswer((prev) => ({
                ...prev,
                numerical: nextQuestion.selected_option,
              }));
            } else {
              setSelectedOption(nextQuestion.selected_option);
            }
            if (nextQuestion.question_type_id === 4) {
              setHide((prev) => ({ ...prev, oneWord: true }));
              setAnswer((prev) => ({
                ...prev,
                oneWord: nextQuestion.selected_option,
              }));
            } else {
              setSelectedOption(nextQuestion.selected_option);
            }
            if (nextQuestion.question_type_id === 5) {
              setHide((prev) => ({ ...prev, subjective: true }));
              setAnswer((prev) => ({
                ...prev,
                subjective: nextQuestion.selected_option,
              }));
            } else {
              setSelectedOption(nextQuestion.selected_option);
            }
          } else {
            if (
              ![0].includes(nextQuestion.status) &&
              nextQuestion.question_type_id === 3
            ) {
              nextQuestion.status = 3;
              setHide((prev) => ({ ...prev, numerical: false }));
            }
            if (
              ![0].includes(nextQuestion.status) &&
              nextQuestion.question_type_id === 4
            ) {
              nextQuestion.status = 3;
              setHide((prev) => ({ ...prev, oneWord: false }));
            }
            if (
              ![0].includes(nextQuestion.status) &&
              nextQuestion.question_type_id === 5
            ) {
              nextQuestion.status = 3;
              setHide((prev) => ({ ...prev, subjective: false }));
            }
            nextQuestion.status = 3;
            setSelectedOption(null);
          }
          return section;
        },
        setSubmitDataResponse
      );
    }
    setCurrentQuestionIndex(currentIndex + 1);
    keyboardRef?.current?.setInput("");
    setAnswer((prev) => ({
      ...prev,
      oneWord: "",
      numerical: "",
      subjective: "",
    }));
  };

  // Handle moving to the previous question
  const handlePrevious = (currentIndex) => {
    if (currentIndex > 0) {
      updateSubmitDataResponse(
        selectedTabId,
        (section) => {
          const previousQuestionData = section.question_data[currentIndex - 1];
          if (!["0", null].includes(previousQuestionData.selected_option)) {
            setSelectedOption(previousQuestionData.selected_option);
            if (previousQuestionData.question_type_id === 3) {
              setHide((prev) => ({ ...prev, numerical: true }));
              setAnswer((prev) => ({
                ...prev,
                numerical: previousQuestionData.selected_option,
              }));
            }
            if (previousQuestionData.question_type_id === 4) {
              setHide((prev) => ({ ...prev, oneWord: true }));
              setAnswer((prev) => ({
                ...prev,
                oneWord: previousQuestionData.selected_option,
              }));
            }
            if (previousQuestionData.question_type_id === 5) {
              setHide((prev) => ({ ...prev, subjective: true }));
              setAnswer((prev) => ({
                ...prev,
                subjective: previousQuestionData.selected_option,
              }));
            }
          } else {
            setSelectedOption(null);
            if (![0].includes(previousQuestionData.status)) {
              if (previousQuestionData?.question_type_id === 3) {
                previousQuestionData.status = 3;
                setHide((prev) => ({ ...prev, numerical: false }));
              }
              if (previousQuestionData?.question_type_id === 4) {
                previousQuestionData.status = 3;
                setHide((prev) => ({ ...prev, oneWord: false }));
              }
              if (previousQuestionData?.question_type_id === 5) {
                previousQuestionData.status = 3;
                setHide((prev) => ({ ...prev, subjective: false }));
              }
              previousQuestionData.status = 3;
            }
          }
          return section;
        },
        setSubmitDataResponse
      );
      setCurrentQuestionIndex(currentIndex - 1);
      keyboardRef?.current?.setInput("");
      setAnswer((prev) => ({
        ...prev,
        oneWord: "",
        numerical: "",
        subjective: "",
      }));
    }
  };

  const handleSubmission = async (responseData) => {
    let calculationExam = calculateExamStatistics(responseData);
    await submitTest(finalSubmitData(calculationExam), (data) => {
      if (data.success === false) {
        setIsError(true);
        setErrorModal(true);
      }
    });
  };

  // Function to handle option changes for a specific question
  const handleOptionChange = (e, questionId) => {
    const selectedOption = Number(e.target.value);
    updateSubmitDataResponse(
      selectedTabId,
      (section) => {
        const questionIndex = section.question_data.findIndex(
          (question) => question.question_id === questionId
        );

        const currentQuestion = section.question_data[questionIndex];
        currentQuestion.selected_option = selectedOption;
        currentQuestion.is_correct =
          Number(currentQuestion.correct_option) === selectedOption ? 0 : 1;
        currentQuestion.status = 0;

        return section;
      },
      setSubmitDataResponse
    );
    setSelectedOption(selectedOption);
  };

  // Function to check if a specific option is selected for a question
  const isOptionSelected = (question_id, option_id) => {
    const currentSection = submitDataResponse.find(
      (section) => section[selectedTabId]
    );

    const selectedQuestion = currentSection?.[
      selectedTabId
    ]?.question_data?.find((question) => question.question_id == question_id);

    const isChecked =
      selectedQuestion?.selected_option == option_id ? true : false;

    return isChecked;
  };

  const handleOneWordInputChange = (input) => {
    keyboardRef?.current?.setInput(input);
  };

  // Function to handle clicking on a question, updating its status, and setting the current question index
  const handleQuestionClick = (nextQuestionIndex) => {
    setCurrentQuestionIndex(nextQuestionIndex);
    updateSubmitDataResponse(
      selectedTabId,
      (section) => {
        const nextQuestion = section.question_data[nextQuestionIndex];

        if (!["0", null].includes(nextQuestion.selected_option)) {
          if (nextQuestion.question_type_id === 3) {
            setSelectedOption(nextQuestion.selected_option);
            setHide((prev) => ({ ...prev, numerical: true }));
            setAnswer((prev) => ({
              ...prev,
              numerical: nextQuestion.selected_option,
            }));
          }
          if (nextQuestion.question_type_id === 4) {
            setSelectedOption(nextQuestion.selected_option);
            setHide((prev) => ({ ...prev, oneWord: true }));
            setAnswer((prev) => ({
              ...prev,
              oneWord: nextQuestion.selected_option,
            }));
          }
          if (nextQuestion.question_type_id === 5) {
            setSelectedOption(nextQuestion.selected_option);
            setHide((prev) => ({ ...prev, subjective: true }));
            setAnswer((prev) => ({
              ...prev,
              subjective: nextQuestion.selected_option,
            }));
          } else {
            setSelectedOption(nextQuestion.selected_option);
          }
        } else {
          setSelectedOption(null);

          if (nextQuestion.question_type_id === 3) {
            setHide((prev) => ({ ...prev, numerical: false }));
          }
          if (nextQuestion.question_type_id === 4) {
            setHide((prev) => ({ ...prev, oneWord: false }));
          }
          if (nextQuestion.question_type_id === 5) {
            setHide((prev) => ({ ...prev, subjective: false }));
          }
          if (![0].includes(nextQuestion.status)) {
            nextQuestion.status = 3;
            if (
              answer.subjective !== "" &&
              nextQuestion.question_type_id === 5
            ) {
              setAnswer((prev) => ({ ...prev, subjective: "" }));
            }
            if (
              answer.numerical !== "" &&
              nextQuestion.question_type_id === 3
            ) {
              setAnswer((prev) => ({ ...prev, numerical: "" }));
            }
            if (answer.oneWord !== "" && nextQuestion.question_type_id === 4) {
              setAnswer((prev) => ({ ...prev, oneWord: "" }));
            }
          }
        }
        return section;
      },
      setSubmitDataResponse
    );
  };

  const autoSubmitExam = async () => {
    await handleSubmission(submitDataResponse);
    // await getSubmittedQuestionCount();
    await updateTestData({ exam_id }, () => {});
  };

  const viewResultHandler = () => {
    setViewResultModal(false);
    navigate(`/home-assignments/assignment-report/${exam_id}`, {
      replace: true,
    });
  };

  const startTimer = () => {
    const timer = setInterval(() => {
      if (isTimerRunningRef.current) {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 0) {
            clearInterval(timer);
            return 0;
          }
          setTotalTimeSpent((prevTotalTime) => prevTotalTime + 1);
          return prevTime - 1;
        });
      }
    }, 1000);
    return () => clearInterval(timer);
  };

  const handleUpdateNumericalQuestion = () => {
    updateSubmitDataResponse(
      selectedTabId,
      (section) => {
        const currentQuestion = section.question_data[currentQuestionIndex];
        currentQuestion.selected_option = answer.numerical;
        currentQuestion.is_correct =
          Number(currentQuestion.correct_option_value) ===
          Number(answer.numerical)
            ? 0
            : 1;
        currentQuestion.status = 0;
        return section;
      },
      setSubmitDataResponse
    );
    setSelectedOption(answer.numerical);
    setHide((prev) => ({ ...prev, numerical: true }));
  };

  const handleOnChangeOneWord = (value, questionId) => {
    const currentSection = submitDataResponse.find(
      (section) => section[selectedTabId]
    );
    const currentQuestion = currentSection?.[
      selectedTabId
    ]?.question_data?.find(
      (question) => Number(question.question_id) === Number(questionId)
    );
    if (currentQuestion) {
      setAnswer((prev) => ({ ...prev, oneWord: value }));
      keyboardRef?.current?.setInput(value);
    }
  };

  const handleOneWordAnswer = () => {
    updateSubmitDataResponse(
      selectedTabId,
      (section) => {
        const currentQuestion = section?.question_data[currentQuestionIndex];
        currentQuestion.selected_option = answer.oneWord;
        currentQuestion.is_correct =
          String(currentQuestion?.correct_option_value)
            .toLowerCase()
            .replaceAll(",", "")
            .replaceAll(".", "") ===
          answer.oneWord
            .toLocaleLowerCase()
            .replaceAll(",", "")
            .replaceAll(".", "")
            ? 0
            : 1;
        currentQuestion.status = 0;
        return section;
      },
      setSubmitDataResponse
    );
    setSelectedOption(answer.oneWord);
    setHide((prev) => ({ ...prev, oneWord: true }));
  };

  const handleSubjectiveAnswer = (answer, questionId) => {
    const currentSection = submitDataResponse.find(
      (section) => section[selectedTabId]
    );
    const currentQuestion = currentSection?.[
      selectedTabId
    ]?.question_data?.find(
      (question) => Number(question.question_id) === Number(questionId)
    );
    if (currentQuestion) {
      setAnswer((prev) => ({ ...prev, subjective: answer }));
    }
  };

  const handleSaveSubjectiveAnswer = () => {
    updateSubmitDataResponse(
      selectedTabId,
      (section) => {
        const currentQuestion = section?.question_data[currentQuestionIndex];
        currentQuestion.selected_option = answer.subjective;
        currentQuestion.is_correct =
          String(currentQuestion.correct_option_value)
            .toLocaleLowerCase()
            .replaceAll(" ", "") ===
          answer.subjective.toLocaleLowerCase().replaceAll(" ", "")
            ? 0
            : 1;
        currentQuestion.status = 0;
        return section;
      },
      setSubmitDataResponse
    );
    setSelectedOption(answer.subjective);
    setHide((prev) => ({ ...prev, subjective: true }));
  };

  useEffect(() => {
    const fetchDataAndStartTimer = async () => {
      try {
        await getExamStructure();
        startTimer();
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataAndStartTimer();
    dispatch({ type: "SET_BREADCRUMB", crumb: [] });
  }, []);

  useEffect(() => {
    if (timeRemaining <= 0 && timeRemaining !== null) {
      autoSubmitExam();
      setViewResultModal(true);
    }
    updateSubmitDataResponse(
      Number(selectedTabId),
      (section) => {
        const currentQuestion = section?.question_data[currentQuestionIndex];
        if (currentQuestion) {
          currentQuestion.time_spent = Number(currentQuestion.time_spent) + 1;
        }
        return section;
      },
      setSubmitDataResponse
    );
    if (totalTimeSpent % 60 === 0) {
      // auto submit
      handleSubmission(submitDataResponse);
    }
  }, [totalTimeSpent]);

  useEffect(() => {
    dataRef.current = submitDataResponse;
  }, [submitDataResponse]);

  useEffect(() => {
    window.addEventListener("beforeunload", async (event) => {
      event.preventDefault();
      await handleSubmission(dataRef.current);
      return;
    });

    return () =>
      window.removeEventListener("beforeunload", async (event) => {
        event.preventDefault();
        await handleSubmission(dataRef.current);
        return;
      });
  }, []);

  return (
    <div className="exam-screen d-flex gap-4">
      {!spinner && !isError && (
        <>
          <div className="position-relative home_exam_white_box w-xl-75">
            <div className="home_exam_container">
              <div className="exam-sec row">
                <Question
                  questionData={questions}
                  currentQuestionIndex={currentQuestionIndex}
                  questionType={questionType}
                  selectedOption={selectedOption}
                  isOptionSelected={isOptionSelected}
                  keyboardRef={keyboardRef}
                  numericAnswer={answer.numerical}
                  oneWordAnswer={answer.oneWord}
                  subjectiveAnswer={answer.subjective}
                  handleOptionChange={handleOptionChange}
                  handleOneWordAnswer={handleOneWordAnswer}
                  handleOneWordInputChange={handleOneWordInputChange}
                  handleNumericAnswer={handleNumericAnswer}
                  handleUpdateNumericalQuestion={handleUpdateNumericalQuestion}
                  handleOnChangeOneWord={handleOnChangeOneWord}
                  handleSubjectiveAnswer={handleSubjectiveAnswer}
                  handleSaveSubjectiveAnswer={handleSaveSubjectiveAnswer}
                  isHideNumericalKeyboard={hide.numerical}
                  isHideOneWordKeyboard={hide.oneWord}
                  isHideSubjectiveKeyboard={hide.subjective}
                />
              </div>
            </div>
            <div className="home_exam_buttons">
              <ul className="navigation_button_container">
                <li className="previous_btn_container">
                  <button
                    className={
                      currentQuestionIndex === 0
                        ? "prev_button_disabled prev_button"
                        : "prev_button"
                    }
                    onClick={() => handlePrevious(currentQuestionIndex)}
                    disabled={currentQuestionIndex === 0}
                  >
                    Previous
                  </button>
                </li>
                <li className="next_btn_container">
                  <button
                    className={
                      currentQuestionIndex === questions.length - 1
                        ? "next_button_disabled next_button"
                        : "next_button"
                    }
                    disabled={currentQuestionIndex === questions.length - 1}
                    onClick={() => handleNext(currentQuestionIndex)}
                  >
                    Next
                  </button>
                </li>
              </ul>
              <ul className="submit_btn_container">
                <div
                  className=""
                  onClick={async () => {
                    await handleSubmission(submitDataResponse);
                    await getSubmittedQuestionCount({ exam_id }, (data) =>
                      setSubmittedQuestionData(data?.data)
                    );
                    await updateTestData({ exam_id }, () => {});
                    setViewResultModal(true);
                  }}
                >
                  <span className="btn theme-btn pinkBox small-btn me-4 submit_button my-3">
                    Submit Test
                  </span>
                </div>
              </ul>
            </div>
          </div>

          <QuestionPallete
            questionsData={submitDataResponse}
            handleClasses={handleClasses}
            handleQuestionClick={handleQuestionClick}
            selectedTabId={selectedTabId}
            remainingTime={timeRemaining}
          />
          <CommonModal
            className="exam-modal"
            show={viewResultModal}
            crossShow={false}
            body={
              <div className="common_modal">
                <div className="modal-footer d-block pb-5 pt-0">
                  <p>Thank you, </p> <p>Submitted Successfully.</p>
                  <div className="button-footer w-100">
                    <Button
                      className="procees small-btn me-4"
                      title="View Result"
                      onClick={viewResultHandler}
                    />
                  </div>
                </div>
              </div>
            }
          />
        </>
      )}
      <div>
        <Modal
          size="sm"
          show={errorModal}
          onHide={() => setErrorModal(false)}
          centered
        >
          <Modal.Header>
            <Modal.Title
              className="w-100 text-center"
              style={{ fontSize: "2rem" }}
            >
              Note
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center p-5" style={{ fontSize: "2rem" }}>
            Retake will be allowed after 5 minutes
          </Modal.Body>
        </Modal>
      </div>
      <div
        className="mobile-sidebar-icon position-fixed"
        onClick={() => setSidebarShow(!sidebarShow)}
      >
        <div className="heading">
          {" "}
          <span>Palette</span>
          <Image src={arrowImg} alt="arrow" />
        </div>
      </div>
    </div>
  );
};

export default Exams;
