import React, { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { userService } from "../../../_services/User.services";
import { AppContext } from "../../shared/AppContext";
import ReportCards from "../../utils/card/ReportCards";
import { CONSTANT } from "../../utils/constants";

const AssignmentReport = () => {
  const { exam_id } = useParams();
  const { setSpinner, setToaster } = useContext(AppContext);
  const [assignmentReportData, setAssignmentReportData] = useState([]);
  const [rData, setRData] = useState({});

  const assignReport = (data) => {
    return [
      {
        id: 1,
        icon: CONSTANT.IMAGE_PATH.TOTAL_QUESTIONS,
        className: "card-box redBG",
        title: CONSTANT.LABEL.TOTAL_QUESTIONS,
        result: data?.total_questions,
      },
      {
        id: 2,
        icon: CONSTANT.IMAGE_PATH.CORRECT,
        className: "cards-box greenBG",
        title: CONSTANT.LABEL.CORRECT,
        result: data?.correct_answers,
      },
      {
        id: 3,
        icon: CONSTANT.IMAGE_PATH.INCORRECT,
        className: "cards-box redBG",
        title: CONSTANT.LABEL.INCORRECT,
        result: data?.incorrect_answers,
      },
      {
        id: 4,
        icon: CONSTANT.IMAGE_PATH.NOT_ATTEMPTED,
        className: "cards-box yellowBG",
        title: CONSTANT.LABEL.NOT_ATTEMPTED,
        result:
          data?.total_questions -
          (data?.correct_answers + data?.incorrect_answers),
      },
      // {
      //   id: 5,
      //   icon: "images/icon24.svg",
      //   className: "cards-box blueBG",
      //   title: "Total Time",
      //   result: data?.exam?.duration,
      //   isMinute: true
      // },
      // {
      //   id: 6,
      //   icon: "images/icon25.svg",
      //   className: "cards-box darkBlue",
      //   title: "Time Remaining",
      //   result: data?.time_remaining,
      // },
    ];
  };

  const [testId, setTestId] = useState();
  const getAssignementReport = async () => {
    const response = await userService.getAssignementReport({
      exam_id: exam_id,
    });

    try {
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();

        setTestId(data?.data?.id);
        setAssignmentReportData(assignReport(data?.data));
        setRData(data?.data);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();

        setToaster({
          show: true,
          type: CONSTANT.TYPE.DANGER,
          content: data.message,
        });
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }
    } catch {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getAssignementReport();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="white-box body-cont">
      <div className="assignment-report">
        <div className="report-header">
          <div className="dash-heading">
            <h1 className="themeLink mb-3 text-center">
              {CONSTANT.LABEL.TEST_REPORT}
            </h1>
            <h1 className="text-center">
              {/* Full Year (Home Assignment SET A) GA 1 (Ancient and Medieval
                History) */}
              {rData?.exam?.name}
            </h1>
          </div>
        </div>
        <div className="dash-body">
          <div className="row reportcard_row justify-content-start border-bottom  pb-4">
            {assignmentReportData?.map((item, index) => (
              <div className="col-md-3 p-4 ps-0">
                <ReportCards
                  image={item.icon}
                  className={item.className}
                  title={item.title}
                  result={item.result}
                  isMinute={item?.isMinute}
                />
              </div>
            ))}
          </div>
          <div className="report-btn mt-5">
            <Link
              to={`/home-assignments/assignment-report/assignment-result/${testId}`}
              state={rData}
              className="btn theme-btn small-btn w-auto mt-0"
            >
              {CONSTANT.LABEL.VIEW_ALL_QUESTIONS}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentReport;
