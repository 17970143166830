import React from "react";

import { Dropdown, DropdownButton } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Auth from "../../../auth/Auth";

export default function SelectInput({
  id,
  label,
  className,
  name,
  value,
  onChange,
  disable,
  firstOption,
  option,
  data,
  onClick,
  isHeader,
  url,
  title,
  setInitialValues,
  values,
  required,
  onClickBtn,
  onClickHandler,
}) {
  const navigate = useNavigate();
  const redirectTo = () => {
    Auth?.logout();
    navigate("/");
  };

  return (
    <div
      className={`form-group position-relative mb-4 ${className && className}`}
    >
      {label && (
        <label className="text-capitalize">
          {label}
          {required && <span className="pink-text"> *</span>}
        </label>
      )}
      <DropdownButton
        className="dropdown-btn"
        title={
          <span>
            {values?.[id]
              ? values?.[id]
              : values?.name
              ? values?.name
              : "Select"}
          </span>
        }
        disabled={disable}
        onClick={onClickBtn}
      >
        {data?.length > 0 &&
          data?.map((elem, index) => {
            return (
              <Dropdown.Item
                key={index}
                className="dropdown_projects"
                title={elem?.name}
              >
                <div
                  {...(setInitialValues && {
                    onClick: () => {
                      setInitialValues({
                        ...values,
                        [name]: elem?.id,
                        [id]: elem?.name,
                      });
                    },
                  })}
                  {...(onClickHandler && {
                    onClick: () => {
                      onClickHandler(elem?.name, elem?.id);
                    },
                  })}
                  className="dropdown_project_item"
                  url={url}
                >
                  <span
                    {...(onClick && {
                      onClick: () => {
                        redirectTo();
                      },
                    })}
                  >
                    {elem?.name}
                  </span>
                </div>
              </Dropdown.Item>
            );
          })}
      </DropdownButton>
    </div>
  );
}
