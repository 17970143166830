import blueTick from "../../../../src/assets/images/blue-tick01.png";
import Image from "../../utils/image/Image";

const QuestionPallete = ({
  remainingTime,
  questionsData,
  handleClasses,
  handleQuestionClick,
  selectedTabId,
  sectionTimeRemaining,
  isEnabledSectionTimeLimit,
  isEnabledQuestionLimit,
  totalAttempts,
  slotTimeRemaining,
  isEnabledSlot,
}) => {
  // Utility function to format seconds into hours:minutes:seconds
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  // Find the current section based on the selected tab ID
  const currentSection = questionsData?.find((item) => {
    return Number(Object.keys(item)[0]) === Number(selectedTabId);
  });

  // Handle the case when data is not available
  if (!currentSection || !currentSection[selectedTabId]) {
    return <div>Loading...</div>;
  }

  const questions = currentSection[selectedTabId]?.question_data || [];

  // Function to count questions by status
  const countByStatus = (status) => {
    const filteredQuestions =
      questions?.filter((elem) => elem?.status == status) || [];
    return filteredQuestions.length || 0;
  };
  return (
    <div className="mock_pallete schedule-sidebar w-25">
      <div className="right-sidebar">
        <div className="pallete_white_box">
          <div className="qa-instructions ps-0 pt-0">
            {/* Display time remaining */}
            {/* <div className="exam-timer">
              Time Remaining: {formatTime(remainingTime)}
            </div> */}
            {/* {isEnabledSlot && (
              <div className="exam-timer">
                Slot Time Remaning: {formatTime(slotTimeRemaining)}
              </div>
            )} */}
            {/* Display section time remaining if section-wise limit is enabled */}
            {isEnabledSectionTimeLimit && (
              <div className="exam-timer">
                Section Time Remaining: {formatTime(sectionTimeRemaining)}
              </div>
            )}

            {/* Display attempts left if question limit is enabled */}
            {isEnabledQuestionLimit && (
              <div className="exam-timer">
                Attempts Left:{" "}
                {totalAttempts - currentSection[selectedTabId]?.total_attempt}
              </div>
            )}
            {/* Display question status summary */}
            <ul className="d-block ps-0">
              <li>
                <span className="grayBox inst-box">{countByStatus(4)}</span>
                Not Visited
              </li>
              <li>
                <span className="pinkBox inst-box">{countByStatus(3)}</span>
                Not Answered
              </li>
              <li>
                <span className="greenBox inst-box">{countByStatus(0)}</span>
                Answered
              </li>
              {/* <li>
                <span className="darkBlueBox inst-box">
                  <span>{countByStatus(1)}</span>
                  <Image src={blueTick} alt="blue-tick" className="checkIcon" />
                </span>
                Answered & Marked for Review (will be considered for evaluation)
              </li>
              <li>
                <span className="yellowBox inst-box p-2">
                  <span>{countByStatus(2)}</span>
                </span>
                Marked for Review (will NOT be considered for evaluation)
              </li> */}
            </ul>
          </div>

          {/* Display question count and status */}
          <div className="question-count mt-4">
            {questions?.map((elem, i) => (
              <span
                key={`key-${i}`}
                className={`${handleClasses(elem.status)} inst-box text-center`}
                onClick={() => handleQuestionClick(i)}
              >
                {i + 1}
                {elem.status === 1 && <Image src={blueTick} alt="blue-tick" />}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionPallete;
