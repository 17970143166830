import React from "react";

import "./spinner.scss";

function spinner({ whiteBackground = false }) {
  return (
    <>
      <div
        className={
          whiteBackground ? "spin-wrapper-white-backgound" : "spin-wrapper"
        }
      >
        <div className="spinner"></div>
      </div>
    </>
  );
}

export default spinner;
