import React, { useContext, useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useBreadcrumb } from "../../shared/breadcrumb-context";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { AppContext } from "../../shared/AppContext";
import { useScreenWidth } from "../../../hooks/useScreenWidth";
import { useDebounce } from "../../../hooks/useDebounce";
import SidebarLogo from "./SidebarLogo";
import MenuSidebar from "./MenuSidebar";
import Logo from "../../../assets/images/prathamLogo.png";
import Auth from "../../../auth/Auth";
import { useStudentMenus } from "../../../hooks/hookList";

const formatMenuData = (menuItems) => {
  const menuMap = new Map();

  menuItems?.forEach((item) => {
    if (!item.parent_id) {
      // Handle parent items
      menuMap.set(item.id, {
        ...item,
        className: item.subMenus ? "nav_link" : "nav_link",
        isSubMenusShow: !!item.subMenus, // Show submenus if they exist
        subMenus: item.subMenus ? [...item.subMenus] : [], // Add existing subMenus
      });
    } else {
      // Handle submenus
      const parentMenu = menuMap.get(item.parent_id);
      if (parentMenu) {
        // Update parent menu with submenus and classNames
        parentMenu.isSubMenusShow = false;
        parentMenu.className =
          parentMenu.name === "forms"
            ? "nav_link formLink drop-icon"
            : "nav_link";

        parentMenu.subMenus.push({
          className: "subLinks",
          title: item.title,
          url: item.url,
        });
      }
    }
  });

  // Return formatted menu items as an array
  return [...menuMap.values()];
};


const SideBar = () => {
  const location = useLocation();
  const {
    isSidebarHide,
    setIsSidebarHide,
    studentInfo,
    organizationDetails,
    userData,
    setSpinner,
    setToaster,
  } = useContext(AppContext);
  const { dispatch } = useBreadcrumb();
  const [activeMenu, setActiveMenu] = useState(0);
  const [isActiveChild, setIsActiveChild] = useState(0);
  const [sideBarItems, setSideBarItems] = useState([]);
  const [showMenu, setShowMenu] = useState(true);
  const sidebarRef = useRef();
  const screenWidth = useScreenWidth();
  const debounceScreenWidth = useDebounce(screenWidth, 300);
  const headerName = Auth?.breadData()?.headerName;
  const isMobile = debounceScreenWidth <= 768;

  const { getData: getStudentMenus } = useStudentMenus(setSpinner, setToaster);

  useOnClickOutside(sidebarRef, () => isMobile && setIsSidebarHide(true));

  const handleBreadCrumb = (data) => {
    if (headerName !== data?.title) {
      if (data?.title !== "Dashboard") {
        dispatch({
          type: "SET_BREADCRUMB",
          crumb: [
            {
              title:
                (data?.title?.toLowerCase() === "forms" && "Form Updates") ||
                data?.title,
              url: data?.url,
            },
          ],
        });
      } else {
        dispatch({ type: "CLEAR_BREADCRUMBS" });
      }
    }
  };

  const handelSubSidebarNavigation = (items, index) => {
    handleBreadCrumb({
      title: items.title,
      url: items.url,
    });
    setIsActiveChild(index);
    isMobile && setIsSidebarHide(!isSidebarHide);
  };

  const studentMenusCallback = (menusData) => {
    const formattedMenu = formatMenuData(menusData.data);
    setSideBarItems(formattedMenu);
  };

  useEffect(() => {
    getStudentMenus({}, studentMenusCallback);
  }, []);

  useEffect(() => {
    setSideBarItems((prev) =>
      prev?.map((elem) => {
        if (location?.pathname?.includes("forms")) {
          elem.isSubMenusShow = true;
        } else {
          elem.isSubMenusShow = false;
        }
        return elem;
      })
    );
  }, [location?.pathname]);

  return (
    <div className={`dash-navbar`} id="nav-bar" ref={sidebarRef}>
      <nav className="nav">
        <span className="nav_logo d-flex justify-content-center">
          {!isSidebarHide && organizationDetails?.full_logo && (
            <SidebarLogo
              to="/dashboard"
              src={organizationDetails?.full_logo}
              alt="dashboard-logo"
              className="sidebar_full_logo"
              onClick={() => dispatch({ type: "CLEAR_BREADCRUMBS" })}
            />
          )}
          {!isSidebarHide && !organizationDetails?.full_logo && (
            <SidebarLogo
              to="/dashboard"
              src={Logo}
              alt="dashboard-logo"
              className="sidebar_full_logo"
              onClick={() => dispatch({ type: "CLEAR_BREADCRUMBS" })}
            />
          )}
          {isSidebarHide && organizationDetails?.short_logo && (
            <SidebarLogo
              to="/dashboard"
              src={organizationDetails?.short_logo}
              alt="dashboard-logo"
              className="sidebar_full_logo"
              onClick={() => dispatch({ type: "CLEAR_BREADCRUMBS" })}
            />
          )}
          {isSidebarHide && !organizationDetails?.short_logo && (
            <SidebarLogo
              to="/dashboard"
              src={Logo}
              alt="dashboard-logo"
              className="sidebar_small_logo"
              onClick={() => dispatch({ type: "CLEAR_BREADCRUMBS" })}
            />
          )}
        </span>

        <div className="left-sidebar">
          {showMenu && (
            <div className="nav_list">
              {sideBarItems?.map((items, index) => {
                return (
                  <div key={index}>
                    <MenuSidebar
                      item={items}
                      index={index + 1}
                      location={location}
                      handleBreadCrumb={handleBreadCrumb}
                      handelSubSidebarNavigation={handelSubSidebarNavigation}
                      isActiveChild={isActiveChild}
                      setActiveMenu={setActiveMenu}
                      activeMenu={activeMenu}
                      setIsSidebarHide={setIsSidebarHide}
                      isMobile={isMobile}
                      isSidebarHide={isSidebarHide}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

export default new React.memo(SideBar);
