import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";

const initialState = {
  breadcrumbs: [],
  source: "custom",
};

const breadCrumbReducer = (state, action) => {
  switch (action.type) {
    case "SET_BREADCRUMB":
      return { breadcrumbs: action.crumb };
    case "ADD_BREADCRUMB":
      return {
        breadcrumbs: [...state.breadcrumbs, action.crumb],
        source: action?.source || state?.source,
      };
    case "REMOVE_BREADCRUMB":
      return {
        breadcrumbs: state.breadcrumbs.slice(0, -1),
        source: action?.source || state?.source,
      };
    case "CLEAR_BREADCRUMBS":
      return {
        breadcrumbs: [],
      };
    case "INITIALIZE_STATE":
      return {
        breadcrumbs: action.breadcrumbs,
      };
    case "REMOVE_SELECT_BREADCRUMB":
      return {
        breadcrumbs: state.breadcrumbs.slice(action.initial, action.second),
      };
    default:
      return state;
  }
};

export const BreadCrumbContext = createContext();
export default function BreadCrumbContextProvider({ children }) {
  const [breadCrumbState, setBreadCrumbstate] = useState([]);
  const [state, dispatch] = useReducer(breadCrumbReducer, initialState);

  useEffect(() => {
    const storedBreadcrumbs = window?.localStorage?.getItem("breadcrumbs");
    if (storedBreadcrumbs) {
      dispatch({
        type: "INITIALIZE_STATE",
        breadcrumbs: JSON?.parse(storedBreadcrumbs),
      });
    }
  }, []);

  useEffect(() => {
    window?.localStorage.setItem(
      "breadcrumbs",
      JSON.stringify(state.breadcrumbs)
    );
  }, [state.breadcrumbs]);
  return (
    <>
      <BreadCrumbContext.Provider
        value={{
          setBreadCrumbstate,
          breadCrumbState,
          state,
          dispatch,
        }}
      >
        {children}
      </BreadCrumbContext.Provider>
    </>
  );
}

export const useBreadcrumb = () => useContext(BreadCrumbContext);
