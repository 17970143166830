import React from "react";
import "./datePicker.scss";
import { useLocation } from "react-router-dom";

const DatePicker = ({ label, id, date, onChange, placeholder }) => {
  const location = useLocation();
  const handleDateChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <div className="">
      <div className="row align-items-center">
        <div className="col-12 col-md-4 mb-2">
          <h3 className="m-0 date_picker_title">{label}</h3>
        </div>
        <div className={"col-12 col-md-8"}>
          <div className="calender">
            <div className="position-relative">
              <input
                className={`w-100 ${
                  location?.pathname.includes("liveClassRecordings") ||
                  location?.pathname.includes("/schedule/homework")
                    ? "date_picker_calendar_live_class"
                    : "date_picker_calendar"
                } `}
                type="date"
                value={date}
                placeholder={placeholder}
                id={id}
                onChange={handleDateChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
