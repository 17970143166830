import React, { useCallback, useContext, useEffect, useState } from "react";
import backBtn from "../../../assets/images/left-arrow.png";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../shared/AppContext";
import moment from "moment";
import Auth from "../../../auth/Auth";
import { userService } from "../../../_services/User.services";
import Threads from "./Threads";
import Image from "../../utils/image/Image";

export default function ChatHistory() {
  const navigate = useNavigate();
  const token = localStorage.getItem("PSIS_token");
  const {
    historyFromDate,
    setHistoryFromDate,
    historyToDate,
    setHistoryToDate,
    setSpinner,
  } = useContext(AppContext);

  const [threads, setThreads] = useState([]);

  const getAllThreads = useCallback(async () => {
    setSpinner(true);
    try {
      const res = await userService?.getThreads({
        type: "CLASS",
        token: `${Auth?.user()?.user_id}|${token}`,
        ...(true
          ? {
              history: true,
              to: moment(historyToDate).format("YYYY-MM-DD"),
              from: moment(historyFromDate).format("YYYY-MM-DD"),
            }
          : ""),
      });
      if (res?.status >= 200 && res?.status <= 299) {
        const data = await res.json();
        setThreads(data?.threads);
        setSpinner(false);
      }
      if (res?.status === 401) {
        localStorage.clear();
        setSpinner(false);
      }
    } catch (error) {
      setSpinner(false);
    }
  }, [historyFromDate, historyToDate, setSpinner, token]);

  useEffect(() => {
    if (token) {
      getAllThreads();
    }
  }, [token, getAllThreads]);

  return (
    <>
      <div className="chat-section chat-history">
        <div className="chat_box chat-tabs pt-0">
          <div className="live-class-header ">
            <div className="chat-history d-flex align-items-center ">
              {" "}
              <span className=" back-btn">
                <Image
                  src={backBtn}
                  alt="back btn"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </span>
              <h2>Chat History</h2>
            </div>
          </div>
          <div className="date-history">
            <div className="date-selectors">
              <div className="from">
                <span className="d-flex">
                  <label htmlFor="start-date" className="time-input">
                    <input
                      type="date"
                      id="start-date"
                      // style={{ backgroundImage: `url(${Calender})` }}
                      onChange={(e) => setHistoryFromDate(e.target.value)}
                    />
                    {moment(historyFromDate).format("DD MMM, YYYY")}
                  </label>
                </span>
              </div>
              <div className="hyp-dash">-</div>
              <div className="to">
                {/* <label htmlFor=""> To</label> */}
                <span className="d-flex">
                  <label htmlFor="start-date" className="time-input end-date">
                    {moment(historyToDate).format("DD MMM, YYYY")}
                    <input
                      type="date"
                      // style={{ backgroundImage: `url(${Calender})` }}
                      onChange={(e) => setHistoryToDate(e.target.value)}
                      max={new Date().toISOString().split("T")[0]}
                    />
                  </label>
                </span>
              </div>
            </div>
            <div className="chat-header-btn">
              <button
                type="button"
                className="chat-btn"
                onClick={getAllThreads}
              >
                Go
              </button>
            </div>
          </div>
          <div className="live-class-body">
            <div className="live-class tab-listing">
              {threads?.length > 0 &&
                threads?.map((item, index) => {
                  return <Threads item={item} isHistory={true} />;
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
