import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ScheduleCard from "../../utils/card/ScheduleCard";

import calenderIcon from "../../../assets/images/calendar.svg";
import nextIcon from "../../../assets/images/nextArrow.svg";
import blueScreen from "../../../assets/images/blue02.svg";
import docIcon from "../../../assets/images/red_doc.svg";
import notes from "../../../assets/images/notes.svg";
import scheduleCalenderIcon from "../../../assets/images/schedule-calendar.png";

import moment from "moment";
import { userService } from "../../../_services/User.services";
import { useContext } from "react";
import { AppContext } from "../../shared/AppContext";
import { useBreadcrumb } from "../../shared/breadcrumb-context";
import Image from "../../utils/image/Image";

const Schedule = () => {
  const {
    setSpinner,
    setToaster,
    calendarDate,
    scheduleDatePicker,
    setScheduleDatePicker,
  } = useContext(AppContext);
  const { dispatch } = useBreadcrumb();
  const [selectedDate, setSelectedDate] = useState(calendarDate);
  const [videoContent, setVideoContent] = useState(calendarDate);
  const [documentContent, setDocumentContent] = useState(calendarDate);
  const [noteContent, setNoteContent] = useState(calendarDate);
  const [classData, setClassData] = useState([]);
  const [testData, setTestData] = useState([]);
  const [totalTestSchedule, setTotalTestSchedule] = useState();
  const [activePage, setActivePage] = useState(1);
  const [linkResources, setLinkedResources] = useState({
    document: [],
    video: [],
    exams: [],
  });
  const dateInputRef = useRef(null);

  const handleIconClick = () => {
    if (dateInputRef.current) {
      // Use showPicker if supported
      if (dateInputRef.current.showPicker) {
        dateInputRef.current.showPicker();
      } else {
        // Fallback for browsers that don't support showPicker
        dateInputRef.current.focus();
        dateInputRef.current.click();
      }
    }
  };

  const setDate = (newDate) => {
    const date = newDate || new Date();
    setSelectedDate(
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  };

  const getPreviousDate = () => {
    const currentDayInMilli = new Date(selectedDate).getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const previousDayInMilli = currentDayInMilli - oneDay;
    const previousDate = new Date(previousDayInMilli);

    if (selectedDate) {
      if (selectedDate === previousDate) {
        setDate(selectedDate);
      } else {
        setDate(previousDate);
        // setCalanderDate(previousDate);
        setScheduleDatePicker(previousDate);
      }
    }

    // setDate(previousDate);
  };

  const getNextDate = () => {
    const currentDayInMilli = new Date(selectedDate).getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const nextDayInMilli = currentDayInMilli + oneDay;
    const nextDate = new Date(nextDayInMilli);
    setDate(nextDate);
    // setCalanderDate(nextDate);
    setScheduleDatePicker(nextDate);
  };

  {
    /* 
    * Commenting Mock and Home Assignment Test Related Functionality
    * 
    const handleTime = (date, duration) => {
    let startDate = new Date(date);
    startDate.setMinutes(startDate.getMinutes() + Number(duration));
    return moment(startDate).format("hh:mm A");
  };
*/
  }

  const whatStatus = (params) => {
    const { start_date, start_time, end_time, live_class_id } = params;
    const currentTime = moment();
    const givenDate = moment(start_date, "YYYY-MM-DD HH:mm:ss");
    const dateOnly = givenDate.format("YYYY-MM-DD");
    const startDateTimeString = `${dateOnly} ${start_time}`;
    const endDateTimeString = `${dateOnly} ${end_time}`;
    const givenDateTime = moment(startDateTimeString, "YYYY-MM-DD HH:mm:ss");

    // Calculate 10 minutes before the scheduled start time
    const joinWindowStart = givenDateTime.clone().subtract(15, "minutes");

    const endTime = moment(endDateTimeString, "YYYY-MM-DD HH:mm:ss");

    if (live_class_id !== null) {
      if (currentTime.isBetween(joinWindowStart, givenDateTime)) {
        return "JOIN NOW";
      } else if (currentTime.isBetween(givenDateTime, endTime)) {
        return "In Progress";
      } else if (currentTime.isBefore(givenDateTime)) {
        return "Scheduled";
      } else {
        return "Completed";
      }
    } else {
      if (currentTime.isBetween(joinWindowStart, givenDateTime)) {
        return "JOIN NOW";
      } else if (currentTime.isBetween(givenDateTime, endTime)) {
        return "In Progress";
      } else if (currentTime.isBefore(givenDateTime)) {
        return;
      } else {
        return "Completed";
      }
    }
  };

  //*****************Status********************** */

  // schedule status is 0
  // expired status is 1
  // attempted and view report status is 2
  // not-attempted and attempt status is 3
  // attempted and retake status is 4
  // attempted status 5
  // resume status 6
  //********************************************* */

  {
    /** 
     * Commenting Mock & Home Assignment Data
     * 
     * 
     * 
  const testTitle = (item) => {
    let status = "";
    if (item?.status === 0) {
      status = CONSTANT.EXAM_STATUS.SCHEDULE;
    }

    if (item?.status === 1 && item?.exam_retakes === 0) {
      status = CONSTANT.EXAM_STATUS.VIEW_REPORT;
    }

    if (item?.status === 1 && item?.exam_retakes > 0) {
      status = CONSTANT.EXAM_STATUS.VIEW_REPORT;
    }

    if (
      (item?.status === 2 || item?.status === 4) &&
      new Date(item?.end_date) < new Date() &&
      !item?.category?.name?.toLowerCase().includes(CONSTANT.HOME)
    ) {
      status = CONSTANT.EXAM_STATUS.VIEW_REPORT;
    }

    if (
      (item?.status === 2 || item?.status === 4) &&
      !item?.category?.name?.toLowerCase().includes(CONSTANT.HOME) &&
      new Date(item?.result_date) > new Date()
    ) {
      status = CONSTANT.EXAM_STATUS.RESULT_AWAITED;
    } else {
      status = CONSTANT.EXAM_STATUS.VIEW_REPORT;
    }

    if (
      new Date(item?.result_date) <= new Date() &&
      item?.exam_retakes < item?.category?.exam_retakes &&
      item?.status === 4
    ) {
      status = CONSTANT.EXAM_STATUS.RETAKE;
    }

    if (item?.status === 6) {
      status = CONSTANT.EXAM_STATUS.RESUME_TEST;
    }

    // if (item?.status === 3 || item?.exam_category_id === 5) {
    if (
      item?.status === 3 ||
      item?.category?.name?.toLowerCase().includes(CONSTANT.HOME)
    ) {
      status = CONSTANT.EXAM_STATUS.TAKE_EXAM;
    }

    if (item?.status === 5) {
      if (new Date(item?.result_date) < new Date()) {
        status = CONSTANT.EXAM_STATUS.VIEW_REPORT;
      } else {
        status = CONSTANT.EXAM_STATUS.RESULT_AWAITED;
      }
    }

    return status;
  };
  */
  }

  const getContentData = async (date) => {
    const response = await userService?.getContentData({
      date: moment(date).format("YYYY-MM-DD"),
    });
    try {
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setVideoContent(data?.data?.videos);
        setDocumentContent(data?.data?.documents);
        setNoteContent(data?.data?.notes);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setToaster({
          show: true,
          type: "danger",
          content: data.message,
        });
      }
      if (response?.status === 401) {
        localStorage.removeItem("_prathamStudentToken");
      }
    } catch {
      setToaster({
        show: true,
        type: "danger",
        content: "Something went wrong",
      });
    }
  };

  const getTodayClassData = async (date) => {
    const classType = (classType) => {
      switch (classType) {
        case 1:
          return "Offline";
        case 2:
          return "Online";
        case 3:
          return "Youtube";
        default:
          return "No Class Name";
      }
    };
    const response = await userService?.getAllSchedule({
      date: moment(date).format("YYYY-MM-DD"),
    });
    try {
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        const scheduleData = data?.data?.data;
        setClassData(
          scheduleData?.map((elem) => {
            const classStatus = whatStatus({
              start_date: elem?.date,
              start_time: elem?.start_time,
              end_time: elem?.end_time,
              duration: elem?.duration,
              class_type: elem?.class_type,
              live_class_id: elem?.live_class_id,
              thread_id: elem?.thread_id,
            });

            return {
              id: elem.live_class_id,
              title: elem?.subject,
              time: `${moment(elem?.start_time, "HH:mm:ss").format(
                "hh:mm A"
              )} - ${moment(elem?.end_time, "HH:mm:ss").format("hh:mm A")}`,
              icon:
                (classStatus === "In Progress" && "images/green-circle.png") ||
                (classStatus === "JOIN NOW" && "images/green-circle.png"),
              status: classStatus,
              btn_title:
                elem?.live_class_id !== null && classStatus === "In Progress"
                  ? "Join Now"
                  : classStatus ||
                    (elem.live_class_id !== null && classStatus === "JOIN NOW")
                  ? "Join Now"
                  : classStatus,
              status_title: classStatus,
              isButton:
                elem?.live_class_id !== null && classStatus === "In Progress"
                  ? true
                  : false ||
                    (elem.live_class_id !== null && classStatus === "JOIN NOW")
                  ? true
                  : false,
              isStatus: true,
              isIcon: false,
              room:
                elem?.class_type === "Offline"
                  ? `${elem?.batch_name}, ${elem?.room}`
                  : classType(elem?.class_type),
              elem: elem,
            };
          })
        );
        setSpinner(false);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        // setSpinner(false);
        setToaster({
          show: true,
          type: "danger",
          content: data.message,
        });
        setSpinner(false);
      }
      if (response?.status === 401) {
        localStorage.removeItem("_prathamStudentToken");
      }
    } catch {
      setSpinner(false);
    }
  };

  //********************Get today schedule(classes and tests)********************//

  {
    /*  
    * Commenting Mock & Home Assignment Schedule API
    *
    * 
    const getTodaySchedule = async (date, pageNo) => {
    setSpinner(true);
    const response = await userService?.getTodaySchedule({
      page: pageNo ? pageNo : 1,
      size: 10,
      date: moment(date).format("YYYY-MM-DD"),
    });
    try {
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();

        // setSpinner(false);
        
        setClassData(
          data?.data?.classes?.data?.map((elem) => ({
            id: elem.id,
            title: elem?.meeting_name,
            meeting_id: elem?.meeting_id,
            time:
              moment(elem?.start_date).format("hh:mm A"),
            // + handleTime(elem?.start_date, elem?.duration),
            icon: "images/green-circle.png",
            status: whatStatus(elem?.status),
            btn_title: "Join Now",
            status_title: whatStatus(elem?.status),
            isButton: whatStatus(elem?.status) === "Online" ? true : false,
            isStatus: true,
            isIcon: whatStatus(elem?.status) === "Online" ? true : false,
            onClick: () => {
              navigate(elem?.join_url);
            },
            join_url: elem?.join_url,
            room: elem?.schdeule?.center[0],
            elem: elem
          }))
        );
      
        
          setTestData(
          data?.data?.online_test?.data
            ?.filter((exam) => {
              const currentDate = new Date();
              const startDate = new Date(exam.start_date);
              const endDate = new Date(exam.end_date);
              return (
                exam.end_date !== null &&
                currentDate >= startDate &&
                currentDate <= endDate
              );
            })
            .map((elem) => ({
              id: elem.id,
              title: elem?.name,
              time: `End Date: ${moment(elem?.end_date).format("DD-MM-yyyy")}`,
              // ${handleTime(elem?.end_date, elem?.duration)}`,
              icon: "images/check-svgrepo-com.svg",
              status:
                elem?.status === 1 || elem?.status === 3 || elem?.status === 0
                  ? "Completed"
                  : "Take Test",
              status_title: elem?.status === 1 ? "Completed" : "Take Test",
              className: "green-text",
              // btn_title: "Take Test",
              btn_title: testTitle(elem),
              isIcon: false,
              isStatus: true,
              isButton: false,
              elem: elem,
            }))
        );
          setTotalTestSchedule(data.data.online_test.total); 
        setSpinner(false);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        // setSpinner(false);
        setToaster({
          show: true,
          type: "danger",
          content: data.message,
        });
        setSpinner(false);
      }
      if (response?.status === 401) {
        localStorage.removeItem("_prathamStudentToken");
      }
    } catch {
      setSpinner(false);
    }
  };

*/
  }

  {
    /** Commenting Mock & Home Assignment Test API 
  const setTest = async (id) => {
    try {
      setSpinner(true);
      const params = {
        exam_id: id,
      };
      const response = await userService.startTest(params);
      if (response.status >= 200 && response.status <= 299) {
        setSpinner(false);
      }
      if (response.status >= 400 && response.status <= 499) {
        setSpinner(false);
        setToaster({
          show: true,
          type: "danger",
          content: "Something went wrong, please try again",
        });
      }
    } catch (error) {
      setSpinner(false);
      setToaster({
        show: true,
        type: "danger",
        content: "Something went wrong, please try again",
      });
    }
  };
*/
  }
  {
    /** Commenting Mock & Home Assignment Tests Functionality
  const toHoursAndMinutes = (totalSeconds) => {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours}:${minutes}:${seconds}`;
  };

   */
  }

  {
    /**  Commenting Mock & Home Assignment Test Local Storage Data
  const localStorageData = (item) => {
    let examId = localStorage.getItem(CONSTANT.LOCAL_STORAGE.EXAM_ID);
    let a = Number(item?.duration) * 60;

    if (!examId || examId !== item?.id) {
      localStorage.setItem("examId", item.id);

      if (item?.id !== Number(examId)) {
        Auth?.setExamData([]);
        localStorage.setItem(
          CONSTANT.LOCAL_STORAGE.TEMPLATE_STRUCTURE_DATA,
          []
        );
        localStorage.removeItem("selectedTab");
        localStorage.setItem("examTemplateData", []);
      }

      if (item?.status === 6) {
        const timeSpentExam = item?.time_spent;
        if (timeSpentExam !== null && timeSpentExam !== undefined) {
          a = a - Number(timeSpentExam);
        }
      }
    }

    localStorage.setItem(
      CONSTANT.LOCAL_STORAGE.UI_TEMPLATE,
      item?.category?.template?.id
    );
    localStorage.setItem(
      CONSTANT.LOCAL_STORAGE.EXAM_CATEGORY_ID,
      item?.category?.template_id
    );
    localStorage.setItem("totalExamTime", Number(item?.duration) * 60);
    localStorage.setItem("totalTimeSpent", Number(item?.time_spent));
    localStorage.setItem(CONSTANT.LOCAL_STORAGE.DURATION, toHoursAndMinutes(a));
    localStorage.setItem(CONSTANT.LOCAL_STORAGE.REAL_DURATION, item?.duration);
    localStorage.setItem(CONSTANT.LOCAL_STORAGE.RESULT_DATE, item?.result_date);
    localStorage.setItem(
      CONSTANT.LOCAL_STORAGE.TEMP_ID,
      item?.exam_template_id
    );
    localStorage.setItem(CONSTANT.LOCAL_STORAGE.STATUS, item?.status);
    localStorage.setItem(
      CONSTANT.LOCAL_STORAGE.HOME_ASSIGNMENT_EXAM_NAME,
      item?.name
    );

    if (item?.time_spent) {
      localStorage.setItem(
        CONSTANT.LOCAL_STORAGE.RESUME_TIME,
        item?.time_spent
      );
    }
  };
*/
  }
  {
    /* Commenting Mock & Home Assignment Test Navigation
  
  const navigation = (data) => {
    navigate(`/test/examinstructions/${data?.id}`, {
      state: { back_url: location?.pathname + location?.search },
    });
  };
 */
  }
  {
    /** 
     * 
     * Commenting Mock & Home Assignment Test Handler 
  const handleExam = (data) => {
    // /home-assignments/assignment-details/assignmentQuiz/26
    if (data?.status === 1 && data?.exam_retakes === 0) {
      navigate(`/test/${data?.id}/exams/resultnew`);
    }
    if (data?.status === 1 && data?.exam_retakes > 0) {
      navigate(`/test/${data?.id}/exams/resultnew`);
    }
    if (data?.status === 2) {
      navigate(`/test/${data?.id}/exams/resultnew`);
    }
    if (data?.status === 5) {
      if (new Date(data?.result_date) < new Date()) {
        // navigation(data);
        // handleBreadCrumb({ title: data?.name, url: "/test" });
        // localStorageData(data);
        // return "Resume Test"
        navigate(`/test/${data?.id}/exams/resultnew`);
      }
    }
    if (data?.status === 3) {
      // if (data?.exam_category_id === 5) {
      if (data?.category?.name?.toLowerCase().includes(CONSTANT.HOME)) {
        setTest(data.id);
        localStorageData(data);
        navigate(
          `/home-assignments/assignment-details/newAssignmentQuiz/${data?.id}`
        );
      } else {
        navigation(data);
      }
      localStorageData(data);
    }
    if (data?.status === 4) {
      // if (data?.exam_category_id === 5) {
      if (data?.category?.name?.toLowerCase().includes(CONSTANT.HOME)) {
        setTest(data.id);

        localStorageData(data);
        navigate(
          `/home-assignments/assignment-details/newAssignmentQuiz/${data?.id}`
        );
      }
      // if (data?.exam_category_id !== 5) {
      // if (!data?.category?.name?.toLowerCase().includes('home')) {
      //   navigate(`/home-assignments/assignment-report/${data?.id}`)
      // }
      else {
        navigate(`/test/${data?.id}/exams/resultnew`);
        // navigation(data);
      }
      localStorageData(data);
    }
    if (data?.status === 6) {
      // if (data?.exam_category_id === 5) {
      if (data?.category?.name?.toLowerCase().includes(CONSTANT.HOME)) {
        setTest(data.id);
        localStorageData(data);
        navigate(
          `/home-assignments/assignment-details/newAssignmentQuiz/${data?.id}`
        );
      } else {
        localStorageData(data);
        navigation(data);
      }
      localStorageData(data);
    }
    if (data?.status === 0) {
      setToaster({
        show: true,
        type: CONSTANT.TYPE.SUCCESS,
        content: CONSTANT.CONTENT.EXAM_SCHEDULED,
      });
    }
  };

  */
  }

  {
    /** 
     * Commenting Mock & Home Assignment Test Pagination Handler
     * 
  const onPageChangeTest = (e) => {
    let pageNo = e;
    setActivePage(pageNo);
    getTodaySchedule(
      scheduleDatePicker ? scheduleDatePicker : calendarDate,
      pageNo
    );
  };
  */
  }

  useEffect(() => {
    dispatch({
      type: "SET_BREADCRUMB",
      crumb: [{ title: "Schedule", url: "/schedule" }],
    });
  }, []);

  useEffect(() => {
    Promise.all([
      // getTodaySchedule(scheduleDatePicker ? scheduleDatePicker : calendarDate),
      getTodayClassData(scheduleDatePicker ? scheduleDatePicker : calendarDate),
      getContentData(scheduleDatePicker ? scheduleDatePicker : calendarDate),
    ]).then((data) => {
      setSpinner(false);
    });
  }, [scheduleDatePicker ? scheduleDatePicker : calendarDate]);

  console.log("anurag", classData);

  return (
    <div className="white-box schedule-dash" style={{ margin: "0 0.6rem" }}>
      <div className="d-flex flex-wrap-reverse flex-lg-nowrap justify-content-between">
        <div className="schedule-header col-md-6">
          <div className="schedule_calendar d-flex align-items-center">
            <div
              className="calender-icon cursor-pointer"
              onClick={handleIconClick}
            >
              <input
                type="date"
                id="date-pick"
                ref={dateInputRef}
                className="cursor-pointer"
                placeholder={moment(new Date()).format("DD MM YYYY")}
                onChange={(e) => {
                  setSelectedDate(e.target.value);
                  // setCalanderDate(e.target.value);
                  setScheduleDatePicker(e.target.value);
                }}
              />
              <Image
                src={calenderIcon}
                className="date-icon cursor-pointer"
                alt="calendar-icon"
              />
            </div>
            <h1>
              {moment(
                scheduleDatePicker ? scheduleDatePicker : selectedDate
              ).format("DD MMM, YYYY")}
            </h1>
          </div>
          {/* <h1>25th April, 2021</h1> */}

          <div className="next-prev">
            <span className="prev-icon prev-date">
              <Image
                src={nextIcon}
                className="pointer"
                // {...(new Date(selectedDate) >= new Date() && { onClick: () => { getPreviousDate(); } })}
                onClick={() => {
                  getPreviousDate();
                }}
                alt="next-icon"
              />
            </span>
            <span className="prev-icon ps-3 next-date">
              <Image
                src={nextIcon}
                className="pointer"
                onClick={() => {
                  getNextDate();
                }}
                alt="next-icon"
              />
            </span>
          </div>
        </div>
        <div className="d-flex">
          <div style={{ marginRight: "1rem" }}>
            <Link to="/schedule/homework" className="themeLink">
              <button className="btn theme-btn small-btn green-btn">
                Homework
              </button>
            </Link>
          </div>
          <div style={{ marginRight: "1rem" }}>
            <Link to="/schedule/liveClassRecordings" className="themeLink">
              <button className="btn theme-btn small-btn live_class_schedule_btn">
                Live Class Recordings
              </button>
            </Link>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <Link to="allclasses" className="themeLink">
              <button className="btn theme-btn small-btn yellow-btn">
                Weekly Schedule
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="class-schedule border-bottom-dashed mb-5 pb-5">
        <div className="sub_dash-heading">
          <h1>Classes</h1>
        </div>
        {classData.length > 0 ? (
          <div className="row">
            <ScheduleCard
              className="col-sm-12 col-md-6 col-xxl-4 mb-4"
              scheduleData={classData}
              // scheduleData={data?.schedule_page}
              type="class"
            />
          </div>
        ) : (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <Image
              src={scheduleCalenderIcon}
              width={90}
              height={90}
              alt="schedule-calendar-icon"
            />
            <h2 className="mt-3 w-100 text-center opacity-75 no_class">
              No class scheduled for the day
            </h2>
          </div>
        )}
        <div className="gray-text mt-4 other_schedules">
          * If you wish to view the schedule of other batches, please
          <Link to="/schedule/otherClasses" className="themeLink">
            &nbsp; click here
          </Link>
        </div>
      </div>
      {videoContent.length > 0 && (
        <div className="folder-detail-sec">
          <div className="folder-detail-sec-heading">
            <h1>Videos</h1>
          </div>
          <div class="row">
            {videoContent?.map((data, index) => {
              return (
                <div class="col-lg-3 mb-3 col-md-6" key={index}>
                  <Link
                    to={`/courses/video/${data.id}`}
                    state={{
                      item: data,
                      resourse: "video",
                      url: "/schedule",
                    }}
                  >
                    <div
                      className="folder-details borderRadius dark-blueBorder pointer"
                      title={data?.title}
                    >
                      <div className="folder-detail-sec-icons">
                        <Image src={blueScreen} alt="live-class-icon" />
                      </div>
                      <div className="folder-detail-sec-name">
                        <h4>
                          <span>{data?.title}</span>
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {documentContent?.length > 0 && (
        <div className="folder-detail-sec">
          <div className="folder-detail-sec-heading">
            <h1>Documents</h1>
          </div>
          <div class="row">
            {documentContent?.map((data, index) => {
              return (
                <div class="col-lg-3 mb-3 col-md-6" key={index}>
                  <Link
                    to={`/courses/document/${data.id}`}
                    state={{
                      item: data,
                      resourse: "Document",
                      url: "/schedule",
                      resourceData: [
                        {
                          id: 1,
                          name: "Document",
                        },
                      ],
                    }}
                  >
                    <div
                      className="folder-details borderRadius redBorder pointer"
                      title={data?.title}
                    >
                      <div className="folder-detail-sec-icons">
                        <Image src={docIcon} alt="document-icon" />
                      </div>
                      <div className="folder-detail-sec-name">
                        <h4>
                          <span>{data?.title}</span>
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {/* {noteContent?.length > 0 && (
        <div className="folder-detail-sec">
          <div className="folder-detail-sec-heading">
            <h1>{CONSTANT.LABEL.NOTES}</h1>
          </div>
          {noteContent?.map((data) => {
            return (
              <div className="col-lg-3 mb-3 col-md-6">
                <Link
                  to={`/courses/notes/?${"isFolder=false"}&${"isResourse=false"}&courseId=${
                    data?.id
                  }&key="Notes"`}
                  state={{
                    item: data,
                    resourse: CONSTANT.LABEL.LIVE_CLASS,
                    url: CONSTANT.PATH.SCHEDULE,
                  }}
                >
                  <div
                    className="folder-details borderRadius blueBorder pointer"
                    title={data?.title}
                  >
                    <div className="folder-detail-sec-icons">
                      <Image src={notes} alt={CONSTANT.ALT.NOTE_ICON} />
                    </div>
                    <div className="folder-detail-sec-name">
                      <h4>
                        <span>{data?.title}</span>
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      )} */}
      {/* {testData?.length > 0 && (
        <div className="test-schedule">
          <div className="sub_dash-heading">
            <h1>{CONSTANT.LABEL.ONLINE_TESTS}</h1>
          </div>
          <div className="row">
            <ScheduleCard
              className="col-md-6 col-xxl-4"
              // scheduleData={data?.test_schedule_data}
              scheduleData={testData}
              handleExam={handleExam}
              type={CONSTANT.TYPE.TEST}
            />
          </div>
          <div>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={totalTestSchedule}
              pageRangeDisplayed={5}
              hideFirstLastPages={true}
              onChange={(e) => onPageChangeTest(e)}
            />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Schedule;
