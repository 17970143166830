import React from "react";
import Logo from "../../assets/images/prathamLogo2x.png";
import Image from "../utils/image/Image";
function OnboardingLayout({ Form, image }) {
  return (
    <div className="stud_onboarding">
      <div className="container container-box">
        <div className="row align-items-center stud_onboarding_row ">
          <div className="col-lg-5 onboardingLeft">
            <div className="studLogo text-center ">
              <Image
                src={Logo}
                alt="Onboarding Logo"
                className="img-fluid"
                width="250"
              />
            </div>
            <div className="adminForm white-box ">{Form}</div>
          </div>
          <div className="col-lg-6 offset-lg-1 onboardingRight position-relative">
            <Image src={image} className="img-fluid" alt="Onboarding Images" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default OnboardingLayout;
