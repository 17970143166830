import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";

// import { PDFExport } from "@progress/kendo-react-pdf";
import html2pdf from "html2pdf.js";
// import '../../../assets/scss/dashboard.scss'
import { data } from "../../utils/data/Data";
import { AppContext } from "../../shared/AppContext";
import { useForm } from "@kaydhiman/react-hook-useform";
import { userService } from "../../../_services/User.services";

import Table from "../../utils/table/Table";
import Button from "../../utils/forms/Button";
import CommonTab from "../../utils/tab/CommonTab";
import ReportCards from "../../utils/card/ReportCards";
import ProgressBar from "../../utils/progressReport/ProgressBar";

import arrowImg from "../../../assets/images/white-arrow-down.svg";
import cross from "../../../assets/images/cancel.svg";
import tick from "../../../assets/images/blue-tick01.svg";
import DYTQuestionsHtml from "../../utils/questionAwn/DYTQuestionsHtml";
import { CONSTANT } from "../../utils/constants";
import ColorCard from "../../utils/card/ColorCard";
import Image from "../../utils/image/Image";

const examRef = React?.createRef(null);
const tabData = [
  { id: 1, title: "Overview" },
  { id: 3, title: "Topic Wise" },
  { id: 4, title: "Level Wise" },
  { id: 6, title: "Question Wise Analysis" },
];

const TestDesignReport = () => {
  const { dyt_id } = useParams();
  const {
    setToaster,
    setSpinner,
    sidebarShow,
    setSidebarShow,
    userData,
  } = useContext(AppContext);
  const { values, setInitialValues } = useForm({ validations: {} });

  const [keys, setKeys] = useState("Overview");
  const [questionnumber, setQuestionNumber] = useState(0);
  const [reviewdData, setReviewData] = useState({});
  const [topicWiseData, setTopicWiseData] = useState([]);
  const [userTableHead, setUserTableHEad] = useState([]);
  // const [selectedTemplate, setSelectedTemplate] = useState("");
  const [examTemplateData, setExamTemplateData] = useState([]);
  const [leaderBoardHeading, setLeaderBoardHeading] = useState([]);
  const [questionAnalysisData, setQuestionAnalysisData] = useState([]);
  const [centerName, setCenterName] = useState("");
  // const center_name = localStorage.getItem("center_name");

  //****************** Function for jump to the particular question after click to question number ***************** */
  const gotoServices = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView();
      setInitialValues((prev) => ({ ...prev, divId: id }));
    }
  };
  const [tempVar, setTempVar] = useState(1);
  useEffect(() => {
    setTempVar(0);
    if (values?.divId && keys === "Question Wise Analysis") {
      const element = document.getElementById(values?.divId);
      element.scrollIntoView();
      setTimeout(() => {
        setTempVar(1);
      }, 0);
    }
  }, [values?.divId]);

  //**************** Function to get Overview report api data ****************//
  const getReportOverViewData = async () => {
    setSpinner(true);
    // ;
    const response = await userService?.getDYTOverviewReport({
      student_dyt_id: dyt_id,
    });
    // ;
    try {
      if (response.status >= 200 && response.status <= 299) {
        setSpinner(false);
        const data = await response.json();

        setInitialValues((prev) => ({
          ...prev,
          student_test_id: data?.score?.id,
        }));
        data?.section_wise?.map((elem, index) => {
          return {
            id: index + 1,
            className: elem?.color,
            color_name: elem?.template_structures[0]?.subject?.name,
            color_data: timeSpentInPercent(
              elem?.time_spent,
              data?.score?.exam?.duration
            ).toFixed(2),
            percent: true,
            time_spent: elem?.time_spent,
          };
        });

        // let tempData = data?.section_wise?.map((itm) => {
        //   return {
        //     id: itm.template_structures[0]?.subject?.id,
        //     title: itm?.template_structures[0]?.subject?.name,
        //   };
        // });
        // setSelectedTemplate(tempData[0]?.title);
        // setExamTemplateData(tempData);

        setReviewData((prev) => ({
          ...prev,
          boardData: [
            {
              id: "1",
              icon: "images/icon16.svg",
              className: "card-box redBG",
              title: "Score",
              result: data?.data?.score + "/" + data?.data?.total_marks,
            },
            {
              id: "2",
              icon: "images/icon23.svg",
              className: "card-box darkBlue",
              title: "Percentage",
              result: data?.percentage,
            },
            {
              id: "3",
              icon: "images/icon23.svg",
              className: "card-box yellowBG",
              title: "Subject",
              result: data?.data?.subject?.name,
            },
            {
              id: "4",
              icon: "images/icon19.svg",
              className: "card-box pinkBG",
              title: "Chapter",
              result: data?.data?.topic?.name,
            },
          ],

          centerData: [
            {
              id: "1",
              className: "card-box ",
              title: "Test Name",
              result: data?.data?.name,
            },
            {
              id: "2",
              className: "cards-box ",
              title: "Test Duration",
              result: data?.data?.dyt_settings?.dyt_time + " Minutes",
            },
            {
              id: "3",
              className: "cards-box ",
              title: "Center Name",
              result: centerName,
            },
          ],
          doughnutData: [
            {
              id: "1",
              className: "cards-box gray-card p-0",
              doughData: [
                data?.data?.correct_ans,
                data?.data?.incorrect_ans,
                data?.skipped_question,
              ],
              data: [
                {
                  class: "Total Questions:",
                  class_data: data?.data?.total_questions,
                  id: 1,
                },
                {
                  class: "Accuracy (%):",
                  class_data: data?.accuracy.toFixed(2),
                  id: 2,
                },
                {
                  class: "Attempted:",
                  class_data: data?.data?.total_attempt,
                  id: 3,
                },
                {
                  class: "Correct:",
                  class_data: data?.data?.correct_ans,
                  id: 4,
                },
              ],
            },
          ],

          totalMarksScored: {
            user: data?.score?.score,
            topper: data?.highest_score,
            average: data?.average_score,
          },
        }));
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setSpinner(false);

        setToaster({ show: true, type: "danger", content: data.message });
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }
      if (response.status === 500) {
        const data = await response.json();
        setSpinner(false);

        setToaster({ show: true, type: "danger", content: data.message });
      }
    } catch {
      setSpinner(false);
    }
  };

  //**************** Function to get Question Wise report api data ****************//
  const getDYTQuestionWiseReport = async (filter) => {
    // ;
    const response = await userService.getDYTQuestionWiseReport({
      student_dyt_id: dyt_id,
      ...(filter ? { filter: filter } : ""),
    });
    try {
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();

        let abc = data?.data?.map((itm) => {
          itm?.answers?.map((a) => {
            itm?.selected_answers?.map((elem) => {
              if (elem?.options?.id === a.id) {
                a.selectedOption = elem?.options?.id;
                return a;
              }
              return a;
            });
          });
          return itm;
        });
        //
        setQuestionAnalysisData(abc);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();

        setToaster({ show: true, type: "danger", content: data.message });
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }
    } catch {
      setSpinner(false);
    }
  };

  //**************** Function to get Topic Wise report api data ****************//

  const getDYTTopicWiseReportData = async (section) => {
    setSpinner(true);
    const response = await userService.getDYTTopicWiseReport({
      student_dyt_id: dyt_id,
    });
    try {
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setSpinner(false);

        setTopicWiseData(
          data?.data?.map((elem) => {
            return [
              { value: elem?.topic?.name, id: 1 },
              { value: elem?.total_questions, id: 2 },
              { value: elem?.total_attempt, id: 3 },
              { value: elem?.correct_ans, id: 4 },
              {
                value: (
                  (elem?.correct_ans / elem?.total_attempt) *
                  100
                ).toFixed(2),
                id: 5,
              },
              { value: elem?.time_spent, id: 6 },
            ];
          })
        );
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setSpinner(false);
        setToaster({ show: true, type: "danger", content: data?.message });
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }
    } catch {
      setSpinner(false);
    }
  };

  //**************** Function to get Level Wise report api data ****************//
  const getLevelWiseReportData = async (section) => {
    const response = await userService?.getDYTLevelWiseReport({
      student_dyt_id: dyt_id,
      // student_dyt_id: section?.id,
    });
    try {
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();

        //
        // setReviewData((prev) => ({
        //   ...prev,
        //   levelWiseChart: [
        //     {
        //       easy: data?.easy_question?.length,
        //       medium: data?.medium_question?.length,
        //       hard: data?.hard_question?.length,
        //     },
        //     {
        //       correct_answers_4_easy: data?.easy_question?.filter((itm) => itm?.correct_ans_count > 0)?.length,
        //       correct_answers_4_medium: data?.medium_question?.filter((itm) => itm?.correct_ans_count > 0)?.length,
        //       correct_answers_4_hard: data?.hard_question?.filter((itm) => itm?.correct_ans_count > 0)?.length,
        //     },
        //     {
        //       incorrect_answers_4_easy: data?.easy_question?.filter((itm) => itm?.incorrect_ans_count === 0)?.length,
        //       incorrect_answers_4_medium: data?.medium_question?.filter((itm) => itm?.incorrect_ans_count === 0)?.length,
        //       incorrect_answers_4_hard: data?.hard_question?.filter((itm) => itm?.incorrect_ans_count === 0)?.length,
        //     },
        //     {
        //       not_attempted_4_easy: data?.easy_question?.filter((itm) => itm?.skiped_count === 1)?.length,
        //       not_attempted_4_medium: data?.medium_question?.filter((itm) => itm?.skiped_count === 1)?.length,
        //       not_attempted_4_hard: data?.hard_question?.filter((itm) => itm?.skiped_count === 1)?.length,
        //     },
        //   ],
        //   levelWiseChartData: {
        //     datasets: [
        //       {
        //         label: "Total Questions",
        //         data: [
        //           {
        //             x: "Easy",
        //             y: data?.easy_question?.length,
        //           },
        //           {
        //             x: "Medium",
        //             y: data?.medium_question?.length,
        //           },
        //           {
        //             x: "Difficult",
        //             y: data?.hard_question?.length,
        //           },
        //         ],
        //         backgroundColor: ["rgb(109, 113, 248)"],
        //         borderRadius: 5,
        //       },
        //       {
        //         label: "Correct",
        //         data: [
        //           {
        //             x: "Easy",
        //             y: data?.easy_question?.filter((itm) => itm?.correct_ans_count > 0)?.length,
        //           },
        //           {
        //             x: "Medium",
        //             y: data?.medium_question?.filter((itm) => itm?.correct_ans_count > 0)?.length,
        //           },
        //           {
        //             x: "Difficult",
        //             y: data?.hard_question?.filter((itm) => itm?.correct_ans_count > 0)?.length,
        //           },
        //         ],
        //         borderRadius: 5,
        //         backgroundColor: ["rgb(17, 206, 140)"],
        //       },
        //       {
        //         label: "Incorrect",
        //         data: [
        //           {
        //             x: "Easy",
        //             y: data?.easy_question?.filter((itm) => itm?.incorrect_ans_count === 0)?.length,
        //           },
        //           {
        //             x: "Medium",
        //             y: data?.medium_question?.filter((itm) => itm?.incorrect_ans_count === 0)?.length,
        //           },
        //           {
        //             x: "Difficult",
        //             y: data?.hard_question?.filter((itm) => itm?.incorrect_ans_count === 0)?.length,
        //           },
        //         ],
        //         borderRadius: 5,
        //         backgroundColor: ["rgb(253, 90, 88)"],
        //       },
        //       {
        //         label: "Not Attempted",
        //         data: [
        //           {
        //             x: "Easy",
        //             y: data?.easy_question?.filter((itm) => itm?.skiped_count === 1)?.length,
        //           },
        //           {
        //             x: "Medium",
        //             y: data?.medium_question?.filter((itm) => itm?.skiped_count === 1)?.length,
        //           },
        //           {
        //             x: "Difficult",
        //             y: data?.hard_question?.filter((itm) => itm?.skiped_count === 1)?.length,
        //           },
        //         ],
        //         borderRadius: 5,
        //         backgroundColor: ["rgb(251, 201, 90)"],
        //       },
        //     ],
        //   },
        //   levelWiseTableData: [
        //     [
        //       { value: "Easy", id: 1 },
        //       {
        //         value: data?.easy_question?.length,
        //         id: 2,
        //       },
        //       {
        //         value: data?.easy_question?.filter((itm) => itm?.correct_ans_count > 0)?.length,
        //         id: 3,
        //       },
        //       {
        //         value: data?.easy_question?.filter((itm) => itm?.incorrect_ans_count === 1)?.length,
        //         id: 4,
        //       },
        //       {
        //         value: data?.easy_question?.filter((itm) => itm?.skiped_count === 1)?.length,
        //         id: 4,
        //       },
        //       {
        //         value: data?.easy_question?.filter((itm) => itm?.correct_ans_count > 0)?.length / (data?.easy_question?.length - data?.easy_question?.filter((itm) => itm?.skiped_count === 1)?.length),
        //         id: 4,
        //       },
        //     ],
        //     [
        //       { value: "Medium", id: 1 },
        //       {
        //         value: data?.medium_question?.length,
        //         id: 2,
        //       },
        //       {
        //         value: data?.medium_question?.filter(
        //           (itm) => itm?.correct_ans_count > 0
        //         )?.length,
        //         id: 3,
        //       },
        //       {
        //         value: data?.medium_question?.filter(
        //           (itm) => itm?.incorrect_ans_count === 1
        //         )?.length,
        //         id: 4,
        //       },
        //       {
        //         value: data?.medium_question?.filter(
        //           (itm) => itm?.skiped_count === 1
        //         )?.length,
        //         id: 4,
        //       },
        //       {
        //         value:
        //           data?.medium_question?.filter(
        //             (itm) => itm?.correct_ans_count > 0
        //           )?.length /
        //           (data?.medium_question?.length -
        //             data?.medium_question?.filter(
        //               (itm) => itm?.skiped_count === 1
        //             )?.length),
        //         id: 4,
        //       },
        //     ],
        //     [
        //       { value: "Hard", id: 1 },
        //       {
        //         value: data?.hard_question?.length,
        //         id: 2,
        //       },
        //       {
        //         value: data?.hard_question?.filter(
        //           (itm) => itm?.correct_ans_count > 0
        //         )?.length,
        //         id: 3,
        //       },
        //       {
        //         value: data?.hard_question?.filter(
        //           (itm) => itm?.incorrect_ans_count === 1
        //         )?.length,
        //         id: 4,
        //       },
        //       {
        //         value: data?.hard_question?.filter(
        //           (itm) => itm?.skiped_count === 1
        //         )?.length,
        //         id: 4,
        //       },
        //       {
        //         value:
        //           data?.hard_question?.filter(
        //             (itm) => itm?.correct_ans_count > 0
        //           )?.length /
        //           (data?.hard_question?.length -
        //             data?.hard_question?.filter(
        //               (itm) => itm?.skiped_count === 1
        //             )?.length),
        //         id: 4,
        //       },
        //     ],
        //   ],
        // }));

        setReviewData((prev) => ({
          ...prev,
          levelWiseChart: [
            {
              easy: data?.easy_question?.easy_total,
              medium: data?.medium_question?.medium_total,
              hard: data?.hard_question?.hard_total,
            },
            {
              correct_answers_4_easy: data?.easy_question?.easy_correct_count,
              correct_answers_4_medium:
                data?.medium_question?.medium_correct_count,
              correct_answers_4_hard: data?.hard_question?.hard_correct_count,
            },
            {
              incorrect_answers_4_easy:
                data?.easy_question?.easy_incorrect_count,
              incorrect_answers_4_medium:
                data?.medium_question?.medium_incorrect_count,
              incorrect_answers_4_hard:
                data?.hard_question?.hard_incorrect_count,
            },
            {
              not_attempted_4_easy: data?.easy_question?.easy_skiped_count,
              not_attempted_4_medium:
                data?.medium_question?.medium_skiped_count,
              not_attempted_4_hard: data?.hard_question?.hard_skiped_count,
            },
          ],
          levelWiseChartData: {
            datasets: [
              {
                label: "Total Questions",
                data: [
                  {
                    x: "Easy",
                    y: data?.easy_question?.easy_total,
                  },
                  {
                    x: "Medium",
                    y: data?.medium_question?.medium_total,
                  },
                  {
                    x: "Difficult",
                    y: data?.hard_question?.hard_total,
                  },
                ],
                backgroundColor: ["rgb(109, 113, 248)"],
                borderRadius: 5,
              },
              {
                label: "Correct",
                data: [
                  {
                    x: "Easy",
                    y: data?.easy_question?.easy_correct_count,
                  },
                  {
                    x: "Medium",
                    y: data?.medium_question?.medium_correct_count,
                  },
                  {
                    x: "Difficult",
                    y: data?.hard_question?.hard_correct_count,
                  },
                ],
                borderRadius: 5,
                backgroundColor: ["rgb(17, 206, 140)"],
              },
              {
                label: "Incorrect",
                data: [
                  {
                    x: "Easy",
                    y: data?.easy_question?.easy_incorrect_count,
                  },
                  {
                    x: "Medium",
                    y: data?.medium_question?.medium_incorrect_count,
                  },
                  {
                    x: "Difficult",
                    y: data?.hard_question?.hard_incorrect_count,
                  },
                ],
                borderRadius: 5,
                backgroundColor: ["rgb(253, 90, 88)"],
              },
              {
                label: "Not Attempted",
                data: [
                  {
                    x: "Easy",
                    y: data?.easy_question?.easy_skiped_count,
                  },
                  {
                    x: "Medium",
                    y: data?.medium_question?.medium_skiped_count,
                  },
                  {
                    x: "Difficult",
                    y: data?.hard_question?.hard_skiped_count,
                  },
                ],
                borderRadius: 5,
                backgroundColor: ["rgb(251, 201, 90)"],
              },
            ],
          },

          levelWiseTableData: [
            [
              { value: "Easy", id: 1 },
              {
                value: data?.easy_question?.easy_total,
                id: 2,
              },
              {
                value: data?.easy_question?.easy_correct_count,
                id: 3,
              },
              {
                value: data?.easy_question?.easy_incorrect_count,
                id: 4,
              },
              {
                value: data?.easy_question?.easy_skiped_count,
                id: 4,
              },
              // {
              //   value: data?.easy_question?.easy_correct_count /
              //     (data?.easy_question?.easy_total - data?.easy_question?.easy_skiped_count) ? data?.easy_question?.easy_correct_count /
              //   (data?.easy_question?.easy_total - data?.easy_question?.easy_skiped_count) : 0,
              //   id: 4,
              // },

              {
                value:
                  data?.easy_question?.easy_correct_count /
                  (data?.easy_question?.easy_correct_count +
                    data?.easy_question?.easy_incorrect_count)
                    ? (
                        (data?.easy_question?.easy_correct_count /
                          (data?.easy_question?.easy_correct_count +
                            data?.easy_question?.easy_incorrect_count)) *
                        100
                      ).toFixed(1)
                    : 0,
                id: 4,
              },
            ],
            [
              { value: "Medium", id: 1 },
              {
                value: data?.medium_question?.medium_total,
                id: 2,
              },
              {
                value: data?.medium_question?.medium_correct_count,
                id: 3,
              },
              {
                value: data?.medium_question?.medium_incorrect_count,
                id: 4,
              },
              {
                value: data?.medium_question?.medium_skiped_count,
                id: 4,
              },
              {
                // value:
                //   data?.medium_question?.medium_correct_count /
                //     (data?.medium_question?.medium_total -
                //       data?.medium_question?.medium_skiped_count) ? data?.medium_question?.medium_correct_count /
                //   (data?.medium_question?.medium_total -
                //     data?.medium_question?.medium_skiped_count) : 0,
                value:
                  data?.medium_question?.medium_correct_count /
                  (data?.medium_question?.medium_correct_count +
                    data?.medium_question?.medium_incorrect_count)
                    ? (
                        (data?.medium_question?.medium_correct_count /
                          (data?.medium_question?.medium_correct_count +
                            data?.medium_question?.medium_incorrect_count)) *
                        100
                      ).toFixed(1)
                    : 0,
                id: 4,
              },
            ],
            [
              { value: "Hard", id: 1 },
              {
                value: data?.hard_question?.hard_total,
                id: 2,
              },
              {
                value: data?.hard_question?.hard_correct_count,
                id: 3,
              },
              {
                value: data?.hard_question?.hard_incorrect_count,
                id: 4,
              },
              {
                value: data?.hard_question?.hard_skiped_count,
                id: 4,
              },
              {
                // value:
                //   data?.hard_question?.hard_correct_count /
                //     (data?.hard_question?.hard_total -
                //       data?.hard_question?.hard_skiped_count) ? data?.hard_question?.hard_correct_count /
                //   (data?.hard_question?.hard_total -
                //     data?.hard_question?.hard_skiped_count) : 0,
                value:
                  data?.hard_question?.hard_correct_count /
                  (data?.hard_question?.hard_correct_count +
                    data?.hard_question?.hard_incorrect_count)
                    ? (
                        (data?.hard_question?.hard_correct_count /
                          (data?.hard_question?.hard_correct_count +
                            data?.hard_question?.hard_incorrect_count)) *
                        100
                      ).toFixed(1)
                    : 0,

                id: 4,
              },
            ],
          ],
        }));
      }

      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();

        setToaster({ show: true, type: "danger", content: data?.message });
      }
      if (response?.status === 401) {
        localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
      }
    } catch {
      setSpinner(false);
    }
  };

  const difficuiltyLevel = (key) => {
    switch (key) {
      case 1:
        return "Easy";
      case 2:
        return "Medium";
      case 3:
        return "Hard";
      default:
        return "";
    }
  };

  // function secondsToHms(d) {
  //   d = Number(d);
  //   var h = Math.floor(d / 3600);
  //   var m = Math.floor((d % 3600) / 60);
  //   var s = Math.floor((d % 3600) % 60);

  //   var hDisplay = h > 0 ? h + (h === 1 ? " hrs, " : " hrs, ") : "";
  //   var mDisplay = m > 0 ? m + (m === 1 ? " mins, " : " mins, ") : "";
  //   var sDisplay = s > 0 ? s + (s === 1 ? " secs" : " secs") : "";
  //   return (
  //     (hDisplay ? hDisplay : "0 hrs ") +
  //     (mDisplay ? mDisplay : "0 mins ") +
  //     (sDisplay ? sDisplay : "0 secs ")
  //   );
  // }

  const timeSpentInPercent = (time, exDuration) => {
    time = Number(time);
    exDuration = Number(exDuration);
    return (time / 60 / exDuration) * 100;
  };

  useEffect(() => {
    if (
      userData !== undefined &&
      userData &&
      userData.batches &&
      userData.batches[0] &&
      userData.batches[0].parent_batch
    ) {
      setCenterName(userData?.batches[0].parent_batch.name);
    }
  }, [userData]);

  useEffect(() => {
    getReportOverViewData();
    setInitialValues((prev) => ({ ...prev, divId: "question-1" }));
    //eslint-disable-next-line
  }, [centerName]);

  useEffect(() => {
    if (keys === "Question Wise Analysis") {
      // getQuestionWiseReportData(dyt_id);

      getDYTQuestionWiseReport();
    }
    //eslint-disable-next-line
  }, [keys]);

  useEffect(() => {
    if (keys === "Topic Wise") {
      getDYTTopicWiseReportData();
    }

    if (keys === "Level Wise") {
      getLevelWiseReportData(examTemplateData[0]);
      //
    }
    if (keys === "Overview") {
      getReportOverViewData();
    }
    // if (keys === "Leader Board") {
    //   getLeaderBoardReportData();
    // }
    // //eslint-disable-next-line
    // if (keys === "Question Wise Analysis") {
    //   getQuestionWiseReportData();
    // }
    //eslint-disable-next-line
  }, [keys]);

  // const callApiForData = (key, data) => {
  //   //
  //   setSelectedTemplate(data?.title);
  //   switch (key) {
  //     // case "Section Wise":
  //     //   return getSectionWiseReportData(data);
  //     case "Topic Wise":
  //       return getDYTTopicWiseReportData();
  //     case "Level Wise":
  //       return getLevelWiseReportData(data);
  //     default:
  //       return "";
  //   }
  // };

  // const pdfExportComponent = React.useRef(null);

  // const exportPDFWithMethod = () => {
  //   let element = examRef?.current || document.body;
  //   savePDF(element, {
  //     paperSize: "auto",
  //     margin: 40,
  //     fileName: `Report for ${new Date().getFullYear()}`
  //   });
  // };

  const downloadPdf = (e) => {
    e.preventDefault();
    // const element = document.getElementById("pdf");

    const opt = {
      margin: 0.3,
      filename: "filename.pdf",
      image: {
        type: "jpeg",
        quality: 0.98,
      },
      html2canvas: {
        scale: 2,
        useCORS: true,
      },
      jsPDF: {
        unit: "in",
        format: "a4",
        orientation: "portrait",
      },
    };

    var objstr = document.getElementById("pdf").innerHTML;
    // var objstr1 = document.getElementById('block2').innerHTML;

    var strr = `<html><head><title>Testing</title><style>.result-dash .result-questions {
    padding-top: 10px;
    margin-top: 1.5rem;
    height: 87%;
    overflow: auto;
}
.result-dash .white-box .question-result .mcq-questions .awn-btn {
    margin-left: 10px;
    margin-top: 0;
    padding: 4px 7px;
}
</style >
<link rel="stylesheet" type="text/css"  href="../../../assets/scss/dashboard.scss">

`;
    strr += "</head><body>";
    strr += objstr;
    // strr += '<div style="border:0.1rem solid #ccc!important;padding:0.5rem 1.5rem 0.5rem 1.5rem;margin-top:1.5rem">' + objstr1 + '</div>';
    strr += "</body></html>";

    // html2pdf()
    //   .set(opt)
    //   .from(element)
    //   .save();

    html2pdf().from(strr).set(opt).save();
  };

  // const exportPDFWithComponent = (e) => {
  //   e.preventDefault();
  //   if (pdfExportComponent.current) {
  //     pdfExportComponent.current.save();
  //   }
  // };

  const handleClasses = (key, isCorrect) => {
    switch (key) {
      case "active":
        return "pinkBox";
      case 0:
        return isCorrect === 0 ? "greenBox" : "pinkBox";
      case 1:
        return isCorrect === 0 ? "greenBox" : "pinkBox";
      case 2:
        return "yellowBox";
      case 3:
        return "yellowBox";
      case 4:
        return "yellowBox";
      default:
        return "grayBox";
    }
  };

  useEffect(() => {
    gotoServices(`question-${Number(questionnumber)}`);
    //eslint-disable-next-line
  }, [questionnumber]);

  return (
    <>
      <div className="result-dash fixed-dash dyt-report">
        <div className="white-box body-cont">
          {/* {moment(new Date()).format("DD/MM/YYYY") <
          moment(resultDate).format("DD/MM/YYYY") ? (
            <div className="report-date ">
              <div className="publishDate text-center">
                <Image src={result} alt="lakshay" />
                <h2 className="active p-4 ">
                  <p>The result will be published on </p>
                  {moment(resultDate).format("DD/MM/YYYY")}
                </h2>
              </div>
            </div>
          ) : ( */}
          <>
            <div className="sticky-dash-header">
              <div className=" row reportcard_row border-bottom-dashed mb-3 dyt-report-header">
                {reviewdData?.centerData?.map((resultDetails, index) => {
                  return (
                    <div
                      className=" col-12 cards dyt-report-header-card "
                      key={index}
                    >
                      <ReportCards
                        title={resultDetails.title}
                        result={resultDetails.result}
                        className={`border-end p-0 rounded-0 ${resultDetails.className}`}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="exam-tabs">
                <CommonTab setKeys={setKeys} keys={keys} data={tabData} />
              </div>
            </div>

            {/* overview tab  */}
            <div className="tab-sec">
              {keys === "Overview" && (
                <>
                  <div className="row reportcard_row  pb-0 ">
                    {reviewdData?.boardData?.map((item, index) => {
                      return (
                        <div className="col-md-3 tab-cards" key={index}>
                          <ReportCards
                            title={item.title}
                            result={
                              item.result ? (
                                item.result
                              ) : (
                                <span className="alert-text font-16">
                                  No data
                                </span>
                              )
                            }
                            image={item.icon}
                            className={item.className}
                            isSpeed={item.isSpeed}
                            cardData
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="graph-sec">
                    <div className="row pb-5">
                      {/* Analytics for the Whole Question Paper */}
                      <div className="col-md-12 col-lg-6 progress-col circle-progress pe-4 card-data-only ">
                        <ProgressBar
                          className=" lightPink text-left"
                          progressHeading="Analytics for the Whole Question Paper"
                          circleProgressBar={false}
                          // colorData={data.circleProgressColor}
                          progressData={reviewdData?.doughnutData}
                          cardData={true}
                        />
                      </div>

                      <div className="col-md-12 col-lg-6 progress-col circle-progress pe-4 color-donught">
                        <ProgressBar
                          className=" lightPink"
                          progressHeading={false}
                          UtillprogressHeading="Analytics for the Whole Question Paper"
                          circleProgressBar={true}
                          cardData={false}
                          colorData={data.circleProgressColor}
                          progressData={reviewdData?.doughnutData}
                        />
                      </div>
                      {/* Individual vs Topper vs Average Comparison */}
                    </div>
                  </div>
                </>
              )}
              {/* sub tabs  */}
              {keys === "Section Wise" && (
                <>
                  <div className="row reportcard_row pb-0 ">
                    {reviewdData?.boardData?.map(
                      (overviewReportData, index) => {
                        return (
                          <div className="col tab-cards" key={index}>
                            <ReportCards
                              title={overviewReportData.title}
                              result={overviewReportData.result}
                              image={overviewReportData.icon}
                              className={overviewReportData.className}
                              isSpeed={overviewReportData.isSpeed}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div className="row border-bottom-dashed pb-5 mb-5">
                    {/* Analytics for the Whole Question Paper */}
                    <div className="col-md-12 col-xxl-6 progress-col circle-progress pe-4 ">
                      <ProgressBar
                        className=" lightPink"
                        progressHeading="Analytics for the Whole Question Paper"
                        circleProgressBar={true}
                        colorData={data.circleProgressColor}
                        progressData={reviewdData?.doughnutData}
                      />
                    </div>
                    {/* Individual vs Topper vs Average Comparison */}
                    <div className="col-md-12 col-xxl-6 progress-col graph-progress ps-4">
                      <ProgressBar
                        progressHeading="Individual vs Topper vs Average Comparison"
                        subGraphHeading="Total Marks Scored"
                        graphProgressBar={true}
                        progressData={[]}
                        totalMarksScored={reviewdData?.totalMarksScored}
                        colorData={data.graphProgressColor}
                      />
                    </div>
                  </div>
                  <Table
                    // heading={data?.testExamTableData?.heading}
                    heading={[
                      { value: "Q. No.", id: 1, className: "w-15" },
                      { value: "Status", id: 2, className: "w-15" },
                      {
                        value: "Time Spent(Seconds)",
                        id: 3,
                        className: "w-20",
                      },
                      { value: "Level", id: 4, className: "w-30" },
                      {
                        value: "Correctly Attempted By(Students)",
                        id: 5,
                        className: "w-30",
                      },
                    ]}
                    rows={reviewdData?.sectionQuestionList}
                  />
                </>
              )}
              {keys === "Topic Wise" && (
                <>
                  <Table
                    heading={[
                      { value: "Topics", id: 1, className: "w-15" },
                      {
                        value: "Total Questions",
                        id: 2,
                        className: "w-15",
                      },
                      { value: "Attempted", id: 3, className: "w-15" },
                      { value: "Correct", id: 3, className: "w-15" },
                      { value: "Accuracy", id: 3, className: "w-15" },
                      {
                        value: "Time Spent (Sec)",
                        id: 3,
                        className: "w-15",
                      },
                    ]}
                    rows={topicWiseData}
                  />
                </>
              )}
              {keys === "Level Wise" && (
                <>
                  {/* <div className="row reportcard_row  pb-0 ">
                    {reviewdData?.boardData?.map(
                      (overviewReportData, index) => {
                        return (
                          <div className="col tab-cards" key={index}>
                            <ReportCards
                              title={overviewReportData.title}
                              result={overviewReportData.result}
                              image={overviewReportData.icon}
                              className={overviewReportData.className}
                              isSpeed={overviewReportData.isSpeed}
                            />
                          </div>
                        );
                      }
                    )}
                  </div> */}
                  <div className="graph-sec qa-level">
                    <div className="row  pb-5 ">
                      {/* Analytics for the Whole Question Paper */}
                      {/* Individual vs Topper vs Average Comparison */}
                      <div className="col-md-12 progress-col graph-progress">
                        <ProgressBar
                          progressHeading="Question Level Wise Analysis"
                          subGraphHeading="Time Comparison (Seconds)"
                          questionlevelProgressBar={true}
                          questionlevelProgressBarS={true}
                          progressData={[]}
                          totalMarksScored={reviewdData?.totalMarksScored}
                          colorData={data.qaGraphProgressColor}
                          // graphData={reviewdData?.levelWiseChart}
                          graphData={reviewdData?.levelWiseChartData}
                        />
                      </div>
                    </div>
                  </div>
                  <Table
                    // heading={data?.testExamTableData?.heading}
                    heading={[
                      { value: "Level", id: 1, className: "w-15" },
                      {
                        value: "Total Questions",
                        id: 2,
                        className: "w-15",
                      },
                      { value: "Correct", id: 3, className: "w-15" },
                      { value: "Incorrect", id: 4, className: "w-15" },
                      { value: "Skipped", id: 5, className: "w-15" },
                      { value: "Accuracy", id: 6, className: "w-15" },
                    ]}
                    rows={reviewdData?.levelWiseTableData}
                  />
                </>
              )}
              {keys === "Leader Board" && (
                <>
                  <div className="row reportcard_row  pb-0 ">
                    {reviewdData?.boardData?.map(
                      (overviewReportData, index) => {
                        return (
                          <div className="col tab-cards" key={index}>
                            <ReportCards
                              title={overviewReportData.title}
                              result={overviewReportData.result}
                              image={overviewReportData.icon}
                              className={overviewReportData.className}
                              isSpeed={overviewReportData.isSpeed}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div className="result-tables">
                    <div className="sub-report mt-5 border-bottom-dashed pb-5">
                      <div className="dash-heading">
                        <h2>Your Performance</h2>
                      </div>
                      <Table
                        heading={userTableHead}
                        rows={reviewdData?.userTableRowData}
                      />
                    </div>
                    <div className="sub-report mt-5">
                      <div className="dash-heading">
                        <h2>Top 10 Students</h2>
                      </div>
                      <Table
                        // heading={data?.testExamTableData?.heading}
                        heading={leaderBoardHeading}
                        rows={reviewdData?.topTenStudents}
                      />
                    </div>
                  </div>
                </>
              )}
              {keys === "Question Wise Analysis" && (
                <>
                  <div className="question-result d-flex">
                    <div className="question-report-sec">
                      <div className="question-result-header border-bottom-dashed">
                        <div className="qa-down row ">
                          <div className="col-xxl-5 dash-heading">
                            <h2>Question Paper With Answers</h2>
                          </div>
                          <div className="col-xxl-7 question-search-box">
                            <div className="search-form">
                              <form className="adminForm align-items-center d-sm-inline-flex w-100">
                                <div className="search-box ">
                                  <div className="form-group radio-group position-relative">
                                    <div className="input-box">
                                      <input
                                        type="text"
                                        placeholder="Search question number"
                                        className={`text-input searchBox`}
                                        // value={value}
                                        onChange={(e) =>
                                          setQuestionNumber(
                                            Number(e.target.value)
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>

                                <Button
                                  className="small-btn mt-0 px-2"
                                  title={"Download Paper"}
                                  onClick={(e) => {
                                    downloadPdf(e);
                                    // exportPDFWithComponent(e);
                                  }}
                                />
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="result-questions" id="allQuestions">
                        {/* <PDFExport
                          ref={pdfExportComponent}
                          // paperSize="auto"
                          // margin={40}
                          paperSize="A6"
                          // margin={{ left: "1cm", top: "1cm", right: "1cm", bottom: "1cm" }}
                        > */}
                        <p className="sub-report">
                          <DYTQuestionsHtml
                            mathsExam={questionAnalysisData}
                            difficuiltyLevel={difficuiltyLevel}
                            examRef={examRef}
                            cross={cross}
                            tick={tick}
                          />
                          {/* <QuestioQuestionHtml
                            mathsExam={questionAnalysisData}
                            difficuiltyLevel={difficuiltyLevel}
                            examRef={examRef}
                          /> */}
                        </p>
                        {/* </PDFExport> */}
                      </div>
                    </div>
                    <div className=" schedule-sidebar">
                      <div className=" right-sidebar ">
                        <div className="question-count gray-bg">
                          <div className="dash-heading border-bottom-dashed pb-4">
                            <h2>Question Palette</h2>
                          </div>
                          <div className="qa-nums">
                            {questionAnalysisData?.map((e, i) => {
                              return (
                                <span
                                  className={`${handleClasses(
                                    e.status,
                                    e?.option_selected?.is_correct
                                  )} inst-box text-center`}
                                  onClick={() =>
                                    gotoServices(`question-${i + 1}`)
                                  }
                                >
                                  {i + 1}
                                </span>
                              );
                            })}
                            <div className="col">
                              <div className="progress-report">
                                <div className="color-util">
                                  <ColorCard
                                    color="greenBox inst-box text-center"
                                    title={CONSTANT.LABEL.CORRECT}
                                  />
                                  <ColorCard
                                    color="pinkBox inst-box text-center"
                                    title={CONSTANT.LABEL.INCORRECT}
                                  />
                                  <ColorCard
                                    color="yellowBox inst-box text-center"
                                    title={CONSTANT.LABEL.NOT_ATTEMPTED}
                                  />
                                  {/* <ColorCard
                                    color="darkBlueBox inst-box text-center"
                                    title={"Answered & Marked for Review "}
                                  /> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="mobile-sidebar-icon position-fixed"
                    onClick={() => setSidebarShow(!sidebarShow)}
                  >
                    <div className="heading">
                      {" "}
                      Calendar <Image src={arrowImg} alt="arrow" />
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default TestDesignReport;
