import blueTick from "../../../../src/assets/images/blue-tick01.png";
import ColorCard from "../../utils/card/ColorCard";
import { CONSTANT } from "../../utils/constants";
import Image from "../../utils/image/Image";

const QuestionPallete = ({
  questionsData,
  handleClasses,
  handleQuestionClick,
  selectedTabId,
  remainingTime,
}) => {
  // Utility function to format seconds into hours:minutes:seconds
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };
  const currentSection = questionsData?.find((item) => {
    return Number(Object.keys(item)[0]) === Number(selectedTabId);
  });

  const questions =
    (currentSection !== undefined &&
      currentSection[selectedTabId]?.question_data) ||
    [];
  return (
    <div className="home_question_palete schedule-sidebar w-25">
      <div className="right-sidebar">
        <div className="white-box d-flex flex-column">
          {/* <h1 className="text-center">Question Pallete</h1> */}
          <div className="qa-instructions ps-0 pt-0">
            <div className="exam-timer">
              Time Remaining: {formatTime(remainingTime)}
            </div>
          </div>
          {/* Display question count and status */}
          <div className="question-count mt-4">
            {questions?.map((elem, i) => (
              <span
                key={`key-${i}`}
                className={`${handleClasses(
                  elem.status,
                  elem.is_correct
                )} inst-box text-center`}
                onClick={() => handleQuestionClick(i)}
              >
                {i + 1}
                {elem.status === 1 && <Image src={blueTick} alt="blue-tick" />}
              </span>
            ))}
          </div>
          <div className="col mt-5">
            <div className="progress-report ">
              {/* ***circleProgressBar*** */}
              <div className="color-util w-100">
                <ColorCard
                  color="greenBox inst-box text-center"
                  title={CONSTANT.LABEL.CORRECT}
                />
                <ColorCard
                  color="pinkBox inst-box text-center"
                  title={CONSTANT.LABEL.INCORRECT}
                />
                <ColorCard
                  color="yellowBox inst-box text-center"
                  title={CONSTANT.LABEL.NOT_ATTEMPTED}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionPallete;
