import React from "react";
import { NavLink } from "react-router-dom";
import DashboardDocument from "../../../assets/images/dashboard-document.png";
import VideoIcon from "../../../assets/images/search-video.png";
import ExamIcon from "../../../assets/images/online-exam.png";
import HomeAssignmentStatusButton from "../homeAssignmentLatest/HomeAssignmentStatusButton";
import MockTestButton from "../test/MockTestButton";
import { uploadOMRFile } from "../../utils/helpers/uploadOMRFile";
import OMRFilePreviewModal from "../test/OMRFilePreviewModal";
import Image from "../../utils/image/Image";
import { convertIsoToOrdinalDate } from "../../utils/dateTime";

const ResourceViewCard = ({
  card,
  setSelectedFile,
  selectedFile,
  setPreviewFile,
  previewFile,
  setIsModalOpen,
  isModalOpen,
  setToaster,
  pageNumber,
  onDocumentLoadSuccess,
  handleUploadOMR,
  studentId,
}) => {
  const formatDate = convertIsoToOrdinalDate(card?.tagged_date);
  const iconMappings = {
    1: DashboardDocument,
    2: VideoIcon,
    4: ExamIcon,
  };
  const selectedIcon = iconMappings[Number(card?.resource_type_id)];

  return (
    <div className="col-md-6">
      <div className="w-100 mb-4 d-flex flex-column align-items-center justify-content-center resource_view_card_container">
        <div className="d-flex justify-content-center align-items-center resource_view_card ">
          <Image
            width={33}
            height={33}
            src={selectedIcon}
            alt="dashboard-document"
          />
        </div>
        <div
          className="d-flex flex-column justify-content-center"
          style={{ marginTop: "20px" }}
        >
          <p className="text-center card_title">
            {card.resource_type_id === 4 ? card.name : card?.title}
          </p>
          <p
            className="text-center card_date"
            dangerouslySetInnerHTML={{ __html: formatDate }}
          />
        </div>
        <div className="d-flex justify-content-center">
          {card.resource_type_id === 1 && (
            <NavLink
              to={`/courses/document/${card.id}`}
              className="btn small-btn theme-btn"
              state={{
                item: card,
                resourse: "Document",
                resourceData: [
                  {
                    id: 1,
                    name: "Document",
                  },
                ],
              }}
            >
              Open
            </NavLink>
          )}
          {[2, 5].includes(card.resource_type_id) && (
            <NavLink
              to={`/courses/video/${card.id}`}
              className="btn small-btn theme-btn"
              state={{
                item: card,
                resourse: "video",
                resourceData: [
                  {
                    id: 1,
                    name: "Video",
                  },
                ],
              }}
            >
              Open
            </NavLink>
          )}
          {card.resource_type_id === 4 && (
            <div>
              {card?.category?.name.replace(/\s+/g, "").toLowerCase() ===
                "homeassignment" && (
                <HomeAssignmentStatusButton
                  id={card?.id}
                  examTemplateId={card?.exam_template_id}
                  status={card?.status}
                  endDate={card?.end_date}
                  resultDate={card?.result_date}
                  subjectName={card?.name}
                  attemptCount={card?.exam_attempt_count}
                />
              )}
              {card?.category?.name.replace(/\s+/g, "").toLowerCase() !==
                "homeassignment" && (
                <MockTestButton
                  status={card?.status}
                  id={card?.id}
                  startDate={card?.start_date}
                  endDate={card?.end_date}
                  resultDate={card?.result_date}
                  examRetake={card?.exam_retakes}
                  examStatus={card?.exam_status}
                  examTemplateId={card?.exam_template_id}
                  categoryExamName={card?.category?.name?.toLowerCase()}
                  categoryExamRetake={card?.category?.exam_retake}
                  subjectName={card?.name}
                  onChangeOMR={(e) =>
                    uploadOMRFile(
                      e,
                      setSelectedFile,
                      setPreviewFile,
                      setIsModalOpen,
                      setToaster
                    )
                  }
                  onClickOMR={() => {
                    document.getElementById("file_upload").click();
                    localStorage.setItem("exam_id", card?.id);
                  }}
                  totalQuestions={card?.total_questions}
                  examName={card?.name}
                  questionLimit={Number(
                    card?.exam_template_structure
                      ? Number(card?.exam_template_structure[0]?.limit)
                      : undefined
                  )}
                  examTemplateUIId={card?.category?.template?.id}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div>
        <OMRFilePreviewModal
          modalSize="md"
          isModalOpenOMR={isModalOpen}
          setIsModalOpenOMR={setIsModalOpen}
          selectedOMRFile={selectedFile}
          pageNumber={pageNumber}
          onDocumentLoadSuccess={onDocumentLoadSuccess}
          previewOMRFile={previewFile}
          handleUploadOMR={handleUploadOMR}
          studentId={studentId}
        />
      </div>
    </div>
  );
};

export default ResourceViewCard;
