export const useHandleClassExam = () => {
  const classMap = {
    3: "pinkBox",
    4: "grayBox",
    0: "greenBox",
    1: "darkBlueBox",
    2: "yellowBox",
    default: "grayBox",
  };
  // Question Pallete question status color
  const handleClasses = (key) => {
    return classMap[key] || classMap.default;
  };
  return { handleClasses };
};
