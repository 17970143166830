import React from "react";
import ComprehensiveQuestionContainer from "./ComprehensiveQuestionContainer";
import QuestionContainer from "./QuestionContainer";
import QuestionDetailContainer from "./QuestionDetailContainer";
import Image from "../image/Image";

const QuestionHtml = ({
  className,
  mathsExam,
  difficuiltyLevel,
  examRef,
  cross,
  tick,
}) => {
  return (
    <div
      className={`question-dash ${className ? className : ""}`}
      ref={examRef}
      id="pdf"
    >
      {mathsExam
        ?.sort(
          (a, b) =>
            a?.exam_questions[0]?.exam_sort_id -
            b?.exam_questions[0]?.exam_sort_id
        )
        .map((mathsExamData, i) => {
          return (
            <>
              {mathsExamData?.question?.comprehension && (
                <ComprehensiveQuestionContainer
                  comprehension={mathsExamData?.question.comprehension[0]?.name}
                />
              )}
              <div
                className="mt-4 border-bottom-dashed question-box"
                id={`question-${i + 1}`}
              >
                <QuestionContainer
                  questionNo={i + 1}
                  question={mathsExamData?.question?.question}
                />
                <div className="qa-txt">
                  <ul className="mcq-questions ps-0">
                    {mathsExamData?.answers?.map((qaData, index) => {
                      const alphabets = {
                        1: "A",
                        2: "B",
                        3: "C",
                        4: "D",
                        5: "E",
                        6: "F",
                        7: "G",
                      };
                      return (
                        <li className="list-unstyled">
                          <span className="qa_data d-inline-flex align-items-center">
                            <span>({alphabets[index + 1]})</span>{" "}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: qaData?.option,
                              }}
                            ></span>
                          </span>
                          {Number(mathsExamData.selected_option) ===
                            Number(qaData.id) &&
                            Number(mathsExamData?.is_correct) === 0 && (
                              <button
                                className={`small-btn report-theme-btn mt-0 no-wrap ms-4`}
                              >
                                <Image
                                  src={tick}
                                  width={15}
                                  height={15}
                                  alt="tick"
                                />
                                <span>My Answer</span>
                              </button>
                            )}
                          {Number(mathsExamData.selected_option) ===
                            Number(qaData.id) &&
                            Number(mathsExamData?.is_correct) === 1 && (
                              <button
                                className={`small-btn report-theme-btn mt-0 no-wrap ms-4`}
                              >
                                <Image
                                  src={cross}
                                  width={15}
                                  height={15}
                                  alt="cross-icon"
                                />
                                <span>My Answer</span>
                              </button>
                            )}

                          {qaData?.is_correct === 1 && (
                            <button className="small-btn report-green-btn mt-0 no-wrap ms-3">
                              <Image
                                src={tick}
                                width={15}
                                height={15}
                                alt="tick"
                              />{" "}
                              <span>Correct Answer</span>
                            </button>
                          )}
                          {Number(mathsExamData.question.question_type_id) ===
                            3 && <br></br>}
                          {mathsExamData.question.question_type_id === 3 &&
                            mathsExamData.selected_option === "-1" && (
                              <div className="ps-3 my-3">
                                (A) {mathsExamData.input_answer}{" "}
                                <span>
                                  <button
                                    className={`small-btn report-theme-btn mt-0 no-wrap ms-4`}
                                  >
                                    <Image
                                      src={cross}
                                      width={15}
                                      height={15}
                                      alt="cross"
                                    />
                                    <span>My Answer</span>
                                  </button>
                                </span>
                              </div>
                            )}
                        </li>
                      );
                    })}
                  </ul>

                  <QuestionDetailContainer
                    difficuiltyLevel={difficuiltyLevel}
                    questionData={mathsExamData}
                  />
                </div>
              </div>
            </>
          );
        })}
    </div>
  );
};

export default QuestionHtml;
