import React from "react";

import { useForm } from "@kaydhiman/react-hook-useform";

import InputField from "../forms/InputField";
import { Accordion } from "react-bootstrap";
import Button from "../forms/Button";
import { useLocation } from "react-router-dom";
import Image from "../image/Image";
// import cross from '../../../assets/images/cancel-svgrepo-com'

export const DYTQuestionsHtml = ({
  className,
  mathsExam,
  difficuiltyLevel,
  examRef,
  tick,
  cross,
}) => {
  const { bindField } = useForm({ validations: {} });

  const location = useLocation();
  const statusMessages = {
    0: "Save & Next",
    1: "Save & Mark For Review",
    2: "Mark For Review & Next",
    3: "Not Answered",
    4: "Not Answered",
  };

  const statusOption = (value) => {
    return statusMessages[Number(value)];
  };

  return (
    <div
      className={`question-dash ${className ? className : ""}`}
      ref={examRef}
      id="pdf"
    >
      {mathsExam?.map((mathsExamData, i) => {
        return (
          <>
            {mathsExamData?.questions[0]?.comprehension && (
              <div className="mt-4 question-box" key={i}>
                <div
                  className="question"
                  dangerouslySetInnerHTML={{
                    __html: mathsExamData?.questions[0]?.comprehension[0]?.name,
                  }}
                ></div>
              </div>
            )}
            <div
              className="mt-4 border-bottom-dashed question-box"
              id={`question-${i + 1}`}
            >
              <div className="dash-heading ">
                <h4>
                  Question <span>{i + 1}</span>:
                </h4>
              </div>
              <div className="qa-txt">
                {/* <span className="question">
                  {mathsExamData?.questions[0]?.question?.replace(
                    /<[^>]+>/g,
                    ""
                  )}
                </span> */}
                <span
                  className="question"
                  dangerouslySetInnerHTML={{
                    __html: mathsExamData?.questions[0]?.question,
                  }}
                ></span>

                {location.pathname.includes("/resultnew") ||
                location.pathname.includes("/test-design-report") ? (
                  <ul className="mcq-questions ps-0">
                    {mathsExamData?.questions[0]?.options?.map(
                      (qaData, index) => {
                        return (
                          <li className="list-unstyled d-flex">
                            <span className="qa_data d-inline-flex align-items-center">
                              <span>({index + 1})</span>{" "}
                              {/* <span>
                                {qaData?.option?.replace(/<[^>]+>/g, "")}
                              </span> */}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: qaData?.option,
                                }}
                              ></span>
                            </span>

                            {[0, 1].includes(mathsExamData.status) &&
                              Number(mathsExamData?.option_selected?.option) ===
                                qaData?.id && (
                                <div
                                  className={`btn small-btn btn-group align-items-center awn-btn ${
                                    qaData?.is_correct === 1
                                      ? "green-btn"
                                      : "theme-btn"
                                  }`}
                                >
                                  <Image
                                    src={
                                      qaData?.is_correct === 1 ? tick : cross
                                    }
                                    style={{ width: "13px" }}
                                    alt={
                                      qaData?.is_correct === 1
                                        ? "tick"
                                        : "cross"
                                    }
                                  />
                                  <Button
                                    title={"My Answer"}
                                    className="bg-transparent m-0"
                                    type="button"
                                  />
                                </div>
                              )}

                            {qaData?.is_correct === 1 && (
                              <div
                                className={`btn small-btn btn-group align-items-center awn-btn green-btn`}
                              >
                                <Image src={tick} alt="tick" />
                                <Button
                                  title={"Correct Answer"}
                                  className="bg-transparent m-0"
                                  type="button"
                                />
                              </div>
                            )}
                          </li>
                        );
                      }
                    )}
                  </ul>
                ) : (
                  <div className="awns_text">
                    <ul className="mcq-questions ps-0">
                      {mathsExamData?.awn_options?.map((awnData) => {
                        return (
                          <li className="list-unstyled">
                            <div className="quiz-awn inline-block">
                              <InputField
                                bindField={bindField}
                                type="radio"
                                id={awnData.id}
                                name="awn_radio"
                                label={awnData.awn_options_title}
                                className="custon-radio d-none"
                              />
                            </div>
                            <div
                              className={`btn small-btn  btn-group align-items-center awn-btn ${awnData.class_Name}`}
                            >
                              <Image src={awnData.icon} alt="lakshay" />
                              <Button
                                title={awnData.btn_title}
                                className="bg-transparent m-0 "
                                type="button"
                              />
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                {location.pathname.includes("/resultnew") ||
                location.pathname.includes("/test-design-report") ? (
                  <div className="exam-details-footer  ">
                    <div className="exam-details gray-bg">
                      <ul className="mcq-questions ps-0">
                        <li className="d-sm-inline-flex  list-unstyled">
                          <span className="dark-gray weight-500">
                            User Action:
                          </span>
                          <span className="dark-gray weight-normal">
                            {statusOption(mathsExamData?.status)}
                          </span>
                        </li>

                        <li className="d-sm-inline-flex  list-unstyled">
                          <span className="dark-gray weight-500">
                            Time Spent:
                          </span>
                          <span className="dark-gray weight-normal">
                            {mathsExamData?.time_spent} Seconds(s)
                          </span>
                        </li>

                        <li className="d-sm-inline-flex  list-unstyled">
                          <span className="dark-gray weight-500">Level:</span>
                          <span className="dark-gray weight-normal">
                            {difficuiltyLevel(
                              mathsExamData?.questions[0]
                                ?.question_difficulty_id
                            )}
                          </span>
                        </li>

                        <li className="d-sm-inline-flex  list-unstyled">
                          <span className="dark-gray weight-500">Subject:</span>
                          <span className="dark-gray weight-normal">
                            {mathsExamData?.questions[0]?.subject?.name}
                          </span>
                        </li>

                        <li className="d-sm-inline-flex  list-unstyled">
                          <span className="dark-gray weight-500">Chapter:</span>
                          <span className="dark-gray weight-normal">
                            {mathsExamData?.questions[0]?.topic?.name}
                          </span>
                        </li>
                      </ul>
                    </div>
                    {mathsExamData?.questions[0]?.solution && (
                      <div className="exam-detail-accordian mt-4 gray-bg">
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              View Explanation
                            </Accordion.Header>
                            <Accordion.Body>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: mathsExamData?.questions[0]?.solution,
                                }}
                              ></span>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="exam-screen">
                    <div className="header-btns">
                      <ul className="ms-auto justify-content-end pe-5">
                        <li>
                          <button
                            className="btn theme-btn prev-btn me-4"
                            disabled=""
                          >
                            Previous
                          </button>
                        </li>
                        <li>
                          <button className="btn theme-btn proceed next-btn">
                            Next
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default DYTQuestionsHtml;
