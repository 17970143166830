import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Components
import HeaderHamburger from "./HeaderHamburger";
import HeaderSearchContent from "./HeaderSearchContent";
import HeaderNotifications from "./HeaderNotifications";
import HeaderUserList from "./HeaderUserList";
import UserList from "./MobileUserList";

// Contexts and Services
import { useBreadcrumb } from "../../../shared/breadcrumb-context";
import { AppContext } from "../../../shared/AppContext";
// import { userService } from "../../../../_services/User.services";

// Constants and Utils
import { CONSTANT } from "../../../utils/constants";

// Images and Assets
import Logo from "../../../../assets/images/prathamLogo.png";
import userImg from "../../../../assets/images/user-image.jpg";
import WhiteArrowDown from "../../../../assets/images/white-arrow-down.svg";
import MentorshipIcon from "../../../../assets/images/mentorship-form-icon.png";
import FeedbackIcon from "../../../../assets/images/feedback.png";
import CertificateIcon from "../../../../assets/images/certificate.png";
import notificationIcon from "../../../../assets/images/annoucements-svg.svg";
import OrangeSearch from "../../../../assets/images/search-svg.svg";
import AccountIcon from "../../../../assets/images/accounting.png";
import DesktopNotificationIcon from "../../../../assets/images/annoucements2.png";
import ReferIcon from "../../../../assets/images/refer.svg";

// Custom Hooks
import { useNotficationList } from "../../../../hooks/hookList";

// Styles
import "./Header.scss";
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside";
import HeaderBreadcrumb from "./HeaderBreadcrumb";
import HeaderExamLogo from "./HeaderExamLogo";
import HeaderExamTitle from "./HeaderExamTitle";
import { useRedirection } from "../../../../hooks/useRedirection";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    setSpinner,
    setToaster,
    getProfileData,
    isSidebarHide,
    setIsSidebarHide,
    userData,
    isOpenNotification,
    setIsOpenNotification,
  } = useContext(AppContext);
  const testName = localStorage.getItem("testName")
    ? JSON.parse(localStorage.getItem("testName"))
    : undefined;
  const headerBreadcrumb = JSON.parse(
    window.localStorage.getItem("breadcrumbs")
  );

  const [profileDrop, setProfileDrop] = useState(false);

  const profileRef = useRef();
  const notificationRef = useRef();

  const { dispatch, state } = useBreadcrumb();
  const { getData: getNotificationList, data: notficationData } =
    useNotficationList(setSpinner, setToaster);

  const { getRedirection } = useRedirection();

  const isShow =
    location?.pathname.includes("examPortal") ||
    location?.pathname?.includes("test_design_layout") ||
    location.pathname.includes(
      "/home-assignments/assignment-details/newAssignmentQuiz/"
    );

  useOnClickOutside(profileRef, () => setProfileDrop(false));
  useOnClickOutside(notificationRef, () => setIsOpenNotification(false));

  // ************************Get Notification Data******************************
  const removeBackItems = (title, index) => {
    headerBreadcrumb.forEach((item) => {
      if (state.breadcrumbs[state.breadcrumbs.length - 1]?.title === title) {
        return null;
      } else if (item?.title === title) {
        dispatch({
          type: CONSTANT.TYPE.REMOVE_SELECT_BREADCRUMB,
          initial: 0,
          second: index + 1,
        });
        navigate(item.url);
      }
    });
  };

  {
    /*
  const logout = async () => {
    setSpinner(true);
    try {
      const response = await userService.logout();
      if (response?.status >= 200 && response?.status <= 299) {
        // const data = await response.json();
        // localStorage.removeItem("_prathamStudentToken");
        setSpinner(false);
        localStorage.clear();
        navigate("/login");
        // window.location.reload();
      }
      if (response?.status === 401) {
        localStorage.removeItem("_prathamStudentToken");
      }
      if (response?.status >= 400 && response?.status <= 499) {
        const data = await response.json();
        setToaster({
          show: true,
          type: "warning",
          content: data.message,
        });
      }
    } catch (error) {
      setSpinner(false);
    }
  };
  */
  }
  const data = [
    {
      name: "Profile",
      url: "/update-profile",
      id: 1,
      icon: "images/profile-circle.svg",
    },
    // {
    //   name: "Change Theme",
    //   url: "/dashboard",
    //   id: 2,
    //   icon: "images/brush.svg",
    //   title: "theme-changer",
    // },
    {
      name: "My Account",
      url: "/my-account",
      id: 3,
      icon: AccountIcon,
    },
    {
      name: "Survey & Mentorship Forms",
      url: `${process.env.REACT_APP_MENTORSHIP_BASE_URL}/${userData?.user_id}`,
      icon: MentorshipIcon,
      id: 4,
    },
    {
      name: "Anonymous Feedback",
      url: process.env.REACT_APP_ANONYMOUS_FEEDBACK_URL,
      icon: FeedbackIcon,
      id: 5,
    },
    {
      name: "Result & Certificate",
      url: `${process.env.REACT_APP_RESULT_CERTIFICATE_URL}/students-list/${userData?.user_id}`,
      icon: CertificateIcon,
      id: 6,
    },
    ...(userData &&
    userData?.batches !== undefined &&
    Number(
      userData?.batches[userData?.batches?.length - 1]?.parent_batch
        ?.parent_batch?.organization_details?.org_id
    ) === 276
      ? [
          {
            name: "School Round Slot Selection",
            url: `${process.env.REACT_APP_PRATHAM_BASE_URL}/osr/${userData?.id}`,
            icon: CertificateIcon,
            id: 7,
          },
        ]
      : []),
    // {
    //   name: "Refer & Earn",
    //   url: "/refer-earn",
    //   id: 8,
    //   icon: ReferIcon,
    // },
    {
      name: "Logout",
      url: "/",
      id: 9,
      icon: "images/logout.svg",
    },
  ];

  const isBreadcrumbShow =
    location?.pathname?.includes("examPortal") ||
    location?.pathname?.includes("test_design_layout") ||
    location?.pathname?.includes("newAssignmentQuiz");

  const handleClickNotification = async () => {
    await getNotificationList({ page: 1, size: 10 });
    setIsOpenNotification(!isOpenNotification);
  };

  const handleOnClick = (item) => {
    if (item?.name === "Logout") {
      // redirectTo();
      const routeRedirection = getRedirection(userData.org_id);

      window?.location?.replace(routeRedirection);
      window?.localStorage?.clear();
    }
    if (item?.name === "Profile") {
      navigate("/update-profile");
    }
    if (item?.name === "My Account") {
      navigate("/my-account");
    }
    if (item?.name === "Survey & Mentorship Forms") {
      window.open(
        `${process.env.REACT_APP_MENTORSHIP_BASE_URL}/${userData?.user_id}}`,
        "_blank"
      );
    }
    if (item?.name === "Anonymous Feedback") {
      window.open(
        process.env.REACT_APP_ANONYMOUS_FEEDBACK_URL +
          "" +
          `?i=${btoa(userData?.user_id)}`,
        "_blank"
      );
    }
    if (item?.name === "Result & Certificate") {
      window.open(
        `${process.env.REACT_APP_RESULT_CERTIFICATE_URL}/students-list/${userData?.user_id}`,
        "_blank"
      );
    }

    if (item.name === "School Round Slot Selection") {
      window.open(
        `${process.env.REACT_APP_PRATHAM_BASE_URL}/osr/${userData.user_id}`
      );
    }
    if (item.name === "Refer & Earn") {
      navigate(item.url);
    }
    setProfileDrop(false);
  };

  useEffect(() => {
    if (location.pathname.includes("examPortal")) {
      getProfileData();
    }
  }, [location]);

  return (
    <div
      style={{
        marginBottom: location.pathname.includes("examPortal") ? "0" : "2rem",
      }}
    >
      <header
        className={
          location.pathname.includes("examPortal") ? "header_exam" : "header"
        }
      >
        <div
          className={`d-flex ${
            testName?.subject ? "justify-content-center" : ""
          } ${isBreadcrumbShow ? "w-70" : ""}`}
        >
          {!isBreadcrumbShow && (
            <HeaderHamburger
              setIsSidebarHide={setIsSidebarHide}
              isSidebarHide={isSidebarHide}
              icon={Logo}
              location={location}
            />
          )}
          {!isBreadcrumbShow && (
            <HeaderBreadcrumb
              isSidebarHide={isSidebarHide}
              removeBackItems={removeBackItems}
              state={state}
            />
          )}
          {isBreadcrumbShow && (
            <HeaderExamLogo
              icon={
                userData?.batches?.length >= 1 &&
                userData?.batches[0]?.parent_batch?.parent_batch
                  ?.organization_details?.full_logo
              }
            />
          )}
          {isShow && (
            <HeaderExamTitle
              isSidebarHide={isSidebarHide}
              location={location}
              examName={testName?.subject}
              topicName={testName?.topic}
            />
          )}
        </div>

        <div className="d-flex align-items-center">
          {userData &&
            userData?.batches !== undefined &&
            Number(
              userData?.batches[userData?.batches?.length - 1]?.parent_batch
                ?.parent_batch?.organization_details?.org_id
            ) === 276 && (
              <div className="me-4 d-none d-lg-block">
                <button
                  className="btn theme-btn py-2"
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_PRATHAM_BASE_URL}/osr/${userData?.user_id}`,
                      "_blank"
                    )
                  }
                >
                  School Round Slot Selection
                </button>
              </div>
            )}
          {!location.pathname.includes("examPortal") && (
            <HeaderSearchContent mobileIcon={OrangeSearch} />
          )}
          {!location.pathname.includes("examPortal") && (
            <HeaderNotifications
              mobileIcon={notificationIcon}
              desktopIcon={DesktopNotificationIcon}
              notificationList={notficationData?.data}
              setIsOpenNotification={setIsOpenNotification}
              isOpenNotification={isOpenNotification}
              notificationRef={notificationRef}
              handleClickNotification={handleClickNotification}
            />
          )}
          <HeaderUserList
            setProfileDrop={setProfileDrop}
            profileDrop={profileDrop}
            userIcon={
              userData?.image !== "NULL" && userData?.image !== null
                ? userData?.image
                : userImg
            }
            arrowIcon={WhiteArrowDown}
            userId={userData?.user_id}
            userName={userData?.name}
            profileRef={profileRef}
            location={location}
          />
        </div>

        {profileDrop && (
          <UserList
            userName={userData?.name}
            userId={userData?.user_id}
            userDetails={data}
            handleOnClick={handleOnClick}
            profileRef={profileRef}
          />
        )}
      </header>
      <div className="header_blue_line">
        {isShow && (
          <div className="header_mobile_exam_name">
            {testName?.subject && (
              <h3 className="exam_name p-0 m-0">{testName?.subject}</h3>
            )}
            {testName?.topic && (
              <h3 className="exam_name p-0 m-0">{testName?.topic}</h3>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
