import React from "react";
import Image from "../../../utils/image/Image";

const HeaderExamLogo = ({ icon }) => {
  return (
    <div className="exam_logo_container">
      <Image className="exam_logo" src={icon} />
    </div>
  );
};

export default HeaderExamLogo;
