import React from "react";

import "react-calendar/dist/Calendar.css";
import { Link } from "react-router-dom";
import pdf_icon from "../../../assets/images/clipboard-text.svg";
import TestDetailCard from "../../utils/card/TestDetailCard";

export default function TestRightSidebar({ testDetail, resourse }) {
  return (
    <div className="right-sidebar test-detail-sidebar">
      <div className="white-box">
        <div className="dash-heading text-center pt-1 pb-2">
          <h2>{testDetail?.title}</h2>
        </div>
        {/* <TestDetailCard testDetail={data?.testDetail} /> */}
        <ul className="marks_list w-100 ps-0 mb-0">
          <li className="w-100 list-unstyled border-bottom-dashed mb-4 pb-4">
            {testDetail?.file_path && (
              <>
                <span className="float-starting">Path</span>
                <span className="float-end">{testDetail?.file_path}</span>
              </>
            )}
          </li>
          {/* <li className="w-100 list-unstyled border-bottom-dashed mb-4 pb-4">
            <span className="float-starting">Date and Time</span>
            <span className="float-end">
              {moment(testDetail?.tagged_date).format("DD MMM, YYYY")}
              <span className="px-2">|</span>
              {moment(testDetail?.tagged_date).format("HH:MM A")}
            </span>
          </li> */}
          <li className="w-100 list-unstyled border-bottom-dashed mb-4 pb-4">
            <span className="float-starting">Content Type</span>
            <span className="float-end">{resourse}</span>
            {testDetail?.type === "video" && (
              <span className="float-end">{testDetail?.type}</span>
            )}
            {testDetail?.type === "document" && (
              <span className="float-end">{testDetail?.type}</span>
            )}
          </li>
          {testDetail?.description && (
            <>
              <li className="w-100 list-unstyled mb-2 ">
                <span className="float-starting">Description</span>
              </li>
              <li className="w-100 list-unstyled mb-2 pt-2 ">
                <span className="float-end">
                  {testDetail?.description?.includes("<p>")
                    ? ""
                    : testDetail?.description}
                </span>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
}
