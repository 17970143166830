import React, { useState, createContext } from "react";
import { userService } from "../../_services/User.services";
import moment from "moment";
import Button from "../utils/forms/Button";
import { Modal } from "react-bootstrap";
import Image from "../utils/image/Image";
import PCIcon from "../../assets/images/pc_icon.png";
import MobileIcon from "../../assets/images/phone_icon.png";
import {
  useCreateQuery,
  useManageLoginSession,
  useOtpVerfication,
  useSendOtpCode,
  useUpdateProfile,
  useUpdateProfilePhoto,
} from "../../hooks/hookList";
import Auth from "../../auth/Auth";
import { useRedirection } from "../../hooks/useRedirection";
import UpdateProfileImageModal from "../projectDashboard/portalLayout/UpdateProfileImageModal";
import { useForm } from "@kaydhiman/react-hook-useform";
import userImg from "../../assets/images/user-image.jpg";
import UpdateProfileModal from "../projectDashboard/dashboard/UpdateProfileModal";

export const AppContext = createContext();

const SessionModal = ({
  isShow,
  devicesData,
  manageLoginSession,
  userData,
  getProfileData,
  setSessionsModal,
  openQuery,
  setOpenQuery,
  raiseQuery,
  setRaiseQuery,
  handleCreateQuery,
}) => {
  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hour = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
  };

  const generateSessionParams = (
    studentLmsId,
    sessionId,
    deviceType,
    deviceModel,
    operatingSystem,
    operatingSystemVersion,
    browserName,
    getCurrentDateTime,
    remark = "Logged In Successfully",
    loginTime
  ) => {
    return {
      student_id: studentLmsId,
      session_id: sessionId,
      device_type: deviceType !== undefined ? deviceType : "PC",
      device_model:
        deviceModel !== undefined
          ? deviceModel
          : `${operatingSystem}-${operatingSystemVersion}`,
      browser: browserName,
      login_time: loginTime,
      ...(remark === "Logged Out Successfully" && {
        logout_time: getCurrentDateTime(),
      }),
      remark,
      is_active: remark === "Logged In Successfully" ? 1 : 0,
    };
  };

  const localStorageSessionId = JSON.parse(
    localStorage.getItem("studentSessionDevices")
  );

  return (
    <Modal show={isShow} centered className="modal-dialog-session-devices">
      <Modal.Header className="w-100 flex-column">
        <Modal.Title className="text-center">
          <p className="mb-2" style={{ fontSize: "20px" }}>
            Device Limit Reached
          </p>
          <p className="mb-0" style={{ fontWeight: "400" }}>
            Maximum 2 Devices allowed. Please log out from one or more devices
            to continue access from this device.
          </p>
        </Modal.Title>
        <hr className="w-100" style={{ height: "2px", color: "#000" }} />
      </Modal.Header>
      <Modal.Body>
        <div className="overflow-hidden w-100">
          <div className="row">
            {devicesData
              .sort((a, b) => {
                // First, prioritize matching session_id
                const sessionMatchA =
                  a.session_id === localStorageSessionId?.session_id ? 1 : 0;
                const sessionMatchB =
                  b.session_id === localStorageSessionId?.session_id ? 1 : 0;

                if (sessionMatchA !== sessionMatchB) {
                  // Sort by session match, with matches coming first
                  return sessionMatchB - sessionMatchA;
                }

                // If session matches are the same, sort by login_time in descending order
                return new Date(b.login_time) - new Date(a.login_time);
              })
              .map((d) => {
                const params = generateSessionParams(
                  Auth?.user()?.id,
                  d.session_id,
                  d.device_type,
                  d.device_model,
                  "",
                  "",
                  d.browser,
                  getCurrentDateTime,
                  "Logged Out Successfully",
                  d.login_time
                );

                return (
                  <div className="col-md-6 mb-4">
                    <div className="session_card">
                      <div className="session_card_container">
                        <div className="d-flex align-items-center">
                          <div>
                            <Image
                              src={d.device_type === "PC" ? PCIcon : MobileIcon}
                              alt={
                                d.device_type === "PC"
                                  ? "pc-icon"
                                  : "mobile-icon"
                              }
                              width={25}
                              height={25}
                            />
                          </div>
                          <div className="ms-3">
                            <p
                              className="mb-0 text-capitalize"
                              style={{ fontWeight: "500" }}
                            >
                              {d.device_type}-{d.browser}-{d.device_model}
                            </p>
                          </div>
                        </div>
                        <div className="device_sessions_desktop">
                          {Number(d?.session_id) ===
                            Number(localStorageSessionId?.session_id) && (
                            <div
                              className="d-flex align-items-center"
                              style={{
                                color: "#11ce8c",
                                borderRadius: "20px",
                                padding: "5px 10px",
                                width: "120px",
                              }}
                            >
                              Current Device
                            </div>
                          )}
                          {Number(d?.session_id) !==
                            Number(localStorageSessionId?.session_id) && (
                            <div>
                              <Button
                                className="btn small-btn mt-0"
                                title="Sign Out"
                                onClick={async () => {
                                  await manageLoginSession(params);
                                  await getProfileData();
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <hr
                        className="w-100 my-3"
                        style={{
                          borderBottom: "1px solid #e2e2e2",
                        }}
                      />
                      <div className="session_time_container">
                        <p className="mb-0">
                          {moment(d.login_time).format("YYYY-MM-DD")},{" "}
                          {moment(d.login_time).format("hh:mm:ss A")}
                        </p>
                      </div>
                      <div className="mt-3 device_sessions_mobile">
                        {Number(d?.session_id) ===
                          Number(localStorageSessionId?.session_id) && (
                          <div
                            className="d-flex align-items-center"
                            style={{
                              color: "#11ce8c",
                              borderRadius: "20px",
                              padding: "5px 10px",
                              width: "120px",
                            }}
                          >
                            Current Device
                          </div>
                        )}
                        {Number(d?.session_id) !==
                          Number(localStorageSessionId?.session_id) && (
                          <div>
                            <Button
                              className="btn small-btn mt-0"
                              title="Sign Out"
                              onClick={async () => {
                                await manageLoginSession(params);
                                await getProfileData();
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div>
            <p
              className="cursor-pointer"
              style={{
                width: "fit-content",
                fontWeight: 400,
              }}
              onClick={() => setOpenQuery(!openQuery)}
            >
              For any assistance, please contact your Service Manager or raise a
              query{" "}
              <span
                className="text-decoration-underline"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                here
              </span>
            </p>
            {openQuery && (
              <div className="adminForm">
                <textarea
                  rows="2"
                  wrap="soft"
                  placeholder="Enter Your Query"
                  className="from-teaxtarea"
                  name="remarks"
                  value={raiseQuery}
                  onChange={(e) => setRaiseQuery(e.target.value)}
                ></textarea>
                <Button
                  className="small-btn theme-btn mt-0"
                  title="Submit"
                  onClick={handleCreateQuery}
                />
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const AppContextProvider = ({ children }) => {
  const { setInitialValues, values, bindField } = useForm({
    validations: {},
  });
  // SPINNER STATE
  const [spinner, setSpinner] = useState(false);
  const [dark_mode, setdark_mode] = useState();
  const [sidebarShow, setSidebarShow] = useState(false);
  const [formatedQuestions, setFormatedQuestion] = useState([]);
  const [index, setIndex] = useState(0);
  const [marksCount, setMarksCount] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [showUpdateProfileModal, setShowUpdateProfileModal] = useState(false);
  const [showUpdateProfileImageModal, setShowUpdateProfileImageModal] =
    useState(false);
  const [userData, setUserData] = useState({});
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [notificationData, setNotificationData] = useState([]);
  const [modalPopUpShow, setModalPopUpShow] = useState(false);
  const [intervalTime, setIntervalTime] = useState();
  const [showNotificationPopUp, setShowNotificationPopUp] = useState(false);
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const [historyFromDate, setHistoryFromDate] = useState(new Date());
  const [historyToDate, setHistoryToDate] = useState(new Date());
  const [msgContent, setMsgContent] = useState([]);
  const [settings, setSettings] = useState();
  const [threadInfo, setthreadInfo] = useState();
  const [seconds, setSeconds] = useState(0);
  const [initialTimeSub, setInitialTimeSub] = useState(true);
  // Create Schedule State for preserved date for api call
  const [scheduleDatePicker, setScheduleDatePicker] = useState("");
  // Create globat state for opening/closing of sidebar
  const [isSidebarHide, setIsSidebarHide] = useState(false);
  const [organizationDetails, setOrganizationDetails] = useState({});
  const [loginTime, setLoginTime] = useState(null);
  const [showImage, setShowImage] = useState();

  // Get student data when the student login for old psis button
  const [studentInfo, setStudentInfo] = useState({});

  // TOASTER STATE
  const [toaster, setToaster] = useState({
    show: false,
    type: "", // type should be "danger" or "success"
    content: "",
  });

  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [activePage, setActivePage] = useState(null);
  const [searchLiveRecordings, setSearchLiveRecordings] = useState("");
  const [searchContent, setSearchContent] = useState("");
  const [selectResourceType, setSelectResourceType] = useState(0);
  const [activePageSearch, setActivePageSearch] = useState(1);
  const [deviceSessionsData, setDeviceSessionsData] = useState([]);
  const [sessionsModal, setSessionsModal] = useState(false);
  const [sessionsCount, setSessionsCount] = useState(0);
  const [openQuery, setOpenQuery] = useState(false);
  const [raiseQuery, setRaiseQuery] = useState("");

  const [otp, setOtp] = useState({
    modal: false,
    type: null,
    value: null,
    error: null,
  });

  const [isEdit, setIsEdit] = useState(true);

  // Manage Login Session Data
  const { getData: manageLoginSession } = useManageLoginSession(
    setSpinner,
    setToaster
  );

  // Create Query
  const { getData: createQuery } = useCreateQuery(setSpinner, setToaster);

  const { getRedirection } = useRedirection();

  const { getData: updateProfilePhoto } = useUpdateProfilePhoto(
    setSpinner,
    setToaster
  );

  const { getData: sendOtpCode } = useSendOtpCode(setSpinner, setToaster);

  // Verification OTP
  const { getData: getOtpVerification } = useOtpVerfication(
    setSpinner,
    setToaster
  );

  const { getData: updateProfileData } = useUpdateProfile(
    setSpinner,
    setToaster
  );

  const createQueryHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("section_id", 5);
    formData.append("sub_section_id", 88);
    formData.append("query", raiseQuery);
    await createQuery(formData);
    setRaiseQuery("");
  };

  const handleImage = (e) => {
    const getImage = URL.createObjectURL(e.target.files[0]);
    setInitialValues((prev) => ({
      ...prev,
      image: URL.createObjectURL(e.target.files[0]),
    }));
    setShowImage(e.target.files[0]);
  };

  // Update Profile Photo Callback
  const updateProfilePhotoCallback = async (profilePhoto) => {
    await getProfileData();
    setUserData({ ...userData, image: URL.createObjectURL(showImage) });
    setShowNotificationPopUp(true);
    setShowUpdateProfileModal(false);
    setSpinner(false);
    setToaster({
      show: true,
      type: "success",
      content: profilePhoto?.message,
    });
    setInitialValues({ image: "" });
  };

  const updateProfilePhotoHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData?.append("image", showImage);
    formData?.append("enrollment_id", Auth.user()?.user_id);
    formData?.append("student_id", Auth.user()?.id);
    await updateProfilePhoto(formData, updateProfilePhotoCallback);
  };

  // ************************Get Profile Data******************************
  const getProfileData = async () => {
    // setSpinner(true);
    try {
      const response = await userService.getProfileData();
      if (response?.status >= 200 && response?.status <= 299) {
        const data = await response.json();
        const studentData = data?.data;
        setUserData({
          ...data?.data,
          parent_email: studentData?.parent?.email,
          parent_phone: studentData?.parent?.phone,
          first_name: studentData?.name?.split(" ")[0],
          last_name: studentData?.name?.split(" ")[1],
          course_ids: studentData?.student_course_ids,
          batch_ids: studentData?.batches.map((d) => d.id),
          sessionData: studentData?.sessions_data,
        });
        setOrganizationDetails(
          studentData?.batches[0]?.parent_batch?.parent_batch
            ?.organization_details
        );
        const sessionIds = studentData?.sessions_data?.map(
          ({ session_id }) => session_id
        );
        const storedSessionId = JSON.parse(
          localStorage.getItem("studentSessionDevices")
        )?.session_id;

        if (studentData?.is_active === 0) {
          localStorage.clear();
          window.location.href = getRedirection(userData.org_id);
          setUserData({});
        } else {
          if (
            ([276, 278].includes(studentData?.org_id) &&
              studentData.is_phone_verified === 0) ||
            ([276, 278].includes(studentData?.org_id) &&
              studentData.is_email_verified === 0)
          ) {
            setInitialValues({
              first_name: studentData?.name?.split(" ")[0],
              last_name: studentData?.name?.split(" ")[1],
              user_id: studentData?.user_id,
              email: studentData?.email,
              phone: studentData?.phone,
              is_phone_verified: studentData?.is_phone_verified,
              is_email_verified: studentData?.is_email_verified,
            });
            setShowImage(studentData?.image);
            setShowUpdateProfileModal(true);
          } else {
            setShowUpdateProfileModal(false);
          }
          if (studentData?.sessions_data?.length > 2) {
            setSessionsModal(true);
            setSessionsCount(data?.data?.sessions_data?.length);
            setDeviceSessionsData(studentData?.sessions_data);
          } else {
            if (studentData?.sessions_data.length <= 2) {
              setSessionsModal(false);
            }
            if (storedSessionId) {
              if (!sessionIds.includes(storedSessionId)) {
                getRedirection(userData?.org_id);
                localStorage.removeItem("_prathamStudentToken");
                localStorage.removeItem("studentSessionDevices");
              }
            }
            if (
              typeof studentData?.image === "string" &&
              studentData?.image.toLowerCase() === "null"
            ) {
              setShowUpdateProfileImageModal(true);
            } else {
              setShowUpdateProfileImageModal(false);
            }
          }
          // setSpinner(false);
        }
      }
      if (response?.status === 401) {
        // localStorage.removeItem(CONSTANT.LOCAL_STORAGE.PRATHAM_STUDENT_TOKEN);
        // setSpinner(false);s
      }
      if (response?.status >= 400 && response?.status <= 499) {
        const data = await response.json();
        setToaster({ show: true, type: "danger", content: data.message });
      }
    } catch (error) {
      // setSpinner(false);
    }
  };

  const handleSendOtp = async (otpType) => {
    const params = {
      ...(otpType === 0 && { email: values?.email }),
      ...(otpType === 1 && { user_id: values?.user_id }),
    };
    await sendOtpCode(params);
  };

  const handleVerificationOtp = async () => {
    const params = {
      ...(otp.type === 0 && { email: values.email }),
      ...(otp.type === 1 && { user_id: values.user_id }),
      otp: otp.value,
    };
    await getOtpVerification(params);
    await getProfileData();
    setOtp((prev) => ({ ...prev, modal: false, value: null, type: null }));
  };

  const updateProfile = async () => {
    const params = {
      id: Auth?.user()?.id,
      body: {
        user_id: values?.user_id,
        email: values?.email,
        phone: values?.phone,
        name: values.first_name + " " + values.last_name,
      },
    };
    await updateProfileData(params, (data) => {
      const { success, message } = data;
      if (success) {
        setToaster({ show: true, type: "success", content: message });
        setIsEdit(true);
      }
    });
  };

  // useEffect(() => {
  //   if (userData.is_active === 0) {
  //     localStorage.clear();
  //     window.location.href = [276, 278].includes(userData.org_id)
  //       ? "https://primaryolympiad.com/"
  //       : process.env.REACT_APP_PRATHAM_BASE_URL;
  //   }
  // }, [userData]);
  return (
    <AppContext.Provider
      value={{
        spinner,
        setSpinner,
        toaster,
        setToaster,
        setSidebarShow,
        sidebarShow,
        dark_mode,
        setdark_mode,
        setFormatedQuestion,
        formatedQuestions,
        setIndex,
        index,
        setMarksCount,
        marksCount,
        modalShow,
        setModalShow,
        showUpdateProfileModal,
        setShowUpdateProfileModal,
        userData,
        setUserData,
        getProfileData,
        calendarDate,
        setCalendarDate,
        notificationData,
        setNotificationData,
        modalPopUpShow,
        setModalPopUpShow,
        intervalTime,
        setIntervalTime,
        showNotificationPopUp,
        setShowNotificationPopUp,
        historyFromDate,
        setHistoryFromDate,
        historyToDate,
        setHistoryToDate,
        msgContent,
        setMsgContent,
        settings,
        setSettings,
        threadInfo,
        setthreadInfo,
        seconds,
        setSeconds,
        initialTimeSub,
        setInitialTimeSub,
        scheduleDatePicker,
        setScheduleDatePicker,
        isSidebarHide,
        setIsSidebarHide,
        setStudentInfo,
        studentInfo,
        organizationDetails,
        selectedEndDate,
        selectedStartDate,
        setSelectedStartDate,
        setSelectedEndDate,
        setActivePage,
        activePage,
        searchLiveRecordings,
        setSearchLiveRecordings,
        searchContent,
        setSearchContent,
        selectResourceType,
        setSelectResourceType,
        activePageSearch,
        setActivePageSearch,
        setIsOpenNotification,
        isOpenNotification,
        setLoginTime,
        setShowUpdateProfileImageModal,
        showUpdateProfileImageModal,
        setShowImage,
        showImage,
      }}
    >
      {children}

      <UpdateProfileImageModal
        handleImage={handleImage}
        showImage={showImage}
        show={showUpdateProfileImageModal}
        profilePicModalClose={setShowUpdateProfileImageModal}
        updateProfilePhoto={updateProfilePhotoHandler}
        image={
          values?.image !== "NULL" && values?.image !== null
            ? values.image
            : userImg
        }
      />
      <UpdateProfileModal
        otp={otp}
        edit={isEdit}
        setOtp={setOtp}
        setEdit={setIsEdit}
        values={values}
        userData={userData}
        bindField={bindField}
        handleSendOtp={handleSendOtp}
        updateProfileData={updateProfile}
        setShowUpdateProfileModal={setShowUpdateProfileModal}
        showUpdateProfileModal={showUpdateProfileModal}
        setShowNotificationPopUp={setShowNotificationPopUp}
        handleVerificationOtp={handleVerificationOtp}
      />
      <SessionModal
        isShow={sessionsModal}
        devicesData={deviceSessionsData}
        manageLoginSession={manageLoginSession}
        userData={userData}
        getProfileData={getProfileData}
        setSessionsModal={setSessionsModal}
        openQuery={openQuery}
        setOpenQuery={setOpenQuery}
        raiseQuery={raiseQuery}
        setRaiseQuery={setRaiseQuery}
        handleCreateQuery={createQueryHandler}
      />
    </AppContext.Provider>
  );
};
