import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFolderDetails, useOMRFileUpload } from "../../../hooks/hookList";
import { AppContext } from "../../shared/AppContext";
import { NewBackButton } from "../../utils/backBtn/NewBackButton";
import { pdfjs } from "react-pdf/dist/esm/entry.webpack";
import FolderDownUp from "../../../assets/images/folder-arrow-down.png";
import FolderDownArrow from "../../../assets/images/folder-new-arrow-down.png";
import RightSidebar from "../portalLayout/RightSidebar";
import DocumentCourseIcon from "../../../assets/images/document-course-icon.png";
import CourseResourceCard from "./CourseResourceCard";
import VideoIcon from "../../../assets/images/search-video.png";
import ExamIcon from "../../../assets/images/online-exam.png";
import Search from "../../utils/search/Search";
import "./folderDetails.scss";
import Image from "../../utils/image/Image";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FolderDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { courseId, folderId } = params;
  const { setSpinner, setToaster } = useContext(AppContext);
  const [courseName, setCourseName] = useState("");
  const [documentData, setDocumentData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [examData, setExamData] = useState([]);
  const [openFolder, setOpenFolder] = useState([]);
  const [level, setLevel] = useState();
  const [examBackCard, setExamBackCard] = useState(false);
  const [searchContent, setSearchContent] = useState("");
  const [filterSearchContent, setFilterSearchContent] = useState("");
  const [data, setData] = useState([
    {
      id: folderId,
      folderName: "",
      childFolder: [
        {
          id: "",
          name: "",
          childFolder: [
            {
              id: "",
              name: "",
            },
          ],
        },
      ],
    },
  ]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [previewFile, setPreviewFile] = useState("");
  const [isModalOpenOMR, setIsModalOpenOMR] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [studentId, setStudentId] = useState(null);

  const { getData: getCourseFolderDetails } = useFolderDetails(
    setSpinner,
    setToaster
  );

  const { getData: handleUploadOMR } = useOMRFileUpload(setSpinner, setToaster);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const callback = (data) => {
    const folderPath = data?.data?.folder_data?.file_path;
    setCourseName(folderPath);
    setData((prevData) => {
      const updatedData = prevData?.map((item) => ({
        ...item,
        folderName: folderPath,
        childFolder: data?.data?.sub_folders
          .filter(
            (d) =>
              d.folder_count > 0 ||
              d.document_count > 0 ||
              d.video_count > 0 ||
              d.exam_count > 0
          )
          .map((d) => ({
            id: d?.id,
            name: d?.name,
          })),
      }));
      return updatedData;
    });
    const documentData = data?.data?.folder_data?.document ?? [];
    const videoData = data?.data?.folder_data?.video ?? [];
    const examData = data?.data?.folder_data?.exam ?? [];

    setDocumentData(documentData);
    setVideoData(videoData);
    setExamData(examData);
  };
  const findFolderByName = (folder, targetName) => {
    if (!folder) return undefined;

    // Check if the current folder matches the target name
    if (folder.name === targetName) {
      return folder;
    }

    // If the current folder doesn't match, search recursively in its child folders
    if (folder.childFolder) {
      for (const child of folder.childFolder) {
        const foundFolder = findFolderByName(child, targetName);
        if (foundFolder) {
          return foundFolder; // Return the found folder if it exists
        }
      }
    }

    // If the folder is not found in the current folder or its children, return undefined
    return undefined;
  };

  const folderCallback = (callbackData, folderData, level) => {
    setData((prevData) => {
      const updatedData = prevData?.map((item) => {
        const activeFolder = findFolderByName(
          item,
          callbackData?.data?.folder_data?.file_path
        );

        if (activeFolder && callbackData?.data?.sub_folders) {
          const filteredSubFolders = (callbackData?.data?.sub_folders)
            .filter(
              (subFolder) =>
                subFolder.document_count > 0 ||
                subFolder.video_count > 0 ||
                subFolder.exam_count > 0 ||
                subFolder.folder_count > 0
            )
            .map((subFolder) => ({
              id: subFolder.id,
              name: subFolder.name,
            }));
          activeFolder.childFolder = filteredSubFolders;
        }

        return item;
      });

      return updatedData;
    });

    const documentData = callbackData?.data?.folder_data?.document ?? [];
    const videoData = callbackData?.data?.folder_data?.video ?? [];
    const examData = callbackData?.data?.folder_data?.exam ?? [];
    setDocumentData(documentData);
    setVideoData(videoData);
    setExamData(examData);

    const folderId = folderData.id;
    const existingIndex = openFolder.findIndex((item) => item.level === level);
    if (existingIndex !== -1) {
      // Replace the existing folder with the new one at the same level
      setOpenFolder((prev) => [
        ...prev.slice(0, existingIndex),
        { id: folderId, level: level },
        ...prev.slice(existingIndex + 1),
      ]);
    } else if (level === 0) {
      setOpenFolder([{ id: folderData.id, level: level }]);
    } else {
      // Add the new folder to the state if it's a parent folder
      setOpenFolder((prev) => [...prev, { id: folderId, level: level }]);
    }
  };
  const toggleFolders = (e, folderData, level) => {
    e.stopPropagation(); // Stop event propagation to prevent triggering parent click event
    if (folderData) {
      const params = {
        id: courseId,
        type: "folder",
        folderId: folderData.id,
      };
      getCourseFolderDetails(params, (data) =>
        folderCallback(data, folderData, level)
      );
      setLevel(level);
      setSearchContent("");
    }
  };

  // Function to normalize strings
  const normalizeString = (str) => {
    return str
      .replaceAll("-", " ")
      .replaceAll("_", " ")
      .replaceAll(/[\[\]']+/g, "")
      .replaceAll(/\s/g, "")
      .replaceAll(/[()]/g, "")
      .trim()
      .toLowerCase();
  };

  const handleSearchCourse = (e) => {
    setSearchContent(e.target.value);
    const combinedData = [...documentData, ...examData, ...videoData];

    const findMatch = combinedData.filter((d) => {
      // Check if d or e.target.value is undefined
      // if (!d?.name || !d?.title || !e?.target?.value) return false;

      const normalizedD = normalizeString(d?.name ? d?.name : d?.title);
      const normalizedValue = normalizeString(e.target.value);

      return normalizedD.includes(normalizedValue);
    });
    setFilterSearchContent(findMatch);
  };

  useEffect(() => {
    const params = {
      id: courseId,
      folderId: folderId,
      type: "folder",
    };
    if (courseId && folderId) {
      getCourseFolderDetails(params, callback);
    }
    const studentInfo = localStorage?.getItem("studentInfo");
    const parsedStudentInfo = studentInfo ? JSON?.parse(studentInfo) : null;
    setStudentId(parsedStudentInfo.id);
  }, []);

  const renderChildFolders = (childFolders, level) => {
    // Check if childFolders is not empty and it's an array
    if (
      childFolders &&
      Array.isArray(childFolders) &&
      childFolders.length > 0
    ) {
      return childFolders.map((childFolder, index) => {
        const folderId = childFolder.id;
        const isOpen = openFolder.some(
          (item) => item.id === folderId && item.level === level
        );
        return (
          <div
            className="child_folders cursor-pointer"
            style={{
              margin: level === 1 && "0 20px",
              padding:
                (level === 1 && "19px 0px") ||
                (level === 2 && "15px 0 19px 0") ||
                (level === 3 && "17px 0 21px 0"),
              borderTop: level >= 2 && index === 0 ? "1px solid #dce1f7" : "",
              borderBottom:
                index + 1 !== childFolders.length &&
                "1px solid rgba(220, 225, 247, 1)",
              background: level === 2 ? "#f1f1f1" : "",
              borderBottomRightRadius: level === 2 ? "10px" : "",
              borderBottomLeftRadius: level === 2 ? "10px" : "",
            }}
            key={folderId}
            onClick={(e) => toggleFolders(e, childFolder, level)}
          >
            <div
              className={`${"d-flex"} ${!isOpen && "justify-content-between"}`}
              style={{
                marginBottom:
                  isOpen &&
                  [1, 2, 3].includes(level) &&
                  childFolder?.childFolder?.length > 0 &&
                  "20px",
                color:
                  isOpen && childFolder?.childFolder?.length > 0
                    ? "#000"
                    : isOpen && childFolder?.childFolder?.length === 0
                    ? "rgba(254, 128, 87, 1)"
                    : "rgba(86, 86, 86, 1)",
                fontWeight: isOpen && "500",
              }}
            >
              {isOpen && childFolder?.childFolder?.length > 0 && (
                <div
                  style={{
                    marginLeft:
                      (level === 1 && "1rem") ||
                      (level === 2 && "1.5rem") ||
                      (level === 3 && "3rem"),
                  }}
                >
                  <Image src={FolderDownArrow} alt="down-arrow-icon" />
                </div>
              )}
              <span
                style={{
                  marginLeft:
                    (isOpen && level === 1 && "1rem") ||
                    (level === 2 && "1.5rem") ||
                    (level === 3 && "3.5rem"),
                }}
              >
                {childFolder?.name?.replaceAll("_", " ")}
              </span>
              {!isOpen && level === 1 && (
                <div>
                  <Image src={FolderDownArrow} alt="down-arrow-icon" />
                </div>
              )}
            </div>
            {/* Recursively render child folders if they exist and parent folder is open */}
            {isOpen &&
              childFolder.childFolder &&
              renderChildFolders(childFolder.childFolder, level + 1)}
          </div>
        );
      });
    }
    // If childFolders is empty or not an array, return null
    return null;
  };

  return (
    <div className="col-md-12 d-flex gap-4 courses">
      <div className="white-box body-cont">
        <div className="d-flex flex-wrap flex-lg-nowrap justify-content-between">
          <div className="w-100 d-flex align-items-center">
            <NewBackButton onClick={() => navigate(-1)} />
            <h1 className="exam_instruction_heading mb-0 pb-0">
              {courseName?.replaceAll("_", " ")}
            </h1>
          </div>
          {[documentData, examData, videoData]?.some((d) => d.length > 12) && (
            <div className="w-100 search_container">
              <Search
                value={searchContent}
                onChange={handleSearchCourse}
                placeholder="Search in Course Folder"
              />
            </div>
          )}
        </div>
        <div className="row resource_view">
          <div className="col-md-3">
            <div className="accordion_container">
              {data?.map((folder) => {
                return (
                  <div key={folder?.id}>
                    <div
                      className="parent_folder cursor-pointer"
                      onClick={(e) => toggleFolders(e, folder, 0)}
                    >
                      <div>{folder?.folderName?.replaceAll("_", " ")}</div>
                      <div>
                        <Image src={FolderDownUp} alt="arrow-up-icon" />
                      </div>
                    </div>
                    {folder?.childFolder?.length > 0 &&
                      renderChildFolders(folder?.childFolder, 1)}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-md-9">
            {/* {[documentData.length, videoData.length].every((e) => e === 0) && (
              <div className="d-flex justify-content-center align-items-center">
                <Image className="no_data_img" src={NoData} alt="no-data-icon" />
              </div>
            )} */}
            <div className="row resource_container ps-0">
              {!searchContent && documentData.length > 0 && (
                <div className="resource_content_container">
                  <span>{`Documents`}</span>
                </div>
              )}
              {!searchContent &&
                documentData?.length > 0 &&
                documentData
                  ?.filter((d) => d.is_active === 1)
                  ?.sort((a, b) => b?.id - a?.id)
                  ?.map((card, index) => (
                    <CourseResourceCard
                      cardData={card}
                      type="Document"
                      key={card.id}
                      icon={DocumentCourseIcon}
                      index={index}
                      navigate={navigate}
                    />
                  ))}
              {!searchContent && videoData.length > 0 && (
                <div
                  className={`resource_content_container ${
                    !searchContent && documentData?.length > 0 && "mt-5"
                  }`}
                >
                  <span>{`Videos`}</span>
                </div>
              )}
              {!searchContent &&
                videoData?.length > 0 &&
                videoData
                  ?.filter((d) => d.is_active === 1)
                  ?.sort((a, b) => b?.id - a?.id)
                  ?.map((card, index) => (
                    <CourseResourceCard
                      cardData={card}
                      type="Video"
                      key={card.id}
                      icon={VideoIcon}
                      index={index}
                      navigate={navigate}
                    />
                  ))}
              {!searchContent && examData.length > 0 && (
                <div
                  className={`resource_content_container ${
                    (!searchContent &&
                      documentData.length > 0 &&
                      "mt-5 mt-md-5 mb-5 mb-lg-0 mt-md-0") ||
                    (!searchContent &&
                      videoData.length > 0 &&
                      "mt-5 mt-md-5 mb-5 mb-lg-0 mt-md-0")
                  } `}
                >
                  <span>{`Exams`}</span>
                </div>
              )}
              {!searchContent &&
                examData?.length > 0 &&
                examData
                  ?.filter((d) => d.is_active === 1)
                  ?.sort((a, b) => b?.id - a?.id)
                  ?.map((card, index) => (
                    <CourseResourceCard
                      cardData={card}
                      type="Exam"
                      key={card.id}
                      icon={ExamIcon}
                      index={index}
                      navigate={navigate}
                      isBackCard={examBackCard}
                      setIsBackCard={setExamBackCard}
                      setSelectedFile={setSelectedFile}
                      selectedFile={selectedFile}
                      setPreviewFile={setPreviewFile}
                      previewFile={previewFile}
                      setIsModalOpen={setIsModalOpenOMR}
                      isModalOpen={isModalOpenOMR}
                      setToaster={setToaster}
                      pageNumber={pageNumber}
                      onDocumentLoadSuccess={onDocumentLoadSuccess}
                      handleUploadOMR={handleUploadOMR}
                      studentId={studentId}
                    />
                  ))}
              {searchContent &&
                filterSearchContent?.length > 0 &&
                filterSearchContent
                  ?.filter((d) => d.is_active === 1)
                  ?.map((card, index) => (
                    <CourseResourceCard
                      cardData={card}
                      type={
                        (card?.document && "Document") ||
                        (card?.video_type && "Videos") ||
                        (card?.category && "Exam")
                      }
                      key={card.id}
                      icon={ExamIcon}
                      index={index}
                      navigate={navigate}
                      isBackCard={examBackCard}
                      setIsBackCard={setExamBackCard}
                    />
                  ))}
            </div>
          </div>
        </div>
      </div>
      <div className="sidebar">
        <RightSidebar />
      </div>
    </div>
  );
};

export default FolderDetails;
