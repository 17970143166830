// QuestionComponent.jsx
import React from "react";
import "react-simple-keyboard/build/css/index.css";
import Keyboard from "react-simple-keyboard";
import NumPad from "react-numpad";

const Question = ({
  questionData,
  currentQuestionIndex,
  questionType,
  handleOptionChange,
  isOptionSelected,
  keyboardRef,
  handleOneWordAnswer,
  handleOneWordInputChange,
  handleNumericAnswer,
  numericAnswer,
}) => {
  return (
    <div className="sub-report">
      {questionData?.length > 0 && (
        <div className="question-dash">
          <div
            className={`dash-heading question-head mock_comp ${
              questionData[currentQuestionIndex]?.questions[0]
                ?.comprehension_id !== null
                ? "comp-test"
                : ""
            }`}
          >
            {questionData[currentQuestionIndex]?.questions[0]
              ?.comprehension_id !== null && (
              <div className="comp-questions">
                <h4 className="comp-questions-heading">
                  Comprehension for Questions{" "}
                </h4>

                <div className="comp-questions-cont">
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        questionData[currentQuestionIndex]?.questions[0]
                          ?.comprehension[0]?.name,
                    }}
                  ></p>
                </div>
              </div>
            )}

            <h3>Question {currentQuestionIndex + 1}</h3>
            <div className="btn question-type">
              {questionType(
                questionData[currentQuestionIndex]?.questions[0]
                  ?.question_type_id
              )}
            </div>
          </div>
          <div
            className="question exam_questions"
            dangerouslySetInnerHTML={{
              __html:
                questionData[currentQuestionIndex]?.questions[0]?.question,
            }}
          ></div>
          <div className="awns_text single_answers_type">
            <ul className="mcq-awns">
              {questionData[currentQuestionIndex]?.questions[0]
                ?.question_type_id !== 4 &&
                questionData[currentQuestionIndex]?.questions[0]
                  ?.question_type_id !== 3 &&
                questionData[currentQuestionIndex]?.questions[0]?.options?.map(
                  (awnData) => {
                    let option = (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: awnData.option,
                        }}
                      ></div>
                    );
                    return (
                      <li key={awnData.id}>
                        <div className="form-group radio-group position-relative">
                          <div className="input-box">
                            <input
                              className="d-none text-input custon-radio"
                              type={
                                questionData[currentQuestionIndex]?.questions[0]
                                  ?.question_type_id === 2 &&
                                questionData[
                                  currentQuestionIndex
                                ]?.questions[0]?.options?.filter(
                                  (a) => a?.is_correct === 1
                                )?.length > 1
                                  ? "checkbox"
                                  : "radio"
                              }
                              name={`awn_${
                                questionData[currentQuestionIndex]?.questions[0]
                                  ?.question_type_id === 2
                                  ? `checkbox_${awnData?.id}`
                                  : `radio_${questionData[currentQuestionIndex]?.id}`
                              }`}
                              value={awnData.id}
                              id={awnData.id}
                              onChange={(e) =>
                                handleOptionChange(e, awnData.question_id)
                              }
                              checked={isOptionSelected(
                                awnData.question_id,
                                awnData.id
                              )}
                            />
                            <label htmlFor={awnData.id}>{option}</label>
                          </div>
                        </div>
                      </li>
                    );
                  }
                )}
            </ul>
          </div>
          {questionData[currentQuestionIndex]?.questions[0]
            ?.question_type_id === 4 && (
            <div className="awn-keyboard">
              <input
                value={keyboardRef?.current?.getInput()}
                onChange={handleOneWordInputChange}
                placeholder={"Enter your answer"}
              />
              <Keyboard
                keyboardRef={(r) => (keyboardRef.current = r)}
                onChange={(value) => {
                  handleOneWordAnswer(
                    value,
                    questionData[currentQuestionIndex]?.questions[0]?.options[0]
                      ?.question_id
                  );
                }}
                value={keyboardRef?.current?.getInput()}
              />
            </div>
          )}
          {questionData[currentQuestionIndex]?.questions[0]
            ?.question_type_id === 3 && (
            <div className="numeric-keyboard">
              <NumPad.Number
                onChange={(value) =>
                  handleNumericAnswer(
                    value,
                    questionData[currentQuestionIndex]?.questions[0]?.options[0]
                      ?.question_id
                  )
                }
                label="Total"
                placeholder="Enter Your Answer"
                decimal={2}
                value={numericAnswer ? numericAnswer : ""}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Question;
