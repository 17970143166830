import React from "react";
import NumericKeypad from "./NumericKeypad";

const NumericAnswerTypeQuestion = ({
  isNumericAnswered,
  handleNumericAnswer,
}) => {
  return (
    <div className="numeric_input_field_container">
      <input
        className="w-100 numeric_input_field"
        type="text"
        value={isNumericAnswered}
        readOnly
        placeholder="Enter Your Answer"
      />
      <NumericKeypad onKeyPress={handleNumericAnswer} />
    </div>
  );
};

export default NumericAnswerTypeQuestion;
