import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import FormCategoryTable from "./FormCategoryTable";
import FormCategoryDetails from "./FormCategoryDetails";

const FormCategoryTabs = ({
  tabs,
  onSelect,
  activeKey,
  tabName,
  categoryTableForm,
  categoryFormDetails,
}) => {
  return (
    <div className="exam-tabs">
      <Tabs
        id="uncontrolled-tab-example"
        onSelect={onSelect}
        activeKey={activeKey}
        className="mb-3 forms-tabs"
      >
        {tabs?.length > 0 &&
          tabs.map((tab) => {
            return (
              <Tab
                eventKey={tab.category_id}
                title={tab.category_name}
                key={tab.category_id}
              ></Tab>
            );
          })}
      </Tabs>
      <div className="tab-body">
        <div className="dash-heading">
          <h1 className="my-5 text-center">{tabName}</h1>
        </div>
        <div className="gray-bg rounded p-5">
          <FormCategoryTable categoryTableForm={categoryTableForm} />

          <FormCategoryDetails categoryFormDetails={categoryFormDetails} />
        </div>
      </div>
    </div>
  );
};

export default FormCategoryTabs;
