import React from "react";

const SubjectiveAnswerTypeQuestion = ({
  handleSubjectiveAnswer,
  questionData,
  currentQuestionIndex,
  isSubjectiveAnswered,
}) => {
  return (
    <div>
      <textarea
        onChange={(e) =>
          handleSubjectiveAnswer(
            e?.target?.value,
            questionData[currentQuestionIndex]?.options[0]?.question_id
          )
        }
        value={isSubjectiveAnswered}
        className="subjective_answer_input"
        placeholder="Type Your Answer here"
      />
    </div>
  );
};

export default SubjectiveAnswerTypeQuestion;
