import React, { useContext, useEffect, useState } from "react";
import { NewBackButton } from "../../utils/backBtn/NewBackButton";
import { AppContext } from "../../shared/AppContext";
import {
  useOMRFileUpload,
  useResearchTypeSearch,
} from "../../../hooks/hookList";
import SearchCard from "./SearchCard";
import SearchFilter from "./SearchFilter";
import NoData from "../../../assets/images/no-data.png";
import Pagination from "react-js-pagination";
import SearchBar from "../../utils/search/Search";
import { useBreadcrumb } from "../../shared/breadcrumb-context";
import { pdfjs } from "react-pdf/dist/esm/entry.webpack";
import Image from "../../utils/image/Image";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const SearchResource = () => {
  const {
    setSpinner,
    setToaster,
    searchContent,
    setSearchContent,
    selectResourceType,
    setSelectResourceType,
    activePageSearch,
    setActivePageSearch,
  } = useContext(AppContext);

  const [resultData, setResultData] = useState({
    from: "",
    to: "",
    total: "",
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [previewFile, setPreviewFile] = useState("");
  const [isModalOpenOMR, setIsModalOpenOMR] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [studentId, setStudentId] = useState(null);

  const { dispatch } = useBreadcrumb();

  const { getData: getResourceTypeSearch, data: resourceData } =
    useResearchTypeSearch(setSpinner, setToaster);

  const { getData: handleUploadOMR } = useOMRFileUpload(setSpinner, setToaster);

  const resourceTypeCallBack = (data) => {
    setActivePageSearch(data.data.current_page);
    setResultData({
      from: data?.data?.from,
      to: data?.data?.to,
      total: data?.data?.total,
    });
  };

  const onChangeSearch = (e) => {
    setSearchContent(e.target.value);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const onChangePage = async (e) => {
    const params = {
      page: e,
      body: {
        resource_type_id: selectResourceType,
        search_keyword: searchContent,
      },
    };
    setActivePageSearch(e);
    await getResourceTypeSearch(params, resourceTypeCallBack);
  };

  const onClickSearch = async (e) => {
    e.preventDefault();
    if (![1, 2, 3, 4, 5].includes(Number(selectResourceType))) {
      setToaster({
        show: true,
        type: "danger",
        content: "Please select the content type",
      });
    } else {
      const params = {
        page: 1,
        body: {
          resource_type_id: selectResourceType,
          search_keyword: searchContent,
        },
      };
      await getResourceTypeSearch(params, resourceTypeCallBack);
    }
  };

  useEffect(() => {
    if (searchContent !== "") {
      const params = {
        page: activePageSearch,
        body: {
          resource_type_id: selectResourceType,
          search_keyword: searchContent,
        },
      };
      getResourceTypeSearch(params, resourceTypeCallBack);
    }
    dispatch({
      type: "SET_BREADCRUMB",
      crumb: [{ title: "Search Content", url: "/search" }],
    });
    const studentInfo = localStorage?.getItem("studentInfo");
    const parsedStudentInfo = studentInfo ? JSON?.parse(studentInfo) : null;
    setStudentId(parsedStudentInfo.id);
  }, []);

  return (
    <div className="col-md-12">
      <div className="row">
        <div className="white-box body-cont" style={{ padding: "30px" }}>
          <div className="w-100 d-flex align-items-center">
            <NewBackButton />
            <h1 className="mb-0 exam_instruction_heading">Search Content</h1>
          </div>
          <div className="w-100 mt-4 search_container_resource">
            <SearchBar
              value={searchContent}
              onChange={onChangeSearch}
              placeholder="Search for example QA"
            />
            <div className="w-100 w-lg-40 d-flex right_side_container">
              <div className="w-100 w-md-60">
                <SearchFilter
                  setResourceType={setSelectResourceType}
                  value={selectResourceType}
                />
              </div>
              <div className="w-100 w-md-40 search_btn_container">
                <button className="search_btn" onClick={onClickSearch}>
                  Search
                </button>
              </div>
            </div>
          </div>
          {resourceData?.data && resourceData?.data?.length > 0 && (
            <div>
              <div className="row" style={{ marginTop: "2rem" }}>
                <div className="top_results">
                  Top {resultData?.from} - {resultData?.to} out of{" "}
                  {resultData?.total} results are found
                </div>
                <div className="card_container">
                  <div className="row">
                    {resourceData?.data?.map((card, index) => (
                      <SearchCard
                        key={index}
                        cardData={card}
                        setSelectedFile={setSelectedFile}
                        selectedFile={selectedFile}
                        setPreviewFile={setPreviewFile}
                        previewFile={previewFile}
                        setIsModalOpen={setIsModalOpenOMR}
                        isModalOpen={isModalOpenOMR}
                        setToaster={setToaster}
                        pageNumber={pageNumber}
                        onDocumentLoadSuccess={onDocumentLoadSuccess}
                        handleUploadOMR={handleUploadOMR}
                        studentId={studentId}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="search_btn_container d-flex justify-content-center">
                  <Pagination
                    pageRangeDisplayed={5}
                    itemsCountPerPage={10}
                    activePage={activePageSearch}
                    totalItemsCount={resultData.total}
                    onChange={onChangePage}
                  />
                </div>
              </div>
            </div>
          )}
          {resourceData?.data && resourceData.data.length === 0 && (
            <div className="d-flex justify-content-center my-5">
              <Image
                src={NoData}
                alt="no-data"
                style={{ width: "200px", height: "200px" }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchResource;
