import React from "react";

const QuestionContainer = ({ questionNo, question }) => {
  return (
    <>
      <div className="dash-heading ">
        <h4>
          Question <span>{questionNo}</span>:
        </h4>
      </div>
      <span
        className="question"
        dangerouslySetInnerHTML={{
          __html: question,
        }}
      ></span>
    </>
  );
};

export default QuestionContainer;
