import React from "react";
import Button from "./Button";
import Image from "../image/Image";

function InputField({
  type,
  value,
  placeholder,
  className,
  name,
  label,
  htmlFor,
  iconData,
  id,
  onChange,
  checked,
  bindField,
  disable,
  minlength,
  accept,
  maxLength,
  onKeyPress,
  isPassword,
  setShowHidePasswordToggle,
  showHidePassword,
  required,
  inputButtonData,
  smallLabel,
}) {
  return (
    <div className="form-group radio-group position-relative">
      <div className="input-box">
        {smallLabel && <span className="small-label">{smallLabel}</span>}
        <input
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
          className={`text-input ${className ? className : ""}`}
          value={value}
          maxLength={maxLength}
          {...bindField(name)}
          // onChange={onChange}
          {...(onChange
            ? {
                onChange: () => {
                  onChange();
                },
              }
            : "")}
          disabled={disable}
          minLength={minlength}
          accept={accept}
          checked={checked}
          onKeyPress={onKeyPress}
          autoComplete="off"
          autoCapitalize="off"
        />
        {/* <Image src={hidePass} alt="eye image" />
        <Image src={showPass} alt="eye image" /> */}
        {label && (
          <label htmlFor={id}>
            {label}
            {required ? <span className="pink-text"> *</span> : ""}
          </label>
        )}
        {iconData?.isLock && (
          <span
            className={`position-absolute ${
              iconData?.className ? iconData?.className : ""
            }`}
          >
            <Image
              src={iconData?.src}
              alt={iconData?.alt}
              className={`input-icon ${className ? className : ""}`}
              onClick={setShowHidePasswordToggle}
            />
          </span>
        )}
        {inputButtonData?.isButton && (
          <span
            className={`position-absolute ${
              inputButtonData?.className ? inputButtonData?.className : ""
            }`}
            onClick={inputButtonData?.onClick}
          >
            <Button
              className="proceed  small-btn mt-0"
              title={inputButtonData?.title}
            />
          </span>
        )}
      </div>
    </div>
  );
}
export default InputField;
