import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../shared/AppContext";
import { userService } from "../../../_services/User.services";
import { useParams } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { Modal } from "react-bootstrap";
import Pagination from "react-js-pagination";
import Table from "../../utils/table/Table";
import RightSidebar from "../portalLayout/RightSidebar";
import Button from "../../utils/forms/Button";
import MockTestButton from "./MockTestButton";
import Image from "../../utils/image/Image";
import {
  convertIsoToOrdinalDate,
  convertMinutesToHours,
} from "../../utils/dateTime";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const ExamCategory = () => {
  const { exam_category_id } = useParams();

  const { setSpinner, setToaster } = useContext(AppContext);
  const [latestExamTable, setLatestExamTable] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewFile, setPreviewFile] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [studentId, setStudentId] = useState(null);

  // pagination
  const [total, setTotal] = useState();
  const [activePage, setActivePage] = useState(1);

  const onPageChange = (e) => {
    let pageNo = e;
    setActivePage(pageNo);
    latestExamData(pageNo);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  // api
  const latestExamData = async (pageNo) => {
    let currentPage = pageNo === undefined ? 1 : pageNo;
    setSpinner(true);

    try {
      const response = await userService.latestExamData({
        exam_category_id: exam_category_id,
        page: currentPage,
        size: 10,
      });

      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setSpinner(false);
        setLatestExamTable(
          Object.values(data?.data?.exam?.data)
            ?.filter((a) => a?.name)
            ?.map((item) => {
              return [
                { value: item.name, id: 1 },
                { value: item.category?.name, id: 2 },
                {
                  value: convertMinutesToHours(item.duration),
                },
                {
                  id: 4,
                  value: (
                    <>
                      {item?.attempted_on ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: convertIsoToOrdinalDate(item.attempted_on),
                          }}
                        />
                      ) : (
                        <span className="text-center d-block w-00">-</span>
                      )}
                    </>
                  ),
                },
                {
                  id: 5,
                  value:
                    (item?.score === 0 || item?.score !== null) &&
                    new Date(item?.result_date) <= new Date()
                      ? `${item?.score}
                /${item?.exam_template_structure?.reduce(
                  (acc, curr) =>
                    acc +
                    Number(curr?.correct_marks) * Number(curr?.total_questions),
                  0
                )}`
                      : 0,
                },
                {
                  id: 6,
                  value:
                    (item?.rank || item?.rank === 0 || item?.rank !== null) &&
                    item?.total_test_students &&
                    new Date(item?.result_date) <= new Date()
                      ? `${item?.rank}/${item?.total_test_students}`
                      : 0,
                },
                {
                  id: 7,
                  value: (
                    <MockTestButton
                      status={item.status}
                      id={item?.id}
                      startDate={item.start_date}
                      endDate={item?.end_date}
                      resultDate={item?.result_date}
                      examRetake={item?.exam_retakes}
                      examTemplateId={item.exam_template_id}
                      examStatus={item?.exam_status}
                      categoryExamName={item?.category?.name?.toLowerCase()}
                      categoryExamRetake={item?.category?.exam_retake}
                      subjectName={item?.name}
                      onChangeOMR={(e) => handleFileChange(e)}
                      onClickOMR={() => {
                        document.getElementById("file_upload").click();
                        localStorage.setItem("exam_id", item?.id);
                      }}
                      totalQuestions={item?.total_questions}
                      examName={item?.name}
                      questionLimit={Number(
                        item?.exam_template_structure[0]?.limit
                      )}
                      examTemplateUIId={item?.category.template.id}
                    />
                  ),
                },
              ];
            })
        );
        setTotal(data?.data?.exam.total);
      }
      if (response?.status === 401) {
        localStorage.removeItem("_prathamStudentToken");
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setSpinner(false);
        setToaster({
          show: true,
          type: "danger",
          content: data.message,
        });
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  // Handling OMR Upload Image Or PDF
  const handleFileChange = (event) => {
    const acceptedFileTypesRegex = /\.(jpg|jpeg|png|pdf)$/i;

    // Check the file length should be greater than 0
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      // Check the file type should image or pdf
      if (file && acceptedFileTypesRegex.test(file?.name)) {
        // Create a File reader
        const reader = new FileReader();
        reader.onload = () => {
          setPreviewFile(reader.result);
        };
        reader.readAsDataURL(file);
        setIsModalOpen(true);
      } else {
        setToaster({
          show: true,
          type: "danger",
          content: "File type should be only image or pdf",
        });
      }
    }
  };
  // Handling OMR Image or PDF Submit API
  const handleUpload = async (event) => {
    event.preventDefault();
    setSpinner(true);
    try {
      const formData = new FormData();
      formData.append(
        "exam_id",
        typeof window !== undefined
          ? parseInt(window?.localStorage.getItem("exam_id"))
          : ""
      );
      formData.append("student_id", studentId);
      formData.append("omr_attachment", selectedFile);

      const response = await userService.uploadOMRExam(formData);

      // Handle API Response
      if (response.status >= 200 && response.status <= 299) {
        //call an api again when OMR upload successfully & changed the button
        latestExamData();

        // Show Message when OMR upload successfully
        setToaster({
          show: true,
          type: "success",
          content: "File is uploaded successfully",
        });
        setSpinner(false);

        //File state reset when omr upload successfully

        // Preview state reset when omr upload successfully
        setPreviewFile("");

        // Modal Open for the preview of uploaded OMR
        setIsModalOpen(false);

        setSelectedFile(null);
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        const errorMessages = Object.values(data.messages).flat().join(", ");

        // Show error toast
        setToaster({ show: true, type: "danger", content: errorMessages });

        // File state reset due to an error
        setSelectedFile(null);

        // File Preview state reset due to an error
        setPreviewFile("");

        setSpinner(false);

        // File Preview modal close due to an error
        setIsModalOpen(false);
      }
    } catch (error) {
      setSpinner(false);
      setToaster({ show: true, type: "danger", content: error.message });
    }
  };

  useEffect(() => {
    latestExamData();
    if (typeof window !== undefined) {
      const studentInfo = window?.localStorage.getItem("studentInfo");
      const parsedStudentInfo = studentInfo ? JSON?.parse(studentInfo) : null;
      setStudentId(parsedStudentInfo.id);
    }
    //eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="col-md-12 d-flex gap-3">
        <div className="white-box body-cont">
          <div className="sub-report ">
            <Table
              heading={[
                { value: "Name", id: 1, className: "title-data" },
                { value: "Exam Category", id: 2, className: "w-16" },
                { value: "Duration", id: 3, className: "w-10" },
                { value: "Attempted On", id: 4, className: "w-16" },
                { value: "Score", id: 5, className: "w-10" },
                { value: "Rank", id: 6, className: "w-10" },
                {
                  value: "Status",
                  id: 7,
                },
              ]}
              rows={latestExamTable}
            />
          </div>
          {total >= 10 && (
            <Pagination
              hideFirstLastPages={true}
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={total}
              pageRangeDisplayed={5}
              onChange={(e) => onPageChange(e)}
            />
          )}
        </div>
        <div>
          <Modal
            size="lg"
            show={isModalOpen}
            centered
            onHide={() => setIsModalOpen(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: "20px" }}>
                File Preview
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={handleUpload}>
                {previewFile.includes("data:image") && (
                  <div className="d-flex justify-content-center mt-5">
                    <Image
                      width="95%"
                      height="95%"
                      src={previewFile}
                      alt="preview-file-image"
                    />
                  </div>
                )}
                {previewFile.includes("data:application/pdf") && (
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12 d-flex">
                        <div
                          className="doc-viewer"
                          style={{ overflow: "hidden" }}
                        >
                          <Document
                            file={"data:application/pdf;" + previewFile}
                            renderMode="canvas"
                            onLoadSuccess={onDocumentLoadSuccess}
                          >
                            <Page
                              height={100}
                              width={1000}
                              pageNumber={pageNumber}
                            />
                          </Document>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="d-flex justify-content-center pb-5">
                  <Button
                    className="btn theme-btn dark-blue mt-5 w-25"
                    disabled={!selectedFile}
                    title="Submit"
                  />
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </div>
        <div className="sidebar">
          <RightSidebar />
        </div>
      </div>
    </>
  );
};

export default ExamCategory;
