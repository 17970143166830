import React from "react";

const ComprehensionContainer = ({
  questionData,
  currentQuestionIndex,
  questionType,
}) => {
  return (
    <>
      <div
        className={`dash-heading question-head mock_comp ${
          questionData[currentQuestionIndex]?.comprehension_id !== null
            ? "comp-test"
            : ""
        }`}
      >
        {questionData[currentQuestionIndex]?.comprehension_id !== null && (
          <div className="comp-questions ">
            <h4 className="comp-questions-heading">
              Comprehension for Questions{" "}
            </h4>

            <div className="comp-questions-cont">
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    questionData[currentQuestionIndex]?.comprehension[0]?.name,
                }}
              ></p>
            </div>
          </div>
        )}

        <h3 className="question_count">Question {currentQuestionIndex + 1}</h3>
        <div className="btn question-type">
          {questionType(questionData[currentQuestionIndex]?.question_type_id)}
        </div>
      </div>
      <div
        className="question exam_questions"
        dangerouslySetInnerHTML={{
          __html: questionData[currentQuestionIndex]?.question,
        }}
      ></div>
    </>
  );
};

export default ComprehensionContainer;
