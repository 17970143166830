import React, { useContext, useEffect, useState, useMemo } from "react";
import { AppContext } from "../../shared/AppContext";
import { useStudentPerformanceReport } from "../../../hooks/hookList";
import Table from "../../utils/table/Table";
import CloseIcon from "../../../assets/images/cross.svg";
import CommonModal from "../../utils/modal/CommonModal";
import Button from "../../utils/forms/Button";
import "./studentPerformance.scss";
import Image from "../../utils/image/Image";

const StudentPerformance = () => {
  const {setSpinner, setToaster } = useContext(AppContext);
  const { getData: getStudentPerformanceReport, data: studentPerformance } =
    useStudentPerformanceReport(setSpinner, setToaster);

  const [subjectWiseReport, setSubjectWiseReport] = useState([]);
  const [activeSubject, setActiveSubject] = useState();
  const [topicLists, setTopicLists] = useState([]);
  const [typeFilter, setTypeFilter] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mobileFilter, setMobileFilter] = useState({
    type: "",
    label: "",
    value: "",
  });

  const activeSubjectName = subjectWiseReport?.find(
    (subject) => subject?.subject_id === activeSubject
  );

  const radioOptions = [
    {
      label: "Topic",
      id: "topic",
      name: "topic_name",
    },
    {
      label: "Total Questions",
      id: "total_questions",
      name: "total_questions",
    },
    {
      label: "Total Attempted",
      id: "total_attempted",
      name: "total_attempted",
    },
    {
      label: "Correctly Attempted",
      id: "correctly_attempted",
      name: "correctly_attempted",
    },
    {
      label: "Time/Ques",
      id: "time_per_question",
      name: "time_per_question",
    },
    {
      label: "Accuracy",
      id: "accuracy",
      name: "accuracy",
    },
    {
      label: "Correct To Total",
      id: "correct_to_total",
      name: "confidence",
    },
  ];

  const tableHeading = [
    { id: 1, value: "#", className: "w-5" },
    {
      id: 2,
      value: "Topic",
      className: "w-20 text-base",
      isFilter: true,
      onClickDescending: () => handleSort("topic_name", "desc"),
      onClickAscending: () => handleSort("topic_name", "asc"),
    },
    {
      id: 3,
      value: "Total Ques",
      className: "w-5",
      isFilter: true,
      onClickDescending: () => handleSort("topic_questions", "desc"),
      onClickAscending: () => handleSort("topic_questions", "asc"),
    },
    {
      id: 4,
      value: "Attempt",
      isFilter: true,
      className: "w-5",
      onClickDescending: () => handleSort("total_attempted", "desc"),
      onClickAscending: () => handleSort("total_attempted", "asc"),
    },
    {
      id: 5,
      value: "Correct",
      isFilter: true,
      className: "w-5",
      onClickDescending: () => handleSort("correctly_attempted", "desc"),
      onClickAscending: () => handleSort("correctly_attempted", "asc"),
    },
    {
      id: 6,
      value: "Time/Ques",
      isFilter: true,
      className: "w-5",

      onClickDescending: () => handleSort("time_per_question", "desc"),
      onClickAscending: () => handleSort("time_per_question", "asc"),
    },
    {
      id: 7,
      value: "Accuracy",
      isFilter: true,
      className: "w-5",

      onClickDescending: () => handleSort("accuracy", "desc"),
      onClickAscending: () => handleSort("accuracy", "asc"),
    },
    {
      id: 8,
      value: "Correct To Total",
      isFilter: true,
      className: "w-5",

      onClickDescending: () => handleSort("confidence", "desc"),
      onClickAscending: () => handleSort("confidence", "asc"),
    },
  ];

  const mobilePerformance = (value) => {
    if (value > 85) {
      return "strong";
    } else if (value >= 60 && value < 85) {
      return "average";
    } else {
      return "weak";
    }
  };

  const filterCard = (label, value) => {
    const grading = mobilePerformance(
      value.length > 2 ? value.slice(0, -1) : value
    );
    const isGrading = label === "Accuracy:" || label === "Correct To Total:";
    return (
      <div className="w-100 d-flex align-items-center mb-4">
        <div className="sorting_label">
          <p className="fw-bold mb-0">{label}</p>
        </div>
        <div className={`sorting_field`}>
          <div style={{ width: "30%" }}>
            <p className="fw-bold mb-0">
              {!label.includes("Total Questions:") &&
              String(value).includes("0") &&
              String(value).length > 1
                ? String(value).slice(0, -1)
                : value}
            </p>
          </div>
          {isGrading && (
            <div style={{ width: "70%" }}>
              <div className={`${grading}`}>{grading}</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const calculateCorrectToTotal = (item) => {
    const correctlyAttempted = item["correctly_attempted"];
    const totalQuestions = item["topic_questions"];
    return Math.round((correctlyAttempted / totalQuestions) * 100);
  };

  const sortTopics = (key, order) => {
    const sortedTopics = [...topicLists].sort((a, b) => {
      const valueA = key === "confidence" ? calculateCorrectToTotal(a) : a[key];
      const valueB = key === "confidence" ? calculateCorrectToTotal(b) : b[key];

      if (key !== "topic_name") {
        return order === "asc" ? valueA - valueB : valueB - valueA;
      } else {
        return order === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }
    });
    setTopicLists(sortedTopics);
  };

  const handleSort = (key, order) => {
    setTypeFilter({ type: key, value: order });
    sortTopics(key, order);
  };

  const handleChange = (e, type, label) => {
    setMobileFilter({
      type: type === "total_questions" ? "topic_questions" : type,
      value: e.target.value,
      label: label,
    });
  };

  const handleApply = () => {
    sortTopics(mobileFilter.type, mobileFilter.value === "1" ? "asc" : "desc");
    setIsModalOpen(false);
  };
  const performanceClass = (accuracy) => {
    if (accuracy < 60) {
      return "performance_pink";
    } else if (accuracy >= 60 && accuracy < 85) {
      return "performance_yellow";
    } else {
      return "performance_green";
    }
  };

  const sortedRows = useMemo(() => {
    return topicLists?.map((d, i) => {
      const accuracy = d.accuracy ? Math.round(d.accuracy * 100) + "%" : 0;
      const accuracyClassName = `text-base text-center w-5 ${performanceClass(
        d.accuracy !== null ? Math.round(d.accuracy * 100) : 0
      )}`;
      const correctToTotalClassName = `text-base text-center w-5 ${performanceClass(
        Math.round((d.correctly_attempted / d.topic_questions) * 100)
      )}`;
      return [
        { value: i + 1, id: 1, className: "text-base w-5" },
        { value: d.topic_name, id: 2, className: "w-20 text-base" },
        {
          value: d.topic_questions,
          id: 3,
          className: "text-base text-center w-5",
        },
        {
          value: d.total_attempted,
          id: 4,
          className: "text-base text-center w-5",
        },
        {
          value: d.correctly_attempted,
          id: 5,
          className: "text-base text-center w-5",
        },
        {
          value: d.time_per_question
            ? Math.round(d.time_per_question) + "s"
            : "0",
          id: 6,
          className: "text-base text-center w-5",
        },
        {
          value: accuracy,
          id: 7,
          className: accuracyClassName,
        },
        {
          value:
            Math.round((d.correctly_attempted / d.topic_questions) * 100) +
            "" +
            "%",
          id: 8,
          className: correctToTotalClassName,
        },
      ];
    });
  }, [topicLists]);

  useEffect(() => {
    getStudentPerformanceReport();
  }, []);

  useEffect(() => {
    // Filter out topics based on common subject_id
    const filteredTopics = studentPerformance.reduce((acc, curr) => {
      const existingSubject = acc.find(
        (item) => item.subject_id === curr.subject_id
      );
      if (!existingSubject) {
        acc.push({
          subject_name: curr.subject_name,
          subject_id: curr.subject_id,
          subjects: [curr],
        });
      } else {
        existingSubject.subjects.push(curr);
      }
      return acc;
    }, []);

    setSubjectWiseReport(filteredTopics);
    setActiveSubject(filteredTopics[0]?.subject_id);
  }, [studentPerformance]);

  useEffect(() => {
    setTopicLists(
      subjectWiseReport?.find((d) => d?.subject_id === activeSubject)?.subjects
    );
  }, [subjectWiseReport, activeSubject]);

  return (
    <div className="white-box h-100">
      <div className="d-flex justify-content-between align-items-center pe-5">
        <div className="p-3">
          <h1 className="text-capitalize performance_heading">
            Overall Performance
            {`: ${activeSubjectName?.subject_name.toLowerCase()}`}
          </h1>
        </div>
        <div className="progress_performance">
          <div className="d-flex align-items-center gap-2">
            <div className="performance_pink circle"></div>
            <div className="fw-bold">Weak</div>
          </div>
          <div className="d-flex align-items-center gap-2">
            <div className="performance_yellow circle"></div>
            <div className="fw-bold">Moderate</div>
          </div>
          <div className="d-flex align-items-center gap-2">
            <div className="performance_green circle"></div>
            <div  className="fw-bold">Strong</div>
          </div>
        </div>
      </div>
      <div className="containers">
        <div className="subject_container_report">
          <div className="subject_inner_container">
            {subjectWiseReport?.map((d) => (
              <div
                className={`subject_title text-capitalize ${
                  activeSubject === d.subject_id ? "active" : "inactive"
                }`}
                onClick={() => {
                  setActiveSubject(d.subject_id);
                  setIsOpen(!isOpen);
                }}
              >
                <p className="mb-0 text-capitalize">
                  {d.subject_name?.toLowerCase()}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="topic_container_table">
          <Table heading={tableHeading} rows={sortedRows} />
        </div>
        <div className={`topic_list_container ${isOpen ? "open" : ""}`}>
          <div className="topic_container_card">
            <div className="mb-5 ms-4 d-flex align-items-center justify-content-between">
              <h1>{activeSubjectName?.subject_name}</h1>
              <div className="performance_drawer_close">
                <Image
                  src={CloseIcon}
                  alt="close"
                  onClick={() => setIsOpen(false)}
                />
              </div>
            </div>
            <div
              className="p-4 my-5 sorting_filter"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
              }}
            >
              Sort By : {mobileFilter?.label}
            </div>
            <div className="progress_performance_mobile">
              <div className="d-flex align-items-center gap-2">
                <div className="performance_pink circle"></div>
                <div className="fw-bold">Weak</div>
              </div>
              <div className="d-flex align-items-center gap-2">
                <div className="performance_yellow circle"></div>
                <div className="fw-bold">Moderate</div>
              </div>
              <div className="d-flex align-items-center gap-2">
                <div className="performance_green circle"></div>
                <div className="fw-bold">Strong</div>
              </div>
            </div>
            <div className="topic_inner_card_container">
              {topicLists?.map((topic) => {
                const accuracy = topic?.accuracy
                  ? Math.round(topic?.accuracy * 100) + "%"
                  : "0";
                const correctToTotal =
                  Math.round(
                    (topic.correctly_attempted / topic.topic_questions) * 100
                  ) + "%";
                const timePerQuestion = topic.time_per_question
                  ? Math.round(topic.time_per_question) + "s"
                  : "0";
                return (
                  <div className="topic_card">
                    <div className="w-100 d-flex justify-content-center mb-4" s>
                      <p className="text-capitalize text-center fw-bold fs-3 mb-0">
                        {topic?.topic_name?.toLowerCase()}
                      </p>
                    </div>
                    {filterCard("Total Questions:", topic.topic_questions)}
                    {filterCard("Total Attempted:", topic.total_attempted)}
                    {filterCard(
                      "Correctly Attempted:",
                      topic.correctly_attempted
                    )}
                    {filterCard("Time/Question:", timePerQuestion)}
                    {filterCard("Accuracy:", accuracy)}
                    {filterCard("Correct To Total:", correctToTotal)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-filter">
        <CommonModal
          close={() => {
            setMobileFilter({ label: "", type: "", value: "" });
            setIsModalOpen(false);
          }}
          show={isModalOpen}
          body={
            <div>
              <div>
                <h1>Sort By</h1>
              </div>
              {radioOptions?.map((d) => {
                return (
                  <div className="d-flex gap-4 w-100 my-5" key={d.id}>
                    <div className="row w-100">
                      <div className="col-4">
                        <p className="mb-0 me-4 text-wrap fw-bold">{d.label}</p>
                      </div>
                      <div className="col-8 p-1">
                        <div className="d-flex ">
                          <div className="me-2 w-50">
                            <input
                              className="me-2"
                              type="radio"
                              name={`${d.id}-sort`}
                              id={`${d.id}-asc`}
                              value="1"
                              onChange={(e) => handleChange(e, d.name, d.label)}
                              checked={
                                (mobileFilter?.type === "topic_questions" &&
                                  d?.name === "total_questions" &&
                                  mobileFilter?.value === "1") ||
                                (mobileFilter?.type === d.name &&
                                  mobileFilter?.value === "1")
                              }
                            />
                            <label className="" htmlFor={`${d.id}-asc`}>
                              Ascending
                            </label>
                          </div>
                          <div className="me-2 w-50">
                            <input
                              className="me-2"
                              type="radio"
                              name={`${d.id}-sort`}
                              id={`${d.id}-desc`}
                              value="2"
                              onChange={(e) => handleChange(e, d.name, d.label)}
                              checked={
                                (mobileFilter?.type === "topic_questions" &&
                                  d?.name === "total_questions" &&
                                  mobileFilter?.value === "2") ||
                                (mobileFilter?.type === d.name &&
                                  mobileFilter?.value === "2")
                              }
                            />
                            <label className="" htmlFor={`${d.id}-desc`}>
                              Descending
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="d-flex justify-content-center gap-4">
                <Button
                  title="Clear"
                  className="btn small-btn"
                  onClick={() => {
                    setMobileFilter({ type: "", label: "", value: "" });
                  }}
                />
                <Button
                  title="Apply"
                  className="btn small-btn"
                  onClick={handleApply}
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default StudentPerformance;
