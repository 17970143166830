import Auth from "../auth/Auth";

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

export const METHOD = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PUT: "PUT",
  PATCH: "PATCH",
};

export function authHeaderConfig() {
  if (Auth.token()) {
    return { ...defaultHeaders, Authorization: `Bearer ${Auth.token()}` };
  } else {
    return defaultHeaders;
  }
}

export function createApiUrl(apiUrl) {
  return `${process.env.REACT_APP_BASE_URL}/api/${apiUrl}`;
}

export function fetchApi(endPoint, method = METHOD.GET, params = null) {
  const { POST, PATCH, PUT } = METHOD;
  const options = {
    headers: authHeaderConfig(),
    method,
  };

  if ([POST, PATCH, PUT].includes(method)) {
    options.body = JSON.stringify(params);
  }

  return fetch(createApiUrl(endPoint), options);
}

function notAddKeysParams(key, isAdd) {
  if (isAdd) {
    return !["id", "folderId", "body", "section_id"].includes(key);
  } else {
    return ![
      "id",
      "folderId",
      "body",
      "exam_id",
      "section_id",
      "exam_template_structure_id",
      "student_dyt_id",
    ].includes(key);
  }
}

export function buildQueryParams(params, isAppend) {
  const query = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    if (params[key] && notAddKeysParams(key, isAppend)) {
      query.append(key, params[key]);
    }
  });
  return query.toString() ? `?${query.toString()}` : "";
}

export function buildApiUrl(base, id, extraPath = "") {
  return `${base}${id ? `/${id}` : ""}${extraPath}`;
}

export function getUrlAndQuery(base, params, isAppend = true, isAdd = false) {
  if (params !== undefined) {
    const url = buildApiUrl(
      base,
      params?.id ||
        (isAppend && params?.exam_id) ||
        (isAppend && params.student_dyt_id),
      (params.folderId && `/${params.folderId}`) ||
        (params.section_id && `/${params.section_id}`)
    );
    const queryParams = buildQueryParams(params, isAdd);
    return `${url}${queryParams}`;
  } else {
    return base;
  }
}
