import React, { useEffect, useContext, useState } from "react";

import { useForm } from "@kaydhiman/react-hook-useform";
import chatIcon from "../../../assets/images/NewAskQuery.png";
import QueryIcon from "../../../assets/images/NewQueryIcon.png";
import chatClose from "../../../assets/images/white-arrow-down.svg";
import minimize from "../../../assets/images/minimize.png";
import maximize from "../../../assets/images/maximize.png";
import { userService } from "../../../_services/User.services";
import { AppContext } from "../../shared/AppContext";
import Button from "../forms/Button";
import CommonModal from "../../utils/modal/CommonModal";
import Accordion from "react-bootstrap/Accordion";

import SelectInput from "../forms/SelectInput";
import attachment from "../../../assets/images/attachment.png";
import attachment_files from "../../../assets/images/attachment-files.png";
import crossIcon from "../../../assets/images/red_cross.svg";
import { useLocation } from "react-router-dom";
import Image from "../image/Image";
import { useRedirection } from "../../../hooks/useRedirection";
import { convertIsoToOrdinalDate } from "../dateTime";

const selQuery = [
  { id: 2, name: "Open Queries" },
  { id: 1, name: "All Queries" },
];
const ChatBox = () => {
  const { values, setInitialValues, bindField } = useForm({ validations: {} });
  const {
    setSpinner,
    setToaster,
    isOpenNotification,
    userData,
    getProfileData,
  } = useContext(AppContext);
  const location = useLocation();
  const [chatBox, setChatBox] = useState(false);
  const [queries, setQueries] = useState();
  const [showQuery, setShowQuery] = useState(0);
  const [sections, setSections] = useState([]);
  const [subSections, setSubSections] = useState([]);
  const [filtQueries, setFiltQueries] = useState([]);
  const [maxChatBox, setMaxChatBox] = useState(false);
  const [image, setImage] = useState();
  const [singleQuery, setSingleQuery] = useState();
  const [show, setShow] = useState(false);
  const attachmentShowClose = () => setShow(false);
  //Prevent multiple api call onClick
  const [loadSectionData, setLoadSectionData] = useState(false);
  //Prevent multiple api call onClick
  const [loadSubSectionData, setSubLoadSectionData] = useState(false);

  //Prevent multiple api call onClick
  const [getQueriesData, setGetQueriesData] = useState(false);

  // const { getData: getProfileData } = useProfileDetails(setSpinner, setToaster);
  const { getRedirection } = useRedirection();

  const getQueries = async () => {
    setSpinner(true);
    const response = await userService.getQueries();
    try {
      if (response.status >= 200 && response.status <= 299) {
        setSpinner(false);
        const data = await response.json();
        setQueries(data?.data);
        setFiltQueries(data?.data);
        setInitialValues((prev) => ({
          ...prev,
          queryId: "All Queries",
          querys: 1,
        }));
      }
      // Prevent Multiple API call onClick
      setGetQueriesData(true);
      if (response.status >= 400 && response.status <= 499) {
        setSpinner(false);
      }
      if (response?.status === 401) {
        setSpinner(false);
        localStorage.removeItem("_prathamStudentToken");
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  const getSections = async () => {
    setSpinner(true);
    const response = await userService.getSections();
    try {
      if (response.status >= 200 && response.status <= 299) {
        setSpinner(false);
        const data = await response.json();
        const filterData = data.data.filter(
          (d) =>
            d.organization.id === userData?.org_id && Number(d.is_active) === 1
        );
        setSections(filterData);

        // Prevent Multiple API call onClick
        setLoadSectionData(true);
      }
      if (response.status >= 400 && response.status <= 499) {
        // const data = await response.json();
        setSpinner(false);
      }
      if (response?.status === 401) {
        setSpinner(false);
        localStorage.removeItem("_prathamStudentToken");
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  const getSubSections = async () => {
    setSpinner(true);
    const response = await userService.getSections({
      sub_section: "yes",
    });
    try {
      if (response.status >= 200 && response.status <= 299) {
        setSpinner(false);
        const data = await response.json();
        setSubSections(data?.data.filter((d) => Number(d.is_active) === 1));
        setSubLoadSectionData(true);
      }
      if (response.status >= 400 && response.status <= 499) {
        setSpinner(false);
      }
      if (response?.status === 401) {
        setSpinner(false);
        localStorage.removeItem("_prathamStudentToken");
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  const createQuery = async (e) => {
    e.preventDefault();

    setSpinner(true);

    try {
      if (values?.query === "") {
        setToaster({
          show: true,
          type: "danger",
          content: "Please write your query before submit",
        });
      } else {
        const formData = new FormData();
        if (image) {
          formData.append("file", image); // Add the file
        }
        formData.append("section_id", values.section); // Add other fields
        formData.append("sub_section_id", values.subSection);
        formData.append("query", values.query);
        const response = await userService.addQuery(formData);

        if (response.status >= 200 && response.status <= 299) {
          const data = await response.json();
          setImage();
          getQueries();
          setSpinner(false);
          setShowQuery(0);
          setInitialValues((prev) => ({
            ...prev,
            file: "",
            query: "",
            section: "",
            sectionId: "",
            subSection: "",
            subSectionId: "",
          }));
          // setToaster({ show: true, type: "success", content: data.message });
          setToaster({
            show: true,
            type: "success",
            content:
              "Query Received successfully, We aim to resolve your query within 48 hours",
          });
        }
        if (response.status >= 400 && response.status <= 499) {
          const data = await response.json();

          if (data.errors) {
            let error = Object.keys(data.errors)?.reverse();

            for (let i = 0; i <= error.length; i++) {
              setToaster({
                show: true,
                type: "danger",
                content: data.errors[error[i]][0],
              });
            }
          } else {
            setToaster({
              show: true,
              type: "danger",
              content: data.message,
            });
          }
          setSpinner(false);
        }
      }
    } catch (error) {
      setSpinner(false);
    }
    setSpinner(false);
    // api funstion
  };

  const containsHTMLString = (string) => {
    const htmlRegex = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
    return htmlRegex.test(string);
  };

  const [filtSubSection, setFiltSubSection] = useState([]);

  const handleLoadGetQueries = () => {
    setChatBox(!chatBox);
    if (!getQueriesData) {
      getQueries();
    }
    setMaxChatBox(false);
  };

  const handleLoadSections = () => {
    if (!loadSectionData) {
      getSections();
      getSubSections();
    }
  };

  const handleLoadSubSection = () => {
    if (!values.section && !values?.sectionId) {
      setToaster({
        show: true,
        type: "danger",
        content: "Please Select Category",
      });
    }
  };

  const handleProfileDetailsCallback = (profileData) => {
    if (profileData.data.is_active === 0) {
      localStorage.clear();
      window.location.href = getRedirection(userData.org_id);
    }
  };

  useEffect(() => {
    let a = subSections?.filter(
      (a) => a.parent?.id === Number(values?.section)
    );
    setFiltSubSection(a);

    //eslint-disable-next-line
  }, [values?.sectionId]);

  useEffect(() => {
    if (values?.queryId === "All Queries") {
      setFiltQueries(queries);
    } else {
      setFiltQueries(
        queries?.filter((a) => a?.status?.name?.toLowerCase() === "open")
      );
    }

    //eslint-disable-next-line
  }, [values?.queryId]);

  // useEffect(() => {
  //   getSections();
  //   getSubSections();
  //   //eslint-disable-next-line
  // }, []);

  useEffect(() => {
    setInitialValues((prev) => ({ ...prev, subSectionId: "", subSection: "" }));
    //eslint-disable-next-line
  }, [values?.section]);

  useEffect(() => {
    getProfileData();
  }, [location]);

  return (
    <>
      <div
        className={`fixed-chat-box cursor-pointer ${
          chatBox
            ? `chat-box-open  ${maxChatBox ? "full-page-chatBoot" : "chatBoot"}`
            : "chat-box-close"
        }`}
        style={{ zIndex: isOpenNotification ? "1" : 999 }}
      >
        <div className="position-relative" onClick={handleLoadGetQueries}>
          {!chatBox ? (
            <div>
              <Image
                src={chatIcon}
                alt="chat-box-icon"
                width={96}
                height={80}
              />
              <Image
                style={{ position: "absolute", top: "8px", left: "8px" }}
                src={QueryIcon}
                alt="chat-box-icon"
                width={80}
                height={50}
              />
            </div>
          ) : (
            <div className="chat-btn">
              <Image
                style={{ width: "4rem", height: "4rem" }}
                src={chatClose}
                alt="chat-box-icon"
              />
            </div>
          )}
        </div>

        <div
          className={`chat-box chat-box-hidden ${showQuery === 1 && "open"}`}
        >
          <div className="chat-box-header justify-content-between">
            <div className="chat-box-header-heading d-flex align-items-center">
              <span className="chatbox-icon">P</span>
              <h1>How Can We Help?</h1>
            </div>
            <div className="chat-box-header-buttons">
              <Image
                src={minimize}
                alt="minimize-icon"
                className="minimize-chat-screen pointer"
                onClick={() => setMaxChatBox(false)}
              />

              <Image
                src={maximize}
                alt="mazximize-icon"
                className="maximize-chat-box pointer"
                onClick={() => setMaxChatBox(true)}
              />
            </div>
          </div>
          <div className="chat-box-body">
            {showQuery === 0 && (
              <div className="query-view">
                <div className="adminForm border-bottom">
                  <SelectInput
                    id="queryId"
                    label=""
                    name="querys"
                    data={selQuery}
                    setInitialValues={setInitialValues}
                    values={values}
                    title="Select"
                  />
                  {/* <SelectInput
              label="Subject"
              id="subject_id"
              name="subject"
              data={values?.subjects || []}
              setInitialValues={setInitialValues}
              values={values}
              title="Select"
            /> */}
                </div>
                <div className="queries-sec">
                  {filtQueries?.length > 0 ? (
                    filtQueries?.map((elem, index) => {
                      const formattedUpdatedDateTime = convertIsoToOrdinalDate(
                        elem.updated_at
                      );
                      return (
                        <div className="querie" key={index + 1}>
                          {/* <h4 className="querie_heading" style={{ cursor: "pointer" }} onClick={() => { setShowQuery(2); setSingleQuery(elem) }}>{elem?.query}</h4> */}

                          {containsHTMLString(elem?.query) ? (
                            <h4
                              className="querie_heading"
                              dangerouslySetInnerHTML={{
                                __html: elem?.query,
                              }}
                            ></h4>
                          ) : (
                            <h4 className="querie_heading">{elem?.query}</h4>
                          )}

                          <div className="querie_bread-crumb">
                            <span>{elem?.section?.name}</span>
                            <span className="gray-text"> {`>`} </span>{" "}
                            <span className="querie_bread-crumb_name">
                              {elem?.sub_section?.name}
                            </span>
                            <div
                              className={`querie_status ${
                                elem?.status?.name.toLowerCase() === "open"
                                  ? "green-btn"
                                  : "pink-btn"
                              }`}
                            ></div>
                            {elem?.attachment && (
                              <div className="querie-attachments">
                                {elem?.attachment
                                  ?.split(".")
                                  [
                                    elem?.attachment?.split(".")?.length - 1
                                  ]?.match("/doc|zip|pdf|docx|xlsx|csv/") ? (
                                  <div className="doc-files">
                                    <a
                                      className=" d-flex align-items-end"
                                      href={`${elem?.attachment}`}
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      <Image
                                        src={attachment_files}
                                        alt="attachment-icon"
                                        className="docAttachment border-0 w-10"
                                      />
                                      <span className="pb-2">
                                        {elem?.attachment}
                                      </span>
                                    </a>
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      setShow(true);
                                      setSingleQuery(elem);
                                    }}
                                    className="pointer"
                                  >
                                    <Image
                                      src={`${elem?.attachment}`}
                                      alt="attachment-icon"
                                    />
                                    {/* <div className="overlay">
                                    <Image src={view} alt="view " />
                                  </div> */}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <div
                            className="querie_time"
                            dangerouslySetInnerHTML={{
                              __html: `Last Updated On: ${formattedUpdatedDateTime}`,
                            }}
                          />

                          {elem?.response?.length > 0 && (
                            <div
                              className="gray-bg p-accordion"
                              onClick={() => {
                                setShowQuery(2);
                                setSingleQuery(elem);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              Response
                            </div>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-center">No Query Found</p>
                  )}
                </div>
              </div>
            )}
            {/* create query */}
            {showQuery === 1 && (
              <form onSubmit={createQuery}>
                <div className="create_query">
                  <div className="adminForm">
                    <div className="chatBoot-select-box">
                      <SelectInput
                        id="sectionId"
                        label=""
                        name="section"
                        data={sections}
                        setInitialValues={setInitialValues}
                        values={values}
                        title="Select Category"
                        onClickBtn={handleLoadSections}
                      />
                    </div>
                    {loadSubSectionData && (
                      <>
                        <div className="chatBoot-select-box query-sub-section">
                          <SelectInput
                            id="subSectionId"
                            label=""
                            name="subSection"
                            data={filtSubSection}
                            setInitialValues={setInitialValues}
                            values={values}
                            title="Select sub-category"
                            onClickBtn={handleLoadSubSection}
                          />
                          <div
                            className={`attactment-box chatbox_upload pointer ${
                              image && !image?.name.endsWith("pdf")
                                ? "attachment-img"
                                : ""
                            }
                      ${
                        image?.name
                          ?.split(".")
                          [image?.name?.split(".")?.length - 1]?.match(
                            "/doc|zip|pdf|docx|xlsx|csv/"
                          ) && "doc-cross"
                      }`}
                          >
                            {!image?.name ? (
                              <>
                                <label
                                  htmlFor="upload_create_imgbox"
                                  className="mb-0"
                                >
                                  <Image
                                    src={attachment}
                                    alt="attachment-icon"
                                  />
                                </label>
                                <input
                                  type="file"
                                  className="chatbox_upload_file curser"
                                  id="chatbox_file"
                                  name="file"
                                  accept=".jpg,.png,.pdf"
                                  // onChange={handleChangeimg}

                                  onChange={(e) => {
                                    setImage(e.target?.files[0]);
                                  }}
                                />{" "}
                              </>
                            ) : (
                              <>
                                <div className="cross-btn">
                                  <Image
                                    src={crossIcon}
                                    alt="attachment-icon"
                                    onClick={() => setImage()}
                                  />
                                </div>
                                {!image.name.endsWith("pdf") && (
                                  <Image
                                    src={image && URL.createObjectURL(image)}
                                    alt="user-attachment"
                                  />
                                )}
                                {image?.name
                                  ?.split(".")
                                  [image?.name?.split(".")?.length - 1]?.match(
                                    "/doc|zip|pdf|docx|xlsx|csv/"
                                  ) && (
                                  <>
                                    {!image.name.endsWith("pdf") && (
                                      <Image
                                        src={attachment_files}
                                        alt="attachment-icon"
                                        className="docAttachment"
                                      />
                                    )}{" "}
                                    <span>{image?.name}</span>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="query-detail">
                      <textarea
                        name="query"
                        rows="10"
                        cols="50"
                        className="bg-transparent"
                        {...bindField("query")}
                        value={values?.query}
                        placeholder="Type Your Query"
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
                {showQuery === 1 && (
                  <div className="text-center mt-4 d-flex gap-4 justify-content-center">
                    <button
                      className="query_btn"
                      onClick={() => {
                        setShowQuery(0);
                      }}
                    >
                      Back
                    </button>
                    <button className="query_btn">Submit</button>
                  </div>
                )}
              </form>
            )}

            {/* Detail of query */}
            {showQuery === 2 && (
              <div className="queries-sec">
                <div className="querie">
                  {containsHTMLString(singleQuery?.query) ? (
                    <h4
                      className="querie_heading"
                      style={{ cursor: "pointer" }}
                      dangerouslySetInnerHTML={{
                        __html: singleQuery?.query,
                      }}
                      onClick={() => setShowQuery(2)}
                    ></h4>
                  ) : (
                    <h4
                      className="querie_heading cursor-pointer"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setShowQuery(2);
                      }}
                    >
                      {singleQuery?.query}
                    </h4>
                  )}
                  <div></div>
                  <div className="querie_bread-crumb">
                    <span>{singleQuery?.section?.name}</span>
                    <span className="gray-text"> {`>`} </span>{" "}
                    <span className="querie_bread-crumb_name">
                      {singleQuery?.sub_section?.name}
                    </span>
                    <div
                      className={`querie_status ${
                        singleQuery?.status?.name.toLowerCase() === "open"
                          ? "green-btn"
                          : "pink-btn"
                      }`}
                    ></div>
                    {singleQuery?.attachment && (
                      <div className="querie-attachments">
                        {singleQuery?.attachment
                          ?.split(".")
                          [
                            singleQuery?.attachment?.split(".")?.length - 1
                          ]?.match("/doc|zip|pdf|docx|xlsx|csv/") ? (
                          <div className="doc-files">
                            <a
                              className=" d-flex align-items-end"
                              href={`${singleQuery?.attachment}`}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <Image
                                src={attachment_files}
                                alt="attachment-icon"
                                className="docAttachment border-0 w-10"
                              />
                              <span className="pb-2">
                                {singleQuery?.attachment}
                              </span>
                            </a>
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              setShow(true);
                              setSingleQuery(singleQuery);
                            }}
                            className="pointer"
                          >
                            <Image
                              src={`${singleQuery?.attachment}`}
                              alt="attachment"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {singleQuery?.response?.length > 0 && (
                    <>
                      <h4
                        className="querie_heading"
                        style={{ cursor: "pointer" }}
                      >
                        Query Responses
                      </h4>
                      {singleQuery?.response?.map((elem, i) => {
                        const formattedCreatedDateTime =
                          convertIsoToOrdinalDate(elem?.created_at);
                        return (
                          <div className="gray-bg p-accordion">
                            <Accordion>
                              <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                  <div className="accordian-text-header">
                                    <span className="w-100 text-capitalize">
                                      {elem?.responded_by_type}:{" "}
                                      {elem?.response_by_name}
                                    </span>
                                    <span
                                      className="querie_time"
                                      dangerouslySetInnerHTML={{
                                        __html: formattedCreatedDateTime,
                                      }}
                                    />
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: elem?.response,
                                    }}
                                  ></div>
                                  {elem?.attachments && (
                                    <div className="querie-attachments d-flex w-100">
                                      {elem?.attachments
                                        ?.split(",")
                                        .map((attachment, index) => (
                                          <div
                                            key={index}
                                            className="attachment-item mt-5 mb-5 w-50"
                                          >
                                            {attachment
                                              ?.split(".")
                                              [
                                                attachment?.split(".")?.length -
                                                  1
                                              ]?.match(
                                                /doc|zip|pdf|docx|xlsx|csv/
                                              ) ? (
                                              <div className="aa">
                                                <a
                                                  className="d-block w-100"
                                                  href={attachment}
                                                  rel="noreferrer"
                                                  target="_blank"
                                                >
                                                  <Image
                                                    src={attachment_files}
                                                    alt="attachment-icon"
                                                    className="docAttachment border-0 w-50"
                                                  />
                                                </a>
                                              </div>
                                            ) : (
                                              <div
                                                onClick={() => {
                                                  window.open(
                                                    attachment,
                                                    "_blank"
                                                  );
                                                }}
                                                className="pointer"
                                              >
                                                <Image
                                                  className="img_class p-3"
                                                  src={attachment}
                                                  alt="attachment-icon"
                                                />
                                              </div>
                                            )}
                                          </div>
                                        ))}
                                    </div>
                                  )}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
                <div className="d-flex justify-content-center">
                  <Button
                    title="Back"
                    className="w-auto mx-1"
                    onClick={() => {
                      setShowQuery(0);
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="chat-box-footer text-center">
            {showQuery === 0 && (
              <Button
                title="New Query"
                className="w-auto"
                onClick={() => setShowQuery(1)}
              />
            )}
          </div>
        </div>
      </div>

      <CommonModal
        className="image-light-box"
        show={show}
        close={attachmentShowClose}
        body={
          <div className="common_modal">
            <Image src={`${singleQuery?.attachment}`} alt="attachment-icon" />
          </div>
        }
      />
    </>
  );
};

export default ChatBox;
