import React from "react";
import ComprehensiveQuestionContainer from "./ComprehensiveQuestionContainer";
import QuestionContainer from "./QuestionContainer";
import QuestionOptions from "./QuestionOptions";
import QuestionDetailContainer from "./QuestionDetailContainer";

const TestQuestionHtml = ({
  className,
  mathsExam,
  difficuiltyLevel,
  examRef,
  cross,
  tick,
  examName,
  mode,
}) => {
  return (
    <div
      className={`question-dash ${className ? className : ""}`}
      ref={examRef}
      id="pdf"
    >
      {mathsExam?.map((mathsExamData, i) => {
        return (
          <>
            {mathsExamData?.question?.comprehension && (
              <ComprehensiveQuestionContainer
                comprehension={mathsExamData?.question?.comprehension[0]?.name}
              />
            )}
            <div
              className="mt-4 border-bottom-dashed question-box"
              id={`question-${i + 1}`}
            >
              <QuestionContainer
                questionNo={i + 1}
                question={mathsExamData?.question?.question}
              />
              <QuestionOptions
                tick={tick}
                cross={cross}
                optionsData={mathsExamData}
              />
              <QuestionDetailContainer
                questionData={mathsExamData}
                examName={examName}
                mode={mode}
                difficuiltyLevel={difficuiltyLevel}
              />
            </div>
          </>
        );
      })}
    </div>
  );
};

export default TestQuestionHtml;
