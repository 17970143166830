export const useApiRequest = (
  apiFunction,
  setSpinner,
  setToaster,
  showToaster = false,
  showSpinner = true,
  setData,
  setTotal
) => {
  const fetchData = async (params, callBack) => {
    if (showSpinner) {
      setSpinner(true);
    }
    try {
      const response = await apiFunction(params);
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setData(data.data);
        if (data?.data?.total && setTotal) {
          setTotal(data?.data?.total);
        }
        if (callBack) {
          callBack(data);
        }
        setSpinner(false);
        if (showToaster) {
          setToaster({ show: true, type: "success", content: data.message });
        }
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        if (callBack) {
          callBack(data,response?.status);
        } else {
          setToaster({ show: true, type: "danger", content: data.message });
        }
        setSpinner(false);
      }
      if (response.status === 500) {
        const data = await response.json();
        setSpinner(false);
        setToaster({ show: true, type: "danger", content: data.message });
      }
    } catch (error) {
      setSpinner(false);
    }
  };
  return { fetchData };
};
