import React, { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { AppContext } from "../../shared/AppContext";
import BreadCrumbContextProvider from "../../shared/breadcrumb-context";
import Header from "./Header/Header";
import SideBar from "./SideBar";
import ChatBox from "../../utils/chatBox/ChatBox";

const classMapping = [
  "/test",
  "/schedule",
  "/reports",
  "/form",
  "/document",
  "/update-profile",
  "design_your_test",
  // "assignment-details",
  "assignment-report",
  "test_design_layout",
  "newAssignmentQuiz",
];

const otherClassMapping = [
  "/schedule/allclasses",
  "/schedule/liveClassRecordings",
  "/forms/form_updates",
];

const PortalLayout = () => {
  const { sidebarShow, setdark_mode, dark_mode, isSidebarHide } =
    useContext(AppContext);
  const location = useLocation();
  const isClassMapping = classMapping.some((path) =>
    location.pathname.includes(path)
  );
  const isOtherClassMapping = otherClassMapping.some((path) =>
    location.pathname.includes(path)
  );

  return (
    <BreadCrumbContextProvider>
      <div
        className={`dashboard-body ${isSidebarHide ? "full-wid " : ""} ${
          dark_mode ? " dark_mode" : ""
        } ${sidebarShow ? " show-inner-cont" : ""}`}
      >
        <Header />
        {!location.pathname.includes("newAssignmentQuiz") && (
          <SideBar setdark_mode={setdark_mode} dark_mode={dark_mode} />
        )}
        <div className="blue-bg"></div>
        <div
          className={`height-100 dash_body ${
            isClassMapping && "tests_layout"
          } ${isOtherClassMapping && "dash_other_body"} ${
            location?.pathname?.includes("newAssignmentQuiz") &&
            "home_dash_body"
          } `}
          id="supAdmin"
        >
          <div className="container-fluid dash-container">
            <div className="row">
              <Outlet />
            </div>
          </div>
        </div>
        {/* chat box  */}
        {location.pathname.includes("/courses/live_class") ||
        location.pathname.includes("/newAssignmentQuiz") ? (
          ""
        ) : (
          <ChatBox />
        )}
      </div>
    </BreadCrumbContextProvider>
  );
};

export default PortalLayout;
