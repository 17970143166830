import React from "react";
import { Link, useLocation } from "react-router-dom";
import Card from "../../utils/card/Card";

const ShortcutCard = ({ item, batchType }) => {
  const location = useLocation();
  const { id, is_active, batch_type, type, url, url_target, name, icon } = item;
  if (
    (is_active === 1 && type === "external_link") ||
    (is_active === 1 && type === "link_course_to_folder")
  ) {
    if (
      (Number(batchType) !== 0 || Number(batch_type) !== 0) &&
      Number(batch_type) === Number(batchType)
    ) {
      return (
        <a
          href={url}
          target={type === "link_course_to_folder" ? "_self" : `_${url_target}`}
          key={id}
        >
          <Card title={name} image={icon} />
        </a>
      );
    } else if (Number(batchType) === 0 || Number(batch_type) === 0) {
      return (
        <a
          href={url}
          target={type === "link_course_to_folder" ? "_self" : `_${url_target}`}
          key={id}
        >
          <Card title={name} image={icon} />
        </a>
      );
    }
  } else if (type === "folder" && is_active === 1) {
    if (
      (Number(batchType) !== 0 || Number(batch_type) !== 0) &&
      Number(batch_type) === Number(batchType)
    ) {
      return (
        <Link
          to={`/dashboard/shortcut/${id}`}
          key={id}
          state={{ folder_name: name, url: location?.pathname }}
        >
          <Card title={name} image={icon} />
        </Link>
      );
    } else if (Number(batchType) === 0 || Number(batch_type) === 0) {
      return (
        <Link
          to={`/dashboard/shortcut/${id}`}
          key={id}
          state={{ folder_name: name, url: location?.pathname }}
        >
          <Card title={name} image={icon} />
        </Link>
      );
    }
  }

  return null; // Return null if the item does not meet the conditions
};

export default ShortcutCard;
