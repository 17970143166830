import React from "react";
import pencil from "../../../assets/images/pencil.png";
import userImg from "../../../assets/images/user-image.jpg";
import Button from "../../utils/forms/Button";
import CommonModal from "../../utils/modal/CommonModal";
import { useLocation } from "react-router-dom";
import user from "../../../assets/images/user-image.jpg";
import Image from "../../utils/image/Image";

const UpdateProfileImageModal = ({
  profilePicModalClose,
  show,
  updateProfilePhoto,
  handleImage,
  showImage,
  image,
}) => {
  return (
    <CommonModal
      className="profile-modal profile-pic-modal "
      show={show}
      crossShow={false}
      modalBodyClassName="py-0"
      // close={() => profilePicModalClose(!show)}
      // heading={"My Profile"}
      body={
        <div className="common_modal">
          <>
            <div className="ps-5 pe-5 pb-1">
              <h1 className="text-center mb-2">Urgent Action Required</h1>
              <p className="fs-3">
                1. Please upload your recent passport size photograph in your
                account immediately
              </p>
              <p className="fs-3">
                2. ⁠Your profile photo should be clear and must show your both
                shoulders
              </p>
              <p className="fs-3">
                3. ⁠Failing to upload correct photo will lead to deactivation of
                your account!
              </p>
              <p className="fs-3">4. Ignore if already done</p>
            </div>
            <div className="modalHeading">
              <h2 className="modal-title text-center">Upload Profile Pic</h2>
            </div>
          </>
          <div className="profile-form">
            <div className="adminForm">
              <div className="profile-box">
                <label htmlFor="filename" className="pointer mb-0">
                  <Image
                    src={pencil}
                    alt="Camera"
                    className="position-absolute camera-img"
                  />
                </label>
                <Image
                  src={image ? image : userImg}
                  alt="User"
                  className="img-fluid current-img user-img"
                />

                <input
                  type="file"
                  id="filename"
                  name="filename"
                  className="d-none"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => handleImage(e)}
                />
              </div>
            </div>
            <div className="modal-footer d-block mt-4">
              <div className="button-footer w-100">
                <Button
                  className="proceed  small-btn px-4 py-2"
                  onClick={(e) => {
                    updateProfilePhoto(e);
                  }}
                  title="Update Photo"
                  disable={!showImage?.name}
                />

                {/* <div className="modal-footer d-block">
              <div className="button-footer w-100">
                <div className="proceed  btn theme-btn small-btn px-4 py-2">
                  <label for="filename" className="pointer mb-0">
                    Update Photo
                  </label>
                  <input
                    type="file"
                    id="filename"
                    name="filename"
                    className="d-none"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => handleIamge(e)}
                  />
                </div>
              </div>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default UpdateProfileImageModal;
