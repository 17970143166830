import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import VideoPlayer from "../../utils/videoPlayer/VideoPlayer";
import { CONSTANT } from "../../utils/constants";
import { updateBreadcrumb } from "../../utils/commonFunctions/updateBreadcrumb";
import { useBreadcrumb } from "../../shared/breadcrumb-context";

const Recordings = () => {
  const location = useLocation();
  const { state, dispatch } = useBreadcrumb();
  const [breadcrumbName, setBreadcrumbName] = useState(
    location?.state?.liveClassName
  );

  const recordingCallBack = () => {
    return;
  };

  useEffect(() => {
    updateBreadcrumb(
      state,
      dispatch,
      breadcrumbName,
      null,
      null,
      false,
      recordingCallBack
    );
  }, [breadcrumbName]);

  return (
    <div className="white-box">
      <div className="text-center mt-3 mb-5">
        <h1>{location?.state?.liveClassName}</h1>
      </div>
      <div className="row">
        {location?.state?.recordings?.map((d) => {
          if (
            [1, 3].includes(d.video[0]?.video_type) &&
            d.video[0]?.content.startsWith(CONSTANT.PATH.VIMEO_URL)
          ) {
            return (
              <div className="col-md-6">
                <VideoPlayer
                  src={d?.video[0]?.content}
                  title={d?.video[0]?.title}
                  subject={d.video[0]?.subject.name}
                />
              </div>
            );
          } else if ([2, 3].includes(d.video[0]?.video_type)) {
            return (
              <div className="col-md-6">
                <VideoPlayer
                  src={d.video[0]?.content}
                  title={d.video[0]?.title}
                  subject={d.video[0]?.subject.name}
                />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default Recordings;
