import React from "react";
import Button from "../../utils/forms/Button";
import CloseIcon from "../../../assets/images/cross.svg";
import Image from "../../utils/image/Image";

const NavigationDrawer = ({
  mobileBtnModal,
  setMobileBtnModal,
  handleQuestionStatus,
  currentQuestionIndex,
  selectedTabId,
  questions,
  handleClearAnswer,
  playPauseTimer,
  handleSubmission,
  submitDataResponse,
  getSubmittedQuestionCount,
  setConfirmationModal,
}) => {
  return (
    <div className={`mobile_btn_drawer ${mobileBtnModal ? "open" : ""}`}>
      <div className="drawer_close">
        <Image
          src={CloseIcon}
          alt="close"
          onClick={() =>
            setMobileBtnModal((prev) => ({ ...prev, mobileBtn: false }))
          }
        />
      </div>
      <div className="mobile_btn_container w-100 d-flex justify-content-center">
        <div className="">
          <button
            className="save_next w-100"
            onClick={() => {
              handleQuestionStatus("save and next", false);
              setMobileBtnModal((prev) => ({ ...prev, mobileBtn: false }));
            }}
          >
            Save & Next
          </button>
            {/* <Button
              className="submit_btn w-100"
              onClick={async () => {
                playPauseTimer(false);
                await handleQuestionStatus("save and next", true);
                await handleSubmission(submitDataResponse);
                getSubmittedQuestionCount &&
                  (await getSubmittedQuestionCount());
                setConfirmationModal((prev) => ({
                  ...prev,
                  confirmation: true,
                }));
                setMobileBtnModal((prev) => ({ ...prev, mobileBtn: false }));
              }}
              link=""
              title="Submit Exam"
            /> */}
        </div>
        {/* <Button
          className="save_mark"
          link=""
          title="Save And Mark For Review"
          onClick={() => {
            handleQuestionStatus("save and mark", false);
            setMobileBtnModal((prev) => ({ ...prev, mobileBtn: false }));
          }}
        /> */}
        {/* <Button
          className="mark_next"
          link=""
          title="Mark For Review And Next"
          onClick={() => {
            handleQuestionStatus("mark and next", false);
            setMobileBtnModal((prev) => ({ ...prev, mobileBtn: false }))
          }}
        /> */}
        {/* <div className="col-4">
          <div className="navigation_btn">
            <button
            className={`clear_btn ${
              currentQuestionIndex === 0 ? "bg-white text-black border" : ""
            }`}
            onClick={() => {
              handleQuestionStatus("active", false, true);
              setMobileBtnModal((prev) => ({ ...prev, mobileBtn: false }));
            }}
            disable={currentQuestionIndex === 0}
          >
            Previous
          </button>
          <button
            className={`clear_btn ${
              currentQuestionIndex === questions.length - 1
                ? "bg-white text-black border"
                : ""
            }`}
            onClick={() => {
              handleQuestionStatus("active", false, false);
              setMobileBtnModal((prev) => ({ ...prev, mobileBtn: false }))
            }}
            disable={currentQuestionIndex === questions.length - 1}
          >
            Next
          </button> 
            <Button
                className="clear_btn w-100"
                onClick={() => {
                  handleClearAnswer(currentQuestionIndex, selectedTabId);
                  setMobileBtnModal((prev) => ({ ...prev, mobileBtn: false }));
                }}
                title="Clear"
              />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default NavigationDrawer;
