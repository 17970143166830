import React from "react";
import dataImg from "../../../assets/images/error.png";
import Image from "../../utils/image/Image";

export default function FormDashboard() {
  return (
    <>
      <h2 className="text-center w-100 border-0 position-absolute alert-text h-50 error-msg mb-5">
        {/* <span> No data found</span> */}
        <Image src={dataImg} width="70%" alt="" />
      </h2>
    </>
  );
}
