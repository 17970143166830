import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../shared/AppContext";
import { useSubmitOmrSelfCheck } from "../../hooks/hookList";
import ViewResultModal from "../projectDashboard/examLatestPratham/ViewResultModal";
import Button from "../utils/forms/Button";
import "./omrSelfCheck.scss";

const OmrSelfCheck = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { examId } = useParams();
  const { setSpinner, setToaster, userData } = useContext(AppContext);
  const [selectedOption, setSelectedOption] = useState([]);
  const [questionLimit, setQuestionLimit] = useState(null);
  const [userAttempt, setUserAttempt] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getData: submitOmrResult } = useSubmitOmrSelfCheck(
    setSpinner,
    setToaster
  );
  const questionIndices = Array.from(
    { length: location.state?.totalQuestions },
    (_, index) => `Q${index + 1}`
  );
  const isSuccess = "Students OMR Result uploaded successfully!";

  const isQuestionLimit =
    (!isNaN(location?.state.questionLimit) &&
      location?.state?.questionLimit >= 1) ||
    questionLimit !== null;

  const handleOptionChange = (e, questionNumber) => {
    const isOMRBased = location?.state?.examName
      .toLowerCase()
      .includes("omr based");

    const limitQuestion = !isNaN(questionLimit)
      ? location?.state?.totalQuestions === 60
        ? 50
        : 40
      : Number(location?.state?.questionLimit);

    if (isOMRBased && userAttempt >= limitQuestion) {
      setToaster({
        show: true,
        type: "danger",
        content:
          "You have reached the maximum number of attempts for this exam.",
      });
    } else {
      const { value } = e.target;
      const copyData = [...selectedOption];
      const findQuestion = copyData?.find((d) =>
        Object.keys(d).includes(questionNumber)
      );
      if (findQuestion[questionNumber] !== value) {
        if (findQuestion[questionNumber] === "NA") {
          setUserAttempt((prevAttempt) => prevAttempt + 1);
        }
        findQuestion[questionNumber] = value;
        setSelectedOption(copyData);
      }
    }
  };

  const optionMapping = (value) => {
    const optioMap = {
      0: "a)",
      1: "b)",
      2: "c)",
      3: "d)",
      4: "e)",
    };
    return optioMap[value];
  };

  // Function to convert numeric answer to letter
  const convertToLetter = (numericAnswer) => {
    const answerMap = {
      1: "A",
      2: "B",
      3: "C",
      4: "D",
      5: "E",
    };
    return answerMap[Number(numericAnswer)] || "NA"; // Return empty string if answer is not found
  };

  const handleClear = (question) => {
    const copyData = [...selectedOption];
    const findQuestion = copyData?.find((d) =>
      Object.keys(d).includes(question)
    );
    findQuestion[question] = "NA"; // Use bracket notation to access and update the value
    setUserAttempt((prev) => prev - 1);
    return setSelectedOption(copyData);
  };

  const handleChecked = (selectedOption, i, optionIndex) => {
    return (
      selectedOption &&
      selectedOption.some((d) => {
        if (d?.[`Q${i + 1}`] !== "NA") {
          return d?.[`Q${i + 1}`] == optionIndex + 1;
        } else {
          return false; // Return false if the option is "NA"
        }
      })
    );
  };

  const onSubmitHandler = async () => {
    const copyData = [...selectedOption];
    const modifiedData = copyData.map((obj) => {
      const modifiedAnswers = {};
      Object.keys(obj).forEach((key) => {
        modifiedAnswers[key] = convertToLetter(obj[key]);
      });

      return modifiedAnswers;
    });
    const params = [
      {
        enrollment_id: userData?.user_id,
        exam_id: examId,
        questions: modifiedData[0],
        mode: 2,
      },
    ];
    await submitOmrResult(params, (data) => {
      if (data.data.length > 0) {
        if (
          data?.data[0].remark.replace(/ /g, "").toLowerCase() !==
          "Students OMR Result uploaded successfully!"
            ?.replace(/ /g, "")
            ?.toLowerCase()
        ) {
          setToaster({
            show: true,
            type: "danger",
            content: "Result is already submitted",
          });
        }
      } else {
        setIsModalOpen(true);
      }
    });
  };

  useEffect(() => {
    const updateQuestionIndices = questionIndices?.reduce((acc, cur) => {
      acc[cur] = "NA";
      return acc;
    }, {});
    setSelectedOption([updateQuestionIndices]);
    if (location?.state?.questionLimit !== 0) {
      setQuestionLimit(location?.state?.questionLimit);
    }
    if (
      location?.state?.examName?.toLowerCase()?.includes("omr based") &&
      isNaN(location?.state?.questionLimit)
    ) {
      if (Number(location?.state?.totalQuestions) == 60) {
        setQuestionLimit(50);
      } else {
        setQuestionLimit(40);
      }
    }
  }, [location?.state]);

  return (
    <div className="white-box body-cont h-100">
      <div>
        <h1 className="mb-5">Exam: {location?.state?.examName}</h1>
      </div>
      <div className="omr_container">
        <div className="omr_question_container">
          {questionIndices.map((questionIndex, i) => {
            return (
              <div
                key={questionIndex}
                className={`question_container ${
                  i % 2 !== 0
                    ? "question_container_odd"
                    : "question_container_event"
                }`}
              >
                <div className="questions_no">
                  <p className="question_no">{`${questionIndex})`}</p>
                  <div className="clear-btn-container">
                    <Button
                      className="btn theme-btn small-btn m-0"
                      title="Clear"
                      onClick={() => handleClear(questionIndex)}
                      disable={selectedOption?.some(
                        (d) => d?.[`Q${i + 1}`] === "NA"
                      )}
                    />
                  </div>
                </div>
                <div className="option_no">
                  <div className="d-flex">
                    {Array.from({ length: 5 }, (_, optionIndex) => {
                      return (
                        <div
                          className="question_option_container"
                          key={optionIndex}
                        >
                          <label className="option_container">
                            {optionMapping(optionIndex)}
                          </label>
                          <input
                            type="radio"
                            id={optionIndex + 1}
                            value={optionIndex + 1}
                            checked={handleChecked(
                              selectedOption,
                              i,
                              optionIndex
                            )}
                            onChange={(e) =>
                              handleOptionChange(e, questionIndex)
                            }
                          />
                        </div>
                      );
                    })}
                    <div className="clear">
                      <Button
                        className="btn theme-btn small-btn m-0 "
                        title="Clear"
                        onClick={() => handleClear(questionIndex)}
                        disable={selectedOption?.some(
                          (d) => d?.[`Q${i + 1}`] === "NA"
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="instruction_container">
          {isQuestionLimit && (
            <div className="question_limit_container">
              <p className="question_limit">
                Total Questions: {location.state.totalQuestions}
              </p>
              <p className="question_limit">
                Total Attempts:{" "}
                {!isNaN(questionLimit)
                  ? location?.state?.totalQuestions === 60
                    ? 50
                    : 40
                  : location?.state?.questionLimit}
              </p>
            </div>
          )}
          <h1>Instructions for Self-Evaluation Process</h1>
          <div>
            <div className="instruction_details">
              <p className="instruction_list">
                1. Start by carefully reading all the instructions provided on
                the first page of the Mock.
              </p>
              <p className="instruction_list">
                2. Take the Test offline using OMR, ensuring to adhere to the
                specified time limit for each section.
              </p>
              <p className="instruction_list">
                3. Once you've completed the Test, fill out your answers to
                receive your Score & Analysis.
              </p>
              <p className="instruction_list">
                4. Remember, self-evaluation must be completed in one continuous
                session.
              </p>
              <p className="instruction_list">
                5. If you realize you've made a mistake in marking your answers,
                please request a reset, stating the mock name and reason for the
                reset.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="my-5 button_container">
        <Button title="Submit" className="btn py-2" onClick={onSubmitHandler} />
      </div>
      <div>
        <ViewResultModal
          viewResultHandler={() => {
            setIsModalOpen(false);
            navigate(`/test/${examId}/exams/resultnew`);
          }}
          buttonTitle="View Result"
          viewResultModal={isModalOpen}
        />
      </div>
    </div>
  );
};

export default OmrSelfCheck;
