import React from "react";
import WebcamComponent from "./WebcamComponent";
import CommonModal from "../../utils/modal/CommonModal";

const MockWebcam = ({ hasCameraAccess }) => {
  return (
    <>
      <WebcamComponent />
      <div className="cuet_modal">
        <CommonModal
          className="exam-modal"
          show={hasCameraAccess}
          crossShow={false}
          body={
            <div className="modal-footer">
              <p className="text-center" style={{ fontSize: "2rem" }}>
                {`You have not granted access to the camera. Please manually provide access to the camera now, and then refresh the page.`}
              </p>
            </div>
          }
        />
      </div>
    </>
  );
};

export default MockWebcam;
