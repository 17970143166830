import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../shared/AppContext";
import { useExamInstructions, useSetTest } from "../../../hooks/hookList";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../utils/forms/Button";

const HomeAssignmentInstructions = () => {
  const { setSpinner, setToaster } = useContext(AppContext);
  const { templateId, examId } = useParams();
  const navigate = useNavigate();
  const [termAndCond, setTermAndCond] = useState("");

  const { getData: getHomeAssignmentInstructions, data: instructionsData } =
    useExamInstructions(setSpinner, setToaster);

  const { getData: setTest } = useSetTest(setSpinner, setToaster);

  const checkInstruction = async (e) => {
    if (!termAndCond) {
      setToaster({
        show: true,
        type: "danger",
        content: "Please accept and check instructions.",
      });
    } else {
      const params = {
        exam_id: examId,
      };
      await setTest(params);
      navigate(
        `/home-assignments/assignment-details/newAssignmentQuiz/${examId}`
      );
    }
  };
  useEffect(() => {
    const params = {
      id: templateId,
      exam_id: examId,
    };
    getHomeAssignmentInstructions(params);
  }, []);
  return (
    <div className="exam-instructions h-100">
      <div className="white-box h-100">
        <div className="dash-heading">
          <h2 className="themeLink pb-2">
            {JSON.parse(localStorage.getItem("testName"))?.subject}
          </h2>
          {/* <h1>General Instructions</h1> */}
        </div>
        <div className="basic_instructions mt-3">
          <div>
            <h1>General Instructions:</h1>
          </div>
          <div>
            <ul>
              <li className="my-0 p-0">
                <p className="my-4 p-0">
                  The clock will be set at the server. The countdown timer in
                  the top right corner of screen will display the remaining time
                  available for you to complete the examination. When the timer
                  reaches zero, the examination will end by itself. You will not
                  be required to end or submit your examination.
                </p>
              </li>
              <li className="my-0 p-0">
                <p className="my-4 p-0">
                  The Questions Palette displayed on the right side of screen
                  will show the status of each question using one of the
                  following symbols:
                </p>
                <div className="my-3">
                  <div className="d-flex align-items-center">
                    <span className="">1.</span>
                    <div
                      className="grayBox"
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "3px",
                        marginLeft: "1rem",
                      }}
                    ></div>
                    <span className="ms-3">
                      You have not visited the question yet.
                    </span>
                  </div>
                  <div className="d-flex align-items-center my-3">
                    <span className="">2.</span>
                    <div
                      className="greenBox"
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "3px",
                        marginLeft: "1rem",
                      }}
                    ></div>
                    <span className="ms-3">Correct Answer.</span>
                  </div>
                  <div className="d-flex align-items-center my-3">
                    <span className="">3.</span>
                    <div
                      className="pinkBox"
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "3px",
                        marginLeft: "1rem",
                      }}
                    ></div>
                    <span className="ms-3">Incorrect Answer</span>
                  </div>
                </div>
              </li>
              <li className="my-0 p-0">
                <p className="my-4 p-0">
                  In mobile devices, you can click on the "Palette" button which
                  appears to the right of the screen to view the question
                  palette.
                </p>
              </li>
            </ul>
          </div>
          <div className="my-3">
            <h1>Navigating & Answering to a Question:</h1>
            <ul>
              <li className="my-0 p-0">
                <p>To answer a question, do the following:</p>
                <div>
                  <p className="my-4 p-0">
                    1. Click on the question number in the Question Palette at
                    the right of your screen to go to that numbered question
                    directly. Or click on 'Next' or 'Previous' button to move
                    from one question to another.
                    <span>
                      Note that using this option does NOT save your answer to
                      the current question.
                    </span>
                  </p>
                  <p className="my-4 p-0">
                    2. After selecting an option for a question, the correct
                    answer will be immediately displayed. Once an option is
                    selected, it cannot be changed thereafter. This ensures that
                    you have access to the right answer promptly and helps
                    maintain the integrity of your response.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {instructionsData?.template?.instructions && (
          <div>
            <div
              className="question mcq-questions fs-2"
              dangerouslySetInnerHTML={{
                __html: instructionsData?.template?.instructions,
              }}
            ></div>
            <div className="nav-qa">
              <div className="accept-condition pt-4">
                <div className="input-text pt-2">
                  <span htmlFor="termBox" className="ps-3 gray-text d-flex">
                    <div className="form-group radio-group position-relative">
                      <div className="input-box">
                        <label>
                          <input
                            type="checkBox"
                            name="termBox"
                            id="termBox"
                            className={`text-input me-3`}
                            value={termAndCond}
                            onChange={(e) => setTermAndCond(e.target.checked)}
                          />
                          <span className="fs-2">
                            I have read the instructions.
                          </span>
                        </label>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div className="exam_instructions_container">
                <Button
                  title={"Proceed"}
                  type="button"
                  className="proceed btn theme-btn btn exam-instruction-proceed-btn"
                  onClick={checkInstruction}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeAssignmentInstructions;
