import React from "react";
import { Modal } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import Button from "../../utils/forms/Button";
import Image from "../../utils/image/Image";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const OMRFilePreviewModal = ({
  isModalOpenOMR,
  setIsModalOpenOMR,
  previewOMRFile,
  pageNumber,
  onDocumentLoadSuccess,
  selectedOMRFile,
  handleUploadOMR,
  studentId,
  modalSize,
}) => {
  const omrParams = () => {
    const formData = new FormData();
    formData.append(
      "exam_id",
      parseInt(window?.localStorage.getItem("exam_id"))
    );
    formData.append("student_id", studentId);
    formData.append("omr_attachment", selectedOMRFile);

    return formData;
  };

  return (
    <div>
      <Modal
        size={modalSize ? modalSize : "lg"}
        show={isModalOpenOMR}
        onHide={() => setIsModalOpenOMR(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px" }}>File Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUploadOMR(omrParams());
              setIsModalOpenOMR(false);
            }}
          >
            {previewOMRFile?.includes("data:image") && (
              <div className="d-flex justify-content-center mt-5">
                <Image src={previewOMRFile} alt="preview-file-image" />
              </div>
            )}
            {previewOMRFile?.includes("data:application/pdf") && (
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 d-flex">
                    <div className="doc-viewer" style={{ overflow: "hidden" }}>
                      <Document
                        file={"data:application/pdf;" + previewOMRFile}
                        renderMode="canvas"
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        <Page
                          height={100}
                          width={1000}
                          pageNumber={pageNumber}
                        />
                      </Document>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex justify-content-center pb-5">
              <Button
                className="btn theme-btn dark-blue mt-5 w-25"
                disabled={!selectedOMRFile}
                title="Submit"
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OMRFilePreviewModal;
