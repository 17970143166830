// NumericKeypad.js
import React from "react";
import "./numericKeypad.scss"; // Import your CSS file for styling
import { useLocation } from "react-router-dom";

const NumericKeypad = ({ onKeyPress, handleUpdateNumericalQuestion }) => {
  const location = useLocation();
  const handleClick = (number) => {
    onKeyPress(number);
  };

  return (
    <div className="numeric-keypad">
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, ".", 0, "⌫"].map((number) => (
        <div className="d-flex justify-content-center align-items-center">
          <button
            key={number}
            onClick={() => handleClick(number)}
            className={`key ${
              number === "." || number === "⌫" ? "function-key" : ""
            }`}
          >
            {number}
          </button>
        </div>
      ))}
      {!location.pathname.includes("examPortal") && (
        <div className="w-100 my-3 ps-4">
          <button
            style={{
              border: "none",
              outline: "none",
              padding: "5px 10px",
              border: "3px solid #ebebeb",
              background: "#f2f4fc",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "16px",
            }}
            onClick={handleUpdateNumericalQuestion}
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default NumericKeypad;
