import React, { useContext, useEffect, useState } from "react";
import Table from "../../utils/table/Table";
import { AppContext } from "../../shared/AppContext";
import { userService } from "../../../_services/User.services";
import Spinner from "../../utils/shared/spinner/spinner";
import { useBreadcrumb } from "../../shared/breadcrumb-context";
import Auth from "../../../auth/Auth";

const MyAccount = () => {
  const { spinner, setSpinner, setToaster, userData } = useContext(AppContext);
  const [bookAccountData, setBookAccountData] = useState([]);
  const { dispatch } = useBreadcrumb();

  const getMyBookDetails = async () => {
    setSpinner(true);
    try {
      const params = {
        enrollment_id: userData?.user_id,
      };
      const response = await userService.bookDetails(params);
      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();
        setBookAccountData(data.book_details);
        setSpinner(false);
      }
      if (response.status >= 200 && response.status <= 499) {
        const data = await response.json();
        setToaster({ show: true, type: "warning", content: data.message });
        setSpinner(false);
      }
    } catch (error) {
      setSpinner(false);
    }
    setSpinner(false);
  };

  useEffect(() => {
    if (userData?.user_id !== undefined) {
      getMyBookDetails();
    }
    dispatch({
      type: "SET_BREADCRUMB",
      crumb: [{ title: "My Account", url: "/my-account" }],
    });
  }, [userData?.user_id]);

  return (
    <>
      {spinner ? (
        <Spinner />
      ) : (
        <div className="white-box body-cont">
          <div className="d-flex flex-wrap justify-content-between">
            <div className="mb-5">
              <h1>Books Allotment</h1>
            </div>
            <div className="d-flex flex-wrap gap-3 gap-lg-5 mb-5 mb-lg-0">
              <div>
                <a
                  className="btn theme-btn small-btn m-0"
                  href={`${process.env.REACT_APP_BATCH_ID_CARD_URL}/${
                    Auth?.user()?.user_id
                  }`}
                  download
                  target="_blank"
                  style={{padding:'10px',fontSize:'16px'}}
                >
                  Download Student ID Card
                </a>
              </div>
              <div>
                <a
                  className="btn theme-btn small-btn m-0"
                  target="_blank"
                  href={`http://psis.prathamedu.com/payment/receipts/${userData?.PSIS_Key}`}
                  style={{ padding: "10px",fontSize:"16px" }}
                >
                  View Receipt
                </a>
              </div>
            </div>
          </div>

          <Table
            heading={[
              {
                id: 1,
                value: "S.No",
                className: "w-10 text-center",
              },
              {
                id: 2,
                value: "Book Name",
                className: "w-25 text-center",
              },
              {
                id: 3,
                value: "Book Status",
                className: "w-15 text-center",
              },
              {
                id: 4,
                value: "Book Assigned By",
                className: "w-15 text-center",
              },
              {
                id: 5,
                value: "Book Assigned Date",
                className: "w-15 text-center",
              },
            ]}
            rows={bookAccountData.map((accountData, index) => {
              const originalDate = new Date(accountData.bookassigned_date);
              const formattedDateTime = originalDate.toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false, // Set to true if you want 12-hour time format
              });

              return [
                {
                  id: 1,
                  value: index + 1,
                  className: "w-10 text-center",
                },
                {
                  id: 2,
                  value: accountData?.books_name,
                  className: "w-25 text-capitalize text-center",
                },
                {
                  id: 3,
                  value: accountData.books_status,
                  className: "w-15 text-capitalize text-center",
                },
                {
                  id: 4,
                  value: accountData.books_assignedby,
                  className: "w-15 text-capitalize text-center",
                },
                {
                  id: 5,
                  value:
                    formattedDateTime === "Invalid Date"
                      ? "-"
                      : formattedDateTime,
                  className: "w-15 text-capitalize text-center",
                },
              ];
            })}
          />
        </div>
      )}
    </>
  );
};

export default MyAccount;
