import React from "react";
import Button from "../../utils/forms/Button";

const ExamSummaryModal = ({
  submitDataResponse,
  dyt_id,
  finalExamSubmitHandler,
  playPauseTimer,
  setConfirmationModal,
}) => {
  // Reusable variable for submitDataResponse[0]?.[dyt_id]?.question_data
  const questionData =
    submitDataResponse?.length > 0
      ? submitDataResponse[0]?.[dyt_id]?.question_data
      : [];

  // Helper function to filter question data by status
  const getQuestionCountByStatus = (status) => {
    return questionData.filter((question) => Number(question.status) === status)
      .length;
  };

  return (
    <div className="common_modal">
      <div className="gray-bg round-body">
        <div className="modalHeading">
          <h1>Exam Summary</h1>
        </div>
        <div className="modal-body">
          <ul className="ps-0 marks_list">
            <li className="w-100 list-unstyled">
              <span className="float-starting w-75">Number of Questions</span>
              <span className="float-end w-25">{questionData.length}</span>
            </li>
            <li className="w-100 list-unstyled">
              <span className="float-starting w-75">Answered</span>
              <span className="float-end w-25">{getQuestionCountByStatus(0)}</span>
            </li>
            <li className="w-100 list-unstyled">
              <span className="float-starting w-75">Not Answered</span>
              <span className="float-end w-25">
                {getQuestionCountByStatus(3) + getQuestionCountByStatus(4)}
              </span>
            </li>
            <li className="w-100 list-unstyled">
              <span className="float-starting w-75">Marked for Review</span>
              <span className="float-end w-25">{getQuestionCountByStatus(2)}</span>
            </li>
            <li className="w-100 list-unstyled">
              <div className="w-75">
                <span className="d-block">Answered & Marked for Review</span>
                <small>(will be considered for evaluation)</small>
              </div>

              <span className="float-end w-25">
                {getQuestionCountByStatus(1)}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-footer d-block">
        <div className="button-footer w-100">
          <ul className="d-flex list-unstyled">
            <li>
              <Button
                className="procees small-btn me-4"
                title="Yes"
                onClick={finalExamSubmitHandler}
              />
            </li>
            <li>
              <Button
                className="proceed small-btn border-btn"
                onClick={() => {
                  playPauseTimer(true);
                  setConfirmationModal(false);
                }}
                title="No"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ExamSummaryModal;
