import React from "react";
import CommonModal from "../../utils/modal/CommonModal";
import Button from "../../utils/forms/Button";

const MockExamSummaryModal = ({
  confirmationModal,
  setConfirmationModal,
  submittedQuestionData,
  totalQuestions,
  isEnableSlot,
  lastStructureObject,
  selectedTabId,
  playPauseTimer,
  finalExamSubmitHandler

}) => {
  return (
    <CommonModal
      className="exam_summary_mock_modal"
      show={confirmationModal}
      close={() => setConfirmationModal(false)}
      crossShow={false}
      body={
        <div className="common_modal">
          <div className="gray-bg round-body">
            <div className="modalHeading">
              <h1>Exam Summary</h1>
            </div>
            <div className="modal-body">
              <ul className="ps-0 marks_list">
                <li className="w-100 list-unstyled">
                  <span className="float-starting">Number of Questions</span>
                  <span className="float-end">{totalQuestions}</span>
                </li>
                <li className="w-100 list-unstyled">
                  <span className="float-starting">Answered</span>
                  <span className="float-end">
                    {submittedQuestionData.find((d) => d.status === 0)
                      ?.total_count !== undefined
                      ? submittedQuestionData.find((d) => d.status === 0)
                          ?.total_count
                      : 0}
                  </span>
                </li>
                <li className="w-100 list-unstyled">
                  <span className="float-starting">Not Answered</span>
                  <span className="float-end">
                    {submittedQuestionData.find(
                      (d) => d.status === 3 || d.status === 4 || d.status === 5
                    )?.total_count !== undefined
                      ? submittedQuestionData.find(
                          (d) =>
                            d.status === 3 || d.status === 4 || d.status === 5
                        )?.total_count
                      : 0}
                  </span>
                </li>
                <li className="w-100 list-unstyled">
                  <span className="float-starting"> Marked for Review</span>
                  <span className="float-end">
                    {submittedQuestionData.find((d) => d.status === 1)
                      ?.total_count !== undefined
                      ? submittedQuestionData.find((d) => d.status === 1)
                          ?.total_count
                      : 0}
                  </span>
                </li>
                <li className="w-100 list-unstyled">
                  Answered & Marked for Review<br></br>
                  <small>(will be considered for evaluation)</small>
                  <span className="float-end">
                    {submittedQuestionData.find((d) => d.status === 2)
                      ?.total_count !== undefined
                      ? submittedQuestionData.find((d) => d.status === 1)
                          ?.total_count
                      : 0}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="modal-footer d-block">
            <p>
              {isEnableSlot && lastStructureObject[0]?.id != selectedTabId
                ? `Are you sure you want to submit the current Slot? No
            changes will be allowed after submission.After submit the current slot you will move to the next slot`
                : `Are you sure you want to submit for final marking? No
            changes will be allowed after submission.`}
            </p>
            <div className="button-footer w-100">
              <ul className="d-flex list-unstyled">
                <li>
                  <Button
                    className="proceed small-btn me-4"
                    title="Yes"
                    onClick={finalExamSubmitHandler}
                  />
                </li>
                <li>
                  <Button
                    className="proceed  small-btn border-btn"
                    onClick={() => {
                      playPauseTimer(true);
                      setConfirmationModal(false);
                    }}
                    title="No"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default MockExamSummaryModal;
