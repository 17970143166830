import React from "react";
import CommonModal from "../../utils/modal/CommonModal";
import Button from "../../utils/forms/Button";

const ViewResultModal = ({
  viewResultModal,
  viewResultHandler,
  resultDate,
  buttonTitle,
  isSubjectiveMarking,
}) => {
  return (
    <CommonModal
      className="exam-modal view_result_modal"
      show={viewResultModal}
      crossShow={false}
      body={
        <div className="common_modal">
          <div className="modal-footer d-block pb-5 pt-0">
            <p>Thank you, </p> <p>Submitted Successfully.</p>
            {buttonTitle !== "View Result" && !isSubjectiveMarking && (
              <p>Your Result will be published on: {resultDate}</p>
            )}
            {isSubjectiveMarking && (
              <p>Evaluation of the exam is pending and will be done shortly.</p>
            )}
            <div className="button-footer w-100">
              <Button
                className="procees small-btn me-4"
                title={buttonTitle}
                onClick={viewResultHandler}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default ViewResultModal;
