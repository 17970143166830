import React from "react";
import VerticalTable from "../../../utils/table/VerticalTable";

const FormCategoryDetails = ({ categoryFormDetails }) => {
  return (
    categoryFormDetails?.length > 0 &&
    categoryFormDetails?.map((formDetails) => {
      return (
        <div
          className="uni-data mb-5 pt-3"
          id={formDetails.formId}
          key={formDetails.formId}
        >
          <h1 className="mb-4 text-center category_form_title">{formDetails?.universityName}</h1>
          <div
            className={`white-box p-0 ${
              formDetails?.isRecommendation === 1  && "recomendation_class"
            }`}
          >
            <VerticalTable
              className="border-0 no-stripe"
              varticalData={formDetails?.formTable}
            />
          </div>
        </div>
      );
    })
  );
};

export default FormCategoryDetails;
