export const useQuestionType = () => {
  // Question Type Mapping
  const questionTypeMap = {
    1: "Single Answer Type",
    2: "Multiple Answer Type",
    3: "Numerical Answer Type",
    4: "Word Answer Type",
    5: "Subjective Answer Type",
    6: "Comprehension Type",
  };
  // Question Type Function
  const questionType = (questions) => {
    return questionTypeMap[questions] || "Question type";
  };
  return { questionType };
};
