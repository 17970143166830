import { useEffect } from "react";
import { Routes, Route, Navigate, HashRouter } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "./component/shared/AppContext";
// onboarding
import Login from "./component/onboarding/Login";
import SignUp from "./component/onboarding/SignUp";
import ForgetPassword from "./component/onboarding/ForgetPassword";
// import ResetPassword from "./component/onboarding/ResetPassword";
import VerfyCode from "./component/onboarding/VerfyCode";

import PortalLayout from "./component/projectDashboard/portalLayout/PortalLayout";
import Home from "./component/projectDashboard/dashboard/Home";
import Tests from "./component/projectDashboard/test/Tests";
import Schedule from "./component/projectDashboard/schedule/Schedule";
import Classes from "./component/projectDashboard/schedule/Classes";

import Reports from "./component/projectDashboard/reports/Reports";
import FormDashboard from "./component/projectDashboard/formsDashboard/FormsDashboard";

import "./App.scss";
import ExamCategory from "./component/projectDashboard/test/ExamCategory";
import Examss from "./component/projectDashboard/examLatestPratham/Exams";

import CompleteReports from "./component/projectDashboard/reports/CompleteReports";

import Spinner from "./component/utils/shared/spinner/spinner";
import Toast from "./component/utils/shared/toast/toast";
import Auth from "./auth/Auth";
import NewPassword from "./component/onboarding/NewPassword";
import ExamLayout from "./component/projectDashboard/examLayout/ExamLayout";
// import { Worker, Viewer } from "@react-pdf-viewer/core";
import DocumentView from "./component/utils/documenView/DocumentView";
import ResultNew from "./component/projectDashboard/examLayout/ResultNew";
import DesignTest from "./component/projectDashboard/designYourTest/DesignTest";
import ProgressBar from "./component/utils/progressReport/ProgressBar";
import Assignment from "./component/projectDashboard/assignment/Assignment";
import LIveClass from "./component/projectDashboard/courses/LIveClass";
import UpdateProfile from "./component/projectDashboard/portalLayout/UpdateProfile";
import DesignTestLayout from "./component/projectDashboard/designYourTest/DesignTestLayout";
import TestDesignReport from "./component/projectDashboard/designYourTest/TestDesignReport";
import AssignmentReport from "./component/projectDashboard/assignment/AssignmentReport";
import AssignmentResult from "./component/projectDashboard/assignment/AssignmentResult";
import FormUpdates from "./component/projectDashboard/formSection/FormUpdates";
import FormWallet from "./component/projectDashboard/formSection/FormWallet";
import AddNewWallet from "./component/projectDashboard/formSection/forms/AddNewWallet";
import WalletUpdate from "./component/projectDashboard/formSection/forms/WalletUpdate";
import Testimonial from "./component/testimonial/Testimonial";
import ShortcutDetails from "./component/projectDashboard/courses/ShortcutDetails";
import Notification from "./component/notification/Notification";
import Notes from "./component/utils/notes/Notes";
import ChatScreen from "./component/projectDashboard/courses/ChatScreen";
import AllThreads from "./component/projectDashboard/courses/AllThreads";
import ChatSettings from "./component/projectDashboard/courses/ChatSettings";
import ChatHistory from "./component/projectDashboard/courses/ChatHistory";
import Quiz from "./component/projectDashboard/quiz/quiz";
import HomeAssignmentExam from "./component/projectDashboard/homeAssignmentLatest/HomeAssignmentExam";
import NewAssignmentDetail from "./component/projectDashboard/homeAssignmentLatest/NewAssignmentDetail";
import NewDytTest from "./component/projectDashboard/newDesignYourTest/NewDytTest";
import ExamInstructionLatest from "./component/projectDashboard/examLatestPratham/ExamInstruction";
import MyAccount from "./component/projectDashboard/myAccount/MyAccount";
import LiveClassRecordings from "./component/projectDashboard/schedule/LiveClassRecordings";
import Recordings from "./component/projectDashboard/schedule/Recordings";
import SearchResource from "./component/projectDashboard/searchResource/SearchResource";
import Content from "./component/projectDashboard/content/Content";
import FolderDetails from "./component/projectDashboard/content/FolderDetails";
import HomeAssignmentInstructions from "./component/projectDashboard/homeAssignmentLatest/HomeAssignmentInstructions";
import OtherClasses from "./component/projectDashboard/schedule/OtherClasses";
import OmrSelfCheck from "./component/omrCheck";
import StudentPerformance from "./component/projectDashboard/reports/StudentPerformance";
import Homework from "./component/projectDashboard/homework/index";
import NewExam from "./component/projectDashboard/newExamLatest/Exams";
import MIS from "./mis/MIS";
import ReferEarn from "./component/projectDashboard/referEarn";
import VideoView from "./component/utils/videoView/VideoView";
import XATExamInstructions from "./component/projectDashboard/xatPratahmExam/ExamInstruction";
import XATExam from "./component/projectDashboard/xatPratahmExam/Exams";

const App = () => {
  const { spinner, toaster, setToaster } = useContext(AppContext);
  useEffect(() => {
    // Check if the user is Authenticated
    if (Auth.isUserAuthenticated()) {
      // Get the current URL from the browser
      const urlParams = new URLSearchParams(window.location.search);
      // Check in the URL has 'prathamtoken' or not
      if (urlParams.has("prathamtoken")) {
        // Delete the pratham token in the URL
        urlParams.delete("prathamtoken");
        // Create new URL & remove the login? from the URL as well
        const newUrl = `${
          window.location.origin
        }${window.location.pathname.replace(
          "login",
          ""
        )}?${urlParams.toString()}${window.location.hash}`;
        window.history.replaceState({}, "", newUrl.replace("?", ""));
      }
    }

    // Disable text selection
    const disableTextSelection = (event) => {
      event.preventDefault();
    };
    document.addEventListener("selectstart", disableTextSelection);

    // Disable dragging
    const disableDrag = (event) => {
      event.preventDefault();
    };
    document.addEventListener("dragstart", disableDrag);

    // Cleanup: remove event listeners when component unmounts
    return () => {
      document.removeEventListener("selectstart", disableTextSelection);
      document.removeEventListener("dragstart", disableDrag);
    };
  }, []);
  return (
    <HashRouter>
      {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js"></Worker> */}
      {spinner && <Spinner />}

      <Toast
        show={toaster.show}
        content={toaster.content}
        type={toaster.type}
        onClose={() => {
          setToaster({
            show: false,
            type: "",
            content: "",
          });
        }}
        autoClose={toaster.autoClose || true}
      />

      <>
        <Routes>
          <Route path="/mis" element={<MIS />} />

          {Auth.isUserAuthenticated() ? (
            <>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route exact path="/" element={<PortalLayout />}>
                <Route path="/dashboard" element={<Home />} />
                <Route
                  path="/dashboard/shortcut/:shortcutId"
                  element={<ShortcutDetails />}
                />
                <Route path="/course" element={<Content />} />
                <Route
                  path="/course/:courseId/folder/:folderId"
                  element={<FolderDetails />}
                />
                <Route path="update-profile" element={<UpdateProfile />} />
                <Route path="/testimonial" element={<Testimonial />} />
                <Route path="/notification" element={<Notification />} />
                <Route
                  path="/courses/document/:id"
                  element={<DocumentView />}
                />
                 <Route
                  path="/courses/video/:id"
                  element={<VideoView />}
                />
                <Route path="/courses/notes/" element={<Notes />} />
                {/* <Route path="/courses/live_class/:id" element={<LIveClass />} /> */}
                <Route path="/courses/live_class/:id" element={<LIveClass />}>
                  <Route
                    path="/courses/live_class/:id/chat/:threadId"
                    element={<ChatScreen />}
                  />
                  <Route
                    path="/courses/live_class/:id/all_threads"
                    element={<AllThreads />}
                  />
                  <Route
                    path="/courses/live_class/:id/settings"
                    element={<ChatSettings />}
                  />
                  <Route
                    path="/courses/live_class/:id/chat_history"
                    element={<ChatHistory />}
                  />
                </Route>
                <Route path="/test" element={<Tests />} />
                <Route
                  path="/test/omr/self-check/:examId"
                  element={<OmrSelfCheck />}
                />
                <Route
                  path="/test/exam_category/:exam_category_id"
                  element={<ExamCategory />}
                />
                <Route
                  path="/test/examinstructions/:exam_id"
                  element={<ExamInstructionLatest />}
                />
                <Route
                  path="/test/xat/examinstructions/:exam_id"
                  element={<XATExamInstructions />}
                />
                <Route
                  path="/test/:exam_id/exams/resultnew"
                  element={<ResultNew />}
                />
                <Route
                  path="/test/student-performance"
                  element={<StudentPerformance />}
                />
                <Route
                  path="/test/:exam_id/exams/precess"
                  element={<ProgressBar />}
                />
                <Route path="/schedule" element={<Schedule />} />
                <Route path="/schedule/allclasses" element={<Classes />} />
                <Route
                  path="/schedule/otherClasses"
                  element={<OtherClasses />}
                />
                <Route
                  path="/schedule/liveClassRecordings"
                  element={<LiveClassRecordings />}
                />
                <Route
                  path="/schedule/liveClassRecordings/:liveClassId/recordings"
                  element={<Recordings />}
                />
                <Route path="/schedule/homework" element={<Homework />} />

                <Route path="/reports" element={<Reports />} />
                <Route
                  path="/reports/completereports"
                  element={<CompleteReports />}
                />
                <Route path="/search" element={<SearchResource />} />
                <Route path="/forms" element={<FormDashboard />} />
                {/* design your test  */}
                <Route path="/design-your-test" element={<DesignTest />} />
                {/* <Route path="/dyt_test/:dyt_id" element={<DYTTest />} /> */}
                <Route
                  path="/design_your_test/test-design-report/:dyt_id"
                  element={<TestDesignReport />}
                />
                {/* end design your test  */}
                <Route path="/home-assignments" element={<Assignment />} />
                <Route
                  path="/home-assignments/assignment-report/:exam_id"
                  element={<AssignmentReport />}
                />
                <Route
                  path="/home-assignments/new-assignment-details/:course_id"
                  element={<NewAssignmentDetail />}
                />
                <Route
                  path="/home-assignments/assignment-details/home-assignment-instructions/:templateId/:examId"
                  element={<HomeAssignmentInstructions />}
                />
                <Route
                  path="/home-assignments/assignment-details/newAssignmentQuiz/:exam_id"
                  element={<HomeAssignmentExam />}
                />
                <Route
                  path="/home-assignments/assignment-report/assignment-result/:exam_id"
                  element={<AssignmentResult />}
                />
                <Route path="/forms/form-updates" element={<FormUpdates />} />
                <Route
                  path="/forms/form-updates/form-wallet"
                  element={<FormWallet />}
                />
                <Route
                  path="/forms/add_form_wallet"
                  element={<AddNewWallet />}
                />
                <Route
                  path="/forms/update_wallet/:wallet_id"
                  element={<WalletUpdate />}
                />
                <Route path="/my-account" element={<MyAccount />} />
                <Route path="/refer-earn" element={<ReferEarn />} />

                {/* <Route path="/quiz" element={<Quiz />} /> */}
              </Route>
              <Route path="/test_design_layout" element={<DesignTestLayout />}>
                <Route
                  path="/test_design_layout/:dyt_id"
                  element={<NewDytTest />}
                />
              </Route>
              <Route path="/examPortal" element={<ExamLayout />}>
                <Route
                  path="/examPortal/template/:exam_id/exams"
                  element={<Examss />}
                />
                <Route
                  path="/examPortal/xat/:exam_id/exams"
                  element={<XATExam />}
                />
                <Route
                  path="/examPortal/:exam_id/exams"
                  element={<NewExam />}
                />
              </Route>
            </>
          ) : (
            <>
              <Route path="*" element={<Navigate to="/" replace />} />
              <Route exact path="/" element={<Login />} />
              <Route path="signup" element={<SignUp />} />
              <Route path="/forgotpassword" element={<ForgetPassword />} />
              <Route path="/newpassword" element={<NewPassword />} />
              <Route path="/verifycode" element={<VerfyCode />} />
            </>
          )}
        </Routes>
      </>
    </HashRouter>
  );
};

export default App;
