import React from "react";
import Button from "../../utils/forms/Button";
import CommonModal from "../../utils/modal/CommonModal";

const MockCuetModal = ({ cuetModal, moveToNextSlot }) => {
  return (
    <CommonModal
      className="exam-modal cuet_modal"
      show={cuetModal}
      crossShow={false}
      body={
        <div className="modal-footer">
          <p className="text-center mb-4" style={{ fontSize: "2rem" }}>
            {`Current Slot has completed. Please click 'Continue' below to start the next slot of the exam.`}
          </p>
          <div className="d-flex justify-content-center mb-4">
            <div className="col-12">
              <Button
                className="btn small-btn theme-btn w-100 text-nowrap py-2 px-3 fs-4"
                title="Move to next Slot"
                onClick={moveToNextSlot}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default MockCuetModal;
