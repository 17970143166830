import React, { useState, useEffect, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AppContext } from "../../shared/AppContext";
import BackButton from "../../utils/backBtn/BackButton";
import Auth from "../../../auth/Auth";
import Spinner from "../../utils/shared/spinner/spinner";
import { useDisableKeys } from "../../../hooks/useDisableKeys";
import { useHomeAssignmentsList } from "../../../hooks/hookList";
import ExamIcon from "../../../assets/images/online-exam.png";
import HomeAssignmentStatusButton from "./HomeAssignmentStatusButton";
import "./homeAssignmentExamList.scss";
import Image from "../../utils/image/Image";

const SubjectAccordion = ({ index, toggleFolder, name, id,activeSubject }) => {
  return (
    <div
      className={`subject_container ${index === 0 && "active_background"}`}
      key={id}
      onClick={() => toggleFolder(id)}
    >
      <div
        className={`accordion_title_container ${
          (index === 0 && "accordion_title_active") ||
          (activeSubject === id && "accordion_title_active")
        }`}
      >
        <span>{name?.toLowerCase()}</span>{" "}
      </div>
    </div>
  );
};

const HomeExamCard = ({
  id,
  name,
  status,
  examTemplateId,
  startDate,
  endDate,
  resultDate,
  examAttemptCount,
}) => {
  return (
    <div className="exam_card">
      <div>
        <div className="d-flex align-items-center">
          <div>
            <Image
              className="resource_card_icon"
              src={ExamIcon}
              alt="resource_icon"
            />
          </div>
          <div className="exam_name">{name}</div>
        </div>
        <div className="border_line" />
        <div className="mt-5 d-flex justify-content-between">
          <HomeAssignmentStatusButton
            id={id}
            examTemplateId={examTemplateId}
            status={status}
            startDate={startDate}
            endDate={endDate}
            resultDate={resultDate}
            subjectName={name}
            attemptCount={examAttemptCount}
          />
        </div>
      </div>
    </div>
  );
};

const NewAssignmentDetail = () => {
  const { course_id } = useParams();
  const location = useLocation();
  const { spinner, setSpinner, setToaster } = useContext(AppContext);
  const [accordionData, setAccordionData] = useState([
    { id: course_id, name: location?.state?.course_name },
  ]);
  const [subjectExamList, setSubjectExamList] = useState([]);
  const [activeSubject, setActiveSubject] = useState();
  const { getData: getHomeAssignments, data: examList } =
    useHomeAssignmentsList(setSpinner, setToaster);

  // useDisableKeys();

  const toggleFolder = (id) => {
    const examsList = examList?.exams;
    setActiveSubject(id);

    const matchingSubjects = [];

    for (const exam of examsList) {
      for (const examSubject of exam.exam_subjects) {
        for (const subject of examSubject.subject) {
          if (subject.id === id) {
            matchingSubjects.push(exam);
          }
        }
      }
    }
    setSubjectExamList(matchingSubjects);
  };

  const extractUniquePairs = (examData) => {
    const uniquePairs = new Set();
    const uniquePairsArr = [];

    for (const exam of examData) {
      if (!exam || !exam.exam_subjects) continue; // Validate exam structure

      for (const examSubject of exam.exam_subjects) {
        if (!examSubject || !examSubject.subject) continue; // Validate examSubject structure

        for (const subject of examSubject.subject) {
          if (!subject || !subject.id || !subject.name) continue; // Validate subject structure

          const { id, name } = subject;
          const uniquePairIdentifier = `${id}_${name}`;

          if (!uniquePairs.has(uniquePairIdentifier)) {
            uniquePairs.add(uniquePairIdentifier);
            uniquePairsArr.push({ id, name });
          }
        }
      }
    }

    return uniquePairsArr;
  };

  const homeAssignmentCallback = (data) => {
    const subjectList = extractUniquePairs(data?.data?.exams?.flat()).sort(
      (a, b) => a.name.localeCompare(b.name)
    );
    setAccordionData((prevData) => [...prevData, ...subjectList]);
    setActiveSubject(subjectList[0]?.id);
    const matchingSubjects = [];

    for (const exam of data?.data?.exams) {
      for (const examSubject of exam.exam_subjects) {
        for (const subject of examSubject.subject) {
          if (subject.id === subjectList[0]?.id) {
            matchingSubjects.push(exam);
          }
        }
      }
    }
    setSubjectExamList(matchingSubjects);
  };

  useEffect(() => {
    Auth?.setExamData([]);
    getHomeAssignments({ course_id }, homeAssignmentCallback);
  }, []);

  return (
    <>
      {spinner && <Spinner whiteBackground={true} />}
      {!spinner && (
        <div className="assignment-dash fixed-dash">
          <div className="white-box body-cont  ">
            <div className="sub-report">
              <div className="dash-heading mb-0 my-3">
                <span className="d-flex align-items-center">
                  <BackButton url={location?.state?.back_url} />
                  <h1 className="mb-0"> Self Assessments</h1>
                </span>
              </div>
              <div className="my-5 container_exam">
                <div className="w-100">
                  <div className="d-flex flex-wrap">
                    <div className="home_assignment_exam_container">
                      <div className="accordion_container">
                        {accordionData.map((d, i) => (
                          <SubjectAccordion
                            id={d.id}
                            name={d.name}
                            index={i}
                            toggleFolder={toggleFolder}
                            key={d.id}
                            activeSubject={activeSubject}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="exam_list_container">
                      <div className="exam_card_container d-flex flex-wrap">
                        {subjectExamList.map((d) => {
                          return (
                            <HomeExamCard
                              key={d.id}
                              id={d?.id}
                              name={d?.name}
                              status={d?.status}
                              startDate={d?.start_date}
                              endDate={d?.end_date}
                              resultDate={d?.result_date}
                              examAttemptCount={d.exam_attempt_count}
                              examTemplateId={d.exam_template_id}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NewAssignmentDetail;
