import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import backIcon from "../../../assets/images/left-arrow.png";
import Image from "../image/Image";
function BackButton({ url }) {
  const navigate = useNavigate();
  // useEffect(() => {
  //     navFun()
  // }, [url])

  const navFun = React.useCallback(() => {
    if (!url) {
      window.history.back();
      // navigate(-1)
    } else {
      navigate(-1);
    }
  }, []);

  return (
    <span className="pointer p-2 ps-0" onClick={navFun}>
      <Image
        className="back-btn"
        src={backIcon}
        width={20}
        height={20}
        alt="back icon"
      />
    </span>
  );
}

export default BackButton;
