import React from "react";
import WhiteChevronRight from "../../../assets/images/white-chevron-right.png";
import MockTestButton from "../test/MockTestButton";
import HomeAssignmentStatusButton from "../homeAssignmentLatest/HomeAssignmentStatusButton";
import OMRFilePreviewModal from "../test/OMRFilePreviewModal";
import { uploadOMRFile } from "../../utils/helpers/uploadOMRFile";
import Image from "../../utils/image/Image";

const CourseResourceCard = ({
  cardData,
  type,
  icon,
  index,
  navigate,
  setSelectedFile,
  selectedFile,
  setPreviewFile,
  previewFile,
  setIsModalOpen,
  isModalOpen,
  setToaster,
  pageNumber,
  onDocumentLoadSuccess,
  handleUploadOMR,
  studentId,
}) => {
  return (
    <div className="col-12 col-lg-6 p-0" key={cardData.id}>
      <div
        className={`resource_card ${
          type !== "Exam" ? "cursor-pointer" : "card_exam"
        } ${[0, 1].includes(index) ? "mt-4" : "mt-16"} ${
          index % 2 === 0 ? "mr-16" : ""
        }`}
        onClick={() => {
          if (type !== "Exam" && String(type).toLowerCase() === "document") {
            navigate(`/courses/document/${cardData.id}`, {
              state: {
                item: cardData,
                resourse: type,
                resourceData: [
                  {
                    id: 1,
                    name: type,
                  },
                ],
              },
            });
          } else {
            navigate(`/courses/video/${cardData.id}`, {
              state: {
                item: cardData,
                resourse: type,
                resourceData: [
                  {
                    id: 2,
                    name: type,
                  },
                ],
              },
            });
          }
        }}
      >
        <div className="d-flex align-items-center ">
          <div>
            <Image
              className="resource_card_icon"
              src={icon}
              alt="resource_icon"
            />
          </div>
          <div className="resource_card_title_container">
            <div className="resource_card_title">
              {cardData?.title || cardData?.name}
            </div>
          </div>
          {type !== "Exam" && (
            <div
              className="resource_card_title_container"
              // onClick={() => {
              //   navigate(`/courses/document/${cardData.id}`, {
              //     state: {
              //       item: cardData,
              //       resourse: type,
              //       resourceData: [
              //         {
              //           id: 1,
              //           name: type,
              //         },
              //       ],
              //     },
              //   });
              // }}
            >
              <div className="d-flex justify-content-center align-items-center cursor-pointer resource_card_navigation">
                <Image src={WhiteChevronRight} alt="chevron-right" />
              </div>
            </div>
          )}
        </div>
        {type === "Exam" && (
          <div
            style={{
              border: "1px solid rgba(215, 221, 245, 1)",
              margin: "20px 0 0 0",
            }}
          ></div>
        )}
        {type === "Exam" && (
          <div style={{ margin: "20px 0" }}>
            {cardData?.category?.name?.replace(/\s+/g, "").toLowerCase() ===
              "homeassignment" && (
              <HomeAssignmentStatusButton
                id={cardData?.id}
                examTemplateId={cardData?.exam_template_id}
                status={cardData?.status}
                endDate={cardData?.end_date}
                resultDate={cardData?.result_date}
                subjectName={cardData?.name}
                attemptCount={cardData?.exam_attempt_count}
              />
            )}
            {cardData?.category?.name?.replace(/\s+/g, "").toLowerCase() !==
              "homeassignment" && (
              <MockTestButton
                status={cardData.status}
                id={cardData?.id}
                startDate={cardData.start_date}
                endDate={cardData?.end_date}
                resultDate={cardData?.result_date}
                examRetake={cardData?.exam_retakes}
                examStatus={cardData?.exam_status}
                examTemplateId={cardData.exam_template_id}
                categoryExamName={cardData?.category?.name?.toLowerCase()}
                categoryExamRetake={cardData?.category?.exam_retake}
                subjectName={cardData?.name}
                onChangeOMR={(e) =>
                  uploadOMRFile(
                    e,
                    setSelectedFile,
                    setPreviewFile,
                    setIsModalOpen,
                    setToaster
                  )
                }
                onClickOMR={() => {
                  document.getElementById("file_upload").click();
                  localStorage.setItem("exam_id", cardData?.id);
                }}
                totalQuestions={cardData?.total_questions}
                examName={cardData?.name}
                questionLimit={Number(
                  cardData?.exam_template_structure
                    ? Number(cardData?.exam_template_structure[0]?.limit)
                    : undefined
                )}
                examTemplateUIId={cardData?.category?.template?.id}
              />
            )}
          </div>
        )}
      </div>
      <div>
        <OMRFilePreviewModal
          modalSize="md"
          isModalOpenOMR={isModalOpen}
          setIsModalOpenOMR={setIsModalOpen}
          selectedOMRFile={selectedFile}
          pageNumber={pageNumber}
          onDocumentLoadSuccess={onDocumentLoadSuccess}
          previewOMRFile={previewFile}
          handleUploadOMR={handleUploadOMR}
          studentId={studentId}
        />
      </div>
    </div>
  );
};

export default CourseResourceCard;
